import { useEffect, useState } from 'react';
import { debounce, off, on } from 'shared/utils';
let prevScroll: number;

if (window) {
  prevScroll = window.pageYOffset;
}
const useScrollingUp = () => {
  const [scrollingUp, setScrollingUp] = useState(false);

  const handleScroll = () => {
    const currScroll = window.pageYOffset;
    const isScrolled = prevScroll > currScroll;
    setScrollingUp(isScrolled);
    prevScroll = currScroll;
  };

  useEffect(() => {
    on(window, 'scroll', handleScroll, { passive: true });
    return () => {
      off(window, 'scroll', handleScroll, { passive: true });
    };
  }, []);
  return scrollingUp;
};

export default useScrollingUp;
