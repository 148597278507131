import { Checkbox } from 'primereact/checkbox';
import { shallowEqual } from 'react-redux';
import PrimaryText from 'shared/components/text/PrimaryText';
import { updateCurrentWidgetStep } from 'store/booking/booking.thunk';
import { useGetBookingWidgetQuery } from '../../../../store/booking-widget/booking-widget.slice';
import {
  getCurrentWidgetInput,
  getReturnLocationSameAsPickup,
  getSelectedBookingLocations
} from '../../../../store/booking/booking.selectors';
import {
  setPickupLocation,
  setReturnLocation,
  setReturnLocationSameAsPickup
} from '../../../../store/booking/booking.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import useGetAsset from '../../../hooks/useGetAsset';
import SecondaryText from '../../text/SecondaryText';
import { WidgetInput } from '../widget-input.enum';
import BookingWidgetLocationInput from './BookingWidgetLocationInput';

export default function BookingWidgetLocationsContainer() {
  const dispatch = useAppDispatch();

  const returnLocationSame = useAppSelector(getReturnLocationSameAsPickup, shallowEqual);
  const selectedBookingLocations = useAppSelector(getSelectedBookingLocations, shallowEqual);

  const { data: widgetConfig } = useGetBookingWidgetQuery();
  const activeWidget = useAppSelector(getCurrentWidgetInput, shallowEqual);
  const informationIcon = useGetAsset(widgetConfig?.goSearchLocationInformation?.id || '');
  function getPickupLocationIcon() {
    return (
      (returnLocationSame
        ? widgetConfig?.goPickUpReturnIcon?.id
        : widgetConfig?.goPickUpIcon?.id) || ''
    );
  }

  const selectLocation = (locationInput: WidgetInput): void => {
    dispatch(updateCurrentWidgetStep(locationInput));
  };

  function clearPickupLocation() {
    dispatch(setPickupLocation(0));
  }

  function clearReturnlocation() {
    dispatch(setReturnLocation(0));
  }

  return (
    <section className="flex flex-wrap justify-content-between w-12">
      <div className="w-12 md:w-6 mb-2">
        <PrimaryText
          className="text-base text-color-black font-semibold"
          text={widgetConfig?.bookingWidgetLocationTitle}
        />
      </div>
      <div className="flex w-12 md:w-6 md:justify-content-end align-items-center flex-order-2 md:flex-order-0 mt-3 md:mt-0 mb-2">
        <Checkbox
          inputId="sameReturnLocation"
          onChange={() => dispatch(setReturnLocationSameAsPickup(!returnLocationSame))}
          checked={returnLocationSame}></Checkbox>
        <label htmlFor="sameReturnLocation" className="p-checkbox-label text-xs ml-1">
          {widgetConfig?.goSearchLocationSame}
        </label>
      </div>
      <div className="flex w-12 flex-column md:flex-row booking-widget-location-inputs-container">
        <BookingWidgetLocationInput
          locationImgUrl={getPickupLocationIcon()}
          placeholder={widgetConfig?.goLocationInputPlaceholderText || ''}
          onClick={() => selectLocation(WidgetInput.PICKUP_LOCATION)}
          isActive={activeWidget === WidgetInput.PICKUP_LOCATION}
          selectedLocationId={selectedBookingLocations.pickupLocationId}
          informationIconUrl={informationIcon.url}
          onClear={clearPickupLocation}
        />
        {/* <div className="mr-2"></div> */}
        {!returnLocationSame && (
          <BookingWidgetLocationInput
            locationImgUrl={widgetConfig?.goSearchReturnIcon?.id || ''}
            placeholder={widgetConfig?.goLocationInputPlaceholderText || ''}
            onClick={() => selectLocation(WidgetInput.RETURN_LOCATION)}
            isActive={activeWidget === WidgetInput.RETURN_LOCATION}
            selectedLocationId={selectedBookingLocations.returnLocationId}
            informationIconUrl={informationIcon.url}
            onClear={clearReturnlocation}
          />
        )}
      </div>
    </section>
  );
}
