import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
  name: 'errors',
  initialState: {
    message: '',
    visible: false
  },
  reducers: {
    showErrorsMessage: (state, action: PayloadAction<string>) => {
      if (state.message !== action.payload) {
        state.message = action.payload;
      }
      if (!state.visible) {
        state.visible = true;
      }
    },
    hideErrorsMessage: (state) => {
      state.message = '';
      state.visible = false;
    }
  }
});

export const { showErrorsMessage, hideErrorsMessage } = errorSlice.actions;

export default errorSlice.reducer;
