import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { getCurrentErrorMessage, getCurrentErrorState } from 'store/errors/errors.selectors';
import { hideErrorsMessage } from 'store/errors/errors.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function ErrorComponent() {
  const toast = useRef<Toast | null>(null);
  const dispatch = useAppDispatch();
  const message = useAppSelector(getCurrentErrorMessage, shallowEqual);
  const visible = useAppSelector(getCurrentErrorState, shallowEqual);

  useEffect(() => {
    if (toast.current && visible) {
      toast.current.show({
        severity: 'error',
        detail: message,
        sticky: true
      });
    }
  }, [visible, message]);

  function removeMessage() {
    dispatch(hideErrorsMessage());
  }

  return <Toast ref={toast} onHide={removeMessage} />;
}
