import { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type TextType = string | Array<any & { markdown?: string }> | undefined | null;

export type MarkdownTextProps = {
  text: TextType;
  paragraphClassName?: string;
  strongClassName?: string;
};

export default function MarkdownText(textProps: MarkdownTextProps) {
  const renderText = useCallback(() => {
    return Array.isArray(textProps.text)
      ? textProps.text.map((textElement) => textElement.html).join('')
      : textProps.text || '';
  }, [textProps.text]);
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      disallowedElements={['br']}
      skipHtml={true}
      components={{
        strong: ({ node, ...props }) => (
          <strong className={`flex ${textProps.strongClassName}`} {...props}></strong>
        ),
        p: ({ node, ...props }) => <p className={textProps.paragraphClassName} {...props}></p>
      }}>
      {renderText()}
    </ReactMarkdown>
  );
}
