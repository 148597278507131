import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import configService from 'shared/config.service';
import { LocationsUtils } from '../../shared/components/booking-widget/locations.utils';
import { BookingLocationDetails, Location } from '../../shared/models/location.model';

export const locationsAPI = createApi({
  reducerPath: 'locations',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configService.baseUrl}/locations`
  }),
  endpoints: (builder) => ({
    getLocationsList: builder.query<Location[], void>({
      query: () => ``,
      transformResponse: (response: Location[]) => {
        return parseGooglePlusCodes(response);
      }
    }),
    getLocationById: builder.query<BookingLocationDetails, number>({
      query: (id) => `${id}`
    })
  })
});

function parseGooglePlusCodes(locations: Location[]) {
  return locations.map((location: Location) => {
    try {
      location.geoLocation = LocationsUtils.parseGooglePlusCode(location.googlePlusCode);
    } catch (e) {
      location.geoLocation = null;
    }
    return location;
  });
}

export const {
  useGetLocationsListQuery,
  useLazyGetLocationByIdQuery,
  useGetLocationByIdQuery,
  usePrefetch
} = locationsAPI;
