import BookingWidgetDateTimeContainer from './BookingWidgetDateTimeContainer';
import BookingWidgetLocationsContainer from './BookingWidgetLocationsContainer';
import { Divider } from 'primereact/divider';
import { usePrefetch as useLocationsPrefetch } from '../../../../store/locations/locations.slice';
import { usePrefetch as useCalendarPrefetch } from '../../../../store/booking-widget/booking-widget.slice';
import { usePrefetch as useLocationsOverviewPrefetch } from '../../../../store/locations/location-overview.slice';
import { useEffect } from 'react';
import PrimaryButton from 'shared/components/primary-button/PrimaryButton';
import './BookingWidgetInputsContainer.scss';
import { useAppSelector } from 'store/hooks';
import { getCurrentWidgetInput } from 'store/booking/booking.selectors';
import { WidgetInput } from '../widget-input.enum';

type BookingWidgetInputsContainerProps = {
  displaySubmitOnOpenedOnly?: boolean;
  submitButtonText?: string;
  submitButtonClass?: string;
  submitCallback?: () => void;
};

export default function BookingWidgetInputsContainer({
  submitButtonText,
  displaySubmitOnOpenedOnly,
  submitButtonClass,
  submitCallback
}: BookingWidgetInputsContainerProps) {
  const prefetchLocations = useLocationsPrefetch('getLocationsList');
  const prefetchLocationsOverview = useLocationsOverviewPrefetch('GetAllLocationsOverview');
  const prefetchCalendar = useCalendarPrefetch('GetBookingCalendar');
  const currentWidget = useAppSelector(getCurrentWidgetInput);

  // Prefetch locations data
  useEffect(() => {
    prefetchWidgetData();
  }, []);

  const prefetchWidgetData = () => {
    prefetchLocations();
    prefetchLocationsOverview();
    prefetchCalendar();
  };

  return (
    <>
      <div className="flex flex-column md:flex-row justify-content-center inputs-container">
        {/* location */}
        <div className="flex flex-2 md:max-w-30rem location-inputs-container mt-3">
          <BookingWidgetLocationsContainer />
        </div>
        <Divider layout="vertical" className="p-0 booking-widget-vertical-divider hidden md:flex" />

        {/* pickup/return dates */}
        <div className="flex flex-1 mt-3">
          <BookingWidgetDateTimeContainer />
        </div>
        {(!displaySubmitOnOpenedOnly ||
          (displaySubmitOnOpenedOnly && currentWidget !== WidgetInput.NONE)) && (
          <div className="flex flex-0 align-items-center mt-3 md:mt-0">
            <PrimaryButton
              onClick={() => (submitCallback ? submitCallback() : null)}
              text={submitButtonText || ''}
              className={`text-base font-bold solid-green-background md:font-normal w-full ${submitButtonClass}`}
            />
          </div>
        )}
      </div>
    </>
  );
}
