import { generateApiWithReducerPath, graphQLAPI } from '../api/base-graphql-api';

export type DownloadApp = {
  id: string;
  applicationName?: string | null;
  modalHeroImage?: { id: string } | null;
  applicationIcon?: { id: string } | null;
  applicationSlogan?: string | null;
  playStoreDownloadButton?: { id: string } | null;
  appStoreDownloadButton?: { id: string } | null;
};

export type GetDownloadAppByIdQuery = {
  goWebappDownloadModals: DownloadApp[] | [];
};

export const GetDownloadAppByIdDocument = `
  query GetDownloadApp() {
    goWebappDownloadModals {
      id
      applicationName
      modalHeroImage {
        id
      }
      applicationIcon {
        id
      }
      applicationSlogan
      playStoreDownloadButton {
        id
      }
      appStoreDownloadButton {
        id
      }
    }
  }
`;

const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetDownloadAppOverview: build.query<DownloadApp, void>({
      query: () => ({ document: GetDownloadAppByIdDocument }),
      transformResponse: (response: GetDownloadAppByIdQuery) => {
        return response.goWebappDownloadModals[0];
      }
    })
  })
});

export { injectedRtkApi as downloadAppOverviewAPI };
export const { useGetDownloadAppOverviewQuery, useLazyGetDownloadAppOverviewQuery } =
  injectedRtkApi;
