import './InTheMediaComponent.scss';
import HomeHeader from 'shared/components/header/HomeHeader';
import useGetAsset from 'shared/hooks/useGetAsset';
import ImageComponent from 'shared/components/image/ImageComponent';
import { useGetMediaPageOverviewQuery } from 'store/media/media.slice';
import { Divider } from 'primereact/divider';

const InTheMediaComponent = () => {
  const { data: media } = useGetMediaPageOverviewQuery();
  const heroImage = useGetAsset(media?.goWebappHomepages[0].mediaPageHeroImage?.id || '');

  return (
    <main className="media">
      <HomeHeader />
      <section>
        <div
          className="relative bg-cover bg-no-repeat bg-center w-full book-car-container z-5"
          style={{ backgroundImage: `url(${heroImage.url})` }}>
          <div className="absolute partners-title md:flex md:align-items-end md:justify-content-between">
            <h1 className="font-normal m-0 md:text-6xl text-5xl text-white ">
              {media?.goWebappHomepages[0].mediaPageHeader || ''}
            </h1>
          </div>
        </div>
        <div className="media-container">
          {media?.goWebappMediaArticles.map((article, index) => (
            <div key={index}>
              <div className="flex justify-content-center align-items-center relative flex-column md:flex-row">
                <div className="md:w-5 border-round-xl overflow-hidden w-full">
                  <ImageComponent id={article.mediaCoverImage?.id || ''} />
                </div>
                <div className="md:w-7">
                  <div className="md:ml-6 md:pr-7">
                    <div className="flex md:block  justify-content-between mt-3 md:mt-0">
                      <div className="w-4 md:w-fit mb-3 md:mb-5">
                        <ImageComponent id={article.mediaLogo?.id || ''} />
                      </div>
                      <a
                        href={article.mediaArticleLinkUrl || ''}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="md:absolute border-round-3xl media-button-link md:right-0 md:top-0 cursor-pointer">
                        <div className="relative w-full h-full">
                          <i className="pi pi-chevron-right absolute left-50 pi pi-chevron-right top-50" />
                        </div>
                      </a>
                    </div>
                    <div className="md:text-lg font-semibold mb-1">{article.mediaArticleTitle}</div>
                    <div className="text-xs text-600 mb-3">{article.publicationDate}</div>
                    <div>{article.mediaArticleLeadParagraph}</div>
                  </div>
                </div>
              </div>
              <Divider layout="horizontal" className="" />
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default InTheMediaComponent;
