import { createSelector } from '@reduxjs/toolkit';
import { WidgetInput } from 'shared/components/booking-widget/widget-input.enum';
import { FormattedDateTime } from 'shared/utils';
import { getCoveragesOverview } from 'store/coverages/coverages.selectors';
import { CarExtrasOverview } from 'store/extras/extras-overview.slice';
import { getExtrasOverview } from 'store/extras/extras.selectors';
import { RootState } from '../store';

export const getBookingInfo = (state: RootState) => state.booking;

export const getUserBookingInfo = createSelector(
  [getBookingInfo],
  (bookingInfo) => bookingInfo.userBookingData
);

export const getUserBookingCarSubclassId = createSelector(
  [getUserBookingInfo],
  (bookingInfo) => bookingInfo.subclassId
);

export const getPickupLocationId = createSelector(
  [getUserBookingInfo],
  (bookingInfo) => bookingInfo.pickUpLocationId
);

export const getReturnLocationId = createSelector(
  [getUserBookingInfo],
  (bookingInfo) => bookingInfo.returnLocationId
);

export const getSelectedBookingLocations = createSelector(
  [getPickupLocationId, getReturnLocationId],
  (pickupLocationid, returnLocationid) => ({
    pickupLocationId: pickupLocationid,
    returnLocationId: returnLocationid
  })
);

export const getPickupAndReturnTimes = (state: RootState) => ({
  pickupTime: state.booking.userBookingData.pickupTime,
  returnTime: state.booking.userBookingData.returnTime
});

export const getPickupAndReturnDates = (state: RootState) => ({
  pickupDate: state.booking.userBookingData.pickUpDate,
  returnDate: state.booking.userBookingData.returnDate
});

export const getPickupReturnDateTime = createSelector(
  [getPickupAndReturnTimes, getPickupAndReturnDates],
  (dates, times) => ({ ...dates, ...times })
);

export const getRentalHours = createSelector([getPickupReturnDateTime], (pickupReturnDateTime) => {
  const pickUpDate = FormattedDateTime(
    pickupReturnDateTime.pickupDate,
    pickupReturnDateTime.pickupTime
  );
  const returnDate = FormattedDateTime(
    pickupReturnDateTime.returnDate,
    pickupReturnDateTime.returnTime
  );
  const diffInMinutes = returnDate.diff(pickUpDate, 'minutes');
  return Math.ceil(diffInMinutes / 60);
});

export const getRentalInDays = createSelector([getRentalHours], (hours: number) => {
  let days = 0;
  if (hours <= 6) {
    days = 0.25;
  } else if (hours > 6 && hours <= 12) {
    days = 0.5;
  } else {
    days = Math.ceil(hours / 24);
  }
  return days;
});
export const getLocationSortType = (state: RootState) => state.booking.widget.locationSort;

const selectBookingWidget = (state: RootState) => state.booking.widget;

export const getUnavailableDays = createSelector([selectBookingWidget], (widget) => {
  return widget.unavailableDays;
});

export const getTimes = createSelector([selectBookingWidget], (widget) => {
  return widget.times;
});

export const getLocationSearchQuery = createSelector(
  [selectBookingWidget],
  (bookingWidget) => bookingWidget.locationSearchQuery
);

export const getReturnLocationSameAsPickup = createSelector(
  [selectBookingWidget],
  (bookingWidget) => bookingWidget.returnLocationSameAsPickup
);

export const getCurrentWidgetInput = createSelector(
  [selectBookingWidget],
  (bookingWidget) => bookingWidget.currentWidgetInput
);

export const isBookingWidgetOpen = createSelector(
  [getCurrentWidgetInput],
  (currentWidgetInput) => currentWidgetInput !== WidgetInput.NONE
);

export const getUserLocation = createSelector(
  [selectBookingWidget],
  (bookingWidget) => bookingWidget.userLocation
);

export const getBookingWidgetSelectionsValid = createSelector(
  [getPickupLocationId, getReturnLocationId, getPickupReturnDateTime],
  (pickupLocationId, returnLocationId, pickupReturnDateTime) => {
    return (
      pickupLocationId &&
      returnLocationId &&
      pickupReturnDateTime.pickupDate &&
      pickupReturnDateTime.pickupTime &&
      pickupReturnDateTime.returnDate &&
      pickupReturnDateTime.returnTime
    );
  }
);

export const getCurrentWizardStep = createSelector(
  [selectBookingWidget],
  (bookingWidget) => bookingWidget.currentJourneyWizardStep
);

export const getSelectedClassId = createSelector(
  [getUserBookingInfo],
  (bookingInfo) => bookingInfo.classId
);

export const getSelectedExtras = createSelector(
  [getUserBookingInfo],
  (bookingInfo) => bookingInfo.selectedExtras
);

export const getSelectedExtrasAndDetails = createSelector(
  [getSelectedExtras, getExtrasOverview],
  (selectedExtras, extrasOverview) => {
    const result = Array<CarExtrasOverview & { count: number }>();

    extrasOverview?.data?.extras.forEach((overview) => {
      const selected = selectedExtras.find(
        (selectedExtra) => selectedExtra.id === overview.rentExtrasId
      );
      if (selected) {
        result.push({ ...overview, count: selected.count });
      }
    });

    return result;
  }
);
export const getSelectedCoverage = createSelector(
  [getUserBookingInfo],
  (bookingInfo) => bookingInfo.coverageId
);

export const getSelectedCoverageAndDetail = createSelector(
  [getSelectedCoverage, getCoveragesOverview],
  (selectedCoverage, coveragesOverview) => {
    return coveragesOverview?.data?.find(
      (coverage) => coverage.rentCoverageId === selectedCoverage
    );
  }
);

export const isExtraSelected = (id: number) =>
  createSelector(
    [getSelectedExtras],
    (selectedExtras) => selectedExtras.findIndex((extra) => extra.id === id) >= 0
  );

export const getExtraSelectionCount = (id: number) =>
  createSelector(
    [getSelectedExtras],
    (selectedExtras) => selectedExtras.find((extra) => extra.id === id)?.count
  );

export const isCoverageSelected = (id: number) =>
  createSelector([getUserBookingInfo], (userBookingInfo) => userBookingInfo.coverageId === id);

export const getRentalInfo = createSelector([getUserBookingInfo], (bookingInfo) => ({
  rentalId: bookingInfo.rentalId,
  systemEmail: bookingInfo.systemEmail
}));
