import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useLayoutEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCurrentLoaderState } from 'store/loader/loader.selectors';
import { setLoaderState } from 'store/loader/loader.slice';
import { RootState } from 'store/store';
import './Loader.scss';

export default function Loader() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getCurrentLoaderState, shallowEqual);
  const appRootElement: Element = document.getElementsByClassName('App')[0];
  const apiLoaderState = useAppSelector((state: RootState) => {
    const queries = {
      ...state.classes.queries,
      ...state.extras.queries,
      ...state.prices.queries,
      ...state.locations.queries,
      ...state.graphQL.queries
    };
    return Object.values(queries).some((query) => query && query?.status === QueryStatus.pending);
  });

  useLayoutEffect(() => {
    if (appRootElement) {
      if (isLoading) {
        appRootElement.classList.add('loader-open');
      } else {
        appRootElement.classList.remove('loader-open');
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (apiLoaderState !== isLoading) {
      dispatch(setLoaderState(apiLoaderState));
    }
  }, [apiLoaderState]);

  return isLoading ? (
    <ProgressSpinner className={'w-full h-screen absolute overflow-hidden top-0 left-0'} />
  ) : (
    <></>
  );
}
