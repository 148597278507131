import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    opened: false
  },
  reducers: {
    setLoaderState: (state, action: PayloadAction<boolean>) => {
      if (state.opened !== action.payload) {
        state.opened = action.payload;
      }
    }
  }
});

export const { setLoaderState } = loaderSlice.actions;

export default loaderSlice.reducer;
