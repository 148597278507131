export const menuItems = [
  {
    key: 'de_CH',
    items: [
      null,
      '/our-partners',
      '/in-the-media',
      'https://support.enterprise-go.ch/portal/de/kb/go'
    ]
  },
  {
    key: 'en',
    items: [
      null,
      '/our-partners',
      '/in-the-media',
      'https://support.enterprise-go.ch/portal/en/kb/go'
    ]
  },
  {
    key: 'fr_CH',
    items: [
      null,
      '/our-partners',
      '/in-the-media',
      'https://support.enterprise-go.ch/portal/fr/kb/go'
    ]
  }
];
