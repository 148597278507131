import { Carousel } from 'primereact/carousel';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import ImageComponent from 'shared/components/image/ImageComponent';
import useIsMobile from 'shared/hooks/useIsMobile';
import './HomeVideoCarousel.scss';

interface HomeVideoCarouselProps {
  items: Array<string>;
  playIcon: { id: string } | null;
  playText: string | null;
}
function useIsInViewport(ref: any) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const HomeVideoCarousel = (props: HomeVideoCarouselProps) => {
  const [volume, setVolume] = useState<number>(0);
  const [carouselPage, setCarouselPage] = useState<number>(0);
  const [pause, setPause] = useState<boolean>(false);
  const [isPlaying, setisPlaying] = useState<boolean>(false);
  // const [userInteraction, setUserInteraction] = useState<boolean>(false);
  const videoCarousel = useRef(null);
  const isInViewport = useIsInViewport(videoCarousel);
  const isAutoplay = useMemo(() => isInViewport, [isInViewport]);
  const { isSmallerDesktop, isMobile } = useIsMobile();

  function unmute() {
    setVolume(volume === 0 ? 1 : 0);
  }

  //TO DO: if they want to unmute video after user interaction
  // useEffect(() => {
  //   if (userInteraction && volume === 0) setVolume(1);
  // }, [userInteraction]);

  useEffect(() => {
    if (isSmallerDesktop) {
      setPause(true);
      setVolume(1);
    }
  }, [isSmallerDesktop]);

  function setPrevPage() {
    setCarouselPage((a) => (a - 1 < 0 ? props.items.length - 1 : a - 1));
  }

  function setNextPage() {
    setCarouselPage((a) => (a + 1 > props.items.length - 1 ? 0 : a + 1));
  }

  const videoTemplate = (item: string) => {
    return (
      <div className="video-player-container">
        <ReactPlayer
          url={item}
          volume={volume}
          playing={isAutoplay && carouselPage === props.items.indexOf(item) && !pause}
          width={'100%'}
          height={'100%'}
          onPlay={() => setisPlaying(true)}
          onPause={() => setisPlaying(false)}
          config={{
            vimeo: {
              playerOptions: {
                autoplay: isAutoplay && carouselPage === props.items.indexOf(item) && !pause,
                controls: false,
                keyboard: true,
                muted: false,
                pip: false,
                title: false,
                autopause: true,
                loop: true
              }
            }
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div ref={videoCarousel} className="md:mr-6 relative full-carousel-container">
        <div
          className="absolute video-overlay top-0 w-full z-3"
          onClick={() => {
            setPause(!pause);
            // if (!userInteraction) setUserInteraction(true);
          }}></div>
        <div className="custom-arrow arrow-prev absolute" onClick={setPrevPage}>
          <i className="pi pi-angle-left" />
        </div>
        <div className="custom-arrow arrow-next absolute" onClick={setNextPage}>
          <i className="pi pi-angle-right" />
        </div>
        <div onClick={unmute} className="z-5  absolute volume-icon">
          <div
            className={`volume`}
            style={{
              backgroundImage: `url(${
                volume
                  ? isMobile
                    ? '/images/Icon_mobile_unmute.png'
                    : '/images/Icon_unmute.png'
                  : isMobile
                  ? '/images/Icon_mobile_mute.png'
                  : '/images/Icon_mute.png'
              })`
            }}></div>
        </div>
        <div className={`z-1 absolute play-pause-icon ${!isPlaying ? 'show-overlay' : ''}`}>
          {!isPlaying && (
            <div className="align-items-center flex font-semibold text-lg md:text-2xl text-white play-video-buttons-container">
              <div className="play-video-image">
                <ImageComponent id={props.playIcon?.id || ''} />
              </div>
              <div className="ml-3 white-space-nowrap">{props.playText}</div>
            </div>
          )}
        </div>
        <Carousel
          page={carouselPage}
          onPageChange={(e) => {
            setCarouselPage(e.page);
          }}
          // if (!userInteraction) setUserInteraction(true);
          value={props.items}
          itemTemplate={videoTemplate}></Carousel>
      </div>
    </>
  );
};

export default HomeVideoCarousel;
