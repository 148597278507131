import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getLegalsOverviewModal } from 'store/modals/modal.selectors';
import { closeLegalsOverviewDialog } from 'store/modals/modals.slice';
import MarkdownText from '../text/MarkdownText';
import './Legals.scss';

const baseDialogConfig = {
  draggable: false,
  dismissableMask: true,
  focusOnShow: false,
  resizable: false,
  maximized: false
};

export default function Legals() {
  const dispatch = useAppDispatch();
  const legalsOverviewModal = useAppSelector(getLegalsOverviewModal);
  const appRootElement: Element = document.getElementsByClassName('App')[0];

  useEffect(() => {
    if (appRootElement) {
      if (legalsOverviewModal.opened) {
        appRootElement.classList.add('open-legal');
      } else {
        appRootElement.classList.remove('open-legal');
      }
    }
  }, [legalsOverviewModal]);

  function onDialogClose() {
    dispatch(closeLegalsOverviewDialog());
  }

  return (
    <>
      {legalsOverviewModal.opened && (
        <Dialog
          {...baseDialogConfig}
          visible={legalsOverviewModal.opened}
          onHide={() => onDialogClose()}
          className="border-none legals-container text"
          headerClassName="md:border-round-top-xl pb-0"
          contentClassName="pl-3 pt-0 pr-0 md:border-round-bottom-xl overflow-hidden">
          <main className="scroll-container">
            <section className="pr-3 md:pr-0 mx-3 content">
              <div className="font-semibold text-lg">{legalsOverviewModal.title}</div>
              <MarkdownText text={legalsOverviewModal.content} />
            </section>
          </main>
        </Dialog>
      )}
    </>
  );
}
