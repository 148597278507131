import { generateApiWithReducerPath } from '../api/base-graphql-api';

export type GetAssetByIdQueryVariables = {
  id: string;
  width?: number;
  height?: number;
};

export type GetAssetByIdQuery = {
  asset?: { fileName: string; url: string } | null;
};

export const GetAssetByIdDocument = `
query GetAssetById($id: ID!) {
  asset(where: {id: $id}) {
    fileName,
    handle
    url(
      transformation: {
        document: {output: {format: webp}}
      }
    )
  }
}`;
export const GetAssetByIdDocumentWithSize = `
query GetAssetById($id: ID!, $height: Int, $width: Int) {
  asset(where: {id: $id}) {
    fileName,
    handle
    url(
      transformation: {
        document: {output: {format: webp}}
        image: {resize: {height: $height, width: $width}}
      }
    )
  }
}`;

//      image: {resize: {height: $height, fit: clip, width: $width}},
const assetsAPI = generateApiWithReducerPath('assets');

const injectedRtkApi = assetsAPI.injectEndpoints({
  endpoints: (build) => ({
    GetAssetById: build.query<GetAssetByIdQuery, GetAssetByIdQueryVariables>({
      query: (variables) => ({
        document:
          variables.width && variables.height ? GetAssetByIdDocumentWithSize : GetAssetByIdDocument,
        variables
      })
    })
  })
});

export { injectedRtkApi as assetsAPI };
export const { useGetAssetByIdQuery, useLazyGetAssetByIdQuery, usePrefetch } = injectedRtkApi;
