import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import {
  useLazyGetUserPricesQuery,
  PricesRequestData
} from 'store/booking-overview/booking-user-data-prices.slice';
import { getSelectedExtrasAndDetails, getUserBookingInfo } from 'store/booking/booking.selectors';
import { UserBookingData } from 'store/booking/booking.slice';
import { useAppSelector } from 'store/hooks';
import useBookingClassSelection from './useBookingClassSelection';

const useUserBookingSelection = () => {
  const userBookingData: UserBookingData = useAppSelector(getUserBookingInfo, shallowEqual);
  const { hours } = useBookingClassSelection();
  const [getUserPrices, { data: userPrices }] = useLazyGetUserPricesQuery();
  const extras = useAppSelector(getSelectedExtrasAndDetails, shallowEqual);

  useEffect(() => {
    if (hours && userBookingData.acriss) {
      const data: PricesRequestData = {
        hours: hours,
        acriss: userBookingData.acriss,
        coverageId: userBookingData.coverageId,
        extraIds: extras
          .map((extra) => {
            const a = Array(extra.count).fill(extra.rentExtrasId).flat();
            return a;
          })
          .flat(),
        pickUpLocationId: userBookingData.pickUpLocationId,
        returnLocationId: userBookingData.returnLocationId,
        includeLocationTax: true
      };
      getUserPrices(
        {
          data: data
        },
        true
      );
    }
    // console.log('userPrices:', userPrices);
  }, [userPrices, userBookingData]);

  return {
    userBookingData,
    userPrices
  };
};

export default useUserBookingSelection;
