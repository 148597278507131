import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CarExtra } from 'store/extras/extras.slice';
import { LocationSort } from '../../shared/components/booking-widget/location-sort.enum';
import { WidgetInput } from '../../shared/components/booking-widget/widget-input.enum';
import { LocationCoordinates } from '../../shared/models/location.model';
import {
  createRent,
  getUserDeviceLocation,
  updatePickupLocation,
  updateReturnLocation
} from './booking.thunk';

const defaultTimes = () => {
  const t: string[] = [];
  for (let i = 0; i < 24; i++) {
    const hours = i.toString().padStart(2, '0');
    t.push(`${hours}:00`);
    t.push(`${i.toString().padStart(2, '0')}:30`);
  }
  return t;
};

interface SelectedExtra {
  id: number;
  count: number;
}

interface Widget {
  currentWidgetInput: WidgetInput;
  locationSearchQuery: string;
  returnLocationSameAsPickup: boolean;
  locationSort: LocationSort;
  userLocation: LocationCoordinates | null;
  currentJourneyWizardStep: number;
  unavailableDays: unavailableDays;
  times: Times;
}
export interface Times {
  pickupTimes: Array<string> | [];
  returnTimes: Array<string> | [];
}

export interface unavailableDays {
  unavailablePickupDays: Array<string> | [];
  unavailableReturnDays: Array<string> | [];
}
export interface UserBookingData extends BookingWidgetInformation {
  classId: number;
  subclassId: number;
  mappingId: number;
  acriss: string;
  selectedExtras: SelectedExtra[];
  coverageId: number;
  rentalId: number | null;
  systemEmail: string;
  tempBookingWidgetData: BookingWidgetInformation;
  userEmail: string;
}

interface BookingWidgetInformation {
  pickUpDate: string;
  returnDate: string;
  pickupTime: string;
  returnTime: string;
  pickUpLocationId: number;
  returnLocationId: number;
}

interface BookingAnalyticsInfo {
  pickupLocation: string;
  returnLocation: string;
  pickupTime: string;
  returnTime: string;
  carClass: string;
  carSubclass: string;
  coverage: string;
  extras: CarExtra[];
  totalPrice: number | null;
}

interface BookingState {
  widget: Widget;
  userBookingData: UserBookingData;
  bookingAnalyticsData: BookingAnalyticsInfo;
  backUrl?: string;
}

const bookingInitialState: BookingState = {
  widget: {
    currentWidgetInput: WidgetInput.NONE,
    locationSearchQuery: '',
    returnLocationSameAsPickup: true,
    locationSort: LocationSort.NAME,
    userLocation: null,
    currentJourneyWizardStep: 1,
    unavailableDays: {
      unavailablePickupDays: [],
      unavailableReturnDays: []
    },
    times: {
      pickupTimes: defaultTimes(),
      returnTimes: defaultTimes()
    }
  } as Widget,
  userBookingData: {
    pickUpDate: '',
    returnDate: '',
    pickupTime: '',
    returnTime: '',
    pickUpLocationId: 0,
    returnLocationId: 0,
    classId: 0,
    subclassId: 0,
    mappingId: 0,
    acriss: '',
    selectedExtras: [] as SelectedExtra[],
    coverageId: 0,
    rentalId: null,
    tempBookingWidgetData: {
      pickUpDate: '',
      returnDate: '',
      pickupTime: '',
      returnTime: '',
      pickUpLocationId: 0,
      returnLocationId: 0
    },
    userEmail: ''
  } as UserBookingData,
  bookingAnalyticsData: {
    pickupLocation: '',
    returnLocation: '',
    pickupTime: '',
    returnTime: '',
    carClass: '',
    carSubclass: '',
    coverage: '',
    extras: [],
    totalPrice: null
  } as BookingAnalyticsInfo
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: bookingInitialState,
  reducers: {
    setTemporaryWidgetData: (state) => {
      state.userBookingData.tempBookingWidgetData.pickUpDate = state.userBookingData.pickUpDate;
      state.userBookingData.tempBookingWidgetData.returnDate = state.userBookingData.returnDate;
      state.userBookingData.tempBookingWidgetData.pickupTime = state.userBookingData.pickupTime;
      state.userBookingData.tempBookingWidgetData.returnTime = state.userBookingData.returnTime;
      state.userBookingData.tempBookingWidgetData.pickUpLocationId =
        state.userBookingData.pickUpLocationId;
      state.userBookingData.tempBookingWidgetData.returnLocationId =
        state.userBookingData.returnLocationId;
    },
    discardBookingChanges: (state) => {
      state.userBookingData.pickUpDate = state.userBookingData.tempBookingWidgetData.pickUpDate;
      state.userBookingData.returnDate = state.userBookingData.tempBookingWidgetData.returnDate;
      state.userBookingData.pickupTime = state.userBookingData.tempBookingWidgetData.pickupTime;
      state.userBookingData.returnTime = state.userBookingData.tempBookingWidgetData.returnTime;
      state.userBookingData.pickUpLocationId =
        state.userBookingData.tempBookingWidgetData.pickUpLocationId;

      state.userBookingData.returnLocationId =
        state.userBookingData.tempBookingWidgetData.returnLocationId;
    },
    resetBookingState: (state) => {
      state.widget = bookingInitialState.widget;
      state.userBookingData = bookingInitialState.userBookingData;
    },
    setPickupDate: (state, action: PayloadAction<string>) => {
      state.userBookingData.pickUpDate = action.payload;
    },
    setReturnDate: (state, action: PayloadAction<string>) => {
      state.userBookingData.returnDate = action.payload;
    },
    setPickupAndReturnDate: (state, action: PayloadAction<Array<string>>) => {
      state.userBookingData.pickUpDate = action.payload[0];
      state.userBookingData.returnDate = action.payload[1];
    },
    setUnavailablePickupDays: (state, action: PayloadAction<Array<string>>) => {
      state.widget.unavailableDays.unavailablePickupDays = action.payload;
    },
    setBackUrl: (state, action: PayloadAction<string>) => {
      state.backUrl = action.payload;
    },
    setUnavailableReturnDays: (state, action: PayloadAction<Array<string>>) => {
      state.widget.unavailableDays.unavailableReturnDays = action.payload;
    },
    limitTimes: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        const limitedPickupTimes = getTimesAfterOffset(
          action.payload,
          state.widget.times.pickupTimes
        );
        state.widget.times.pickupTimes = limitedPickupTimes ? limitedPickupTimes : defaultTimes();
      } else {
        state.widget.times.pickupTimes = defaultTimes();
      }
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userBookingData.userEmail = action.payload;
    },
    resetTimes: (state) => {
      state.widget.times.pickupTimes = defaultTimes();
    },
    setPickupTime: (state, action: PayloadAction<string>) => {
      state.userBookingData.pickupTime = action.payload;
    },
    setReturnTime: (state, action: PayloadAction<string>) => {
      state.userBookingData.returnTime = action.payload;
    },
    setLocationSearchQuery: (state, action: PayloadAction<string>) => {
      state.widget.locationSearchQuery = action.payload;
    },
    setPickupLocation: (state, action: PayloadAction<number>) => {
      state.userBookingData.pickUpLocationId = action.payload;
      if (state.widget.returnLocationSameAsPickup) {
        state.userBookingData.returnLocationId = action.payload;
      }
    },
    setReturnLocation: (state, action: PayloadAction<number>) => {
      state.userBookingData.returnLocationId = action.payload;
    },
    setCurrentWidgetInput: (state, action: PayloadAction<WidgetInput>) => {
      state.widget = { ...state.widget, currentWidgetInput: action.payload };
    },
    setReturnLocationSameAsPickup: (state, action: PayloadAction<boolean>) => {
      state.widget.returnLocationSameAsPickup = action.payload;
      if (action.payload) {
        state.userBookingData.returnLocationId = state.userBookingData.pickUpLocationId;
      }
    },
    setLocationSortType: (state, action: PayloadAction<LocationSort>) => {
      state.widget.locationSort = action.payload;
    },
    setClassId: (state, action: PayloadAction<number>) => {
      state.userBookingData.classId = action.payload;
    },
    setSubclassInformation: (
      state,
      action: PayloadAction<{ mappingId: number; acriss: string; subclassId: number }>
    ) => {
      state.userBookingData.mappingId = action.payload.mappingId;
      state.userBookingData.subclassId = action.payload.subclassId;
      state.userBookingData.acriss = action.payload.acriss;
    },
    setNavigationStep: (state, action: PayloadAction<number>) => {
      state.widget.currentJourneyWizardStep = action.payload;
    },
    addExtra: (state, action: PayloadAction<number>) => {
      const existing = state.userBookingData.selectedExtras.find(
        (existingExtra: SelectedExtra) => existingExtra.id === action.payload
      );
      if (!existing) {
        state.userBookingData.selectedExtras.push({ id: action.payload, count: 1 });
      }
    },
    removeExtra: (state, action: PayloadAction<number>) => {
      state.userBookingData.selectedExtras.splice(
        state.userBookingData.selectedExtras.findIndex((item) => item.id === action.payload),
        1
      );
    },
    changeExtraCount: (state, action: PayloadAction<SelectedExtra>) => {
      const existing = state.userBookingData.selectedExtras.find(
        (existingExtra: SelectedExtra) => existingExtra.id === action.payload.id
      );

      if (existing) {
        existing.count = action.payload.count;
      }
    },
    setCoverage: (state, action: PayloadAction<number>) => {
      state.userBookingData.coverageId = action.payload;
    },
    clearRent: (state) => {
      state.userBookingData.rentalId = null;
      state.userBookingData.systemEmail = '';
    },
    updateBookingAnalyticsData: (state, action: PayloadAction<Partial<BookingAnalyticsInfo>>) => {
      state.bookingAnalyticsData = Object.assign(state.bookingAnalyticsData, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDeviceLocation.fulfilled, (state, action) => {
      state.widget.userLocation = action.payload;
    });
    builder.addCase(createRent.fulfilled, (state, action: any) => {
      state.userBookingData.rentalId = action.payload.rentalId;
      state.userBookingData.systemEmail = action.payload.systemEmail;
    });
    builder.addCase(updatePickupLocation.fulfilled, (state, action: PayloadAction<number>) => {
      state.userBookingData.pickUpLocationId = action.payload;
    });
    builder.addCase(updateReturnLocation.fulfilled, (state, action: PayloadAction<number>) => {
      state.userBookingData.returnLocationId = action.payload;
    });
  }
});

export const {
  setPickupDate,
  setReturnDate,
  setPickupAndReturnDate,
  setPickupTime,
  setUnavailablePickupDays,
  setUnavailableReturnDays,
  limitTimes,
  resetTimes,
  setReturnTime,
  setLocationSearchQuery,
  setPickupLocation,
  setCurrentWidgetInput,
  setReturnLocation,
  setReturnLocationSameAsPickup,
  setLocationSortType,
  setSubclassInformation,
  setClassId,
  setNavigationStep,
  clearRent,
  addExtra,
  removeExtra,
  setBackUrl,
  changeExtraCount,
  setCoverage,
  resetBookingState,
  setTemporaryWidgetData,
  discardBookingChanges,
  setUserEmail,
  updateBookingAnalyticsData
} = bookingSlice.actions;

export default bookingSlice.reducer;

function getTimesAfterOffset(offset: string, initialTimes: string[]): string[] | null {
  const index = initialTimes.findIndex((time) => time === offset);
  if (index >= 0) {
    return initialTimes.slice(index, initialTimes.length);
  }

  return null;
}
