import { Image } from 'primereact/image';
import './ImageComponent.scss';
import useGetAsset from 'shared/hooks/useGetAsset';
import { Skeleton } from 'primereact/skeleton';

type ImageComponentProps = {
  id: string;
  width?: string;
  height?: string;
  requestWidth?: number;
  requestHeight?: number;
  skeletonHeight?: string;
};

export default function ImageComponent({
  id,
  width,
  height,
  skeletonHeight,
  requestWidth,
  requestHeight
}: ImageComponentProps) {
  const { url, isFetching, fileName } = useGetAsset(id, requestWidth, requestHeight);

  return isFetching || !url ? (
    <Skeleton width={width} height={skeletonHeight} />
  ) : (
    <Image src={url} width={width || '100%'} height={height || 'auto'} alt={fileName} />
  );
}
