// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export const firebaseConfig = {
//   apiKey: 'AIzaSyAQpCU8tV6wHJMfd9zxpH81a6Y00OhdYc4',
//   authDomain: 'enttest-b241e.firebaseapp.com',
//   projectId: 'enttest-b241e',
//   storageBucket: 'enttest-b241e.appspot.com',
//   messagingSenderId: '998832757092',
//   appId: '1:998832757092:web:16fafcad3cb124cad30271',
//   measurementId: 'G-DPLD3DSYL8'
// };
// PROD

const isNotDevelopment = process.env.NODE_ENV !== 'development';

export const firebaseConfig = {
  apiKey: isNotDevelopment ? 'AIzaSyCQP1fNUkHB0X6omKeGmMPSan9AhYj2XmQ' : '',
  authDomain: isNotDevelopment ? 'enterprise-go-1000.firebaseapp.com' : '',
  projectId: 'enterprise-go-1000',
  storageBucket: 'enterprise-go-1000.appspot.com',
  messagingSenderId: '535030080872',
  appId: isNotDevelopment ? '1:535030080872:web:349474f29961905ceb22dd' : '',
  measurementId: isNotDevelopment ? 'G-2CSZ7W60DT' : ''
};

// Initialize Firebase
