import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';
import { useLazyGetDownloadAppOverviewQuery } from 'store/download-app/download-app-overview.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDownloadModal } from 'store/modals/modal.selectors';
import { closeDownloadAppOverview } from 'store/modals/modals.slice';
import ImageComponent from '../image/ImageComponent';
import './DownloadAppOverview.scss';

const baseDialogConfig = {
  draggable: false,
  dismissableMask: true,
  focusOnShow: false,
  resizable: false,
  maximized: false
};

export default function DownloadAppOverview() {
  const dispatch = useAppDispatch();

  const downloadOverviewModal = useAppSelector(getDownloadModal);
  const [getLocationOverview, { data: downloadOverview }] = useLazyGetDownloadAppOverviewQuery();

  useEffect(() => {
    if (downloadOverviewModal) {
      getLocationOverview(undefined, true);
    }
  }, [downloadOverviewModal]);

  function onDialogClose() {
    dispatch(closeDownloadAppOverview());
  }

  return (
    <>
      {downloadOverviewModal && (
        <Dialog
          {...baseDialogConfig}
          visible={downloadOverviewModal}
          onHide={() => onDialogClose()}
          className="w-11 download-app md:w-8 lg:w-6 border-none"
          headerClassName="border-round-top-xl pb-0"
          contentClassName="pl-3 pr-0 md:pl-5 border-round-bottom-xl">
          <main className="flex align-items-center flex-column md:flex-row">
            <section className="w-12 md:w-6 pr-3 md:pr-0">
              <div className="w-3rem mb-3">
                <ImageComponent id={downloadOverview?.applicationIcon?.id || ''} />
              </div>
              <div className="text-xl md:text-2xl font-semibold mb-1">
                {downloadOverview?.applicationName}
              </div>
              <div className="mb-5 text-base md:text-lg">{downloadOverview?.applicationSlogan}</div>
              <div className="flex">
                <a
                  className="w-10rem block mr-2"
                  href={'https://apps.apple.com/ch/app/enterprise-go-rent-a-car/id1635135473'}
                  target="_blank"
                  rel="noreferrer">
                  <ImageComponent id={downloadOverview?.appStoreDownloadButton?.id || ''} />
                </a>
                <a
                  className="w-10rem block"
                  href={'https://play.google.com/store/apps/details?id=com.go.car.rental'}
                  target="_blank"
                  rel="noreferrer">
                  <ImageComponent id={downloadOverview?.playStoreDownloadButton?.id || ''} />
                </a>
              </div>
            </section>
            <section className="w-12 md:w-6">
              <div>
                <ImageComponent id={downloadOverview?.modalHeroImage?.id || ''} />
              </div>
            </section>
          </main>
        </Dialog>
      )}
    </>
  );
}
