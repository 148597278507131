import './App.scss';
import LocationOverview from './components/location-overview/LocationOverview';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutContainer from 'shared/components/layout/LayoutContainer';
import PageNotFound from 'components/page-not-found/PageNotFound';
import Loader from 'shared/components/loader/Loader';
import ErrorComponent from 'shared/components/error-component/ErrorComponent';
import DownloadAppOverview from 'shared/components/download-app-overview/DownloadAppOverview';
import './i18/i18next';
import Legals from 'shared/components/legals/Legals';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CookieNotice from 'shared/components/cookie-notice/CookieNotice';
import TagManager from 'react-gtm-module';
import moment from 'moment-timezone';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'firebaseConfig';
import { getAnalytics } from 'firebase/analytics';
import LandingComponent from 'components/landing/LandingComponent';

const TITLE_KEY = 'COMMON.TITLE';
const DESCRIPTION_KEY = 'COMMON.DESCRIPTION';

// const tagManagerArgs = {
//   gtmId: 'GTM-5LTKDJ7'
// };

const app = process.env.NODE_ENV !== 'development' ? initializeApp(firebaseConfig) : null;
const analyticsApp = app ? getAnalytics(app) : null;

// TagManager.initialize(tagManagerArgs);
moment.tz.setDefault('Europe/Zurich');

function App() {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t(TITLE_KEY)}</title>
          <meta name="description" content={t(DESCRIPTION_KEY)} />
        </Helmet>
        <LocationOverview />
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <LayoutContainer />
              </>
            }></Route>
          <Route path="/external-booking" element={<LandingComponent />}></Route>
          <Route path="not-found" element={<PageNotFound />}></Route>
        </Routes>
        <Loader />
        <ErrorComponent />
        <DownloadAppOverview />
        <Legals />
        <CookieNotice />
      </div>
    </BrowserRouter>
  );
}

export default App;
