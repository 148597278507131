import React from 'react';

interface HomeTitleText {
  title: string;
  content: string;
  titleClasses?: string;
  diffFontSize?: boolean;
  contentClasses?: string;
}

const HomeTitleTextComponent = ({
  title,
  content,
  titleClasses,
  diffFontSize,
  contentClasses
}: HomeTitleText) => {
  return (
    <>
      <h3
        className={`font-semibold m-0 ${
          !diffFontSize ? `text-2xl md:text-5xl` : ``
        } ${titleClasses}`}>
        {title}
      </h3>
      <div className={`${!diffFontSize ? `text-base md:text-lg` : ``}  ${contentClasses}`}>
        {content}
      </div>
    </>
  );
};

export default React.memo(HomeTitleTextComponent);
