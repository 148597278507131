import moment, { Moment } from 'moment';
import { UserBookingData } from 'store/booking/booking.slice';
import { CarMileages, CarMiliagesSubClass } from './models/car-class.model';

export function isScreenWidthLowerThan(maxWidth: number) {
  return window.innerWidth <= maxWidth;
}

export function convertStringToUTCDate(date: string) {
  return moment(date).utc().toDate();
}

export function formatDate(date: Moment): string {
  return date.isValid() ? date.format('YYYY-MM-DD') : '';
}
export function formatTime(date: Moment): string {
  if (date.isValid()) {
    let timeRounded;
    if (date.minute() < 30) {
      timeRounded = date.minute(30).second(0);
    } else {
      timeRounded = date.add(1, 'hour').minute(0).second(0);
    }
    return timeRounded.format('HH:mm');
  }
  return '';
}

export function FormattedDateTime(date: string, time: string) {
  return moment(date + ' ' + time);
}
export function getNowDate() {
  return moment();
}

export function FormattedPickupReturnDates(pickupReturnDateTime: any): string {
  const format = 'MMM DD HH:mm';
  const pickupMoment = FormattedDateTime(
    pickupReturnDateTime.pickupDate,
    pickupReturnDateTime.pickupTime
  );

  const pickupDate = pickupMoment.isValid()
    ? pickupMoment.format(format).split(' ').splice(0, 3).join(' ').trimEnd()
    : '';

  const returnMoment = FormattedDateTime(
    pickupReturnDateTime.returnDate,
    pickupReturnDateTime.returnTime
  );

  const returnDate = returnMoment.isValid()
    ? returnMoment.format(format).split(' ').splice(0, 3).join(' ').trimEnd()
    : '';
  return pickupDate + ' - ' + returnDate;
}
export function on(obj: any, ...args: any) {
  obj.addEventListener(...args);
}

export function off(obj: any, ...args: any) {
  obj.removeEventListener(...args);
}

export function debounce(func: () => void, wait = 50) {
  let h: any;
  return () => {
    clearTimeout(h);
    h = setTimeout(() => func(), wait);
  };
}

export function findLowestPriceForClass(id: number, carMilliageClasses: CarMileages[] | undefined) {
  const carSubClasses: CarMiliagesSubClass[] =
    carMilliageClasses?.find((cc) => cc.id === id)?.subclasses || [];

  let lowestPrice = Infinity;

  carSubClasses?.forEach((subclass: CarMiliagesSubClass) => {
    if (subclass?.automaticPrice) {
      const automaticPrice = calcPriceWithVAT(
        subclass.automaticPrice.dailyPriceExcludingVATRounded,
        subclass.automaticPrice.dailyVATAmountRounded
      );
      lowestPrice = automaticPrice > 0 ? Math.min(lowestPrice, automaticPrice) : lowestPrice;
    }
    if (subclass?.manualPrice) {
      const manualPrice = calcPriceWithVAT(
        subclass.manualPrice.dailyPriceExcludingVATRounded,
        subclass.manualPrice.dailyVATAmountRounded
      );
      lowestPrice = manualPrice > 0 ? Math.min(lowestPrice, manualPrice) : lowestPrice;
    }
  });

  if (!isFinite(lowestPrice)) return 0;
  return lowestPrice;
}

export function findLowestPriceForSubClass(
  classId: number,
  subclassId: number,
  carMilliageClasses: CarMileages[] | undefined
) {
  const carSubClasses: CarMiliagesSubClass[] =
    carMilliageClasses?.find((cc) => cc.id === classId)?.subclasses || [];

  const subclass = carSubClasses.find((sc) => sc.id === subclassId);

  const manualPrice = subclass?.manualPrice?.dailyPriceExcludingVATRounded || Infinity;
  const automaticPrice = subclass?.automaticPrice?.dailyPriceExcludingVATRounded || Infinity;

  const lowestPrice = Math.min(manualPrice, automaticPrice);
  if (!isFinite(lowestPrice)) return 0;
  return lowestPrice;
}

export function isWidgetValid(userBookingData: UserBookingData): boolean {
  return (
    isValidNumber(userBookingData.pickUpLocationId) &&
    isValidNumber(userBookingData.returnLocationId) &&
    !!userBookingData.pickUpDate &&
    !!userBookingData.returnDate &&
    !!userBookingData.pickupTime &&
    !!userBookingData.returnTime
  );
}

function isValidNumber(n: number): boolean {
  return typeof n === 'number' && n > 0;
}

function calcPriceWithVAT(price: number, vat: number): number {
  return Number((price + vat).toFixed(2));
}
