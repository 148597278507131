import { graphQLAPI } from '../api/base-graphql-api';

export type GetLocationOverviewByIdQueryVariables = {
  id: number;
};

export type GetLocationOverviewByHygraphIdQueryVariables = {
  id: string;
};

export type GoLocation = GoLocationOverview & {
  id: string;
  rentLocationId: number;
  gOLocationName?: string | null;
  goLocationMapLink?: string | null;
  goLocationImportantName?: string | null;
  goLocationOpeningHoursName?: string | null;
  goLocationDirectionsName?: string | null;
  goLocationImportantInformation: Array<{ html: string }>;
  gOLocationOpeningHours: Array<{ html: string }>;
  goLocationImage: Array<{ url: string; fileName: string }>;
  goLocationDirectionsDescription: Array<{ html: string }>;
  googleMapsLinkText?: string | null;
};

export type GoLocationOverview = {
  id: string;
  rentLocationId: number;
  gOLocationName?: string | null;
  localizations: Array<{
    gOLocationName: string;
  }>;
};

export type GetLocationOverviewByIdQuery = {
  gOLocation?: GoLocation | null;
};

export const GetLocationOverviewByIdDocument = `
    query GetLocationOverviewById($id: Int!) {
  gOLocation(where: {rentLocationId: $id}) {
    id
    rentLocationId
    gOLocationName
    goLocationMapLink
    goLocationImportantName
    goLocationImportantInformation {
      html
    }
    goLocationOpeningHoursName
    gOLocationOpeningHours {
      html
    }
    goLocationImage {
      url
      fileName
    }
    goLocationDirectionsDescription {
      html
    }
    goLocationDirectionsName
    googleMapsLinkText
  }
}`;

export const GetLocationOverviewByHygraphIdDocument = `
    query GetLocationOverviewById($id: ID!) {
  gOLocation(where: {id: $id}) {
    id
    rentLocationId
    gOLocationName
    goLocationMapLink
    goLocationImportantName
    goLocationImportantInformation {
      html
    }
    goLocationOpeningHoursName
    gOLocationOpeningHours {
      html
    }
    goLocationImage {
      url
      fileName
    }
    goLocationDirectionsDescription {
      html
    }
    goLocationDirectionsName
    googleMapsLinkText
  }
}`;

const GetLocationsOverviewList = `
  query GetLocationsOverviewList {
    locations(first: 1000) {
      id
      gOLocationName,
      rentLocationId,
      localizations(locales: en) {
        gOLocationName
      }
    }
  }`;

const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetLocationOverviewById: build.query<
      GetLocationOverviewByIdQuery,
      GetLocationOverviewByIdQueryVariables
    >({
      query: (variables) => ({ document: GetLocationOverviewByIdDocument, variables })
    }),
    GetLocationOverviewByHygraphId: build.query<
      GetLocationOverviewByIdQuery,
      GetLocationOverviewByHygraphIdQueryVariables
    >({
      query: (variables) => ({ document: GetLocationOverviewByHygraphIdDocument, variables })
    }),
    GetAllLocationsOverview: build.query<GoLocationOverview[], void>({
      query: () => ({ document: GetLocationsOverviewList }),
      transformResponse: (response: { locations: GoLocationOverview[] }) => {
        return response.locations;
      }
    })
  })
});

export { injectedRtkApi as locationOverviewAPI };
export const {
  useGetAllLocationsOverviewQuery,
  useLazyGetAllLocationsOverviewQuery,
  usePrefetch,
  useGetLocationOverviewByIdQuery,
  useLazyGetLocationOverviewByIdQuery,
  useLazyGetLocationOverviewByHygraphIdQuery
} = injectedRtkApi;
