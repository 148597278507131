import { useGetAssetByIdQuery } from 'store/assets/assets.slice';
import { useGetBookingWidgetQuery } from 'store/booking-widget/booking-widget.slice';
import { setPickupLocation, setReturnLocation } from 'store/booking/booking.slice';
import {
  getCurrentWidgetInput,
  getPickupLocationId,
  getReturnLocationId
} from '../../../../store/booking/booking.selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import BookingWidgetLocationInput from '../inputs/BookingWidgetLocationInput';
import BookingLocationContainer from '../locations/BookingLocationsContainer';
import { WidgetInput } from '../widget-input.enum';

export function BookingMobileLocationContainer() {
  const currentInputWidget = useAppSelector(getCurrentWidgetInput);
  const { data: widgetConfig } = useGetBookingWidgetQuery();

  const dispatch = useAppDispatch();

  const { data: informationIcon } = useGetAssetByIdQuery({
    id: widgetConfig?.goSearchLocationInformation?.id || ''
  });

  const currentLocationId =
    currentInputWidget === WidgetInput.PICKUP_LOCATION
      ? useAppSelector(getPickupLocationId)
      : useAppSelector(getReturnLocationId);

  function clearLocation() {
    dispatch(
      currentInputWidget === WidgetInput.PICKUP_LOCATION
        ? setPickupLocation(0)
        : setReturnLocation(0)
    );
  }

  return (
    <>
      <BookingWidgetLocationInput
        placeholder={widgetConfig?.goLocationPlaceholderText || ''}
        selectedLocationId={currentLocationId || 0}
        informationIconUrl={informationIcon?.asset?.url || ''}
        disabled={false}
        onClear={clearLocation}
        isActive
      />
      <div className="mt-3">
        <BookingLocationContainer />
      </div>
    </>
  );
}
