import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import PrimaryText from 'shared/components/text/PrimaryText';
import { updateCurrentWidgetStep } from 'store/booking/booking.thunk';
import { useAppDispatch } from '../../../../store/hooks';
import SecondaryText from '../../text/SecondaryText';
import { WidgetInput } from '../widget-input.enum';

import './BookingWidgetDateTimeInput.scss';

type BookingWidgetDateTimeInputProps = {
  title: string;
  datePlaceholderText: string;
  timePlaceholderText: string;
  dateWidgetInput: WidgetInput;
  timeWidgetInput: WidgetInput;
  dateValue: string;
  timeValue: string;
  isActive: boolean;
};

const format = 'ddd, MMM D';

export default function BookingWidgetDateTimeInput(props: BookingWidgetDateTimeInputProps) {
  const dispatch = useAppDispatch();

  function formatDate(): string {
    const momentDate = moment(props.dateValue);
    return momentDate.isValid() ? momentDate.format(format) : '';
  }

  function onDateClick() {
    dispatch(updateCurrentWidgetStep(props.dateWidgetInput));
  }

  function onTimeClick() {
    dispatch(updateCurrentWidgetStep(props.timeWidgetInput));
  }

  return (
    <>
      <div className="w-12 flex-column mb-2">
        <PrimaryText className="text-base text-color-black font-semibold" text={props.title} />
      </div>
      <div
        className={`p-inputgroup booking-widget-date-time-input ${
          props.isActive ? 'border-primary border-1 border-round' : ''
        }`}>
        <InputText
          value={formatDate()}
          className="w-8"
          placeholder={props.datePlaceholderText}
          readOnly={true}
          onClick={onDateClick}
        />
        <div className="line"></div>
        <InputText
          value={props.timeValue}
          className="pr-3 text-right relative time-input w-4 flex-grow-0 flex-shrink-1"
          placeholder={props.timePlaceholderText}
          readOnly={true}
          onClick={onTimeClick}
        />
        {/* <span
          className={`p-inputgroup-addon text-xs px-3 relative ${
            props.timeValue ? 'text-black-alpha-90' : ''
          }`}
          onClick={props.onTimeClick}>
          {props.timeValue || props.timePlaceholderText}
        </span> */}
      </div>
    </>
  );
}
