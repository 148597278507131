import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUserBookingSelection from 'shared/hooks/useUserBookingSelection';
import { isWidgetValid } from 'shared/utils';
import { useGetNavigationWizardQuery } from 'store/booking-journey-wizard/booking-journey-wizard.slice';
import { setNavigationStep } from 'store/booking/booking.slice';
import { useAppDispatch } from 'store/hooks';

export default function BookingGuard({ children }: { children: JSX.Element }) {
  const { userBookingData } = useUserBookingSelection();
  const [canNavigate, setCanNavigate] = useState<boolean | undefined>(undefined);
  const navigationWizardSteps = useGetNavigationWizardQuery();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  function isValidNumber(n: number): boolean {
    return typeof n === 'number' && n >= 0;
  }

  function canActivateClasses(): boolean {
    return isWidgetValid(userBookingData);
  }

  function canActivateSubclasses(): boolean {
    return canActivateClasses() && isValidNumber(userBookingData.classId);
  }

  function canActivateOverview(): boolean {
    return canActivateSubclasses() && isValidNumber(userBookingData.subclassId);
  }

  function canActivateCheckout(): boolean {
    return canActivateOverview() && isValidNumber(userBookingData.coverageId);
  }

  useEffect(() => {
    canActivate();
  }, [location.pathname, navigationWizardSteps]);

  function canActivate() {
    let result = false;
    const steps = navigationWizardSteps.data?.steps || [];

    switch (location.pathname) {
      case '/booking/':
        result = canActivateClasses();
        // just like everything in this project, we assume the items are ordered
        // and access them by index...
        dispatch(setNavigationStep(steps[0]?.navigationWizardScreenDisplayOrder || 1));
        break;
      case '/booking/subclass':
        result = canActivateSubclasses();
        dispatch(setNavigationStep(steps[1]?.navigationWizardScreenDisplayOrder || 1));
        break;
      case '/booking/car-section-overview':
        result = canActivateOverview();
        dispatch(setNavigationStep(steps[2]?.navigationWizardScreenDisplayOrder || 1));
        break;
      case '/booking/extras':
        result = canActivateOverview();
        dispatch(setNavigationStep(steps[3]?.navigationWizardScreenDisplayOrder || 1));
        break;
      case '/booking/coverage':
        result = canActivateOverview();
        dispatch(setNavigationStep(steps[4]?.navigationWizardScreenDisplayOrder || 1));
        break;
      case '/booking/checkout/driver':
        result = canActivateCheckout();
        dispatch(setNavigationStep(steps[5]?.navigationWizardScreenDisplayOrder || 1));
        break;
      case '/booking/checkout/payment':
        result = canActivateCheckout();
        dispatch(setNavigationStep(steps[6]?.navigationWizardScreenDisplayOrder || 1));
    }

    if (!result) navigate('/');
    setCanNavigate(result);
  }

  return <>{canNavigate !== undefined && canNavigate && children}</>;
}
