import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { showErrorsMessage } from './errors/errors.slice';
import store from './store';
import i18next from '../i18/i18next';

export const reduxQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    store.dispatch(showErrorsMessage(i18next.t('COMMON.SOMETHING_WENT_WRONG')));
  }

  return next(action);
};
