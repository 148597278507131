import './PartnersComponent.scss';
import { useGetPartnersPageOverviewQuery } from 'store/homepage/homepage.slice';
import ImageComponent from 'shared/components/image/ImageComponent';
import HomeHeader from 'shared/components/header/HomeHeader';
import useGetAsset from 'shared/hooks/useGetAsset';

const PartnersComponent = () => {
  const { data: partners } = useGetPartnersPageOverviewQuery();
  const heroImage = useGetAsset(partners?.parterPageHeroImage?.id || '');

  return (
    <main className="partners">
      <HomeHeader />
      <section>
        <div
          className="relative bg-cover bg-no-repeat bg-center w-full book-car-container z-5"
          style={{ backgroundImage: `url(${heroImage.url})` }}>
          <div className="absolute  partners-title md:flex md:align-items-end md:justify-content-between">
            <h1 className="font-normal m-0 md:text-6xl text-5xl text-white">
              {partners?.partnersSectionTitle || ''}
            </h1>
          </div>
        </div>
        <div className="container flex flex-wrap justify-content-between partner-logos-container">
          {partners?.partnershipAssets?.map((image, index) => (
            <a
              href={partners.partnershipLinkURL ? partners.partnershipLinkURL[index] : ''}
              target="_blank"
              rel="noreferrer noopener"
              className=" partner-logo-container"
              key={index}>
              <div className="partner-logo">
                <ImageComponent id={image.id} />
              </div>
            </a>
          ))}
        </div>
      </section>
    </main>
  );
};

export default PartnersComponent;
