/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { CarMileages, CarMiliagesSubClass } from 'shared/models/car-class.model';
import { findLowestPriceForClass, findLowestPriceForSubClass } from 'shared/utils';
import { getRentalHours, getUserBookingInfo } from 'store/booking/booking.selectors';
import { UserBookingData } from 'store/booking/booking.slice';
import {
  CarClassOverview,
  CarSubclassOverview,
  useLazyGetCarClassesOverviewQuery
} from 'store/classes/car-classes-overview.slice';
import {
  useLazyGetClassesListQuery,
  useLazyGetClassesMiliagesQuery
} from 'store/classes/car-classes.slice';
import { useAppSelector } from 'store/hooks';
import { getCurrentLocale } from 'store/language/language.selectors';

const useBookingClassSelection = () => {
  const [getCarMilliageClasses, { data: carMilliageClasses }] = useLazyGetClassesMiliagesQuery();
  const [getCarClassesOverview, { data: carClassesOverview }] = useLazyGetCarClassesOverviewQuery();
  const [getCarClasses, { data: carClasses }] = useLazyGetClassesListQuery();
  const hours = useAppSelector(getRentalHours, shallowEqual);
  const currentLanguage = useAppSelector(getCurrentLocale);
  const [sortedClassesOverview, setSortedClassesOverview] = useState<CarClassOverview[]>([]);
  const userBookingData: UserBookingData = useAppSelector(getUserBookingInfo, shallowEqual);

  // console.log('carMilliageClasses:', carMilliageClasses);

  useEffect(() => {
    if (carClassesOverview && carMilliageClasses) {
      const sortedClasses =
        [...carMilliageClasses]
          .sort((a, b) => {
            return findLowestPriceForClass(a.id, carMilliageClasses) <
              findLowestPriceForClass(b.id, carMilliageClasses)
              ? -1
              : 1;
          })
          .map((carClass: CarMileages) => {
            return carClass.id;
          }) || [];

      const sortedClassesOverview: CarClassOverview[] = [];
      sortedClasses.forEach((cId) => {
        const nextInOrderOverview = carClassesOverview.find(
          (overview) => overview.rentClassId === cId
        );
        if (nextInOrderOverview) {
          sortedClassesOverview.push(nextInOrderOverview);
        }
      });
      setSortedClassesOverview(sortedClassesOverview);
    }
  }, [carClassesOverview, carMilliageClasses]);

  useEffect(() => {
    getCarClasses(undefined, true);
  }, []);

  useEffect(() => {
    if (hours) {
      getCarMilliageClasses(
        {
          hours: hours,
          pickUpLocationId: userBookingData.pickUpLocationId,
          returnLocationId: userBookingData.returnLocationId,
          includeLocationTax: true
        },
        false
      );
    }
  }, [hours, userBookingData]);

  useEffect(() => {
    if (carMilliageClasses) {
      const ids =
        carMilliageClasses.map((carClass: CarMileages) => {
          return carClass.id;
        }) || [];
      getCarClassesOverview({ ids: ids }, false);
    }
  }, [carMilliageClasses, currentLanguage]);

  function sortSubclassesByPrice(classId: number, carSubclasses: CarSubclassOverview[]) {
    const sorted = [...carSubclasses].sort((a, b) =>
      findLowestPriceForSubClass(classId, a.rentSubclassId, carMilliageClasses) <
        findLowestPriceForSubClass(classId, b.rentSubclassId, carMilliageClasses)
        ? -1
        : 1
    );
    return sorted;
  }

  return { carMilliageClasses, carClasses, sortedClassesOverview, hours, sortSubclassesByPrice };
};
export default useBookingClassSelection;
