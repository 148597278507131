import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import ImageComponent from 'shared/components/image/ImageComponent';
import useIsMobile from 'shared/hooks/useIsMobile';
import { setLocationSearchQuery } from '../../../../store/booking/booking.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getLocationOverviewNameById } from '../../../../store/locations/locations.selectors';
import { openLocationOverviewDialog } from '../../../../store/modals/modals.slice';

import './BookingWIdgetLocationInput.scss';

type BookingWidgetLocationInput = {
  placeholder: string;
  selectedLocationId: number;
  informationIconUrl: string;
  onClear?: () => void;
  onClick?: () => void;
  isActive?: boolean;
  locationImgUrl?: string;
  disabled?: boolean;
};

function BookingWidgetLocationInput({
  locationImgUrl,
  placeholder,
  selectedLocationId,
  informationIconUrl,
  onClick,
  onClear,
  isActive,
  disabled
}: BookingWidgetLocationInput) {
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { isMobile } = useIsMobile();
  const locationName = useAppSelector(
    getLocationOverviewNameById(selectedLocationId),
    shallowEqual
  );

  useEffect(() => {
    dispatch(setLocationSearchQuery(inputValue));
  }, [inputValue]);

  function openLocationOverview(event: React.MouseEvent<HTMLImageElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();
    dispatch(openLocationOverviewDialog(selectedLocationId));
  }

  function onInputClear(): void {
    setInputValue('');

    if (onClear) {
      onClear();
    }
  }

  return (
    <div
      className={`p-inputgroup booking-widget-location-input-container ${
        isActive || isInputFocused ? 'border-1 border-primary border-round' : ''
      }`}
      onClick={() => (onClick ? onClick() : null)}>
      {locationImgUrl && (
        <span className="p-inputgroup-addon">
          <span className="icon-img">
            <ImageComponent id={locationImgUrl} width="20px" height="20px" />
          </span>
        </span>
      )}

      <InputText
        className="w-full text-color-black"
        placeholder={placeholder}
        onChange={(e) => setInputValue(e.target.value)}
        value={locationName || inputValue}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        disabled={disabled === undefined ? isMobile : disabled}
      />

      <span className={`p-inputgroup-addon`}>
        {isActive && onClear && (
          <i className="pi pi-times cursor-pointer" onClick={() => onInputClear()}></i>
        )}
        {!isActive && locationName && (
          <span className="icon-img">
            <img
              src={informationIconUrl}
              className="icon-img"
              onClick={(e) => openLocationOverview(e)}
            />
          </span>
        )}
      </span>
    </div>
  );
}

export default React.memo(BookingWidgetLocationInput);
