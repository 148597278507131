import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';
import { updateCurrentWidgetStep } from 'store/booking/booking.thunk';
import { setCurrentWidgetInput } from '../../../store/booking/booking.slice';
import { useAppDispatch } from '../../../store/hooks';
import useModal from '../../hooks/useModal';
import { WidgetInput } from '../booking-widget/widget-input.enum';

import './MobileWidgetInput.scss';

export default function MobileWidgetInput({ children }: { children: JSX.Element }) {
  const { isShowing, toggle } = useModal();
  const dispatch = useAppDispatch();

  const getMobileWidgetInputHeader = (): JSX.Element => {
    return <h3 className="flex text-lg font-semibold m-0">SELECT A LOCATION</h3>;
  };

  useEffect(() => {
    toggle();
  }, []);

  function onHide() {
    toggle();
    dispatch(updateCurrentWidgetStep(WidgetInput.NONE));
  }

  return (
    <>
      <Dialog
        visible={isShowing}
        maximized={true}
        focusOnShow={false}
        resizable={false}
        draggable={false}
        modal={true}
        position={'bottom'}
        className="m-0 border-none"
        headerClassName="mobile-widget-input-header"
        closeOnEscape={false}
        dismissableMask={false}
        header={getMobileWidgetInputHeader()}
        onHide={() => onHide()}>
        <>{children}</>
      </Dialog>
    </>
  );
}
