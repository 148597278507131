import { GraphQLClient } from 'graphql-request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import configService from 'shared/config.service';

// TODO: Token in .env
export const client = new GraphQLClient(configService.graphQlUrl, {
  headers: {
    Authorization: `Bearer ${configService.graphQlToken}`
    // 'gcms-locales': localStorage.getItem('locale') || ''
  }
});

export const generateApiWithReducerPath = (reducerPath: string) => {
  return createApi({
    reducerPath: reducerPath,
    baseQuery: graphqlRequestBaseQuery({ client }),
    endpoints: () => ({})
  });
};

export const graphQLAPI = createApi({
  reducerPath: 'graphQL',
  baseQuery: graphqlRequestBaseQuery({ client }),
  endpoints: () => ({})
});
