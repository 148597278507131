import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';
import fr from './fr.json';

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    de: { translation: de },
    fr: { translation: fr }
  },
  lng: 'de',
  fallbackLng: 'de',

  interpolation: {
    escapeValue: false
  }
});

export default i18next;
