import { Divider } from 'primereact/divider';
import { shallowEqual } from 'react-redux';
import { useGetBookingWidgetQuery } from '../../../../store/booking-widget/booking-widget.slice';
import {
  getCurrentWidgetInput,
  getPickupReturnDateTime
} from '../../../../store/booking/booking.selectors';
import { useAppSelector } from '../../../../store/hooks';
import { WidgetInput } from '../widget-input.enum';
import BookingWidgetDateTimeInput from './BookingWidgetDateTimeInput';
import './BookingWidgetDateTimeContainer.scss';

export default function BookingWidgetDateTimeContainer() {
  const { data: widgetConfig } = useGetBookingWidgetQuery();
  const pickupReturnDateTime = useAppSelector(getPickupReturnDateTime, shallowEqual);
  const activeWidget = useAppSelector(getCurrentWidgetInput, shallowEqual);

  return (
    <div className="flex md:flex-row">
      <div className="flex-1 mr-3 md:mr-0 mt-auto">
        <section className="flex flex-column pickup-input">
          <BookingWidgetDateTimeInput
            title={widgetConfig?.goPickUpLocation || ''}
            datePlaceholderText={'Date'} // TODO: Remove this and extract it to the input itself
            timePlaceholderText={'Time'} // TODO: Remove this and extract it to the input itself
            dateValue={pickupReturnDateTime.pickupDate}
            timeValue={pickupReturnDateTime.pickupTime}
            dateWidgetInput={WidgetInput.PICKUP_DATE}
            timeWidgetInput={WidgetInput.PICKUP_TIME}
            isActive={
              activeWidget === WidgetInput.PICKUP_DATE || activeWidget === WidgetInput.PICKUP_TIME
            }
          />
        </section>
      </div>
      <Divider layout="vertical" className="p-0 booking-widget-vertical-divider hidden md:flex" />
      <div className="flex-1  mt-auto">
        <section className="flex flex-column">
          <BookingWidgetDateTimeInput
            title={widgetConfig?.goReturnLocationName || ''}
            datePlaceholderText={'Date'}
            timePlaceholderText={'Time'}
            dateValue={pickupReturnDateTime.returnDate}
            timeValue={pickupReturnDateTime.returnTime}
            dateWidgetInput={WidgetInput.RETURN_DATE}
            timeWidgetInput={WidgetInput.RETURN_TIME}
            isActive={
              activeWidget === WidgetInput.RETURN_DATE || activeWidget === WidgetInput.RETURN_TIME
            }
          />
        </section>
      </div>
    </div>
  );
}
