import { graphQLAPI } from '../api/base-graphql-api';

type GetCoveragesOverviewQuery = {
  coverages: Array<CarCoveragesOverview>;
};

export type CarCoveragesOverview = {
  id: string;
  rentCoverageId: number;
  coveragedisplayOrder: number;
  coverageName: string;
  coverageTag: { id: string };
  coveragePrice: string;
  coverageShortDescription: string;
  coverageYouPay: string;
  coverageDeductible: string;
  coverageFeatures: Array<{ html: string }>;
  coverageFeatureIcon: Array<{ id: string }>;
  localizations: Array<{
    coverageName: string;
  }>;
};

const GetCoveragesOverviewDocument = `
  query GetCoveragesOverviewQuery() {
    coverages(orderBy: coveragedisplayOrder_ASC) {
      id
      rentCoverageId,
      coveragedisplayOrder
      coverageName
      coverageTag {
        id
      }
      coveragePrice
      coverageShortDescription
      coverageYouPay
      coverageDeductible
      coverageFeatures {
        html
      }
      coverageFeatureIcon {
        id
      }
      localizations(locales: en) {
        coverageName
      }
    }
  }
`;

const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetCoveragesOverview: build.query<CarCoveragesOverview[], void>({
      query: () => ({ document: GetCoveragesOverviewDocument }),
      transformResponse: (response: GetCoveragesOverviewQuery) => {
        return response.coverages;
      },
      keepUnusedDataFor: 100000000
    })
  })
});

export { injectedRtkApi as coveragesOverview };
export const { useGetCoveragesOverviewQuery, usePrefetch } = injectedRtkApi;
