import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const getModals = (state: RootState) => state.modals;

export const getLocationOverviewModal = createSelector(
  [getModals],
  (modals) => modals.locationOverview
);

export const getBookingFlowModal = createSelector(
  [getModals],
  (modals) => modals.bookingFlowMobileInputs.opened
);

export const getDownloadModal = createSelector(
  [getModals],
  (modals) => modals.downloadAppOverview.opened
);

export const getLegalsOverviewModal = createSelector(
  [getModals],
  (modals) => modals.legalsOverview
);
