import { Location, LocationCoordinates } from '../../models/location.model';
import OpenLocationCode from 'open-location-code-typescript';

const sortByName = (locationsList: Location[] = []): Location[] => {
  return (
    locationsList?.slice().sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    }) || []
  );
};

const sortByDistance = (locationsList: Location[] = []): Location[] => {
  return locationsList.slice().sort((a, b) => a.distanceToUserLocation - b.distanceToUserLocation);
};

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

const parseGooglePlusCode = (googlePlusCode: string): LocationCoordinates => {
  const decoded = OpenLocationCode.decode(googlePlusCode);
  return {
    latitude: decoded.latitudeCenter,
    longitude: decoded.longitudeCenter
  };
};

const calculateDistanceForLocations = (
  locationsList: Location[] | undefined,
  userPosition: LocationCoordinates | null
) => {
  if (!locationsList) return [];
  const userPositionLat = userPosition?.latitude;
  const userPositionLon = userPosition?.longitude;

  if (userPositionLat && userPositionLon) {
    return locationsList.map((location: Location) => {
      const targetLat = location.geoLocation?.latitude || 0;
      const targetLon = location.geoLocation?.longitude || 0;
      location.distanceToUserLocation = getDistanceFromLatLonInKm(
        userPositionLat,
        userPositionLon,
        targetLat,
        targetLon
      );
      return location;
    });
  }
};

export const LocationsUtils = {
  sortByName,
  sortByDistance,
  calculateDistanceForLocations,
  parseGooglePlusCode
};
