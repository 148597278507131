import { Skeleton } from 'primereact/skeleton';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingWidgetController from 'shared/components/booking-widget/BookingWidgetController';
import BookingWidgetInputsContainer from 'shared/components/booking-widget/inputs/BookingWidgetInputsContainer';
import { WidgetInput } from 'shared/components/booking-widget/widget-input.enum';
import useIsMobile from 'shared/hooks/useIsMobile';
import useIsWidgetValid from 'shared/hooks/useIsWidgetValid';
import { useGetBookingWidgetQuery } from 'store/booking-widget/booking-widget.slice';
import { emitStateToTagManager, updateCurrentWidgetStep } from 'store/booking/booking.thunk';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isBookingWidgetOpen } from '../../../store/booking/booking.selectors';
import { usePrefetch as bookingWidgetPrefetch } from 'store/booking-widget/booking-widget.slice';
import { discardBookingChanges } from 'store/booking/booking.slice';
import { AnalyticsStep } from 'shared/models/analytics-step.model';

const BookingWidget = ({ compact }: { compact?: boolean }) => {
  const isOpenWidget = useAppSelector(isBookingWidgetOpen);
  const { isMobile } = useIsMobile();
  const { validateWidget, isAlreadyPrefilledVehicle } = useIsWidgetValid();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: widgetConfig, isLoading } = useGetBookingWidgetQuery();
  const prefetchCalendarConfig = bookingWidgetPrefetch('GetBookingCalendar');
  // Needed to properly display backdrop for modal
  const appRootElement: Element = document.getElementsByClassName('App')[0];

  useEffect(() => {
    prefetchCalendarConfig();
  }, []);

  const isDesktopOpened = useMemo(() => {
    return isOpenWidget && !isMobile;
  }, [isOpenWidget, isMobile]);

  function closeWidget() {
    dispatch(discardBookingChanges());
    dispatch(updateCurrentWidgetStep(WidgetInput.NONE));
  }

  useEffect(() => {
    if (appRootElement) {
      if (isOpenWidget && !isMobile) {
        appRootElement.classList.add('open');
      } else if (!isOpenWidget || isMobile) {
        appRootElement.classList.remove('open');
      }
    }
  }, [isOpenWidget, isMobile]);

  const submit = useCallback(() => {
    if (validateWidget()) {
      dispatch(updateCurrentWidgetStep(WidgetInput.NONE));
      dispatch(emitStateToTagManager(AnalyticsStep.STEP_1));
      if (isAlreadyPrefilledVehicle()) {
        navigate('/booking/extras');
      } else {
        navigate('/booking/');
      }
    }
    // if (isWidgetValid) navigate('/booking/');
  }, [validateWidget]);

  return (
    <>
      <div
        className={`overlay ${
          isDesktopOpened ? 'open absolute opacity-40 surface-900 left-0 right-0 h-screen' : ''
        }`}></div>
      <div
        className={`widget-container bg-white ${
          isDesktopOpened
            ? 'open absolute w-full top-0 right-0 left-0 translate-x-0 translate-y-0 h-screen'
            : 'left-50 border-round-xl'
        }${!compact ? ' pt-3 pb-5 px-4' : ''}`}>
        <div
          className={`widget-inner-container bg-white ${isDesktopOpened ? 'container' : ''}${
            !compact ? '' : ' container'
          }`}>
          {isLoading ? (
            <Skeleton width="100%" height="120px" />
          ) : (
            <>
              <div className="flex align-items-center justify-content-between mb-2">
                {/* {!compact && (
                  <h3 className="-ml-4 -translate-y-100 absolute font-normal m-0 md:text-6xl pb-2 text-5xl text-white top-0">
                    {widgetConfig?.bookingWidgetTitleWebapp}
                  </h3>
                )} */}
                <i
                  className={`${isDesktopOpened ? '' : 'hidden'} pi pi-times cursor-pointer`}
                  onClick={closeWidget}
                />
              </div>
              <div className="flex align-items-center flex-column md:flex-row">
                <BookingWidgetInputsContainer
                  submitButtonText={
                    !compact
                      ? widgetConfig?.goSearchButton
                        ? widgetConfig?.goSearchButton[0]
                        : ''
                      : 'MAKE CHANGES'
                  }
                  submitButtonClass="flex  md:ml-5 md:mt-0 mt-3 homepage-submit align-self-end"
                  displaySubmitOnOpenedOnly={compact}
                  submitCallback={submit}
                />
              </div>
              <div className="flex">
                <BookingWidgetController />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingWidget;
