import { createSelector } from '@reduxjs/toolkit';
import { WebAppNavigationSelection } from 'generated/graphql';
import { RootState } from '../store';
import { NavigationModel, navigationsOverview } from './navigations-overview.slice';

const navigations = navigationsOverview.endpoints.GetNavigationsOverview.select();

type ReturnNavigation = (state: RootState) => NavigationModel | null;

export const getNavigationByViewportSizeId = (id: WebAppNavigationSelection): ReturnNavigation =>
  createSelector([navigations], (navs) => {
    return (
      navs?.data?.find((nav: NavigationModel) => nav.navigationComponentViewportSize === id) || null
    );
  });

export const getDataProtectionPolicy = createSelector(
  [getNavigationByViewportSizeId(WebAppNavigationSelection.Footer)],
  (footer) => (Array.isArray(footer?.legals) ? footer?.legals[1] : null)
);
