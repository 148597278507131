import { Calendar, CalendarViewChangeParams } from 'primereact/calendar';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import moment, { Moment } from 'moment-timezone';

import { getPickupAndReturnDates, getUnavailableDays } from 'store/booking/booking.selectors';
import { getCurrentLocale } from 'store/language/language.selectors';

import './BookingCalendar.scss';
import { useGetBookingCalendarQuery } from 'store/booking-widget/booking-widget.slice';
import { localeOptions, addLocale, updateLocaleOptions, locale } from 'primereact/api';
import { updatePickupAndReturnDate } from 'store/booking/booking.thunk';
import { setPickupAndReturnDate } from 'store/booking/booking.slice';

const DEFAULT_CALENDARS_COUNT = 1;

const dateNow = moment();
const utcMinDate = dateNow.clone().add(1, 'day').set({ h: 0, m: 0, s: 0 });
const utcMaxDate = dateNow.clone().add(1, 'year').set({ h: 0, m: 0, s: 0 });
const minDate = utcMinDate.toDate();
const maxDate = utcMaxDate.toDate();
const minVisibleMonth = utcMinDate.startOf('month');
const maxVisibleMonth = utcMaxDate.startOf('month');

type BookingCalendarProps = {
  calendarsCount?: number;
};

export default function BookingCalendar({ calendarsCount }: BookingCalendarProps) {
  const dispatch = useAppDispatch();
  const container = useRef(null);
  const { data: calendar } = useGetBookingCalendarQuery();
  const currentLanguage = useAppSelector(getCurrentLocale);
  const pickupAndReturnDates = useAppSelector(getPickupAndReturnDates);
  const unavailableDays = useAppSelector(getUnavailableDays);

  const [dates, setDates] = useState<Date | Date[] | undefined>(undefined);
  const [disabledDays, setDisabledDays] = useState<Date[]>([]);
  const [currentMinDate, setCurrentMinDate] = useState<Date>(minDate);

  // First date that is shown in the left-most date picker
  const [viewDate, setViewDate] = useState(minVisibleMonth);

  useEffect(() => {
    if (!dates || (Array.isArray(dates) && !dates.length)) {
      setDates([
        new Date(pickupAndReturnDates.pickupDate),
        new Date(pickupAndReturnDates.returnDate)
      ]);
    }
  }, [dates, pickupAndReturnDates]);

  useEffect(() => {
    if (dates && Array.isArray(dates) && dates[0] && dates[1]) {
      setCurrentMinDate(minDate);
      setDisabledDays(unavailableDays.unavailablePickupDays.map((date: string) => new Date(date)));
    } else {
      if (dates && Array.isArray(dates)) {
        setCurrentMinDate(dates[0]);
      }
      setDisabledDays(unavailableDays.unavailableReturnDays.map((date: string) => new Date(date)));
    }
  }, [unavailableDays, dates]);

  useEffect(() => {
    if (calendar) {
      const weekdaysShort = [...calendar.weekdaysAbbreviation];
      weekdaysShort.unshift(weekdaysShort.pop() || '');
      const calendarOptions = {
        dayNamesMin: weekdaysShort,
        firstDayOfWeek: 1,
        monthNames: calendar.month,
        // arr.unshift(arr.pop())
        dayNamesShort: calendar.inputFieldWeekdayShort
      };

      if (!localeOptions(currentLanguage)) {
        addLocale(currentLanguage, localeOptions('en'));
      }
      // update locale options
      updateLocaleOptions(calendarOptions, currentLanguage);
      locale(currentLanguage);
      // Set as active locale
      moment.locale(currentLanguage);
    }
  }, [calendar]);

  function onViewDateChange(e: CalendarViewChangeParams): void {
    const calendarViewDate: Moment = moment(e.value);

    if (calendarViewDate.isSameOrBefore(minVisibleMonth)) {
      setViewDate(moment(minDate));
    } else if (calendarViewDate >= maxVisibleMonth) {
      setViewDate(maxVisibleMonth);
    } else {
      setViewDate(calendarViewDate);
    }
  }

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  function onDateSelect(selectedDates: Date | Date[] | undefined) {
    if (Array.isArray(selectedDates)) {
      const pickupDate = selectedDates[0];
      const returnDate = selectedDates[1];
      setDates(selectedDates);
      dispatch(
        updatePickupAndReturnDate({
          pickupDate: formatDate(pickupDate),
          returnDate: formatDate(returnDate)
        })
      );
    }
    // if (dates && Array.isArray(dates) && dates[1]) {
    //   dispatch(
    //     updatePickupAndReturnDate({
    //       pickupDate: formatDate(moment(dates[0])),
    //       returnDate: formatDate(moment(dates[1]))
    //     })
    //   );
    // } else {
    //   setDates(dates);
    // }
  }

  function formatDate(date: Date): string {
    const d = date
      ? moment(
          date.getFullYear() +
            '-' +
            padTo2Digits(date.getMonth() + 1) +
            '-' +
            padTo2Digits(date.getDate())
        ).format('YYYY-MM-DD')
      : '';
    return d;
  }

  return (
    <div ref={container} className="flex flex-1 max-w-full">
      <Calendar
        locale={localeOptions(currentLanguage) ? currentLanguage : 'en'}
        inputStyle={{ display: 'none' }}
        visible={true}
        inline={true}
        numberOfMonths={calendarsCount || DEFAULT_CALENDARS_COUNT}
        appendTo={'self'}
        className={`date-picker-widget w-full ${
          viewDate.isSameOrBefore(minDate) ? 'disabled-back-button' : ''
        } ${viewDate.isSameOrAfter(maxVisibleMonth) ? 'disabled-next-button' : ''}`}
        value={dates}
        showOtherMonths={false}
        disabledDates={disabledDays}
        onChange={(dates) => onDateSelect(dates.value)}
        selectionMode="range"
        minDate={currentMinDate}
        maxDate={maxDate}
        viewDate={viewDate.toDate()}
        panelClassName="booking-calendar"
        onViewDateChange={(e) => onViewDateChange(e)}
      />
    </div>
  );
}
