import { Moment } from 'moment';

const MIMINUM_BOOKING_TIME = 6; // in hours

const BookingValidationService = {
  isLessThanMinimumBookingTime: (pickupDate: Moment, returnDate: Moment) => {
    const diffInDays = returnDate.diff(pickupDate, 'minutes');
    return Math.trunc(diffInDays / 60) < MIMINUM_BOOKING_TIME;
  }
};

export default BookingValidationService;
