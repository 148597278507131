import { Button } from 'primereact/button';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import './PrimaryButton.scss';
type PrimaryButtonProps = {
  text: string;
  className?: string;
  rounded?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

export default function PrimaryButton({
  className,
  rounded,
  text,
  onClick,
  disabled
}: PrimaryButtonProps) {
  // const { clickEvent } = useGoogleTagPageEvents();

  function buttonClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <Button
      onClick={() => buttonClick()}
      label={text}
      className={`primary-button border-none ${rounded ? 'p-button-rounded' : ''} ${className}`}
      disabled={disabled}
    />
  );
}
