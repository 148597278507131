import { RefObject, useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { getPickupAndReturnTimes, getTimes } from '../../../../store/booking/booking.selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import useIsMobile from '../../../hooks/useIsMobile';
import SecondaryText from '../../text/SecondaryText';

import './BookingTime.scss';
import BookingLocationInformation from '../locations/BookingLocationInformation';
import { useGetBookingWidgetQuery } from 'store/booking-widget/booking-widget.slice';
import { setPickupTime, setReturnTime } from 'store/booking/booking.slice';

const MAX_ITEMS_VISIBLE = 3;
const MOBILE_TIME_ITEM_HEIGHT = 30;
const MOBILE_TIME_ITEM_STYLE = {
  minHeight: `${MOBILE_TIME_ITEM_HEIGHT}px`
};
const MOBILE_SCROLL_TIMEOUT = 600;

// TODO: This component needs a lot of refactoring
// come back if there is time to rethink the functionality
export function BookingTime() {
  const dispatch = useAppDispatch();
  const selectedTimes = useAppSelector(getPickupAndReturnTimes);
  const { data: widgetConfig } = useGetBookingWidgetQuery();
  const times = useAppSelector(getTimes, shallowEqual);

  const pickupTimeRef = useRef<HTMLDivElement>(null);
  const returnTimeRef = useRef<HTMLDivElement>(null);

  let pickupTimeTimeout: NodeJS.Timeout;
  let returnTimeTimeout: NodeJS.Timeout;

  const { isMobile } = useIsMobile();

  const formatPickupTimes = useMemo(() => {
    return formatTimes('pickup', updatePickupTime, selectedTimes.pickupTime, times.pickupTimes);
  }, [selectedTimes.pickupTime, times]);

  const formatReturnTimes = useMemo(() => {
    return formatTimes('return', updateReturnTime, selectedTimes.returnTime, times.returnTimes);
  }, [selectedTimes.returnTime, times]);

  function formatTimes(
    type: string,
    onClickCallback: (time: string) => void,
    selectedTime: string,
    times: Array<string>
  ) {
    return (
      <>
        {times.map((t) => (
          <div
            className="flex font-semibold time-item m-auto md:pl-2 md:w-full align-items-center"
            style={MOBILE_TIME_ITEM_STYLE}
            onClick={() => (!isMobile ? onClickCallback(t) : null)}
            key={`${type}-${t}`}>
            <p
              className={`flex md:pl-3 w-full h-full text-lg m-0 md:text-base md:align-items-center ${
                selectedTime === t ? 'active' : ''
              }`}>
              {t}
            </p>
          </div>
        ))}
      </>
    );
  }

  function updatePickupTime(time: string): void {
    dispatch(setPickupTime(time));
  }

  function updateReturnTime(time: string): void {
    dispatch(setReturnTime(time));
  }

  function setInitialOffsetForMobile(
    ref: RefObject<HTMLDivElement>,
    value: string,
    times: Array<string>
  ) {
    const offsetPx = times.findIndex((time: string) => time === value) * MOBILE_TIME_ITEM_HEIGHT;
    ref.current?.scroll({ top: offsetPx });
  }

  useEffect(() => {
    if (isMobile) {
      setInitialOffsetForMobile(pickupTimeRef, selectedTimes.pickupTime, times.pickupTimes);
      setInitialOffsetForMobile(returnTimeRef, selectedTimes.returnTime, times.pickupTimes);
    }
  }, []);

  function handleScroll(ref: RefObject<HTMLDivElement>, callback: (value: string) => void) {
    if (ref) {
      const current = ref?.current;
      const docViewTop = current?.scrollTop || 0;
      const docViewBottom = docViewTop + (current?.clientHeight || 0);
      const children = [].slice.call(current?.children || []) as HTMLElement[];

      const valid = children.filter((item) => {
        const elemTop = item.offsetTop - MOBILE_TIME_ITEM_HEIGHT; // because of the padding for top/bottom
        const elemBottom = elemTop + item.clientHeight;

        return elemBottom <= docViewBottom && elemTop >= docViewTop;
      });

      if (valid.length === 1) {
        callback(valid[0]?.children.item(0)?.innerHTML || '');
      } else {
        callback(valid[1]?.children.item(0)?.innerHTML || '');
      }
    }
  }

  function handlePickupTimeScroll() {
    if (!isMobile) return;
    clearTimeout(pickupTimeTimeout);
    pickupTimeTimeout = setTimeout(() => {
      handleScroll(pickupTimeRef, updatePickupTime);
    }, MOBILE_SCROLL_TIMEOUT);
  }

  function handleReturnTimeScroll() {
    if (!isMobile) return;
    clearTimeout(returnTimeTimeout);
    returnTimeTimeout = setTimeout(() => {
      handleScroll(returnTimeRef, updateReturnTime);
    }, MOBILE_SCROLL_TIMEOUT);
  }

  return (
    <div className="flex w-full flex-row">
      <div className="w-8 flex-column hidden md:flex mr-5">
        <BookingLocationInformation />
      </div>
      <div className="flex w-full md:w-4 md:justify-content-start h-9rem md:h-auto relative">
        <section className="flex flex-column w-6 md:justify-content-start z-2 md:mr-5">
          <SecondaryText
            text={widgetConfig?.goPickUpLocation}
            className={'uppercase text-lg pb-3 justify-content-center text-center md:text-left'}
          />
          <div
            ref={pickupTimeRef}
            className="flex flex-column w-full h-30rem scroll-panel overflow-y-scroll time-select-container"
            style={{
              maxHeight: isMobile ? `${MOBILE_TIME_ITEM_HEIGHT * MAX_ITEMS_VISIBLE}px` : 'auto',
              paddingTop: isMobile ? MOBILE_TIME_ITEM_STYLE.minHeight : 0,
              paddingBottom: isMobile ? MOBILE_TIME_ITEM_STYLE.minHeight : 0
            }}
            onScroll={handlePickupTimeScroll}>
            {formatPickupTimes}
          </div>
        </section>
        <section className="flex flex-column w-6 md:justify-content-start z-2">
          <SecondaryText
            text={widgetConfig?.goReturnLocationName}
            className={'uppercase text-lg pb-3 justify-content-center text-center md:text-left'}
          />
          <div
            ref={returnTimeRef}
            className="flex flex-column w-full h-30rem scroll-panel overflow-y-scroll time-select-container"
            style={{
              maxHeight: isMobile ? `${MOBILE_TIME_ITEM_HEIGHT * MAX_ITEMS_VISIBLE}px` : 'auto',
              paddingTop: isMobile ? MOBILE_TIME_ITEM_STYLE.minHeight : 0,
              paddingBottom: isMobile ? MOBILE_TIME_ITEM_STYLE.minHeight : 0
            }}
            onScroll={handleReturnTimeScroll}>
            {formatReturnTimes}
          </div>
        </section>
        <div
          className="md:hidden mobile-time-selection-overlay absolute w-full h-2rem z-1"
          style={MOBILE_TIME_ITEM_STYLE}></div>
      </div>
    </div>
  );
}
