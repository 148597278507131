import { Accordion, AccordionTab } from 'primereact/accordion';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './HomeHeaderMobile.scss';
import { Locale, WebAppNavigationSelection } from 'generated/graphql';
import {
  NavigationModel,
  useGetContactsOverviewQuery
} from 'store/navigations/navigations-overview.slice';
import { getNavigationByViewportSizeId } from 'store/navigations/navigations.selectors';
import ImageComponent from '../image/ImageComponent';
import { useLayoutEffect, useMemo, useState } from 'react';
import { HomeSections } from 'components/home/home-sections';
import PrimaryButton from '../primary-button/PrimaryButton';
import { Button } from 'primereact/button';
import useIsMobile from 'shared/hooks/useIsMobile';
import DesktopLogo from './logo/DesktopLogo';
import { shallowEqual } from 'react-redux';
import { getCurrentLocale } from 'store/language/language.selectors';
import { updateCurrentLanguage } from 'store/language/language.thunk';
import { Skeleton } from 'primereact/skeleton';
import { openDownloadAppOverview, openLegalsOverviewDialog } from 'store/modals/modals.slice';
import { useNavigate } from 'react-router-dom';

const mapLanguages = (langNames: Array<string>) => {
  const locales: Array<string> = Object.values(Locale);
  const languages = locales.map((langKey, index) => {
    return {
      key: langKey,
      name: langNames[index]
    };
  });
  return languages;
};

function scrollToSection(section: string) {
  document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
}

const HomeHeaderMobile = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = useAppSelector(getCurrentLocale);
  const dispatch = useAppDispatch();
  const { data: contacts, isFetching: isFetchingContacts } = useGetContactsOverviewQuery();
  const appRootElement: Element = document.getElementsByClassName('App')[0];
  const { headerMobile } = useIsMobile();
  const navigate = useNavigate();
  const navMobile: NavigationModel | null = useAppSelector(
    getNavigationByViewportSizeId(WebAppNavigationSelection.MobileMenu)
  );
  const mobileHeader: NavigationModel | null = useAppSelector(
    getNavigationByViewportSizeId(WebAppNavigationSelection.MobileHeader)
  );
  const getCurrentLanguageName = useMemo(() => {
    const languages = mapLanguages(navMobile?.languageSetting || []);
    return languages.find((lang) => lang.key === currentLanguage)?.name || '';
  }, [currentLanguage, navMobile]);

  function onMenuClick() {
    setIsOpen(false);
  }

  useLayoutEffect(() => {
    if (appRootElement) {
      if (isOpen && headerMobile) {
        appRootElement.classList.add('open');
      } else if (!isOpen || !headerMobile) {
        appRootElement.classList.remove('open');
        setIsOpen(false);
      }
    }
  }, [isOpen, headerMobile]);

  function openLegalModal(title: string, content: string) {
    dispatch(openLegalsOverviewDialog({ title: title, content: content }));
  }

  return !isFetchingContacts ? (
    <div className="container justify-content-between align-items-center flex">
      <div className="">
        <Button
          icon={`pi ${isOpen ? 'pi-times' : 'pi-bars'}`}
          className="p-button-rounded p-button-text text-900 menu-button border-0 shadow-none bg-white"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <div
        className={`${
          isOpen
            ? 'bg-white fixed flex h-screen left-0 right-0 top-0 pl-7 pt-4 mobile-menu overflow-scroll'
            : 'hidden'
        }`}>
        <div>
          <DesktopLogo />
          <div className="block lg:hidden font-semibold  mt-4 text-xs">
            {navMobile?.languageSettingTitle}
          </div>
          <Accordion>
            <AccordionTab
              header={getCurrentLanguageName}
              headerClassName="lang-selection text-xs mt-1"
              contentClassName="lang-options">
              {navMobile?.languageSetting
                ? mapLanguages(navMobile.languageSetting)
                    .filter((lang) => lang.key !== currentLanguage)
                    .map((lang) => (
                      <a
                        className="block text-xs mt-2"
                        key={lang.key}
                        onClick={(e) => {
                          onMenuClick();
                          dispatch(updateCurrentLanguage(lang.key as Locale));
                        }}>
                        {lang.name}{' '}
                      </a>
                    ))
                : ''}
            </AccordionTab>
          </Accordion>
          <div className="block lg:hidden font-semibold  mt-4 text-xs">
            {navMobile?.siteNavigationItemsTitle}
          </div>
          {navMobile?.siteNavigationItems?.map((item, index) => (
            <a
              key={index}
              className="mt-2 block text-xs"
              onClick={() => {
                onMenuClick();
                props.menuClick(index);
              }}>
              <div>{item}</div>
            </a>
          ))}
          <div className="block lg:hidden font-semibold  mt-4 text-xs">
            {navMobile?.policyNavigationItemsTitle}
          </div>
          {navMobile?.legals?.map((item, index) => (
            <a
              key={index}
              className="mt-2 block text-xs"
              onClick={() => {
                if (index === 0) {
                  navigate('/privacy/' + currentLanguage);
                } else {
                  openLegalModal(item.staticContentName || '', item.staticContentBody?.html || '');
                }
              }}>
              <div>{item.staticContentName}</div>
            </a>
          ))}
          <div className="block lg:hidden font-semibold  mt-4 text-xs">
            {navMobile?.contactNavigationItemsTitle}
          </div>
          {navMobile?.contactNavigationItems?.map((item, index) => (
            <a
              key={index}
              className="mt-2 block text-xs"
              href={
                index
                  ? `tel:${contacts?.customerServiceNumber}`
                  : `mailto:${contacts?.customerEmail}`
              }>
              <div>{item}</div>
            </a>
          ))}
          <div className="block lg:hidden font-semibold  mt-4 text-xs">
            {navMobile?.applicationHeader}
          </div>
          {navMobile?.appStoreImage?.map((storeImage, index) => (
            <a
              key={index}
              href={
                index
                  ? 'https://play.google.com/store/apps/details?id=com.go.car.rental'
                  : 'https://apps.apple.com/ch/app/enterprise-go-rent-a-car/id1635135473'
              }
              target="_blank"
              rel="noreferrer"
              className="mt-2 block w-8rem">
              <ImageComponent id={storeImage.id} width="100%" />
            </a>
          ))}
        </div>
      </div>
      <Button
        className={`black-button btn-big border-round-md font-semibold text-sm ${
          isOpen ? 'hidden' : 'block'
        }`}
        onClick={() => dispatch(openDownloadAppOverview())}>
        {mobileHeader?.downloadAppButtonTextHeader || ''}
      </Button>
    </div>
  ) : (
    <Skeleton width="100%" height="100%" />
  );
};

export default HomeHeaderMobile;
