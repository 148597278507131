import { HomePageOverview } from 'store/homepage/homepage.slice';
import { graphQLAPI } from '../api/base-graphql-api';

export type MediaOverview = {
  mediaLogo?: { id: string } | null;
  mediaCoverImage?: { id: string } | null;
  mediaArticleTitle?: string | null;
  mediaArticleLinkUrl?: string | null;
  mediaArticleLeadParagraph?: string | null;
  publicationDate?: string | null;
};
type MediaDataFromHomepageOverview = {
  mediaPageHeroImage?: { id: string } | null;
  mediaPageHeader?: string | null;
};
type GetMediaOverviewQuery = {
  goWebappMediaArticles: Array<MediaOverview>;
  goWebappHomepages: Array<MediaDataFromHomepageOverview>;
};

const GetMediaPageOverviewDocument = `
query getMedia {
  goWebappMediaArticles {
    mediaLogo {
      id
    }
    mediaCoverImage {
      id
    }
    mediaArticleTitle
    mediaArticleLinkUrl
    mediaArticleLeadParagraph
    publicationDate
  }
  goWebappHomepages {
    mediaPageHeroImage {
      id
    }
    mediaPageHeader
  }
}`;
const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetMediaPageOverview: build.query<GetMediaOverviewQuery, void>({
      query: () => ({ document: GetMediaPageOverviewDocument })
    })
  })
});

export { injectedRtkApi as mediaPageOverview };
export const { useGetMediaPageOverviewQuery } = injectedRtkApi;
