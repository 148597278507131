import useScrollingUp from 'shared/hooks/useScrollingUp';
import './HomeHeader.scss';
import HomeHeaderDesktop from './HomeHeaderDesktop';
import HomeHeaderMobile from './HomeHeaderMobile';
import useIsMobile from 'shared/hooks/useIsMobile';
import { useEffect } from 'react';
import { menuItems } from './navigations-items';
import { HomeSections } from 'components/home/home-sections';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { getCurrentLocale } from 'store/language/language.selectors';
import { shallowEqual } from 'react-redux';

const appRootElement: Element = document.getElementsByTagName('html')[0];

const HomeHeader = () => {
  const isScrollingUp = useScrollingUp();
  const { headerMobile } = useIsMobile();
  const navigate = useNavigate();
  const currentLanguage = useAppSelector(getCurrentLocale, shallowEqual);

  useEffect(() => {
    if (appRootElement && !location.hash) {
      setTimeout(() => {
        appRootElement.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      setTimeout(() => {
        document
          .getElementById(location.hash.replace('#', ''))
          ?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, []);

  function scrollToSection(section: string) {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  }

  function onMenuClick(index: number): void {
    if (!menuItems.find((language) => language.key === currentLanguage)?.items[index]) {
      navigate('/#' + HomeSections.HOW_IT_WORKS);
      scrollToSection(HomeSections.HOW_IT_WORKS);
    } else {
      if (index !== 3) {
        navigate(
          menuItems.find((language) => language.key === currentLanguage)?.items[index] || ''
        );
      } else {
        window.open(
          menuItems.find((language) => language.key === currentLanguage)?.items[index] || '',
          '_blank'
        );
      }
    }
  }

  return (
    <header className={`header bg-white ${isScrollingUp ? 'stickyHeader' : ''}`}>
      {headerMobile ? (
        <HomeHeaderMobile menuClick={onMenuClick} />
      ) : (
        <HomeHeaderDesktop menuClick={onMenuClick} />
      )}
    </header>
  );
};

export default HomeHeader;
