import HomeComponent from 'components/home/HomeComponent';
import HomeHeader from 'shared/components/header/HomeHeader';

const HomeLayout = () => {
  return (
    <>
      <HomeHeader />
      <HomeComponent />
    </>
  );
};

export default HomeLayout;
