import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import BookingValidationService from 'shared/services/booking-validation.service';
import { FormattedDateTime, getNowDate, isWidgetValid } from 'shared/utils';
import { getPickupReturnDateTime, getUserBookingInfo } from 'store/booking/booking.selectors';
import { UserBookingData } from 'store/booking/booking.slice';
import { showErrorsMessage } from 'store/errors/errors.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const LESS_THAN_24_ERROR = 'RENTAL.LESS_THAN_24_HOURS';
const LESS_THAN_6_ERROR = 'RENTAL.LESS_THAN_6_HOURS';
const FILL_ALL_REQUIRED_FIELDS = 'RENTAL.FILL_ALL_REQUIRED_FIELDS';

const useIsWidgetValid = () => {
  const bookingTimes = useAppSelector(getPickupReturnDateTime);
  const userBookingData: UserBookingData = useAppSelector(getUserBookingInfo, shallowEqual);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isLessThanMinimumBookingTime = useMemo(() => {
    const pickupDate = FormattedDateTime(bookingTimes.pickupDate, bookingTimes.pickupTime);
    const returnDate = FormattedDateTime(bookingTimes.returnDate, bookingTimes.returnTime);
    return BookingValidationService.isLessThanMinimumBookingTime(pickupDate, returnDate);
  }, [bookingTimes]);

  const isLessThanDay = useMemo(() => {
    const formatedDate = FormattedDateTime(bookingTimes.pickupDate, bookingTimes.pickupTime);
    const nowDate = getNowDate();
    const diffInDays = formatedDate.diff(nowDate, 'minutes');
    return Math.trunc(diffInDays / 60 / 24) <= 0;
  }, [bookingTimes]);

  const validateWidget = useCallback(() => {
    const inputsValid = isWidgetValid(userBookingData);
    if (isWidgetValid(userBookingData)) {
      if (isLessThanMinimumBookingTime) {
        dispatch(showErrorsMessage(t(LESS_THAN_6_ERROR)));
      }
      if (isLessThanDay) {
        dispatch(showErrorsMessage(t(LESS_THAN_24_ERROR)));
      }
    } else {
      dispatch(showErrorsMessage(t(FILL_ALL_REQUIRED_FIELDS)));
    }

    return inputsValid && !isLessThanDay && !isLessThanMinimumBookingTime;
  }, [bookingTimes]);

  const isAlreadyPrefilledVehicle = useCallback(() => {
    return userBookingData.acriss;
  }, [userBookingData]);

  return { validateWidget: validateWidget, isAlreadyPrefilledVehicle };
};

export default useIsWidgetValid;
