import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'shared/components/primary-button/PrimaryButton';
import './PageNotFound.scss';

export default function PageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex container min-h-screen page-not-found-container justify-content-center align-items-center flex-column md:flex-row">
      <div className="md:flex-1 md:mr-8">
        <div className="text-xl md:text-2xl text-primary mb-2">{t('PAGE_NOT_FOUND.SUB_TITLE')}</div>
        <div className="text-2xl md:text-5xl font-semibold mb-2">{t('PAGE_NOT_FOUND.TITLE')}</div>
        <div className="text-lg md:text-xl mb-5">{t('PAGE_NOT_FOUND.TEXT')}</div>
        <div>
          <PrimaryButton
            text={t('PAGE_NOT_FOUND.BUTTON_TEXT')}
            rounded
            onClick={() => navigate('/')}
          />
        </div>
      </div>
      <div className="md:flex-1">
        <Image src="/images/404-image.png" />
      </div>
    </div>
  );
}
