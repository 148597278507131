import './HomeComponent.scss';
import HomeTitleTextComponent from './home-title-text/HomeTitleTextComponent';
import { useGetHomePageOverviewQuery } from 'store/homepage/homepage.slice';
import useGetAsset from 'shared/hooks/useGetAsset';
import ImageComponent from 'shared/components/image/ImageComponent';
import { HomeSections } from './home-sections';

import BookingWidget from 'shared/components/booking-widget/BookingWidget';
import { Skeleton } from 'primereact/skeleton';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { getDataProtectionPolicy } from '../../store/navigations/navigations.selectors';
import HomeVideoCarousel from './home-video-carousel/HomeVideoCarousel';
import { TabPanel, TabView } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import useIsMobile from 'shared/hooks/useIsMobile';
import { Image } from 'primereact/image';
import { getCurrentLocale } from 'store/language/language.selectors';
import { localesLinks } from 'shared/components/footer/FooterComponent';

const HomeComponent = () => {
  const { data: homepage, isFetching } = useGetHomePageOverviewQuery();
  const heroImage = useGetAsset(homepage?.heroImage?.id || '');
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const currentLanguage = useAppSelector(getCurrentLocale);

  const policy = useAppSelector(getDataProtectionPolicy);

  function goToPartners() {
    navigate('our-partners');
  }

  return (
    <main className="home">
      {!isFetching ? (
        <>
          <div
            className="relative bg-cover bg-no-repeat bg-center w-full book-car-container z-5"
            style={{ backgroundImage: `url(${heroImage.url})` }}>
            <div className="absolute  home-title md:flex md:align-items-end md:justify-content-between">
              <h1 className="font-normal m-0 md:text-6xl text-5xl text-white flex-1">
                {homepage?.heroTitleText || ''}
              </h1>
              <div className="flex mt-3 md:mt-0 mb-1">
                <div className="text-sm text-white font-semibold mr-2 ml-auto">
                  {homepage?.officialPartnerText || ''}
                </div>
                <a
                  href={homepage?.sbbWebsiteLinkUrl || ''}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="sbb-logo">
                  <ImageComponent id={homepage?.sbbLogoFromHero?.id || ''} />
                </a>
              </div>
            </div>
            <BookingWidget />
          </div>
          <section className="grid grid-nogutter justify-content-between app-info-container m-0 pt-5 md:pt-8 lg:pb-0">
            <>
              <div className="app-info flex flex-column justify-content-center align-items--center md:align-items-start col-12 md:col-6">
                <HomeTitleTextComponent
                  title={homepage?.valuePropositionTitle || ''}
                  content={homepage?.valuePropostionText || ''}
                  titleClasses="value-propostion-title font-bold"
                  contentClasses="mt-3 value-propostion-text"
                />
                <div className="flex">
                  <a
                    className="w-10rem block logos"
                    href={'https://apps.apple.com/ch/app/enterprise-go-rent-a-car/id1635135473'}
                    target="_blank"
                    rel="noreferrer">
                    <ImageComponent
                      height="48px"
                      id={homepage?.downloadButtons ? homepage.downloadButtons[0].id : ''}
                    />
                  </a>
                  <a
                    className="w-10rem block logos"
                    href={'https://play.google.com/store/apps/details?id=com.go.car.rental'}
                    target="_blank"
                    rel="noreferrer">
                    <ImageComponent
                      height="48px"
                      id={homepage?.downloadButtons ? homepage.downloadButtons[1].id : ''}
                    />
                  </a>
                </div>
              </div>
              <div className="col-12 md:col-6 md:pb-7">
                <ImageComponent
                  id={homepage?.valuePropositionImage?.id || ''}
                  width="100%"
                  height="100%"
                />
              </div>
            </>
          </section>
          <section className="app-video-container container flex align-items-center flex-column md:flex-row">
            <div className="md:w-8 lg:w-7 app-video-container-video">
              <HomeVideoCarousel
                items={homepage?.videoContentLinks || []}
                playText={homepage?.playVideoText || ''}
                playIcon={homepage?.mediaPlayerPlayIcon || null}
              />
            </div>
            <div className="mx-auto md:w-4 mb-8 app-video-text-container">
              <HomeTitleTextComponent
                title={homepage?.videoSectionTitle || ''}
                content={homepage?.videoSectionBodyText || ''}
                titleClasses="font-bold app-video-text-container-title"
                contentClasses="mt-2 text-base line-height-3 video-text line-height-3"
                diffFontSize
              />
            </div>
          </section>
          <section className="how-it-works-container pb-8" id={HomeSections.HOW_IT_WORKS}>
            <div className="container">
              <div className="mx-auto mb-6 text-center">
                <HomeTitleTextComponent
                  title={homepage?.howItWorksSectionTitle || ''}
                  content={homepage?.howItWorksSectionText || ''}
                  titleClasses="md:w-8 m-auto font-bold how-it-works-title"
                  contentClasses="mt-2 text-base md:w-7 m-auto line-height-3"
                  diffFontSize
                />
              </div>
              <TabView>
                <TabPanel
                  headerClassName="text-sm md:text-base"
                  header={
                    homepage?.howItWorksSectionCardTitles
                      ? homepage?.howItWorksSectionCardTitles[0]
                      : ''
                  }>
                  <div className="flex cards-container md:flex-row flex-column">
                    {homepage?.howItWorksCardText?.map((text: string, index: number) => (
                      <div
                        key={index}
                        className="card-container bg-white mb-4 md:mb-0 md:mr-4 border-round-2xl md:w-3">
                        <div className="card-icon ml-auto">
                          <ImageComponent
                            id={
                              homepage?.howItWorksFirstTimeCardImage
                                ? homepage?.howItWorksFirstTimeCardImage[index].id
                                : ''
                            }
                          />
                        </div>
                        <div className="text-base md:text-lg font-semibold my-2">
                          {homepage?.howItWorksCardTitle
                            ? homepage?.howItWorksCardTitle[index]
                            : ''}
                        </div>
                        <div className="text-base">{text}</div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
                <TabPanel
                  headerClassName="text-sm md:text-base"
                  header={
                    homepage?.howItWorksSectionCardTitles
                      ? homepage?.howItWorksSectionCardTitles[1]
                      : ''
                  }>
                  <div className="flex cards-container justify-content-center md:flex-row flex-column">
                    {homepage?.howItWorksRepeatCardText?.map((text: string, index: number) => (
                      <div
                        key={index}
                        className="card-container bg-white mb-4 md:mb-0 md:mr-4 border-round-2xl repeat">
                        <div className="card-icon ml-auto">
                          <ImageComponent
                            id={
                              homepage?.howItWorksRepeatCardImage
                                ? homepage?.howItWorksRepeatCardImage[index].id
                                : ''
                            }
                          />
                        </div>
                        <div className="text-base md:text-lg font-semibold my-2">
                          {homepage?.howItWorksRepeatCardTitle
                            ? homepage?.howItWorksRepeatCardTitle[index]
                            : ''}
                        </div>
                        <div className="text-base">{text}</div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </section>
          <section className="faq-container">
            <div className="container">
              <HomeTitleTextComponent
                title={homepage?.faqTitle || ''}
                content={''}
                titleClasses="md:w-8 m-auto text-center font-bold mb-2 md:mb-6 mt-8 faq-container-title"
                contentClasses="mt-2 text-base md:w-7 m-auto faq-container-text line-height-3"
                diffFontSize
              />
              {!isMobile ? (
                <div className="flex w-full justify-content-between pb-8 border-bottom-1 border-400">
                  <Accordion multiple>
                    {homepage?.faqQuestions?.map((question: string, index: number) => {
                      if (index % 2 === 0) {
                        return (
                          <AccordionTab header={question} key={index}>
                            <p>{homepage?.faqAnswers ? homepage.faqAnswers[index] : ''}</p>
                          </AccordionTab>
                        );
                      }
                    })}
                  </Accordion>
                  <Accordion multiple>
                    {homepage?.faqQuestions?.map((question: string, index: number) => {
                      if (index % 2 !== 0) {
                        return (
                          <AccordionTab header={question} key={index}>
                            <p>{homepage?.faqAnswers ? homepage.faqAnswers[index] : ''}</p>
                          </AccordionTab>
                        );
                      }
                    })}
                  </Accordion>
                </div>
              ) : (
                <Accordion multiple>
                  {homepage?.faqQuestions?.map((question: string, index: number) => (
                    <AccordionTab header={question} key={index}>
                      <p>{homepage?.faqAnswers ? homepage.faqAnswers[index] : ''}</p>
                    </AccordionTab>
                  ))}
                </Accordion>
              )}
            </div>
          </section>
          <section className="partners-conatiner">
            <div className="mx-auto md:w-9 lg:w-5 m-auto  mb-5">
              <HomeTitleTextComponent
                title={homepage?.partnersSectionTitle || ''}
                content={homepage?.partnershipsSectionText || ''}
                titleClasses="text-center partners-title font-bold"
                contentClasses="text-center m-2 line-height-3"
              />
            </div>
            <div className="container flex flex-wrap justify-content-around">
              {homepage?.partnershipAssets
                ?.filter((item: { id: string }, index: number) => index < 3)
                .map((image, index) => (
                  <a
                    href={homepage.partnershipLinkURL ? homepage.partnershipLinkURL[index] : ''}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="partner-logo justify-content-center flex align-items-center"
                    key={index}>
                    <div>
                      <ImageComponent id={image.id} />
                    </div>
                  </a>
                ))}
            </div>
            <div className="text-center">
              <Button
                onClick={goToPartners}
                label={homepage?.partnerSectionButtonText || ''}
                className="p-button-outlined p-button-secondary font-semibold btn-big border-round-md"
              />
            </div>
          </section>
          <div className="help-center-icon">
            <a
              href={localesLinks.find((lang) => lang.key === currentLanguage)?.helpCenterLink || ''}
              target="_blank"
              rel="noreferrer noopener">
              <Image src="/images/Helpcenter.png" />
            </a>
          </div>
        </>
      ) : (
        <Skeleton width="100%" height="100vh" />
      )}
    </main>
  );
};

export default HomeComponent;
