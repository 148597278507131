import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BookingGuard from 'shared/guards/BookingGuard';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCurrentLocale } from 'store/language/language.selectors';
import FooterComponent from '../footer/FooterComponent';
import Loader from '../loader/Loader';
import HomeLayout from './home-layout/HomeLayout';
import './LayoutContainer.scss';
import { usePrefetch as useNavigationsPrefetch } from 'store/navigations/navigations-overview.slice';
import { resetBookingState } from 'store/booking/booking.slice';
import PaymentSuccessGuard from 'shared/guards/PaymentSuccessGuard';
import PartnersComponent from 'components/partners/PartnersComponent';
import InTheMediaComponent from 'components/in-the-media/InTheMediaComponent';
import PrivacyComponent from 'components/privacy/PrivacyComponent';
// import useGoogleTagPageEvents from 'shared/hooks/useGoogleTagPageEvents';

const BookingLayout = lazy(() => import('./booking-layout/BookingLayout'));
const PaymentSuccessContainer = lazy(
  () => import('../../../components/payment-success/PaymentSuccessContainer')
);

const LayoutContainer = () => {
  const dispatch = useAppDispatch();
  const prefetchNavigations = useNavigationsPrefetch('GetNavigationsOverview');
  const currentLanguage = useAppSelector(getCurrentLocale);
  const location = useLocation();
  const navigate = useNavigate();
  const [prevPath, setPrevPath] = useState<string>();
  // const a = useGoogleTagPageEvents();

  useEffect(() => {
    if (prevPath === '/payment-success') {
      dispatch(resetBookingState());
      navigate('/');
    }
    setPrevPath(location.pathname);
  }, [location]);

  useEffect(() => {
    prefetchNavigations();
  }, [currentLanguage]);

  return (
    <div className="layout">
      <div className="layout-container">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomeLayout />}></Route>
            <Route path="/our-partners" element={<PartnersComponent />}></Route>
            <Route path="/in-the-media" element={<InTheMediaComponent />}></Route>
            <Route path="/privacy/:lang" element={<PrivacyComponent />}></Route>
            <Route
              path="/booking/*"
              element={
                <BookingGuard>
                  <BookingLayout />
                </BookingGuard>
              }></Route>
            <Route
              path="/payment-success"
              element={
                <PaymentSuccessGuard>
                  <PaymentSuccessContainer />
                </PaymentSuccessGuard>
              }
            />
            <Route path="*" element={<Navigate to="not-found" replace />}></Route>
          </Routes>
        </Suspense>
      </div>
      <FooterComponent />
    </div>
  );
};

export default LayoutContainer;
