import { BookingWorkflow } from 'generated/graphql';
import { graphQLAPI } from '../api/base-graphql-api';

export type bookLaterHours = {
  hours: number;
  pickUpLocationId?: number;
  returnLocationId?: number;
  includeLocationTax?: boolean;
};

export type CarClassOverview = {
  id: string;
  className?: string | null;
  classFeatureIcon: Array<{ id: string }> | null;
  classFeatures: Array<{ html: string }>;
  classImage: { id: string };
  rentClassId: number;
  referenceCarModelName?: string | null;
  localizations: Array<{
    className: string;
  }>;
};

type GetCarClassOverviewQuery = {
  classes: Array<CarClassOverview>;
};

export type CarSubclassOverview = {
  id: string;
  referenceCarModelName?: string;
  subClassImage?: { id: string };
  carModelFeatures?: CarModelFeatures[];
  subClassName?: string;
  rentSubclassId: number;
  subClassFallbackFeatures?: Array<string> | null;
  subclassFallbackFeaturesIcon?: Array<{ id: string }> | null;
  subClassDescriptionTitle?: string | null;
  subClassDescription?: { html: string } | null;
  carModels?: carModel[];
  subClassFeatureIcon?: Array<{ id: string }> | null;
  subClassFeatures?: Array<{ html: string }> | null;
  localizations: Array<{
    subClassName: string;
  }>;
};
type carModel = {
  carModelImage?: Array<{ id: string }> | null;
};

export type CarModelFeatures = {
  carModelFeatureName?: string;
  carModelFeatureIcon?: { id: string } | null;
};

type GetCarSubclassOverviewQuery = {
  subClasses: Array<CarSubclassOverview>;
};

type GetPriceButtonText = {
  priceButtonText: string;
  priceButtonTextCurrencyOnly: string;
};

type GetPriceButtonTextQuery = {
  goBookingJourneys: Array<GetPriceButtonText>;
};

type GoSelectionsRentalFeatures = {
  includedRentalFeatures?: Array<{ html: string }>;
  includedRentalFeaturesIcon?: Array<{ id: string }> | null;
};

type GoSelectionsRentalFeaturesQuery = {
  goSelections: Array<GoSelectionsRentalFeatures>;
};

type CarClassesIds = {
  ids: Array<number>;
};

type GetCarClassAndSubclassBySubclassHygraphIdQuery = {
  id: number;
  rentSubclassId: string;
  subClassName: string;
  localizations: {
    subClassName: string;
  }[];
  class: {
    id: number;
    rentClassId: string;
    className: string;
    localizations: {
      className: string;
    }[];
  };
};

type GetCarClassAndSubclassBySubclassHygraphIdQueryResponse = {
  subClass: GetCarClassAndSubclassBySubclassHygraphIdQuery;
};

const GetCarClassesOverviewDocument = `
  query GetCarClasses($ids: [Int!]) {
    classes(where: {rentClassId_in: $ids}) {
      className
      id
      classFeatureIcon {
        id
      }
      classFeatures {
        html
      }
      classImage {
        id
      }
      rentClassId
      referenceCarModelName
      localizations(locales: en) {
        className
      }
    }
  }
`;

const GetPriceButtonTextDocument = `
  query GetPriceButtonText() {
    goBookingJourneys {
      priceButtonText
      priceButtonTextCurrencyOnly

    }
  }
`;

const GetCarSubclassesOverviewDocument = `
  query GetCarSubclasses($ids: [Int!]) {
    subClasses(where: {rentSubclassId_in: $ids}) {
      id
      referenceCarModelName
      carModelFeatures {
        carModelFeatureName
        carModelFeatureIcon {
          id
        }
      }
      subClassFeatures { 
        html
      }
      subClassFeatureIcon {
        id
      }
      subClassName
      rentSubclassId
      subClassImage {
        id
      }
      localizations(locales: en) {
        subClassName
      }
    }
  }
`;

const GetCarOverviewByRentIdDocument = `
  query GetSubclassByRentId($ids: [Int!]) {
    subClasses(where: {rentSubclassId_in: $ids}) {
      id
      subClassName
      referenceCarModelName
      subClassImage {
        id
      }
      rentSubclassId
    }
  }
`;

const GetCarSubclassOverviewByRentIdDocument = `
  query GetSubclassOverviewByRentId($ids: [Int!]) {
    subClasses(where: {rentSubclassId_in: $ids}) {
      id
      subClassName
      referenceCarModelName
      subClassImage {
        id
      }
      rentSubclassId
      subClassFallbackFeatures
      subclassFallbackFeaturesIcon {
        id
      }
      subClassDescription {
        html
      }
      subClassDescriptionTitle
      carModels {
        carModelImage {
          id
        }
      }
    }
  }
`;

const GetGoSelectionsRentalFeaturesDocument = `
  query goSelections($bookingJourney: BookingWorkflow!) {
    goSelections(where: {bookingJourney: $bookingJourney}) {
      includedRentalFeatures {
        html
      }
      includedRentalFeaturesIcon {
        id
      }
    }
  }
`;

const GetCarClassAndSubclassBySubclassHygraphIdDocument = `
  query getClasses($id: ID!) {
    subClass(where: {id: $id}) {
      id
      rentSubclassId
      subClassName
      localizations(locales: en) {
        subClassName
      }
      class {
        rentClassId
        id
        className
        localizations(locales: en) {
          className
        }
      }
    }
  }
`;

const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetCarClassesOverview: build.query<CarClassOverview[], CarClassesIds>({
      query: (variables) => ({ document: GetCarClassesOverviewDocument, variables }),
      transformResponse: (response: GetCarClassOverviewQuery) => {
        return response?.classes;
      }
    }),
    GetCarSubclassesOverview: build.query<CarSubclassOverview[], CarClassesIds>({
      query: (variables) => ({ document: GetCarSubclassesOverviewDocument, variables }),
      transformResponse: (response: GetCarSubclassOverviewQuery) => {
        return response?.subClasses;
      }
    }),
    GetPriceButtonText: build.query<GetPriceButtonText, void>({
      query: () => ({ document: GetPriceButtonTextDocument }),
      transformResponse: (response: GetPriceButtonTextQuery) => {
        return response?.goBookingJourneys[0];
      }
    }),
    GetCarOverviewByRentId: build.query<CarSubclassOverview, CarClassesIds>({
      query: (variables) => ({ document: GetCarOverviewByRentIdDocument, variables }),
      transformResponse: (response: GetCarSubclassOverviewQuery) => {
        return response?.subClasses[0];
      }
    }),
    GetCarSubclassOverviewByRentId: build.query<CarSubclassOverview, CarClassesIds>({
      query: (variables) => ({ document: GetCarSubclassOverviewByRentIdDocument, variables }),
      transformResponse: (response: GetCarSubclassOverviewQuery) => {
        return response?.subClasses[0];
      }
    }),
    GetCarClassAndSubclassBySubclassHygraphId: build.query<
      GetCarClassAndSubclassBySubclassHygraphIdQuery,
      { id: string }
    >({
      query: (variables) => ({
        document: GetCarClassAndSubclassBySubclassHygraphIdDocument,
        variables
      }),
      transformResponse: (response: GetCarClassAndSubclassBySubclassHygraphIdQueryResponse) => {
        return response?.subClass;
      }
    }),
    GetGoSelectionsRentalFeatures: build.query<
      GoSelectionsRentalFeatures,
      { bookingJourney: BookingWorkflow }
    >({
      query: (variables) => ({ document: GetGoSelectionsRentalFeaturesDocument, variables }),
      transformResponse: (response: GoSelectionsRentalFeaturesQuery) => {
        return response?.goSelections[0];
      }
    })
  })
});

export { injectedRtkApi as carClassesOverviewAPI };
export const {
  useGetCarClassesOverviewQuery,
  useLazyGetCarClassesOverviewQuery,
  useGetCarOverviewByRentIdQuery,
  useGetCarSubclassOverviewByRentIdQuery,
  useLazyGetCarOverviewByRentIdQuery,
  useLazyGetCarSubclassOverviewByRentIdQuery,
  useGetCarSubclassesOverviewQuery,
  useLazyGetCarSubclassesOverviewQuery,
  useGetGoSelectionsRentalFeaturesQuery,
  useLazyGetGoSelectionsRentalFeaturesQuery,
  useGetPriceButtonTextQuery,
  useLazyGetCarClassAndSubclassBySubclassHygraphIdQuery
} = injectedRtkApi;
