import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import configService from 'shared/config.service';
import { CarClass, CarMileages } from 'shared/models/car-class.model';
import { bookLaterHours } from './car-classes-overview.slice';

export const carClassesAPI = createApi({
  reducerPath: 'classes',
  baseQuery: fetchBaseQuery({
    baseUrl: configService.baseUrl
  }),
  endpoints: (builder) => ({
    getClassesMiliages: builder.query<CarMileages[], bookLaterHours>({
      query: ({ hours, pickUpLocationId, returnLocationId, includeLocationTax }) => {
        return {
          url: '/price/mileages/booklater/hours',
          params: { hours, pickUpLocationId, returnLocationId, includeLocationTax },
          method: 'GET'
        };
      },
      transformResponse: (response: { classes: CarMileages[] }) => {
        return response.classes.filter((carClass) => carClass.isActive);
      }
    }),
    getClassesList: builder.query<CarClass[], void>({
      query: () => {
        return {
          url: '/classes',
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useGetClassesMiliagesQuery,
  useLazyGetClassesMiliagesQuery,
  useGetClassesListQuery,
  useLazyGetClassesListQuery
} = carClassesAPI;
