import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserBookingSelection from 'shared/hooks/useUserBookingSelection';
import { isWidgetValid } from 'shared/utils';

export default function PaymentSuccessGuard({ children }: { children: JSX.Element }) {
  const { userBookingData } = useUserBookingSelection();
  const navigate = useNavigate();

  useEffect(() => {
    const isValid = isWidgetValid(userBookingData);
    if (!isValid) {
      navigate('/');
    }
  }, [location.pathname, userBookingData]);

  return <>{children}</>;
}
