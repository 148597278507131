import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import configService from 'shared/config.service';

export type CarExtra = {
  id: number;
  isTaxable: boolean;
  isVariable: boolean;
  name: string;
  price: number;
  shortDescription: string;
  vat: number;
  dailyPriceExcludingVAT: number;
  dailyPriceIncludingVAT: number;
  dailyVAT: number;
  isActive: boolean;
};

export const carExtrasAPI = createApi({
  reducerPath: 'extras',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configService.baseUrl}/extras`
  }),
  endpoints: (builder) => ({
    getExtrasList: builder.query<CarExtra[], number>({
      query: (period) => `/extended/hours/${period}`,
      transformResponse: (response: CarExtra[]) => {
        return response.filter((extra) => extra.isActive);
      }
    })
  })
});

export const { useGetExtrasListQuery, useLazyGetExtrasListQuery, usePrefetch } = carExtrasAPI;
