import { ExtrasGroup } from 'generated/graphql';
import { graphQLAPI } from '../api/base-graphql-api';

type GetExtrasOverviewQuery = {
  extras: Array<CarExtrasOverview>;
  extrasGroups: Array<CarExtrasGroupOverview>;
};

export type CarExtrasOverview = {
  id: string;
  extrasGroup: Array<string>;
  extrasIcon: { id: string };
  extrasName: string;
  extrasShortDescription: string;
  rentExtrasId: number;
  extrasPriceButttonText: string;
  extrasPriceNoDay: string;
};

export type CarExtrasGroupOverview = {
  id: string;
  extrasGroupName: string;
  extrasGroupEnum: ExtrasGroup;
  extras: Array<{ id: string }> | null;
};

export type CarModelFeatures = {
  carModelFeatureName?: string;
  carModelFeatureIcon?: { id: string } | null;
};

const GetExtrasOverviewDocument = `
query GetExtrasOverviewQuery() {
    extras() {
      extrasName
      extrasIcon {
        id
      }
      extrasGroup
      extrasShortDescription
      rentExtrasId
      id
      extrasPriceButttonText
      extrasPriceNoDay
    }
    extrasGroups() {
      id
      extrasGroupName
      extrasGroupEnum
      extras {
        id
      }
    }
  }
  `;

const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetExtrasOverview: build.query<GetExtrasOverviewQuery, void>({
      query: () => ({ document: GetExtrasOverviewDocument }),
      keepUnusedDataFor: 100000000
    })
  })
});

export { injectedRtkApi as extrasOverview };
export const { useGetExtrasOverviewQuery, usePrefetch } = injectedRtkApi;
