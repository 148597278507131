import { createSelector } from '@reduxjs/toolkit';
import { ExtrasGroup } from 'generated/graphql';
import { CarExtrasOverview, extrasOverview } from './extras-overview.slice';
import { CarExtra, carExtrasAPI } from './extras.slice';

const getExtrasList = (hours: number) => carExtrasAPI.endpoints.getExtrasList.select(hours);

export const getExtrasOverview = extrasOverview.endpoints.GetExtrasOverview.select();

export const getExtrasOverviewForType = (type: ExtrasGroup, hours: number) =>
  createSelector([getExtrasList(hours), getExtrasOverview], (extrasList, extrasOverview) => {
    return extrasOverview.data?.extras.filter((extraOverview: CarExtrasOverview) => {
      return (
        extraOverview.extrasGroup.includes(type) &&
        extrasList.data?.find((extra: CarExtra) => extra.id === extraOverview.rentExtrasId)
      );
    });
  });

export const getExtraDetailsById = (id: number, hours: number) =>
  createSelector([getExtrasList(hours)], (extraslist) =>
    extraslist?.data?.find((extra: CarExtra) => extra.id === id)
  );
