export const privacyTexts = {
  en: `<div>
  <p style="padding-top:4pt; font-size:28pt;margin-bottom:0;"><b><span style="letter-spacing:-0.5pt;">Privacy Policy of Enterprise GO</span></b></span></p>
  <p style="padding-bottom:4pt; font-size:14pt; margin:0;"><b><span style="letter-spacing:-0.5pt;"></span></b></span></p>
  <p style="padding-top:4pt; font-size:14pt;margin-bottom:0;"><b><span style="letter-spacing:-0.5pt;">Version 1.2 – 01.08.2023</span></b></span></p>
  <p style="padding-bottom:4pt; font-size:28pt; margin:0;"><b><span style="letter-spacing:-0.5pt;"></span></b></span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid; font-size:14pt;">
    About us
  </h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">This privacy policy ("<b>Privacy Policy</b>") explains how we process and protect your personal data when you use this application (the "<b>Application</b>").</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">The Application is operated by <a name="_Hlk19780381">Helvetic Motion AG, <span style="background-color:#ffffff;">Werftstrasse 80, 8302 Kloten, Switzerland</span> ("<b>Enterprise</b>", "<b>we</b>", "<b>our</b>", or "<b>us"</b>)</a>. Enterprise is the controller for the data processing described below.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">Unless otherwise defined in this Privacy Policy or our General Terms &amp; Conditions, the definitions used in this Privacy Policy have the same meaning as in the Swiss Federal Act on Data Protection.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">1</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Personal data we collect</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We collect or receive personal information for a number of purposes connected with our business operations when you use our Application. This includes the following:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Personal Information (e.g. name and surname, e-mail address, phone number, physical address, date of birth, login information and password, ID card, passport and driver&rsquo;s license);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>In-app Identifiers (e.g. screen name, account ID, customer number and other user-, account- and device-level ID that are used to identify you, your account or your device);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Photos (e.g. the photos and images that are uploaded to the app);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Billing information (e.g. form of payment, payment card number, bank account number, purchase history);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Booking information (e.g. booking history and dates, pick up and return locations and time, car choice and details, extras and coverage, accident information, booking status and loyalty programme details);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>In-app messages (e.g. time, content);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Precise location (location of the user or the device with the same or greater resolution as a latitude and longitude with three or more decimal places);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Search history (e.g. information about the searches performed in the Application);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Usage data (e.g. interaction with our Application, such as app launches, taps, clicks, scrolling, music listening data, video views and other information about how the user interacts with the Application);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Advertising data (e.g. information about the advertisements the user has seen);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Diagnostics data (e.g. crash logs, launch time, hang rate, energy use and other information related to the performance of the Application).</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;">As a franchisee of Enterprise Holdings, Inc. and/or any of its operating subsidiaries ("<b>EHI</b>"), to support global booking systems, EHI may share with us your reservation and booking information. &nbsp;We also share booking information about you with EHI, as described below in the relevant Section.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">2</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>How we collect personal data</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We collect information about our users when they use our Application, including taking certain actions within it.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;"><b>Directly</b></span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>When users register in our Application;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>When users access, use, or otherwise interact with our Application;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>When users correspond with us by electronic means;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>When users submit their data to us.</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;"><b>Indirectly</b></span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>From service providers such as market research, advertising and analytics companies;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>From third-party applications;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>From third-party integrations included in our Application;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Through public sources, such as public registers (e.g. commercial registers), news articles, sanctions lists, and Internet searches;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>When EHI shares with us your reservation and booking information.</li>
  </ul>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">3</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Legal basis and purposes</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">Our legal basis for collecting and using the personal data described in this Privacy Policy depends on the personal data we collect and the specific purposes for which we collect it.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;"><b>Contract</b>: To perform our contractual obligations or take steps linked to a contract with you. In particular:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Performance of the contract: To provide our services.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Customer Support: To provide you with customer support.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Account management: To set up and manage your account in our Application, as well as to verify your credentials when logging in.</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;"><b>Consent</b>: We may rely on your freely given consent at the time you provided your personal data. In particular:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Marketing: To provide you with news, special offers and general information about goods, services and events which we offer by means of push notifications, ads or newsletters.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Third-Party Advertising: To provide you with news, special offers and general information about goods, services and events which are offered by our partners, by means of push notifications, ads or newsletters.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Analytics and tracking: To analyse, improve, personalise and monitor the usage of our Application&nbsp;&nbsp;and communications.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Product Personalisation: To customise what you see, such as recommended products, posts, suggestions.</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;"><b>Legitimate interests</b>: We may rely on legitimate interests based on our assessment that the processing is fair and reasonable and does not override your interests or fundamental rights and freedoms. In particular:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-left: 27.6pt; padding-left: 8.4pt; '>App functionality and security: To provide, maintain and improve our Application, as well as to detect, prevent and address security threats.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Developer communications: To notify you about changes to our Application and our Privacy Policy, as well as service performance-related notifications.&nbsp;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Age Control: To verify your age.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Service development: To develop new services.</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;"><b>Necessity for compliance with legal obligations:</b> To meet regulatory and public interest obligations. In particular:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Legal compliance: To comply with applicable regulations and legislation.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Exercise of rights: For the legal enforcement of claims and rights.</li>
  </ul>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">4</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Data retention</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We retain personal data for so long as it is needed for the purposes for which it was collected or in line with legal and regulatory requirements or contractual arrangements. </span><span style='background-color: rgb(255, 255, 255); '>We delete geolocation data within 6 months after your rental end date.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">5</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>EHI</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We share your personal information with EHI for the following purposes:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt;  color: rgb(33, 37, 41);'>To manage and facilitate global reservations;&nbsp;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt;  color: rgb(33, 37, 41);'>Rental information for EHI and its third-party service providers to carry out customer satisfaction surveys;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt;  color: rgb(33, 37, 41);'>To compile statistics and analysis about the use of EHI&rsquo;s products and services, including statistics based on anonymized data, which enable EHI to provide you and other customers in the future with better customer service, products, features and functionalities.&nbsp;&nbsp;</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;"><span style='background-color: rgb(255, 255, 255); '>When sharing such information with EHI, we and EHI operate as independent data controllers. EHI maintains its own privacy policy in respect of the information we provide EHI. You are encouraged to review EHI&rsquo;s privacy policy, which can be found here:&nbsp;</span><a href="https://privacy.ehi.com"  target="_blank" style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">https://privacy.ehi.com</span></u></a><u><span style="color:#002a24;">.</span></u></span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">6</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Service providers</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We may employ third party companies ("<b>Service Providers</b>") to facilitate the operation of our Application, provide the Application on our behalf, perform Application-related services, assist us in analysing how our Application is used or help us provide you with tailor-made offers and exclusive deals. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">Categorie(s) of service providers who might access your personal data:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Accounting firms;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Banks and payment service providers;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Cloud storage service providers;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Other group entities;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>KYC service providers;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Marketing firms and mailing list providers;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>Public authorities.</li>
  </ul>
  <h2 style="margin-top:12pt; margin-left:28.8pt; margin-bottom:12pt; text-indent:-28.8pt; page-break-inside:avoid; page-break-after:avoid;  font-size:12pt;">6.1</span><span style='width: 12.12pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>Google OAuth</span></h2>
  <p style="margin-top:6pt; margin-bottom:6pt;">Google OAuth is a registration and authentication service operated by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from and is connected to the Google network.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">For more information you may visit <a href="https://policies.google.com/privacy" target="_blank"  style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">https://policies.google.com/privacy</span></u></a>.</span></p>
  <h2 style="margin-top:12pt; margin-left:28.8pt; margin-bottom:12pt; text-indent:-28.8pt; page-break-inside:avoid; page-break-after:avoid;  font-size:12pt;">6.2</span><span style='width: 12.12pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>Sign in with Apple</span></h2>
  <p style="margin-top:6pt; margin-bottom:6pt;">Sign in with Apple is provided by Apple Distribution International Limited, or Apple Inc. depending on the location this Application is accessed from. Sign in with Apple is a registration and authentication service connected to the Apple network.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">For more information you may visit <a
  href="https://www.apple.com/legal/privacy/en-ww/" target="_blank" style="text-decoration: none"><u
    ><span style="color: rgb(5, 99, 193);"  >https://www.apple.com/legal/privacy/en-ww/</span
    ></u
  ></a
>.</span></p>
  <h2 style="margin-top:12pt; margin-left:28.8pt; margin-bottom:12pt; text-indent:-28.8pt; page-break-inside:avoid; page-break-after:avoid;  font-size:12pt;">6.3</span><span style='width: 12.12pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>Google Maps</span></h2>
  <p style="margin-top:6pt; margin-bottom:6pt;">Our Application uses the map service Google Maps, which is offered to persons from Switzerland and the European Economic Area by Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Grand Canal Dock, Dublin 4, D04 V4X7, Ireland. In order for the Google map material used by us to be integrated, your web browser must establish a connection to a Google server, which may also be located in the USA, when you call up our Application. By integrating the map material, Google receives the information that a page was called up from the IP address of your device. If you call up the Google map service on our Application while you are logged into your Google profile, this may also be linked to your Google profile. If you do not wish this to be associated with your Google profile, you must log out of Google before accessing our Application.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">For more information, please see Google&apos;s <a href="https://policies.google.com/privacy?" target="_blank"  style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">privacy policy</span></u></a> and the additional terms of use for <a href="https://www.google.com/help/terms_maps/"  target="_blank" style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">Google Maps</span></u></a>.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">7</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Data transfers</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;"><a name="_Hlk126941960">Enterprise and/or our Service Providers may transfer your personal data to and process it:</a></span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>EU/EEA;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>USA.</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;">We may use service providers who are partly located in so-called third countries (outside the European Union or the European Economic Area or Switzerland) or process personal data there, i.e. countries whose level of data protection does not correspond to that of the EU.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">We safeguard your personal data per our contractual obligations and applicable data protection legislation when transferring data abroad.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;"><span style='background-color: rgb(255, 255, 255); '>Such safeguards may include:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><a name="_Hlk128585377"><span style="background-color: rgb(255, 255, 255);">the transfer to countries that have been deemed to provide an adequate level of protection according to lists of countries published by the Federal Data Protection and Information Commissioner;&nbsp;</span></a></li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>applying standard data protection model clauses, binding corporate rules or other standard contractual obligations that provide appropriate data protection.</li>
  </ul>
  <p style="margin-top:6pt; margin-bottom:6pt;"><span style='background-color: rgb(255, 255, 255); '>If a third country transfer takes place and there is no adequacy decision or appropriate safeguards, it is possible and there is a risk that authorities in the third country (e.g. intelligence services) can gain access to the transferred data and that the enforceability of your data subject&apos;s rights cannot be guaranteed.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">8</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Data disclosure</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We may disclose your personal data in the good faith belief that such action is necessary:</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>To comply with a legal obligation (i.e., if required by law or in response to valid requests by public authorities, such as a court or government agency);</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>To protect the security of the Application and defend our rights or property;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>To prevent or investigate possible wrongdoing in connection with us;</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '>To defend ourselves against legal liability.</li>
  </ul>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">9</span><span style='width: 13.81pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>Data security</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;"><a name="_Hlk20230515">We take reasonable technical and organisational security measures that we deem appropriate to protect your stored data against manipulation, loss, or unauthorised third-party access. Our security measures are continually adapted to technological developments.</a></span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">We also take internal data privacy very seriously. Our employees and the Service Providers that we retain are required to maintain secrecy and comply with applicable data protection legislation. In addition, they are granted access to personal data only insofar as this is necessary for them to carry out their respective tasks or mandate.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">The security of your personal data is important to us but remember that no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">10</span><span style='width: 6.03pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp;&nbsp;</span>Your rights</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">You have the below data protection rights. To exercise these rights, you may contact the above address or send an e-mail to: support@enterprise-go.ch . You can also exercise these rights in the Application settings. Please note that we may ask you to verify your identity before responding to such requests.</span></p>
  <ul type="disc" style="margin:0pt; padding-left:0pt;">
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right of access</b>: You have a right to request a copy of your personal data, which we will provide to you in an electronic form.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to amendment</b>: You have the right to ask us to correct our records if you believe they contain incorrect or incomplete information about you.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to withdraw consent: If you have provided your consent to the processing of your personal data, you have the right to withdraw your consent with effect for the future. This includes cases where you wish to opt-out from marketing communications. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose(s) to which you initially consented unless there is another legal basis for processing. You may use the Application settings to adjust your consent settings. To stop receiving emails from us, please change your privacy preferences within the Application settings or contact us at support@enterprise-go.ch</b></li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to erasure</b>: You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected or when it was unlawfully processed.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to restriction of processing</b>: You have the right to request the restriction of our processing of your personal data where you believe it to be inaccurate, our processing is unlawful, or where we no longer need to process it for the initial purpose, but where we are not able to delete it due to a legal obligation or because you do not want us to delete it.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to portability</b>: You have the right to request that we transmit your personal data to another data controller in a standard format such as Excel, where this is data which you have provided to us and where we are processing it on the legal basis of your consent or to perform our contractual obligations.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to object to processing: Where the legal basis for our processing of your personal data is our legitimate interest, you have the right to object to such processing on grounds relating to your particular situation. We will abide by your request unless we have a compelling legal basis for the processing which overrides your interests or if we need to continue to process the personal data for the exercise or defence of a legal claim</b>.</li>
      <li style='margin-top: 6pt; margin-left: 27.6pt; margin-bottom: 6pt; padding-left: 8.4pt; '><b>Right to lodge a complaint with a supervisory authority</b>: You have the right of appeal to a data protection supervisory authority if you believe that the processing of your personal data violates data protection law. You are entitled to contact the relevant Supervisory Authority&mdash;in Switzerland, the Federal Data Protection and Information Commissioner, Feldeggweg 1<br>CH - 3003 Bern, info@edoeb.admin.ch.</li>
  </ul>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">11</span><span style='width: 6.03pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp;&nbsp;</span>Links to third-party apps and sites</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">Our Application may contain links to websites or apps that are not operated by us. When you click on a third party link, you will be directed to that third party&apos;s website or app. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party websites or services.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">We maintain online presences on social networks to, among other things, communicate with customers and prospective customers and to provide information about our products and services. If you have an account on the same network, it is possible that your information and media made available there may be seen by us, for example, when we access your profile. In addition, the social network may allow us to contact you. The content communication via the social network and the processing of the content data is thereby subject to the responsibility of the social network. As soon as we transfer personal data into our own system, we are responsible for this independently. This is then done in order to carry out pre-contractual measures and to fulfil a contract. For the legal basis of the data processing carried out by the social networks under their own responsibility, please refer to their data protection declarations. Below is a list of social networks on which we operate an online presence:</span></p>
  <p style="margin-top:6pt; margin-left:61.25pt; margin-bottom:6pt; text-indent:-39.7pt;">- Facebook: <a href="https://www.facebook.com/privacy/policy?_rdr" target="_blank"  style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">Privacy policy</span></u></a></span></p>
  <p style="margin-top:6pt; margin-left:61.25pt; margin-bottom:6pt; text-indent:-39.7pt;">- Instagram: <a href="https://help.instagram.com/155833707900388"  target="_blank" style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">Privacy Policy</span></u></a></span></p>
  <p style="margin-top:6pt; margin-left:61.25pt; margin-bottom:6pt; text-indent:-39.7pt;">- LinkedIn: <a href="https://www.linkedin.com/legal/privacy-policy?" target="_blank"  style="text-decoration:none;"><u><span style="color: rgb(5, 99, 193);">Privacy policy</span></u></a></span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">12</span><span style='width: 6.03pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp;&nbsp;</span>Changes to this privacy policy</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">We may update our Privacy Policy from time to time. We, therefore, encourage you to review this Privacy Policy periodically for any changes.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">We will notify you via email and/or a prominent notice on our Application, prior to the change becoming effective and update the &apos;effective date&apos; at the top of this Privacy Policy, but we encourage you to review this Privacy Policy periodically for any changes.</span></p>
  <p style="margin-top:6pt; margin-bottom:6pt;">Changes to this Privacy Policy are effective when they are posted on this page.</span></p>
  <h1 style="margin-top:18pt; margin-left:21.6pt; margin-bottom:12pt; text-indent:-21.6pt; page-break-inside:avoid; page-break-after:avoid;  font-size:14pt;">13</span><span style='width: 6.03pt; font: 7pt "Times New Roman", Times, serif; display: inline-block;'>&nbsp; &nbsp;&nbsp;</span>Contact us</span></h1>
  <p style="margin-top:6pt; margin-bottom:6pt;">If you have any questions about this Privacy Policy, do not hesitate to get in touch with us at support@enterprise-go.ch</span></p>
</div>`,
  fr_CH: `<div>

  <p
    style="line-height: normal;
      padding-top: 4pt;
      font-size: 28pt;
      margin-bottom:0;"
  >
    <b
      ><span style="letter-spacing: -0.5pt"  >Politique de confidentialit&eacute; d&rsquo; Enterprise GO</span
      ></b
    >
  </p>
  <p style="padding-bottom:4pt; font-size:14pt; margin:0;"><b><span style="letter-spacing:-0.5pt;"></span></b></span></p>
  <p style="padding-top:4pt; font-size:14pt;margin-bottom:0;"><b><span style="letter-spacing:-0.5pt;">Version 1.2 – 01.08.2023</span></b></span></p>
  <p
    style="line-height: normal;
      padding-bottom: 4pt;
      font-size: 28pt;
      margin:0"
  >
    <b><span style="letter-spacing: -0.5pt"></span></b>
  </p>
<p
  style="margin-top: 6pt;
    margin-bottom: 12pt;
    line-height: normal;
    font-size: 16pt;"
>
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  A propos de nous
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Cette politique de confidentialit&eacute; (&quot;<b
    >Politique de confidentialit&eacute;</b
  >&quot;) explique comment nous traitons et prot&eacute;geons vos
  donn&eacute;es personnelles lorsque vous utilisez cette application
  (&quot;<b>Application</b>&quot;).
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  L&apos;application est g&eacute;r&eacute;e par
  <b>Helvetic Motion AG</b>, Werftstrasse 80, 8302 Kloten, Suisse
  (&quot;<b>Enterprise</b>&quot;, &quot;<b>nous</b>&quot;,
  &quot;<b>notre</b>&quot; ou &quot;<b>nos</b>&quot;).
  Enterprise est le responsable du traitement des donn&eacute;es d&eacute;crit
  ci-dessous.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Sauf indication contraire dans la pr&eacute;sente Politique de
  confidentialit&eacute; ou dans nos conditions g&eacute;n&eacute;rales, les
  d&eacute;finitions utilis&eacute;es dans la pr&eacute;sente Politique de
  confidentialit&eacute; ont la m&ecirc;me signification que dans la Loi
  f&eacute;d&eacute;rale suisse sur la protection des donn&eacute;es.
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  1<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  ><a name="_cmntref1">Donn&eacute;es personnelles que nous collectons</a
  >
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous collectons ou recevons des donn&eacute;es &agrave; caract&egrave;re
  personnel pour un certain nombre de raisons li&eacute;es &agrave; nos
  activit&eacute;s commerciales lorsque vous utilisez notre application. Il
  s&apos;agit notamment des informations suivantes :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Informations personnelles (par exemple : nom et pr&eacute;nom, adresse
      e-mail, num&eacute;ro de t&eacute;l&eacute;phone, adresse, date de
      naissance, informations de connexion et mot de passe, carte
      d&apos;identit&eacute;, passeport et permis de conduire) ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Identifiants In-app (par exemple : nom d&apos;&eacute;cran, identifiant
      de compte, num&eacute;ro de client et autres identifiants au niveau de
      l&apos;utilisateur, du compte et de l&apos;appareil qui sont
      utilis&eacute;s pour vous identifier, identifier votre compte ou votre
      appareil) ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <a name="_cmntref2"><span  >Photos (par exemple : les photographies et les images
        t&eacute;l&eacute;charg&eacute;es sur l&apos;application) ;</span
      ></a
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Informations de facturation (par exemple : mode de paiement,
      num&eacute;ro de carte bancaire, num&eacute;ro de compte bancaire,
      historique des achats) ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Informations relatives &agrave; la r&eacute;servation (par exemple :
      l&apos;historique des r&eacute;servations et les dates, les lieux et
      heures de prise en charge et de retour, le choix de la voiture et les
      d&eacute;tails, les extras et la garantie, les informations sur les
      accidents, l&apos;&eacute;tat de la r&eacute;servation et les
      d&eacute;tails du programme de fid&eacute;lit&eacute;) ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <a name="_cmntref3"><span  >Messages in-app (par exemple : heure, contenu) ;</span
      ></a
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <a name="_cmntref4"><span  >Localisation pr&eacute;cise (par exemple : localisation de
        l&apos;utilisateur ou de l&apos;appareil avec une r&eacute;solution
        identique ou sup&eacute;rieure &agrave; celle d&apos;une latitude et
        d&apos;une longitude avec trois d&eacute;cimales ou plus) ;</span
      ></a
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <a name="_cmntref5"><span  >Historique des recherches (par exemple : informations sur les
        recherches effectu&eacute;es dans l&apos;Application) ;</span
      ></a
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Donn&eacute;es d&apos;utilisation (par exemple : l&apos;interaction
      avec notre Application, comme les d&eacute;ploiements de notre
      Application, les tapotements, les clics, le d&eacute;filement, les
      donn&eacute;es d&apos;&eacute;coute de musique, les visionnages de
      vid&eacute;os et d&apos;autres informations sur la fa&ccedil;on dont
      l&apos;utilisateur interagit avec l&apos;Application) ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Donn&eacute;es marketing (par exemple : informations sur les
      publicit&eacute;s que l&apos;utilisateur a vues) ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Les donn&eacute;es de diagnostic (par exemple : les crash logs,
      l&apos;heure de d&eacute;ploiement, le taux d&apos;accrochage,
      l&apos;utilisation de l&apos;&eacute;nergie et d&apos;autres
      informations relatives aux performances de l&apos;application).</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  En tant que franchis&eacute; d&apos;Enterprise Holdings, Inc. et/ou de
  l&apos;une de ses filiales d&apos;exploitation
  (&quot;<b>EHI</b>&quot;), pour soutenir les syst&egrave;mes de
  r&eacute;servation globales, EHI peut partager avec nous vos informations de
  r&eacute;servation.&nbsp; Nous partageons &eacute;galement des informations
  de r&eacute;servation vous concernant avec EHI, comme d&eacute;crit
  ci-dessous dans la Section correspondante.
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  2<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Comment nous collectons les donn&eacute;es personnelles
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous collectons des informations sur nos utilisateurs lorsqu&apos;ils
  utilisent notre Application, y compris lorsqu&apos;ils effectuent certaines
  actions au sein de celle-ci.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <b>Directement</b>
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Lorsque les utilisateurs s&apos;enregistrent dans notre Application
      ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Lorsque les utilisateurs acc&egrave;dent &agrave; notre Application,
      l&apos;utilisent ou interagissent d&apos;une autre mani&egrave;re avec
      elle ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Lorsque les utilisateurs correspondent avec nous par voie
      &eacute;lectronique ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Lorsque les utilisateurs nous soumettent leurs donn&eacute;es
      personnelles.</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <b>Indirectment</b>
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>De nos fournisseurs de services tels que des soci&eacute;t&eacute;s de
      recherche de marketing, de publicit&eacute; et d&apos;analyse ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>&Agrave; partir d&apos;applications tierces ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>&Agrave; partir d&apos;int&eacute;grations tierces incluses dans notre
      Application ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Par le moyen de sources publiques, telles que les registres publics
      (par exemple, les registres du commerce), les articles de presse, les
      listes de sanctions et les recherches sur Internet ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Lorsque EHI partage avec nous vos informations de
      r&eacute;servation.</span
    >
  </li>
</ul>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  3<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Base l&eacute;gale et finalit&eacute;s de traitement
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Notre base l&eacute;gale pour la collecte et l&apos;utilisation des
  donn&eacute;es personnelles d&eacute;crites dans la pr&eacute;sente
  Politique de confidentialit&eacute; d&eacute;pend des donn&eacute;es
  personnelles que nous collectons et des objectifs sp&eacute;cifiques pour
  lesquels nous les collectons.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <b>Contrat&nbsp;</b>: pour ex&eacute;cuter nos obligations
  contractuelles ou prendre des mesures li&eacute;es &agrave; un contrat avec
  vous. En particulier :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Ex&eacute;cution du contrat : pour fournir nos services.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Assistance &agrave; la client&egrave;le : pour vous fournir du support
      client.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Gestion de compte : pour configurer et g&eacute;rer votre compte dans
      notre Application, ainsi que pour v&eacute;rifier vos informations
      d&apos;identification lorsque vous vous connectez.</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <b>Consentement :&nbsp;</b>Nous pouvons nous pr&eacute;valoir de
  votre consentement librement donn&eacute; au moment o&ugrave; vous avez
  fourni vos donn&eacute;es personnelles. En particulier :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Marketing : pour vous fournir des nouvelles, des offres
      sp&eacute;ciales et des informations g&eacute;n&eacute;rales sur les
      biens, les services et les &eacute;v&eacute;nements que nous proposons
      au moyen de notifications push, d&apos;annonces ou de bulletins
      d&apos;information.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Publicit&eacute; de tiers : pour vous fournir des nouvelles, des offres
      sp&eacute;ciales et des informations g&eacute;n&eacute;rales sur les
      biens, les services et les &eacute;v&eacute;nements qui sont offerts par
      nos partenaires, au moyen de notifications push, d&apos;annonces ou de
      bulletins d&apos;information.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Analyse et tra&ccedil;age : pour analyser, am&eacute;liorer,
      personnaliser et surveiller l&apos;utilisation de notre Application et
      de nos communications.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Personnalisation des produits : pour personnaliser ce que vous voyez,
      comme des produits recommand&eacute;s, des publications, des
      suggestions.</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <b>Int&eacute;r&ecirc;ts l&eacute;gitimes :</b> Nous pouvons nous
  pr&eacute;valoir de nos int&eacute;r&ecirc;ts l&eacute;gitimes en fonction
  de notre &eacute;valuation que le traitement est juste et raisonnable et ne
  l&apos;emporte pas sur vos int&eacute;r&ecirc;ts ou vos droits et
  libert&eacute;s fondamentaux. En particulier :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Fonctionnalit&eacute; et s&eacute;curit&eacute; de notre Application :
      pour fournir, maintenir et am&eacute;liorer notre Application, ainsi que
      pour d&eacute;tecter, pr&eacute;venir et traiter les menaces de
      s&eacute;curit&eacute;.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Communications des d&eacute;veloppeurs : pour vous informer des
      changements apport&eacute;s &agrave; notre Application et &agrave; notre
      Politique de confidentialit&eacute;, ainsi que des notifications
      li&eacute;es &agrave; la performance du service.&nbsp;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Contr&ocirc;le de l&apos;&acirc;ge : pour v&eacute;rifier votre
      &acirc;ge.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>D&eacute;veloppement de services : pour d&eacute;velopper de nouveaux
      services.</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <b
    >N&eacute;cessit&eacute; de se conformer aux obligations l&eacute;gales
    :</b
  >
  Pour r&eacute;pondre &agrave; des obligations r&eacute;glementaires et
  d&apos;int&eacute;r&ecirc;t public. En particulier :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Conformit&eacute; l&eacute;gale : pour respecter la
      r&eacute;glementation et la l&eacute;gislation applicables.</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Exercice des droits : pour l&apos;application l&eacute;gale des
      r&eacute;clamations et des droits.</span
    >
  </li>
</ul>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  4<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Conservation des donn&eacute;es
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <span style="background-color: #ffffff">Nous conservons les donn&eacute;es personnelles pour autant de temps
    qu&rsquo;elles sont n&eacute;cessaires aux finalit&eacute;s pour
    lesquelles elles ont &eacute;t&eacute; collect&eacute;es ou en
    conformit&eacute; aux exigences l&eacute;gales et r&eacute;glementaires ou
    aux dispositions contractuelles. Nous effa&ccedil;ons les donn&eacute;es
    de g&eacute;olocalisation dans les 6 mois suivant la date de fin de votre
    location.</span
  >
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  5<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >EHI
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous partageons vos donn&eacute;es personnelles avec EHI pour les raisons
  suivantes :&nbsp;&nbsp;
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 28.06pt;
      margin-bottom: 6pt;
      line-height: 150%;
      padding-left: 7.94pt;"
  >
    <span style="line-height: 150%;">Gérer et faciliter les réservations mondiales ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 28.06pt;
      margin-bottom: 6pt;
      line-height: 150%;
      padding-left: 7.94pt;"
  >
    <span style="line-height: 150%;">Informations de location pour EHI et ses prestataires de services tiers
      afin de r&eacute;aliser des enqu&ecirc;tes de satisfaction aupr&egrave;s
      des clients ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 28.06pt;
      margin-bottom: 6pt;
      line-height: 150%;
      padding-left: 7.94pt;"
  >
    <span style="line-height: 150%;">Pour &eacute;tablir des statistiques et des analyses sur
      l&apos;utilisation des produits et services d&apos;EHI, y compris des
      statistiques bas&eacute;es sur des donn&eacute;es anonymes, qui
      permettent &agrave; EHI de vous fournir, ainsi qu&apos;&agrave;
      d&apos;autres clients &agrave; l&apos;avenir, un meilleur service, de
      meilleurs produits, de meilleures caract&eacute;ristiques et de
      meilleures fonctionnalit&eacute;s.</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Lorsque nous partageons ces informations avec EHI, EHI et nous-m&ecirc;mes
  agissons en tant que responsables du traitement ind&eacute;pendants. EHI
  applique sa propre politique de confidentialit&eacute; en ce qui concerne
  les informations que nous lui fournissons. Nous vous invitons &agrave;
  consulter la politique de confidentialit&eacute; d&apos;EHI, qui peut
  &ecirc;tre consult&eacute;e &agrave; l&apos;adresse suivante : 
  <a
    href="https://privacy.ehi.com/" target="_blank" style="text-decoration: none"><u><span style="color: rgb(5, 99, 193);">https://privacy.ehi.com</span></u></a
  >.
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  6<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  ><a name="_cmntref6">Prestataires de services</a
  >
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  L&rsquo;Enterprise peut engager des soci&eacute;t&eacute;s tierces
  ("<b>Prestataires de Services</b>&rdquo;) pour faciliter le
  fonctionnement de notre site internet, aider &agrave; analyser
  l&rsquo;utilisation du site internet ou fournir des services li&eacute;s au
  site internet et aux services fournis, tels que le paiement et la fourniture
  de services d&apos;infrastructure d&rsquo;IT. Ces tiers n&rsquo;ont
  acc&egrave;s aux donn&eacute;es personnelles de l&rsquo;utilisateur que dans
  la mesure n&eacute;cessaire &agrave; l&rsquo;ex&eacute;cution de ces
  fonctions, pour le compte d&apos;Enterprise.&nbsp;
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Cat&eacute;gorie(s) de Prestataires de services susceptibles
  d&rsquo;acc&eacute;der &agrave; vos donn&eacute;es personnelles:
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <a name="_cmntref7"><span  >Enterprises de comptabilit&eacute; ;</span
      ></a
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="; background-color: #ffffff">Prestataires de services bancaires et de paiement ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="; background-color: #ffffff">Prestataires de services d&apos;h&eacute;bergement de sites
      internet&nbsp;</span
    ><span style="">;</span>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="; background-color: #ffffff">Autres entreprises du groupe&nbsp;</span
    ><span style="">;</span>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="; background-color: #ffffff">Prestataires de services KYC ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Prestataires de services marketing et fournisseurs de listes de
      diffusion ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="; background-color: #ffffff">Autorit&eacute;s&nbsp;</span
    ><span style="">publiques.</span
    >
  </li>
</ul>
<h2
  style="margin-top: 12pt;
    margin-left: 28.8pt;
    margin-bottom: 12pt;
    text-indent: -28.8pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 12pt;"
>
  6.1<span
    style="width: 12.12pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Google OAuth
</h2>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Google OAuth est un service d&apos;enregistrement et d&apos;authentification
  exploit&eacute; par Google LLC ou par Google Ireland Limited, selon
  l&apos;endroit d&apos;o&ugrave; l&apos;on acc&egrave;de &agrave; cette
  Application et qui est connect&eacute; au r&eacute;seau Google.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Pour plus d&apos;informations, vous pouvez consulter le site
  <a href="https://policies.google.com/privacy" target="_blank"  style="color: rgb(5, 99, 193);"><u
      ><span style="color: rgb(5, 99, 193);"  >https://policies.google.com/privacy</span
      ></u
    ></a
  >.
</p>
<h2
  style="margin-top: 12pt;
    margin-left: 28.8pt;
    margin-bottom: 12pt;
    text-indent: -28.8pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 12pt;"
>
  6.2<span
    style="width: 12.12pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Sign in with Apple
</h2>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Sign in with Apple est fournie par Apple Distribution International Limited,
  ou Apple Inc. selon l&apos;endroit o&ugrave; l&apos;on acc&egrave;de
  &agrave; cette application. Sign in with Apple est un service
  d&apos;enregistrement et d&apos;authentification connect&eacute; au
  r&eacute;seau Apple.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Pour plus d&apos;informations, vous pouvez consulter le site
  <a
    href="https://www.apple.com/fr/legal/privacy/fr-ww/"  target="_blank" style="text-decoration: none"><u
      ><span style="color: rgb(5, 99, 193);"  >https://www.apple.com/fr/legal/privacy/fr-ww/</span
      ></u
    ></a
  >.
</p>
<h2
  style="margin-top: 12pt;
    margin-left: 28.8pt;
    margin-bottom: 12pt;
    text-indent: -28.8pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 12pt;"
>
  6.3<span
    style="width: 12.12pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  ><a name="_cmntref8">Google Maps</a
  >
</h2>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Notre Application utilise le service de cartographie Google Maps, qui est
  propos&eacute; aux personnes r&eacute;sidant en Suisse et dans l&apos;Espace
  &eacute;conomique europ&eacute;en par Google Ireland Limited, Google
  Building Gordon House, 4 Barrow St, Grand Canal Dock, Dublin 4, D04 V4X7,
  Irlande. Pour que le mat&eacute;riel cartographique de Google que nous
  utilisons puisse &ecirc;tre int&eacute;gr&eacute;, votre navigateur Internet
  doit &eacute;tablir une connexion avec un serveur de Google,
  &eacute;ventuellement situ&eacute; aux &Eacute;tats-Unis, lorsque vous
  appelez notre application. En int&eacute;grant le mat&eacute;riel
  cartographique, Google re&ccedil;oit l&apos;information qu&apos;une page a
  &eacute;t&eacute; appel&eacute;e &agrave; partir de l&apos;adresse IP de
  votre appareil. Si vous appelez le service de cartographie de Google sur
  notre application alors que vous &ecirc;tes connect&eacute; &agrave; votre
  profil Google, cela peut &eacute;galement &ecirc;tre li&eacute; &agrave;
  votre profil Google. Si vous ne souhaitez pas que ce service soit
  associ&eacute; &agrave; votre profil Google, vous devez vous
  d&eacute;connecter de Google avant d&apos;acc&eacute;der &agrave; notre
  application.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Pour plus d&apos;informations, veuillez consulter la
  <a href="https://policies.google.com/privacy?" target="_blank"   target="_blank"  style="text-decoration: none"><u
      ><span style="color: rgb(5, 99, 193);"  >politique de confidentialit&eacute;</span
      ></u
    ></a
  >
  de Google et les
  <a
    href="https://www.google.com/help/terms_maps/" target="_blank" style="text-decoration: none"><u
      ><span style="color: rgb(5, 99, 193);"  >conditions d&apos;utilisation suppl&eacute;mentaires de Google
        Maps</span
      ></u
    ></a
  >.
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  7<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Transferts de donn&eacute;es
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <a name="_Hlk126941960">L&rsquo;Enterprise&nbsp;</a
  ><a name="_Hlk128585377">et/ou les Prestataires de Services peuvent transf&eacute;rer et traiter
    vos donn&eacute;es personnelles :&nbsp;</a
  >
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="">UE/EEE</span>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="">&Eacute;tats-Unis&nbsp;</span>
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous pouvons faire appel &agrave; des Prestataires de Services partialement
  situ&eacute;s dans des pays dits tiers (en dehors de la Suisse) ou
  qu&rsquo;y traitent des donn&eacute;es personnelles, c&rsquo;est &agrave;
  dire des pays dont le niveau de protection des donn&eacute;es personnelles
  ne correspond pas &agrave; celui de la Suisse.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous prot&eacute;geons vos donn&eacute;es personnelles en conformit&eacute;
  avec nos obligations contractuelles et la l&eacute;gislation applicable en
  mati&egrave;re de la protection des donn&eacute;es personnelles, lors du
  transfert de donn&eacute;es &agrave; l&rsquo;&eacute;tranger.&nbsp;
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Ces mesures peuvent inclure :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Le transfert vers des pays consid&eacute;r&eacute;s comme offrant un
      niveau de protection ad&eacute;quat selon le Conseil
      F&eacute;d&eacute;ral de la Suisse ;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>L&rsquo;application de clauses contractuelles types de protection de
      donn&eacute;es, r&egrave;gles contraignantes d&apos;entreprise ou
      d&rsquo;autres obligations contractuelles types qui assurent une
      protection appropri&eacute;e des donn&eacute;es.&nbsp;</span
    >
  </li>
</ul>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Si un transfert vers un pays tiers a lieu et il n&rsquo;y a pas une
  d&eacute;cision d&rsquo;ad&eacute;quation ou de garanties
  appropri&eacute;es, il est possible et il y a un risqu&eacute; que les
  autorit&eacute;s du pays tiers (par exemple, les services de renseignement)
  puissent avoir acc&egrave;s aux donn&eacute;es transf&eacute;r&eacute;es et
  que ne soit pas garantie &agrave; la personne concern&eacute;e se faire
  valoir de ses droits.
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  8<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Divulgation des donn&eacute;es
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous pouvons divulguer vos donn&eacute;es personnelles en toute bonne foi si
  une telle action est n&eacute;cessaire :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Pour respecter une obligation l&eacute;gale (c&rsquo;est-&agrave;-dire
      si la loi l&rsquo;exige ou en r&eacute;ponse &agrave; des demandes
      valides des autorit&eacute;s publiques, telles qu&rsquo;un tribunal ou
      une agence gouvernementale) ;&nbsp;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Pour prot&eacute;ger la s&eacute;curit&eacute; du site internet et
      defender nos droits et propri&eacute;t&eacute; ;&nbsp;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Pour pr&eacute;venir ou enqu&ecirc;ter &eacute;ventuels actes illicites
      en rapport avec nous ;&nbsp;</span
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span>Pour nous defender contre toute responsabilit&eacute;
      l&eacute;gale.</span
    >
  </li>
</ul>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  9<span
    style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Securit&eacute; des donn&eacute;es
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <a name="_Hlk20230515">Nous prenons des mesures de s&eacute;curit&eacute; techniques et
    organisationnelles raisonnables que nous jugeons appropri&eacute;es pour
    prot&eacute;ger vos donn&eacute;es conserv&eacute;es contre la
    manipulation, la perte ou l&apos;acc&egrave;s non autoris&eacute; de
    tiers. Nos mesures de s&eacute;curit&eacute; sont continuellement
    adapt&eacute;es aux &eacute;volutions technologiques.&nbsp;</a
  >
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous sommes &eacute;galement tr&egrave;s s&eacute;rieux avec la protection
  des donn&eacute;es dedans notre entreprise. Nos employ&eacute;s et les
  Fournisseurs de Services que nous engageons sont tenus de garder la
  confidentialit&eacute; et de se conformer &agrave; la l&eacute;gislation
  applicable en mati&egrave;re de protection des donn&eacute;es. En outre, ils
  n&apos;ont acc&egrave;s aux donn&eacute;es personnelles que dans la mesure
  o&ugrave; cela est n&eacute;cessaire &agrave; l&apos;ex&eacute;cution de
  leurs fonctions ou de leur mandat.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  La s&eacute;curit&eacute; de vos donn&eacute;es personnelles est importante
  pour nous, mais n&apos;oubliez pas qu&apos;aucune m&eacute;thode de
  transmission sur Internet ou de conservation &eacute;lectronique n&apos;est
  s&ucirc;re &agrave; 100 %. Bien que nous nous efforcions d&apos;utiliser des
  moyens commercialement acceptables pour prot&eacute;ger vos donn&eacute;es
  personnelles, nous ne pouvons pas garantir leur s&eacute;curit&eacute;
  absolue. Nous vous recommandons d&apos;utiliser un logiciel antivirus, un
  firewall et d&apos;autres logiciels similaires pour prot&eacute;ger votre
  syst&egrave;me.
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  10<span
    style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Vos droits
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Vous disposez des droits suivants en relation avec la protection de vos
  donn&eacute;es personnelles. Pour exercer ses droits, vous pouvez contact
  l&rsquo;adresse ci-dessus ou envoyer un e-mail &agrave;:
  support@enterprise-go.ch Vous pouvez &eacute;galement exercer ces droits dans les
  param&egrave;tres de l&apos;Application sous.
  <span style="background-color: #ffffff">Veuillez noter que nous pouvons vous demander de v&eacute;rifier votre
    identit&eacute; avant de r&eacute;pondre &agrave; votre demande</span
  >.
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit d&rsquo;acc&egrave;s :&nbsp;</span
      ></b
    ><span>Vous avez le droit de demander une copie de vos donn&eacute;es
      personnelles, que nous vous fournirons sous forme
      &eacute;lectronique.</span
    ><b><span style="">&nbsp;</span></b>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit de rectification :&nbsp;</span
      ></b
    ><span>Vous avez le droit de nous demander de corriger nous registres si vous
      croyez qu&rsquo;ils contiennent des informations incorrectes ou
      incompl&egrave;tes &agrave; votre propos.</span
    ><b><span style="">&nbsp;</span></b>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit de retirer votre consentement : Si vous avez donn&eacute; votre
        consentement au traitement de vos donn&eacute;es personnelles, vous
        avez le droit de le retirer avec des affects pour l&rsquo;avenir. Cela
        inclut les cas o&ugrave; vous souhaitez ne plus recevoir de
        communications marketing. Lorsque nous aurons re&ccedil;u la
        notification du retrait de votre consentement, nous ne traiterons plus
        vos informations aux fins auxquelles vous avez initialement consenti,
        &agrave; moins que le traitement ne repose pas sur une autre base
        juridique. Pour ne plus recevoir d&rsquo;e-mails de notre part,
        veuillez cliquer sur le lien "se d&eacute;sabonner&rdquo; dans
        l&rsquo;email que vous avez re&ccedil;u ou nous contacter &agrave;
        l&rsquo;adresse suivante: support@enterprise-go.ch</span
      ></b
    ><span style="">.</span>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit &agrave; effacement :&nbsp;</span
      ></b
    ><span>Vous avez le droit de nous demander d&rsquo;effacer vos donn&eacute;es
      personnelles lorsqu&rsquo;elles ne sont plus n&eacute;cessaires aux fins
      pour lesquelles elles ont &eacute;t&eacute; collect&eacute;es ou quand
      elles ont &eacute;t&eacute; trait&eacute;es illicitement.</span
    ><b><span style="">&nbsp;</span></b>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit &agrave; la limitation du traitement :&nbsp;</span
      ></b
    ><span>Vous avez le droit de demander la limitation de notre traitement de vos
      donn&eacute;es personnelles si vous consid&eacute;rez qu&rsquo;elles
      sont inexactes, que notre traitement est ill&eacute;gal ou que nous
      n&rsquo;avons plus besoin de les traiter pour le but initial, mais que
      nous ne pouvons pas les effacer en raison d&rsquo;une obligation
      l&eacute;gale ou parce que vous ne voulez pas que nous les
      effacions.</span
    ><b><span style="">&nbsp;</span></b>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit &agrave; la portabilit&eacute; :&nbsp;</span
      ></b
    ><span>Vous avez le droit de demander que nous transmettions vos
      donn&eacute;es personnelles &agrave; un autre responsable du traitement
      dans un format standard, tel que fichier Excel, dans la mesure o&ugrave;
      il s&rsquo;agit de donn&eacute;es que vous nous avez fournis et
      o&ugrave; nous les traitons sur la base juridique de votre consentement
      ou pour ex&eacute;cuter nos obligations contractuelles.</span
    ><b><span style="">&nbsp;</span></b>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit d&rsquo;opposition au traitement : Lorsque la base
        l&eacute;gale de notre traitement de vos donn&eacute;es personnelles
        est notre int&eacute;r&ecirc;t l&eacute;gitime, vous avez le droit de
        vous opposer &agrave; ce traitement pour des raisons li&eacute;es
        &agrave; votre situation particuli&egrave;re. Nous nous alignerons sur
        votre demande, sauf si nous disposons d&rsquo;une base juridique
        d&eacute;terminante pour le traitement qui pr&eacute;vaut sur vos
        int&eacute;r&ecirc;ts ou si nous devons continuer &agrave; traiter les
        donn&eacute;es personnelles pour l&rsquo;exercice ou la d&eacute;fense
        d&rsquo;un litige juridictionnel.&nbsp;</span
      ></b
    >
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <b
      ><span  >Droit de porter plainte aupr&egrave;s d&rsquo;une autorit&eacute; de
        contr&ocirc;le :&nbsp;</span
      ></b
    ><span>Vous avez le droit de faire appel &agrave; une autorit&eacute; de
      contr&ocirc;le de la protection de donn&eacute;es personnelles si vous
      croyez que le traitement de vos donn&eacute;es personnelles porte
      atteinte aux lois sur la protection des donn&eacute;es. Vous pouvez
      contacter l&rsquo;autorit&eacute; de contr&ocirc;le comp&eacute;tente
      &ndash; en Suisse le Pr&eacute;pos&eacute; f&eacute;d&eacute;ral
      &agrave; la protection des donn&eacute;es et &agrave; la transparence
      (www.edoeb.admin.ch/edoeb/en/home.html).</span
    >
  </li>
</ul>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  11<span
    style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
  ><a name="_cmntref11">Liens vers des applications et des sites tiers</a
  >
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Notre Application peut contenir des liens vers des sites internet ou des
  applications qui ne sont pas g&eacute;r&eacute;s par nous. Lorsque vous
  cliquez sur le lien d&apos;un tiers, vous &ecirc;tes dirig&eacute; vers le
  site internet ou l&apos;application de ce tiers. Nous n&apos;avons aucun
  contr&ocirc;le sur, et n&apos;assumons aucune responsabilit&eacute; pour, le
  contenu, les politiques de confidentialit&eacute;, ou les pratiques de ces
  web sites ou services de tiers.
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Nous maintenons la pr&eacute;sence en ligne sur les r&eacute;seaux sociaux
  pour, entre autres, communiquer avec les clients et les clients potentiels
  et pour fournir des informations sur nos produits et services. Si vous avez
  un compte sur le m&ecirc;me r&eacute;seau, il est possible que les
  informations et les m&eacute;dias que vous y mettez &agrave; disposition
  soient vus par nous, par exemple lorsque nous acc&eacute;dons &agrave; votre
  profil. En outre, le r&eacute;seau social peut nous permettre de vous
  contacter. La communication de contenu via un r&eacute;seau social et le
  traitement des donn&eacute;es de contenu rel&egrave;vent de la
  responsabilit&eacute; du r&eacute;seau social. D&egrave;s que nous
  transf&eacute;rons des donn&eacute;es personnelles dans notre propre
  syst&egrave;me, nous en sommes responsables de mani&egrave;re
  ind&eacute;pendante. Nous le faisons alors pour prendre des mesures
  pr&eacute;contractuelles ou pour ex&eacute;cuter un contrat. Pour
  conna&icirc;tre la base l&eacute;gale du traitement des donn&eacute;es
  effectu&eacute; par les r&eacute;seaux sociaux sous leur propre
  responsabilit&eacute;, veuillez-vous r&eacute;f&eacute;rer &agrave; leurs
  d&eacute;clarations de protection des donn&eacute;es. Vous trouverez
  ci-dessous une liste des r&eacute;seaux sociaux sur lesquels nous sommes
  pr&eacute;sents :
</p>
<ul type="disc" style="margin: 0pt; padding-left: 0pt">
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="">Facebook :&nbsp;</span
    ><a
      href="https://fr-fr.facebook.com/privacy/policy/" target="_blank "style="text-decoration: none"><u
        ><span style="color: rgb(5, 99, 193);"    >Politique de Confidentialit&eacute;</span
        ></u
      ></a
    ><span style="">&nbsp;</span>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="">Instagram :&nbsp;</span
    ><a
      href="https://help.instagram.com/155833707900388" target="_blank "style="text-decoration: none"><u
        ><span style="color: rgb(5, 99, 193);"    >Politique de Confidentialit&eacute;</span
        ></u
      ></a
    ><span style="">&nbsp;</span>
  </li>
  <li
    style="margin-top: 6pt;
      margin-left: 27.6pt;
      margin-bottom: 6pt;
      padding-left: 8.4pt;"
  >
    <span style="">LinkedIn :&nbsp;</span
    ><a
      href="https://fr.linkedin.com/legal/privacy-policy" target="_blank" style="text-decoration: none"><u
        ><span style="color: rgb(5, 99, 193);"    >Politique de Confidentialit&eacute;</span
        ></u
      ></a
    ><span style="">&nbsp;</span>
  </li>
</ul>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  12<span
    style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Modifications de la pr&eacute;sente Politique de confidentialit&eacute;
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <span style="background-color: #ffffff">Nous pouvons mettre &agrave; jour notre Politique de
    confidentialit&eacute; de temps en temps. Nous vous encourageons donc
    &agrave; consulter r&eacute;guli&egrave;rement la pr&eacute;sente
    Politique de confidentialit&eacute; pour prendre connaissance
    d&apos;&eacute;ventuels changements.</span
  >
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <a name="_cmntref12">Nous vous informerons par courrier &eacute;lectronique et/ou par un avis
    bien visible sur notre Application, avant que le changement n&apos;entre
    en vigueur et nous mettrons &agrave; jour la &quot;date
    d&apos;entr&eacute;e en vigueur&quot; en haut de la pr&eacute;sente
    politique de confidentialit&eacute;, mais nous vous encourageons &agrave;
    consulter r&eacute;guli&egrave;rement la pr&eacute;sente politique de
    confidentialit&eacute; pour prendre connaissance de tout changement.</a
  >
</p>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  <span style="background-color: #ffffff">Les modifications apport&eacute;es &agrave; la pr&eacute;sente Politique
    de confidentialit&eacute; entrent en vigueur au moment o&ugrave; elles
    sont publi&eacute;es sur cette page.</span
  >
</p>
<h1
  style="margin-top: 18pt;
    margin-left: 21.6pt;
    margin-bottom: 12pt;
    text-indent: -21.6pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: normal;
    font-size: 14pt;"
>
  13<span
    style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
  >Contactez-nous
</h1>
<p style="margin-top: 6pt; margin-bottom: 6pt">
  Si vous avez des questions concernant cette Politique de
  confidentialit&eacute;, n&apos;h&eacute;sitez pas &agrave; nous contacter
  &agrave; l&apos;adresse suivante : support@enterprise-go.ch
</p>
</div>`,
  de_CH: `<div>
  <p style="padding-top: 4pt; font-size: 28pt; margin-bottom:0">
    <b
      ><span style="letter-spacing: -0.5pt">Datenschutzerkl&auml;rung der Enterprise GO</span
      ></b
    >
  </p>
  <p style="padding-bottom:4pt; font-size:14pt; margin:0;"><b><span style="letter-spacing:-0.5pt;"></span></b></span></p>
  <p style="padding-top:4pt; font-size:14pt;margin-bottom:0;"><b><span style="letter-spacing:-0.5pt;">Version 1.2 – 01.08.2023</span></b></span></p>
  <p style="padding-bottom: 4pt; font-size: 28pt;margin:0;">
    <b><span style="letter-spacing: -0.5pt"></span></b>
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    &Uuml;ber uns
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Diese Datenschutzerkl&auml;rung
    (&quot;<b>Datenschutzerkl&auml;rung</b>&quot;) erl&auml;utert, wie wir Ihre
    personenbezogenen Daten verarbeiten und sch&uuml;tzen, wenn Sie diese App
    (die &quot;<b>App</b>&quot;) nutzen.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Die App wird von<a name="_Hlk19780381">&nbsp;Helvetic Motion AG,
      <span style="background-color: #ffffff">Werftstrasse 80, 8302 Kloten, Schweiz&nbsp;</span
      >(&quot;<b>Unternehmen</b>&quot;, &quot;<b>wir</b>&quot;,
      &quot;<b>unser</b>&quot; oder &quot;<b>uns&quot;</b>)</a
    >
    betrieben. Das Unternehmen ist der Verantwortliche f&uuml;r die unten
    beschriebene Datenverarbeitung.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Sofern in dieser Datenschutzerkl&auml;rung oder unseren Allgemeinen
    Gesch&auml;ftsbedingungen nicht anders definiert, haben die in dieser
    Datenschutzerkl&auml;rung verwendeten Definitionen dieselbe Bedeutung wie im
    Schweizer Bundesgesetz &uuml;ber den Datenschutz.
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    1<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Von uns erhobene personenbezogene Daten
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir erheben oder erhalten personenbezogene Daten f&uuml;r eine Reihe von
    Zwecken im Zusammenhang mit unserem Gesch&auml;ftsbetrieb, wenn Sie unsere
    App nutzen. Dazu geh&ouml;rt das Folgende:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Pers&ouml;nliche Daten (z. B. Vor- und Nachname, E-Mail-Adresse,
        Telefonnummer, Anschrift, Geburtsdatum, Anmeldedaten und Passwort,
        Personalausweis, Reisepass und F&uuml;hrerschein);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >In-App-Identifikatoren (z. B. Benutzername, Konto-ID, Kundennummer und
        andere IDs auf Nutzer-, Konto- und Ger&auml;teebene, die verwendet
        werden, um Sie, Ihr Konto oder Ihr Ger&auml;t zu identifizieren);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <a name="_cmntref2"><span
          >Fotos (z. B. die Fotos und Bilder, die in die App hochgeladen
          werden);</span
        ></a
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Abrechnungsinformationen (z. B. Zahlungsart, Zahlungskartennummer,
        Kontonummer, Kaufhistorie);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Buchungsinformationen (z. B. Buchungshistorie und -daten, Abhol- und
        R&uuml;ckgabeort und -zeit, Fahrzeugauswahl und -details, Extras und
        Versicherungsdeckung, Unfallinformationen, Buchungsstatus und Details
        zum Treueprogramm);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <a name="_cmntref3"><span>In-App-Nachrichten (z. B. Zeit, Inhalt);</span></a
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <a name="_cmntref4"><span
          >Pr&auml;ziser Standort (Standort des Nutzers oder des Ger&auml;ts mit
          der gleichen oder einer h&ouml;heren Aufl&ouml;sung als Breiten- und
          L&auml;ngengrad mit drei oder mehr Dezimalstellen)</span
        ></a
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <a name="_cmntref5"><span
          >Suchverlauf (z. B. Informationen &uuml;ber die in der App
          durchgef&uuml;hrten Suchen);</span
        ></a
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Nutzungsdaten (z. B. Interaktion mit unserer App, wie z. B. Starts der
        App, Taps, Klicks, Scrollen, Musikh&ouml;rdaten, Videoaufrufe und andere
        Informationen dar&uuml;ber, wie der Nutzer mit der App
        interagiert);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Werbedaten (z. B. Informationen &uuml;ber die Werbung, die der Nutzer
        gesehen hat);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Diagnosedaten (z. B. Absturzprotokolle, Startzeit, H&auml;ngerquote,
        Energieverbrauch und andere Informationen &uuml;ber die Leistung der
        App)</span
      >
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Als Franchisenehmer von Enterprise Holdings, Inc. und/oder einer ihrer
    Tochtergesellschaften (&quot;<b>EHI</b>&quot;) kann EHI zur
    Unterst&uuml;tzung globaler Buchungssysteme Ihre Reservierungs- und
    Buchungsdaten an uns weitergeben. Wir geben auch Buchungsinformationen
    &uuml;ber Sie an EHI weiter, wie unten im entsprechenden Abschnitt
    beschrieben.
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    2<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Wie wir personenbezogene Daten erheben
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir erheben Informationen &uuml;ber unsere Nutzer, wenn sie unsere App
    nutzen, einschliesslich der Durchf&uuml;hrung bestimmter Aktionen innerhalb
    der App.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt"><b>Unmittelbar</b></p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Wenn sich Nutzer in unserer App registrieren;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Wenn Nutzer auf unsere App zugreifen, sie nutzen oder anderweitig mit
        ihr interagieren;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Wenn Nutzer mit uns auf elektronischem Wege korrespondieren;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Wenn Nutzer ihre Daten an uns &uuml;bermitteln.</span>
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt"><b>Indirekt</b></p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Von Dienstleistern wie Marktforschungs-, Werbe- und
        Analyseunternehmen;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Von Apps Dritter;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Von Integrationen Dritter, die in unserer App enthalten sind;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Durch &ouml;ffentliche Quellen, wie &ouml;ffentliche Register (z. B.
        Handelsregister), Nachrichtenartikel, Sanktionslisten und
        Internetrecherchen;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Wenn EHI Ihre Reservierungs- und Buchungsinformationen an uns
        weitergibt.</span
      >
    </li>
  </ul>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    3<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Rechtsgrundlagen und Zwecke
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Unsere Rechtsgrundlage f&uuml;r die Erhebung und Verwendung der in dieser
    Datenschutzerkl&auml;rung beschriebenen personenbezogenen Daten h&auml;ngt
    von den personenbezogenen Daten ab, die wir erheben, sowie von den
    spezifischen Zwecken, f&uuml;r die wir sie erheben.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <b>Vertrag</b>: Zur Erf&uuml;llung unserer vertraglichen Verpflichtungen
    oder zur Durchf&uuml;hrung von Massnahmen im Zusammenhang mit einem Vertrag
    mit Ihnen. Im Besonderen:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Vertragserf&uuml;llung: Zur Erbringung unserer Dienstleistungen.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Kunden-Support: Um Ihnen Support zu bieten.</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Konto-Verwaltung: Zum Einrichten und Verwalten Ihres Kontos in unserer
        App sowie zur &Uuml;berpr&uuml;fung Ihrer Anmeldedaten beim
        Anmelden.</span
      >
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <a name="_cmntref6"><b>Einwilligung</b>:</a
    >
    Wir k&ouml;nnen uns auf Ihre zum Zeitpunkt der Bereitstellung Ihrer
    personenbezogenen Daten freiwillig erteilte Einwilligung berufen. Dies gilt
    insbesondere f&uuml;r:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <a name="_cmntref7"><span
          >Marketing: Um Sie mit Neuigkeiten, Sonderangeboten und allgemeinen
          Informationen &uuml;ber Waren, Dienstleistungen und Veranstaltungen,
          die wir anbieten, mittels Push-Benachrichtigungen, Anzeigen oder
          Newslettern zu versorgen.</span
        ></a
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Werbung von Drittanbietern: Um Sie mit Nachrichten, Sonderangeboten und
        allgemeinen Informationen &uuml;ber Waren, Dienstleistungen und
        Veranstaltungen zu versorgen, die von unseren Partnern angeboten werden,
        und zwar mittels Push-Benachrichtigungen, Anzeigen oder
        Newslettern.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Analyse und Tracking: Zur Analyse, Verbesserung, Personalisierung und
        &Uuml;berpr&uuml;fung der Nutzung unserer App und Kommunikation.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Produktpersonalisierung: Um das, was Sie sehen, individuell anzupassen,
        z. B. empfohlene Produkte, Beitr&auml;ge, Vorschl&auml;ge.</span
      >
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <b>Legitime Interessen</b>: Wir k&ouml;nnen uns auf berechtigte Interessen
    berufen, die auf unserer Einsch&auml;tzung beruhen, dass die Verarbeitung
    fair und angemessen ist und Ihre Interessen oder Grundrechte und
    Grundfreiheiten nicht &uuml;berwiegen. Dies gilt insbesondere f&uuml;r:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li style="margin-left: 27.6pt; padding-left: 8.4pt">
      <span
        >App-Funktionalit&auml;t und Sicherheit: Zur Bereitstellung, Wartung und
        Verbesserung unserer App sowie zur Erkennung, Verhinderung und Behebung
        von Sicherheitsrisiken.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Mitteilungen f&uuml;r Entwickler: Um Sie &uuml;ber &Auml;nderungen an
        unserer App und unseren Datenschutzerkl&auml;rungen sowie &uuml;ber die
        Performance unserer Dienstleistungen zu informieren.&nbsp;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Alterskontrolle: Um Ihr Alter zu &uuml;berpr&uuml;fen.</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Entwicklung von Dienstleistungen: Entwicklung neuer
        Dienstleistungen.</span
      >
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <b>Notwendigkeit zur Erf&uuml;llung gesetzlicher Verpflichtungen:</b> Zur
    Erf&uuml;llung gesetzlicher und im &ouml;ffentlichen Interesse liegender
    Verpflichtungen. Dies gilt insbesondere f&uuml;r:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Einhaltung der Gesetze: Einhaltung der geltenden Vorschriften und
        Gesetze.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Aus&uuml;bung von Rechten: Zur gerichtlichen Durchsetzung von
        Anspr&uuml;chen und Rechten.</span
      >
    </li>
  </ul>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    4<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Speicherfristen
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir bewahren personenbezogene Daten so lange auf, wie sie f&uuml;r die
    Zwecke, f&uuml;r die sie erhoben wurden, oder im Einklang mit gesetzlichen
    und regulatorischen Anforderungen oder vertraglichen Vereinbarungen
    ben&ouml;tigt werden.
    <span style="background-color: #ffffff">Wir l&ouml;schen die Geolokalisierungsdaten innerhalb von 6 Monaten nach
      dem Ende Ihres Mietverh&auml;ltnisses.</span
    >
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    5<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >EHI
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir teilen Ihre pers&ouml;nlichen Daten mit EHI f&uuml;r folgende Zwecke:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
        color: #212529;
      "
    >
      <span>Verwaltung und Erleichterung globaler Reservierungen;&nbsp;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
        color: #212529;
      "
    >
      <span
        >Mietinformationen f&uuml;r EHI und seine Drittdienstleister zur
        Durchf&uuml;hrung von Umfragen zur Kundenzufriedenheit;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
        color: #212529;
      "
    >
      <span
        >Zur Erstellung von Statistiken und Analysen &uuml;ber die Nutzung der
        Produkte und Dienstleistungen von EHI, einschliesslich Statistiken auf
        der Grundlage anonymisierter Daten, die es EHI erm&ouml;glichen, Ihnen
        und anderen Kunden in Zukunft einen besseren Kundenservice, bessere
        Produkte, Features und Funktionen zu bieten.</span
      >
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <span style="background-color: #ffffff">Bei der Weitergabe solcher Informationen an EHI agieren wir und EHI als
      unabh&auml;ngige Verantwortliche f&uuml;r die Daten. EHI unterh&auml;lt
      seine eigene Datenschutzerkl&auml;rung in Bezug auf die Informationen, die
      wir EHI zur Verf&uuml;gung stellen. Wir empfehlen Ihnen, die
      Datenschutzerkl&auml;rung von EHI zu lesen, die Sie hier finden
      k&ouml;nnen:</span
    ><a href="https://privacy.ehi.com"  target="_blank" style="text-decoration: none"><u><span style="color: rgb(5, 99, 193);">&nbsp;</span></u
      ><u><span style="color: rgb(5, 99, 193);">https://privacy.ehi.com</span></u></a
    ><u><span style="color: rgb(5, 99, 193);">&nbsp;.</span></u>
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    6<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><a name="_cmntref8">Externe Dienstleister</a
    >
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir k&ouml;nnen Drittunternehmen (&quot;<b>Dienstleister</b>&quot;) damit
    beauftragen, den Betrieb unserer App zu erleichtern, die App in unserem
    Namen bereitzustellen, App-bezogene Dienstleistungen zu erbringen, uns bei
    der Analyse der Nutzung unserer App zu unterst&uuml;tzen oder uns dabei zu
    helfen, Ihnen massgeschneiderte Angebote und exklusive Deals zu
    unterbreiten. Diese Dritten haben nur Zugang zu Ihren personenbezogenen
    Daten, um diese Aufgaben in unserem Auftrag zu erf&uuml;llen, und sind
    verpflichtet, diese nicht f&uuml;r andere Zwecke weiterzugeben oder zu
    verwenden.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Kategorie(n) von Dienstleistern, die auf Ihre personenbezogenen Daten
    zugreifen k&ouml;nnen:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Buchhaltungsdienstleister</span
      ><a name="_cmntref9"><span>;</span></a>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Banken und Zahlungsdienstleister;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Anbieter von Cloud-Speicherdiensten;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Andere Unternehmen des Konzerns;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Anbieter von KYC-Dienstleistungen;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Marketingfirmen und Anbieter von Mailinglisten;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>&Ouml;ffentliche Beh&ouml;rden.</span
      >
    </li>
  </ul>
  <h2
    style="margin-top: 12pt;
      margin-left: 28.8pt;
      margin-bottom: 12pt;
      text-indent: -28.8pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 12pt;"
  >
    6.1<span
      style="width: 12.12pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Google OAuth
  </h2>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Google OAuth ist ein Registrierungs- und Authentifizierungsdienst, der von
    Google LLC oder von Google Ireland Limited betrieben wird, je nachdem, von
    welchem Standort aus auf diese App zugegriffen wird und sie mit dem
    Google-Netzwerk verbunden ist.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Weitere Informationen finden Sie unter
    <a href="https://policies.google.com/privacy" target="_blank"  style="text-decoration: none"><u
        ><span style="color: rgb(5, 99, 193);"  >https://policies.google.com/privacy.</span
        ></u
      ></a
    >
  </p>
  <h2
    style="margin-top: 12pt;
      margin-left: 28.8pt;
      margin-bottom: 12pt;
      text-indent: -28.8pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 12pt;"
  >
    6.2<span
      style="width: 12.12pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Mit Apple anmelden
  </h2>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Die Anmeldung bei Apple wird von Apple Distribution International Limited
    oder Apple Inc. bereitgestellt, je nachdem, von welchem Standort aus auf
    diese App zugegriffen wird. &quot;Mit Apple anmelden&quot; ist ein
    Registrierungs- und Authentifizierungsdienst, der mit dem Apple-Netzwerk
    verbunden ist.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Weitere Informationen finden Sie unter
    <a
    href="https://www.apple.com/de/legal/privacy/de-ww/"  target="_blank" style="text-decoration: none"><u
      ><span style="color: rgb(5, 99, 193);"  >https://www.apple.com/de/legal/privacy/de-ww/</span
      ></u
    ></a
  >.
  </p>
  <h2
    style="margin-top: 12pt;
      margin-left: 28.8pt;
      margin-bottom: 12pt;
      text-indent: -28.8pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 12pt;"
  >
    6.3<span
      style="width: 12.12pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><a name="_cmntref10">Google Maps</a
    >
  </h2>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Unsere Website benutzt den Kartendienst Google Maps, der von Google Ireland
    Limited, Google Building Gordon House, 4 Barrow St, Grand Canal Dock, Dublin
    4, D04 V4X7, Irland, f&uuml;r Personen aus der Schweiz und dem
    Europ&auml;ischen Wirtschaftsraum angeboten wird. Damit das von uns
    verwendete Google-Kartenmaterial eingebunden werden kann, muss Ihr
    Webbrowser beim Aufruf unserer Website eine Verbindung zu einem
    Google-Server herstellen, der sich auch in den USA befinden kann. Durch die
    Einbindung des Kartenmaterials erh&auml;lt Google die Information, dass
    &uuml;ber die IP-Adresse Ihres Ger&auml;tes eine Seite unseres
    Internetauftritts aufgerufen wurde. Wenn Sie den Google-Kartendienst auf
    unserer Website aufrufen, w&auml;hrend Sie in Ihrem Google-Profil angemeldet
    sind, kann dies auch mit Ihrem Google-Profil verkn&uuml;pft werden. Wenn Sie
    die Verkn&uuml;pfung mit Ihrem Google-Profil nicht w&uuml;nschen,
    m&uuml;ssen Sie sich vor dem Zugriff auf unsere entsprechende Website bei
    Google abmelden.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Weitere Informationen finden Sie in den
    <a href="https://policies.google.com/privacy?" target="_blank"   style="text-decoration: none"><u><span style="color: rgb(5, 99, 193);">Datenschutzbestimmungen</span></u></a
    >
    von Google und in den zus&auml;tzlichen AGB f&uuml;r
    <a
      href="https://www.google.com/help/terms_maps/" target="_blank" style="text-decoration: none"><u><span style="color: rgb(5, 99, 193);">Google Maps</span></u></a
    >.
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    7<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Daten&uuml;bermittlungen
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <a name="_Hlk126941960">Wir und/oder unsere Dienstleister k&ouml;nnen Ihre personenbezogenen
      Daten an folgende Orte &uuml;bermitteln und dort verarbeiten:</a
    >
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>EU/EWR;</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>USA.</span>
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir k&ouml;nnen Dienstleister einsetzen, die sich teilweise in sogenannten
    Drittl&auml;ndern (au&szlig;erhalb der Europ&auml;ischen Union oder des
    Europ&auml;ischen Wirtschaftsraums bzw. der Schweiz) befinden oder dort
    personenbezogene Daten verarbeiten, d.h. in L&auml;ndern, deren
    Datenschutzniveau nicht dem der EU bzw. der Schweiz entspricht.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir sch&uuml;tzen Ihre personenbezogenen Daten gem&auml;ss unseren
    vertraglichen Verpflichtungen und den geltenden Datenschutzgesetzen, wenn
    wir Daten ins Ausland &uuml;bermitteln.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <span style="background-color: #ffffff">Zu diesen Schutzmassnahmen kann geh&ouml;ren:</span
    >
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <a name="_Hlk128585377"><span style="background-color: #ffffff"  >die &Uuml;bermittlung in L&auml;nder, die nach den vom
          Eidgen&ouml;ssischen Datenschutz- und &Ouml;ffentlichkeitsbeauftragten
          ver&ouml;ffentlichten L&auml;nderlisten ein angemessenes Schutzniveau
          bieten;&nbsp;</span
        ></a
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Anwendung von Standardvertragsklauseln, verbindlichen
        Unternehmensregeln oder anderen Standard-Vertragspflichten, die einen
        angemessenen Datenschutz gew&auml;hrleisten.</span
      >
    </li>
  </ul>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Sofern eine Drittstaats&uuml;bermittlung stattfindet und kein
    Angemessenheitsbeschluss oder geeignete Garantien vorliegen, ist es
    m&ouml;glich und besteht das Risiko, dass Beh&ouml;rden im Drittland (z.B.
    Geheimdienste) Zugriff auf die &uuml;bermittelten Daten erlangen k&ouml;nnen
    und dass eine Durchsetzbarkeit der Betroffenenrechte nicht
    gew&auml;hrleistet werden kann.
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    8<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Datenweitergabe
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir k&ouml;nnen Ihre personenbezogenen Daten offenlege, wenn wir in gutem
    Glauben davon ausgehen, dass eine solche Massnahme notwendig ist:
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Um einer gesetzlichen Verpflichtung nachzukommen (d. h. wenn dies
        gesetzlich vorgeschrieben ist oder als Reaktion auf berechtigte Anfragen
        von Beh&ouml;rden, wie z. B. einem Gericht oder einer
        Regierungsbeh&ouml;rde);</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Um die Sicherheit der App zu sch&uuml;tzen und unsere Rechte oder unser
        Eigentum zu verteidigen;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span
        >Um m&ouml;gliches Fehlverhalten in Verbindung mit uns zu verhindern
        oder zu untersuchen;</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <span>Um uns gegen rechtliche Haftung zu verteidigen.</span>
    </li>
  </ul>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    9<span
      style="width: 13.81pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Datensicherheit
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir setzen angemessene technische und organisatorische
    Sicherheitsma&szlig;nahmen ein, um Ihre gespeicherten Daten vor
    Manipulation, Verlust oder unberechtigtem Zugriff Dritter zu sch&uuml;tzen.
    Unsere Sicherheitsma&szlig;nahmen werden entsprechend der technologischen
    Entwicklung fortlaufend angepasst.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Auch den internen Datenschutz nehmen wir sehr ernst. Unsere Mitarbeiter und
    die von uns beauftragten Dienstleister sind zur Verschwiegenheit und zur
    Einhaltung der geltenden Datenschutzgesetze verpflichtet. Dar&uuml;ber
    hinaus erhalten sie nur insoweit Zugang zu personenbezogenen Daten, als dies
    f&uuml;r die Erf&uuml;llung ihrer jeweiligen Aufgaben oder ihres Auftrags
    erforderlich ist.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Die Sicherheit Ihrer personenbezogenen Daten ist uns wichtig, aber bedenken
    Sie, dass keine Methode der &Uuml;bertragung &uuml;ber das Internet oder der
    elektronischen Speicherung zu 100 % sicher ist. Auch wenn wir uns
    bem&uuml;hen, Ihre personenbezogenen Daten mit wirtschaftlich vertretbaren
    Mitteln zu sch&uuml;tzen, k&ouml;nnen wir keine absolute Sicherheit
    garantieren.
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    10<span
      style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Ihre Rechte
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Sie haben die folgenden Datenschutzrechte. Um diese Rechte auszu&uuml;ben,
    k&ouml;nnen Sie sich an die oben genannte Adresse wenden oder eine E-Mail
    senden an: support@enterprise-go.ch. Sie k&ouml;nnen diese Rechte auch in
    den App-Einstellungen aus&uuml;ben. Bitte beachten Sie, dass wir Sie
    m&ouml;glicherweise auffordern, Ihre Identit&auml;t zu &uuml;berpr&uuml;fen,
    bevor wir auf solche Anfragen antworten.
  </p>
  <ul type="disc" style="margin: 0pt; padding-left: 0pt">
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b><span>Recht auf Auskunft</span></b
      ><span
        >: Sie haben das Recht, eine Kopie Ihrer personenbezogenen Daten
        anzufordern, die wir Ihnen in elektronischer Form zur Verf&uuml;gung
        stellen werden.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b><span>Recht auf Berichtigung</span></b
      ><span
        >: Sie haben das Recht, uns zu bitten, unsere Aufzeichnungen zu
        korrigieren, wenn Sie glauben, dass sie falsche oder unvollst&auml;ndige
        Informationen &uuml;ber Sie enthalten.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b
        ><span
          >Recht auf Widerruf der Einwilligung: Wenn Sie Ihre Einwilligung zur
          Verarbeitung Ihrer personenbezogenen Daten gegeben haben, haben Sie
          das Recht, Ihre Einwilligung mit Wirkung f&uuml;r die Zukunft zu
          widerrufen. Dies gilt auch f&uuml;r den Fall, dass Sie sich von
          Marketingmitteilungen abmelden m&ouml;chten. Sobald wir die Mitteilung
          erhalten haben, dass Sie Ihre Einwilligung widerrufen haben, werden
          wir Ihre Daten nicht mehr f&uuml;r den/die Zweck(e) verarbeiten, in
          den/die Sie urspr&uuml;nglich eingewilligt haben, es sei denn, es gibt
          eine andere Rechtsgrundlage f&uuml;r die Verarbeitung. &Uuml;ber die
          App-Einstellungen k&ouml;nnen Sie Ihre Einwilligung anpassen. Um keine
          E-Mails mehr von uns zu erhalten, &auml;ndern Sie bitte Ihre
          Datenschutzeinstellungen in den App-Einstellungen oder kontaktieren
          Sie uns unter&nbsp;</span
        ></b
      ><span>support@enterprise-go.ch.</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b><span>Recht auf L&ouml;schung</span></b
      ><span
        >: Sie haben das Recht, von uns die L&ouml;schung Ihrer
        personenbezogenen Daten zu verlangen, wenn diese f&uuml;r die Zwecke,
        f&uuml;r die sie erhoben wurden, nicht mehr erforderlich sind oder
        unrechtm&auml;ssig verarbeitet wurden.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b><span>Recht auf Einschr&auml;nkung der Verarbeitung</span></b
      ><span
        >: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, wenn Sie der Ansicht sind, dass
        die Daten unrichtig sind, die Verarbeitung unrechtm&auml;ssig ist oder
        wir die Daten f&uuml;r den urspr&uuml;nglichen Zweck nicht mehr
        verarbeiten m&uuml;ssen, wir sie aber aufgrund einer gesetzlichen
        Verpflichtung nicht l&ouml;schen k&ouml;nnen oder weil Sie dies nicht
        w&uuml;nschen.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b><span>Recht auf Daten&uuml;bertragbarkeit</span></b
      ><span
        >: Sie haben das Recht zu verlangen, dass wir Ihre personenbezogenen
        Daten in einem Standardformat wie Excel an einen anderen
        Verantwortlichen &uuml;bermitteln, sofern es sich um Daten handelt, die
        Sie uns zur Verf&uuml;gung gestellt haben und die wir auf der
        Rechtsgrundlage Ihrer Einwilligung oder zur Erf&uuml;llung unserer
        vertraglichen Verpflichtungen verarbeiten.</span
      >
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b
        ><span
          >Recht auf Widerspruch gegen die Verarbeitung: Wenn die
          Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer personenbezogenen
          Daten unser berechtigtes Interesse ist, haben Sie das Recht, aus
          Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben,
          Widerspruch gegen diese Verarbeitung einzulegen. Wir werden Ihrem
          Wunsch nachkommen, es sei denn, wir haben eine zwingende
          Rechtsgrundlage f&uuml;r die Verarbeitung, die Ihre Interessen
          &uuml;berwiegt, oder wir m&uuml;ssen die personenbezogenen Daten zur
          Aus&uuml;bung oder Verteidigung eines Rechtsanspruchs
          weiterverarbeiten</span
        ></b
      ><span>.</span>
    </li>
    <li
      style="margin-top: 6pt;
        margin-left: 27.6pt;
        margin-bottom: 6pt;
        padding-left: 8.4pt;
      "
    >
      <b><span>Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde</span></b
      ><span
        >: Sie haben das Recht auf Beschwerde bei einer
        Datenschutzaufsichtsbeh&ouml;rde, wenn Sie der Meinung sind, dass die
        Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht
        verst&ouml;sst. Sie sind berechtigt, sich an die zust&auml;ndige
        Aufsichtsbeh&ouml;rde zu wenden - in der Schweiz an den
        Eidgen&ouml;ssischen Datenschutz- und &Ouml;ffentlichkeitsbeauftragten,
        Feldeggweg 1, CH - 3003 Bern, info@edoeb.admin.ch.</span
      >
    </li>
  </ul>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    11<span
      style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><a name="_cmntref11">Links zu Apps und Websites Dritter</a
    >
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Unsere App kann Links zu Websites oder Apps enthalten, die nicht von uns
    betrieben werden. Wenn Sie auf einen Link eines Drittanbieters klicken,
    werden Sie auf die Website oder App dieses Drittanbieters weitergeleitet.
    Wir haben keine Kontrolle &uuml;ber und &uuml;bernehmen keine Verantwortung
    f&uuml;r den Inhalt, die Datenschutzerkl&auml;rungen oder die Praktiken von
    Websites oder Dienstleistungen Dritter.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir unterhalten Online-Pr&auml;senzen in sozialen Netzwerken, um u. a. mit
    Kunden und Interessenten zu kommunizieren und Informationen &uuml;ber unsere
    Produkte und Dienstleistungen bereitzustellen. Wenn Sie ein Konto bei
    demselben Netzwerk haben, ist es m&ouml;glich, dass Ihre dort zur
    Verf&uuml;gung gestellten Informationen und Medien von uns eingesehen werden
    k&ouml;nnen, wenn wir beispielsweise auf Ihr Profil zugreifen. Dar&uuml;ber
    hinaus kann das soziale Netzwerk uns erm&ouml;glichen, mit Ihnen in Kontakt
    zu treten. Die inhaltliche Kommunikation &uuml;ber das soziale Netzwerk und
    die Verarbeitung der Inhaltsdaten unterliegt dabei der Verantwortung des
    sozialen Netzwerks. Sobald wir personenbezogene Daten in unser eigenes
    System &uuml;bertragen, sind wir daf&uuml;r selbst verantwortlich. Dies
    geschieht dann, um vorvertragliche Massnahmen durchzuf&uuml;hren und einen
    Vertrag zu erf&uuml;llen. Die Rechtsgrundlagen f&uuml;r die
    Datenverarbeitung durch die sozialen Netzwerke in eigener Verantwortung
    entnehmen Sie bitte deren Datenschutzerkl&auml;rungen. Nachfolgend finden
    Sie eine Liste der sozialen Netzwerke, in denen wir eine Online-Pr&auml;senz
    betreiben:
  </p>
  <p
    style="margin-top: 6pt;
      margin-left: 61.25pt;
      margin-bottom: 6pt;
      text-indent: -39.7pt;"
  >
    - Facebook:
    <a
      href="https://www.facebook.com/privacy/policy?_rdr" target="_blank" style="text-decoration: none"><u><span style="color: rgb(5, 99, 193);">Datenschutzerkl&auml;rung</span></u></a
    >
  </p>
  <p
    style="margin-top: 6pt;
      margin-left: 61.25pt;
      margin-bottom: 6pt;
      text-indent: -39.7pt;"
  >
    - Instagram:
    <a
      href="https://help.instagram.com/155833707900388" target="_blank" style="text-decoration: none"><u
        ><span style="color: rgb(5, 99, 193);">Datenschutzerkl&auml;rung&nbsp;</span></u
      ></a
    >
  </p>
  <p
    style="margin-top: 6pt;
      margin-left: 61.25pt;
      margin-bottom: 6pt;
      text-indent: -39.7pt;"
  >
    - LinkedIn:
    <a
      href="https://www.linkedin.com/legal/privacy-policy?" target="_blank" style="text-decoration: none"><u
        ><span style="color: rgb(5, 99, 193);">Datenschutzerkl&auml;rung&nbsp;</span></u
      ></a
    >
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    12<span
      style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
    >&Auml;nderungen dieser Datenschutzerkl&auml;rung
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wir k&ouml;nnen unsere Datenschutzerkl&auml;rung von Zeit zu Zeit
    aktualisieren. Wir empfehlen Ihnen daher, diese Datenschutzerkl&auml;rung
    regelm&auml;ssig auf etwaige &Auml;nderungen zu &uuml;berpr&uuml;fen.
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    <a name="_cmntref12">Wir werden Sie per E-Mail und/oder durch eine auff&auml;llige Mitteilung
      in unserer App benachrichtigen, bevor die &Auml;nderung in Kraft tritt,
      und das Datum des Inkrafttretens oben in dieser Datenschutzerkl&auml;rung
      aktualisieren, aber wir empfehlen Ihnen, diese Datenschutzerkl&auml;rung
      regelm&auml;ssig auf &Auml;nderungen zu &uuml;berpr&uuml;fen</a
    >
    .
  </p>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    &Auml;nderungen an dieser Datenschutzerkl&auml;rung treten in Kraft, wenn
    sie auf dieser Seite ver&ouml;ffentlicht werden.
  </p>
  <h1
    style="margin-top: 18pt;
      margin-left: 21.6pt;
      margin-bottom: 12pt;
      text-indent: -21.6pt;
      page-break-inside: avoid;
      page-break-after: avoid;
      font-size: 14pt;"
  >
    13<span
      style="width: 6.03pt; display: inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span
    >Kontaktieren Sie uns
  </h1>
  <p style="margin-top: 6pt; margin-bottom: 6pt">
    Wenn Sie Fragen zu dieser Datenschutzerkl&auml;rung haben, z&ouml;gern Sie
    nicht, sich mit uns in Verbindung zu setzen: support@enterprise-go.ch.
  </p>
</div>`
};
