import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import configService from 'shared/config.service';
import { Price } from 'shared/models/car-class.model';

export type CarCoverage = {
  id: number;
  name: string;
  acriss: string;
  basePrice: number;
  deductible: number;
  price: Price;
  totalPriceExcludingVATRounded: number;
  totalVATAmountRounded: number;
  totalAmountRounded: number;
};

export type CoveragesRequestData = {
  Acriss: string;
  Hours: number;
};

export const carCoveragesAPI = createApi({
  reducerPath: 'coverages',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configService.baseUrl}/price/coverages/hours`
  }),
  endpoints: (builder) => ({
    getCoveragesList: builder.query<
      CarCoverage[],
      {
        data: CoveragesRequestData;
      }
    >({
      query: ({ data }) => {
        return {
          url: '/',
          params: data,
          method: 'GET'
        };
      }
    })
  })
});

export const { useGetCoveragesListQuery, useLazyGetCoveragesListQuery } = carCoveragesAPI;
