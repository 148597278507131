import { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const headerMobile = width <= 992;
  const isSmallerDesktop = width <= 1000;
  return { isMobile, headerMobile, isSmallerDesktop };
};

export default useIsMobile;
