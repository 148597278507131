import { useEffect } from 'react';
import LocationImportantInformation from '../../../../components/location-overview/location-important-information/LocationImportantInformation';
import { useGetBookingWidgetQuery } from '../../../../store/booking-widget/booking-widget.slice';
import { getSelectedBookingLocations } from '../../../../store/booking/booking.selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  useLazyGetLocationOverviewByIdQuery,
  GetLocationOverviewByIdQuery
} from '../../../../store/locations/location-overview.slice';
import { openLocationOverviewDialog } from '../../../../store/modals/modals.slice';
import useGetAsset from '../../../hooks/useGetAsset';

export default function BookingLocationInformation() {
  const dispatch = useAppDispatch();
  const locationIds = useAppSelector(getSelectedBookingLocations);
  const { data: widgetConfig } = useGetBookingWidgetQuery();
  const informationIcon = useGetAsset(widgetConfig?.goSearchLocationInformation?.id || '');

  const [getPickupLocationOverview, { data: pickupLocationOverview }] =
    useLazyGetLocationOverviewByIdQuery();
  const [getReturnLocationOverview, { data: returnLocationOverview }] =
    useLazyGetLocationOverviewByIdQuery();

  useEffect(() => {
    const pickupLocation = locationIds.pickupLocationId;
    const returnLocation = locationIds.returnLocationId;
    if (pickupLocation) {
      if (pickupLocation !== returnLocation) {
        getReturnLocationOverview({ id: returnLocation });
      }

      getPickupLocationOverview({ id: pickupLocation });
    }
  }, [locationIds]);

  function formatLocationTitle(locationId: number, locationName: string) {
    return (
      <div className="flex mb-3">
        <h2 className="flex m-0 font-normal pb-0 text-2xl">{locationName}</h2>
        <img
          src={informationIcon.url}
          className="ml-3 flex icon-img cursor-pointer"
          onClick={() => dispatch(openLocationOverviewDialog(locationId))}
        />
      </div>
    );
  }

  function formatLocationInformation(location: GetLocationOverviewByIdQuery): JSX.Element {
    return (
      <div className="flex flex-column mb-5">
        {formatLocationTitle(
          location.gOLocation?.rentLocationId || 0,
          location.gOLocation?.gOLocationName || ''
        )}
        <LocationImportantInformation
          goLocationImportantName={location?.gOLocation?.goLocationImportantName || ''}
          goLocationImportantInformation={
            location?.gOLocation?.goLocationImportantInformation || ''
          }
          goLocationOpeningHoursName={location?.gOLocation?.goLocationOpeningHoursName || ''}
          gOLocationOpeningHours={location?.gOLocation?.gOLocationOpeningHours || ''}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-column">
      {pickupLocationOverview && formatLocationInformation(pickupLocationOverview)}
      {returnLocationOverview && formatLocationInformation(returnLocationOverview)}
    </div>
  );
}
