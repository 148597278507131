import { graphQLAPI } from '../api/base-graphql-api';

export type BookingWizard = {
  navigationItems: Array<string>;
  steps: Array<WizardStep>;
};

export type WizardStep = {
  navigationWizardItem: number;
  navigationWizardScreenDisplayOrder: number;
  navigationWizardScreenTitle: string;
};

export type WizardStepResponse = WizardStep & {
  navigationItems: Array<string>;
};

export type GetWizardSteps = {
  goWebappNavigationWizards: Array<WizardStepResponse>;
};

export const GetBookingWidgetSteps = `
query GetNavigationWizard {
  goWebappNavigationWizards(orderBy: navigationWizardScreenDisplayOrder_ASC) {
    navigationItems
    navigationWizardItem
    navigationWizardScreenDisplayOrder
    navigationWizardScreenTitle
  }
}`;

// const bookingWidgetAPI = generateApiWithReducerPath('booking-widget');
const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (builder) => ({
    GetNavigationWizard: builder.query<BookingWizard, void>({
      query: () => ({ document: GetBookingWidgetSteps }),
      transformResponse: (response: GetWizardSteps) => {
        const wizard: BookingWizard = {
          navigationItems: response.goWebappNavigationWizards[0]?.navigationItems,
          steps: response.goWebappNavigationWizards?.map(
            (step: WizardStepResponse) =>
              ({
                navigationWizardItem: step.navigationWizardItem,
                navigationWizardScreenDisplayOrder: step.navigationWizardScreenDisplayOrder,
                navigationWizardScreenTitle: step.navigationWizardScreenTitle
              } as WizardStep)
          )
        };

        return wizard;
      }
    })
  })
});

export { injectedRtkApi as bookingJourneyWizard };
export const { useGetNavigationWizardQuery } = injectedRtkApi;
