import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modals',
  initialState: {
    locationOverview: {
      opened: false,
      locationId: 0
    },
    bookingFlowMobileInputs: {
      opened: false
    },
    downloadAppOverview: {
      opened: false
    },
    legalsOverview: {
      opened: false,
      title: '',
      content: ''
    }
  },
  reducers: {
    openLocationOverviewDialog: (state, action: PayloadAction<number>) => {
      state.locationOverview.locationId = action.payload;
      state.locationOverview.opened = true;
    },
    closeLocationOverviewDialog: (state) => {
      state.locationOverview.opened = false;
      state.locationOverview.locationId = 0;
    },
    openBookingFlowMobileInputs: (state) => {
      state.bookingFlowMobileInputs.opened = true;
    },
    closeBookingFlowMobileInputs: (state) => {
      state.bookingFlowMobileInputs.opened = false;
    },
    openDownloadAppOverview: (state) => {
      state.downloadAppOverview.opened = true;
    },
    closeDownloadAppOverview: (state) => {
      state.downloadAppOverview.opened = false;
    },
    openLegalsOverviewDialog: (
      state,
      action: PayloadAction<{ title: string; content: string }>
    ) => {
      state.legalsOverview.title = action.payload.title;
      state.legalsOverview.content = action.payload.content;
      state.legalsOverview.opened = true;
    },
    closeLegalsOverviewDialog: (state) => {
      state.legalsOverview.opened = false;
      state.legalsOverview.title = '';
      state.legalsOverview.content = '';
    }
  }
});

export const {
  openLocationOverviewDialog,
  closeLocationOverviewDialog,
  openBookingFlowMobileInputs,
  closeBookingFlowMobileInputs,
  openDownloadAppOverview,
  closeDownloadAppOverview,
  openLegalsOverviewDialog,
  closeLegalsOverviewDialog
} = modalSlice.actions;

export default modalSlice.reducer;
