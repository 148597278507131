import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import configService from 'shared/config.service';
import { Price } from 'shared/models/car-class.model';

export type PricesRequestData = {
  acriss: string;
  hours: number;
  coverageId: number;
  extraIds: Array<number>;
  pickUpLocationId: number;
  returnLocationId: number;
  includeLocationTax: boolean;
};

export type PricesRespondData = {
  mileagePrice: Price;
  coveragePrice: Price;
  extraPrices: Array<{
    id: number;
    name: string;
    price: Price;
  }>;
  periodDays: number;
  totalMileagePriceExcludingVATRounded: number;
  totalCoveragePriceExcludingVATRounded: number;
  totalExtrasPriceExcludingVATRounded: number;
  totalMileageVATRounded: number;
  totalCoverageVATRounded: number;
  totalExtrasVATRounded: number;
  totalPriceExludingVATRounded: number;
  totalVATRounded: number;
  totalAmountRounded: number;
  coverageBasePrice: number;
  coverageDeductible: number;
};
export const bookingUserDataPricesAPI = createApi({
  reducerPath: 'prices',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configService.baseUrl}/price/allprices/hours`
  }),
  endpoints: (builder) => ({
    getUserPrices: builder.query<
      PricesRespondData,
      {
        data: PricesRequestData;
      }
    >({
      query: ({ data }) => {
        return {
          url: '/',
          body: data,
          method: 'POST'
        };
      }
    })
  })
});

export const { useGetUserPricesQuery, useLazyGetUserPricesQuery } = bookingUserDataPricesAPI;
