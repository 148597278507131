import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'store/store';
import { client, graphQLAPI } from '../../store/api/base-graphql-api';
import { changeLocale } from './language.slice';
import 'moment/locale/fr';
import 'moment/locale/de';
import { addLocale, locale, localeOptions } from 'primereact/api';
import i18next from '../../i18/i18next';
import { assetsAPI } from 'store/assets/assets.slice';

export const updateCurrentLanguage =
  (language: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    client.setHeader('gcms-locales', language);
    dispatch(changeLocale(language));
    dispatch(graphQLAPI.util.resetApiState());
    dispatch(assetsAPI.util.resetApiState());
    const short = language.slice(0, 2);
    moment.locale(short);
    i18next.changeLanguage(short);
    if (!localeOptions(language)) {
      addLocale(language, localeOptions('en'));
    }
    i18next.changeLanguage(short);
    locale(language);

    // localStorage.setItem('locale', language);
  };
