import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CookieNotice.scss';

const COOKIES_LOCALIZATION_KEY = 'COOKIES.';

enum CookieStatus {
  NONE = '',
  ACCEPTED = 'yes',
  DECLINED = 'no'
}

const COOKIE_STORAGE_KEY = 'cookies-accepted';
const COOKIE_ACCEPTED_DATE_TIME_KEY = 'cookies-accepted-expiry';

export default function CookieNotice() {
  const { t } = useTranslation();
  const [promptVisible, setPromptVisible] = useState(false);

  useEffect(() => {
    const storageCookieStatus = localStorage.getItem(COOKIE_STORAGE_KEY);
    if (storageCookieStatus !== CookieStatus.ACCEPTED) {
      setPromptVisible(true);
    } else {
      // check if expired
      const expiry = Number(localStorage.getItem(COOKIE_ACCEPTED_DATE_TIME_KEY));
      if (expiry && moment.unix(expiry).utc().isSameOrBefore(moment().utc())) {
        localStorage.removeItem(COOKIE_STORAGE_KEY);
        localStorage.removeItem(COOKIE_ACCEPTED_DATE_TIME_KEY);
        setPromptVisible(true);
      }
    }
  }, []);

  function selectPromptOption(option: CookieStatus) {
    if (option === CookieStatus.ACCEPTED) {
      const date = moment().utc().add(1, 'year').format('X');
      localStorage.setItem(COOKIE_ACCEPTED_DATE_TIME_KEY, date);
    }
    localStorage.setItem(COOKIE_STORAGE_KEY, option);
    setPromptVisible(false);
  }

  return promptVisible ? (
    <div className="cookie-notice w-full fixed bottom-0 text-sm font-semibold shadow-4">
      <div className="flex bg-white flex-column md:flex-row md:justify-content-between">
        <div className="flex p-3">
          <span>
            {t(`${COOKIES_LOCALIZATION_KEY}TEXT`)}{' '}
            <a
              className="underline font-light"
              href="https://privacy.ehi.com/en-gb/home.html"
              target="_blank"
              rel="noreferrer">
              {t(`${COOKIES_LOCALIZATION_KEY}LEARN_MORE`)}
            </a>
          </span>
        </div>
        <div className="cookies-button-container flex justify-content-around w-full flex-1">
          <div className="flex w-full justify-content-center">
            <div
              className="cursor-pointer w-full h-full align-items-center flex justify-content-center"
              onClick={() => selectPromptOption(CookieStatus.DECLINED)}>
              {t(`${COOKIES_LOCALIZATION_KEY}DECLINE`)}
            </div>
          </div>
          <div className="flex w-full justify-content-center">
            <a
              className="flex accept-cookies-button w-full h-full align-items-center py-4 md:py-0 px-4 justify-content-center"
              onClick={() => selectPromptOption(CookieStatus.ACCEPTED)}>
              {t(`${COOKIES_LOCALIZATION_KEY}ALLOW`)}
            </a>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
