import { graphQLAPI } from '../api/base-graphql-api';

export type GoSearch = {
  id: string;
  locationTitle?: string | null;
  bookingWidgetTitleWebapp?: string | null;
  bookingWidgetLocationTitle?: string | null;
  goLocationPlaceholderText?: string | null;
  goReturnLocationName?: string | null;
  goPickUpLocation?: string | null;
  goSearchButton: Array<string>;
  goSearchLocationSame?: string | null;
  goPickUpDateTimeNames: Array<string>;
  goCurrentLocationIcon?: { id: string } | null;
  goPickUpIcon?: { id: string } | null;
  goPickUpReturnIcon?: { id: string } | null;
  goSearchLocationInformation?: { id: string } | null;
  goSearchReturnIcon?: { id: string } | null;
  goCurrentLocationIconActive?: { id: string } | null;
  travellingAbroadLinkText?: string | null;
  goLocationInputPlaceholderText?: string | null;
  locationsNearMeText?: string | null;
};

export type GoCalendar = {
  id: string;
  inputFieldPickUpName: string;
  inputFieldReturnName: string;
  inputFieldWeekdayShort: Array<string>;
  month: Array<string>;
  monthAbbreviation: Array<string>;
  weekdaysAbbreviation: Array<string>;
};

export type GetBookingWidgetQuery = {
  gOSearches: Array<GoSearch>;
};

export type GetBookingCalendarQuery = {
  calendars: Array<GoCalendar>;
};

export type BookingWidgetConfig = {
  gOSearch: GoSearch;
  calendar: GoCalendar;
};

export const GetBookingWidgetCalendarDocument = `
query GetBookingCalendar {
  calendars {
    id
    inputFieldPickUpName
    inputFieldReturnName
    inputFieldWeekdayShort
    month
    monthAbbreviation
    weekdaysAbbreviation
  }
}`;

export const GetBookingWidgetDocument = `
query GetBookingWidget() {
  gOSearches {
    id
    locationTitle
    bookingWidgetTitleWebapp
    bookingWidgetLocationTitle
    goLocationPlaceholderText
    goLocationInputPlaceholderText
    goPickUpIcon {
      id
    }
    goPickUpReturnIcon {
      id
      url
    }
    goSearchReturnIcon {
      id
    }
    goReturnLocationName
    goPickUpLocation
    goSearchButton
    goSearchLocationSame
    goPickUpDateTimeNames
    goSearchLocationInformation {
      id
    }
    goCurrentLocationIconActive {
      id
    }
    goCurrentLocationIconInactive {
      id
    }
    travellingAbroadLinkText
    locationsNearMeText
  }
}`;

// const bookingWidgetAPI = generateApiWithReducerPath('booking-widget');
const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetBookingWidget: build.query<GoSearch, void>({
      query: () => ({ document: GetBookingWidgetDocument }),
      transformResponse: (response: GetBookingWidgetQuery) => {
        return response?.gOSearches[2] || ({} as GoSearch);
      }
    }),
    GetBookingCalendar: build.query<GoCalendar, void>({
      query: () => ({ document: GetBookingWidgetCalendarDocument }),
      transformResponse: (response: GetBookingCalendarQuery) => {
        return response.calendars[0] || ({} as GoCalendar);
      }
    })
  })
});

export { injectedRtkApi as bookingWidgetAPI };
export const {
  useGetBookingWidgetQuery,
  useLazyGetBookingWidgetQuery,
  useGetBookingCalendarQuery,
  usePrefetch
} = injectedRtkApi;
