import { useEffect } from 'react';
import { useLazyGetAssetByIdQuery } from '../../store/assets/assets.slice';

const useGetAsset = (id: string, width?: number, height?: number) => {
  const [getAsset, { data: asset, isFetching }] = useLazyGetAssetByIdQuery();

  useEffect(() => {
    if (id) {
      getAsset({ id, width, height }, true);
    }
  }, [id]);

  return { url: asset?.asset?.url || '', isFetching: isFetching, fileName: asset?.asset?.fileName };
};

export default useGetAsset;
