import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { languageSlice } from './language/language.slice';
import { bookingSlice } from './booking/booking.slice';
import { locationsAPI } from './locations/locations.slice';
import { assetsAPI } from './assets/assets.slice';
import { modalSlice } from './modals/modals.slice';
import { graphQLAPI } from './api/base-graphql-api';
import { carClassesAPI } from './classes/car-classes.slice';
import { bookingUserDataPricesAPI } from './booking-overview/booking-user-data-prices.slice';
import { carExtrasAPI } from './extras/extras.slice';
import { carCoveragesAPI } from './coverages/coverages.slice';
import { loaderSlice } from './loader/loader.slice';
import { errorSlice } from './errors/errors.slice';
import { reduxQueryErrorLogger } from './error.middleware';

export const rootReducers = combineReducers({
  [languageSlice.name]: languageSlice.reducer,
  [bookingSlice.name]: bookingSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [locationsAPI.reducerPath]: locationsAPI.reducer,
  [assetsAPI.reducerPath]: assetsAPI.reducer,
  [carClassesAPI.reducerPath]: carClassesAPI.reducer,
  [graphQLAPI.reducerPath]: graphQLAPI.reducer,
  [bookingUserDataPricesAPI.reducerPath]: bookingUserDataPricesAPI.reducer,
  [carExtrasAPI.reducerPath]: carExtrasAPI.reducer,
  [carCoveragesAPI.reducerPath]: carCoveragesAPI.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [errorSlice.name]: errorSlice.reducer
});

const store = configureStore({
  reducer: rootReducers,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      graphQLAPI.middleware,
      assetsAPI.middleware,
      locationsAPI.middleware,
      carClassesAPI.middleware,
      carExtrasAPI.middleware,
      carCoveragesAPI.middleware,
      bookingUserDataPricesAPI.middleware,
      reduxQueryErrorLogger
    )
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducers>;

export default store;
