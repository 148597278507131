import { Dialog } from 'primereact/dialog';
import PrimaryText from '../../shared/components/text/PrimaryText';
import MarkdownText from '../../shared/components/text/MarkdownText';
import GalleryComponent from '../../shared/components/gallery/GalleryComponent';
import { useLazyGetLocationOverviewByIdQuery } from '../../store/locations/location-overview.slice';
import { useEffect } from 'react';
import ExternalLink from '../../shared/components/external-link/ExternalLink';
import LocationImportantInformation from './location-important-information/LocationImportantInformation';
import { useSelector } from 'react-redux';
import { closeLocationOverviewDialog } from '../../store/modals/modals.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import './LocationOverview.scss';
import { getLocationOverviewModal } from 'store/modals/modal.selectors';

const baseDialogConfig = {
  draggable: false,
  dismissableMask: true,
  focusOnShow: false,
  resizable: false,
  maximized: true
};

export default function LocationOverview() {
  const dispatch = useAppDispatch();

  const locationOverviewModal = useAppSelector(getLocationOverviewModal);
  const [getLocationOverview, { data }] = useLazyGetLocationOverviewByIdQuery();

  useEffect(() => {
    if (locationOverviewModal.opened) {
      getLocationOverview({ id: locationOverviewModal.locationId }, true);
    }
  }, [locationOverviewModal]);

  const header = (
    <header className="flex">
      <h2 className="w-11 m-0 font-normal pb-0">{data?.gOLocation?.gOLocationName}</h2>
    </header>
  );

  function onDialogClose() {
    dispatch(closeLocationOverviewDialog());
  }

  return (
    <>
      {locationOverviewModal.opened && (
        <Dialog
          {...baseDialogConfig}
          header={header}
          visible={locationOverviewModal.opened}
          onHide={() => onDialogClose()}
          className="location-overview-dialog"
          headerClassName="border-bottom-none pb-1"
          contentClassName="pt-0">
          <main className="flex flex-column">
            <section className="w-12 mb-3">
              <ExternalLink
                url={data?.gOLocation?.goLocationMapLink || ''}
                text={data?.gOLocation?.googleMapsLinkText || ''}
              />
            </section>
            <section className="w-12 mb-3">
              <GalleryComponent images={data?.gOLocation?.goLocationImage || []}></GalleryComponent>
            </section>
            <section className="w-12 mb-3">
              <div className="flex flex-wrap flex-column">
                <section className="w-12 flex-column mb-3">
                  <LocationImportantInformation
                    goLocationImportantName={data?.gOLocation?.goLocationImportantName || ''}
                    goLocationImportantInformation={
                      data?.gOLocation?.goLocationImportantInformation || ''
                    }
                    goLocationOpeningHoursName={data?.gOLocation?.goLocationOpeningHoursName || ''}
                    gOLocationOpeningHours={data?.gOLocation?.gOLocationOpeningHours || ''}
                  />
                </section>
                <section className="w-12 flex-column mb-3">
                  <PrimaryText
                    text={data?.gOLocation?.goLocationDirectionsName}
                    className="font-semibold text-lg mb-2"
                  />
                  <MarkdownText
                    paragraphClassName="my-1 flex-1 text-sm line-height-2 text-color-muted mb-2"
                    strongClassName="text-color-secondary mb-1"
                    text={data?.gOLocation?.goLocationDirectionsDescription}
                  />
                </section>
              </div>
            </section>
          </main>
        </Dialog>
      )}
    </>
  );
}
