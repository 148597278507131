import { Locale } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from 'shared/components/loader/Loader';
import { CarClass, CarSubClass } from 'shared/models/car-class.model';
import {
  setBackUrl,
  setClassId,
  setPickupAndReturnDate,
  setPickupLocation,
  setPickupTime,
  setReturnLocation,
  setReturnLocationSameAsPickup,
  setReturnTime,
  updateBookingAnalyticsData
} from 'store/booking/booking.slice';
import { updateSubclassAndAcriss } from 'store/booking/booking.thunk';
import { useLazyGetCarClassAndSubclassBySubclassHygraphIdQuery } from 'store/classes/car-classes-overview.slice';
import { useGetClassesListQuery } from 'store/classes/car-classes.slice';
import { useAppDispatch } from 'store/hooks';
import { updateCurrentLanguage } from 'store/language/language.thunk';
import { setLoaderState } from 'store/loader/loader.slice';
import { useLazyGetLocationOverviewByHygraphIdQuery } from 'store/locations/location-overview.slice';

const LandingComponent = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [fetchLocation, { data }] = useLazyGetLocationOverviewByHygraphIdQuery();
  const [fetchClass, { data: subclassData }] =
    useLazyGetCarClassAndSubclassBySubclassHygraphIdQuery();
  const { data: classes } = useGetClassesListQuery();
  const navigate = useNavigate();
  const [acriss, setAcriss] = useState('');

  const [queryDone, setQueryDone] = useState(false);

  useEffect(() => {
    getQueryArgs();
  }, [searchParams]);

  useEffect(() => {
    if (queryDone && classes?.length && acriss) {
      let subclass: CarSubClass;
      let carClass: CarClass;
      classes.forEach((c) => {
        const res = c.subclasses.find((s) => s.goClassesMappings.find((m) => m.aCRISS === acriss));
        if (res) {
          carClass = c;
          subclass = res;
        }
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (subclass && carClass?.id) {
        dispatch(setClassId(Number(carClass.id)));

        dispatch(
          updateBookingAnalyticsData({
            carClass: carClass.name
          })
        );

        dispatch(
          updateSubclassAndAcriss({
            subclassId: Number(subclass.id),
            acriss: acriss || ''
          })
        );

        navigate('/booking/extras');
      }
    }
  }, [classes, acriss, queryDone]);

  const getQueryArgs = async () => {
    dispatch(setLoaderState(true));
    const pickuplocationId = searchParams.get('pickupLocationId');
    const returnLocationId = searchParams.get('returnLocationId');
    const pickupDate = decodeURIComponent(searchParams.get('pickup') || '')?.split(':');
    const returnDate = decodeURIComponent(searchParams.get('return') || '')?.split(':');
    const language = searchParams.get('language');
    const ac = searchParams.get('acriss') || '';
    setAcriss(ac);
    const backUrl = searchParams.get('backUrl');

    let pickupLocation;
    let returnLocation;

    if (pickuplocationId) {
      pickupLocation = await fetchLocation({ id: pickuplocationId }).unwrap();
      const rentId = pickupLocation?.gOLocation?.rentLocationId;
      if (rentId) {
        if (pickuplocationId === returnLocationId) {
          dispatch(setReturnLocationSameAsPickup(true));
        }

        dispatch(setPickupLocation(rentId));
        dispatch(setLoaderState(true));

        if (returnLocationId) {
          dispatch(setReturnLocationSameAsPickup(false));
          returnLocation = await fetchLocation({ id: returnLocationId }).unwrap();
          const rentId = returnLocation?.gOLocation?.rentLocationId;
          if (rentId) {
            dispatch(setReturnLocation(rentId));
            dispatch(setLoaderState(true));
          }
        }
      }
      if (!pickupDate && !returnDate && !ac) navigate('/');
    }

    if (pickupDate && returnDate && pickupDate[0] && returnDate[0]) {
      dispatch(setPickupAndReturnDate([pickupDate[0], returnDate[0]]));
      dispatch(setPickupTime(`${pickupDate[1]}:${pickupDate[2]}`));
      dispatch(setReturnTime(`${returnDate[1]}:${returnDate[2]}`));
    }

    if (language && Object.values(Locale).includes(language as Locale)) {
      dispatch(updateCurrentLanguage(language));
    }

    if (backUrl) {
      dispatch(setBackUrl(backUrl));
    }

    if (!ac) {
      if (pickupDate && returnDate) {
        navigate('/booking/');
      } else {
        navigate('/');
      }
    } else {
      setQueryDone(true);
    }
  };

  return (
    <>
      <Loader />
    </>
  );
};

export default LandingComponent;
