/* eslint-disable prettier/prettier */
import {
  ActionCreatorWithPayload,
  AnyAction,
  createAsyncThunk,
  ThunkAction
} from '@reduxjs/toolkit';
import moment from 'moment';
import { WidgetInput } from 'shared/components/booking-widget/widget-input.enum';
import { CarClass } from 'shared/models/car-class.model';
import { formatDate, FormattedDateTime, formatTime, getNowDate } from 'shared/utils';
import { showErrorsMessage } from 'store/errors/errors.slice';
import { LocationCoordinates, ScheduleExclusions } from '../../shared/models/location.model';
import { RootState } from '../store';
import {
  setCurrentWidgetInput,
  setPickupAndReturnDate,
  setPickupDate,
  setPickupTime,
  setReturnDate,
  setReturnLocation,
  setReturnTime,
  setSubclassInformation,
  setTemporaryWidgetData,
  setUnavailablePickupDays,
  setUnavailableReturnDays,
  updateBookingAnalyticsData,
  UserBookingData
} from './booking.slice';
import { locationsAPI } from '../locations/locations.slice';
import configService from 'shared/config.service';
import TagManager from 'react-gtm-module';
import { AnalyticsStep } from 'shared/models/analytics-step.model';
import { GoLocationOverview } from 'store/locations/location-overview.slice';
import { getAnalytics, logEvent } from 'firebase/analytics';

const RENTAL_DESCRIPTION = '';
const SOURCE_OF_BOOKING = 1;
const LESS_THAN_24_ERROR = 'Pickup date must be more than 24 hours from now.';
const LESS_THAN_6_ERROR = 'Minimal rental time is 6 hours.';

export const createRent = createAsyncThunk<
  { rentalId: number; systemEmail: string } | null,
  number
>('booking/rent', async (totalPrice, { rejectWithValue, getState }) => {
  const state = getState() as RootState;

  const userBookingData = state.booking.userBookingData;
  const existingRentalId = userBookingData.rentalId;

  if (existingRentalId) return existingRentalId;
  else {
    return await fetch(`${configService.baseUrl}/rentals`, {
      method: 'POST',
      body: JSON.stringify({
        description: RENTAL_DESCRIPTION,
        pickUpDateAndTime: moment(
          `${userBookingData.pickUpDate} ${userBookingData.pickupTime}`,
          true
        ),
        // .tz(`${userBookingData.pickUpDate} ${userBookingData.pickupTime}`)
        // .format(),
        returnDateAndTime: moment(
          `${userBookingData.returnDate} ${userBookingData.returnTime}`,
          true
        ),
        pickUpLocationId: userBookingData.pickUpLocationId,
        returnLocationId: userBookingData.returnLocationId,
        coverageId: userBookingData.coverageId,
        goClassesMappingId: userBookingData.mappingId,
        unitExtraIds: userBookingData.selectedExtras
          .map((extra) => Array(extra.count).fill(extra.id).flat())
          .flat(),
        totalPrice: totalPrice,
        sourceOfBooking: SOURCE_OF_BOOKING,
        includeLocationTax: true
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.status !== 200) {
          return rejectWithValue('Could not create rental');
        }
        return response.json();
      })
      .catch(() => {
        return rejectWithValue('Could not create rental');
      });
  }
});

export const getUserDeviceLocation = createAsyncThunk<LocationCoordinates, void>(
  'booking/userDeviceLocation',
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    if (state.booking.widget.userLocation) {
      return state.booking.widget.userLocation;
    }

    const locationPromise = new Promise<LocationCoordinates>(function (resolve, reject) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) =>
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }),
          (error) => reject(error)
        );
      }
    });

    const response = await locationPromise;

    if (response) {
      return response;
    }

    return rejectWithValue('Could not get device location');
  }
);

const widgetInputOrder = new Map<WidgetInput, number>([
  [WidgetInput.PICKUP_LOCATION, 0],
  [WidgetInput.RETURN_LOCATION, 1],
  [WidgetInput.PICKUP_DATE, 2],
  [WidgetInput.RETURN_DATE, 3],
  [WidgetInput.PICKUP_TIME, 4],
  [WidgetInput.RETURN_TIME, 5]
]);

export const updateCurrentWidgetStep =
  (requestedInput: WidgetInput): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      if (requestedInput === WidgetInput.NONE) dispatch(setCurrentWidgetInput(requestedInput));
      // Check if the user can proceed with this input,
      // or there are previous steps that need to be completed first
      const requestedInputOrder = widgetInputOrder.get(requestedInput) || 0;
      const bookingState = getState().booking.userBookingData;
      const nextRequired = getNextRequiredWidget(bookingState);
      const nextRequiredIndex = widgetInputOrder.get(nextRequired);
      if (nextRequiredIndex === undefined) dispatch(setCurrentWidgetInput(requestedInput));
      else {
        // Good to go
        if (requestedInputOrder <= nextRequiredIndex) dispatch(setCurrentWidgetInput(requestedInput));
        else {
          // User needs to complete another field first
          dispatch(setCurrentWidgetInput(nextRequired));
        }
      }
    };

export const updateSubclassAndAcriss =
  (payload: {
    subclassId: number;
    acriss: string;
  }): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      const classes = getState()?.classes?.queries['getClassesList(undefined)']?.data as CarClass[];
      const selectedClassId = getState()?.booking.userBookingData.classId;
      if (classes) {
        const subclasses = classes.find((c) => c.id === selectedClassId)?.subclasses;
        const subclassMappingId =
          subclasses
            ?.find((subclass) => subclass.id === payload.subclassId)
            ?.goClassesMappings.find((mappings) => mappings.aCRISS === payload.acriss)?.id || 0;

        dispatch(
          setSubclassInformation({
            mappingId: subclassMappingId,
            acriss: payload.acriss,
            subclassId: payload.subclassId
          })
        );
      }
    };

type UpdateLocationPayload = { locationId: number; locationName: string };

export const updatePickupLocation = createAsyncThunk<number, UpdateLocationPayload>(
  'booking/updatePickupLocation',
  async ({ locationId, locationName }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const pickupDate = state.booking.userBookingData.pickUpDate;
    const returnDate = state.booking.userBookingData.returnDate;

    const locationsOverview = state.graphQL.queries['GetAllLocationsOverview(undefined)']
      ?.data as GoLocationOverview[];

    calculatedDatesForLocation(
      locationId,
      dispatch,
      pickupDate,
      returnDate,
      setUnavailablePickupDays
    );

    const lName = findEnLocationNameById(locationsOverview, locationId) || locationName;

    dispatch(
      updateBookingAnalyticsData({
        pickupLocation: lName
      })
    );

    if (state.booking.widget.returnLocationSameAsPickup) {
      dispatch(setReturnLocation(locationId));
      dispatch(updateBookingAnalyticsData({ returnLocation: lName }));
    }

    return locationId;
  }
);

function findEnLocationNameById(
  locationsOverview: GoLocationOverview[],
  locationId: number
): string {
  return (
    locationsOverview?.find((l) => l.rentLocationId === locationId)?.localizations[0]
      ?.gOLocationName || ''
  );
}

export const updateReturnLocation = createAsyncThunk<number, UpdateLocationPayload>(
  'booking/updateReturnLocation',
  async ({ locationId, locationName }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const pickupDate = state.booking.userBookingData.pickUpDate;
    const returnDate = state.booking.userBookingData.returnDate;
    calculatedDatesForLocation(
      locationId,
      dispatch,
      pickupDate,
      returnDate,
      setUnavailableReturnDays
    );
    dispatch(updateBookingAnalyticsData({ returnLocation: locationName }));
    return locationId;
  }
);

const updateLimitTimes =
  (payload: {
    pickupDate: string;
    returnDate: string;
  }): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
      // Uncomment this in order to untroduce limits to pickup times
      // const tomorrow = moment().add(1, 'day');
      // const shouldUpdatePickupTimes = tomorrow.isSame(moment(moment(payload.pickupDate)), 'days');
      // if (shouldUpdatePickupTimes) {
      //   const timeOffsetStart = formatTime(tomorrow);
      //   dispatch(limitTimes(timeOffsetStart));
      // } else {
      //   dispatch(resetTimes());
      // }
    };

export const updatePickupAndReturnDate =
  (payload: {
    pickupDate: string;
    returnDate: string;
  }): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
      dispatch(
        setPickupAndReturnDate([
          formatDate(moment(payload.pickupDate)),
          formatDate(moment(payload.returnDate))
        ])
      );
      dispatch(updateLimitTimes({ pickupDate: payload.pickupDate, returnDate: payload.returnDate }));
      // const pickupTime = getState()?.booking.userBookingData.pickupTime;
      // const returnTime = getState()?.booking.userBookingData.returnTime;
      // const lessThanDay = isLessThanDay(payload.pickupDate, pickupTime);
      // const lessThanSixHours = isLessThanSixHours(
      //   payload.pickupDate,
      //   pickupTime,
      //   payload.returnDate,
      //   returnTime
      // );
      // if (lessThanDay || lessThanSixHours) {
      //   dispatch(showErrorsMessage(lessThanDay ? LESS_THAN_24_ERROR : LESS_THAN_6_ERROR));
      // } else {
      //   dispatch(
      //     setPickupAndReturnDate([
      //       formatDate(moment(payload.pickupDate)),
      //       formatDate(moment(payload.returnDate))
      //     ])
      //   );
      //   dispatch(updateLimitTimes({ pickupDate: payload.pickupDate }));
      // }
    };

export const updateAndCheckPickupTime =
  (payload: { time: string }): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      const pickupDate = getState()?.booking.userBookingData.pickUpDate;
      const returnDate = getState()?.booking.userBookingData.returnDate;
      const returnTime = getState()?.booking.userBookingData.returnTime;
      const lessThanDay = isLessThanDay(pickupDate, payload.time);
      const lessThanSixHours = isLessThanSixHours(pickupDate, payload.time, returnDate, returnTime);
      if (lessThanDay || lessThanSixHours) {
        dispatch(showErrorsMessage(lessThanDay ? LESS_THAN_24_ERROR : LESS_THAN_6_ERROR));
      } else {
        dispatch(setPickupTime(payload.time));
      }
    };

export const updateAndCheckReturnTime =
  (payload: { time: string }): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      const pickupDate = getState()?.booking.userBookingData.pickUpDate;
      const returnDate = getState()?.booking.userBookingData.returnDate;
      const pickupTime = getState()?.booking.userBookingData.pickupTime;
      const lessThanSixHours = isLessThanSixHours(pickupDate, pickupTime, returnDate, payload.time);
      if (lessThanSixHours) {
        dispatch(showErrorsMessage(LESS_THAN_6_ERROR));
      } else {
        dispatch(setReturnTime(payload.time));
      }
    };

export const emitStateToTagManager =
  (event: string): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      const userBookingData = getState().booking.userBookingData;
      const userBookingAnalyticsData = getState().booking.bookingAnalyticsData;

      // TagManager.dataLayer({
      //   dataLayer: {
      //     ecommerce: null
      //   }
      // });

      let ecommerce = {
        bookingData: {}
      };

      const pickupDate = moment
        .utc(`${userBookingData.pickUpDate}:${userBookingData.pickupTime}`)
        .format();
      const returnDate = moment
        .utc(`${userBookingData.returnDate}:${userBookingData.returnTime}`)
        .format();
      const rentalDays = Math.ceil(moment(returnDate).diff(moment(pickupDate), 'minutes') / 60 / 24);

      switch (event) {
        case AnalyticsStep.STEP_1:
          ecommerce.bookingData = {
            pickupLocation: userBookingAnalyticsData.pickupLocation,
            returnLocation: userBookingAnalyticsData.returnLocation,
            pickupDateTime: pickupDate,
            returnDateTime: returnDate,
            rentalDays: rentalDays
          };
          break;
        case AnalyticsStep.STEP_2:
          ecommerce.bookingData = {
            class: userBookingAnalyticsData.carClass,
            subclass: userBookingAnalyticsData.carSubclass,
            totalPrice: userBookingAnalyticsData.totalPrice
          };
          break;
        case AnalyticsStep.STEP_3:
          ecommerce.bookingData = {
            extras: userBookingData.selectedExtras?.map((extra) => {
              const extraName =
                userBookingAnalyticsData.extras?.find(
                  (analyticsExtra) => extra.id === analyticsExtra.id
                )?.name || '';

              return {
                name: extraName,
                count: extra.count
              };
            }),
            totalPrice: userBookingAnalyticsData.totalPrice
          };
          break;
        case AnalyticsStep.STEP_4:
          ecommerce.bookingData = {
            coverage: userBookingAnalyticsData.coverage,
            totalPrice: userBookingAnalyticsData.totalPrice
          };
          break;
        case AnalyticsStep.STEP_5:
          ecommerce.bookingData = {
            userEmail: userBookingData.userEmail,
            totalPrice: userBookingAnalyticsData.totalPrice
          };
          break;
        case AnalyticsStep.PURCHASE: {
          ecommerce = {
            purchase: {
              value: userBookingAnalyticsData.totalPrice,
              class: userBookingAnalyticsData.carClass,
              subclass: userBookingAnalyticsData.carSubclass,
              currency: 'CHF',
              pickupLocation: userBookingAnalyticsData.pickupLocation,
              returnLocation: userBookingAnalyticsData.returnLocation,
              pickupDateTime: userBookingAnalyticsData.pickupTime,
              returnDateTime: userBookingAnalyticsData.returnTime,
              rentalDays: rentalDays
            }
          } as any;
        }
      }

      const analytics = process.env.NODE_ENV !== 'development' ? getAnalytics() : null;
      if (analytics) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logEvent(analytics, event, {
          ecommerce: ecommerce
        });
      }
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: event,
      //     ecommerce: ecommerce
      //   }
      // });
    };

export const emitPurchaseEventToFirebase =
  (): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    // const userBookingData = getState().booking.userBookingData;
    const analytics = process.env.NODE_ENV !== 'development' ? getAnalytics() : null;
    const userBookingAnalyticsData = getState().booking.bookingAnalyticsData;

    if (analytics) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logEvent(analytics, 'purchase', {
        affiliation: 'webapp',
        value: userBookingAnalyticsData.totalPrice?.toFixed(2),
        currency: 'CHF'
      });
    }
  };

function isLessThanDay(pickupDate: string, pickupTime: string) {
  const formatedDate = FormattedDateTime(pickupDate, pickupTime);
  const nowDate = getNowDate();
  const diffInDays = formatedDate.diff(nowDate, 'minutes');
  return Math.trunc(diffInDays / 60 / 24) <= 0;
}

function isLessThanSixHours(
  pickupDate: string,
  pickupTime: string,
  returnDate: string,
  returnTime: string
) {
  const formatedPickupDate = FormattedDateTime(pickupDate, pickupTime);
  const formatedReturnDate = FormattedDateTime(returnDate, returnTime);
  const diffInDays = formatedReturnDate.diff(formatedPickupDate, 'minutes');
  return Math.trunc(diffInDays / 60) <= 6;
}

async function calculatedDatesForLocation(
  locationId: number,
  dispatch: any,
  pickupDate: string,
  returnDate: string,
  unavailableDaysAction: ActionCreatorWithPayload<string[], string>
) {
  const locationDetails = await dispatch(
    locationsAPI.endpoints.getLocationById.initiate(locationId)
  ).unwrap();

  const unavailableDays = getDisabledDates(
    [pickupDate, returnDate],
    locationDetails.scheduleExclusions
  );

  dispatch(unavailableDaysAction(unavailableDays));

  const pickUpDateMoment = moment(pickupDate);
  const isPickupDateUnavailable = pickupDate
    ? unavailableDays.find((date) => pickUpDateMoment.isSame(moment(date), 'days'))
    : false;
  if (!pickupDate || isPickupDateUnavailable) {
    const fullDate = moment().add(1, 'hours');
    do {
      fullDate.add(1, 'day');
    } while (unavailableDays.find((date) => fullDate.isSame(moment(date), 'days')));

    const date = formatDate(fullDate);
    const time = formatTime(fullDate);
    const returnDate = formatDate(fullDate.add(5, 'days'));
    dispatch(setPickupDate(date));
    dispatch(setPickupTime(time));
    dispatch(setReturnDate(returnDate));
    dispatch(setReturnTime(time));
    dispatch(updateLimitTimes({ pickupDate: date, returnDate: returnDate }));
    dispatch(setTemporaryWidgetData());
  }
}

function getDisabledDates(dates: Array<string>, scheduleExclusions: ScheduleExclusions[]) {
  const unavailableDays = [];
  if (!dates || (Array.isArray(dates) && !dates.length)) {
    unavailableDays.push(
      ...(scheduleExclusions.map((exclusion) => formatDate(moment(exclusion.effectiveDate))) || [])
    );
  }
  return unavailableDays;
}

function getNextRequiredWidget(bookingState: UserBookingData): WidgetInput {
  if (!bookingState.pickUpLocationId) return WidgetInput.PICKUP_LOCATION;
  else if (!bookingState.returnLocationId) return WidgetInput.RETURN_LOCATION;
  else if (!bookingState.pickUpDate) return WidgetInput.PICKUP_DATE;
  else if (!bookingState.returnDate) return WidgetInput.RETURN_DATE;
  else if (!bookingState.pickupTime) return WidgetInput.PICKUP_TIME;
  else if (!bookingState.returnTime) return WidgetInput.RETURN_TIME;

  return WidgetInput.NONE;
}
