import React from 'react';
import { Link } from 'react-router-dom';

const DESKTOP_LOGO = '/svg/enterpriseGOLogoHeader.svg';

function DesktopLogo() {
  return (
    <Link className="w-11rem" to="/">
      <img alt="logo" src={DESKTOP_LOGO} width="215px" height="50px" />
    </Link>
  );
}

export default React.memo(DesktopLogo);
