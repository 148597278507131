import SecondaryText from '../text/SecondaryText';

export type ExternalLinkProps = {
  url: string;
  text: string;
};

export default function ExternalLink({ url, text }: ExternalLinkProps) {
  return (
    <a href={url} target="_blank" rel="noreferrer noopener">
      <SecondaryText text={text + ' >'} className="font-semibold text-xs"></SecondaryText>
    </a>
  );
}
