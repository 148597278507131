import { useEffect, useMemo } from 'react';
import { getCurrentLocale } from 'store/language/language.selectors';
import { useGetAllLocationsOverviewQuery } from 'store/locations/location-overview.slice';
import { useGetAssetByIdQuery } from '../../../../store/assets/assets.slice';
import { useGetBookingWidgetQuery } from '../../../../store/booking-widget/booking-widget.slice';
import {
  getCurrentWidgetInput,
  getLocationSortType,
  getUserLocation
} from '../../../../store/booking/booking.selectors';
import { setLocationSortType } from '../../../../store/booking/booking.slice';
import {
  getUserDeviceLocation,
  updateCurrentWidgetStep,
  updatePickupLocation,
  updateReturnLocation
} from '../../../../store/booking/booking.thunk';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getLocationsListOverview,
  getSortedLocations
} from '../../../../store/locations/locations.selectors';
import { locationsAPI } from '../../../../store/locations/locations.slice';
import SecondaryText from '../../text/SecondaryText';
import { LocationSort } from '../location-sort.enum';
import { LocationsUtils } from '../locations.utils';
import { WidgetInput } from '../widget-input.enum';
import BookingLocation from './BookingLocation';

import './BookingLocationsContainer.scss';

const MAX_LOCATION_COLUMNS = 3;

export default function BookingLocationContainer() {
  const dispatch = useAppDispatch();

  const { data: widgetConfig } = useGetBookingWidgetQuery();

  const { data: goPickUpReturnIcon } = useGetAssetByIdQuery({
    id: widgetConfig?.goPickUpReturnIcon?.id || ''
  });
  const { data: goCurrentLocationIconActive } = useGetAssetByIdQuery({
    id: widgetConfig?.goCurrentLocationIconActive?.id || ''
  });
  const { data: informationIcon } = useGetAssetByIdQuery({
    id: widgetConfig?.goSearchLocationInformation?.id || ''
  });

  const currentWidgetInput = useAppSelector(getCurrentWidgetInput);
  const locationSortOrder = useAppSelector(getLocationSortType);
  const locationsList = useAppSelector(getSortedLocations);
  const userLocation = useAppSelector(getUserLocation);
  const locationsOverviewList = useAppSelector(getLocationsListOverview);

  function calculateDistance(): void {
    dispatch(getUserDeviceLocation());
  }

  useEffect(() => {
    if (userLocation) {
      dispatch(
        locationsAPI.util.updateQueryData('getLocationsList', undefined, (locations) => {
          locations = LocationsUtils.calculateDistanceForLocations(locations, userLocation) || [];
        })
      );
      dispatch(setLocationSortType(LocationSort.DISTANCE));
    }
  }, [userLocation]);

  const selectLocation = (locationId: number) => {
    if (
      currentWidgetInput === WidgetInput.PICKUP_LOCATION ||
      currentWidgetInput === WidgetInput.RETURN_LOCATION
    ) {
      const locationName =
        locationsOverviewList?.find((location) => location.rentLocationId === locationId)
          ?.gOLocationName || '';
      if (currentWidgetInput === WidgetInput.PICKUP_LOCATION) {
        dispatch(updatePickupLocation({ locationId: locationId, locationName: locationName }));
      } else {
        dispatch(updateReturnLocation({ locationId: locationId, locationName: locationName }));
      }
      dispatch(updateCurrentWidgetStep(WidgetInput.NONE));
    }
  };

  const printLocations = useMemo(() => {
    const sectionsArray: Array<Array<JSX.Element>> = [];
    if (!locationsList?.length)
      return (
        <div className="flex align-self-end text-sm font-italic no-results-found">
          There are no results that match your search
        </div>
      ); // TODO: No locations found message

    const numberOfRecordsPerColumn = Math.ceil(locationsList.length / MAX_LOCATION_COLUMNS);

    let i = 0;
    const n = locationsList.length;
    while (i < n) {
      sectionsArray.push(
        locationsList.slice(i, (i += numberOfRecordsPerColumn)).map((location, index) => {
          const locationName = locationsOverviewList?.find(
            (locationOverview) => locationOverview.rentLocationId === location.locationId
          )?.gOLocationName;
          return (
            <div key={location.locationId}>
              <BookingLocation
                locationId={location.locationId || 0}
                locationName={locationName || ''}
                goPickupReturnIconUrl={
                  locationSortOrder === LocationSort.DISTANCE &&
                  i === numberOfRecordsPerColumn &&
                  index === 0
                    ? goCurrentLocationIconActive?.asset?.url || ''
                    : goPickUpReturnIcon?.asset?.url || ''
                }
                informationIconUrl={informationIcon?.asset?.url || ''}
                onLocationSelect={selectLocation}
                locationDistance={location.distanceToUserLocation || 0}
                showKm={locationSortOrder === LocationSort.DISTANCE}
              />
            </div>
          );
        })
      );
    }

    return (
      <>
        {sectionsArray.map((values: JSX.Element[], index: number) => {
          return (
            <section
              key={index}
              className={`flex flex-column md:w-4 location-section ${
                index !== 0 ? 'md:ml-5' : ''
              }`}>
              {values}
            </section>
          );
        })}
      </>
    );
  }, [
    locationsList,
    locationsOverviewList,
    goCurrentLocationIconActive,
    informationIcon,
    goPickUpReturnIcon,
    currentWidgetInput,
    locationSortOrder
  ]);

  return (
    <div className="flex-1 flex-column">
      <div className="flex w-full flex-row justify-content-between mb-3">
        <div className="flex align-items-center" onClick={() => calculateDistance()}>
          <img src={goCurrentLocationIconActive?.asset?.url} className="mr-1 -ml-2" width="20px" />
          <SecondaryText
            className="cursor-pointer font-semibold text-sm"
            text={widgetConfig?.locationsNearMeText || ''}
          />
        </div>
        <a
          href={'https://www.enterprise.ch/'}
          className="flex font-semibold text-sm underline"
          target="_blank"
          rel="noreferrer noopener">
          {widgetConfig?.travellingAbroadLinkText || ''}
        </a>
      </div>
      <div className="flex">
        <SecondaryText
          text={widgetConfig?.locationTitle || ''}
          className={'text-base font-medium'}
        />
      </div>

      <div className="flex mt-1 overflow-y-auto scroll-panel widget-locations-container scroll-panel justify-content-end flex-column md:flex-row-reverse">
        {printLocations}
      </div>
    </div>
  );
}
