import { HomeSections } from 'components/home/home-sections';
import { Locale, WebAppNavigationSelection } from 'generated/graphql';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCurrentLocale } from 'store/language/language.selectors';
import { updateCurrentLanguage } from 'store/language/language.thunk';
import { openLegalsOverviewDialog } from 'store/modals/modals.slice';
import {
  NavigationModel,
  useGetContactsOverviewQuery
} from 'store/navigations/navigations-overview.slice';
import { getNavigationByViewportSizeId } from 'store/navigations/navigations.selectors';
import { menuItems } from '../header/navigations-items';
import ImageComponent from '../image/ImageComponent';
import './FooterComponent.scss';

const mapLanguages = (langNames: Array<string>) => {
  const locales: Array<string> = Object.values(Locale);
  const languages = locales.map((langKey, index) => {
    return {
      key: langKey,
      name: langNames[index]
    };
  });
  return languages;
};
export const localesLinks = [
  {
    key: 'de_CH',
    newTicketLink: 'https://support.enterprise-go.ch/portal/de/newticket',
    helpCenterLink: 'https://support.enterprise-go.ch/portal/de/kb/go'
  },
  {
    key: 'en',
    newTicketLink: 'https://support.enterprise-go.ch/portal/en/newticket',
    helpCenterLink: 'https://support.enterprise-go.ch/portal/en/kb/go'
  },
  {
    key: 'fr_CH',
    newTicketLink: 'https://support.enterprise-go.ch/portal/fr/newticket',
    helpCenterLink: 'https://support.enterprise-go.ch/portal/fr/kb/go'
  }
];

const FooterComponent = () => {
  const { data: contacts, isFetching } = useGetContactsOverviewQuery();
  const currentLanguage = useAppSelector(getCurrentLocale);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navFooter: NavigationModel | null = useAppSelector(
    getNavigationByViewportSizeId(WebAppNavigationSelection.Footer)
  );

  function scrollToSection(section: string) {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  }

  function openLegalModal(title: string, content: string) {
    dispatch(openLegalsOverviewDialog({ title: title, content: content }));
  }

  function onMenuClick(index: number): void {
    if (!menuItems.find((language) => language.key === currentLanguage)?.items[index]) {
      navigate('/#' + HomeSections.HOW_IT_WORKS);
      scrollToSection(HomeSections.HOW_IT_WORKS);
    } else {
      if (index !== 3) {
        navigate(
          menuItems.find((language) => language.key === currentLanguage)?.items[index] || ''
        );
      } else {
        window.open(
          menuItems.find((language) => language.key === currentLanguage)?.items[index] || '',
          '_blank'
        );
      }
    }
  }

  const getCurrentLanguageName = useMemo(() => {
    const languages = mapLanguages(navFooter?.languageSetting || []);
    return <div>{languages.find((lang) => lang.key === currentLanguage)?.name || ''}</div>;
  }, [currentLanguage, navFooter]);

  function onLanguageChange(lang: Locale) {
    dispatch(updateCurrentLanguage(lang));
  }

  return (
    <footer className="footer-background-container page-container-padding pt-6 text-white">
      <div className="footer-container">
        {!isFetching ? (
          <>
            <div className="flex flex-column justify-content-between">
              <Link to="/" className="block footer-logo">
                <img
                  alt="logo"
                  src="/svg/enterpriseGOLogoFooter.svg"
                  width="100%"
                  height="100%"></img>
              </Link>
            </div>
            <div className="flex flex-wrap lg:flex-nowrap lg:flex-row justify-content-between mt-5 text-sm">
              <div className="mb-4 md:mb-0 footer-section-column">
                <div className="font-light opacity-80 mb-3">
                  {navFooter?.siteNavigationItemsTitle}
                </div>
                {navFooter?.siteNavigationItems?.map((siteNavItem, index) => {
                  return (
                    <a
                      key={index}
                      className="block mt-3  font-medium"
                      onClick={() => onMenuClick(index)}>
                      {siteNavItem}
                    </a>
                  );
                })}
              </div>
              <div className="mb-4 md:mb-0 footer-section-column">
                <div className="font-light opacity-80 mb-3">
                  {navFooter?.policyNavigationItemsTitle}
                </div>
                {navFooter?.legals?.map((policyNavItem, index) => (
                  <a
                    className="block mt-3 font-medium"
                    key={index}
                    onClick={() => {
                      if (index === 1) {
                        navigate('/privacy/' + currentLanguage);
                      } else {
                        openLegalModal(
                          policyNavItem.staticContentName || '',
                          policyNavItem.staticContentBody?.html || ''
                        );
                      }
                    }}>
                    {policyNavItem.staticContentName}
                  </a>
                ))}
              </div>
              <div className="mb-4 md:mb-0 footer-section-column">
                <div className="font-light opacity-80 mb-3">
                  {navFooter?.contactNavigationItemsTitle}
                </div>
                {navFooter?.contactNavigationItems?.map((contactItem, index) => {
                  if (index !== 0) {
                    return (
                      <div key={index} className="block mt-3 font-medium">
                        {contactItem}
                      </div>
                    );
                  } else {
                    return (
                      <a
                        key={index}
                        className="block mt-3 font-medium"
                        href={
                          index === 0
                            ? localesLinks.find((lang) => lang.key === currentLanguage)
                                ?.newTicketLink || ''
                            : ''
                        }>
                        {contactItem}
                      </a>
                    );
                  }
                })}
              </div>
              <div className="mb-4 md:mb-0 footer-section-column">
                <div className="font-light opacity-80 mb-3">{navFooter?.languageSettingTitle}</div>
                <Accordion>
                  <AccordionTab
                    header={getCurrentLanguageName}
                    headerClassName="lang-selection text-xs"
                    contentClassName="lang-options">
                    {navFooter?.languageSetting
                      ? mapLanguages(navFooter.languageSetting)
                          .filter((lang) => lang.key !== currentLanguage)
                          .map((lang) => (
                            <a
                              className="block text-xs mt-2 text-white"
                              key={lang.key}
                              onClick={(e) => {
                                onLanguageChange(lang.key as Locale);
                              }}>
                              {lang.name}
                            </a>
                          ))
                      : ''}
                  </AccordionTab>
                </Accordion>
              </div>
              <div className="mb-4 md:mb-0 flex md:block footer-store-logos">
                {/* <div className="font-semibold mb-3">{navFooter?.applicationHeader}</div>
                <div className="block mt-2">{navFooter?.applicationSubheader}</div> */}
                {navFooter?.appStoreImage?.map((image, index) => (
                  <a
                    key={index}
                    href={
                      index
                        ? 'https://play.google.com/store/apps/details?id=com.go.car.rental'
                        : 'https://apps.apple.com/ch/app/enterprise-go-rent-a-car/id1635135473'
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="mt-2 block w-8rem font-medium">
                    <ImageComponent id={image.id} width="100%" />
                  </a>
                ))}
              </div>
            </div>
            <div className="flex flex-column text-center lg:align-items-center text-sm">
              <div className="mt-5">© Enterprise GO {new Date().getFullYear()}</div>
            </div>
          </>
        ) : (
          <Skeleton width="100%" height="100%" />
        )}
      </div>
    </footer>
  );
};

export default FooterComponent;
