import PrimaryButton from 'shared/components/primary-button/PrimaryButton';
import { updateCurrentWidgetStep } from 'store/booking/booking.thunk';
import { useAppDispatch } from 'store/hooks';
import BookingCalendar from '../calendar/BookingCalendar';
import BookingWidgetDateTimeContainer from '../inputs/BookingWidgetDateTimeContainer';
import { WidgetInput } from '../widget-input.enum';

// If needed we can further customize the mobile view in this container
export function BookingMobileCalendarContainer() {
  const dispatch = useAppDispatch();

  function confirmDates() {
    dispatch(updateCurrentWidgetStep(WidgetInput.NONE));
  }

  return (
    <>
      <BookingWidgetDateTimeContainer />
      <div className="mt-3 flex flex-column">
        <BookingCalendar />
        <PrimaryButton text="CONFIRM DATES" className="mt-2" onClick={confirmDates} />
      </div>
    </>
  );
}
