import { WebAppNavigationSelection } from 'generated/graphql';
import { graphQLAPI } from '../api/base-graphql-api';

export type NavigationModel = {
  navigationComponentViewportSize: WebAppNavigationSelection;
  siteNavigationItemsTitle?: string | null;
  siteNavigationItems?: Array<string> | null;
  languageIcon?: { id: string } | null;
  languageSetting?: Array<string> | null;
  contactNavigationItemsTitle?: string | null;
  contactNavigationItems?: Array<string> | null;
  policyNavigationItemsTitle?: string | null;
  policyNavigationItems?: Array<string> | null;
  applicationHeader?: string | null;
  applicationSubheader?: string | null;
  appStoreImage?: Array<{ id: string }> | null;
  downloadAppButtonTextHeader?: string | null;
  languageSettingTitle?: string | null;
  legals?: Array<LegalsOverview>;
};

type GetNavigationsOverviewQuery = {
  goWebappNavLinks: Array<NavigationModel>;
  contacts: Array<ContactsOverview>;
};

export type ContactsOverview = {
  customerEmail?: string | null;
  customerServiceNumber?: string | null;
};

type GetContactsOverviewQuery = {
  contacts: Array<ContactsOverview>;
};

export type LegalsOverview = {
  staticContentName?: string;
  staticContentBody?: {
    html: string;
  };
};

const GetNavigationsOverviewDocument = `
query getNavigations {
  goWebappNavLinks {
    navigationComponentViewportSize
    siteNavigationItemsTitle
    siteNavigationItems
    languageIcon {
      id
    }
    languageSetting
    contactNavigationItemsTitle
    contactNavigationItems
    policyNavigationItemsTitle
    policyNavigationItems
    applicationHeader
    applicationSubheader
    appStoreImage {
      id
    }
    downloadAppButtonTextHeader
    languageSettingTitle
    legals {
      staticContentName
      staticContentBody {
       html
     }
   }
   copywriteInformation
  }
}`;

const GetContactsOverviewDocument = `
  query Contacts {
    contacts {
      customerEmail
      customerServiceNumber
    }
  }
`;

const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetNavigationsOverview: build.query<NavigationModel[], void>({
      query: () => ({ document: GetNavigationsOverviewDocument }),
      transformResponse: (response: GetNavigationsOverviewQuery) => response?.goWebappNavLinks
    }),
    GetContactsOverview: build.query<ContactsOverview, void>({
      query: () => ({ document: GetContactsOverviewDocument }),
      transformResponse: (response: GetContactsOverviewQuery) => response?.contacts[0]
    })
  })
});

export { injectedRtkApi as navigationsOverview };
export const { useGetContactsOverviewQuery, usePrefetch } = injectedRtkApi;
