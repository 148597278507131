import { graphQLAPI } from '../api/base-graphql-api';

export type HomePageOverview = {
  heroImage: { id: string; handle: string } | null;
  heroTitleText?: string | null;
  officialPartnerText?: string | null;
  sbbLogoFromHero?: { id: string } | null;
  sbbWebsiteLinkUrl?: string | null;
  downloadButtons?: Array<{ id: string }> | null;
  valuePropositionTitle?: string | null;
  valuePropostionText?: string | null;
  valuePropositionDownloadLinkButton?: string | null;
  valuePropositionImage?: { id: string; handle: string } | null;
  playVideoText?: string | null;
  mediaPlayerPlayIcon?: { id: string } | null;
  videoSectionTitle?: string | null;
  videoSectionBodyText?: string | null;
  videoContentLinks?: Array<string> | null;
  howItWorksSectionTitle?: string | null;
  howItWorksSectionText?: string | null;
  howItWorksSectionCardTitles?: Array<string> | null;
  howItWorksFirstTimeCardImage?: Array<{ id: string }> | null;
  howItWorksCardImage?: Array<{ id: string; handle: string }> | null;
  howItWorksCardTitle?: Array<string> | null;
  howItWorksCardText?: Array<string> | null;
  howItWorksRepeatCardImage?: Array<{ id: string }> | null;
  howItWorksRepeatCardTitle?: Array<string> | null;
  howItWorksRepeatCardText?: Array<string> | null;
  faqTitle?: string | null;
  faqQuestions?: Array<string> | null;
  faqAnswers?: Array<string> | null;
  partnersSectionTitle?: string | null;
  partnershipsSectionText?: string | null;
  partnershipAssets?: Array<{ id: string; handle: string }> | null;
  partnershipLinkURL?: Array<string> | null;
  partnerSectionButtonText?: string | null;
  heroImageLinkURL?: string | null;
  mediaImages?: Array<{ id: string }> | null;
};

type PartnersOverview = {
  parterPageHeroImage: { id: string } | null;
  partnersSectionTitle?: string | null;
  partnershipsSectionText?: string | null;
  partnershipAssets?: Array<{ id: string; handle: string }> | null;
  partnershipLinkURL?: Array<string> | null;
};
type GetPartnersOverviewQuery = {
  goWebappHomepages: Array<PartnersOverview>;
};

type GetHomePageOverviewQuery = {
  goWebappHomepages: Array<HomePageOverview>;
};

const GetHomePageOverviewDocument = `
query getHomePage {
  goWebappHomepages {
    heroImage {
      id,
      handle
    }
    heroTitleText
    officialPartnerText
    sbbLogoFromHero {
      id
    }
    sbbWebsiteLinkUrl
    valuePropositionTitle
    valuePropostionText
    valuePropositionDownloadLinkButton
    valuePropositionImage {
      id,
      handle
    }
    downloadButtons {
      id
    }  
    playVideoText
    mediaPlayerPlayIcon {
      id
    }
    videoSectionTitle
    videoSectionBodyText
    videoContentLinks
    howItWorksSectionTitle
    howItWorksSectionText
		howItWorksFirstTimeCardImage {
		  id
		}
    howItWorksCardTitle
    howItWorksCardText
    howItWorksSectionCardTitles
    howItWorksRepeatCardImage {
      id
    }
    howItWorksRepeatCardTitle
    howItWorksRepeatCardText
    faqTitle
    faqQuestions
    faqAnswers
    partnersSectionTitle
    partnershipsSectionText
    partnershipAssets{
      id,
      handle
    }
    partnershipLinkURL
    partnerSectionButtonText
    mediaImages {
      id
    }
    heroImageLinkURL
  }
}`;

const GetPartnersPageOverviewDocument = `
query getHomePage {
  goWebappHomepages {
    
    parterPageHeroImage {
      id
    }
    partnersSectionTitle
    partnershipAssets{
      id
    }
    partnershipLinkURL
  }
}`;
const injectedRtkApi = graphQLAPI.injectEndpoints({
  endpoints: (build) => ({
    GetHomePageOverview: build.query<HomePageOverview, void>({
      query: () => ({ document: GetHomePageOverviewDocument }),
      transformResponse: (response: GetHomePageOverviewQuery) => {
        return response?.goWebappHomepages[0];
      }
    }),
    GetPartnersPageOverview: build.query<PartnersOverview, void>({
      query: () => ({ document: GetPartnersPageOverviewDocument }),
      transformResponse: (response: GetPartnersOverviewQuery) => {
        return response?.goWebappHomepages[0];
      }
    })
  })
});

export { injectedRtkApi as homePageOverview };
export const { useGetHomePageOverviewQuery, useGetPartnersPageOverviewQuery } = injectedRtkApi;
