import { Galleria } from 'primereact/galleria';
import { useRef, useState } from 'react';

interface GalleryImage {
  url: string;
  fileName?: string;
}

type GalleryComponentProps = {
  images: GalleryImage[];
};

export default function GalleryComponent({ images }: GalleryComponentProps) {
  const galleria = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const itemTemplate = (item: GalleryImage) => {
    return (
      <img
        src={item.url}
        // TODO: Handle properly
        onError={(e) => console.log(e)}
        alt={item.fileName}
        style={{ width: '100%', display: 'block' }}
      />
    );
  };

  function showImage(index: number) {
    setActiveIndex(index);
    if (galleria && galleria.current) {
      galleria.current.show();
    }
  }

  return (
    <>
      {/* TODO: To hook */}
      <Galleria
        ref={galleria}
        value={images}
        style={{ maxWidth: '850px' }}
        activeIndex={activeIndex}
        onItemChange={(e) => setActiveIndex(e.index)}
        circular
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
      />
      {/* TODO: Component */}
      <div className="grid">
        {images &&
          images.map((image, index) => {
            const imgEl = (
              <img
                src={image.url}
                alt={image.fileName}
                className="w-full"
                style={{ cursor: 'pointer', objectFit: 'cover' }}
                onClick={() => showImage(index)}
              />
            );

            return (
              <div
                className="col-3 flex flex-1"
                style={{
                  minWidth: '150px',
                  maxHeight: '230px',
                  maxWidth: '220px'
                }}
                key={index}>
                {imgEl}
              </div>
            );
          })}
      </div>
    </>
  );
}
