import { lazy, useEffect, useMemo } from 'react';
import { getCurrentWidgetInput } from '../../../store/booking/booking.selectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useIsMobile from '../../hooks/useIsMobile';
import MobileWidgetInput from '../mobile-widget-input/MobileWidgetInput';
import { BookingTime } from './time/BookingTime';
import BookingLocationContainer from './locations/BookingLocationsContainer';
import { BookingMobileCalendarContainer } from './mobile-containers/BookingMobileCalendarContainer';
import { BookingMobileLocationContainer } from './mobile-containers/BookingMobileLocationContainer';
import BookingMobileTimeContainer from './mobile-containers/BookingMobileTimeContainer';
import { WidgetInput } from './widget-input.enum';
import { shallowEqual } from 'react-redux';
import { setTemporaryWidgetData } from 'store/booking/booking.slice';

const BookingCalendar = lazy(() => import('./calendar/BookingCalendar'));

export default function BookingWidgetController() {
  const { isMobile } = useIsMobile();
  const currentInputWidget = useAppSelector(getCurrentWidgetInput, shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isMobile && currentInputWidget === WidgetInput.NONE) {
      // cache the values
      dispatch(setTemporaryWidgetData());
    }
  }, [currentInputWidget]);

  const selectDesktopWidget = useMemo(() => {
    switch (currentInputWidget) {
      case WidgetInput.PICKUP_LOCATION:
      case WidgetInput.RETURN_LOCATION:
        return <BookingLocationContainer />;
      case WidgetInput.PICKUP_DATE:
      case WidgetInput.RETURN_DATE:
        return <BookingCalendar calendarsCount={3} />;
      case WidgetInput.PICKUP_TIME:
      case WidgetInput.RETURN_TIME:
        return <BookingTime />;
      default:
        return <></>;
    }
  }, [currentInputWidget, isMobile]);

  const selectMobileWidget = useMemo(() => {
    switch (currentInputWidget) {
      case WidgetInput.PICKUP_LOCATION:
      case WidgetInput.RETURN_LOCATION:
        return <BookingMobileLocationContainer />;
      case WidgetInput.PICKUP_DATE:
      case WidgetInput.RETURN_DATE:
        return <BookingMobileCalendarContainer />;
      case WidgetInput.PICKUP_TIME:
      case WidgetInput.RETURN_TIME:
        return <BookingMobileTimeContainer />;
      default:
        return <></>;
    }
  }, [currentInputWidget, isMobile]);

  return (
    <>
      {currentInputWidget !== WidgetInput.NONE && (
        <>
          {!isMobile ? (
            <div className="flex flex-1 mt-5 w-full">{selectDesktopWidget}</div>
          ) : (
            <MobileWidgetInput>{selectMobileWidget}</MobileWidgetInput>
          )}
        </>
      )}
    </>
  );
}
