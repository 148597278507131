import { Dropdown } from 'primereact/dropdown';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Locale, WebAppNavigationSelection } from 'generated/graphql';
import { NavigationModel } from 'store/navigations/navigations-overview.slice';
import { getNavigationByViewportSizeId } from 'store/navigations/navigations.selectors';
import { useMemo } from 'react';
import { HomeSections } from 'components/home/home-sections';
import PrimaryButton from '../primary-button/PrimaryButton';
import './HomeHeaderDesktop.scss';
import DesktopLogo from './logo/DesktopLogo';
import { shallowEqual } from 'react-redux';
import { getCurrentLocale } from 'store/language/language.selectors';
import { updateCurrentLanguage } from 'store/language/language.thunk';
import { openDownloadAppOverview } from 'store/modals/modals.slice';
import { menuItems } from './navigations-items';
import { useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';

const mapLanguages = (langNames: Array<string>) => {
  const locales: Array<string> = Object.values(Locale);
  const languages = locales.map((langKey, index) => {
    return {
      key: langKey,
      name: langNames[index]
    };
  });
  return languages;
};

const HomeHeaderDesktop = (props: any) => {
  const currentLanguage = useAppSelector(getCurrentLocale, shallowEqual);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navDesktop: NavigationModel | null = useAppSelector(
    getNavigationByViewportSizeId(WebAppNavigationSelection.DesktopHeader),
    shallowEqual
  );

  const getCurrentLanguageName = useMemo(() => {
    const languages = mapLanguages(navDesktop?.languageSetting || []);
    return languages.find((lang) => lang.key === currentLanguage)?.name || '';
  }, [currentLanguage, navDesktop]);

  const dropdownOptions = useMemo(() => {
    return navDesktop?.languageSetting
      ? mapLanguages(navDesktop.languageSetting).filter((lang) => lang.key !== currentLanguage)
      : [];
  }, [currentLanguage, navDesktop]);

  return (
    navDesktop && (
      <div className="justify-content-between align-items-center hidden lg:flex header-desktop-container">
        <DesktopLogo />
        <div className="flex align-items-center">
          {navDesktop?.siteNavigationItems?.map((item, index) => (
            <a
              key={index}
              className={`mr-4 header-navigation-item ${index === 3 ? 'font-bold text-green' : ''}`}
              onClick={() => {
                props.menuClick(index);
              }}>
              <div
                className={
                  menuItems.find((language) => language.key === currentLanguage)?.items[index] ===
                  location.pathname
                    ? 'active header-navigation-item-text'
                    : 'header-navigation-item-text'
                }>
                {item}
              </div>
            </a>
          ))}
          <Dropdown
            optionLabel="name"
            optionValue="key"
            className="border-0 mr-3 lang"
            value={getCurrentLanguageName}
            options={dropdownOptions}
            onChange={(e) => dispatch(updateCurrentLanguage(e.value as Locale))}
            placeholder={getCurrentLanguageName}
          />
          <Button
            onClick={() => dispatch(openDownloadAppOverview())}
            className="black-button btn-big border-round-md font-semibold text-sm">
            {navDesktop?.downloadAppButtonTextHeader || ''}
          </Button>
        </div>
      </div>
    )
  );
};

export default HomeHeaderDesktop;
