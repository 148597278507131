import { createSlice } from '@reduxjs/toolkit';
import { locale } from 'moment';
import { Locale } from '../../generated/graphql';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    currentLocale: Locale.DeCh
  },
  reducers: {
    changeLocale: (state, action) => {
      state.currentLocale = action.payload;
      locale(action.payload.toString().slice(0, 2));
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeLocale } = languageSlice.actions;

export default languageSlice.reducer;
