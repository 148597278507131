import { Divider } from 'primereact/divider';
import { useAppDispatch } from '../../../../store/hooks';
import { openLocationOverviewDialog } from '../../../../store/modals/modals.slice';

type BookingLocationProps = {
  locationId: number;
  locationName: string;
  goPickupReturnIconUrl: string;
  informationIconUrl: string;
  onLocationSelect: (locationId: number) => void;
  locationDistance: number;
  showKm: boolean;
};

export default function BookingLocation({
  locationId,
  locationName,
  goPickupReturnIconUrl,
  informationIconUrl,
  onLocationSelect,
  locationDistance,
  showKm
}: BookingLocationProps) {
  const dispatch = useAppDispatch();

  function openLocationOverview(
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
    locationId: number
  ): void {
    event.preventDefault();
    event.stopPropagation();
    dispatch(openLocationOverviewDialog(locationId));
  }

  return (
    <>
      <div onClick={() => onLocationSelect(locationId)}>
        <div className="flex w-full py-3 align-items-center text-sm location-item cursor-pointer">
          <img
            className="flex booking-location-icon mr-2 cursor-pointer"
            src={goPickupReturnIconUrl}
          />
          <div className="flex-1 ">{locationName}</div>
          {showKm && (
            <span className="text-sm font-semibold">{`${Math.round(locationDistance)} km.`}</span>
          )}
          <img
            className="flex booking-location-icon "
            src={informationIconUrl || ''}
            onClick={(e) => openLocationOverview(e, locationId)}
          />
        </div>
        <Divider className="m-0 booking-widget-horizontal-divider flex w-full" />
      </div>
    </>
  );
}
