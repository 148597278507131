import { BookingTime } from '../time/BookingTime';
import BookingWidgetDateTimeContainer from '../inputs/BookingWidgetDateTimeContainer';
import { useAppDispatch } from 'store/hooks';
import { updateCurrentWidgetStep } from 'store/booking/booking.thunk';
import { WidgetInput } from '../widget-input.enum';
import PrimaryButton from 'shared/components/primary-button/PrimaryButton';

export default function BookingMobileTimeContainer() {
  const dispatch = useAppDispatch();

  function confirmTimes() {
    dispatch(updateCurrentWidgetStep(WidgetInput.NONE));
  }
  return (
    <>
      <BookingWidgetDateTimeContainer />
      <div className="flex flex-column">
        <div className="mt-3 flex w-full">
          <BookingTime />
        </div>
        <PrimaryButton text="CONFIRM TIMES" className="mt-2" onClick={confirmTimes} />
      </div>
    </>
  );
}
