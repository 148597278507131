export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Hex: any;
  Json: any;
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  RichTextAST: any;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  count: Scalars['Int'];
};

/** This model contain the onboarding screens for the App. */
export type AppOnboarding = Node & {
  __typename?: 'AppOnboarding';
  /** Main Button to follow the onboarding process, from screen to screen */
  appOnboardingButton?: Maybe<Scalars['String']>;
  /** This is the short text that describes the app onboarding topic. This text field can only be formatted in the rich text field with a bold styling.  */
  appOnboardingDescription: RichText;
  /** This is the image that illustrates the topic of the app onboarding step.  */
  appOnboardingImage: Asset;
  /** The name is the title of the onboarding screen. */
  appOnboardingName: Scalars['String'];
  /** The App Onboarding Order defines the order of the screens that are shown after each other. */
  appOnboardingOrder?: Maybe<Scalars['Int']>;
  /** Permanent Bottom Link to skip onboarding */
  appOnboardingSkipLink?: Maybe<Scalars['String']>;
  /** This is the header of the app onboarding screen to further describe the topic. */
  appOnboardingSubtitle?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AppOnboarding>;
  /** This is the download app button text that appear in the header */
  downloadAppButtonTextHeader?: Maybe<Scalars['String']>;
  /** List of AppOnboarding versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AppOnboarding>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingAppOnboardingImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model contain the onboarding screens for the App. */
export type AppOnboardingUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type AppOnboardingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AppOnboardingWhereUniqueInput;
};

/** A connection to a list of items. */
export type AppOnboardingConnection = {
  __typename?: 'AppOnboardingConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AppOnboardingEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AppOnboardingCreateInput = {
  /** appOnboardingButton input for default locale (de_CH) */
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  /** appOnboardingDescription input for default locale (de_CH) */
  appOnboardingDescription: Scalars['RichTextAST'];
  appOnboardingImage: AssetCreateOneInlineInput;
  /** appOnboardingName input for default locale (de_CH) */
  appOnboardingName: Scalars['String'];
  appOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** appOnboardingSkipLink input for default locale (de_CH) */
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** appOnboardingSubtitle input for default locale (de_CH) */
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** downloadAppButtonTextHeader input for default locale (de_CH) */
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AppOnboardingCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AppOnboardingCreateLocalizationDataInput = {
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  appOnboardingDescription: Scalars['RichTextAST'];
  appOnboardingName: Scalars['String'];
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AppOnboardingCreateLocalizationInput = {
  /** Localization input */
  data: AppOnboardingCreateLocalizationDataInput;
  locale: Locale;
};

export type AppOnboardingCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AppOnboardingCreateLocalizationInput>>;
};

export type AppOnboardingCreateManyInlineInput = {
  /** Connect multiple existing AppOnboarding documents */
  connect?: InputMaybe<Array<AppOnboardingWhereUniqueInput>>;
  /** Create and connect multiple existing AppOnboarding documents */
  create?: InputMaybe<Array<AppOnboardingCreateInput>>;
};

export type AppOnboardingCreateOneInlineInput = {
  /** Connect one existing AppOnboarding document */
  connect?: InputMaybe<AppOnboardingWhereUniqueInput>;
  /** Create and connect one AppOnboarding document */
  create?: InputMaybe<AppOnboardingCreateInput>;
};

/** An edge in a connection. */
export type AppOnboardingEdge = {
  __typename?: 'AppOnboardingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AppOnboarding;
};

/** Identifies documents */
export type AppOnboardingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AppOnboardingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AppOnboardingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AppOnboardingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appOnboardingImage?: InputMaybe<AssetWhereInput>;
  appOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  appOnboardingOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  appOnboardingOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  appOnboardingOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  appOnboardingOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  appOnboardingOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  appOnboardingOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  appOnboardingOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AppOnboardingOrderByInput {
  AppOnboardingButtonAsc = 'appOnboardingButton_ASC',
  AppOnboardingButtonDesc = 'appOnboardingButton_DESC',
  AppOnboardingNameAsc = 'appOnboardingName_ASC',
  AppOnboardingNameDesc = 'appOnboardingName_DESC',
  AppOnboardingOrderAsc = 'appOnboardingOrder_ASC',
  AppOnboardingOrderDesc = 'appOnboardingOrder_DESC',
  AppOnboardingSkipLinkAsc = 'appOnboardingSkipLink_ASC',
  AppOnboardingSkipLinkDesc = 'appOnboardingSkipLink_DESC',
  AppOnboardingSubtitleAsc = 'appOnboardingSubtitle_ASC',
  AppOnboardingSubtitleDesc = 'appOnboardingSubtitle_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DownloadAppButtonTextHeaderAsc = 'downloadAppButtonTextHeader_ASC',
  DownloadAppButtonTextHeaderDesc = 'downloadAppButtonTextHeader_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AppOnboardingUpdateInput = {
  /** appOnboardingButton input for default locale (de_CH) */
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  /** appOnboardingDescription input for default locale (de_CH) */
  appOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  appOnboardingImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** appOnboardingName input for default locale (de_CH) */
  appOnboardingName?: InputMaybe<Scalars['String']>;
  appOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** appOnboardingSkipLink input for default locale (de_CH) */
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** appOnboardingSubtitle input for default locale (de_CH) */
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** downloadAppButtonTextHeader input for default locale (de_CH) */
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AppOnboardingUpdateLocalizationsInput>;
};

export type AppOnboardingUpdateLocalizationDataInput = {
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  appOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  appOnboardingName?: InputMaybe<Scalars['String']>;
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
};

export type AppOnboardingUpdateLocalizationInput = {
  data: AppOnboardingUpdateLocalizationDataInput;
  locale: Locale;
};

export type AppOnboardingUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AppOnboardingCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AppOnboardingUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AppOnboardingUpsertLocalizationInput>>;
};

export type AppOnboardingUpdateManyInlineInput = {
  /** Connect multiple existing AppOnboarding documents */
  connect?: InputMaybe<Array<AppOnboardingConnectInput>>;
  /** Create and connect multiple AppOnboarding documents */
  create?: InputMaybe<Array<AppOnboardingCreateInput>>;
  /** Delete multiple AppOnboarding documents */
  delete?: InputMaybe<Array<AppOnboardingWhereUniqueInput>>;
  /** Disconnect multiple AppOnboarding documents */
  disconnect?: InputMaybe<Array<AppOnboardingWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AppOnboarding documents */
  set?: InputMaybe<Array<AppOnboardingWhereUniqueInput>>;
  /** Update multiple AppOnboarding documents */
  update?: InputMaybe<Array<AppOnboardingUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AppOnboarding documents */
  upsert?: InputMaybe<Array<AppOnboardingUpsertWithNestedWhereUniqueInput>>;
};

export type AppOnboardingUpdateManyInput = {
  /** appOnboardingButton input for default locale (de_CH) */
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  /** appOnboardingDescription input for default locale (de_CH) */
  appOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** appOnboardingName input for default locale (de_CH) */
  appOnboardingName?: InputMaybe<Scalars['String']>;
  /** appOnboardingSkipLink input for default locale (de_CH) */
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** appOnboardingSubtitle input for default locale (de_CH) */
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** downloadAppButtonTextHeader input for default locale (de_CH) */
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AppOnboardingUpdateManyLocalizationsInput>;
};

export type AppOnboardingUpdateManyLocalizationDataInput = {
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  appOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  appOnboardingName?: InputMaybe<Scalars['String']>;
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
};

export type AppOnboardingUpdateManyLocalizationInput = {
  data: AppOnboardingUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AppOnboardingUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AppOnboardingUpdateManyLocalizationInput>>;
};

export type AppOnboardingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AppOnboardingUpdateManyInput;
  /** Document search */
  where: AppOnboardingWhereInput;
};

export type AppOnboardingUpdateOneInlineInput = {
  /** Connect existing AppOnboarding document */
  connect?: InputMaybe<AppOnboardingWhereUniqueInput>;
  /** Create and connect one AppOnboarding document */
  create?: InputMaybe<AppOnboardingCreateInput>;
  /** Delete currently connected AppOnboarding document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AppOnboarding document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AppOnboarding document */
  update?: InputMaybe<AppOnboardingUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AppOnboarding document */
  upsert?: InputMaybe<AppOnboardingUpsertWithNestedWhereUniqueInput>;
};

export type AppOnboardingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AppOnboardingUpdateInput;
  /** Unique document search */
  where: AppOnboardingWhereUniqueInput;
};

export type AppOnboardingUpsertInput = {
  /** Create document if it didn't exist */
  create: AppOnboardingCreateInput;
  /** Update document if it exists */
  update: AppOnboardingUpdateInput;
};

export type AppOnboardingUpsertLocalizationInput = {
  create: AppOnboardingCreateLocalizationDataInput;
  locale: Locale;
  update: AppOnboardingUpdateLocalizationDataInput;
};

export type AppOnboardingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AppOnboardingUpsertInput;
  /** Unique document search */
  where: AppOnboardingWhereUniqueInput;
};

/** Identifies documents */
export type AppOnboardingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AppOnboardingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AppOnboardingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AppOnboardingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appOnboardingButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  appOnboardingButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  appOnboardingButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  appOnboardingButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  appOnboardingButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  appOnboardingButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  appOnboardingButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  appOnboardingButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  appOnboardingButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  appOnboardingButton_starts_with?: InputMaybe<Scalars['String']>;
  appOnboardingImage?: InputMaybe<AssetWhereInput>;
  appOnboardingName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  appOnboardingName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  appOnboardingName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  appOnboardingName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  appOnboardingName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  appOnboardingName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  appOnboardingName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  appOnboardingName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  appOnboardingName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  appOnboardingName_starts_with?: InputMaybe<Scalars['String']>;
  appOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  appOnboardingOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  appOnboardingOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  appOnboardingOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  appOnboardingOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  appOnboardingOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  appOnboardingOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  appOnboardingOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  appOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  appOnboardingSkipLink_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  appOnboardingSkipLink_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  appOnboardingSkipLink_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  appOnboardingSkipLink_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  appOnboardingSkipLink_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  appOnboardingSkipLink_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  appOnboardingSkipLink_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  appOnboardingSkipLink_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  appOnboardingSkipLink_starts_with?: InputMaybe<Scalars['String']>;
  appOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  appOnboardingSubtitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  appOnboardingSubtitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  appOnboardingSubtitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  appOnboardingSubtitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  appOnboardingSubtitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  appOnboardingSubtitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  appOnboardingSubtitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  appOnboardingSubtitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  appOnboardingSubtitle_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  downloadAppButtonTextHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  downloadAppButtonTextHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  downloadAppButtonTextHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  downloadAppButtonTextHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  downloadAppButtonTextHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  downloadAppButtonTextHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  downloadAppButtonTextHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  downloadAppButtonTextHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  downloadAppButtonTextHeader_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References AppOnboarding record uniquely */
export type AppOnboardingWhereUniqueInput = {
  appOnboardingOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** Asset system model */
export type Asset = Node & {
  __typename?: 'Asset';
  appOnboardingImageAppOnboarding: Array<AppOnboarding>;
  appStoreImageGoWebappNavLink: Array<GoWebappNavLink>;
  bannerIconBanner: Array<Banner>;
  bottomNavigationItemIconBottomNavigationItem: Array<BottomNavigationItem>;
  bottomNavigationItemIconInactiveBottomNavigationItem: Array<BottomNavigationItem>;
  cancellationPolicyIconsRental: Array<Rental>;
  carModelFeatureIconCarModelFeature: Array<CarModelFeature>;
  carModelImageCarModel: Array<CarModel>;
  cardExpandCollapseIconPriceBreakdown: Array<PriceBreakdown>;
  classFeatureIconClass: Array<Class>;
  classImageClass: Array<Class>;
  closingScreeenCalendar: Array<Calendar>;
  contactButtonIconRental: Array<Rental>;
  coverageFeatureIconCoverage: Array<Coverage>;
  coverageTagCoverage: Array<Coverage>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  deletePaymentMethodIconProfile: Array<Profile>;
  /** Get the document in other stages */
  documentInStages: Array<Asset>;
  editProfileIconProfile: Array<Profile>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding: Array<EmergencyKeyOnboarding>;
  errorMessageHeaderErrorMessage: Array<ErrorMessage>;
  extraDeselectIconExtra: Array<Extra>;
  extrasIconExtra: Array<Extra>;
  /** The file name */
  fileName: Scalars['String'];
  goCurrentLocationGOSearch: Array<GoSearch>;
  goCurrentLocationIconInactiveGOSearch: Array<GoSearch>;
  goLocationImageGOLocation: Array<GoLocation>;
  goPickUpReturnIconGOSearch: Array<GoSearch>;
  goPickUpTimeIconGOSearch: Array<GoSearch>;
  goReturnDateIconGOSearch: Array<GoSearch>;
  goSearchLocationInformationGOSearch: Array<GoSearch>;
  goSearchPickUpIconGOSearch: Array<GoSearch>;
  goSearchRentalPeriodCalendarGOSearch: Array<GoSearch>;
  goSearchReturnIconGOSearch: Array<GoSearch>;
  googleMapsLinkIconGOLocation: Array<GoLocation>;
  hamburgerItemIconHamburgerMenuItem: Array<HamburgerMenuItem>;
  /** The file handle */
  handle: Scalars['String'];
  /** The height of the file */
  height?: Maybe<Scalars['Float']>;
  heroImageGoWebappHomepage: Array<GoWebappHomepage>;
  /** List of Asset versions */
  history: Array<Version>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage: Array<GoWebappHomepage>;
  howItWorksCardImageGoWebappHomepage: Array<GoWebappHomepage>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage: Array<GoWebappHomepage>;
  /** The unique identifier */
  id: Scalars['ID'];
  importantInformationIconKey: Array<Key>;
  includedRentalFeaturesIconGoSelection: Array<GoSelection>;
  languageIconGoWebappNavLink: Array<GoWebappNavLink>;
  legalCheckboxGoSelection: Array<GoSelection>;
  legalcheckboxProfile: Array<Profile>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Asset>;
  locationInformationIconGoWebappBookingOverview: Array<GoWebappBookingOverview>;
  mediaImagesGoWebappHomepage: Array<GoWebappHomepage>;
  mediaLinkAssetsGoWebappHomepage: Array<GoWebappHomepage>;
  mediaSectionHeroImageGoWebappHomepage: Array<GoWebappHomepage>;
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']>;
  noCarsImageGoBookingJourney: Array<GoBookingJourney>;
  noCurrentRentalImageRental: Array<Rental>;
  noKeyImageKey: Array<Key>;
  onboardingImageKeyOnboarding: Array<KeyOnboarding>;
  partnershipAssetsGoWebappHomepage: Array<GoWebappHomepage>;
  paymentMethodIconGoSelection: Array<GoSelection>;
  paymentMethodIconProfile: Array<Profile>;
  pickUpLocationIconGoWebappBookingOverview: Array<GoWebappBookingOverview>;
  popUpCardHeaderIconPopUpCard: Array<PopUpCard>;
  popUpCloseIconPopUpCard: Array<PopUpCard>;
  profileIconsProfile: Array<Profile>;
  profileSectionIconProfile: Array<Profile>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  rentalLocationIconGoWebappBookingOverview: Array<GoWebappBookingOverview>;
  rentalPeriodIconGoWebappBookingOverview: Array<GoWebappBookingOverview>;
  rentalReturnIconGoWebappBookingOverview: Array<GoWebappBookingOverview>;
  scheduledIn: Array<ScheduledOperation>;
  /** The file size */
  size?: Maybe<Scalars['Float']>;
  /** System stage field */
  stage: Stage;
  subClassFeatureIconSubClass: Array<SubClass>;
  subClassImageSubClass: Array<SubClass>;
  subclassFallbackFeaturesIconSubClass: Array<SubClass>;
  successfulPaymentPageImageSuccessfulPaymentScreen: Array<SuccessfulPaymentScreen>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String'];
  valuePropositionImageGoWebappHomepage: Array<GoWebappHomepage>;
  verficationiconVerification: Array<Verification>;
  verificationImageVerification: Array<Verification>;
  verificationLinkIconProfile: Array<Profile>;
  verificationPhotoUploadMethodIconsVerification: Array<Verification>;
  verificationStatusIconProfile: Array<Profile>;
  verificationSubtitleIconVerification: Array<Verification>;
  /** The file width */
  width?: Maybe<Scalars['Float']>;
};


/** Asset system model */
export type AssetAppOnboardingImageAppOnboardingArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AppOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppOnboardingWhereInput>;
};


/** Asset system model */
export type AssetAppStoreImageGoWebappNavLinkArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappNavLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappNavLinkWhereInput>;
};


/** Asset system model */
export type AssetBannerIconBannerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerWhereInput>;
};


/** Asset system model */
export type AssetBottomNavigationItemIconBottomNavigationItemArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BottomNavigationItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BottomNavigationItemWhereInput>;
};


/** Asset system model */
export type AssetBottomNavigationItemIconInactiveBottomNavigationItemArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BottomNavigationItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BottomNavigationItemWhereInput>;
};


/** Asset system model */
export type AssetCancellationPolicyIconsRentalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<RentalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentalWhereInput>;
};


/** Asset system model */
export type AssetCarModelFeatureIconCarModelFeatureArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelFeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelFeatureWhereInput>;
};


/** Asset system model */
export type AssetCarModelImageCarModelArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelWhereInput>;
};


/** Asset system model */
export type AssetCardExpandCollapseIconPriceBreakdownArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PriceBreakdownOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PriceBreakdownWhereInput>;
};


/** Asset system model */
export type AssetClassFeatureIconClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassWhereInput>;
};


/** Asset system model */
export type AssetClassImageClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassWhereInput>;
};


/** Asset system model */
export type AssetClosingScreeenCalendarArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CalendarOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarWhereInput>;
};


/** Asset system model */
export type AssetContactButtonIconRentalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<RentalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentalWhereInput>;
};


/** Asset system model */
export type AssetCoverageFeatureIconCoverageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CoverageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoverageWhereInput>;
};


/** Asset system model */
export type AssetCoverageTagCoverageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CoverageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoverageWhereInput>;
};


/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Asset system model */
export type AssetDeletePaymentMethodIconProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Asset system model */
export type AssetEditProfileIconProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetEmergencyKeyOnboardingImageEmergencyKeyOnboardingArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<EmergencyKeyOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
};


/** Asset system model */
export type AssetErrorMessageHeaderErrorMessageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ErrorMessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ErrorMessageWhereInput>;
};


/** Asset system model */
export type AssetExtraDeselectIconExtraArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ExtraOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtraWhereInput>;
};


/** Asset system model */
export type AssetExtrasIconExtraArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ExtraOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtraWhereInput>;
};


/** Asset system model */
export type AssetGoCurrentLocationGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoCurrentLocationIconInactiveGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoLocationImageGoLocationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoLocationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoLocationWhereInput>;
};


/** Asset system model */
export type AssetGoPickUpReturnIconGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoPickUpTimeIconGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoReturnDateIconGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoSearchLocationInformationGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoSearchPickUpIconGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoSearchRentalPeriodCalendarGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoSearchReturnIconGoSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchWhereInput>;
};


/** Asset system model */
export type AssetGoogleMapsLinkIconGoLocationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoLocationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoLocationWhereInput>;
};


/** Asset system model */
export type AssetHamburgerItemIconHamburgerMenuItemArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<HamburgerMenuItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HamburgerMenuItemWhereInput>;
};


/** Asset system model */
export type AssetHeroImageGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Asset system model */
export type AssetHowItWorksBackgroundImageMobileViewportsGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetHowItWorksCardImageGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetHowItWorksSectionBackgroundImageDesktopGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetImportantInformationIconKeyArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<KeyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyWhereInput>;
};


/** Asset system model */
export type AssetIncludedRentalFeaturesIconGoSelectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSelectionWhereInput>;
};


/** Asset system model */
export type AssetLanguageIconGoWebappNavLinkArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappNavLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappNavLinkWhereInput>;
};


/** Asset system model */
export type AssetLegalCheckboxGoSelectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSelectionWhereInput>;
};


/** Asset system model */
export type AssetLegalcheckboxProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Asset system model */
export type AssetLocationInformationIconGoWebappBookingOverviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


/** Asset system model */
export type AssetMediaImagesGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetMediaLinkAssetsGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetMediaSectionHeroImageGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetNoCarsImageGoBookingJourneyArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoBookingJourneyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoBookingJourneyWhereInput>;
};


/** Asset system model */
export type AssetNoCurrentRentalImageRentalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<RentalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentalWhereInput>;
};


/** Asset system model */
export type AssetNoKeyImageKeyArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<KeyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyWhereInput>;
};


/** Asset system model */
export type AssetOnboardingImageKeyOnboardingArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<KeyOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyOnboardingWhereInput>;
};


/** Asset system model */
export type AssetPartnershipAssetsGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetPaymentMethodIconGoSelectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSelectionWhereInput>;
};


/** Asset system model */
export type AssetPaymentMethodIconProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetPickUpLocationIconGoWebappBookingOverviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


/** Asset system model */
export type AssetPopUpCardHeaderIconPopUpCardArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PopUpCardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopUpCardWhereInput>;
};


/** Asset system model */
export type AssetPopUpCloseIconPopUpCardArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PopUpCardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopUpCardWhereInput>;
};


/** Asset system model */
export type AssetProfileIconsProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetProfileSectionIconProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Asset system model */
export type AssetRentalLocationIconGoWebappBookingOverviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


/** Asset system model */
export type AssetRentalPeriodIconGoWebappBookingOverviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


/** Asset system model */
export type AssetRentalReturnIconGoWebappBookingOverviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Asset system model */
export type AssetSubClassFeatureIconSubClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SubClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubClassWhereInput>;
};


/** Asset system model */
export type AssetSubClassImageSubClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SubClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubClassWhereInput>;
};


/** Asset system model */
export type AssetSubclassFallbackFeaturesIconSubClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SubClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubClassWhereInput>;
};


/** Asset system model */
export type AssetSuccessfulPaymentPageImageSuccessfulPaymentScreenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SuccessfulPaymentScreenOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
};


/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>;
};


/** Asset system model */
export type AssetValuePropositionImageGoWebappHomepageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


/** Asset system model */
export type AssetVerficationiconVerificationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VerificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationWhereInput>;
};


/** Asset system model */
export type AssetVerificationImageVerificationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VerificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationWhereInput>;
};


/** Asset system model */
export type AssetVerificationLinkIconProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetVerificationPhotoUploadMethodIconsVerificationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VerificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationWhereInput>;
};


/** Asset system model */
export type AssetVerificationStatusIconProfileArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileWhereInput>;
};


/** Asset system model */
export type AssetVerificationSubtitleIconVerificationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VerificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationWhereInput>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  appOnboardingImageAppOnboarding?: InputMaybe<AppOnboardingCreateManyInlineInput>;
  appStoreImageGoWebappNavLink?: InputMaybe<GoWebappNavLinkCreateManyInlineInput>;
  bannerIconBanner?: InputMaybe<BannerCreateManyInlineInput>;
  bottomNavigationItemIconBottomNavigationItem?: InputMaybe<BottomNavigationItemCreateManyInlineInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem?: InputMaybe<BottomNavigationItemCreateManyInlineInput>;
  cancellationPolicyIconsRental?: InputMaybe<RentalCreateManyInlineInput>;
  carModelFeatureIconCarModelFeature?: InputMaybe<CarModelFeatureCreateManyInlineInput>;
  carModelImageCarModel?: InputMaybe<CarModelCreateManyInlineInput>;
  cardExpandCollapseIconPriceBreakdown?: InputMaybe<PriceBreakdownCreateManyInlineInput>;
  classFeatureIconClass?: InputMaybe<ClassCreateManyInlineInput>;
  classImageClass?: InputMaybe<ClassCreateManyInlineInput>;
  closingScreeenCalendar?: InputMaybe<CalendarCreateManyInlineInput>;
  contactButtonIconRental?: InputMaybe<RentalCreateManyInlineInput>;
  coverageFeatureIconCoverage?: InputMaybe<CoverageCreateManyInlineInput>;
  coverageTagCoverage?: InputMaybe<CoverageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletePaymentMethodIconProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  editProfileIconProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding?: InputMaybe<EmergencyKeyOnboardingCreateManyInlineInput>;
  errorMessageHeaderErrorMessage?: InputMaybe<ErrorMessageCreateManyInlineInput>;
  extraDeselectIconExtra?: InputMaybe<ExtraCreateManyInlineInput>;
  extrasIconExtra?: InputMaybe<ExtraCreateManyInlineInput>;
  fileName: Scalars['String'];
  goCurrentLocationGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goCurrentLocationIconInactiveGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goLocationImageGOLocation?: InputMaybe<GoLocationCreateManyInlineInput>;
  goPickUpReturnIconGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goPickUpTimeIconGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goReturnDateIconGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goSearchLocationInformationGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goSearchPickUpIconGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goSearchRentalPeriodCalendarGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  goSearchReturnIconGOSearch?: InputMaybe<GoSearchCreateManyInlineInput>;
  googleMapsLinkIconGOLocation?: InputMaybe<GoLocationCreateManyInlineInput>;
  hamburgerItemIconHamburgerMenuItem?: InputMaybe<HamburgerMenuItemCreateManyInlineInput>;
  handle: Scalars['String'];
  height?: InputMaybe<Scalars['Float']>;
  heroImageGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  howItWorksCardImageGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  importantInformationIconKey?: InputMaybe<KeyCreateManyInlineInput>;
  includedRentalFeaturesIconGoSelection?: InputMaybe<GoSelectionCreateManyInlineInput>;
  languageIconGoWebappNavLink?: InputMaybe<GoWebappNavLinkCreateManyInlineInput>;
  legalCheckboxGoSelection?: InputMaybe<GoSelectionCreateManyInlineInput>;
  legalcheckboxProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  locationInformationIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewCreateManyInlineInput>;
  mediaImagesGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  mediaLinkAssetsGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  mediaSectionHeroImageGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  noCarsImageGoBookingJourney?: InputMaybe<GoBookingJourneyCreateManyInlineInput>;
  noCurrentRentalImageRental?: InputMaybe<RentalCreateManyInlineInput>;
  noKeyImageKey?: InputMaybe<KeyCreateManyInlineInput>;
  onboardingImageKeyOnboarding?: InputMaybe<KeyOnboardingCreateManyInlineInput>;
  partnershipAssetsGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  paymentMethodIconGoSelection?: InputMaybe<GoSelectionCreateManyInlineInput>;
  paymentMethodIconProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  pickUpLocationIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewCreateManyInlineInput>;
  popUpCardHeaderIconPopUpCard?: InputMaybe<PopUpCardCreateManyInlineInput>;
  popUpCloseIconPopUpCard?: InputMaybe<PopUpCardCreateManyInlineInput>;
  profileIconsProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  profileSectionIconProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  rentalLocationIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewCreateManyInlineInput>;
  rentalPeriodIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewCreateManyInlineInput>;
  rentalReturnIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewCreateManyInlineInput>;
  size?: InputMaybe<Scalars['Float']>;
  subClassFeatureIconSubClass?: InputMaybe<SubClassCreateManyInlineInput>;
  subClassImageSubClass?: InputMaybe<SubClassCreateManyInlineInput>;
  subclassFallbackFeaturesIconSubClass?: InputMaybe<SubClassCreateManyInlineInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen?: InputMaybe<SuccessfulPaymentScreenCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valuePropositionImageGoWebappHomepage?: InputMaybe<GoWebappHomepageCreateManyInlineInput>;
  verficationiconVerification?: InputMaybe<VerificationCreateManyInlineInput>;
  verificationImageVerification?: InputMaybe<VerificationCreateManyInlineInput>;
  verificationLinkIconProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  verificationPhotoUploadMethodIconsVerification?: InputMaybe<VerificationCreateManyInlineInput>;
  verificationStatusIconProfile?: InputMaybe<ProfileCreateManyInlineInput>;
  verificationSubtitleIconVerification?: InputMaybe<VerificationCreateManyInlineInput>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileName: Scalars['String'];
  handle: Scalars['String'];
  height?: InputMaybe<Scalars['Float']>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appOnboardingImageAppOnboarding_every?: InputMaybe<AppOnboardingWhereInput>;
  appOnboardingImageAppOnboarding_none?: InputMaybe<AppOnboardingWhereInput>;
  appOnboardingImageAppOnboarding_some?: InputMaybe<AppOnboardingWhereInput>;
  appStoreImageGoWebappNavLink_every?: InputMaybe<GoWebappNavLinkWhereInput>;
  appStoreImageGoWebappNavLink_none?: InputMaybe<GoWebappNavLinkWhereInput>;
  appStoreImageGoWebappNavLink_some?: InputMaybe<GoWebappNavLinkWhereInput>;
  bannerIconBanner_every?: InputMaybe<BannerWhereInput>;
  bannerIconBanner_none?: InputMaybe<BannerWhereInput>;
  bannerIconBanner_some?: InputMaybe<BannerWhereInput>;
  bottomNavigationItemIconBottomNavigationItem_every?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconBottomNavigationItem_none?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconBottomNavigationItem_some?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem_every?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem_none?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem_some?: InputMaybe<BottomNavigationItemWhereInput>;
  cancellationPolicyIconsRental_every?: InputMaybe<RentalWhereInput>;
  cancellationPolicyIconsRental_none?: InputMaybe<RentalWhereInput>;
  cancellationPolicyIconsRental_some?: InputMaybe<RentalWhereInput>;
  carModelFeatureIconCarModelFeature_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatureIconCarModelFeature_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatureIconCarModelFeature_some?: InputMaybe<CarModelFeatureWhereInput>;
  carModelImageCarModel_every?: InputMaybe<CarModelWhereInput>;
  carModelImageCarModel_none?: InputMaybe<CarModelWhereInput>;
  carModelImageCarModel_some?: InputMaybe<CarModelWhereInput>;
  cardExpandCollapseIconPriceBreakdown_every?: InputMaybe<PriceBreakdownWhereInput>;
  cardExpandCollapseIconPriceBreakdown_none?: InputMaybe<PriceBreakdownWhereInput>;
  cardExpandCollapseIconPriceBreakdown_some?: InputMaybe<PriceBreakdownWhereInput>;
  classFeatureIconClass_every?: InputMaybe<ClassWhereInput>;
  classFeatureIconClass_none?: InputMaybe<ClassWhereInput>;
  classFeatureIconClass_some?: InputMaybe<ClassWhereInput>;
  classImageClass_every?: InputMaybe<ClassWhereInput>;
  classImageClass_none?: InputMaybe<ClassWhereInput>;
  classImageClass_some?: InputMaybe<ClassWhereInput>;
  closingScreeenCalendar_every?: InputMaybe<CalendarWhereInput>;
  closingScreeenCalendar_none?: InputMaybe<CalendarWhereInput>;
  closingScreeenCalendar_some?: InputMaybe<CalendarWhereInput>;
  contactButtonIconRental_every?: InputMaybe<RentalWhereInput>;
  contactButtonIconRental_none?: InputMaybe<RentalWhereInput>;
  contactButtonIconRental_some?: InputMaybe<RentalWhereInput>;
  coverageFeatureIconCoverage_every?: InputMaybe<CoverageWhereInput>;
  coverageFeatureIconCoverage_none?: InputMaybe<CoverageWhereInput>;
  coverageFeatureIconCoverage_some?: InputMaybe<CoverageWhereInput>;
  coverageTagCoverage_every?: InputMaybe<CoverageWhereInput>;
  coverageTagCoverage_none?: InputMaybe<CoverageWhereInput>;
  coverageTagCoverage_some?: InputMaybe<CoverageWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  deletePaymentMethodIconProfile_every?: InputMaybe<ProfileWhereInput>;
  deletePaymentMethodIconProfile_none?: InputMaybe<ProfileWhereInput>;
  deletePaymentMethodIconProfile_some?: InputMaybe<ProfileWhereInput>;
  editProfileIconProfile_every?: InputMaybe<ProfileWhereInput>;
  editProfileIconProfile_none?: InputMaybe<ProfileWhereInput>;
  editProfileIconProfile_some?: InputMaybe<ProfileWhereInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding_every?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding_none?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding_some?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
  errorMessageHeaderErrorMessage_every?: InputMaybe<ErrorMessageWhereInput>;
  errorMessageHeaderErrorMessage_none?: InputMaybe<ErrorMessageWhereInput>;
  errorMessageHeaderErrorMessage_some?: InputMaybe<ErrorMessageWhereInput>;
  extraDeselectIconExtra_every?: InputMaybe<ExtraWhereInput>;
  extraDeselectIconExtra_none?: InputMaybe<ExtraWhereInput>;
  extraDeselectIconExtra_some?: InputMaybe<ExtraWhereInput>;
  extrasIconExtra_every?: InputMaybe<ExtraWhereInput>;
  extrasIconExtra_none?: InputMaybe<ExtraWhereInput>;
  extrasIconExtra_some?: InputMaybe<ExtraWhereInput>;
  goCurrentLocationGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationIconInactiveGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationIconInactiveGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationIconInactiveGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goLocationImageGOLocation_every?: InputMaybe<GoLocationWhereInput>;
  goLocationImageGOLocation_none?: InputMaybe<GoLocationWhereInput>;
  goLocationImageGOLocation_some?: InputMaybe<GoLocationWhereInput>;
  goPickUpReturnIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goPickUpReturnIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goPickUpReturnIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goPickUpTimeIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goPickUpTimeIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goPickUpTimeIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goReturnDateIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goReturnDateIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goReturnDateIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchLocationInformationGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchLocationInformationGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchLocationInformationGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchPickUpIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchPickUpIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchPickUpIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchRentalPeriodCalendarGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchRentalPeriodCalendarGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchRentalPeriodCalendarGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchReturnIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchReturnIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchReturnIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  googleMapsLinkIconGOLocation_every?: InputMaybe<GoLocationWhereInput>;
  googleMapsLinkIconGOLocation_none?: InputMaybe<GoLocationWhereInput>;
  googleMapsLinkIconGOLocation_some?: InputMaybe<GoLocationWhereInput>;
  hamburgerItemIconHamburgerMenuItem_every?: InputMaybe<HamburgerMenuItemWhereInput>;
  hamburgerItemIconHamburgerMenuItem_none?: InputMaybe<HamburgerMenuItemWhereInput>;
  hamburgerItemIconHamburgerMenuItem_some?: InputMaybe<HamburgerMenuItemWhereInput>;
  heroImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  heroImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  heroImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksCardImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksCardImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksCardImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  importantInformationIconKey_every?: InputMaybe<KeyWhereInput>;
  importantInformationIconKey_none?: InputMaybe<KeyWhereInput>;
  importantInformationIconKey_some?: InputMaybe<KeyWhereInput>;
  includedRentalFeaturesIconGoSelection_every?: InputMaybe<GoSelectionWhereInput>;
  includedRentalFeaturesIconGoSelection_none?: InputMaybe<GoSelectionWhereInput>;
  includedRentalFeaturesIconGoSelection_some?: InputMaybe<GoSelectionWhereInput>;
  languageIconGoWebappNavLink_every?: InputMaybe<GoWebappNavLinkWhereInput>;
  languageIconGoWebappNavLink_none?: InputMaybe<GoWebappNavLinkWhereInput>;
  languageIconGoWebappNavLink_some?: InputMaybe<GoWebappNavLinkWhereInput>;
  legalCheckboxGoSelection_every?: InputMaybe<GoSelectionWhereInput>;
  legalCheckboxGoSelection_none?: InputMaybe<GoSelectionWhereInput>;
  legalCheckboxGoSelection_some?: InputMaybe<GoSelectionWhereInput>;
  legalcheckboxProfile_every?: InputMaybe<ProfileWhereInput>;
  legalcheckboxProfile_none?: InputMaybe<ProfileWhereInput>;
  legalcheckboxProfile_some?: InputMaybe<ProfileWhereInput>;
  locationInformationIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  locationInformationIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  locationInformationIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  mediaImagesGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaImagesGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaImagesGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaLinkAssetsGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaLinkAssetsGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaLinkAssetsGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaSectionHeroImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaSectionHeroImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaSectionHeroImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  noCarsImageGoBookingJourney_every?: InputMaybe<GoBookingJourneyWhereInput>;
  noCarsImageGoBookingJourney_none?: InputMaybe<GoBookingJourneyWhereInput>;
  noCarsImageGoBookingJourney_some?: InputMaybe<GoBookingJourneyWhereInput>;
  noCurrentRentalImageRental_every?: InputMaybe<RentalWhereInput>;
  noCurrentRentalImageRental_none?: InputMaybe<RentalWhereInput>;
  noCurrentRentalImageRental_some?: InputMaybe<RentalWhereInput>;
  noKeyImageKey_every?: InputMaybe<KeyWhereInput>;
  noKeyImageKey_none?: InputMaybe<KeyWhereInput>;
  noKeyImageKey_some?: InputMaybe<KeyWhereInput>;
  onboardingImageKeyOnboarding_every?: InputMaybe<KeyOnboardingWhereInput>;
  onboardingImageKeyOnboarding_none?: InputMaybe<KeyOnboardingWhereInput>;
  onboardingImageKeyOnboarding_some?: InputMaybe<KeyOnboardingWhereInput>;
  partnershipAssetsGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  partnershipAssetsGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  partnershipAssetsGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  paymentMethodIconGoSelection_every?: InputMaybe<GoSelectionWhereInput>;
  paymentMethodIconGoSelection_none?: InputMaybe<GoSelectionWhereInput>;
  paymentMethodIconGoSelection_some?: InputMaybe<GoSelectionWhereInput>;
  paymentMethodIconProfile_every?: InputMaybe<ProfileWhereInput>;
  paymentMethodIconProfile_none?: InputMaybe<ProfileWhereInput>;
  paymentMethodIconProfile_some?: InputMaybe<ProfileWhereInput>;
  pickUpLocationIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  pickUpLocationIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  pickUpLocationIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  popUpCardHeaderIconPopUpCard_every?: InputMaybe<PopUpCardWhereInput>;
  popUpCardHeaderIconPopUpCard_none?: InputMaybe<PopUpCardWhereInput>;
  popUpCardHeaderIconPopUpCard_some?: InputMaybe<PopUpCardWhereInput>;
  popUpCloseIconPopUpCard_every?: InputMaybe<PopUpCardWhereInput>;
  popUpCloseIconPopUpCard_none?: InputMaybe<PopUpCardWhereInput>;
  popUpCloseIconPopUpCard_some?: InputMaybe<PopUpCardWhereInput>;
  profileIconsProfile_every?: InputMaybe<ProfileWhereInput>;
  profileIconsProfile_none?: InputMaybe<ProfileWhereInput>;
  profileIconsProfile_some?: InputMaybe<ProfileWhereInput>;
  profileSectionIconProfile_every?: InputMaybe<ProfileWhereInput>;
  profileSectionIconProfile_none?: InputMaybe<ProfileWhereInput>;
  profileSectionIconProfile_some?: InputMaybe<ProfileWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentalLocationIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalLocationIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalLocationIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalPeriodIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalPeriodIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalPeriodIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalReturnIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalReturnIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalReturnIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subClassFeatureIconSubClass_every?: InputMaybe<SubClassWhereInput>;
  subClassFeatureIconSubClass_none?: InputMaybe<SubClassWhereInput>;
  subClassFeatureIconSubClass_some?: InputMaybe<SubClassWhereInput>;
  subClassImageSubClass_every?: InputMaybe<SubClassWhereInput>;
  subClassImageSubClass_none?: InputMaybe<SubClassWhereInput>;
  subClassImageSubClass_some?: InputMaybe<SubClassWhereInput>;
  subclassFallbackFeaturesIconSubClass_every?: InputMaybe<SubClassWhereInput>;
  subclassFallbackFeaturesIconSubClass_none?: InputMaybe<SubClassWhereInput>;
  subclassFallbackFeaturesIconSubClass_some?: InputMaybe<SubClassWhereInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen_every?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen_none?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen_some?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  valuePropositionImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  valuePropositionImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  valuePropositionImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  verficationiconVerification_every?: InputMaybe<VerificationWhereInput>;
  verficationiconVerification_none?: InputMaybe<VerificationWhereInput>;
  verficationiconVerification_some?: InputMaybe<VerificationWhereInput>;
  verificationImageVerification_every?: InputMaybe<VerificationWhereInput>;
  verificationImageVerification_none?: InputMaybe<VerificationWhereInput>;
  verificationImageVerification_some?: InputMaybe<VerificationWhereInput>;
  verificationLinkIconProfile_every?: InputMaybe<ProfileWhereInput>;
  verificationLinkIconProfile_none?: InputMaybe<ProfileWhereInput>;
  verificationLinkIconProfile_some?: InputMaybe<ProfileWhereInput>;
  verificationPhotoUploadMethodIconsVerification_every?: InputMaybe<VerificationWhereInput>;
  verificationPhotoUploadMethodIconsVerification_none?: InputMaybe<VerificationWhereInput>;
  verificationPhotoUploadMethodIconsVerification_some?: InputMaybe<VerificationWhereInput>;
  verificationStatusIconProfile_every?: InputMaybe<ProfileWhereInput>;
  verificationStatusIconProfile_none?: InputMaybe<ProfileWhereInput>;
  verificationStatusIconProfile_some?: InputMaybe<ProfileWhereInput>;
  verificationSubtitleIconVerification_every?: InputMaybe<VerificationWhereInput>;
  verificationSubtitleIconVerification_none?: InputMaybe<VerificationWhereInput>;
  verificationSubtitleIconVerification_some?: InputMaybe<VerificationWhereInput>;
};

export enum AssetOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars['Boolean']>;
};

export type AssetUpdateInput = {
  appOnboardingImageAppOnboarding?: InputMaybe<AppOnboardingUpdateManyInlineInput>;
  appStoreImageGoWebappNavLink?: InputMaybe<GoWebappNavLinkUpdateManyInlineInput>;
  bannerIconBanner?: InputMaybe<BannerUpdateManyInlineInput>;
  bottomNavigationItemIconBottomNavigationItem?: InputMaybe<BottomNavigationItemUpdateManyInlineInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem?: InputMaybe<BottomNavigationItemUpdateManyInlineInput>;
  cancellationPolicyIconsRental?: InputMaybe<RentalUpdateManyInlineInput>;
  carModelFeatureIconCarModelFeature?: InputMaybe<CarModelFeatureUpdateManyInlineInput>;
  carModelImageCarModel?: InputMaybe<CarModelUpdateManyInlineInput>;
  cardExpandCollapseIconPriceBreakdown?: InputMaybe<PriceBreakdownUpdateManyInlineInput>;
  classFeatureIconClass?: InputMaybe<ClassUpdateManyInlineInput>;
  classImageClass?: InputMaybe<ClassUpdateManyInlineInput>;
  closingScreeenCalendar?: InputMaybe<CalendarUpdateManyInlineInput>;
  contactButtonIconRental?: InputMaybe<RentalUpdateManyInlineInput>;
  coverageFeatureIconCoverage?: InputMaybe<CoverageUpdateManyInlineInput>;
  coverageTagCoverage?: InputMaybe<CoverageUpdateManyInlineInput>;
  deletePaymentMethodIconProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  editProfileIconProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding?: InputMaybe<EmergencyKeyOnboardingUpdateManyInlineInput>;
  errorMessageHeaderErrorMessage?: InputMaybe<ErrorMessageUpdateManyInlineInput>;
  extraDeselectIconExtra?: InputMaybe<ExtraUpdateManyInlineInput>;
  extrasIconExtra?: InputMaybe<ExtraUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars['String']>;
  goCurrentLocationGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goCurrentLocationIconInactiveGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goLocationImageGOLocation?: InputMaybe<GoLocationUpdateManyInlineInput>;
  goPickUpReturnIconGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goPickUpTimeIconGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goReturnDateIconGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goSearchLocationInformationGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goSearchPickUpIconGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goSearchRentalPeriodCalendarGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  goSearchReturnIconGOSearch?: InputMaybe<GoSearchUpdateManyInlineInput>;
  googleMapsLinkIconGOLocation?: InputMaybe<GoLocationUpdateManyInlineInput>;
  hamburgerItemIconHamburgerMenuItem?: InputMaybe<HamburgerMenuItemUpdateManyInlineInput>;
  handle?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  heroImageGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  howItWorksCardImageGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  importantInformationIconKey?: InputMaybe<KeyUpdateManyInlineInput>;
  includedRentalFeaturesIconGoSelection?: InputMaybe<GoSelectionUpdateManyInlineInput>;
  languageIconGoWebappNavLink?: InputMaybe<GoWebappNavLinkUpdateManyInlineInput>;
  legalCheckboxGoSelection?: InputMaybe<GoSelectionUpdateManyInlineInput>;
  legalcheckboxProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  locationInformationIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewUpdateManyInlineInput>;
  mediaImagesGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  mediaLinkAssetsGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  mediaSectionHeroImageGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  noCarsImageGoBookingJourney?: InputMaybe<GoBookingJourneyUpdateManyInlineInput>;
  noCurrentRentalImageRental?: InputMaybe<RentalUpdateManyInlineInput>;
  noKeyImageKey?: InputMaybe<KeyUpdateManyInlineInput>;
  onboardingImageKeyOnboarding?: InputMaybe<KeyOnboardingUpdateManyInlineInput>;
  partnershipAssetsGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  paymentMethodIconGoSelection?: InputMaybe<GoSelectionUpdateManyInlineInput>;
  paymentMethodIconProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  pickUpLocationIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewUpdateManyInlineInput>;
  popUpCardHeaderIconPopUpCard?: InputMaybe<PopUpCardUpdateManyInlineInput>;
  popUpCloseIconPopUpCard?: InputMaybe<PopUpCardUpdateManyInlineInput>;
  profileIconsProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  profileSectionIconProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  rentalLocationIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewUpdateManyInlineInput>;
  rentalPeriodIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewUpdateManyInlineInput>;
  rentalReturnIconGoWebappBookingOverview?: InputMaybe<GoWebappBookingOverviewUpdateManyInlineInput>;
  size?: InputMaybe<Scalars['Float']>;
  subClassFeatureIconSubClass?: InputMaybe<SubClassUpdateManyInlineInput>;
  subClassImageSubClass?: InputMaybe<SubClassUpdateManyInlineInput>;
  subclassFallbackFeaturesIconSubClass?: InputMaybe<SubClassUpdateManyInlineInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen?: InputMaybe<SuccessfulPaymentScreenUpdateManyInlineInput>;
  valuePropositionImageGoWebappHomepage?: InputMaybe<GoWebappHomepageUpdateManyInlineInput>;
  verficationiconVerification?: InputMaybe<VerificationUpdateManyInlineInput>;
  verificationImageVerification?: InputMaybe<VerificationUpdateManyInlineInput>;
  verificationLinkIconProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  verificationPhotoUploadMethodIconsVerification?: InputMaybe<VerificationUpdateManyInlineInput>;
  verificationStatusIconProfile?: InputMaybe<ProfileUpdateManyInlineInput>;
  verificationSubtitleIconVerification?: InputMaybe<VerificationUpdateManyInlineInput>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateLocalizationDataInput = {
  fileName?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  fileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appOnboardingImageAppOnboarding_every?: InputMaybe<AppOnboardingWhereInput>;
  appOnboardingImageAppOnboarding_none?: InputMaybe<AppOnboardingWhereInput>;
  appOnboardingImageAppOnboarding_some?: InputMaybe<AppOnboardingWhereInput>;
  appStoreImageGoWebappNavLink_every?: InputMaybe<GoWebappNavLinkWhereInput>;
  appStoreImageGoWebappNavLink_none?: InputMaybe<GoWebappNavLinkWhereInput>;
  appStoreImageGoWebappNavLink_some?: InputMaybe<GoWebappNavLinkWhereInput>;
  bannerIconBanner_every?: InputMaybe<BannerWhereInput>;
  bannerIconBanner_none?: InputMaybe<BannerWhereInput>;
  bannerIconBanner_some?: InputMaybe<BannerWhereInput>;
  bottomNavigationItemIconBottomNavigationItem_every?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconBottomNavigationItem_none?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconBottomNavigationItem_some?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem_every?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem_none?: InputMaybe<BottomNavigationItemWhereInput>;
  bottomNavigationItemIconInactiveBottomNavigationItem_some?: InputMaybe<BottomNavigationItemWhereInput>;
  cancellationPolicyIconsRental_every?: InputMaybe<RentalWhereInput>;
  cancellationPolicyIconsRental_none?: InputMaybe<RentalWhereInput>;
  cancellationPolicyIconsRental_some?: InputMaybe<RentalWhereInput>;
  carModelFeatureIconCarModelFeature_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatureIconCarModelFeature_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatureIconCarModelFeature_some?: InputMaybe<CarModelFeatureWhereInput>;
  carModelImageCarModel_every?: InputMaybe<CarModelWhereInput>;
  carModelImageCarModel_none?: InputMaybe<CarModelWhereInput>;
  carModelImageCarModel_some?: InputMaybe<CarModelWhereInput>;
  cardExpandCollapseIconPriceBreakdown_every?: InputMaybe<PriceBreakdownWhereInput>;
  cardExpandCollapseIconPriceBreakdown_none?: InputMaybe<PriceBreakdownWhereInput>;
  cardExpandCollapseIconPriceBreakdown_some?: InputMaybe<PriceBreakdownWhereInput>;
  classFeatureIconClass_every?: InputMaybe<ClassWhereInput>;
  classFeatureIconClass_none?: InputMaybe<ClassWhereInput>;
  classFeatureIconClass_some?: InputMaybe<ClassWhereInput>;
  classImageClass_every?: InputMaybe<ClassWhereInput>;
  classImageClass_none?: InputMaybe<ClassWhereInput>;
  classImageClass_some?: InputMaybe<ClassWhereInput>;
  closingScreeenCalendar_every?: InputMaybe<CalendarWhereInput>;
  closingScreeenCalendar_none?: InputMaybe<CalendarWhereInput>;
  closingScreeenCalendar_some?: InputMaybe<CalendarWhereInput>;
  contactButtonIconRental_every?: InputMaybe<RentalWhereInput>;
  contactButtonIconRental_none?: InputMaybe<RentalWhereInput>;
  contactButtonIconRental_some?: InputMaybe<RentalWhereInput>;
  coverageFeatureIconCoverage_every?: InputMaybe<CoverageWhereInput>;
  coverageFeatureIconCoverage_none?: InputMaybe<CoverageWhereInput>;
  coverageFeatureIconCoverage_some?: InputMaybe<CoverageWhereInput>;
  coverageTagCoverage_every?: InputMaybe<CoverageWhereInput>;
  coverageTagCoverage_none?: InputMaybe<CoverageWhereInput>;
  coverageTagCoverage_some?: InputMaybe<CoverageWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  deletePaymentMethodIconProfile_every?: InputMaybe<ProfileWhereInput>;
  deletePaymentMethodIconProfile_none?: InputMaybe<ProfileWhereInput>;
  deletePaymentMethodIconProfile_some?: InputMaybe<ProfileWhereInput>;
  editProfileIconProfile_every?: InputMaybe<ProfileWhereInput>;
  editProfileIconProfile_none?: InputMaybe<ProfileWhereInput>;
  editProfileIconProfile_some?: InputMaybe<ProfileWhereInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding_every?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding_none?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
  emergencyKeyOnboardingImageEmergencyKeyOnboarding_some?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
  errorMessageHeaderErrorMessage_every?: InputMaybe<ErrorMessageWhereInput>;
  errorMessageHeaderErrorMessage_none?: InputMaybe<ErrorMessageWhereInput>;
  errorMessageHeaderErrorMessage_some?: InputMaybe<ErrorMessageWhereInput>;
  extraDeselectIconExtra_every?: InputMaybe<ExtraWhereInput>;
  extraDeselectIconExtra_none?: InputMaybe<ExtraWhereInput>;
  extraDeselectIconExtra_some?: InputMaybe<ExtraWhereInput>;
  extrasIconExtra_every?: InputMaybe<ExtraWhereInput>;
  extrasIconExtra_none?: InputMaybe<ExtraWhereInput>;
  extrasIconExtra_some?: InputMaybe<ExtraWhereInput>;
  fileName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  fileName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars['String']>;
  goCurrentLocationGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationIconInactiveGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationIconInactiveGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goCurrentLocationIconInactiveGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goLocationImageGOLocation_every?: InputMaybe<GoLocationWhereInput>;
  goLocationImageGOLocation_none?: InputMaybe<GoLocationWhereInput>;
  goLocationImageGOLocation_some?: InputMaybe<GoLocationWhereInput>;
  goPickUpReturnIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goPickUpReturnIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goPickUpReturnIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goPickUpTimeIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goPickUpTimeIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goPickUpTimeIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goReturnDateIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goReturnDateIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goReturnDateIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchLocationInformationGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchLocationInformationGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchLocationInformationGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchPickUpIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchPickUpIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchPickUpIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchRentalPeriodCalendarGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchRentalPeriodCalendarGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchRentalPeriodCalendarGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  goSearchReturnIconGOSearch_every?: InputMaybe<GoSearchWhereInput>;
  goSearchReturnIconGOSearch_none?: InputMaybe<GoSearchWhereInput>;
  goSearchReturnIconGOSearch_some?: InputMaybe<GoSearchWhereInput>;
  googleMapsLinkIconGOLocation_every?: InputMaybe<GoLocationWhereInput>;
  googleMapsLinkIconGOLocation_none?: InputMaybe<GoLocationWhereInput>;
  googleMapsLinkIconGOLocation_some?: InputMaybe<GoLocationWhereInput>;
  hamburgerItemIconHamburgerMenuItem_every?: InputMaybe<HamburgerMenuItemWhereInput>;
  hamburgerItemIconHamburgerMenuItem_none?: InputMaybe<HamburgerMenuItemWhereInput>;
  hamburgerItemIconHamburgerMenuItem_some?: InputMaybe<HamburgerMenuItemWhereInput>;
  handle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  handle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  height_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<Scalars['Float']>>;
  heroImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  heroImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  heroImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksBackgroundImageMobileViewportsGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksCardImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksCardImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksCardImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  howItWorksSectionBackgroundImageDesktopGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  importantInformationIconKey_every?: InputMaybe<KeyWhereInput>;
  importantInformationIconKey_none?: InputMaybe<KeyWhereInput>;
  importantInformationIconKey_some?: InputMaybe<KeyWhereInput>;
  includedRentalFeaturesIconGoSelection_every?: InputMaybe<GoSelectionWhereInput>;
  includedRentalFeaturesIconGoSelection_none?: InputMaybe<GoSelectionWhereInput>;
  includedRentalFeaturesIconGoSelection_some?: InputMaybe<GoSelectionWhereInput>;
  languageIconGoWebappNavLink_every?: InputMaybe<GoWebappNavLinkWhereInput>;
  languageIconGoWebappNavLink_none?: InputMaybe<GoWebappNavLinkWhereInput>;
  languageIconGoWebappNavLink_some?: InputMaybe<GoWebappNavLinkWhereInput>;
  legalCheckboxGoSelection_every?: InputMaybe<GoSelectionWhereInput>;
  legalCheckboxGoSelection_none?: InputMaybe<GoSelectionWhereInput>;
  legalCheckboxGoSelection_some?: InputMaybe<GoSelectionWhereInput>;
  legalcheckboxProfile_every?: InputMaybe<ProfileWhereInput>;
  legalcheckboxProfile_none?: InputMaybe<ProfileWhereInput>;
  legalcheckboxProfile_some?: InputMaybe<ProfileWhereInput>;
  locationInformationIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  locationInformationIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  locationInformationIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  mediaImagesGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaImagesGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaImagesGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaLinkAssetsGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaLinkAssetsGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaLinkAssetsGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaSectionHeroImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaSectionHeroImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  mediaSectionHeroImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  mimeType_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars['String']>;
  noCarsImageGoBookingJourney_every?: InputMaybe<GoBookingJourneyWhereInput>;
  noCarsImageGoBookingJourney_none?: InputMaybe<GoBookingJourneyWhereInput>;
  noCarsImageGoBookingJourney_some?: InputMaybe<GoBookingJourneyWhereInput>;
  noCurrentRentalImageRental_every?: InputMaybe<RentalWhereInput>;
  noCurrentRentalImageRental_none?: InputMaybe<RentalWhereInput>;
  noCurrentRentalImageRental_some?: InputMaybe<RentalWhereInput>;
  noKeyImageKey_every?: InputMaybe<KeyWhereInput>;
  noKeyImageKey_none?: InputMaybe<KeyWhereInput>;
  noKeyImageKey_some?: InputMaybe<KeyWhereInput>;
  onboardingImageKeyOnboarding_every?: InputMaybe<KeyOnboardingWhereInput>;
  onboardingImageKeyOnboarding_none?: InputMaybe<KeyOnboardingWhereInput>;
  onboardingImageKeyOnboarding_some?: InputMaybe<KeyOnboardingWhereInput>;
  partnershipAssetsGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  partnershipAssetsGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  partnershipAssetsGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  paymentMethodIconGoSelection_every?: InputMaybe<GoSelectionWhereInput>;
  paymentMethodIconGoSelection_none?: InputMaybe<GoSelectionWhereInput>;
  paymentMethodIconGoSelection_some?: InputMaybe<GoSelectionWhereInput>;
  paymentMethodIconProfile_every?: InputMaybe<ProfileWhereInput>;
  paymentMethodIconProfile_none?: InputMaybe<ProfileWhereInput>;
  paymentMethodIconProfile_some?: InputMaybe<ProfileWhereInput>;
  pickUpLocationIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  pickUpLocationIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  pickUpLocationIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  popUpCardHeaderIconPopUpCard_every?: InputMaybe<PopUpCardWhereInput>;
  popUpCardHeaderIconPopUpCard_none?: InputMaybe<PopUpCardWhereInput>;
  popUpCardHeaderIconPopUpCard_some?: InputMaybe<PopUpCardWhereInput>;
  popUpCloseIconPopUpCard_every?: InputMaybe<PopUpCardWhereInput>;
  popUpCloseIconPopUpCard_none?: InputMaybe<PopUpCardWhereInput>;
  popUpCloseIconPopUpCard_some?: InputMaybe<PopUpCardWhereInput>;
  profileIconsProfile_every?: InputMaybe<ProfileWhereInput>;
  profileIconsProfile_none?: InputMaybe<ProfileWhereInput>;
  profileIconsProfile_some?: InputMaybe<ProfileWhereInput>;
  profileSectionIconProfile_every?: InputMaybe<ProfileWhereInput>;
  profileSectionIconProfile_none?: InputMaybe<ProfileWhereInput>;
  profileSectionIconProfile_some?: InputMaybe<ProfileWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentalLocationIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalLocationIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalLocationIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalPeriodIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalPeriodIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalPeriodIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalReturnIconGoWebappBookingOverview_every?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalReturnIconGoWebappBookingOverview_none?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  rentalReturnIconGoWebappBookingOverview_some?: InputMaybe<GoWebappBookingOverviewWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  size_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<Scalars['Float']>>;
  subClassFeatureIconSubClass_every?: InputMaybe<SubClassWhereInput>;
  subClassFeatureIconSubClass_none?: InputMaybe<SubClassWhereInput>;
  subClassFeatureIconSubClass_some?: InputMaybe<SubClassWhereInput>;
  subClassImageSubClass_every?: InputMaybe<SubClassWhereInput>;
  subClassImageSubClass_none?: InputMaybe<SubClassWhereInput>;
  subClassImageSubClass_some?: InputMaybe<SubClassWhereInput>;
  subclassFallbackFeaturesIconSubClass_every?: InputMaybe<SubClassWhereInput>;
  subclassFallbackFeaturesIconSubClass_none?: InputMaybe<SubClassWhereInput>;
  subclassFallbackFeaturesIconSubClass_some?: InputMaybe<SubClassWhereInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen_every?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen_none?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
  successfulPaymentPageImageSuccessfulPaymentScreen_some?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  valuePropositionImageGoWebappHomepage_every?: InputMaybe<GoWebappHomepageWhereInput>;
  valuePropositionImageGoWebappHomepage_none?: InputMaybe<GoWebappHomepageWhereInput>;
  valuePropositionImageGoWebappHomepage_some?: InputMaybe<GoWebappHomepageWhereInput>;
  verficationiconVerification_every?: InputMaybe<VerificationWhereInput>;
  verficationiconVerification_none?: InputMaybe<VerificationWhereInput>;
  verficationiconVerification_some?: InputMaybe<VerificationWhereInput>;
  verificationImageVerification_every?: InputMaybe<VerificationWhereInput>;
  verificationImageVerification_none?: InputMaybe<VerificationWhereInput>;
  verificationImageVerification_some?: InputMaybe<VerificationWhereInput>;
  verificationLinkIconProfile_every?: InputMaybe<ProfileWhereInput>;
  verificationLinkIconProfile_none?: InputMaybe<ProfileWhereInput>;
  verificationLinkIconProfile_some?: InputMaybe<ProfileWhereInput>;
  verificationPhotoUploadMethodIconsVerification_every?: InputMaybe<VerificationWhereInput>;
  verificationPhotoUploadMethodIconsVerification_none?: InputMaybe<VerificationWhereInput>;
  verificationPhotoUploadMethodIconsVerification_some?: InputMaybe<VerificationWhereInput>;
  verificationStatusIconProfile_every?: InputMaybe<ProfileWhereInput>;
  verificationStatusIconProfile_none?: InputMaybe<ProfileWhereInput>;
  verificationStatusIconProfile_some?: InputMaybe<ProfileWhereInput>;
  verificationSubtitleIconVerification_every?: InputMaybe<VerificationWhereInput>;
  verificationSubtitleIconVerification_none?: InputMaybe<VerificationWhereInput>;
  verificationSubtitleIconVerification_some?: InputMaybe<VerificationWhereInput>;
  width?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  width_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<Scalars['Float']>>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Banners are consistent messages, that are displayed on all pages. */
export type Banner = Node & {
  __typename?: 'Banner';
  /** This is the Icon that is used for the specific banner. */
  bannerIcon?: Maybe<Asset>;
  /** This is the banner title for internal uses only, to be used as an identifier */
  bannerIdentifierTitle?: Maybe<Scalars['String']>;
  /** This is a text link that links to another screen. */
  bannerLinkName?: Maybe<Scalars['String']>;
  /** This is the header in CAPS of the Banner. */
  bannerName?: Maybe<Scalars['String']>;
  /** This is the short description that describes the intent of the message. */
  bannerShortDescription?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Banner>;
  /** List of Banner versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Banner>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerBannerIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Banners are consistent messages, that are displayed on all pages. */
export type BannerUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type BannerConnection = {
  __typename?: 'BannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BannerCreateInput = {
  bannerIcon?: InputMaybe<AssetCreateOneInlineInput>;
  bannerIdentifierTitle?: InputMaybe<Scalars['String']>;
  /** bannerLinkName input for default locale (de_CH) */
  bannerLinkName?: InputMaybe<Scalars['String']>;
  /** bannerName input for default locale (de_CH) */
  bannerName?: InputMaybe<Scalars['String']>;
  /** bannerShortDescription input for default locale (de_CH) */
  bannerShortDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BannerCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerCreateLocalizationDataInput = {
  bannerLinkName?: InputMaybe<Scalars['String']>;
  bannerName?: InputMaybe<Scalars['String']>;
  bannerShortDescription?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerCreateLocalizationInput = {
  /** Localization input */
  data: BannerCreateLocalizationDataInput;
  locale: Locale;
};

export type BannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BannerCreateLocalizationInput>>;
};

export type BannerCreateManyInlineInput = {
  /** Connect multiple existing Banner documents */
  connect?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Create and connect multiple existing Banner documents */
  create?: InputMaybe<Array<BannerCreateInput>>;
};

export type BannerCreateOneInlineInput = {
  /** Connect one existing Banner document */
  connect?: InputMaybe<BannerWhereUniqueInput>;
  /** Create and connect one Banner document */
  create?: InputMaybe<BannerCreateInput>;
};

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: 'BannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Banner;
};

/** Identifies documents */
export type BannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bannerIcon?: InputMaybe<AssetWhereInput>;
  bannerIdentifierTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bannerIdentifierTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bannerIdentifierTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bannerIdentifierTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bannerIdentifierTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bannerIdentifierTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bannerIdentifierTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bannerIdentifierTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bannerIdentifierTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bannerIdentifierTitle_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BannerOrderByInput {
  BannerIdentifierTitleAsc = 'bannerIdentifierTitle_ASC',
  BannerIdentifierTitleDesc = 'bannerIdentifierTitle_DESC',
  BannerLinkNameAsc = 'bannerLinkName_ASC',
  BannerLinkNameDesc = 'bannerLinkName_DESC',
  BannerNameAsc = 'bannerName_ASC',
  BannerNameDesc = 'bannerName_DESC',
  BannerShortDescriptionAsc = 'bannerShortDescription_ASC',
  BannerShortDescriptionDesc = 'bannerShortDescription_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BannerUpdateInput = {
  bannerIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  bannerIdentifierTitle?: InputMaybe<Scalars['String']>;
  /** bannerLinkName input for default locale (de_CH) */
  bannerLinkName?: InputMaybe<Scalars['String']>;
  /** bannerName input for default locale (de_CH) */
  bannerName?: InputMaybe<Scalars['String']>;
  /** bannerShortDescription input for default locale (de_CH) */
  bannerShortDescription?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<BannerUpdateLocalizationsInput>;
};

export type BannerUpdateLocalizationDataInput = {
  bannerLinkName?: InputMaybe<Scalars['String']>;
  bannerName?: InputMaybe<Scalars['String']>;
  bannerShortDescription?: InputMaybe<Scalars['String']>;
};

export type BannerUpdateLocalizationInput = {
  data: BannerUpdateLocalizationDataInput;
  locale: Locale;
};

export type BannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BannerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BannerUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BannerUpsertLocalizationInput>>;
};

export type BannerUpdateManyInlineInput = {
  /** Connect multiple existing Banner documents */
  connect?: InputMaybe<Array<BannerConnectInput>>;
  /** Create and connect multiple Banner documents */
  create?: InputMaybe<Array<BannerCreateInput>>;
  /** Delete multiple Banner documents */
  delete?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Disconnect multiple Banner documents */
  disconnect?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Banner documents */
  set?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Update multiple Banner documents */
  update?: InputMaybe<Array<BannerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Banner documents */
  upsert?: InputMaybe<Array<BannerUpsertWithNestedWhereUniqueInput>>;
};

export type BannerUpdateManyInput = {
  /** bannerLinkName input for default locale (de_CH) */
  bannerLinkName?: InputMaybe<Scalars['String']>;
  /** bannerName input for default locale (de_CH) */
  bannerName?: InputMaybe<Scalars['String']>;
  /** bannerShortDescription input for default locale (de_CH) */
  bannerShortDescription?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BannerUpdateManyLocalizationsInput>;
};

export type BannerUpdateManyLocalizationDataInput = {
  bannerLinkName?: InputMaybe<Scalars['String']>;
  bannerName?: InputMaybe<Scalars['String']>;
  bannerShortDescription?: InputMaybe<Scalars['String']>;
};

export type BannerUpdateManyLocalizationInput = {
  data: BannerUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BannerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BannerUpdateManyLocalizationInput>>;
};

export type BannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BannerUpdateManyInput;
  /** Document search */
  where: BannerWhereInput;
};

export type BannerUpdateOneInlineInput = {
  /** Connect existing Banner document */
  connect?: InputMaybe<BannerWhereUniqueInput>;
  /** Create and connect one Banner document */
  create?: InputMaybe<BannerCreateInput>;
  /** Delete currently connected Banner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Banner document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Banner document */
  update?: InputMaybe<BannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Banner document */
  upsert?: InputMaybe<BannerUpsertWithNestedWhereUniqueInput>;
};

export type BannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BannerUpdateInput;
  /** Unique document search */
  where: BannerWhereUniqueInput;
};

export type BannerUpsertInput = {
  /** Create document if it didn't exist */
  create: BannerCreateInput;
  /** Update document if it exists */
  update: BannerUpdateInput;
};

export type BannerUpsertLocalizationInput = {
  create: BannerCreateLocalizationDataInput;
  locale: Locale;
  update: BannerUpdateLocalizationDataInput;
};

export type BannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BannerUpsertInput;
  /** Unique document search */
  where: BannerWhereUniqueInput;
};

/** Identifies documents */
export type BannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bannerIcon?: InputMaybe<AssetWhereInput>;
  bannerIdentifierTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bannerIdentifierTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bannerIdentifierTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bannerIdentifierTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bannerIdentifierTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bannerIdentifierTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bannerIdentifierTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bannerIdentifierTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bannerIdentifierTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bannerIdentifierTitle_starts_with?: InputMaybe<Scalars['String']>;
  bannerLinkName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bannerLinkName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bannerLinkName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bannerLinkName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bannerLinkName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bannerLinkName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bannerLinkName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bannerLinkName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bannerLinkName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bannerLinkName_starts_with?: InputMaybe<Scalars['String']>;
  bannerName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bannerName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bannerName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bannerName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bannerName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bannerName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bannerName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bannerName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bannerName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bannerName_starts_with?: InputMaybe<Scalars['String']>;
  bannerShortDescription?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bannerShortDescription_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bannerShortDescription_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bannerShortDescription_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bannerShortDescription_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bannerShortDescription_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bannerShortDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bannerShortDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bannerShortDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bannerShortDescription_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Banner record uniquely */
export type BannerWhereUniqueInput = {
  bannerIdentifierTitle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'];
};

/** This workflow defines the subsequent screens to show. */
export enum BookingWorkflow {
  BookLater = 'BookLater',
  BookNow = 'BookNow'
}

export type BottomNavigationItem = Node & {
  __typename?: 'BottomNavigationItem';
  /** This is the name of the bottom navigation item (eg. rentals, home, chat). This will be localized across languages. */
  bottomNavigationItem?: Maybe<Scalars['String']>;
  /** This is the icon that is displayed for each bottom navigation item if the app. Active means that icon is selected. */
  bottomNavigationItemIconActive?: Maybe<Asset>;
  /** This is the icon that is displayed for each bottom navigation item if the app Inactive means that icon is not selected. */
  bottomNavigationItemIconInactive?: Maybe<Asset>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<BottomNavigationItem>;
  /** List of BottomNavigationItem versions */
  history: Array<Version>;
  /** This is the order in which the bottom navigations items should be ordered from left to right */
  homeScreenItemOrder?: Maybe<Scalars['Int']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BottomNavigationItem>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type BottomNavigationItemBottomNavigationItemIconActiveArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


export type BottomNavigationItemBottomNavigationItemIconInactiveArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


export type BottomNavigationItemCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BottomNavigationItemCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


export type BottomNavigationItemDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


export type BottomNavigationItemHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


export type BottomNavigationItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


export type BottomNavigationItemPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BottomNavigationItemPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


export type BottomNavigationItemScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type BottomNavigationItemUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BottomNavigationItemUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type BottomNavigationItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BottomNavigationItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type BottomNavigationItemConnection = {
  __typename?: 'BottomNavigationItemConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BottomNavigationItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BottomNavigationItemCreateInput = {
  /** bottomNavigationItem input for default locale (de_CH) */
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
  bottomNavigationItemIconActive?: InputMaybe<AssetCreateOneInlineInput>;
  bottomNavigationItemIconInactive?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  homeScreenItemOrder?: InputMaybe<Scalars['Int']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BottomNavigationItemCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BottomNavigationItemCreateLocalizationDataInput = {
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BottomNavigationItemCreateLocalizationInput = {
  /** Localization input */
  data: BottomNavigationItemCreateLocalizationDataInput;
  locale: Locale;
};

export type BottomNavigationItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BottomNavigationItemCreateLocalizationInput>>;
};

export type BottomNavigationItemCreateManyInlineInput = {
  /** Connect multiple existing BottomNavigationItem documents */
  connect?: InputMaybe<Array<BottomNavigationItemWhereUniqueInput>>;
  /** Create and connect multiple existing BottomNavigationItem documents */
  create?: InputMaybe<Array<BottomNavigationItemCreateInput>>;
};

export type BottomNavigationItemCreateOneInlineInput = {
  /** Connect one existing BottomNavigationItem document */
  connect?: InputMaybe<BottomNavigationItemWhereUniqueInput>;
  /** Create and connect one BottomNavigationItem document */
  create?: InputMaybe<BottomNavigationItemCreateInput>;
};

/** An edge in a connection. */
export type BottomNavigationItemEdge = {
  __typename?: 'BottomNavigationItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BottomNavigationItem;
};

/** Identifies documents */
export type BottomNavigationItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BottomNavigationItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BottomNavigationItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BottomNavigationItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bottomNavigationItemIconActive?: InputMaybe<AssetWhereInput>;
  bottomNavigationItemIconInactive?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  homeScreenItemOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  homeScreenItemOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  homeScreenItemOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  homeScreenItemOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  homeScreenItemOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  homeScreenItemOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  homeScreenItemOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  homeScreenItemOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BottomNavigationItemOrderByInput {
  BottomNavigationItemAsc = 'bottomNavigationItem_ASC',
  BottomNavigationItemDesc = 'bottomNavigationItem_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HomeScreenItemOrderAsc = 'homeScreenItemOrder_ASC',
  HomeScreenItemOrderDesc = 'homeScreenItemOrder_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BottomNavigationItemUpdateInput = {
  /** bottomNavigationItem input for default locale (de_CH) */
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
  bottomNavigationItemIconActive?: InputMaybe<AssetUpdateOneInlineInput>;
  bottomNavigationItemIconInactive?: InputMaybe<AssetUpdateOneInlineInput>;
  homeScreenItemOrder?: InputMaybe<Scalars['Int']>;
  /** Manage document localizations */
  localizations?: InputMaybe<BottomNavigationItemUpdateLocalizationsInput>;
};

export type BottomNavigationItemUpdateLocalizationDataInput = {
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
};

export type BottomNavigationItemUpdateLocalizationInput = {
  data: BottomNavigationItemUpdateLocalizationDataInput;
  locale: Locale;
};

export type BottomNavigationItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BottomNavigationItemCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BottomNavigationItemUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BottomNavigationItemUpsertLocalizationInput>>;
};

export type BottomNavigationItemUpdateManyInlineInput = {
  /** Connect multiple existing BottomNavigationItem documents */
  connect?: InputMaybe<Array<BottomNavigationItemConnectInput>>;
  /** Create and connect multiple BottomNavigationItem documents */
  create?: InputMaybe<Array<BottomNavigationItemCreateInput>>;
  /** Delete multiple BottomNavigationItem documents */
  delete?: InputMaybe<Array<BottomNavigationItemWhereUniqueInput>>;
  /** Disconnect multiple BottomNavigationItem documents */
  disconnect?: InputMaybe<Array<BottomNavigationItemWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BottomNavigationItem documents */
  set?: InputMaybe<Array<BottomNavigationItemWhereUniqueInput>>;
  /** Update multiple BottomNavigationItem documents */
  update?: InputMaybe<Array<BottomNavigationItemUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BottomNavigationItem documents */
  upsert?: InputMaybe<Array<BottomNavigationItemUpsertWithNestedWhereUniqueInput>>;
};

export type BottomNavigationItemUpdateManyInput = {
  /** bottomNavigationItem input for default locale (de_CH) */
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
  homeScreenItemOrder?: InputMaybe<Scalars['Int']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BottomNavigationItemUpdateManyLocalizationsInput>;
};

export type BottomNavigationItemUpdateManyLocalizationDataInput = {
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
};

export type BottomNavigationItemUpdateManyLocalizationInput = {
  data: BottomNavigationItemUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BottomNavigationItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BottomNavigationItemUpdateManyLocalizationInput>>;
};

export type BottomNavigationItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BottomNavigationItemUpdateManyInput;
  /** Document search */
  where: BottomNavigationItemWhereInput;
};

export type BottomNavigationItemUpdateOneInlineInput = {
  /** Connect existing BottomNavigationItem document */
  connect?: InputMaybe<BottomNavigationItemWhereUniqueInput>;
  /** Create and connect one BottomNavigationItem document */
  create?: InputMaybe<BottomNavigationItemCreateInput>;
  /** Delete currently connected BottomNavigationItem document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected BottomNavigationItem document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single BottomNavigationItem document */
  update?: InputMaybe<BottomNavigationItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BottomNavigationItem document */
  upsert?: InputMaybe<BottomNavigationItemUpsertWithNestedWhereUniqueInput>;
};

export type BottomNavigationItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BottomNavigationItemUpdateInput;
  /** Unique document search */
  where: BottomNavigationItemWhereUniqueInput;
};

export type BottomNavigationItemUpsertInput = {
  /** Create document if it didn't exist */
  create: BottomNavigationItemCreateInput;
  /** Update document if it exists */
  update: BottomNavigationItemUpdateInput;
};

export type BottomNavigationItemUpsertLocalizationInput = {
  create: BottomNavigationItemCreateLocalizationDataInput;
  locale: Locale;
  update: BottomNavigationItemUpdateLocalizationDataInput;
};

export type BottomNavigationItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BottomNavigationItemUpsertInput;
  /** Unique document search */
  where: BottomNavigationItemWhereUniqueInput;
};

/** Identifies documents */
export type BottomNavigationItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BottomNavigationItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BottomNavigationItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BottomNavigationItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bottomNavigationItem?: InputMaybe<Scalars['String']>;
  bottomNavigationItemIconActive?: InputMaybe<AssetWhereInput>;
  bottomNavigationItemIconInactive?: InputMaybe<AssetWhereInput>;
  /** All values containing the given string. */
  bottomNavigationItem_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bottomNavigationItem_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bottomNavigationItem_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bottomNavigationItem_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bottomNavigationItem_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bottomNavigationItem_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bottomNavigationItem_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bottomNavigationItem_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bottomNavigationItem_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  homeScreenItemOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  homeScreenItemOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  homeScreenItemOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  homeScreenItemOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  homeScreenItemOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  homeScreenItemOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  homeScreenItemOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  homeScreenItemOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References BottomNavigationItem record uniquely */
export type BottomNavigationItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the calendar schema! */
export type Calendar = Node & {
  __typename?: 'Calendar';
  /** This is the continue button text at the button of the calendar */
  calendarCtaButton?: Maybe<Scalars['String']>;
  /** Title of the screen above in the middle */
  calendarName?: Maybe<Scalars['String']>;
  /** This is the X on the top right. */
  closingScreeen?: Maybe<Asset>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** This is the tomorrow text that is localized across languages to show the pick up date is tomorrow */
  dateTomorrow?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<Calendar>;
  /** List of Calendar versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the 'Input' placeholder name. */
  inputFieldPickUpName?: Maybe<Scalars['String']>;
  /** This is the 'Return' placeholder in the field */
  inputFieldReturnName?: Maybe<Scalars['String']>;
  /** This is the short name of the day (Mon, Tue,) shown in the input field of every weekday. */
  inputFieldWeekdayShort: Array<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Calendar>;
  /** Every month in the year */
  month: Array<Scalars['String']>;
  /**
   * This is the three letter abbreviation of each month
   *
   * eg. October = Oct
   */
  monthAbbreviation: Array<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Header of the selectable return time. */
  returnName?: Maybe<Scalars['String']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** Header of the selectable pick up time. */
  timeName?: Maybe<Scalars['String']>;
  /** The 'now' in the time. */
  timeNow?: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** This is the abbreviation of every weekday (first letter of the name) */
  weekdaysAbbreviation: Array<Scalars['String']>;
};


/** This is the calendar schema! */
export type CalendarClosingScreeenArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the calendar schema! */
export type CalendarCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the calendar schema! */
export type CalendarCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the calendar schema! */
export type CalendarDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the calendar schema! */
export type CalendarHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the calendar schema! */
export type CalendarLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the calendar schema! */
export type CalendarPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the calendar schema! */
export type CalendarPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the calendar schema! */
export type CalendarScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the calendar schema! */
export type CalendarUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the calendar schema! */
export type CalendarUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type CalendarConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CalendarWhereUniqueInput;
};

/** A connection to a list of items. */
export type CalendarConnection = {
  __typename?: 'CalendarConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CalendarEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CalendarCreateInput = {
  /** calendarCtaButton input for default locale (de_CH) */
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  /** calendarName input for default locale (de_CH) */
  calendarName?: InputMaybe<Scalars['String']>;
  closingScreeen?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** dateTomorrow input for default locale (de_CH) */
  dateTomorrow?: InputMaybe<Scalars['String']>;
  /** inputFieldPickUpName input for default locale (de_CH) */
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  /** inputFieldReturnName input for default locale (de_CH) */
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  /** inputFieldWeekdayShort input for default locale (de_CH) */
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CalendarCreateLocalizationsInput>;
  /** month input for default locale (de_CH) */
  month?: InputMaybe<Array<Scalars['String']>>;
  /** monthAbbreviation input for default locale (de_CH) */
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  /** returnName input for default locale (de_CH) */
  returnName?: InputMaybe<Scalars['String']>;
  /** timeName input for default locale (de_CH) */
  timeName?: InputMaybe<Scalars['String']>;
  /** timeNow input for default locale (de_CH) */
  timeNow?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** weekdaysAbbreviation input for default locale (de_CH) */
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
};

export type CalendarCreateLocalizationDataInput = {
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  calendarName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateTomorrow?: InputMaybe<Scalars['String']>;
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  month?: InputMaybe<Array<Scalars['String']>>;
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  returnName?: InputMaybe<Scalars['String']>;
  timeName?: InputMaybe<Scalars['String']>;
  timeNow?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
};

export type CalendarCreateLocalizationInput = {
  /** Localization input */
  data: CalendarCreateLocalizationDataInput;
  locale: Locale;
};

export type CalendarCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CalendarCreateLocalizationInput>>;
};

export type CalendarCreateManyInlineInput = {
  /** Connect multiple existing Calendar documents */
  connect?: InputMaybe<Array<CalendarWhereUniqueInput>>;
  /** Create and connect multiple existing Calendar documents */
  create?: InputMaybe<Array<CalendarCreateInput>>;
};

export type CalendarCreateOneInlineInput = {
  /** Connect one existing Calendar document */
  connect?: InputMaybe<CalendarWhereUniqueInput>;
  /** Create and connect one Calendar document */
  create?: InputMaybe<CalendarCreateInput>;
};

/** An edge in a connection. */
export type CalendarEdge = {
  __typename?: 'CalendarEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Calendar;
};

/** Identifies documents */
export type CalendarManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CalendarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CalendarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CalendarWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  closingScreeen?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CalendarOrderByInput {
  CalendarCtaButtonAsc = 'calendarCtaButton_ASC',
  CalendarCtaButtonDesc = 'calendarCtaButton_DESC',
  CalendarNameAsc = 'calendarName_ASC',
  CalendarNameDesc = 'calendarName_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DateTomorrowAsc = 'dateTomorrow_ASC',
  DateTomorrowDesc = 'dateTomorrow_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputFieldPickUpNameAsc = 'inputFieldPickUpName_ASC',
  InputFieldPickUpNameDesc = 'inputFieldPickUpName_DESC',
  InputFieldReturnNameAsc = 'inputFieldReturnName_ASC',
  InputFieldReturnNameDesc = 'inputFieldReturnName_DESC',
  InputFieldWeekdayShortAsc = 'inputFieldWeekdayShort_ASC',
  InputFieldWeekdayShortDesc = 'inputFieldWeekdayShort_DESC',
  MonthAbbreviationAsc = 'monthAbbreviation_ASC',
  MonthAbbreviationDesc = 'monthAbbreviation_DESC',
  MonthAsc = 'month_ASC',
  MonthDesc = 'month_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReturnNameAsc = 'returnName_ASC',
  ReturnNameDesc = 'returnName_DESC',
  TimeNameAsc = 'timeName_ASC',
  TimeNameDesc = 'timeName_DESC',
  TimeNowAsc = 'timeNow_ASC',
  TimeNowDesc = 'timeNow_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WeekdaysAbbreviationAsc = 'weekdaysAbbreviation_ASC',
  WeekdaysAbbreviationDesc = 'weekdaysAbbreviation_DESC'
}

export type CalendarUpdateInput = {
  /** calendarCtaButton input for default locale (de_CH) */
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  /** calendarName input for default locale (de_CH) */
  calendarName?: InputMaybe<Scalars['String']>;
  closingScreeen?: InputMaybe<AssetUpdateOneInlineInput>;
  /** dateTomorrow input for default locale (de_CH) */
  dateTomorrow?: InputMaybe<Scalars['String']>;
  /** inputFieldPickUpName input for default locale (de_CH) */
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  /** inputFieldReturnName input for default locale (de_CH) */
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  /** inputFieldWeekdayShort input for default locale (de_CH) */
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  /** Manage document localizations */
  localizations?: InputMaybe<CalendarUpdateLocalizationsInput>;
  /** month input for default locale (de_CH) */
  month?: InputMaybe<Array<Scalars['String']>>;
  /** monthAbbreviation input for default locale (de_CH) */
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  /** returnName input for default locale (de_CH) */
  returnName?: InputMaybe<Scalars['String']>;
  /** timeName input for default locale (de_CH) */
  timeName?: InputMaybe<Scalars['String']>;
  /** timeNow input for default locale (de_CH) */
  timeNow?: InputMaybe<Scalars['String']>;
  /** weekdaysAbbreviation input for default locale (de_CH) */
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
};

export type CalendarUpdateLocalizationDataInput = {
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  calendarName?: InputMaybe<Scalars['String']>;
  dateTomorrow?: InputMaybe<Scalars['String']>;
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  month?: InputMaybe<Array<Scalars['String']>>;
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  returnName?: InputMaybe<Scalars['String']>;
  timeName?: InputMaybe<Scalars['String']>;
  timeNow?: InputMaybe<Scalars['String']>;
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
};

export type CalendarUpdateLocalizationInput = {
  data: CalendarUpdateLocalizationDataInput;
  locale: Locale;
};

export type CalendarUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CalendarCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CalendarUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CalendarUpsertLocalizationInput>>;
};

export type CalendarUpdateManyInlineInput = {
  /** Connect multiple existing Calendar documents */
  connect?: InputMaybe<Array<CalendarConnectInput>>;
  /** Create and connect multiple Calendar documents */
  create?: InputMaybe<Array<CalendarCreateInput>>;
  /** Delete multiple Calendar documents */
  delete?: InputMaybe<Array<CalendarWhereUniqueInput>>;
  /** Disconnect multiple Calendar documents */
  disconnect?: InputMaybe<Array<CalendarWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Calendar documents */
  set?: InputMaybe<Array<CalendarWhereUniqueInput>>;
  /** Update multiple Calendar documents */
  update?: InputMaybe<Array<CalendarUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Calendar documents */
  upsert?: InputMaybe<Array<CalendarUpsertWithNestedWhereUniqueInput>>;
};

export type CalendarUpdateManyInput = {
  /** calendarCtaButton input for default locale (de_CH) */
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  /** calendarName input for default locale (de_CH) */
  calendarName?: InputMaybe<Scalars['String']>;
  /** dateTomorrow input for default locale (de_CH) */
  dateTomorrow?: InputMaybe<Scalars['String']>;
  /** inputFieldPickUpName input for default locale (de_CH) */
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  /** inputFieldReturnName input for default locale (de_CH) */
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  /** inputFieldWeekdayShort input for default locale (de_CH) */
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CalendarUpdateManyLocalizationsInput>;
  /** month input for default locale (de_CH) */
  month?: InputMaybe<Array<Scalars['String']>>;
  /** monthAbbreviation input for default locale (de_CH) */
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  /** returnName input for default locale (de_CH) */
  returnName?: InputMaybe<Scalars['String']>;
  /** timeName input for default locale (de_CH) */
  timeName?: InputMaybe<Scalars['String']>;
  /** timeNow input for default locale (de_CH) */
  timeNow?: InputMaybe<Scalars['String']>;
  /** weekdaysAbbreviation input for default locale (de_CH) */
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
};

export type CalendarUpdateManyLocalizationDataInput = {
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  calendarName?: InputMaybe<Scalars['String']>;
  dateTomorrow?: InputMaybe<Scalars['String']>;
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  month?: InputMaybe<Array<Scalars['String']>>;
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  returnName?: InputMaybe<Scalars['String']>;
  timeName?: InputMaybe<Scalars['String']>;
  timeNow?: InputMaybe<Scalars['String']>;
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
};

export type CalendarUpdateManyLocalizationInput = {
  data: CalendarUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CalendarUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CalendarUpdateManyLocalizationInput>>;
};

export type CalendarUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CalendarUpdateManyInput;
  /** Document search */
  where: CalendarWhereInput;
};

export type CalendarUpdateOneInlineInput = {
  /** Connect existing Calendar document */
  connect?: InputMaybe<CalendarWhereUniqueInput>;
  /** Create and connect one Calendar document */
  create?: InputMaybe<CalendarCreateInput>;
  /** Delete currently connected Calendar document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Calendar document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Calendar document */
  update?: InputMaybe<CalendarUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Calendar document */
  upsert?: InputMaybe<CalendarUpsertWithNestedWhereUniqueInput>;
};

export type CalendarUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CalendarUpdateInput;
  /** Unique document search */
  where: CalendarWhereUniqueInput;
};

export type CalendarUpsertInput = {
  /** Create document if it didn't exist */
  create: CalendarCreateInput;
  /** Update document if it exists */
  update: CalendarUpdateInput;
};

export type CalendarUpsertLocalizationInput = {
  create: CalendarCreateLocalizationDataInput;
  locale: Locale;
  update: CalendarUpdateLocalizationDataInput;
};

export type CalendarUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CalendarUpsertInput;
  /** Unique document search */
  where: CalendarWhereUniqueInput;
};

/** Identifies documents */
export type CalendarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CalendarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CalendarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CalendarWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  calendarCtaButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  calendarCtaButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  calendarCtaButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  calendarCtaButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  calendarCtaButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  calendarCtaButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  calendarCtaButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  calendarCtaButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  calendarCtaButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  calendarCtaButton_starts_with?: InputMaybe<Scalars['String']>;
  calendarName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  calendarName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  calendarName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  calendarName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  calendarName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  calendarName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  calendarName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  calendarName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  calendarName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  calendarName_starts_with?: InputMaybe<Scalars['String']>;
  closingScreeen?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dateTomorrow?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  dateTomorrow_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  dateTomorrow_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  dateTomorrow_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  dateTomorrow_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  dateTomorrow_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  dateTomorrow_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  dateTomorrow_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  dateTomorrow_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  dateTomorrow_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inputFieldPickUpName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  inputFieldPickUpName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  inputFieldPickUpName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  inputFieldPickUpName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  inputFieldPickUpName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  inputFieldPickUpName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  inputFieldPickUpName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  inputFieldPickUpName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  inputFieldPickUpName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  inputFieldPickUpName_starts_with?: InputMaybe<Scalars['String']>;
  inputFieldReturnName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  inputFieldReturnName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  inputFieldReturnName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  inputFieldReturnName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  inputFieldReturnName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  inputFieldReturnName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  inputFieldReturnName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  inputFieldReturnName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  inputFieldReturnName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  inputFieldReturnName_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  inputFieldWeekdayShort?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  inputFieldWeekdayShort_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  inputFieldWeekdayShort_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  inputFieldWeekdayShort_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  inputFieldWeekdayShort_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  month?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  monthAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  monthAbbreviation_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  monthAbbreviation_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  monthAbbreviation_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  monthAbbreviation_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  month_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  month_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  month_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  month_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  returnName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  returnName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  returnName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  returnName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  returnName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  returnName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  returnName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  returnName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  returnName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  returnName_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  timeName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  timeName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  timeName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  timeName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  timeName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  timeName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  timeName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  timeName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  timeName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  timeName_starts_with?: InputMaybe<Scalars['String']>;
  timeNow?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  timeNow_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  timeNow_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  timeNow_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  timeNow_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  timeNow_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  timeNow_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  timeNow_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  timeNow_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  timeNow_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  weekdaysAbbreviation?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  weekdaysAbbreviation_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  weekdaysAbbreviation_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  weekdaysAbbreviation_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  weekdaysAbbreviation_not?: InputMaybe<Array<Scalars['String']>>;
};

/** References Calendar record uniquely */
export type CalendarWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This model includes all car models that are or have been part of the fleet. */
export type CarModel = Node & {
  __typename?: 'CarModel';
  /** Each Car Model Feature can be referenced to multiple Car Models. */
  carModelFeatures: Array<CarModelFeature>;
  /** There is only this car model image per record. It's a transparent car image facing to the left. */
  carModelImage?: Maybe<Asset>;
  /** This car model name represents the name of the car model. It contains the brand and model without any further details (e.g. liters, fuel type, etc) */
  carModelName?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<CarModel>;
  /** List of CarModel versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CarModel>;
  /**
   *
   *
   */
  locationTab?: Maybe<GoBookingJourney>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** Each Car Model is referenced to only one Sub Class. There cannot be any overlap! */
  subClass?: Maybe<SubClass>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** List of VINs matched to Car Models. These VINs are written to the CMS database when infleeting a new unit into RENT. DO NOT TOUCH THIS!! */
  vinMatch: Array<Vin>;
  /** This is the header 'Why drive a ...' + Car Model, for example: 'Why drive a VW Polo'? */
  whyDriveThisCarModelName?: Maybe<Scalars['String']>;
  /** This is the short marketing description, why this car is great! Min 100 characters, max 225 characters allowed. */
  whyDriveThisCarModelText?: Maybe<Scalars['String']>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelCarModelFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelFeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelFeatureWhereInput>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelCarModelImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelLocationTabArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelSubClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model includes all car models that are or have been part of the fleet. */
export type CarModelVinMatchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VinOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VinWhereInput>;
};

export type CarModelConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CarModelWhereUniqueInput;
};

/** A connection to a list of items. */
export type CarModelConnection = {
  __typename?: 'CarModelConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CarModelEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CarModelCreateInput = {
  carModelFeatures?: InputMaybe<CarModelFeatureCreateManyInlineInput>;
  carModelImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** carModelName input for default locale (de_CH) */
  carModelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CarModelCreateLocalizationsInput>;
  locationTab?: InputMaybe<GoBookingJourneyCreateOneInlineInput>;
  subClass?: InputMaybe<SubClassCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vinMatch?: InputMaybe<VinCreateManyInlineInput>;
  /** whyDriveThisCarModelName input for default locale (de_CH) */
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  /** whyDriveThisCarModelText input for default locale (de_CH) */
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
};

export type CarModelCreateLocalizationDataInput = {
  carModelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
};

export type CarModelCreateLocalizationInput = {
  /** Localization input */
  data: CarModelCreateLocalizationDataInput;
  locale: Locale;
};

export type CarModelCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CarModelCreateLocalizationInput>>;
};

export type CarModelCreateManyInlineInput = {
  /** Connect multiple existing CarModel documents */
  connect?: InputMaybe<Array<CarModelWhereUniqueInput>>;
  /** Create and connect multiple existing CarModel documents */
  create?: InputMaybe<Array<CarModelCreateInput>>;
};

export type CarModelCreateOneInlineInput = {
  /** Connect one existing CarModel document */
  connect?: InputMaybe<CarModelWhereUniqueInput>;
  /** Create and connect one CarModel document */
  create?: InputMaybe<CarModelCreateInput>;
};

/** An edge in a connection. */
export type CarModelEdge = {
  __typename?: 'CarModelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CarModel;
};

/** These are the features that are referenced to the different Car Models. */
export type CarModelFeature = Node & {
  __typename?: 'CarModelFeature';
  /** This is the icon that corresponds to the feature. */
  carModelFeatureIcon?: Maybe<Asset>;
  /** This is the name of the Car Model Feature. */
  carModelFeatureName?: Maybe<Scalars['String']>;
  /** This is the order of appearance by importance in both eService and GO App. */
  carModelFeatureOrder?: Maybe<Scalars['Int']>;
  /** Each Car Model can be referenced to multiple Car Model Features */
  carModels: Array<CarModel>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<CarModelFeature>;
  /** List of CarModelFeature versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CarModelFeature>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  vinCarModelMatch?: Maybe<Vin>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureCarModelFeatureIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureCarModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelWhereInput>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeaturePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeaturePublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the features that are referenced to the different Car Models. */
export type CarModelFeatureVinCarModelMatchArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type CarModelFeatureConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CarModelFeatureWhereUniqueInput;
};

/** A connection to a list of items. */
export type CarModelFeatureConnection = {
  __typename?: 'CarModelFeatureConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CarModelFeatureEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CarModelFeatureCreateInput = {
  carModelFeatureIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** carModelFeatureName input for default locale (de_CH) */
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  /** carModelFeatureOrder input for default locale (de_CH) */
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
  carModels?: InputMaybe<CarModelCreateManyInlineInput>;
  ckzzprrht8agb01z8g6h7hc4r?: InputMaybe<SubClassCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CarModelFeatureCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vinCarModelMatch?: InputMaybe<VinCreateOneInlineInput>;
};

export type CarModelFeatureCreateLocalizationDataInput = {
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CarModelFeatureCreateLocalizationInput = {
  /** Localization input */
  data: CarModelFeatureCreateLocalizationDataInput;
  locale: Locale;
};

export type CarModelFeatureCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CarModelFeatureCreateLocalizationInput>>;
};

export type CarModelFeatureCreateManyInlineInput = {
  /** Connect multiple existing CarModelFeature documents */
  connect?: InputMaybe<Array<CarModelFeatureWhereUniqueInput>>;
  /** Create and connect multiple existing CarModelFeature documents */
  create?: InputMaybe<Array<CarModelFeatureCreateInput>>;
};

export type CarModelFeatureCreateOneInlineInput = {
  /** Connect one existing CarModelFeature document */
  connect?: InputMaybe<CarModelFeatureWhereUniqueInput>;
  /** Create and connect one CarModelFeature document */
  create?: InputMaybe<CarModelFeatureCreateInput>;
};

/** An edge in a connection. */
export type CarModelFeatureEdge = {
  __typename?: 'CarModelFeatureEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CarModelFeature;
};

/** Identifies documents */
export type CarModelFeatureManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CarModelFeatureWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CarModelFeatureWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CarModelFeatureWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModelFeatureIcon?: InputMaybe<AssetWhereInput>;
  carModels_every?: InputMaybe<CarModelWhereInput>;
  carModels_none?: InputMaybe<CarModelWhereInput>;
  carModels_some?: InputMaybe<CarModelWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vinCarModelMatch?: InputMaybe<VinWhereInput>;
};

export enum CarModelFeatureOrderByInput {
  CarModelFeatureNameAsc = 'carModelFeatureName_ASC',
  CarModelFeatureNameDesc = 'carModelFeatureName_DESC',
  CarModelFeatureOrderAsc = 'carModelFeatureOrder_ASC',
  CarModelFeatureOrderDesc = 'carModelFeatureOrder_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CarModelFeatureUpdateInput = {
  carModelFeatureIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** carModelFeatureName input for default locale (de_CH) */
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  /** carModelFeatureOrder input for default locale (de_CH) */
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
  carModels?: InputMaybe<CarModelUpdateManyInlineInput>;
  ckzzprrht8agb01z8g6h7hc4r?: InputMaybe<SubClassUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CarModelFeatureUpdateLocalizationsInput>;
  vinCarModelMatch?: InputMaybe<VinUpdateOneInlineInput>;
};

export type CarModelFeatureUpdateLocalizationDataInput = {
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
};

export type CarModelFeatureUpdateLocalizationInput = {
  data: CarModelFeatureUpdateLocalizationDataInput;
  locale: Locale;
};

export type CarModelFeatureUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CarModelFeatureCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CarModelFeatureUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CarModelFeatureUpsertLocalizationInput>>;
};

export type CarModelFeatureUpdateManyInlineInput = {
  /** Connect multiple existing CarModelFeature documents */
  connect?: InputMaybe<Array<CarModelFeatureConnectInput>>;
  /** Create and connect multiple CarModelFeature documents */
  create?: InputMaybe<Array<CarModelFeatureCreateInput>>;
  /** Delete multiple CarModelFeature documents */
  delete?: InputMaybe<Array<CarModelFeatureWhereUniqueInput>>;
  /** Disconnect multiple CarModelFeature documents */
  disconnect?: InputMaybe<Array<CarModelFeatureWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CarModelFeature documents */
  set?: InputMaybe<Array<CarModelFeatureWhereUniqueInput>>;
  /** Update multiple CarModelFeature documents */
  update?: InputMaybe<Array<CarModelFeatureUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CarModelFeature documents */
  upsert?: InputMaybe<Array<CarModelFeatureUpsertWithNestedWhereUniqueInput>>;
};

export type CarModelFeatureUpdateManyInput = {
  /** carModelFeatureName input for default locale (de_CH) */
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  /** carModelFeatureOrder input for default locale (de_CH) */
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CarModelFeatureUpdateManyLocalizationsInput>;
};

export type CarModelFeatureUpdateManyLocalizationDataInput = {
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
};

export type CarModelFeatureUpdateManyLocalizationInput = {
  data: CarModelFeatureUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CarModelFeatureUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CarModelFeatureUpdateManyLocalizationInput>>;
};

export type CarModelFeatureUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CarModelFeatureUpdateManyInput;
  /** Document search */
  where: CarModelFeatureWhereInput;
};

export type CarModelFeatureUpdateOneInlineInput = {
  /** Connect existing CarModelFeature document */
  connect?: InputMaybe<CarModelFeatureWhereUniqueInput>;
  /** Create and connect one CarModelFeature document */
  create?: InputMaybe<CarModelFeatureCreateInput>;
  /** Delete currently connected CarModelFeature document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CarModelFeature document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CarModelFeature document */
  update?: InputMaybe<CarModelFeatureUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CarModelFeature document */
  upsert?: InputMaybe<CarModelFeatureUpsertWithNestedWhereUniqueInput>;
};

export type CarModelFeatureUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CarModelFeatureUpdateInput;
  /** Unique document search */
  where: CarModelFeatureWhereUniqueInput;
};

export type CarModelFeatureUpsertInput = {
  /** Create document if it didn't exist */
  create: CarModelFeatureCreateInput;
  /** Update document if it exists */
  update: CarModelFeatureUpdateInput;
};

export type CarModelFeatureUpsertLocalizationInput = {
  create: CarModelFeatureCreateLocalizationDataInput;
  locale: Locale;
  update: CarModelFeatureUpdateLocalizationDataInput;
};

export type CarModelFeatureUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CarModelFeatureUpsertInput;
  /** Unique document search */
  where: CarModelFeatureWhereUniqueInput;
};

/** Identifies documents */
export type CarModelFeatureWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CarModelFeatureWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CarModelFeatureWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CarModelFeatureWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModelFeatureIcon?: InputMaybe<AssetWhereInput>;
  carModelFeatureName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  carModelFeatureName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  carModelFeatureName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  carModelFeatureName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  carModelFeatureName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  carModelFeatureName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  carModelFeatureName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  carModelFeatureName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  carModelFeatureName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  carModelFeatureName_starts_with?: InputMaybe<Scalars['String']>;
  carModelFeatureOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  carModelFeatureOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  carModelFeatureOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  carModelFeatureOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  carModelFeatureOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  carModelFeatureOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  carModelFeatureOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  carModelFeatureOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  carModels_every?: InputMaybe<CarModelWhereInput>;
  carModels_none?: InputMaybe<CarModelWhereInput>;
  carModels_some?: InputMaybe<CarModelWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vinCarModelMatch?: InputMaybe<VinWhereInput>;
};

/** References CarModelFeature record uniquely */
export type CarModelFeatureWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type CarModelManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CarModelWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CarModelWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CarModelWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModelFeatures_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_some?: InputMaybe<CarModelFeatureWhereInput>;
  carModelImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationTab?: InputMaybe<GoBookingJourneyWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subClass?: InputMaybe<SubClassWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vinMatch_every?: InputMaybe<VinWhereInput>;
  vinMatch_none?: InputMaybe<VinWhereInput>;
  vinMatch_some?: InputMaybe<VinWhereInput>;
};

export enum CarModelOrderByInput {
  CarModelNameAsc = 'carModelName_ASC',
  CarModelNameDesc = 'carModelName_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WhyDriveThisCarModelNameAsc = 'whyDriveThisCarModelName_ASC',
  WhyDriveThisCarModelNameDesc = 'whyDriveThisCarModelName_DESC',
  WhyDriveThisCarModelTextAsc = 'whyDriveThisCarModelText_ASC',
  WhyDriveThisCarModelTextDesc = 'whyDriveThisCarModelText_DESC'
}

export type CarModelUpdateInput = {
  carModelFeatures?: InputMaybe<CarModelFeatureUpdateManyInlineInput>;
  carModelImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** carModelName input for default locale (de_CH) */
  carModelName?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<CarModelUpdateLocalizationsInput>;
  locationTab?: InputMaybe<GoBookingJourneyUpdateOneInlineInput>;
  subClass?: InputMaybe<SubClassUpdateOneInlineInput>;
  vinMatch?: InputMaybe<VinUpdateManyInlineInput>;
  /** whyDriveThisCarModelName input for default locale (de_CH) */
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  /** whyDriveThisCarModelText input for default locale (de_CH) */
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
};

export type CarModelUpdateLocalizationDataInput = {
  carModelName?: InputMaybe<Scalars['String']>;
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
};

export type CarModelUpdateLocalizationInput = {
  data: CarModelUpdateLocalizationDataInput;
  locale: Locale;
};

export type CarModelUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CarModelCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CarModelUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CarModelUpsertLocalizationInput>>;
};

export type CarModelUpdateManyInlineInput = {
  /** Connect multiple existing CarModel documents */
  connect?: InputMaybe<Array<CarModelConnectInput>>;
  /** Create and connect multiple CarModel documents */
  create?: InputMaybe<Array<CarModelCreateInput>>;
  /** Delete multiple CarModel documents */
  delete?: InputMaybe<Array<CarModelWhereUniqueInput>>;
  /** Disconnect multiple CarModel documents */
  disconnect?: InputMaybe<Array<CarModelWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CarModel documents */
  set?: InputMaybe<Array<CarModelWhereUniqueInput>>;
  /** Update multiple CarModel documents */
  update?: InputMaybe<Array<CarModelUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CarModel documents */
  upsert?: InputMaybe<Array<CarModelUpsertWithNestedWhereUniqueInput>>;
};

export type CarModelUpdateManyInput = {
  /** carModelName input for default locale (de_CH) */
  carModelName?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CarModelUpdateManyLocalizationsInput>;
  /** whyDriveThisCarModelName input for default locale (de_CH) */
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  /** whyDriveThisCarModelText input for default locale (de_CH) */
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
};

export type CarModelUpdateManyLocalizationDataInput = {
  carModelName?: InputMaybe<Scalars['String']>;
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
};

export type CarModelUpdateManyLocalizationInput = {
  data: CarModelUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CarModelUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CarModelUpdateManyLocalizationInput>>;
};

export type CarModelUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CarModelUpdateManyInput;
  /** Document search */
  where: CarModelWhereInput;
};

export type CarModelUpdateOneInlineInput = {
  /** Connect existing CarModel document */
  connect?: InputMaybe<CarModelWhereUniqueInput>;
  /** Create and connect one CarModel document */
  create?: InputMaybe<CarModelCreateInput>;
  /** Delete currently connected CarModel document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CarModel document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CarModel document */
  update?: InputMaybe<CarModelUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CarModel document */
  upsert?: InputMaybe<CarModelUpsertWithNestedWhereUniqueInput>;
};

export type CarModelUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CarModelUpdateInput;
  /** Unique document search */
  where: CarModelWhereUniqueInput;
};

export type CarModelUpsertInput = {
  /** Create document if it didn't exist */
  create: CarModelCreateInput;
  /** Update document if it exists */
  update: CarModelUpdateInput;
};

export type CarModelUpsertLocalizationInput = {
  create: CarModelCreateLocalizationDataInput;
  locale: Locale;
  update: CarModelUpdateLocalizationDataInput;
};

export type CarModelUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CarModelUpsertInput;
  /** Unique document search */
  where: CarModelWhereUniqueInput;
};

/** Identifies documents */
export type CarModelWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CarModelWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CarModelWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CarModelWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModelFeatures_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_some?: InputMaybe<CarModelFeatureWhereInput>;
  carModelImage?: InputMaybe<AssetWhereInput>;
  carModelName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  carModelName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  carModelName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  carModelName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  carModelName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  carModelName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  carModelName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  carModelName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  carModelName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  carModelName_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationTab?: InputMaybe<GoBookingJourneyWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subClass?: InputMaybe<SubClassWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vinMatch_every?: InputMaybe<VinWhereInput>;
  vinMatch_none?: InputMaybe<VinWhereInput>;
  vinMatch_some?: InputMaybe<VinWhereInput>;
  whyDriveThisCarModelName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  whyDriveThisCarModelName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  whyDriveThisCarModelName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  whyDriveThisCarModelName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  whyDriveThisCarModelName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  whyDriveThisCarModelName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  whyDriveThisCarModelName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  whyDriveThisCarModelName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  whyDriveThisCarModelName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  whyDriveThisCarModelName_starts_with?: InputMaybe<Scalars['String']>;
  whyDriveThisCarModelText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  whyDriveThisCarModelText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  whyDriveThisCarModelText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  whyDriveThisCarModelText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  whyDriveThisCarModelText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  whyDriveThisCarModelText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  whyDriveThisCarModelText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  whyDriveThisCarModelText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  whyDriveThisCarModelText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  whyDriveThisCarModelText_starts_with?: InputMaybe<Scalars['String']>;
};

/** References CarModel record uniquely */
export type CarModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Classes represent the grouping of multiple sub classes into one class. */
export type Class = Node & {
  __typename?: 'Class';
  /**
   * These are the class feature icons. Each icon comes with a corresponding description. These icons must be ordered in the EXACT same order as their corresponding feature description.
   *
   * EG. [icon] urban icon (#1) [Description] great for city (#1)
   */
  classFeatureIcon: Array<Asset>;
  /** These class features are the 3 distinct features that make up a class. */
  classFeatures: Array<ClassClassFeaturesRichText>;
  /** This image represents a class. */
  classImage: Asset;
  /** The class name represents a class or subclass */
  className?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Class>;
  /** List of Class versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Class>;
  /**
   *
   *
   */
  locationTab?: Maybe<GoBookingJourney>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** This reference model acts as a placeholder model for all the car models that comprise this class. It always requires an ending "or similar" */
  referenceCarModelName?: Maybe<Scalars['String']>;
  /** Matching the Class ID in RENT with the CMS. Do not change this! */
  rentClassId?: Maybe<Scalars['Int']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** This reference allows multiple sub class to be referenced in one class, meaning a query can be made to sub class class to get the belongig class. */
  subClass: Array<SubClass>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassClassFeatureIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassClassImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassLocationTabArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassSubClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SubClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubClassWhereInput>;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Classes represent the grouping of multiple sub classes into one class. */
export type ClassUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type ClassClassFeaturesRichText = {
  __typename?: 'ClassClassFeaturesRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<ClassClassFeaturesRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type ClassClassFeaturesRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ClassClassFeaturesRichTextEmbeddedTypes = Asset;

export type ClassConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ClassWhereUniqueInput;
};

/** A connection to a list of items. */
export type ClassConnection = {
  __typename?: 'ClassConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ClassEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ClassCreateInput = {
  classFeatureIcon?: InputMaybe<AssetCreateManyInlineInput>;
  /** classFeatures input for default locale (de_CH) */
  classFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  classImage: AssetCreateOneInlineInput;
  /** className input for default locale (de_CH) */
  className?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ClassCreateLocalizationsInput>;
  locationTab?: InputMaybe<GoBookingJourneyCreateOneInlineInput>;
  /** referenceCarModelName input for default locale (de_CH) */
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  rentClassId?: InputMaybe<Scalars['Int']>;
  subClass?: InputMaybe<SubClassCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassCreateLocalizationDataInput = {
  classFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  className?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassCreateLocalizationInput = {
  /** Localization input */
  data: ClassCreateLocalizationDataInput;
  locale: Locale;
};

export type ClassCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ClassCreateLocalizationInput>>;
};

export type ClassCreateManyInlineInput = {
  /** Connect multiple existing Class documents */
  connect?: InputMaybe<Array<ClassWhereUniqueInput>>;
  /** Create and connect multiple existing Class documents */
  create?: InputMaybe<Array<ClassCreateInput>>;
};

export type ClassCreateOneInlineInput = {
  /** Connect one existing Class document */
  connect?: InputMaybe<ClassWhereUniqueInput>;
  /** Create and connect one Class document */
  create?: InputMaybe<ClassCreateInput>;
};

/** An edge in a connection. */
export type ClassEdge = {
  __typename?: 'ClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Class;
};

/** Identifies documents */
export type ClassManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  classFeatureIcon_every?: InputMaybe<AssetWhereInput>;
  classFeatureIcon_none?: InputMaybe<AssetWhereInput>;
  classFeatureIcon_some?: InputMaybe<AssetWhereInput>;
  classImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationTab?: InputMaybe<GoBookingJourneyWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentClassId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentClassId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentClassId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentClassId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentClassId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentClassId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentClassId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentClassId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subClass_every?: InputMaybe<SubClassWhereInput>;
  subClass_none?: InputMaybe<SubClassWhereInput>;
  subClass_some?: InputMaybe<SubClassWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ClassOrderByInput {
  ClassNameAsc = 'className_ASC',
  ClassNameDesc = 'className_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReferenceCarModelNameAsc = 'referenceCarModelName_ASC',
  ReferenceCarModelNameDesc = 'referenceCarModelName_DESC',
  RentClassIdAsc = 'rentClassId_ASC',
  RentClassIdDesc = 'rentClassId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ClassUpdateInput = {
  classFeatureIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** classFeatures input for default locale (de_CH) */
  classFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  classImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** className input for default locale (de_CH) */
  className?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ClassUpdateLocalizationsInput>;
  locationTab?: InputMaybe<GoBookingJourneyUpdateOneInlineInput>;
  /** referenceCarModelName input for default locale (de_CH) */
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  rentClassId?: InputMaybe<Scalars['Int']>;
  subClass?: InputMaybe<SubClassUpdateManyInlineInput>;
};

export type ClassUpdateLocalizationDataInput = {
  classFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  className?: InputMaybe<Scalars['String']>;
  referenceCarModelName?: InputMaybe<Scalars['String']>;
};

export type ClassUpdateLocalizationInput = {
  data: ClassUpdateLocalizationDataInput;
  locale: Locale;
};

export type ClassUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ClassCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ClassUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ClassUpsertLocalizationInput>>;
};

export type ClassUpdateManyInlineInput = {
  /** Connect multiple existing Class documents */
  connect?: InputMaybe<Array<ClassConnectInput>>;
  /** Create and connect multiple Class documents */
  create?: InputMaybe<Array<ClassCreateInput>>;
  /** Delete multiple Class documents */
  delete?: InputMaybe<Array<ClassWhereUniqueInput>>;
  /** Disconnect multiple Class documents */
  disconnect?: InputMaybe<Array<ClassWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Class documents */
  set?: InputMaybe<Array<ClassWhereUniqueInput>>;
  /** Update multiple Class documents */
  update?: InputMaybe<Array<ClassUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Class documents */
  upsert?: InputMaybe<Array<ClassUpsertWithNestedWhereUniqueInput>>;
};

export type ClassUpdateManyInput = {
  /** classFeatures input for default locale (de_CH) */
  classFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ClassUpdateManyLocalizationsInput>;
  /** referenceCarModelName input for default locale (de_CH) */
  referenceCarModelName?: InputMaybe<Scalars['String']>;
};

export type ClassUpdateManyLocalizationDataInput = {
  classFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  referenceCarModelName?: InputMaybe<Scalars['String']>;
};

export type ClassUpdateManyLocalizationInput = {
  data: ClassUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ClassUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ClassUpdateManyLocalizationInput>>;
};

export type ClassUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ClassUpdateManyInput;
  /** Document search */
  where: ClassWhereInput;
};

export type ClassUpdateOneInlineInput = {
  /** Connect existing Class document */
  connect?: InputMaybe<ClassWhereUniqueInput>;
  /** Create and connect one Class document */
  create?: InputMaybe<ClassCreateInput>;
  /** Delete currently connected Class document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Class document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Class document */
  update?: InputMaybe<ClassUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Class document */
  upsert?: InputMaybe<ClassUpsertWithNestedWhereUniqueInput>;
};

export type ClassUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ClassUpdateInput;
  /** Unique document search */
  where: ClassWhereUniqueInput;
};

export type ClassUpsertInput = {
  /** Create document if it didn't exist */
  create: ClassCreateInput;
  /** Update document if it exists */
  update: ClassUpdateInput;
};

export type ClassUpsertLocalizationInput = {
  create: ClassCreateLocalizationDataInput;
  locale: Locale;
  update: ClassUpdateLocalizationDataInput;
};

export type ClassUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ClassUpsertInput;
  /** Unique document search */
  where: ClassWhereUniqueInput;
};

/** Identifies documents */
export type ClassWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  classFeatureIcon_every?: InputMaybe<AssetWhereInput>;
  classFeatureIcon_none?: InputMaybe<AssetWhereInput>;
  classFeatureIcon_some?: InputMaybe<AssetWhereInput>;
  classImage?: InputMaybe<AssetWhereInput>;
  className?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  className_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  className_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  className_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  className_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  className_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  className_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  className_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  className_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  className_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationTab?: InputMaybe<GoBookingJourneyWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  referenceCarModelName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  referenceCarModelName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  referenceCarModelName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  referenceCarModelName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  referenceCarModelName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  referenceCarModelName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  referenceCarModelName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  referenceCarModelName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  referenceCarModelName_starts_with?: InputMaybe<Scalars['String']>;
  rentClassId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentClassId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentClassId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentClassId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentClassId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentClassId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentClassId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentClassId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subClass_every?: InputMaybe<SubClassWhereInput>;
  subClass_none?: InputMaybe<SubClassWhereInput>;
  subClass_some?: InputMaybe<SubClassWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Class record uniquely */
export type ClassWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  rentClassId?: InputMaybe<Scalars['Int']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color';
  css: Scalars['String'];
  hex: Scalars['Hex'];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars['Hex']>;
  rgba?: InputMaybe<RgbaInput>;
};

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars['ID']>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars['ID']>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars['Boolean']>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars['Boolean']>;
};

/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type Contact = Node & {
  __typename?: 'Contact';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  customerEmail?: Maybe<Scalars['String']>;
  /** This is the telephone number of the customer service center. Beginning with (+41) to enable calling from abroad */
  customerServiceNumber?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<Contact>;
  /** List of Contact versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type ContactCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type ContactDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type ContactHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type ContactPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type ContactScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** These are the contacts (email, phone numbers, etc) that will be linked through the contact us buttons and link text */
export type ContactUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type ContactConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ContactWhereUniqueInput;
};

/** A connection to a list of items. */
export type ContactConnection = {
  __typename?: 'ContactConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ContactEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ContactCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  customerServiceNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ContactCreateManyInlineInput = {
  /** Connect multiple existing Contact documents */
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  /** Create and connect multiple existing Contact documents */
  create?: InputMaybe<Array<ContactCreateInput>>;
};

export type ContactCreateOneInlineInput = {
  /** Connect one existing Contact document */
  connect?: InputMaybe<ContactWhereUniqueInput>;
  /** Create and connect one Contact document */
  create?: InputMaybe<ContactCreateInput>;
};

/** An edge in a connection. */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Contact;
};

/** Identifies documents */
export type ContactManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContactWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContactWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  customerEmail?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  customerEmail_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  customerEmail_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  customerEmail_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  customerEmail_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  customerEmail_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  customerEmail_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  customerEmail_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  customerEmail_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  customerEmail_starts_with?: InputMaybe<Scalars['String']>;
  customerServiceNumber?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  customerServiceNumber_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  customerServiceNumber_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  customerServiceNumber_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  customerServiceNumber_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  customerServiceNumber_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  customerServiceNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  customerServiceNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  customerServiceNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  customerServiceNumber_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ContactOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerEmailAsc = 'customerEmail_ASC',
  CustomerEmailDesc = 'customerEmail_DESC',
  CustomerServiceNumberAsc = 'customerServiceNumber_ASC',
  CustomerServiceNumberDesc = 'customerServiceNumber_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ContactUpdateInput = {
  customerEmail?: InputMaybe<Scalars['String']>;
  customerServiceNumber?: InputMaybe<Scalars['String']>;
};

export type ContactUpdateManyInlineInput = {
  /** Connect multiple existing Contact documents */
  connect?: InputMaybe<Array<ContactConnectInput>>;
  /** Create and connect multiple Contact documents */
  create?: InputMaybe<Array<ContactCreateInput>>;
  /** Delete multiple Contact documents */
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  /** Disconnect multiple Contact documents */
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Contact documents */
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  /** Update multiple Contact documents */
  update?: InputMaybe<Array<ContactUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Contact documents */
  upsert?: InputMaybe<Array<ContactUpsertWithNestedWhereUniqueInput>>;
};

export type ContactUpdateManyInput = {
  customerEmail?: InputMaybe<Scalars['String']>;
  customerServiceNumber?: InputMaybe<Scalars['String']>;
};

export type ContactUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ContactUpdateManyInput;
  /** Document search */
  where: ContactWhereInput;
};

export type ContactUpdateOneInlineInput = {
  /** Connect existing Contact document */
  connect?: InputMaybe<ContactWhereUniqueInput>;
  /** Create and connect one Contact document */
  create?: InputMaybe<ContactCreateInput>;
  /** Delete currently connected Contact document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Contact document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Contact document */
  update?: InputMaybe<ContactUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Contact document */
  upsert?: InputMaybe<ContactUpsertWithNestedWhereUniqueInput>;
};

export type ContactUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ContactUpdateInput;
  /** Unique document search */
  where: ContactWhereUniqueInput;
};

export type ContactUpsertInput = {
  /** Create document if it didn't exist */
  create: ContactCreateInput;
  /** Update document if it exists */
  update: ContactUpdateInput;
};

export type ContactUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ContactUpsertInput;
  /** Unique document search */
  where: ContactWhereUniqueInput;
};

/** Identifies documents */
export type ContactWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContactWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContactWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  customerEmail?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  customerEmail_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  customerEmail_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  customerEmail_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  customerEmail_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  customerEmail_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  customerEmail_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  customerEmail_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  customerEmail_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  customerEmail_starts_with?: InputMaybe<Scalars['String']>;
  customerServiceNumber?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  customerServiceNumber_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  customerServiceNumber_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  customerServiceNumber_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  customerServiceNumber_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  customerServiceNumber_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  customerServiceNumber_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  customerServiceNumber_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  customerServiceNumber_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  customerServiceNumber_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Contact record uniquely */
export type ContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** A list of all countries of the world */
export enum Countries {
  Schweiz = 'Schweiz'
}

/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type Coverage = Node & {
  __typename?: 'Coverage';
  /** This is the deductible amount including CHF. */
  coverageDeductible?: Maybe<Scalars['String']>;
  /**
   * These are the coverage feature icons. Each icon comes with a corresponding description. These icons must be ordered in the EXACT same order as their corresponding feature description
   *
   * EG. [icon] tick (#1) [desicription] Roadside Assistance (Europe)
   */
  coverageFeatureIcon: Array<Asset>;
  /** Each coverage feature comes with an icon */
  coverageFeatures: Array<CoverageCoverageFeaturesRichText>;
  /** This is the name of the coverage type. */
  coverageName?: Maybe<Scalars['String']>;
  /** The price is retrieved by the pricing service, this is only the info for the user to understand how the price is calculated (e.g. CHF per Day) */
  coveragePrice?: Maybe<Scalars['String']>;
  /** A very short descriptive text about the coverage type. Can be an informative or marketing text, just needs to be consistent. */
  coverageShortDescription?: Maybe<Scalars['String']>;
  /** The tag 'most popular', 'super saver' etc is a png that is shown */
  coverageTag?: Maybe<Asset>;
  /** This is the short title of the total amount a customer pays in case of an accident (deductible) */
  coverageYouPay?: Maybe<Scalars['String']>;
  /** This is the order of display of the coverage types. */
  coveragedisplayOrder?: Maybe<Scalars['Int']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Coverage>;
  /** List of Coverage versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Coverage>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  rentCoverageId?: Maybe<Scalars['Int']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageCoverageFeatureIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageCoverageTagArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoveragePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoveragePublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** There are 3 different coverages that users select. In this schema, coverages are described including the price related figures in the button (except for the number itself). */
export type CoverageUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type CoverageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CoverageWhereUniqueInput;
};

/** A connection to a list of items. */
export type CoverageConnection = {
  __typename?: 'CoverageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CoverageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CoverageCoverageFeaturesRichText = {
  __typename?: 'CoverageCoverageFeaturesRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<CoverageCoverageFeaturesRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type CoverageCoverageFeaturesRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CoverageCoverageFeaturesRichTextEmbeddedTypes = Asset;

export type CoverageCreateInput = {
  /** coverageDeductible input for default locale (de_CH) */
  coverageDeductible?: InputMaybe<Scalars['String']>;
  coverageFeatureIcon?: InputMaybe<AssetCreateManyInlineInput>;
  /** coverageFeatures input for default locale (de_CH) */
  coverageFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** coverageName input for default locale (de_CH) */
  coverageName?: InputMaybe<Scalars['String']>;
  /** coveragePrice input for default locale (de_CH) */
  coveragePrice?: InputMaybe<Scalars['String']>;
  /** coverageShortDescription input for default locale (de_CH) */
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  coverageTag?: InputMaybe<AssetCreateOneInlineInput>;
  /** coverageYouPay input for default locale (de_CH) */
  coverageYouPay?: InputMaybe<Scalars['String']>;
  coveragedisplayOrder?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CoverageCreateLocalizationsInput>;
  rentCoverageId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CoverageCreateLocalizationDataInput = {
  coverageDeductible?: InputMaybe<Scalars['String']>;
  coverageFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  coverageName?: InputMaybe<Scalars['String']>;
  coveragePrice?: InputMaybe<Scalars['String']>;
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  coverageYouPay?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CoverageCreateLocalizationInput = {
  /** Localization input */
  data: CoverageCreateLocalizationDataInput;
  locale: Locale;
};

export type CoverageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CoverageCreateLocalizationInput>>;
};

export type CoverageCreateManyInlineInput = {
  /** Connect multiple existing Coverage documents */
  connect?: InputMaybe<Array<CoverageWhereUniqueInput>>;
  /** Create and connect multiple existing Coverage documents */
  create?: InputMaybe<Array<CoverageCreateInput>>;
};

export type CoverageCreateOneInlineInput = {
  /** Connect one existing Coverage document */
  connect?: InputMaybe<CoverageWhereUniqueInput>;
  /** Create and connect one Coverage document */
  create?: InputMaybe<CoverageCreateInput>;
};

/** An edge in a connection. */
export type CoverageEdge = {
  __typename?: 'CoverageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Coverage;
};

/** Identifies documents */
export type CoverageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CoverageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CoverageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CoverageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  coverageFeatureIcon_every?: InputMaybe<AssetWhereInput>;
  coverageFeatureIcon_none?: InputMaybe<AssetWhereInput>;
  coverageFeatureIcon_some?: InputMaybe<AssetWhereInput>;
  coverageTag?: InputMaybe<AssetWhereInput>;
  coveragedisplayOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  coveragedisplayOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  coveragedisplayOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  coveragedisplayOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  coveragedisplayOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  coveragedisplayOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  coveragedisplayOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  coveragedisplayOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentCoverageId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentCoverageId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentCoverageId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentCoverageId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentCoverageId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentCoverageId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentCoverageId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentCoverageId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CoverageOrderByInput {
  CoverageDeductibleAsc = 'coverageDeductible_ASC',
  CoverageDeductibleDesc = 'coverageDeductible_DESC',
  CoverageNameAsc = 'coverageName_ASC',
  CoverageNameDesc = 'coverageName_DESC',
  CoveragePriceAsc = 'coveragePrice_ASC',
  CoveragePriceDesc = 'coveragePrice_DESC',
  CoverageShortDescriptionAsc = 'coverageShortDescription_ASC',
  CoverageShortDescriptionDesc = 'coverageShortDescription_DESC',
  CoverageYouPayAsc = 'coverageYouPay_ASC',
  CoverageYouPayDesc = 'coverageYouPay_DESC',
  CoveragedisplayOrderAsc = 'coveragedisplayOrder_ASC',
  CoveragedisplayOrderDesc = 'coveragedisplayOrder_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RentCoverageIdAsc = 'rentCoverageId_ASC',
  RentCoverageIdDesc = 'rentCoverageId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CoverageUpdateInput = {
  /** coverageDeductible input for default locale (de_CH) */
  coverageDeductible?: InputMaybe<Scalars['String']>;
  coverageFeatureIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** coverageFeatures input for default locale (de_CH) */
  coverageFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** coverageName input for default locale (de_CH) */
  coverageName?: InputMaybe<Scalars['String']>;
  /** coveragePrice input for default locale (de_CH) */
  coveragePrice?: InputMaybe<Scalars['String']>;
  /** coverageShortDescription input for default locale (de_CH) */
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  coverageTag?: InputMaybe<AssetUpdateOneInlineInput>;
  /** coverageYouPay input for default locale (de_CH) */
  coverageYouPay?: InputMaybe<Scalars['String']>;
  coveragedisplayOrder?: InputMaybe<Scalars['Int']>;
  /** Manage document localizations */
  localizations?: InputMaybe<CoverageUpdateLocalizationsInput>;
  rentCoverageId?: InputMaybe<Scalars['Int']>;
};

export type CoverageUpdateLocalizationDataInput = {
  coverageDeductible?: InputMaybe<Scalars['String']>;
  coverageFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  coverageName?: InputMaybe<Scalars['String']>;
  coveragePrice?: InputMaybe<Scalars['String']>;
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  coverageYouPay?: InputMaybe<Scalars['String']>;
};

export type CoverageUpdateLocalizationInput = {
  data: CoverageUpdateLocalizationDataInput;
  locale: Locale;
};

export type CoverageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CoverageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CoverageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CoverageUpsertLocalizationInput>>;
};

export type CoverageUpdateManyInlineInput = {
  /** Connect multiple existing Coverage documents */
  connect?: InputMaybe<Array<CoverageConnectInput>>;
  /** Create and connect multiple Coverage documents */
  create?: InputMaybe<Array<CoverageCreateInput>>;
  /** Delete multiple Coverage documents */
  delete?: InputMaybe<Array<CoverageWhereUniqueInput>>;
  /** Disconnect multiple Coverage documents */
  disconnect?: InputMaybe<Array<CoverageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Coverage documents */
  set?: InputMaybe<Array<CoverageWhereUniqueInput>>;
  /** Update multiple Coverage documents */
  update?: InputMaybe<Array<CoverageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Coverage documents */
  upsert?: InputMaybe<Array<CoverageUpsertWithNestedWhereUniqueInput>>;
};

export type CoverageUpdateManyInput = {
  /** coverageDeductible input for default locale (de_CH) */
  coverageDeductible?: InputMaybe<Scalars['String']>;
  /** coverageFeatures input for default locale (de_CH) */
  coverageFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** coverageName input for default locale (de_CH) */
  coverageName?: InputMaybe<Scalars['String']>;
  /** coveragePrice input for default locale (de_CH) */
  coveragePrice?: InputMaybe<Scalars['String']>;
  /** coverageShortDescription input for default locale (de_CH) */
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  /** coverageYouPay input for default locale (de_CH) */
  coverageYouPay?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CoverageUpdateManyLocalizationsInput>;
};

export type CoverageUpdateManyLocalizationDataInput = {
  coverageDeductible?: InputMaybe<Scalars['String']>;
  coverageFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  coverageName?: InputMaybe<Scalars['String']>;
  coveragePrice?: InputMaybe<Scalars['String']>;
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  coverageYouPay?: InputMaybe<Scalars['String']>;
};

export type CoverageUpdateManyLocalizationInput = {
  data: CoverageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CoverageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CoverageUpdateManyLocalizationInput>>;
};

export type CoverageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CoverageUpdateManyInput;
  /** Document search */
  where: CoverageWhereInput;
};

export type CoverageUpdateOneInlineInput = {
  /** Connect existing Coverage document */
  connect?: InputMaybe<CoverageWhereUniqueInput>;
  /** Create and connect one Coverage document */
  create?: InputMaybe<CoverageCreateInput>;
  /** Delete currently connected Coverage document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Coverage document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Coverage document */
  update?: InputMaybe<CoverageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Coverage document */
  upsert?: InputMaybe<CoverageUpsertWithNestedWhereUniqueInput>;
};

export type CoverageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CoverageUpdateInput;
  /** Unique document search */
  where: CoverageWhereUniqueInput;
};

export type CoverageUpsertInput = {
  /** Create document if it didn't exist */
  create: CoverageCreateInput;
  /** Update document if it exists */
  update: CoverageUpdateInput;
};

export type CoverageUpsertLocalizationInput = {
  create: CoverageCreateLocalizationDataInput;
  locale: Locale;
  update: CoverageUpdateLocalizationDataInput;
};

export type CoverageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CoverageUpsertInput;
  /** Unique document search */
  where: CoverageWhereUniqueInput;
};

/** Identifies documents */
export type CoverageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CoverageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CoverageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CoverageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  coverageDeductible?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coverageDeductible_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coverageDeductible_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coverageDeductible_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coverageDeductible_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coverageDeductible_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coverageDeductible_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coverageDeductible_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coverageDeductible_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coverageDeductible_starts_with?: InputMaybe<Scalars['String']>;
  coverageFeatureIcon_every?: InputMaybe<AssetWhereInput>;
  coverageFeatureIcon_none?: InputMaybe<AssetWhereInput>;
  coverageFeatureIcon_some?: InputMaybe<AssetWhereInput>;
  coverageName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coverageName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coverageName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coverageName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coverageName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coverageName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coverageName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coverageName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coverageName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coverageName_starts_with?: InputMaybe<Scalars['String']>;
  coveragePrice?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coveragePrice_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coveragePrice_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coveragePrice_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coveragePrice_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coveragePrice_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coveragePrice_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coveragePrice_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coveragePrice_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coveragePrice_starts_with?: InputMaybe<Scalars['String']>;
  coverageShortDescription?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coverageShortDescription_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coverageShortDescription_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coverageShortDescription_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coverageShortDescription_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coverageShortDescription_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coverageShortDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coverageShortDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coverageShortDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coverageShortDescription_starts_with?: InputMaybe<Scalars['String']>;
  coverageTag?: InputMaybe<AssetWhereInput>;
  coverageYouPay?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coverageYouPay_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coverageYouPay_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coverageYouPay_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coverageYouPay_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coverageYouPay_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coverageYouPay_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coverageYouPay_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coverageYouPay_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coverageYouPay_starts_with?: InputMaybe<Scalars['String']>;
  coveragedisplayOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  coveragedisplayOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  coveragedisplayOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  coveragedisplayOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  coveragedisplayOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  coveragedisplayOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  coveragedisplayOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  coveragedisplayOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentCoverageId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentCoverageId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentCoverageId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentCoverageId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentCoverageId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentCoverageId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentCoverageId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentCoverageId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Coverage record uniquely */
export type CoverageWhereUniqueInput = {
  coveragedisplayOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  rentCoverageId?: InputMaybe<Scalars['Int']>;
};

export enum DocumentFileTypes {
  Doc = 'doc',
  Docx = 'docx',
  Html = 'html',
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Xls = 'xls',
  Xlsx = 'xlsx'
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboarding = Node & {
  __typename?: 'EmergencyKeyOnboarding';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<EmergencyKeyOnboarding>;
  emergencyKeyOnboardingImage?: Maybe<Asset>;
  emergencyKeyOnboardingNumber?: Maybe<Scalars['Int']>;
  emergencyKeyOnboardingText?: Maybe<Scalars['String']>;
  /** This is the title that explains what the emergency temporary key onboarding page is.  */
  emergencyTemporaryKeyName?: Maybe<Scalars['String']>;
  /** List of EmergencyKeyOnboarding versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<EmergencyKeyOnboarding>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingEmergencyKeyOnboardingImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the Onboarding screen that uses illustrations and text (formatted in steps) to explain the process of requesting and using an emergency temporary key. */
export type EmergencyKeyOnboardingUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type EmergencyKeyOnboardingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmergencyKeyOnboardingWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmergencyKeyOnboardingConnection = {
  __typename?: 'EmergencyKeyOnboardingConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmergencyKeyOnboardingEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmergencyKeyOnboardingCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emergencyKeyOnboardingImage?: InputMaybe<AssetCreateOneInlineInput>;
  emergencyKeyOnboardingNumber?: InputMaybe<Scalars['Int']>;
  /** emergencyKeyOnboardingText input for default locale (de_CH) */
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  /** emergencyTemporaryKeyName input for default locale (de_CH) */
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EmergencyKeyOnboardingCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmergencyKeyOnboardingCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmergencyKeyOnboardingCreateLocalizationInput = {
  /** Localization input */
  data: EmergencyKeyOnboardingCreateLocalizationDataInput;
  locale: Locale;
};

export type EmergencyKeyOnboardingCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EmergencyKeyOnboardingCreateLocalizationInput>>;
};

export type EmergencyKeyOnboardingCreateManyInlineInput = {
  /** Connect multiple existing EmergencyKeyOnboarding documents */
  connect?: InputMaybe<Array<EmergencyKeyOnboardingWhereUniqueInput>>;
  /** Create and connect multiple existing EmergencyKeyOnboarding documents */
  create?: InputMaybe<Array<EmergencyKeyOnboardingCreateInput>>;
};

export type EmergencyKeyOnboardingCreateOneInlineInput = {
  /** Connect one existing EmergencyKeyOnboarding document */
  connect?: InputMaybe<EmergencyKeyOnboardingWhereUniqueInput>;
  /** Create and connect one EmergencyKeyOnboarding document */
  create?: InputMaybe<EmergencyKeyOnboardingCreateInput>;
};

/** An edge in a connection. */
export type EmergencyKeyOnboardingEdge = {
  __typename?: 'EmergencyKeyOnboardingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmergencyKeyOnboarding;
};

/** Identifies documents */
export type EmergencyKeyOnboardingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmergencyKeyOnboardingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmergencyKeyOnboardingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmergencyKeyOnboardingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  emergencyKeyOnboardingImage?: InputMaybe<AssetWhereInput>;
  emergencyKeyOnboardingNumber?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  emergencyKeyOnboardingNumber_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  emergencyKeyOnboardingNumber_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  emergencyKeyOnboardingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  emergencyKeyOnboardingNumber_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  emergencyKeyOnboardingNumber_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  emergencyKeyOnboardingNumber_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  emergencyKeyOnboardingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum EmergencyKeyOnboardingOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmergencyKeyOnboardingNumberAsc = 'emergencyKeyOnboardingNumber_ASC',
  EmergencyKeyOnboardingNumberDesc = 'emergencyKeyOnboardingNumber_DESC',
  EmergencyKeyOnboardingTextAsc = 'emergencyKeyOnboardingText_ASC',
  EmergencyKeyOnboardingTextDesc = 'emergencyKeyOnboardingText_DESC',
  EmergencyTemporaryKeyNameAsc = 'emergencyTemporaryKeyName_ASC',
  EmergencyTemporaryKeyNameDesc = 'emergencyTemporaryKeyName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EmergencyKeyOnboardingUpdateInput = {
  emergencyKeyOnboardingImage?: InputMaybe<AssetUpdateOneInlineInput>;
  emergencyKeyOnboardingNumber?: InputMaybe<Scalars['Int']>;
  /** emergencyKeyOnboardingText input for default locale (de_CH) */
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  /** emergencyTemporaryKeyName input for default locale (de_CH) */
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<EmergencyKeyOnboardingUpdateLocalizationsInput>;
};

export type EmergencyKeyOnboardingUpdateLocalizationDataInput = {
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
};

export type EmergencyKeyOnboardingUpdateLocalizationInput = {
  data: EmergencyKeyOnboardingUpdateLocalizationDataInput;
  locale: Locale;
};

export type EmergencyKeyOnboardingUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EmergencyKeyOnboardingCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<EmergencyKeyOnboardingUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<EmergencyKeyOnboardingUpsertLocalizationInput>>;
};

export type EmergencyKeyOnboardingUpdateManyInlineInput = {
  /** Connect multiple existing EmergencyKeyOnboarding documents */
  connect?: InputMaybe<Array<EmergencyKeyOnboardingConnectInput>>;
  /** Create and connect multiple EmergencyKeyOnboarding documents */
  create?: InputMaybe<Array<EmergencyKeyOnboardingCreateInput>>;
  /** Delete multiple EmergencyKeyOnboarding documents */
  delete?: InputMaybe<Array<EmergencyKeyOnboardingWhereUniqueInput>>;
  /** Disconnect multiple EmergencyKeyOnboarding documents */
  disconnect?: InputMaybe<Array<EmergencyKeyOnboardingWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing EmergencyKeyOnboarding documents */
  set?: InputMaybe<Array<EmergencyKeyOnboardingWhereUniqueInput>>;
  /** Update multiple EmergencyKeyOnboarding documents */
  update?: InputMaybe<Array<EmergencyKeyOnboardingUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple EmergencyKeyOnboarding documents */
  upsert?: InputMaybe<Array<EmergencyKeyOnboardingUpsertWithNestedWhereUniqueInput>>;
};

export type EmergencyKeyOnboardingUpdateManyInput = {
  emergencyKeyOnboardingNumber?: InputMaybe<Scalars['Int']>;
  /** emergencyKeyOnboardingText input for default locale (de_CH) */
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  /** emergencyTemporaryKeyName input for default locale (de_CH) */
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<EmergencyKeyOnboardingUpdateManyLocalizationsInput>;
};

export type EmergencyKeyOnboardingUpdateManyLocalizationDataInput = {
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
};

export type EmergencyKeyOnboardingUpdateManyLocalizationInput = {
  data: EmergencyKeyOnboardingUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type EmergencyKeyOnboardingUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EmergencyKeyOnboardingUpdateManyLocalizationInput>>;
};

export type EmergencyKeyOnboardingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmergencyKeyOnboardingUpdateManyInput;
  /** Document search */
  where: EmergencyKeyOnboardingWhereInput;
};

export type EmergencyKeyOnboardingUpdateOneInlineInput = {
  /** Connect existing EmergencyKeyOnboarding document */
  connect?: InputMaybe<EmergencyKeyOnboardingWhereUniqueInput>;
  /** Create and connect one EmergencyKeyOnboarding document */
  create?: InputMaybe<EmergencyKeyOnboardingCreateInput>;
  /** Delete currently connected EmergencyKeyOnboarding document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected EmergencyKeyOnboarding document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single EmergencyKeyOnboarding document */
  update?: InputMaybe<EmergencyKeyOnboardingUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmergencyKeyOnboarding document */
  upsert?: InputMaybe<EmergencyKeyOnboardingUpsertWithNestedWhereUniqueInput>;
};

export type EmergencyKeyOnboardingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmergencyKeyOnboardingUpdateInput;
  /** Unique document search */
  where: EmergencyKeyOnboardingWhereUniqueInput;
};

export type EmergencyKeyOnboardingUpsertInput = {
  /** Create document if it didn't exist */
  create: EmergencyKeyOnboardingCreateInput;
  /** Update document if it exists */
  update: EmergencyKeyOnboardingUpdateInput;
};

export type EmergencyKeyOnboardingUpsertLocalizationInput = {
  create: EmergencyKeyOnboardingCreateLocalizationDataInput;
  locale: Locale;
  update: EmergencyKeyOnboardingUpdateLocalizationDataInput;
};

export type EmergencyKeyOnboardingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmergencyKeyOnboardingUpsertInput;
  /** Unique document search */
  where: EmergencyKeyOnboardingWhereUniqueInput;
};

/** Identifies documents */
export type EmergencyKeyOnboardingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmergencyKeyOnboardingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmergencyKeyOnboardingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmergencyKeyOnboardingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  emergencyKeyOnboardingImage?: InputMaybe<AssetWhereInput>;
  emergencyKeyOnboardingNumber?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  emergencyKeyOnboardingNumber_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  emergencyKeyOnboardingNumber_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  emergencyKeyOnboardingNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  emergencyKeyOnboardingNumber_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  emergencyKeyOnboardingNumber_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  emergencyKeyOnboardingNumber_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  emergencyKeyOnboardingNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  emergencyKeyOnboardingText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  emergencyKeyOnboardingText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  emergencyKeyOnboardingText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  emergencyKeyOnboardingText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  emergencyKeyOnboardingText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  emergencyKeyOnboardingText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  emergencyKeyOnboardingText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  emergencyKeyOnboardingText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  emergencyKeyOnboardingText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  emergencyKeyOnboardingText_starts_with?: InputMaybe<Scalars['String']>;
  emergencyTemporaryKeyName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  emergencyTemporaryKeyName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  emergencyTemporaryKeyName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  emergencyTemporaryKeyName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  emergencyTemporaryKeyName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  emergencyTemporaryKeyName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  emergencyTemporaryKeyName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  emergencyTemporaryKeyName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  emergencyTemporaryKeyName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  emergencyTemporaryKeyName_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References EmergencyKeyOnboarding record uniquely */
export type EmergencyKeyOnboardingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessage = Node & {
  __typename?: 'ErrorMessage';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ErrorMessage>;
  /** This is the text displayed in the button on the error message page */
  errorMessageButton?: Maybe<Scalars['String']>;
  /**
   * This is the header text that is displayed at the top of the error page
   *
   * EG : in english 'oops! looks like something went wrong'
   */
  errorMessageHeader?: Maybe<Scalars['String']>;
  /** This is the title used for page identification. This is NOT displayed in the UI */
  errorMessageIdentifier?: Maybe<Scalars['String']>;
  /** This is the image that is displayed in the error message.  */
  errorMessageImage?: Maybe<Asset>;
  /** This is the multi-line paragraph text that explains the error to the user.  */
  errorMessageText?: Maybe<Scalars['String']>;
  /** This is the title above the main text area of the error message.  */
  errorMessageTextTitle?: Maybe<Scalars['String']>;
  /** List of ErrorMessage versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ErrorMessage>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageErrorMessageImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessagePublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the error messages that are displayed to users when there has been a loading error (CMS) or another failure that prevents the application displaying the correct information  */
export type ErrorMessageUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type ErrorMessageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ErrorMessageWhereUniqueInput;
};

/** A connection to a list of items. */
export type ErrorMessageConnection = {
  __typename?: 'ErrorMessageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ErrorMessageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ErrorMessageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** errorMessageButton input for default locale (de_CH) */
  errorMessageButton?: InputMaybe<Scalars['String']>;
  /** errorMessageHeader input for default locale (de_CH) */
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  errorMessageIdentifier?: InputMaybe<Scalars['String']>;
  errorMessageImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** errorMessageText input for default locale (de_CH) */
  errorMessageText?: InputMaybe<Scalars['String']>;
  /** errorMessageTextTitle input for default locale (de_CH) */
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ErrorMessageCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ErrorMessageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  errorMessageButton?: InputMaybe<Scalars['String']>;
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  errorMessageText?: InputMaybe<Scalars['String']>;
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ErrorMessageCreateLocalizationInput = {
  /** Localization input */
  data: ErrorMessageCreateLocalizationDataInput;
  locale: Locale;
};

export type ErrorMessageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ErrorMessageCreateLocalizationInput>>;
};

export type ErrorMessageCreateManyInlineInput = {
  /** Connect multiple existing ErrorMessage documents */
  connect?: InputMaybe<Array<ErrorMessageWhereUniqueInput>>;
  /** Create and connect multiple existing ErrorMessage documents */
  create?: InputMaybe<Array<ErrorMessageCreateInput>>;
};

export type ErrorMessageCreateOneInlineInput = {
  /** Connect one existing ErrorMessage document */
  connect?: InputMaybe<ErrorMessageWhereUniqueInput>;
  /** Create and connect one ErrorMessage document */
  create?: InputMaybe<ErrorMessageCreateInput>;
};

/** An edge in a connection. */
export type ErrorMessageEdge = {
  __typename?: 'ErrorMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ErrorMessage;
};

/** Identifies documents */
export type ErrorMessageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ErrorMessageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ErrorMessageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ErrorMessageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  errorMessageIdentifier?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessageIdentifier_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessageIdentifier_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessageIdentifier_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessageIdentifier_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessageIdentifier_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessageIdentifier_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessageIdentifier_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessageIdentifier_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessageIdentifier_starts_with?: InputMaybe<Scalars['String']>;
  errorMessageImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ErrorMessageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ErrorMessageButtonAsc = 'errorMessageButton_ASC',
  ErrorMessageButtonDesc = 'errorMessageButton_DESC',
  ErrorMessageHeaderAsc = 'errorMessageHeader_ASC',
  ErrorMessageHeaderDesc = 'errorMessageHeader_DESC',
  ErrorMessageIdentifierAsc = 'errorMessageIdentifier_ASC',
  ErrorMessageIdentifierDesc = 'errorMessageIdentifier_DESC',
  ErrorMessageTextTitleAsc = 'errorMessageTextTitle_ASC',
  ErrorMessageTextTitleDesc = 'errorMessageTextTitle_DESC',
  ErrorMessageTextAsc = 'errorMessageText_ASC',
  ErrorMessageTextDesc = 'errorMessageText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ErrorMessageUpdateInput = {
  /** errorMessageButton input for default locale (de_CH) */
  errorMessageButton?: InputMaybe<Scalars['String']>;
  /** errorMessageHeader input for default locale (de_CH) */
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  errorMessageIdentifier?: InputMaybe<Scalars['String']>;
  errorMessageImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** errorMessageText input for default locale (de_CH) */
  errorMessageText?: InputMaybe<Scalars['String']>;
  /** errorMessageTextTitle input for default locale (de_CH) */
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ErrorMessageUpdateLocalizationsInput>;
};

export type ErrorMessageUpdateLocalizationDataInput = {
  errorMessageButton?: InputMaybe<Scalars['String']>;
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  errorMessageText?: InputMaybe<Scalars['String']>;
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
};

export type ErrorMessageUpdateLocalizationInput = {
  data: ErrorMessageUpdateLocalizationDataInput;
  locale: Locale;
};

export type ErrorMessageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ErrorMessageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ErrorMessageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ErrorMessageUpsertLocalizationInput>>;
};

export type ErrorMessageUpdateManyInlineInput = {
  /** Connect multiple existing ErrorMessage documents */
  connect?: InputMaybe<Array<ErrorMessageConnectInput>>;
  /** Create and connect multiple ErrorMessage documents */
  create?: InputMaybe<Array<ErrorMessageCreateInput>>;
  /** Delete multiple ErrorMessage documents */
  delete?: InputMaybe<Array<ErrorMessageWhereUniqueInput>>;
  /** Disconnect multiple ErrorMessage documents */
  disconnect?: InputMaybe<Array<ErrorMessageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ErrorMessage documents */
  set?: InputMaybe<Array<ErrorMessageWhereUniqueInput>>;
  /** Update multiple ErrorMessage documents */
  update?: InputMaybe<Array<ErrorMessageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ErrorMessage documents */
  upsert?: InputMaybe<Array<ErrorMessageUpsertWithNestedWhereUniqueInput>>;
};

export type ErrorMessageUpdateManyInput = {
  /** errorMessageButton input for default locale (de_CH) */
  errorMessageButton?: InputMaybe<Scalars['String']>;
  /** errorMessageHeader input for default locale (de_CH) */
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  errorMessageIdentifier?: InputMaybe<Scalars['String']>;
  /** errorMessageText input for default locale (de_CH) */
  errorMessageText?: InputMaybe<Scalars['String']>;
  /** errorMessageTextTitle input for default locale (de_CH) */
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ErrorMessageUpdateManyLocalizationsInput>;
};

export type ErrorMessageUpdateManyLocalizationDataInput = {
  errorMessageButton?: InputMaybe<Scalars['String']>;
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  errorMessageText?: InputMaybe<Scalars['String']>;
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
};

export type ErrorMessageUpdateManyLocalizationInput = {
  data: ErrorMessageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ErrorMessageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ErrorMessageUpdateManyLocalizationInput>>;
};

export type ErrorMessageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ErrorMessageUpdateManyInput;
  /** Document search */
  where: ErrorMessageWhereInput;
};

export type ErrorMessageUpdateOneInlineInput = {
  /** Connect existing ErrorMessage document */
  connect?: InputMaybe<ErrorMessageWhereUniqueInput>;
  /** Create and connect one ErrorMessage document */
  create?: InputMaybe<ErrorMessageCreateInput>;
  /** Delete currently connected ErrorMessage document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ErrorMessage document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ErrorMessage document */
  update?: InputMaybe<ErrorMessageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ErrorMessage document */
  upsert?: InputMaybe<ErrorMessageUpsertWithNestedWhereUniqueInput>;
};

export type ErrorMessageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ErrorMessageUpdateInput;
  /** Unique document search */
  where: ErrorMessageWhereUniqueInput;
};

export type ErrorMessageUpsertInput = {
  /** Create document if it didn't exist */
  create: ErrorMessageCreateInput;
  /** Update document if it exists */
  update: ErrorMessageUpdateInput;
};

export type ErrorMessageUpsertLocalizationInput = {
  create: ErrorMessageCreateLocalizationDataInput;
  locale: Locale;
  update: ErrorMessageUpdateLocalizationDataInput;
};

export type ErrorMessageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ErrorMessageUpsertInput;
  /** Unique document search */
  where: ErrorMessageWhereUniqueInput;
};

/** Identifies documents */
export type ErrorMessageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ErrorMessageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ErrorMessageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ErrorMessageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  errorMessageButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessageButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessageButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessageButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessageButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessageButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessageButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessageButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessageButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessageButton_starts_with?: InputMaybe<Scalars['String']>;
  errorMessageHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessageHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessageHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessageHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessageHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessageHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessageHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessageHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessageHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessageHeader_starts_with?: InputMaybe<Scalars['String']>;
  errorMessageIdentifier?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessageIdentifier_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessageIdentifier_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessageIdentifier_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessageIdentifier_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessageIdentifier_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessageIdentifier_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessageIdentifier_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessageIdentifier_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessageIdentifier_starts_with?: InputMaybe<Scalars['String']>;
  errorMessageImage?: InputMaybe<AssetWhereInput>;
  errorMessageText?: InputMaybe<Scalars['String']>;
  errorMessageTextTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessageTextTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessageTextTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessageTextTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessageTextTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessageTextTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessageTextTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessageTextTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessageTextTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessageTextTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessageText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessageText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessageText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessageText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessageText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessageText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessageText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessageText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessageText_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ErrorMessage record uniquely */
export type ErrorMessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type Extra = Node & {
  __typename?: 'Extra';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Extra>;
  /** Extra can be deselected */
  extraDeselectIcon?: Maybe<Asset>;
  /** Each extra needs to belong to an Extra Group (the Extra Group is visible as an Enumeration) */
  extrasGroup: Array<ExtrasGroup>;
  /** This is the corresponding Icon that belongs to an Extra. */
  extrasIcon?: Maybe<Asset>;
  /** This is the name of the extra */
  extrasName?: Maybe<Scalars['String']>;
  /** This is a short description on the Extra. */
  extrasShortDescription?: Maybe<Scalars['String']>;
  /** List of Extra versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Extra>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Match RENT ID with Extras ID. Do NOT change! */
  rentExtrasId?: Maybe<Scalars['Int']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraExtraDeselectIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraExtrasIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** The extras can be added to any rental, they do not have dependencies to any subclass. */
export type ExtraUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type ExtraConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ExtraWhereUniqueInput;
};

/** A connection to a list of items. */
export type ExtraConnection = {
  __typename?: 'ExtraConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ExtraEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ExtraCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  extraDeselectIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** extrasGroup input for default locale (de_CH) */
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  extrasIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** extrasName input for default locale (de_CH) */
  extrasName?: InputMaybe<Scalars['String']>;
  /** extrasShortDescription input for default locale (de_CH) */
  extrasShortDescription?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ExtraCreateLocalizationsInput>;
  rentExtrasId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ExtraCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  extrasName?: InputMaybe<Scalars['String']>;
  extrasShortDescription?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ExtraCreateLocalizationInput = {
  /** Localization input */
  data: ExtraCreateLocalizationDataInput;
  locale: Locale;
};

export type ExtraCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ExtraCreateLocalizationInput>>;
};

export type ExtraCreateManyInlineInput = {
  /** Connect multiple existing Extra documents */
  connect?: InputMaybe<Array<ExtraWhereUniqueInput>>;
  /** Create and connect multiple existing Extra documents */
  create?: InputMaybe<Array<ExtraCreateInput>>;
};

export type ExtraCreateOneInlineInput = {
  /** Connect one existing Extra document */
  connect?: InputMaybe<ExtraWhereUniqueInput>;
  /** Create and connect one Extra document */
  create?: InputMaybe<ExtraCreateInput>;
};

/** An edge in a connection. */
export type ExtraEdge = {
  __typename?: 'ExtraEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Extra;
};

/** Identifies documents */
export type ExtraManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExtraWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExtraWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExtraWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  extraDeselectIcon?: InputMaybe<AssetWhereInput>;
  extrasIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentExtrasId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentExtrasId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentExtrasId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentExtrasId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentExtrasId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentExtrasId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentExtrasId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentExtrasId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ExtraOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExtrasGroupAsc = 'extrasGroup_ASC',
  ExtrasGroupDesc = 'extrasGroup_DESC',
  ExtrasNameAsc = 'extrasName_ASC',
  ExtrasNameDesc = 'extrasName_DESC',
  ExtrasShortDescriptionAsc = 'extrasShortDescription_ASC',
  ExtrasShortDescriptionDesc = 'extrasShortDescription_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RentExtrasIdAsc = 'rentExtrasId_ASC',
  RentExtrasIdDesc = 'rentExtrasId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ExtraUpdateInput = {
  extraDeselectIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** extrasGroup input for default locale (de_CH) */
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  extrasIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** extrasName input for default locale (de_CH) */
  extrasName?: InputMaybe<Scalars['String']>;
  /** extrasShortDescription input for default locale (de_CH) */
  extrasShortDescription?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ExtraUpdateLocalizationsInput>;
  rentExtrasId?: InputMaybe<Scalars['Int']>;
};

export type ExtraUpdateLocalizationDataInput = {
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  extrasName?: InputMaybe<Scalars['String']>;
  extrasShortDescription?: InputMaybe<Scalars['String']>;
};

export type ExtraUpdateLocalizationInput = {
  data: ExtraUpdateLocalizationDataInput;
  locale: Locale;
};

export type ExtraUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ExtraCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ExtraUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ExtraUpsertLocalizationInput>>;
};

export type ExtraUpdateManyInlineInput = {
  /** Connect multiple existing Extra documents */
  connect?: InputMaybe<Array<ExtraConnectInput>>;
  /** Create and connect multiple Extra documents */
  create?: InputMaybe<Array<ExtraCreateInput>>;
  /** Delete multiple Extra documents */
  delete?: InputMaybe<Array<ExtraWhereUniqueInput>>;
  /** Disconnect multiple Extra documents */
  disconnect?: InputMaybe<Array<ExtraWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Extra documents */
  set?: InputMaybe<Array<ExtraWhereUniqueInput>>;
  /** Update multiple Extra documents */
  update?: InputMaybe<Array<ExtraUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Extra documents */
  upsert?: InputMaybe<Array<ExtraUpsertWithNestedWhereUniqueInput>>;
};

export type ExtraUpdateManyInput = {
  /** extrasGroup input for default locale (de_CH) */
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  /** extrasName input for default locale (de_CH) */
  extrasName?: InputMaybe<Scalars['String']>;
  /** extrasShortDescription input for default locale (de_CH) */
  extrasShortDescription?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ExtraUpdateManyLocalizationsInput>;
};

export type ExtraUpdateManyLocalizationDataInput = {
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  extrasName?: InputMaybe<Scalars['String']>;
  extrasShortDescription?: InputMaybe<Scalars['String']>;
};

export type ExtraUpdateManyLocalizationInput = {
  data: ExtraUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ExtraUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ExtraUpdateManyLocalizationInput>>;
};

export type ExtraUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ExtraUpdateManyInput;
  /** Document search */
  where: ExtraWhereInput;
};

export type ExtraUpdateOneInlineInput = {
  /** Connect existing Extra document */
  connect?: InputMaybe<ExtraWhereUniqueInput>;
  /** Create and connect one Extra document */
  create?: InputMaybe<ExtraCreateInput>;
  /** Delete currently connected Extra document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Extra document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Extra document */
  update?: InputMaybe<ExtraUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Extra document */
  upsert?: InputMaybe<ExtraUpsertWithNestedWhereUniqueInput>;
};

export type ExtraUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ExtraUpdateInput;
  /** Unique document search */
  where: ExtraWhereUniqueInput;
};

export type ExtraUpsertInput = {
  /** Create document if it didn't exist */
  create: ExtraCreateInput;
  /** Update document if it exists */
  update: ExtraUpdateInput;
};

export type ExtraUpsertLocalizationInput = {
  create: ExtraCreateLocalizationDataInput;
  locale: Locale;
  update: ExtraUpdateLocalizationDataInput;
};

export type ExtraUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ExtraUpsertInput;
  /** Unique document search */
  where: ExtraWhereUniqueInput;
};

/** Identifies documents */
export type ExtraWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExtraWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExtraWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExtraWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  extraDeselectIcon?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  extrasGroup?: InputMaybe<Array<ExtrasGroup>>;
  /** Matches if the field array contains *all* items provided to the filter */
  extrasGroup_contains_all?: InputMaybe<Array<ExtrasGroup>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  extrasGroup_contains_none?: InputMaybe<Array<ExtrasGroup>>;
  /** Matches if the field array contains at least one item provided to the filter */
  extrasGroup_contains_some?: InputMaybe<Array<ExtrasGroup>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  extrasGroup_not?: InputMaybe<Array<ExtrasGroup>>;
  extrasIcon?: InputMaybe<AssetWhereInput>;
  extrasName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  extrasName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  extrasName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  extrasName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  extrasName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  extrasName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  extrasName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  extrasName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  extrasName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  extrasName_starts_with?: InputMaybe<Scalars['String']>;
  extrasShortDescription?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  extrasShortDescription_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  extrasShortDescription_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  extrasShortDescription_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  extrasShortDescription_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  extrasShortDescription_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  extrasShortDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  extrasShortDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  extrasShortDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  extrasShortDescription_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentExtrasId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentExtrasId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentExtrasId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentExtrasId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentExtrasId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentExtrasId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentExtrasId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentExtrasId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Extra record uniquely */
export type ExtraWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  rentExtrasId?: InputMaybe<Scalars['Int']>;
};

/** Each extra belongs to a group and can change over time. */
export enum ExtrasGroup {
  All = 'All',
  Summer = 'Summer',
  Winter = 'Winter'
}

/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocation = Node & {
  __typename?: 'GOLocation';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoLocation>;
  /** The displayed title of each location - has to correspond exactly with the location title in RENT. */
  gOLocationName?: Maybe<Scalars['String']>;
  /** Each location has specific regular and one-off opening hours, all displayed in this area. */
  gOLocationOpeningHours: Array<RichText>;
  /** This is the street address of the GO location that will be used as a link to be opened in google maps.  */
  goLocationAddress?: Maybe<Scalars['String']>;
  /** The is the title that reads 'address' in english above the street address for each GO location. */
  goLocationAddressTitle?: Maybe<Scalars['String']>;
  /** Description of each location - for each adress; 'by...': 'by train'; 'by bus'; use a seperate value */
  goLocationDirectionsDescription: Array<RichText>;
  /** This is the header for directions */
  goLocationDirectionsName?: Maybe<Scalars['String']>;
  /** Images showing the physical GO location */
  goLocationImage: Array<Asset>;
  /** Some Go Locations require special information (like a code to drive out of the parking space) */
  goLocationImportantInformation: Array<RichText>;
  /** The Header 'Important Information' of this section. */
  goLocationImportantName?: Maybe<Scalars['String']>;
  /** This is the URI for Google Maps */
  goLocationMapLink?: Maybe<Scalars['String']>;
  /** This is the header for opening hours */
  goLocationOpeningHoursName?: Maybe<Scalars['String']>;
  /** This is the icon that links to google maps  */
  googleMapsLinkIcon?: Maybe<Asset>;
  /** List of GOLocation versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoLocation>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** This is the Location ID that is generated in RENT. This ID must EXACTLY match the ID in RENT */
  rentLocationId?: Maybe<Scalars['Int']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationGoLocationImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationGoogleMapsLinkIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Every bookable location has a location description, that includes images and text how to find the location. Some of the information within a location description comes from RENT (such as opening hours). */
export type GoLocationUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoLocationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoLocationWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoLocationConnection = {
  __typename?: 'GOLocationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoLocationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoLocationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** gOLocationName input for default locale (de_CH) */
  gOLocationName?: InputMaybe<Scalars['String']>;
  /** gOLocationOpeningHours input for default locale (de_CH) */
  gOLocationOpeningHours?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationAddress input for default locale (de_CH) */
  goLocationAddress?: InputMaybe<Scalars['String']>;
  /** goLocationAddressTitle input for default locale (de_CH) */
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  /** goLocationDirectionsDescription input for default locale (de_CH) */
  goLocationDirectionsDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationDirectionsName input for default locale (de_CH) */
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  goLocationImage?: InputMaybe<AssetCreateManyInlineInput>;
  /** goLocationImportantInformation input for default locale (de_CH) */
  goLocationImportantInformation?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationImportantName input for default locale (de_CH) */
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  goLocationMapLink?: InputMaybe<Scalars['String']>;
  /** goLocationOpeningHoursName input for default locale (de_CH) */
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
  googleMapsLinkIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoLocationCreateLocalizationsInput>;
  rentLocationId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoLocationCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gOLocationName?: InputMaybe<Scalars['String']>;
  gOLocationOpeningHours?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationAddress?: InputMaybe<Scalars['String']>;
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  goLocationDirectionsDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  goLocationImportantInformation?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoLocationCreateLocalizationInput = {
  /** Localization input */
  data: GoLocationCreateLocalizationDataInput;
  locale: Locale;
};

export type GoLocationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoLocationCreateLocalizationInput>>;
};

export type GoLocationCreateManyInlineInput = {
  /** Connect multiple existing GOLocation documents */
  connect?: InputMaybe<Array<GoLocationWhereUniqueInput>>;
  /** Create and connect multiple existing GOLocation documents */
  create?: InputMaybe<Array<GoLocationCreateInput>>;
};

export type GoLocationCreateOneInlineInput = {
  /** Connect one existing GOLocation document */
  connect?: InputMaybe<GoLocationWhereUniqueInput>;
  /** Create and connect one GOLocation document */
  create?: InputMaybe<GoLocationCreateInput>;
};

/** An edge in a connection. */
export type GoLocationEdge = {
  __typename?: 'GOLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoLocation;
};

/** Identifies documents */
export type GoLocationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoLocationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoLocationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoLocationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  goLocationImage_every?: InputMaybe<AssetWhereInput>;
  goLocationImage_none?: InputMaybe<AssetWhereInput>;
  goLocationImage_some?: InputMaybe<AssetWhereInput>;
  goLocationMapLink?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationMapLink_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationMapLink_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationMapLink_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationMapLink_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationMapLink_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationMapLink_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationMapLink_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationMapLink_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationMapLink_starts_with?: InputMaybe<Scalars['String']>;
  googleMapsLinkIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentLocationId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentLocationId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentLocationId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentLocationId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentLocationId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentLocationId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentLocationId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentLocationId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoLocationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  GOLocationNameAsc = 'gOLocationName_ASC',
  GOLocationNameDesc = 'gOLocationName_DESC',
  GoLocationAddressTitleAsc = 'goLocationAddressTitle_ASC',
  GoLocationAddressTitleDesc = 'goLocationAddressTitle_DESC',
  GoLocationAddressAsc = 'goLocationAddress_ASC',
  GoLocationAddressDesc = 'goLocationAddress_DESC',
  GoLocationDirectionsNameAsc = 'goLocationDirectionsName_ASC',
  GoLocationDirectionsNameDesc = 'goLocationDirectionsName_DESC',
  GoLocationImportantNameAsc = 'goLocationImportantName_ASC',
  GoLocationImportantNameDesc = 'goLocationImportantName_DESC',
  GoLocationMapLinkAsc = 'goLocationMapLink_ASC',
  GoLocationMapLinkDesc = 'goLocationMapLink_DESC',
  GoLocationOpeningHoursNameAsc = 'goLocationOpeningHoursName_ASC',
  GoLocationOpeningHoursNameDesc = 'goLocationOpeningHoursName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RentLocationIdAsc = 'rentLocationId_ASC',
  RentLocationIdDesc = 'rentLocationId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoLocationUpdateInput = {
  /** gOLocationName input for default locale (de_CH) */
  gOLocationName?: InputMaybe<Scalars['String']>;
  /** gOLocationOpeningHours input for default locale (de_CH) */
  gOLocationOpeningHours?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationAddress input for default locale (de_CH) */
  goLocationAddress?: InputMaybe<Scalars['String']>;
  /** goLocationAddressTitle input for default locale (de_CH) */
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  /** goLocationDirectionsDescription input for default locale (de_CH) */
  goLocationDirectionsDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationDirectionsName input for default locale (de_CH) */
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  goLocationImage?: InputMaybe<AssetUpdateManyInlineInput>;
  /** goLocationImportantInformation input for default locale (de_CH) */
  goLocationImportantInformation?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationImportantName input for default locale (de_CH) */
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  goLocationMapLink?: InputMaybe<Scalars['String']>;
  /** goLocationOpeningHoursName input for default locale (de_CH) */
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
  googleMapsLinkIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoLocationUpdateLocalizationsInput>;
  rentLocationId?: InputMaybe<Scalars['Int']>;
};

export type GoLocationUpdateLocalizationDataInput = {
  gOLocationName?: InputMaybe<Scalars['String']>;
  gOLocationOpeningHours?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationAddress?: InputMaybe<Scalars['String']>;
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  goLocationDirectionsDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  goLocationImportantInformation?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
};

export type GoLocationUpdateLocalizationInput = {
  data: GoLocationUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoLocationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoLocationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoLocationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoLocationUpsertLocalizationInput>>;
};

export type GoLocationUpdateManyInlineInput = {
  /** Connect multiple existing GOLocation documents */
  connect?: InputMaybe<Array<GoLocationConnectInput>>;
  /** Create and connect multiple GOLocation documents */
  create?: InputMaybe<Array<GoLocationCreateInput>>;
  /** Delete multiple GOLocation documents */
  delete?: InputMaybe<Array<GoLocationWhereUniqueInput>>;
  /** Disconnect multiple GOLocation documents */
  disconnect?: InputMaybe<Array<GoLocationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GOLocation documents */
  set?: InputMaybe<Array<GoLocationWhereUniqueInput>>;
  /** Update multiple GOLocation documents */
  update?: InputMaybe<Array<GoLocationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GOLocation documents */
  upsert?: InputMaybe<Array<GoLocationUpsertWithNestedWhereUniqueInput>>;
};

export type GoLocationUpdateManyInput = {
  /** gOLocationName input for default locale (de_CH) */
  gOLocationName?: InputMaybe<Scalars['String']>;
  /** gOLocationOpeningHours input for default locale (de_CH) */
  gOLocationOpeningHours?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationAddress input for default locale (de_CH) */
  goLocationAddress?: InputMaybe<Scalars['String']>;
  /** goLocationAddressTitle input for default locale (de_CH) */
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  /** goLocationDirectionsDescription input for default locale (de_CH) */
  goLocationDirectionsDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationDirectionsName input for default locale (de_CH) */
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  /** goLocationImportantInformation input for default locale (de_CH) */
  goLocationImportantInformation?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** goLocationImportantName input for default locale (de_CH) */
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  /** goLocationOpeningHoursName input for default locale (de_CH) */
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoLocationUpdateManyLocalizationsInput>;
};

export type GoLocationUpdateManyLocalizationDataInput = {
  gOLocationName?: InputMaybe<Scalars['String']>;
  gOLocationOpeningHours?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationAddress?: InputMaybe<Scalars['String']>;
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  goLocationDirectionsDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  goLocationImportantInformation?: InputMaybe<Array<Scalars['RichTextAST']>>;
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
};

export type GoLocationUpdateManyLocalizationInput = {
  data: GoLocationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoLocationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoLocationUpdateManyLocalizationInput>>;
};

export type GoLocationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoLocationUpdateManyInput;
  /** Document search */
  where: GoLocationWhereInput;
};

export type GoLocationUpdateOneInlineInput = {
  /** Connect existing GOLocation document */
  connect?: InputMaybe<GoLocationWhereUniqueInput>;
  /** Create and connect one GOLocation document */
  create?: InputMaybe<GoLocationCreateInput>;
  /** Delete currently connected GOLocation document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GOLocation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GOLocation document */
  update?: InputMaybe<GoLocationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GOLocation document */
  upsert?: InputMaybe<GoLocationUpsertWithNestedWhereUniqueInput>;
};

export type GoLocationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoLocationUpdateInput;
  /** Unique document search */
  where: GoLocationWhereUniqueInput;
};

export type GoLocationUpsertInput = {
  /** Create document if it didn't exist */
  create: GoLocationCreateInput;
  /** Update document if it exists */
  update: GoLocationUpdateInput;
};

export type GoLocationUpsertLocalizationInput = {
  create: GoLocationCreateLocalizationDataInput;
  locale: Locale;
  update: GoLocationUpdateLocalizationDataInput;
};

export type GoLocationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoLocationUpsertInput;
  /** Unique document search */
  where: GoLocationWhereUniqueInput;
};

/** Identifies documents */
export type GoLocationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoLocationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoLocationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoLocationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  gOLocationName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  gOLocationName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  gOLocationName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  gOLocationName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  gOLocationName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  gOLocationName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  gOLocationName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  gOLocationName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  gOLocationName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  gOLocationName_starts_with?: InputMaybe<Scalars['String']>;
  goLocationAddress?: InputMaybe<Scalars['String']>;
  goLocationAddressTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationAddressTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationAddressTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationAddressTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationAddressTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationAddressTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationAddressTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationAddressTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationAddressTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationAddressTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationAddress_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationAddress_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationAddress_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationAddress_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationAddress_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationAddress_starts_with?: InputMaybe<Scalars['String']>;
  goLocationDirectionsName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationDirectionsName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationDirectionsName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationDirectionsName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationDirectionsName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationDirectionsName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationDirectionsName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationDirectionsName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationDirectionsName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationDirectionsName_starts_with?: InputMaybe<Scalars['String']>;
  goLocationImage_every?: InputMaybe<AssetWhereInput>;
  goLocationImage_none?: InputMaybe<AssetWhereInput>;
  goLocationImage_some?: InputMaybe<AssetWhereInput>;
  goLocationImportantName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationImportantName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationImportantName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationImportantName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationImportantName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationImportantName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationImportantName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationImportantName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationImportantName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationImportantName_starts_with?: InputMaybe<Scalars['String']>;
  goLocationMapLink?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationMapLink_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationMapLink_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationMapLink_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationMapLink_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationMapLink_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationMapLink_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationMapLink_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationMapLink_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationMapLink_starts_with?: InputMaybe<Scalars['String']>;
  goLocationOpeningHoursName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationOpeningHoursName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationOpeningHoursName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationOpeningHoursName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationOpeningHoursName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationOpeningHoursName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationOpeningHoursName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationOpeningHoursName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationOpeningHoursName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationOpeningHoursName_starts_with?: InputMaybe<Scalars['String']>;
  googleMapsLinkIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentLocationId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentLocationId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentLocationId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentLocationId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentLocationId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentLocationId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentLocationId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentLocationId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GOLocation record uniquely */
export type GoLocationWhereUniqueInput = {
  goLocationMapLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  rentLocationId?: InputMaybe<Scalars['Int']>;
};

/** This is the search form for the book now and book later journey. */
export type GoSearch = Node & {
  __typename?: 'GOSearch';
  /** This is the tile that heads the booking widget. This in exnglish is book a rental now */
  bookingWidgetTitleWebapp?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoSearch>;
  /**
   * This is the title field that differentiates between the section of the application in which the data should be displayed.
   *
   * IE: Home : should display the data on the home screen
   *      Rental : should display the data on the rental screens
   */
  gOSearchDisplaySection?: Maybe<Scalars['String']>;
  /** This is the icon of the current location of the user. */
  goCurrentLocationIconActive?: Maybe<Asset>;
  /** This is the current location icon to be displayed on the home screen when the user has not acitvated the current location settings.  */
  goCurrentLocationIconInactive?: Maybe<Asset>;
  /** Placeholder text in the location search bar. */
  goLocationPlaceholderText?: Maybe<Scalars['String']>;
  /** These are the names displayed instead of dates and times OR in addition at the beginning with date & time. */
  goPickUpDateTimeNames: Array<Scalars['String']>;
  /** This is the icon (pin and >) before each location name */
  goPickUpIcon?: Maybe<Asset>;
  /** "Pick Up" Header of the Input data field */
  goPickUpLocation?: Maybe<Scalars['String']>;
  /** This icon is displayed in front of the GO location name when pickup and return is the same. */
  goPickUpReturnIcon?: Maybe<Asset>;
  /** This is the icon used to show the GO pick up time and is displayed next to the work pick up in the app. */
  goPickUpTimeIcon?: Maybe<Asset>;
  /** This is the icon that represents the return date and in shown to the left of the return text in the app.  */
  goReturnDateIcon?: Maybe<Asset>;
  /** 'Return' Header for the return data input field */
  goReturnLocationName?: Maybe<Scalars['String']>;
  /** The search button either displays the go now booking journey or book later journey. */
  goSearchButton: Array<Scalars['String']>;
  /** This is the 'i' icon for more information on the location */
  goSearchLocationInformation?: Maybe<Asset>;
  /** Checkbox if Return Location is the same as Pick Up Location */
  goSearchLocationSame?: Maybe<Scalars['String']>;
  /**
   * This is the calendar used when an abbreviated version of the rental period is shown
   *
   * EG. In the Upcoming rental overview
   */
  goSearchRentalPeriodCalendar?: Maybe<Asset>;
  /** This is the icon (pin and <) before each location name */
  goSearchReturnIcon?: Maybe<Asset>;
  /** List of GOSearch versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoSearch>;
  /** This is the title above displayed above the location next to which is the the location information icon */
  locationTitle?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoCurrentLocationIconActiveArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoCurrentLocationIconInactiveArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoPickUpIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoPickUpReturnIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoPickUpTimeIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoReturnDateIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoSearchLocationInformationArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoSearchRentalPeriodCalendarArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchGoSearchReturnIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the search form for the book now and book later journey. */
export type GoSearchUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoSearchConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoSearchWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoSearchConnection = {
  __typename?: 'GOSearchConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoSearchEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoSearchCreateInput = {
  /** bookingWidgetTitleWebapp input for default locale (de_CH) */
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gOSearchDisplaySection?: InputMaybe<Scalars['String']>;
  goCurrentLocationIconActive?: InputMaybe<AssetCreateOneInlineInput>;
  goCurrentLocationIconInactive?: InputMaybe<AssetCreateOneInlineInput>;
  /** goLocationPlaceholderText input for default locale (de_CH) */
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  /** goPickUpDateTimeNames input for default locale (de_CH) */
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  goPickUpIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** goPickUpLocation input for default locale (de_CH) */
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  goPickUpReturnIcon?: InputMaybe<AssetCreateOneInlineInput>;
  goPickUpTimeIcon?: InputMaybe<AssetCreateOneInlineInput>;
  goReturnDateIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** goReturnLocationName input for default locale (de_CH) */
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  /** goSearchButton input for default locale (de_CH) */
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  goSearchLocationInformation?: InputMaybe<AssetCreateOneInlineInput>;
  /** goSearchLocationSame input for default locale (de_CH) */
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  goSearchRentalPeriodCalendar?: InputMaybe<AssetCreateOneInlineInput>;
  goSearchReturnIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoSearchCreateLocalizationsInput>;
  /** locationTitle input for default locale (de_CH) */
  locationTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoSearchCreateLocalizationDataInput = {
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  locationTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoSearchCreateLocalizationInput = {
  /** Localization input */
  data: GoSearchCreateLocalizationDataInput;
  locale: Locale;
};

export type GoSearchCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoSearchCreateLocalizationInput>>;
};

export type GoSearchCreateManyInlineInput = {
  /** Connect multiple existing GOSearch documents */
  connect?: InputMaybe<Array<GoSearchWhereUniqueInput>>;
  /** Create and connect multiple existing GOSearch documents */
  create?: InputMaybe<Array<GoSearchCreateInput>>;
};

export type GoSearchCreateOneInlineInput = {
  /** Connect one existing GOSearch document */
  connect?: InputMaybe<GoSearchWhereUniqueInput>;
  /** Create and connect one GOSearch document */
  create?: InputMaybe<GoSearchCreateInput>;
};

/** An edge in a connection. */
export type GoSearchEdge = {
  __typename?: 'GOSearchEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoSearch;
};

/** Identifies documents */
export type GoSearchManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoSearchWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoSearchWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoSearchWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  gOSearchDisplaySection?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  gOSearchDisplaySection_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  gOSearchDisplaySection_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  gOSearchDisplaySection_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  gOSearchDisplaySection_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  gOSearchDisplaySection_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  gOSearchDisplaySection_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  gOSearchDisplaySection_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  gOSearchDisplaySection_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  gOSearchDisplaySection_starts_with?: InputMaybe<Scalars['String']>;
  goCurrentLocationIconActive?: InputMaybe<AssetWhereInput>;
  goCurrentLocationIconInactive?: InputMaybe<AssetWhereInput>;
  goPickUpIcon?: InputMaybe<AssetWhereInput>;
  goPickUpReturnIcon?: InputMaybe<AssetWhereInput>;
  goPickUpTimeIcon?: InputMaybe<AssetWhereInput>;
  goReturnDateIcon?: InputMaybe<AssetWhereInput>;
  goSearchLocationInformation?: InputMaybe<AssetWhereInput>;
  goSearchRentalPeriodCalendar?: InputMaybe<AssetWhereInput>;
  goSearchReturnIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoSearchOrderByInput {
  BookingWidgetTitleWebappAsc = 'bookingWidgetTitleWebapp_ASC',
  BookingWidgetTitleWebappDesc = 'bookingWidgetTitleWebapp_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  GOSearchDisplaySectionAsc = 'gOSearchDisplaySection_ASC',
  GOSearchDisplaySectionDesc = 'gOSearchDisplaySection_DESC',
  GoLocationPlaceholderTextAsc = 'goLocationPlaceholderText_ASC',
  GoLocationPlaceholderTextDesc = 'goLocationPlaceholderText_DESC',
  GoPickUpDateTimeNamesAsc = 'goPickUpDateTimeNames_ASC',
  GoPickUpDateTimeNamesDesc = 'goPickUpDateTimeNames_DESC',
  GoPickUpLocationAsc = 'goPickUpLocation_ASC',
  GoPickUpLocationDesc = 'goPickUpLocation_DESC',
  GoReturnLocationNameAsc = 'goReturnLocationName_ASC',
  GoReturnLocationNameDesc = 'goReturnLocationName_DESC',
  GoSearchButtonAsc = 'goSearchButton_ASC',
  GoSearchButtonDesc = 'goSearchButton_DESC',
  GoSearchLocationSameAsc = 'goSearchLocationSame_ASC',
  GoSearchLocationSameDesc = 'goSearchLocationSame_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LocationTitleAsc = 'locationTitle_ASC',
  LocationTitleDesc = 'locationTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoSearchUpdateInput = {
  /** bookingWidgetTitleWebapp input for default locale (de_CH) */
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  gOSearchDisplaySection?: InputMaybe<Scalars['String']>;
  goCurrentLocationIconActive?: InputMaybe<AssetUpdateOneInlineInput>;
  goCurrentLocationIconInactive?: InputMaybe<AssetUpdateOneInlineInput>;
  /** goLocationPlaceholderText input for default locale (de_CH) */
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  /** goPickUpDateTimeNames input for default locale (de_CH) */
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  goPickUpIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** goPickUpLocation input for default locale (de_CH) */
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  goPickUpReturnIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  goPickUpTimeIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  goReturnDateIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** goReturnLocationName input for default locale (de_CH) */
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  /** goSearchButton input for default locale (de_CH) */
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  goSearchLocationInformation?: InputMaybe<AssetUpdateOneInlineInput>;
  /** goSearchLocationSame input for default locale (de_CH) */
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  goSearchRentalPeriodCalendar?: InputMaybe<AssetUpdateOneInlineInput>;
  goSearchReturnIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoSearchUpdateLocalizationsInput>;
  /** locationTitle input for default locale (de_CH) */
  locationTitle?: InputMaybe<Scalars['String']>;
};

export type GoSearchUpdateLocalizationDataInput = {
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  locationTitle?: InputMaybe<Scalars['String']>;
};

export type GoSearchUpdateLocalizationInput = {
  data: GoSearchUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoSearchUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoSearchCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoSearchUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoSearchUpsertLocalizationInput>>;
};

export type GoSearchUpdateManyInlineInput = {
  /** Connect multiple existing GOSearch documents */
  connect?: InputMaybe<Array<GoSearchConnectInput>>;
  /** Create and connect multiple GOSearch documents */
  create?: InputMaybe<Array<GoSearchCreateInput>>;
  /** Delete multiple GOSearch documents */
  delete?: InputMaybe<Array<GoSearchWhereUniqueInput>>;
  /** Disconnect multiple GOSearch documents */
  disconnect?: InputMaybe<Array<GoSearchWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GOSearch documents */
  set?: InputMaybe<Array<GoSearchWhereUniqueInput>>;
  /** Update multiple GOSearch documents */
  update?: InputMaybe<Array<GoSearchUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GOSearch documents */
  upsert?: InputMaybe<Array<GoSearchUpsertWithNestedWhereUniqueInput>>;
};

export type GoSearchUpdateManyInput = {
  /** bookingWidgetTitleWebapp input for default locale (de_CH) */
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  /** goLocationPlaceholderText input for default locale (de_CH) */
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  /** goPickUpDateTimeNames input for default locale (de_CH) */
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  /** goPickUpLocation input for default locale (de_CH) */
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  /** goReturnLocationName input for default locale (de_CH) */
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  /** goSearchButton input for default locale (de_CH) */
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  /** goSearchLocationSame input for default locale (de_CH) */
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoSearchUpdateManyLocalizationsInput>;
  /** locationTitle input for default locale (de_CH) */
  locationTitle?: InputMaybe<Scalars['String']>;
};

export type GoSearchUpdateManyLocalizationDataInput = {
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  locationTitle?: InputMaybe<Scalars['String']>;
};

export type GoSearchUpdateManyLocalizationInput = {
  data: GoSearchUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoSearchUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoSearchUpdateManyLocalizationInput>>;
};

export type GoSearchUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoSearchUpdateManyInput;
  /** Document search */
  where: GoSearchWhereInput;
};

export type GoSearchUpdateOneInlineInput = {
  /** Connect existing GOSearch document */
  connect?: InputMaybe<GoSearchWhereUniqueInput>;
  /** Create and connect one GOSearch document */
  create?: InputMaybe<GoSearchCreateInput>;
  /** Delete currently connected GOSearch document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GOSearch document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GOSearch document */
  update?: InputMaybe<GoSearchUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GOSearch document */
  upsert?: InputMaybe<GoSearchUpsertWithNestedWhereUniqueInput>;
};

export type GoSearchUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoSearchUpdateInput;
  /** Unique document search */
  where: GoSearchWhereUniqueInput;
};

export type GoSearchUpsertInput = {
  /** Create document if it didn't exist */
  create: GoSearchCreateInput;
  /** Update document if it exists */
  update: GoSearchUpdateInput;
};

export type GoSearchUpsertLocalizationInput = {
  create: GoSearchCreateLocalizationDataInput;
  locale: Locale;
  update: GoSearchUpdateLocalizationDataInput;
};

export type GoSearchUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoSearchUpsertInput;
  /** Unique document search */
  where: GoSearchWhereUniqueInput;
};

/** Identifies documents */
export type GoSearchWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoSearchWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoSearchWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoSearchWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bookingWidgetTitleWebapp?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bookingWidgetTitleWebapp_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bookingWidgetTitleWebapp_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bookingWidgetTitleWebapp_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  bookingWidgetTitleWebapp_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bookingWidgetTitleWebapp_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bookingWidgetTitleWebapp_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bookingWidgetTitleWebapp_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bookingWidgetTitleWebapp_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bookingWidgetTitleWebapp_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  gOSearchDisplaySection?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  gOSearchDisplaySection_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  gOSearchDisplaySection_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  gOSearchDisplaySection_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  gOSearchDisplaySection_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  gOSearchDisplaySection_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  gOSearchDisplaySection_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  gOSearchDisplaySection_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  gOSearchDisplaySection_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  gOSearchDisplaySection_starts_with?: InputMaybe<Scalars['String']>;
  goCurrentLocationIconActive?: InputMaybe<AssetWhereInput>;
  goCurrentLocationIconInactive?: InputMaybe<AssetWhereInput>;
  goLocationPlaceholderText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goLocationPlaceholderText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goLocationPlaceholderText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goLocationPlaceholderText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goLocationPlaceholderText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goLocationPlaceholderText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goLocationPlaceholderText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goLocationPlaceholderText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goLocationPlaceholderText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goLocationPlaceholderText_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  goPickUpDateTimeNames?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  goPickUpDateTimeNames_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  goPickUpDateTimeNames_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  goPickUpDateTimeNames_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  goPickUpDateTimeNames_not?: InputMaybe<Array<Scalars['String']>>;
  goPickUpIcon?: InputMaybe<AssetWhereInput>;
  goPickUpLocation?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goPickUpLocation_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goPickUpLocation_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goPickUpLocation_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goPickUpLocation_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goPickUpLocation_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goPickUpLocation_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goPickUpLocation_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goPickUpLocation_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goPickUpLocation_starts_with?: InputMaybe<Scalars['String']>;
  goPickUpReturnIcon?: InputMaybe<AssetWhereInput>;
  goPickUpTimeIcon?: InputMaybe<AssetWhereInput>;
  goReturnDateIcon?: InputMaybe<AssetWhereInput>;
  goReturnLocationName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goReturnLocationName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goReturnLocationName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goReturnLocationName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goReturnLocationName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goReturnLocationName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goReturnLocationName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goReturnLocationName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goReturnLocationName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goReturnLocationName_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  goSearchButton?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  goSearchButton_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  goSearchButton_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  goSearchButton_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  goSearchButton_not?: InputMaybe<Array<Scalars['String']>>;
  goSearchLocationInformation?: InputMaybe<AssetWhereInput>;
  goSearchLocationSame?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goSearchLocationSame_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goSearchLocationSame_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goSearchLocationSame_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goSearchLocationSame_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goSearchLocationSame_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goSearchLocationSame_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goSearchLocationSame_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goSearchLocationSame_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goSearchLocationSame_starts_with?: InputMaybe<Scalars['String']>;
  goSearchRentalPeriodCalendar?: InputMaybe<AssetWhereInput>;
  goSearchReturnIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  locationTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  locationTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  locationTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  locationTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  locationTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  locationTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  locationTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  locationTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  locationTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GOSearch record uniquely */
export type GoSearchWhereUniqueInput = {
  gOSearchDisplaySection?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourney = Node & {
  __typename?: 'GoBookingJourney';
  /** This is showing the availability of either classes or cars. */
  availability?: Maybe<Scalars['String']>;
  /** Depending on the book now or later journey, we need to reference to either ALL classes or ALL models. Important: Always reference to all records. */
  availableCarClasses: Array<LocationTabAvailableCarClasses>;
  bookingJourney?: Maybe<BookingWorkflow>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoBookingJourney>;
  /** List of GoBookingJourney versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoBookingJourney>;
  /** This is the tab that shows the location overview */
  locationOverview?: Maybe<Scalars['String']>;
  /** This is the text that populates the CTA button during the book now journey if no cars are available at the selected location.  */
  noCarsButton?: Maybe<Scalars['String']>;
  /** This is the header shown if there are no available cars at a location */
  noCarsHeader?: Maybe<Scalars['String']>;
  /** This is the illustration or image that is displayed when there are no available cars at a location.  */
  noCarsImage?: Maybe<Asset>;
  /** This is the paragraph text that is displayed in the available cars section if there are no cars currently available at that location */
  noCarsText?: Maybe<Scalars['String']>;
  /** This is the header above the explanation paragraph text  */
  noCarsTextHeader?: Maybe<Scalars['String']>;
  /**
   * This is the price per day button text that follows the price of selected subclass or unit depending on the booking journey.
   * eg. 41.05 'CHF | Day'
   *
   */
  priceButtonText?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyAvailableCarClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyNoCarsImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/**
 * The GO booking journey comes after 'GO Search', but before 'GO Selection' schemas. It is defined by the pickup date/time:
 * - within the next 24 hours = book now
 * - after 24 hours from now = book later
 */
export type GoBookingJourneyUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoBookingJourneyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoBookingJourneyWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoBookingJourneyConnection = {
  __typename?: 'GoBookingJourneyConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoBookingJourneyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoBookingJourneyCreateInput = {
  /** availability input for default locale (de_CH) */
  availability?: InputMaybe<Scalars['String']>;
  availableCarClasses?: InputMaybe<LocationTabAvailableCarClassesCreateManyInlineInput>;
  bookingJourney?: InputMaybe<BookingWorkflow>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoBookingJourneyCreateLocalizationsInput>;
  /** locationOverview input for default locale (de_CH) */
  locationOverview?: InputMaybe<Scalars['String']>;
  /** noCarsButton input for default locale (de_CH) */
  noCarsButton?: InputMaybe<Scalars['String']>;
  /** noCarsHeader input for default locale (de_CH) */
  noCarsHeader?: InputMaybe<Scalars['String']>;
  noCarsImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** noCarsText input for default locale (de_CH) */
  noCarsText?: InputMaybe<Scalars['String']>;
  /** noCarsTextHeader input for default locale (de_CH) */
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  /** priceButtonText input for default locale (de_CH) */
  priceButtonText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoBookingJourneyCreateLocalizationDataInput = {
  availability?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  locationOverview?: InputMaybe<Scalars['String']>;
  noCarsButton?: InputMaybe<Scalars['String']>;
  noCarsHeader?: InputMaybe<Scalars['String']>;
  noCarsText?: InputMaybe<Scalars['String']>;
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  priceButtonText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoBookingJourneyCreateLocalizationInput = {
  /** Localization input */
  data: GoBookingJourneyCreateLocalizationDataInput;
  locale: Locale;
};

export type GoBookingJourneyCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoBookingJourneyCreateLocalizationInput>>;
};

export type GoBookingJourneyCreateManyInlineInput = {
  /** Connect multiple existing GoBookingJourney documents */
  connect?: InputMaybe<Array<GoBookingJourneyWhereUniqueInput>>;
  /** Create and connect multiple existing GoBookingJourney documents */
  create?: InputMaybe<Array<GoBookingJourneyCreateInput>>;
};

export type GoBookingJourneyCreateOneInlineInput = {
  /** Connect one existing GoBookingJourney document */
  connect?: InputMaybe<GoBookingJourneyWhereUniqueInput>;
  /** Create and connect one GoBookingJourney document */
  create?: InputMaybe<GoBookingJourneyCreateInput>;
};

/** An edge in a connection. */
export type GoBookingJourneyEdge = {
  __typename?: 'GoBookingJourneyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoBookingJourney;
};

/** Identifies documents */
export type GoBookingJourneyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoBookingJourneyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoBookingJourneyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoBookingJourneyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** All values that are contained in given list. */
  bookingJourney_in?: InputMaybe<Array<BookingWorkflow>>;
  /** All values that are not equal to given value. */
  bookingJourney_not?: InputMaybe<BookingWorkflow>;
  /** All values that are not contained in given list. */
  bookingJourney_not_in?: InputMaybe<Array<BookingWorkflow>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  noCarsImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoBookingJourneyOrderByInput {
  AvailabilityAsc = 'availability_ASC',
  AvailabilityDesc = 'availability_DESC',
  BookingJourneyAsc = 'bookingJourney_ASC',
  BookingJourneyDesc = 'bookingJourney_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LocationOverviewAsc = 'locationOverview_ASC',
  LocationOverviewDesc = 'locationOverview_DESC',
  NoCarsButtonAsc = 'noCarsButton_ASC',
  NoCarsButtonDesc = 'noCarsButton_DESC',
  NoCarsHeaderAsc = 'noCarsHeader_ASC',
  NoCarsHeaderDesc = 'noCarsHeader_DESC',
  NoCarsTextHeaderAsc = 'noCarsTextHeader_ASC',
  NoCarsTextHeaderDesc = 'noCarsTextHeader_DESC',
  NoCarsTextAsc = 'noCarsText_ASC',
  NoCarsTextDesc = 'noCarsText_DESC',
  PriceButtonTextAsc = 'priceButtonText_ASC',
  PriceButtonTextDesc = 'priceButtonText_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoBookingJourneyUpdateInput = {
  /** availability input for default locale (de_CH) */
  availability?: InputMaybe<Scalars['String']>;
  availableCarClasses?: InputMaybe<LocationTabAvailableCarClassesUpdateManyInlineInput>;
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoBookingJourneyUpdateLocalizationsInput>;
  /** locationOverview input for default locale (de_CH) */
  locationOverview?: InputMaybe<Scalars['String']>;
  /** noCarsButton input for default locale (de_CH) */
  noCarsButton?: InputMaybe<Scalars['String']>;
  /** noCarsHeader input for default locale (de_CH) */
  noCarsHeader?: InputMaybe<Scalars['String']>;
  noCarsImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** noCarsText input for default locale (de_CH) */
  noCarsText?: InputMaybe<Scalars['String']>;
  /** noCarsTextHeader input for default locale (de_CH) */
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  /** priceButtonText input for default locale (de_CH) */
  priceButtonText?: InputMaybe<Scalars['String']>;
};

export type GoBookingJourneyUpdateLocalizationDataInput = {
  availability?: InputMaybe<Scalars['String']>;
  locationOverview?: InputMaybe<Scalars['String']>;
  noCarsButton?: InputMaybe<Scalars['String']>;
  noCarsHeader?: InputMaybe<Scalars['String']>;
  noCarsText?: InputMaybe<Scalars['String']>;
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  priceButtonText?: InputMaybe<Scalars['String']>;
};

export type GoBookingJourneyUpdateLocalizationInput = {
  data: GoBookingJourneyUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoBookingJourneyUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoBookingJourneyCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoBookingJourneyUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoBookingJourneyUpsertLocalizationInput>>;
};

export type GoBookingJourneyUpdateManyInlineInput = {
  /** Connect multiple existing GoBookingJourney documents */
  connect?: InputMaybe<Array<GoBookingJourneyConnectInput>>;
  /** Create and connect multiple GoBookingJourney documents */
  create?: InputMaybe<Array<GoBookingJourneyCreateInput>>;
  /** Delete multiple GoBookingJourney documents */
  delete?: InputMaybe<Array<GoBookingJourneyWhereUniqueInput>>;
  /** Disconnect multiple GoBookingJourney documents */
  disconnect?: InputMaybe<Array<GoBookingJourneyWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GoBookingJourney documents */
  set?: InputMaybe<Array<GoBookingJourneyWhereUniqueInput>>;
  /** Update multiple GoBookingJourney documents */
  update?: InputMaybe<Array<GoBookingJourneyUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GoBookingJourney documents */
  upsert?: InputMaybe<Array<GoBookingJourneyUpsertWithNestedWhereUniqueInput>>;
};

export type GoBookingJourneyUpdateManyInput = {
  /** availability input for default locale (de_CH) */
  availability?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoBookingJourneyUpdateManyLocalizationsInput>;
  /** locationOverview input for default locale (de_CH) */
  locationOverview?: InputMaybe<Scalars['String']>;
  /** noCarsButton input for default locale (de_CH) */
  noCarsButton?: InputMaybe<Scalars['String']>;
  /** noCarsHeader input for default locale (de_CH) */
  noCarsHeader?: InputMaybe<Scalars['String']>;
  /** noCarsText input for default locale (de_CH) */
  noCarsText?: InputMaybe<Scalars['String']>;
  /** noCarsTextHeader input for default locale (de_CH) */
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  /** priceButtonText input for default locale (de_CH) */
  priceButtonText?: InputMaybe<Scalars['String']>;
};

export type GoBookingJourneyUpdateManyLocalizationDataInput = {
  availability?: InputMaybe<Scalars['String']>;
  locationOverview?: InputMaybe<Scalars['String']>;
  noCarsButton?: InputMaybe<Scalars['String']>;
  noCarsHeader?: InputMaybe<Scalars['String']>;
  noCarsText?: InputMaybe<Scalars['String']>;
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  priceButtonText?: InputMaybe<Scalars['String']>;
};

export type GoBookingJourneyUpdateManyLocalizationInput = {
  data: GoBookingJourneyUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoBookingJourneyUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoBookingJourneyUpdateManyLocalizationInput>>;
};

export type GoBookingJourneyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoBookingJourneyUpdateManyInput;
  /** Document search */
  where: GoBookingJourneyWhereInput;
};

export type GoBookingJourneyUpdateOneInlineInput = {
  /** Connect existing GoBookingJourney document */
  connect?: InputMaybe<GoBookingJourneyWhereUniqueInput>;
  /** Create and connect one GoBookingJourney document */
  create?: InputMaybe<GoBookingJourneyCreateInput>;
  /** Delete currently connected GoBookingJourney document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GoBookingJourney document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GoBookingJourney document */
  update?: InputMaybe<GoBookingJourneyUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GoBookingJourney document */
  upsert?: InputMaybe<GoBookingJourneyUpsertWithNestedWhereUniqueInput>;
};

export type GoBookingJourneyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoBookingJourneyUpdateInput;
  /** Unique document search */
  where: GoBookingJourneyWhereUniqueInput;
};

export type GoBookingJourneyUpsertInput = {
  /** Create document if it didn't exist */
  create: GoBookingJourneyCreateInput;
  /** Update document if it exists */
  update: GoBookingJourneyUpdateInput;
};

export type GoBookingJourneyUpsertLocalizationInput = {
  create: GoBookingJourneyCreateLocalizationDataInput;
  locale: Locale;
  update: GoBookingJourneyUpdateLocalizationDataInput;
};

export type GoBookingJourneyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoBookingJourneyUpsertInput;
  /** Unique document search */
  where: GoBookingJourneyWhereUniqueInput;
};

/** Identifies documents */
export type GoBookingJourneyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoBookingJourneyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoBookingJourneyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoBookingJourneyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  availability?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  availability_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  availability_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  availability_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  availability_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  availability_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  availability_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  availability_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  availability_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  availability_starts_with?: InputMaybe<Scalars['String']>;
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** All values that are contained in given list. */
  bookingJourney_in?: InputMaybe<Array<BookingWorkflow>>;
  /** All values that are not equal to given value. */
  bookingJourney_not?: InputMaybe<BookingWorkflow>;
  /** All values that are not contained in given list. */
  bookingJourney_not_in?: InputMaybe<Array<BookingWorkflow>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationOverview?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  locationOverview_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  locationOverview_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  locationOverview_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  locationOverview_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  locationOverview_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  locationOverview_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  locationOverview_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  locationOverview_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  locationOverview_starts_with?: InputMaybe<Scalars['String']>;
  noCarsButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCarsButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCarsButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCarsButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCarsButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCarsButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCarsButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCarsButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCarsButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCarsButton_starts_with?: InputMaybe<Scalars['String']>;
  noCarsHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCarsHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCarsHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCarsHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCarsHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCarsHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCarsHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCarsHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCarsHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCarsHeader_starts_with?: InputMaybe<Scalars['String']>;
  noCarsImage?: InputMaybe<AssetWhereInput>;
  noCarsText?: InputMaybe<Scalars['String']>;
  noCarsTextHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCarsTextHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCarsTextHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCarsTextHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCarsTextHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCarsTextHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCarsTextHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCarsTextHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCarsTextHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCarsTextHeader_starts_with?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCarsText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCarsText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCarsText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCarsText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCarsText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCarsText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCarsText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCarsText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCarsText_starts_with?: InputMaybe<Scalars['String']>;
  priceButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceButtonText_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GoBookingJourney record uniquely */
export type GoBookingJourneyWhereUniqueInput = {
  bookingJourney?: InputMaybe<BookingWorkflow>;
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelection = Node & {
  __typename?: 'GoSelection';
  /** This is either the booking now or booking later journey. */
  bookingJourney?: Maybe<BookingWorkflow>;
  /** This is the 'change' button next to the credit card, to allow a change of the payment method. */
  changePaymentMethod?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoSelection>;
  /** User selects the extras Group, depending on the Group, extras are shown. */
  extrasGroup?: Maybe<ExtrasGroup>;
  /** This is the CTA button on the bottom of the screen. */
  goSelectionButton?: Maybe<Scalars['String']>;
  /** This is the header of the coverage type; for example: 'Coverage Level' */
  goSelectionCoverageName?: Maybe<Scalars['String']>;
  /** Screen Order after the GO Search, before proceeding to payment screens. */
  goSelectionScreenOrder?: Maybe<Scalars['Int']>;
  /** List of GoSelection versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** These are the static features that are included as a further value for the GO User. */
  includedRentalFeatures: Array<GoSelectionIncludedRentalFeaturesRichText>;
  /**
   * These are the included rental feature icons that come before the descriptive text. Each icon comes with a corresponding description. These icons must be ordered in the EXACT same order as their corresponding feature description.
   *
   * EG. [icon] tick (#1) [Description] unlimited mileage (#1)
   */
  includedRentalFeaturesIcon: Array<Asset>;
  /** The checkbox for the user to check that they confirm. After this checkbox is selected, user will be able to proceed. */
  legalCheckbox: Array<Asset>;
  /** This is the text corresponding to the legal checkbox. */
  legalConfirmation?: Maybe<Scalars['String']>;
  legals: Array<Legal>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoSelection>;
  /** This is the icon that shows the user which credit card company he/she is using. */
  paymentMethodIcon: Array<Asset>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** This is the title of the screen, so the user knows where she/he is. */
  screenTitle?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionIncludedRentalFeaturesIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionLegalCheckboxArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionLegalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LegalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegalWhereInput>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionPaymentMethodIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the user selection screen, showing all the selected information in a summary. */
export type GoSelectionUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoSelectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoSelectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoSelectionConnection = {
  __typename?: 'GoSelectionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoSelectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoSelectionCreateInput = {
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** changePaymentMethod input for default locale (de_CH) */
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** extrasGroup input for default locale (de_CH) */
  extrasGroup?: InputMaybe<ExtrasGroup>;
  /** goSelectionButton input for default locale (de_CH) */
  goSelectionButton?: InputMaybe<Scalars['String']>;
  /** goSelectionCoverageName input for default locale (de_CH) */
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  goSelectionScreenOrder?: InputMaybe<Scalars['Int']>;
  /** includedRentalFeatures input for default locale (de_CH) */
  includedRentalFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  includedRentalFeaturesIcon?: InputMaybe<AssetCreateManyInlineInput>;
  legalCheckbox?: InputMaybe<AssetCreateManyInlineInput>;
  /** legalConfirmation input for default locale (de_CH) */
  legalConfirmation?: InputMaybe<Scalars['String']>;
  legals?: InputMaybe<LegalCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoSelectionCreateLocalizationsInput>;
  paymentMethodIcon?: InputMaybe<AssetCreateManyInlineInput>;
  /** screenTitle input for default locale (de_CH) */
  screenTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoSelectionCreateLocalizationDataInput = {
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  extrasGroup?: InputMaybe<ExtrasGroup>;
  goSelectionButton?: InputMaybe<Scalars['String']>;
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  includedRentalFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  screenTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoSelectionCreateLocalizationInput = {
  /** Localization input */
  data: GoSelectionCreateLocalizationDataInput;
  locale: Locale;
};

export type GoSelectionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoSelectionCreateLocalizationInput>>;
};

export type GoSelectionCreateManyInlineInput = {
  /** Connect multiple existing GoSelection documents */
  connect?: InputMaybe<Array<GoSelectionWhereUniqueInput>>;
  /** Create and connect multiple existing GoSelection documents */
  create?: InputMaybe<Array<GoSelectionCreateInput>>;
};

export type GoSelectionCreateOneInlineInput = {
  /** Connect one existing GoSelection document */
  connect?: InputMaybe<GoSelectionWhereUniqueInput>;
  /** Create and connect one GoSelection document */
  create?: InputMaybe<GoSelectionCreateInput>;
};

/** An edge in a connection. */
export type GoSelectionEdge = {
  __typename?: 'GoSelectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoSelection;
};

export type GoSelectionIncludedRentalFeaturesRichText = {
  __typename?: 'GoSelectionIncludedRentalFeaturesRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<GoSelectionIncludedRentalFeaturesRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type GoSelectionIncludedRentalFeaturesRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type GoSelectionIncludedRentalFeaturesRichTextEmbeddedTypes = Asset;

/** Identifies documents */
export type GoSelectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoSelectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoSelectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoSelectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** All values that are contained in given list. */
  bookingJourney_in?: InputMaybe<Array<BookingWorkflow>>;
  /** All values that are not equal to given value. */
  bookingJourney_not?: InputMaybe<BookingWorkflow>;
  /** All values that are not contained in given list. */
  bookingJourney_not_in?: InputMaybe<Array<BookingWorkflow>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  goSelectionScreenOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  goSelectionScreenOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  goSelectionScreenOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  goSelectionScreenOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  goSelectionScreenOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  goSelectionScreenOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  goSelectionScreenOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  goSelectionScreenOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  includedRentalFeaturesIcon_every?: InputMaybe<AssetWhereInput>;
  includedRentalFeaturesIcon_none?: InputMaybe<AssetWhereInput>;
  includedRentalFeaturesIcon_some?: InputMaybe<AssetWhereInput>;
  legalCheckbox_every?: InputMaybe<AssetWhereInput>;
  legalCheckbox_none?: InputMaybe<AssetWhereInput>;
  legalCheckbox_some?: InputMaybe<AssetWhereInput>;
  legals_every?: InputMaybe<LegalWhereInput>;
  legals_none?: InputMaybe<LegalWhereInput>;
  legals_some?: InputMaybe<LegalWhereInput>;
  paymentMethodIcon_every?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_none?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_some?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoSelectionOrderByInput {
  BookingJourneyAsc = 'bookingJourney_ASC',
  BookingJourneyDesc = 'bookingJourney_DESC',
  ChangePaymentMethodAsc = 'changePaymentMethod_ASC',
  ChangePaymentMethodDesc = 'changePaymentMethod_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExtrasGroupAsc = 'extrasGroup_ASC',
  ExtrasGroupDesc = 'extrasGroup_DESC',
  GoSelectionButtonAsc = 'goSelectionButton_ASC',
  GoSelectionButtonDesc = 'goSelectionButton_DESC',
  GoSelectionCoverageNameAsc = 'goSelectionCoverageName_ASC',
  GoSelectionCoverageNameDesc = 'goSelectionCoverageName_DESC',
  GoSelectionScreenOrderAsc = 'goSelectionScreenOrder_ASC',
  GoSelectionScreenOrderDesc = 'goSelectionScreenOrder_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LegalConfirmationAsc = 'legalConfirmation_ASC',
  LegalConfirmationDesc = 'legalConfirmation_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ScreenTitleAsc = 'screenTitle_ASC',
  ScreenTitleDesc = 'screenTitle_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoSelectionUpdateInput = {
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** changePaymentMethod input for default locale (de_CH) */
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  /** extrasGroup input for default locale (de_CH) */
  extrasGroup?: InputMaybe<ExtrasGroup>;
  /** goSelectionButton input for default locale (de_CH) */
  goSelectionButton?: InputMaybe<Scalars['String']>;
  /** goSelectionCoverageName input for default locale (de_CH) */
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  goSelectionScreenOrder?: InputMaybe<Scalars['Int']>;
  /** includedRentalFeatures input for default locale (de_CH) */
  includedRentalFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  includedRentalFeaturesIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  legalCheckbox?: InputMaybe<AssetUpdateManyInlineInput>;
  /** legalConfirmation input for default locale (de_CH) */
  legalConfirmation?: InputMaybe<Scalars['String']>;
  legals?: InputMaybe<LegalUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoSelectionUpdateLocalizationsInput>;
  paymentMethodIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** screenTitle input for default locale (de_CH) */
  screenTitle?: InputMaybe<Scalars['String']>;
};

export type GoSelectionUpdateLocalizationDataInput = {
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  extrasGroup?: InputMaybe<ExtrasGroup>;
  goSelectionButton?: InputMaybe<Scalars['String']>;
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  includedRentalFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  screenTitle?: InputMaybe<Scalars['String']>;
};

export type GoSelectionUpdateLocalizationInput = {
  data: GoSelectionUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoSelectionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoSelectionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoSelectionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoSelectionUpsertLocalizationInput>>;
};

export type GoSelectionUpdateManyInlineInput = {
  /** Connect multiple existing GoSelection documents */
  connect?: InputMaybe<Array<GoSelectionConnectInput>>;
  /** Create and connect multiple GoSelection documents */
  create?: InputMaybe<Array<GoSelectionCreateInput>>;
  /** Delete multiple GoSelection documents */
  delete?: InputMaybe<Array<GoSelectionWhereUniqueInput>>;
  /** Disconnect multiple GoSelection documents */
  disconnect?: InputMaybe<Array<GoSelectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GoSelection documents */
  set?: InputMaybe<Array<GoSelectionWhereUniqueInput>>;
  /** Update multiple GoSelection documents */
  update?: InputMaybe<Array<GoSelectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GoSelection documents */
  upsert?: InputMaybe<Array<GoSelectionUpsertWithNestedWhereUniqueInput>>;
};

export type GoSelectionUpdateManyInput = {
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** changePaymentMethod input for default locale (de_CH) */
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  /** extrasGroup input for default locale (de_CH) */
  extrasGroup?: InputMaybe<ExtrasGroup>;
  /** goSelectionButton input for default locale (de_CH) */
  goSelectionButton?: InputMaybe<Scalars['String']>;
  /** goSelectionCoverageName input for default locale (de_CH) */
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  goSelectionScreenOrder?: InputMaybe<Scalars['Int']>;
  /** includedRentalFeatures input for default locale (de_CH) */
  includedRentalFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** legalConfirmation input for default locale (de_CH) */
  legalConfirmation?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoSelectionUpdateManyLocalizationsInput>;
  /** screenTitle input for default locale (de_CH) */
  screenTitle?: InputMaybe<Scalars['String']>;
};

export type GoSelectionUpdateManyLocalizationDataInput = {
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  extrasGroup?: InputMaybe<ExtrasGroup>;
  goSelectionButton?: InputMaybe<Scalars['String']>;
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  includedRentalFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  screenTitle?: InputMaybe<Scalars['String']>;
};

export type GoSelectionUpdateManyLocalizationInput = {
  data: GoSelectionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoSelectionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoSelectionUpdateManyLocalizationInput>>;
};

export type GoSelectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoSelectionUpdateManyInput;
  /** Document search */
  where: GoSelectionWhereInput;
};

export type GoSelectionUpdateOneInlineInput = {
  /** Connect existing GoSelection document */
  connect?: InputMaybe<GoSelectionWhereUniqueInput>;
  /** Create and connect one GoSelection document */
  create?: InputMaybe<GoSelectionCreateInput>;
  /** Delete currently connected GoSelection document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GoSelection document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GoSelection document */
  update?: InputMaybe<GoSelectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GoSelection document */
  upsert?: InputMaybe<GoSelectionUpsertWithNestedWhereUniqueInput>;
};

export type GoSelectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoSelectionUpdateInput;
  /** Unique document search */
  where: GoSelectionWhereUniqueInput;
};

export type GoSelectionUpsertInput = {
  /** Create document if it didn't exist */
  create: GoSelectionCreateInput;
  /** Update document if it exists */
  update: GoSelectionUpdateInput;
};

export type GoSelectionUpsertLocalizationInput = {
  create: GoSelectionCreateLocalizationDataInput;
  locale: Locale;
  update: GoSelectionUpdateLocalizationDataInput;
};

export type GoSelectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoSelectionUpsertInput;
  /** Unique document search */
  where: GoSelectionWhereUniqueInput;
};

/** Identifies documents */
export type GoSelectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoSelectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoSelectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoSelectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bookingJourney?: InputMaybe<BookingWorkflow>;
  /** All values that are contained in given list. */
  bookingJourney_in?: InputMaybe<Array<BookingWorkflow>>;
  /** All values that are not equal to given value. */
  bookingJourney_not?: InputMaybe<BookingWorkflow>;
  /** All values that are not contained in given list. */
  bookingJourney_not_in?: InputMaybe<Array<BookingWorkflow>>;
  changePaymentMethod?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  changePaymentMethod_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  changePaymentMethod_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  changePaymentMethod_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  changePaymentMethod_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  changePaymentMethod_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  changePaymentMethod_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  changePaymentMethod_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  changePaymentMethod_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  changePaymentMethod_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  extrasGroup?: InputMaybe<ExtrasGroup>;
  /** All values that are contained in given list. */
  extrasGroup_in?: InputMaybe<Array<ExtrasGroup>>;
  /** All values that are not equal to given value. */
  extrasGroup_not?: InputMaybe<ExtrasGroup>;
  /** All values that are not contained in given list. */
  extrasGroup_not_in?: InputMaybe<Array<ExtrasGroup>>;
  goSelectionButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goSelectionButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goSelectionButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goSelectionButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goSelectionButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goSelectionButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goSelectionButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goSelectionButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goSelectionButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goSelectionButton_starts_with?: InputMaybe<Scalars['String']>;
  goSelectionCoverageName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  goSelectionCoverageName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  goSelectionCoverageName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  goSelectionCoverageName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  goSelectionCoverageName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  goSelectionCoverageName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  goSelectionCoverageName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  goSelectionCoverageName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  goSelectionCoverageName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  goSelectionCoverageName_starts_with?: InputMaybe<Scalars['String']>;
  goSelectionScreenOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  goSelectionScreenOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  goSelectionScreenOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  goSelectionScreenOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  goSelectionScreenOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  goSelectionScreenOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  goSelectionScreenOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  goSelectionScreenOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  includedRentalFeaturesIcon_every?: InputMaybe<AssetWhereInput>;
  includedRentalFeaturesIcon_none?: InputMaybe<AssetWhereInput>;
  includedRentalFeaturesIcon_some?: InputMaybe<AssetWhereInput>;
  legalCheckbox_every?: InputMaybe<AssetWhereInput>;
  legalCheckbox_none?: InputMaybe<AssetWhereInput>;
  legalCheckbox_some?: InputMaybe<AssetWhereInput>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  legalConfirmation_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  legalConfirmation_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  legalConfirmation_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  legalConfirmation_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  legalConfirmation_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  legalConfirmation_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  legalConfirmation_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  legalConfirmation_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  legalConfirmation_starts_with?: InputMaybe<Scalars['String']>;
  legals_every?: InputMaybe<LegalWhereInput>;
  legals_none?: InputMaybe<LegalWhereInput>;
  legals_some?: InputMaybe<LegalWhereInput>;
  paymentMethodIcon_every?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_none?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_some?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  screenTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  screenTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  screenTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  screenTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  screenTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  screenTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  screenTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  screenTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  screenTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  screenTitle_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GoSelection record uniquely */
export type GoSelectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverview = Node & {
  __typename?: 'GoWebappBookingOverview';
  /** This is the title of the coverage section of the selection overview */
  coverageTitle?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /**
   * This is the text that populates the continue button in the booking overview
   *
   * EG. Continue
   */
  ctaButtonText?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<GoWebappBookingOverview>;
  /** This the the title to the extras section of the selection overview */
  extrasTitle?: Maybe<Scalars['String']>;
  /** List of GoWebappBookingOverview versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoWebappBookingOverview>;
  /** This is the information icon that is to the right of all locations and opens the location overview.  */
  locationInformationIcon?: Maybe<Asset>;
  /**
   * This is the total price text title
   *
   * EG. Total Price
   */
  overviewTotalPriceTitle?: Maybe<Scalars['String']>;
  /** This the the pick up location icon that is displayed if the pick up location is different to the return location.  */
  pickUpLocationIcon?: Maybe<Asset>;
  /** This is the text below the total price that opens the price Breakdown */
  priceBreakdownText?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** This is the icon for the rental location if the return and pickup location are the same  */
  rentalLocationIcon?: Maybe<Asset>;
  /**
   * This is the localization for the amount of days in a rental. the first in this list should be utilised for a single day's rental. the second for mutliple
   *
   * eg 8 'days' - 1 'day'
   */
  rentalPeriodDays: Array<Scalars['String']>;
  /** This is the icon that is displayed to show the length of the rental period */
  rentalPeriodIcon?: Maybe<Asset>;
  /** This is the icon that is displayed if a rental has a different return location to the selected pick up location */
  rentalReturnIcon?: Maybe<Asset>;
  /** This is the title of the of the rental pick up, return and locations */
  rentalTitle?: Maybe<Scalars['String']>;
  scheduledIn: Array<ScheduledOperation>;
  /** This is the title of the selection card */
  selectionTitle?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewLocationInformationIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewPickUpLocationIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewRentalLocationIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewRentalPeriodIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewRentalReturnIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the booking overview displayed in the desktop version of the Enterprise GO webapp.  */
export type GoWebappBookingOverviewUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoWebappBookingOverviewConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoWebappBookingOverviewWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoWebappBookingOverviewConnection = {
  __typename?: 'GoWebappBookingOverviewConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoWebappBookingOverviewEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoWebappBookingOverviewCreateInput = {
  /** coverageTitle input for default locale (de_CH) */
  coverageTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** ctaButtonText input for default locale (de_CH) */
  ctaButtonText?: InputMaybe<Scalars['String']>;
  /** extrasTitle input for default locale (de_CH) */
  extrasTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoWebappBookingOverviewCreateLocalizationsInput>;
  locationInformationIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** overviewTotalPriceTitle input for default locale (de_CH) */
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  pickUpLocationIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** priceBreakdownText input for default locale (de_CH) */
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  rentalLocationIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** rentalPeriodDays input for default locale (de_CH) */
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  rentalPeriodIcon?: InputMaybe<AssetCreateOneInlineInput>;
  rentalReturnIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** rentalTitle input for default locale (de_CH) */
  rentalTitle?: InputMaybe<Scalars['String']>;
  /** selectionTitle input for default locale (de_CH) */
  selectionTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoWebappBookingOverviewCreateLocalizationDataInput = {
  coverageTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonText?: InputMaybe<Scalars['String']>;
  extrasTitle?: InputMaybe<Scalars['String']>;
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  rentalTitle?: InputMaybe<Scalars['String']>;
  selectionTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoWebappBookingOverviewCreateLocalizationInput = {
  /** Localization input */
  data: GoWebappBookingOverviewCreateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappBookingOverviewCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoWebappBookingOverviewCreateLocalizationInput>>;
};

export type GoWebappBookingOverviewCreateManyInlineInput = {
  /** Connect multiple existing GoWebappBookingOverview documents */
  connect?: InputMaybe<Array<GoWebappBookingOverviewWhereUniqueInput>>;
  /** Create and connect multiple existing GoWebappBookingOverview documents */
  create?: InputMaybe<Array<GoWebappBookingOverviewCreateInput>>;
};

export type GoWebappBookingOverviewCreateOneInlineInput = {
  /** Connect one existing GoWebappBookingOverview document */
  connect?: InputMaybe<GoWebappBookingOverviewWhereUniqueInput>;
  /** Create and connect one GoWebappBookingOverview document */
  create?: InputMaybe<GoWebappBookingOverviewCreateInput>;
};

/** An edge in a connection. */
export type GoWebappBookingOverviewEdge = {
  __typename?: 'GoWebappBookingOverviewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoWebappBookingOverview;
};

/** Identifies documents */
export type GoWebappBookingOverviewManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappBookingOverviewWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappBookingOverviewWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappBookingOverviewWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationInformationIcon?: InputMaybe<AssetWhereInput>;
  pickUpLocationIcon?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentalLocationIcon?: InputMaybe<AssetWhereInput>;
  rentalPeriodIcon?: InputMaybe<AssetWhereInput>;
  rentalReturnIcon?: InputMaybe<AssetWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoWebappBookingOverviewOrderByInput {
  CoverageTitleAsc = 'coverageTitle_ASC',
  CoverageTitleDesc = 'coverageTitle_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CtaButtonTextAsc = 'ctaButtonText_ASC',
  CtaButtonTextDesc = 'ctaButtonText_DESC',
  ExtrasTitleAsc = 'extrasTitle_ASC',
  ExtrasTitleDesc = 'extrasTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OverviewTotalPriceTitleAsc = 'overviewTotalPriceTitle_ASC',
  OverviewTotalPriceTitleDesc = 'overviewTotalPriceTitle_DESC',
  PriceBreakdownTextAsc = 'priceBreakdownText_ASC',
  PriceBreakdownTextDesc = 'priceBreakdownText_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RentalPeriodDaysAsc = 'rentalPeriodDays_ASC',
  RentalPeriodDaysDesc = 'rentalPeriodDays_DESC',
  RentalTitleAsc = 'rentalTitle_ASC',
  RentalTitleDesc = 'rentalTitle_DESC',
  SelectionTitleAsc = 'selectionTitle_ASC',
  SelectionTitleDesc = 'selectionTitle_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoWebappBookingOverviewUpdateInput = {
  /** coverageTitle input for default locale (de_CH) */
  coverageTitle?: InputMaybe<Scalars['String']>;
  /** ctaButtonText input for default locale (de_CH) */
  ctaButtonText?: InputMaybe<Scalars['String']>;
  /** extrasTitle input for default locale (de_CH) */
  extrasTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoWebappBookingOverviewUpdateLocalizationsInput>;
  locationInformationIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** overviewTotalPriceTitle input for default locale (de_CH) */
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  pickUpLocationIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** priceBreakdownText input for default locale (de_CH) */
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  rentalLocationIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** rentalPeriodDays input for default locale (de_CH) */
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  rentalPeriodIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  rentalReturnIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** rentalTitle input for default locale (de_CH) */
  rentalTitle?: InputMaybe<Scalars['String']>;
  /** selectionTitle input for default locale (de_CH) */
  selectionTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappBookingOverviewUpdateLocalizationDataInput = {
  coverageTitle?: InputMaybe<Scalars['String']>;
  ctaButtonText?: InputMaybe<Scalars['String']>;
  extrasTitle?: InputMaybe<Scalars['String']>;
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  rentalTitle?: InputMaybe<Scalars['String']>;
  selectionTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappBookingOverviewUpdateLocalizationInput = {
  data: GoWebappBookingOverviewUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappBookingOverviewUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoWebappBookingOverviewCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappBookingOverviewUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoWebappBookingOverviewUpsertLocalizationInput>>;
};

export type GoWebappBookingOverviewUpdateManyInlineInput = {
  /** Connect multiple existing GoWebappBookingOverview documents */
  connect?: InputMaybe<Array<GoWebappBookingOverviewConnectInput>>;
  /** Create and connect multiple GoWebappBookingOverview documents */
  create?: InputMaybe<Array<GoWebappBookingOverviewCreateInput>>;
  /** Delete multiple GoWebappBookingOverview documents */
  delete?: InputMaybe<Array<GoWebappBookingOverviewWhereUniqueInput>>;
  /** Disconnect multiple GoWebappBookingOverview documents */
  disconnect?: InputMaybe<Array<GoWebappBookingOverviewWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GoWebappBookingOverview documents */
  set?: InputMaybe<Array<GoWebappBookingOverviewWhereUniqueInput>>;
  /** Update multiple GoWebappBookingOverview documents */
  update?: InputMaybe<Array<GoWebappBookingOverviewUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GoWebappBookingOverview documents */
  upsert?: InputMaybe<Array<GoWebappBookingOverviewUpsertWithNestedWhereUniqueInput>>;
};

export type GoWebappBookingOverviewUpdateManyInput = {
  /** coverageTitle input for default locale (de_CH) */
  coverageTitle?: InputMaybe<Scalars['String']>;
  /** ctaButtonText input for default locale (de_CH) */
  ctaButtonText?: InputMaybe<Scalars['String']>;
  /** extrasTitle input for default locale (de_CH) */
  extrasTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoWebappBookingOverviewUpdateManyLocalizationsInput>;
  /** overviewTotalPriceTitle input for default locale (de_CH) */
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  /** priceBreakdownText input for default locale (de_CH) */
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  /** rentalPeriodDays input for default locale (de_CH) */
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  /** rentalTitle input for default locale (de_CH) */
  rentalTitle?: InputMaybe<Scalars['String']>;
  /** selectionTitle input for default locale (de_CH) */
  selectionTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappBookingOverviewUpdateManyLocalizationDataInput = {
  coverageTitle?: InputMaybe<Scalars['String']>;
  ctaButtonText?: InputMaybe<Scalars['String']>;
  extrasTitle?: InputMaybe<Scalars['String']>;
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  rentalTitle?: InputMaybe<Scalars['String']>;
  selectionTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappBookingOverviewUpdateManyLocalizationInput = {
  data: GoWebappBookingOverviewUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoWebappBookingOverviewUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappBookingOverviewUpdateManyLocalizationInput>>;
};

export type GoWebappBookingOverviewUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoWebappBookingOverviewUpdateManyInput;
  /** Document search */
  where: GoWebappBookingOverviewWhereInput;
};

export type GoWebappBookingOverviewUpdateOneInlineInput = {
  /** Connect existing GoWebappBookingOverview document */
  connect?: InputMaybe<GoWebappBookingOverviewWhereUniqueInput>;
  /** Create and connect one GoWebappBookingOverview document */
  create?: InputMaybe<GoWebappBookingOverviewCreateInput>;
  /** Delete currently connected GoWebappBookingOverview document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GoWebappBookingOverview document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GoWebappBookingOverview document */
  update?: InputMaybe<GoWebappBookingOverviewUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GoWebappBookingOverview document */
  upsert?: InputMaybe<GoWebappBookingOverviewUpsertWithNestedWhereUniqueInput>;
};

export type GoWebappBookingOverviewUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoWebappBookingOverviewUpdateInput;
  /** Unique document search */
  where: GoWebappBookingOverviewWhereUniqueInput;
};

export type GoWebappBookingOverviewUpsertInput = {
  /** Create document if it didn't exist */
  create: GoWebappBookingOverviewCreateInput;
  /** Update document if it exists */
  update: GoWebappBookingOverviewUpdateInput;
};

export type GoWebappBookingOverviewUpsertLocalizationInput = {
  create: GoWebappBookingOverviewCreateLocalizationDataInput;
  locale: Locale;
  update: GoWebappBookingOverviewUpdateLocalizationDataInput;
};

export type GoWebappBookingOverviewUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoWebappBookingOverviewUpsertInput;
  /** Unique document search */
  where: GoWebappBookingOverviewWhereUniqueInput;
};

/** Identifies documents */
export type GoWebappBookingOverviewWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappBookingOverviewWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappBookingOverviewWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappBookingOverviewWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  coverageTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coverageTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coverageTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coverageTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coverageTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coverageTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coverageTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coverageTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coverageTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coverageTitle_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ctaButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ctaButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ctaButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  ctaButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ctaButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ctaButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ctaButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  ctaButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ctaButtonText_starts_with?: InputMaybe<Scalars['String']>;
  extrasTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  extrasTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  extrasTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  extrasTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  extrasTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  extrasTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  extrasTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  extrasTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  extrasTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  extrasTitle_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  locationInformationIcon?: InputMaybe<AssetWhereInput>;
  overviewTotalPriceTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  overviewTotalPriceTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  overviewTotalPriceTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  overviewTotalPriceTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  overviewTotalPriceTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  overviewTotalPriceTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  overviewTotalPriceTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  overviewTotalPriceTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  overviewTotalPriceTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  overviewTotalPriceTitle_starts_with?: InputMaybe<Scalars['String']>;
  pickUpLocationIcon?: InputMaybe<AssetWhereInput>;
  priceBreakdownText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceBreakdownText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceBreakdownText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceBreakdownText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceBreakdownText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceBreakdownText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceBreakdownText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceBreakdownText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceBreakdownText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceBreakdownText_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentalLocationIcon?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  rentalPeriodDays?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  rentalPeriodDays_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  rentalPeriodDays_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  rentalPeriodDays_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  rentalPeriodDays_not?: InputMaybe<Array<Scalars['String']>>;
  rentalPeriodIcon?: InputMaybe<AssetWhereInput>;
  rentalReturnIcon?: InputMaybe<AssetWhereInput>;
  rentalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  rentalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  rentalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  rentalTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  rentalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  rentalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  rentalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  rentalTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  rentalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  rentalTitle_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  selectionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  selectionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  selectionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  selectionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  selectionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  selectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  selectionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  selectionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  selectionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  selectionTitle_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GoWebappBookingOverview record uniquely */
export type GoWebappBookingOverviewWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepage = Node & {
  __typename?: 'GoWebappHomepage';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoWebappHomepage>;
  /** This is the hero image that heads the one pager that is overlayed by the booking widget */
  heroImage?: Maybe<Asset>;
  /** This is the URL for the media hero image */
  heroImageLinkURL?: Maybe<Scalars['String']>;
  /** List of GoWebappHomepage versions */
  history: Array<Version>;
  /** This si the background image to the how it works section to be displayed in mobile viewports and tablet.ö sub 900px */
  howItWorksBackgroundImageMobileViewports?: Maybe<Asset>;
  /**
   * This is the image that is contained within each of the 3 how it works cards. These are ordered EXACTLY in order on representation on the homepage.
   *
   *
   */
  howItWorksCardImage: Array<Asset>;
  /** This is the how it works card text that gives further details on each step of the userjourney and are ordered EXACTLY as they are represented on the homepage from left to right. */
  howItWorksCardText: Array<Scalars['String']>;
  /** This is the tile of the how it works card. These are ordered EXACTLY in the order that the cards are represented (left to right) */
  howItWorksCardTitle: Array<Scalars['String']>;
  /**
   * This is the link text that follows the paragph on the how it works card
   *
   *
   */
  howItWorksLinkText: Array<Scalars['String']>;
  /** This is the image that acts as a background to the how it works section in desktop viewports (please see mobile, tablet asset for those viewpoerts) */
  howItWorksSectionBackgroundImageDesktop?: Maybe<Asset>;
  /** This is the text that comes below the section title and explains how the service functions */
  howItWorksSectionText?: Maybe<Scalars['String']>;
  /** This is the title that heads the paragraph text at the top of the how it works section  */
  howItWorksSectionTitle?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoWebappHomepage>;
  /** These are the URL links for the corresponding media assets and MUST be ordered EXACTLY the same as the media assets */
  mediaImageUrl: Array<Scalars['String']>;
  /** These are the media image links below the partnership section. These images will be uploaded WITH source logo attached. These are to represented without luminosity in the UI */
  mediaImages: Array<Asset>;
  /** These are the media logos (asset) that link to articles about Enterprise GO  */
  mediaLinkAssets: Array<Asset>;
  /** These are the URL links for each media article that correspond EXACTLY to the order in which the media link assets are ordered */
  mediaLinkUrl: Array<Scalars['String']>;
  /** This is the main image for the media section and will be colorized in the UI. */
  mediaSectionHeroImage?: Maybe<Asset>;
  /** This is the title of the partnership section in which we elaborate on the various partnerships GO can offer and works in cooperation with */
  partnersSectionTitle?: Maybe<Scalars['String']>;
  /** These are the images of the partnership logos that come after the section title. These should be represented in the UI has images without luminosity, that are colorized on hover.  */
  partnershipAssets: Array<Asset>;
  /** These are the link urls that are attached to each of our partner's logos. These MUST be ordered EXACTLY the same as the partner logos.  */
  partnershipLinkURL: Array<Scalars['String']>;
  /** This is the paragraph text that elaborates further on the GO partnerships and comes directly below the title  */
  partnershipsSectionText?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** This the the text that populates the download application button */
  valuePropositionDownloadLinkButton?: Maybe<Scalars['String']>;
  /** This is the image that is displayed in the value proposition section. (desktop to the right of screen and sub desktop viewports above the paragraph text and title */
  valuePropositionImage?: Maybe<Asset>;
  /**
   * This is the the one line title of the value proposition section.
   *
   * EG. keyless, one demand car rental
   */
  valuePropositionTitle?: Maybe<Scalars['String']>;
  /** This is the multi-line text that explains GO's value proposition  */
  valuePropostionText?: Maybe<Scalars['String']>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageHeroImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageHowItWorksBackgroundImageMobileViewportsArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageHowItWorksCardImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageHowItWorksSectionBackgroundImageDesktopArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageMediaImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageMediaLinkAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageMediaSectionHeroImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepagePartnershipAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepagePublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the homepage (one pager) for the GO webapp. This contains all sections (hero, how it works, partners and media links) */
export type GoWebappHomepageValuePropositionImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoWebappHomepageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoWebappHomepageWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoWebappHomepageConnection = {
  __typename?: 'GoWebappHomepageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoWebappHomepageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoWebappHomepageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  heroImage?: InputMaybe<AssetCreateOneInlineInput>;
  heroImageLinkURL?: InputMaybe<Scalars['String']>;
  howItWorksBackgroundImageMobileViewports?: InputMaybe<AssetCreateOneInlineInput>;
  howItWorksCardImage?: InputMaybe<AssetCreateManyInlineInput>;
  /** howItWorksCardText input for default locale (de_CH) */
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksCardTitle input for default locale (de_CH) */
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksLinkText input for default locale (de_CH) */
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksSectionBackgroundImageDesktop?: InputMaybe<AssetCreateOneInlineInput>;
  /** howItWorksSectionText input for default locale (de_CH) */
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  /** howItWorksSectionTitle input for default locale (de_CH) */
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoWebappHomepageCreateLocalizationsInput>;
  mediaImageUrl?: InputMaybe<Array<Scalars['String']>>;
  mediaImages?: InputMaybe<AssetCreateManyInlineInput>;
  mediaLinkAssets?: InputMaybe<AssetCreateManyInlineInput>;
  mediaLinkUrl?: InputMaybe<Array<Scalars['String']>>;
  mediaSectionHeroImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** partnersSectionTitle input for default locale (de_CH) */
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  partnershipAssets?: InputMaybe<AssetCreateManyInlineInput>;
  partnershipLinkURL?: InputMaybe<Array<Scalars['String']>>;
  /** partnershipsSectionText input for default locale (de_CH) */
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** valuePropositionDownloadLinkButton input for default locale (de_CH) */
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  valuePropositionImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** valuePropositionTitle input for default locale (de_CH) */
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  /** valuePropostionText input for default locale (de_CH) */
  valuePropostionText?: InputMaybe<Scalars['String']>;
};

export type GoWebappHomepageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  valuePropostionText?: InputMaybe<Scalars['String']>;
};

export type GoWebappHomepageCreateLocalizationInput = {
  /** Localization input */
  data: GoWebappHomepageCreateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappHomepageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoWebappHomepageCreateLocalizationInput>>;
};

export type GoWebappHomepageCreateManyInlineInput = {
  /** Connect multiple existing GoWebappHomepage documents */
  connect?: InputMaybe<Array<GoWebappHomepageWhereUniqueInput>>;
  /** Create and connect multiple existing GoWebappHomepage documents */
  create?: InputMaybe<Array<GoWebappHomepageCreateInput>>;
};

export type GoWebappHomepageCreateOneInlineInput = {
  /** Connect one existing GoWebappHomepage document */
  connect?: InputMaybe<GoWebappHomepageWhereUniqueInput>;
  /** Create and connect one GoWebappHomepage document */
  create?: InputMaybe<GoWebappHomepageCreateInput>;
};

/** An edge in a connection. */
export type GoWebappHomepageEdge = {
  __typename?: 'GoWebappHomepageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoWebappHomepage;
};

/** Identifies documents */
export type GoWebappHomepageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappHomepageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappHomepageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappHomepageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  heroImage?: InputMaybe<AssetWhereInput>;
  heroImageLinkURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  heroImageLinkURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  heroImageLinkURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  heroImageLinkURL_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  heroImageLinkURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  heroImageLinkURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  heroImageLinkURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  heroImageLinkURL_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  heroImageLinkURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  heroImageLinkURL_starts_with?: InputMaybe<Scalars['String']>;
  howItWorksBackgroundImageMobileViewports?: InputMaybe<AssetWhereInput>;
  howItWorksCardImage_every?: InputMaybe<AssetWhereInput>;
  howItWorksCardImage_none?: InputMaybe<AssetWhereInput>;
  howItWorksCardImage_some?: InputMaybe<AssetWhereInput>;
  howItWorksSectionBackgroundImageDesktop?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  mediaImageUrl?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  mediaImageUrl_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  mediaImageUrl_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  mediaImageUrl_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  mediaImageUrl_not?: InputMaybe<Array<Scalars['String']>>;
  mediaImages_every?: InputMaybe<AssetWhereInput>;
  mediaImages_none?: InputMaybe<AssetWhereInput>;
  mediaImages_some?: InputMaybe<AssetWhereInput>;
  mediaLinkAssets_every?: InputMaybe<AssetWhereInput>;
  mediaLinkAssets_none?: InputMaybe<AssetWhereInput>;
  mediaLinkAssets_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  mediaLinkUrl?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  mediaLinkUrl_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  mediaLinkUrl_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  mediaLinkUrl_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  mediaLinkUrl_not?: InputMaybe<Array<Scalars['String']>>;
  mediaSectionHeroImage?: InputMaybe<AssetWhereInput>;
  partnershipAssets_every?: InputMaybe<AssetWhereInput>;
  partnershipAssets_none?: InputMaybe<AssetWhereInput>;
  partnershipAssets_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  partnershipLinkURL?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  partnershipLinkURL_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  partnershipLinkURL_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  partnershipLinkURL_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  partnershipLinkURL_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  valuePropositionImage?: InputMaybe<AssetWhereInput>;
};

export enum GoWebappHomepageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HeroImageLinkUrlAsc = 'heroImageLinkURL_ASC',
  HeroImageLinkUrlDesc = 'heroImageLinkURL_DESC',
  HowItWorksCardTextAsc = 'howItWorksCardText_ASC',
  HowItWorksCardTextDesc = 'howItWorksCardText_DESC',
  HowItWorksCardTitleAsc = 'howItWorksCardTitle_ASC',
  HowItWorksCardTitleDesc = 'howItWorksCardTitle_DESC',
  HowItWorksLinkTextAsc = 'howItWorksLinkText_ASC',
  HowItWorksLinkTextDesc = 'howItWorksLinkText_DESC',
  HowItWorksSectionTextAsc = 'howItWorksSectionText_ASC',
  HowItWorksSectionTextDesc = 'howItWorksSectionText_DESC',
  HowItWorksSectionTitleAsc = 'howItWorksSectionTitle_ASC',
  HowItWorksSectionTitleDesc = 'howItWorksSectionTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MediaImageUrlAsc = 'mediaImageUrl_ASC',
  MediaImageUrlDesc = 'mediaImageUrl_DESC',
  MediaLinkUrlAsc = 'mediaLinkUrl_ASC',
  MediaLinkUrlDesc = 'mediaLinkUrl_DESC',
  PartnersSectionTitleAsc = 'partnersSectionTitle_ASC',
  PartnersSectionTitleDesc = 'partnersSectionTitle_DESC',
  PartnershipLinkUrlAsc = 'partnershipLinkURL_ASC',
  PartnershipLinkUrlDesc = 'partnershipLinkURL_DESC',
  PartnershipsSectionTextAsc = 'partnershipsSectionText_ASC',
  PartnershipsSectionTextDesc = 'partnershipsSectionText_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValuePropositionDownloadLinkButtonAsc = 'valuePropositionDownloadLinkButton_ASC',
  ValuePropositionDownloadLinkButtonDesc = 'valuePropositionDownloadLinkButton_DESC',
  ValuePropositionTitleAsc = 'valuePropositionTitle_ASC',
  ValuePropositionTitleDesc = 'valuePropositionTitle_DESC',
  ValuePropostionTextAsc = 'valuePropostionText_ASC',
  ValuePropostionTextDesc = 'valuePropostionText_DESC'
}

export type GoWebappHomepageUpdateInput = {
  heroImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heroImageLinkURL?: InputMaybe<Scalars['String']>;
  howItWorksBackgroundImageMobileViewports?: InputMaybe<AssetUpdateOneInlineInput>;
  howItWorksCardImage?: InputMaybe<AssetUpdateManyInlineInput>;
  /** howItWorksCardText input for default locale (de_CH) */
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksCardTitle input for default locale (de_CH) */
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksLinkText input for default locale (de_CH) */
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksSectionBackgroundImageDesktop?: InputMaybe<AssetUpdateOneInlineInput>;
  /** howItWorksSectionText input for default locale (de_CH) */
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  /** howItWorksSectionTitle input for default locale (de_CH) */
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoWebappHomepageUpdateLocalizationsInput>;
  mediaImageUrl?: InputMaybe<Array<Scalars['String']>>;
  mediaImages?: InputMaybe<AssetUpdateManyInlineInput>;
  mediaLinkAssets?: InputMaybe<AssetUpdateManyInlineInput>;
  mediaLinkUrl?: InputMaybe<Array<Scalars['String']>>;
  mediaSectionHeroImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** partnersSectionTitle input for default locale (de_CH) */
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  partnershipAssets?: InputMaybe<AssetUpdateManyInlineInput>;
  partnershipLinkURL?: InputMaybe<Array<Scalars['String']>>;
  /** partnershipsSectionText input for default locale (de_CH) */
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  /** valuePropositionDownloadLinkButton input for default locale (de_CH) */
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  valuePropositionImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** valuePropositionTitle input for default locale (de_CH) */
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  /** valuePropostionText input for default locale (de_CH) */
  valuePropostionText?: InputMaybe<Scalars['String']>;
};

export type GoWebappHomepageUpdateLocalizationDataInput = {
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  valuePropostionText?: InputMaybe<Scalars['String']>;
};

export type GoWebappHomepageUpdateLocalizationInput = {
  data: GoWebappHomepageUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappHomepageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoWebappHomepageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappHomepageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoWebappHomepageUpsertLocalizationInput>>;
};

export type GoWebappHomepageUpdateManyInlineInput = {
  /** Connect multiple existing GoWebappHomepage documents */
  connect?: InputMaybe<Array<GoWebappHomepageConnectInput>>;
  /** Create and connect multiple GoWebappHomepage documents */
  create?: InputMaybe<Array<GoWebappHomepageCreateInput>>;
  /** Delete multiple GoWebappHomepage documents */
  delete?: InputMaybe<Array<GoWebappHomepageWhereUniqueInput>>;
  /** Disconnect multiple GoWebappHomepage documents */
  disconnect?: InputMaybe<Array<GoWebappHomepageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GoWebappHomepage documents */
  set?: InputMaybe<Array<GoWebappHomepageWhereUniqueInput>>;
  /** Update multiple GoWebappHomepage documents */
  update?: InputMaybe<Array<GoWebappHomepageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GoWebappHomepage documents */
  upsert?: InputMaybe<Array<GoWebappHomepageUpsertWithNestedWhereUniqueInput>>;
};

export type GoWebappHomepageUpdateManyInput = {
  heroImageLinkURL?: InputMaybe<Scalars['String']>;
  /** howItWorksCardText input for default locale (de_CH) */
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksCardTitle input for default locale (de_CH) */
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksLinkText input for default locale (de_CH) */
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  /** howItWorksSectionText input for default locale (de_CH) */
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  /** howItWorksSectionTitle input for default locale (de_CH) */
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoWebappHomepageUpdateManyLocalizationsInput>;
  mediaImageUrl?: InputMaybe<Array<Scalars['String']>>;
  mediaLinkUrl?: InputMaybe<Array<Scalars['String']>>;
  /** partnersSectionTitle input for default locale (de_CH) */
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  partnershipLinkURL?: InputMaybe<Array<Scalars['String']>>;
  /** partnershipsSectionText input for default locale (de_CH) */
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  /** valuePropositionDownloadLinkButton input for default locale (de_CH) */
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  /** valuePropositionTitle input for default locale (de_CH) */
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  /** valuePropostionText input for default locale (de_CH) */
  valuePropostionText?: InputMaybe<Scalars['String']>;
};

export type GoWebappHomepageUpdateManyLocalizationDataInput = {
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  valuePropostionText?: InputMaybe<Scalars['String']>;
};

export type GoWebappHomepageUpdateManyLocalizationInput = {
  data: GoWebappHomepageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoWebappHomepageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappHomepageUpdateManyLocalizationInput>>;
};

export type GoWebappHomepageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoWebappHomepageUpdateManyInput;
  /** Document search */
  where: GoWebappHomepageWhereInput;
};

export type GoWebappHomepageUpdateOneInlineInput = {
  /** Connect existing GoWebappHomepage document */
  connect?: InputMaybe<GoWebappHomepageWhereUniqueInput>;
  /** Create and connect one GoWebappHomepage document */
  create?: InputMaybe<GoWebappHomepageCreateInput>;
  /** Delete currently connected GoWebappHomepage document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GoWebappHomepage document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GoWebappHomepage document */
  update?: InputMaybe<GoWebappHomepageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GoWebappHomepage document */
  upsert?: InputMaybe<GoWebappHomepageUpsertWithNestedWhereUniqueInput>;
};

export type GoWebappHomepageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoWebappHomepageUpdateInput;
  /** Unique document search */
  where: GoWebappHomepageWhereUniqueInput;
};

export type GoWebappHomepageUpsertInput = {
  /** Create document if it didn't exist */
  create: GoWebappHomepageCreateInput;
  /** Update document if it exists */
  update: GoWebappHomepageUpdateInput;
};

export type GoWebappHomepageUpsertLocalizationInput = {
  create: GoWebappHomepageCreateLocalizationDataInput;
  locale: Locale;
  update: GoWebappHomepageUpdateLocalizationDataInput;
};

export type GoWebappHomepageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoWebappHomepageUpsertInput;
  /** Unique document search */
  where: GoWebappHomepageWhereUniqueInput;
};

/** Identifies documents */
export type GoWebappHomepageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappHomepageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappHomepageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappHomepageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  heroImage?: InputMaybe<AssetWhereInput>;
  heroImageLinkURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  heroImageLinkURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  heroImageLinkURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  heroImageLinkURL_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  heroImageLinkURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  heroImageLinkURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  heroImageLinkURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  heroImageLinkURL_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  heroImageLinkURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  heroImageLinkURL_starts_with?: InputMaybe<Scalars['String']>;
  howItWorksBackgroundImageMobileViewports?: InputMaybe<AssetWhereInput>;
  howItWorksCardImage_every?: InputMaybe<AssetWhereInput>;
  howItWorksCardImage_none?: InputMaybe<AssetWhereInput>;
  howItWorksCardImage_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  howItWorksCardText?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  howItWorksCardText_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  howItWorksCardText_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  howItWorksCardText_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  howItWorksCardText_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  howItWorksCardTitle?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  howItWorksCardTitle_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  howItWorksCardTitle_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  howItWorksCardTitle_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  howItWorksCardTitle_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  howItWorksLinkText?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  howItWorksLinkText_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  howItWorksLinkText_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  howItWorksLinkText_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  howItWorksLinkText_not?: InputMaybe<Array<Scalars['String']>>;
  howItWorksSectionBackgroundImageDesktop?: InputMaybe<AssetWhereInput>;
  howItWorksSectionText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  howItWorksSectionText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  howItWorksSectionText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  howItWorksSectionText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  howItWorksSectionText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  howItWorksSectionText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  howItWorksSectionText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  howItWorksSectionText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  howItWorksSectionText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  howItWorksSectionText_starts_with?: InputMaybe<Scalars['String']>;
  howItWorksSectionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  howItWorksSectionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  howItWorksSectionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  howItWorksSectionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  howItWorksSectionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  howItWorksSectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  howItWorksSectionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  howItWorksSectionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  howItWorksSectionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  howItWorksSectionTitle_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  mediaImageUrl?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  mediaImageUrl_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  mediaImageUrl_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  mediaImageUrl_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  mediaImageUrl_not?: InputMaybe<Array<Scalars['String']>>;
  mediaImages_every?: InputMaybe<AssetWhereInput>;
  mediaImages_none?: InputMaybe<AssetWhereInput>;
  mediaImages_some?: InputMaybe<AssetWhereInput>;
  mediaLinkAssets_every?: InputMaybe<AssetWhereInput>;
  mediaLinkAssets_none?: InputMaybe<AssetWhereInput>;
  mediaLinkAssets_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  mediaLinkUrl?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  mediaLinkUrl_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  mediaLinkUrl_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  mediaLinkUrl_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  mediaLinkUrl_not?: InputMaybe<Array<Scalars['String']>>;
  mediaSectionHeroImage?: InputMaybe<AssetWhereInput>;
  partnersSectionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  partnersSectionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  partnersSectionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  partnersSectionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  partnersSectionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  partnersSectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  partnersSectionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  partnersSectionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  partnersSectionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  partnersSectionTitle_starts_with?: InputMaybe<Scalars['String']>;
  partnershipAssets_every?: InputMaybe<AssetWhereInput>;
  partnershipAssets_none?: InputMaybe<AssetWhereInput>;
  partnershipAssets_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  partnershipLinkURL?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  partnershipLinkURL_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  partnershipLinkURL_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  partnershipLinkURL_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  partnershipLinkURL_not?: InputMaybe<Array<Scalars['String']>>;
  partnershipsSectionText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  partnershipsSectionText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  partnershipsSectionText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  partnershipsSectionText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  partnershipsSectionText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  partnershipsSectionText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  partnershipsSectionText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  partnershipsSectionText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  partnershipsSectionText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  partnershipsSectionText_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  valuePropositionDownloadLinkButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  valuePropositionDownloadLinkButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  valuePropositionDownloadLinkButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  valuePropositionDownloadLinkButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  valuePropositionDownloadLinkButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  valuePropositionDownloadLinkButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  valuePropositionDownloadLinkButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  valuePropositionDownloadLinkButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  valuePropositionDownloadLinkButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  valuePropositionDownloadLinkButton_starts_with?: InputMaybe<Scalars['String']>;
  valuePropositionImage?: InputMaybe<AssetWhereInput>;
  valuePropositionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  valuePropositionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  valuePropositionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  valuePropositionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  valuePropositionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  valuePropositionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  valuePropositionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  valuePropositionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  valuePropositionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  valuePropositionTitle_starts_with?: InputMaybe<Scalars['String']>;
  valuePropostionText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  valuePropostionText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  valuePropostionText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  valuePropostionText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  valuePropostionText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  valuePropostionText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  valuePropostionText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  valuePropostionText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  valuePropostionText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  valuePropostionText_starts_with?: InputMaybe<Scalars['String']>;
};

/** References GoWebappHomepage record uniquely */
export type GoWebappHomepageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLink = Node & {
  __typename?: 'GoWebappNavLink';
  /** These are the png images in the hamburger menu and the footer that link to the Enterprise GO app page in their relevant app stores. */
  appStoreImage: Array<Asset>;
  /** This si the header of the application download section on the footer and in the hamburger menu */
  applicationHeader?: Maybe<Scalars['String']>;
  /** This is the subheader of the application link section. */
  applicationSubheader?: Maybe<Scalars['String']>;
  /**
   * These are the contact navigation items that populate the footer and the hamburger menu
   *
   * EG. telephone number
   *         email address
   */
  contactNavigationItems: Array<Scalars['String']>;
  /** This is the non-clickable title of the contact navigation items  */
  contactNavigationItemsTitle?: Maybe<Scalars['String']>;
  /** This is the text that will fill the copywrite statement at the bottom of the footer */
  copywriteInformation?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoWebappNavLink>;
  /** This is the button text that populates the downoad application button in the header */
  downloadAppButtonTextHeader?: Maybe<Scalars['String']>;
  /** List of GoWebappNavLink versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the icon that is directly before the user's langauge preference */
  languageIcon?: Maybe<Asset>;
  /** This is the language of preference. */
  languageSetting: Array<Scalars['String']>;
  /** This is the non-clickable text above the language options */
  languageSettingTitle?: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoWebappNavLink>;
  /** These are navigation components and their respective viewport size. For example the mobileHeader is the representation of the header in mobile viewports (1000px and under).  */
  navigationComponentViewportSize?: Maybe<WebAppNavigationSelection>;
  /** These are the policy navigation items that populate the footer and the hamburger menu */
  policyNavigationItems: Array<Scalars['String']>;
  /** This is the non-clickable title of the policies navigation items  */
  policyNavigationItemsTitle?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** Theses are the site-wide (sections of the onepager) navigation items that populate the footer, header navigation and the hamburger menu */
  siteNavigationItems: Array<Scalars['String']>;
  /**
   * This is the non-clickable title of the site-wide (sections of the onepager) navigation items
   *
   */
  siteNavigationItemsTitle?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkAppStoreImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkLanguageIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the schema that compiles all navigation links in the Footer, Header Navigation, hamburger menu */
export type GoWebappNavLinkUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoWebappNavLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoWebappNavLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoWebappNavLinkConnection = {
  __typename?: 'GoWebappNavLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoWebappNavLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoWebappNavLinkCreateInput = {
  appStoreImage?: InputMaybe<AssetCreateManyInlineInput>;
  /** applicationHeader input for default locale (de_CH) */
  applicationHeader?: InputMaybe<Scalars['String']>;
  /** applicationSubheader input for default locale (de_CH) */
  applicationSubheader?: InputMaybe<Scalars['String']>;
  /** contactNavigationItems input for default locale (de_CH) */
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** contactNavigationItemsTitle input for default locale (de_CH) */
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  copywriteInformation?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** downloadAppButtonTextHeader input for default locale (de_CH) */
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  languageIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** languageSetting input for default locale (de_CH) */
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  /** languageSettingTitle input for default locale (de_CH) */
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoWebappNavLinkCreateLocalizationsInput>;
  navigationComponentViewportSize?: InputMaybe<WebAppNavigationSelection>;
  /** policyNavigationItems input for default locale (de_CH) */
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** policyNavigationItemsTitle input for default locale (de_CH) */
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  /** siteNavigationItems input for default locale (de_CH) */
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** siteNavigationItemsTitle input for default locale (de_CH) */
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoWebappNavLinkCreateLocalizationDataInput = {
  applicationHeader?: InputMaybe<Scalars['String']>;
  applicationSubheader?: InputMaybe<Scalars['String']>;
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoWebappNavLinkCreateLocalizationInput = {
  /** Localization input */
  data: GoWebappNavLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappNavLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoWebappNavLinkCreateLocalizationInput>>;
};

export type GoWebappNavLinkCreateManyInlineInput = {
  /** Connect multiple existing GoWebappNavLink documents */
  connect?: InputMaybe<Array<GoWebappNavLinkWhereUniqueInput>>;
  /** Create and connect multiple existing GoWebappNavLink documents */
  create?: InputMaybe<Array<GoWebappNavLinkCreateInput>>;
};

export type GoWebappNavLinkCreateOneInlineInput = {
  /** Connect one existing GoWebappNavLink document */
  connect?: InputMaybe<GoWebappNavLinkWhereUniqueInput>;
  /** Create and connect one GoWebappNavLink document */
  create?: InputMaybe<GoWebappNavLinkCreateInput>;
};

/** An edge in a connection. */
export type GoWebappNavLinkEdge = {
  __typename?: 'GoWebappNavLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoWebappNavLink;
};

/** Identifies documents */
export type GoWebappNavLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappNavLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappNavLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappNavLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appStoreImage_every?: InputMaybe<AssetWhereInput>;
  appStoreImage_none?: InputMaybe<AssetWhereInput>;
  appStoreImage_some?: InputMaybe<AssetWhereInput>;
  copywriteInformation?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  copywriteInformation_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  copywriteInformation_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  copywriteInformation_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  copywriteInformation_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  copywriteInformation_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  copywriteInformation_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  copywriteInformation_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  copywriteInformation_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  copywriteInformation_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  languageIcon?: InputMaybe<AssetWhereInput>;
  navigationComponentViewportSize?: InputMaybe<WebAppNavigationSelection>;
  /** All values that are contained in given list. */
  navigationComponentViewportSize_in?: InputMaybe<Array<WebAppNavigationSelection>>;
  /** All values that are not equal to given value. */
  navigationComponentViewportSize_not?: InputMaybe<WebAppNavigationSelection>;
  /** All values that are not contained in given list. */
  navigationComponentViewportSize_not_in?: InputMaybe<Array<WebAppNavigationSelection>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoWebappNavLinkOrderByInput {
  ApplicationHeaderAsc = 'applicationHeader_ASC',
  ApplicationHeaderDesc = 'applicationHeader_DESC',
  ApplicationSubheaderAsc = 'applicationSubheader_ASC',
  ApplicationSubheaderDesc = 'applicationSubheader_DESC',
  ContactNavigationItemsTitleAsc = 'contactNavigationItemsTitle_ASC',
  ContactNavigationItemsTitleDesc = 'contactNavigationItemsTitle_DESC',
  ContactNavigationItemsAsc = 'contactNavigationItems_ASC',
  ContactNavigationItemsDesc = 'contactNavigationItems_DESC',
  CopywriteInformationAsc = 'copywriteInformation_ASC',
  CopywriteInformationDesc = 'copywriteInformation_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DownloadAppButtonTextHeaderAsc = 'downloadAppButtonTextHeader_ASC',
  DownloadAppButtonTextHeaderDesc = 'downloadAppButtonTextHeader_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguageSettingTitleAsc = 'languageSettingTitle_ASC',
  LanguageSettingTitleDesc = 'languageSettingTitle_DESC',
  LanguageSettingAsc = 'languageSetting_ASC',
  LanguageSettingDesc = 'languageSetting_DESC',
  NavigationComponentViewportSizeAsc = 'navigationComponentViewportSize_ASC',
  NavigationComponentViewportSizeDesc = 'navigationComponentViewportSize_DESC',
  PolicyNavigationItemsTitleAsc = 'policyNavigationItemsTitle_ASC',
  PolicyNavigationItemsTitleDesc = 'policyNavigationItemsTitle_DESC',
  PolicyNavigationItemsAsc = 'policyNavigationItems_ASC',
  PolicyNavigationItemsDesc = 'policyNavigationItems_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SiteNavigationItemsTitleAsc = 'siteNavigationItemsTitle_ASC',
  SiteNavigationItemsTitleDesc = 'siteNavigationItemsTitle_DESC',
  SiteNavigationItemsAsc = 'siteNavigationItems_ASC',
  SiteNavigationItemsDesc = 'siteNavigationItems_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoWebappNavLinkUpdateInput = {
  appStoreImage?: InputMaybe<AssetUpdateManyInlineInput>;
  /** applicationHeader input for default locale (de_CH) */
  applicationHeader?: InputMaybe<Scalars['String']>;
  /** applicationSubheader input for default locale (de_CH) */
  applicationSubheader?: InputMaybe<Scalars['String']>;
  /** contactNavigationItems input for default locale (de_CH) */
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** contactNavigationItemsTitle input for default locale (de_CH) */
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  copywriteInformation?: InputMaybe<Scalars['String']>;
  /** downloadAppButtonTextHeader input for default locale (de_CH) */
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  languageIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** languageSetting input for default locale (de_CH) */
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  /** languageSettingTitle input for default locale (de_CH) */
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<GoWebappNavLinkUpdateLocalizationsInput>;
  navigationComponentViewportSize?: InputMaybe<WebAppNavigationSelection>;
  /** policyNavigationItems input for default locale (de_CH) */
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** policyNavigationItemsTitle input for default locale (de_CH) */
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  /** siteNavigationItems input for default locale (de_CH) */
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** siteNavigationItemsTitle input for default locale (de_CH) */
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavLinkUpdateLocalizationDataInput = {
  applicationHeader?: InputMaybe<Scalars['String']>;
  applicationSubheader?: InputMaybe<Scalars['String']>;
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavLinkUpdateLocalizationInput = {
  data: GoWebappNavLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappNavLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoWebappNavLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappNavLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoWebappNavLinkUpsertLocalizationInput>>;
};

export type GoWebappNavLinkUpdateManyInlineInput = {
  /** Connect multiple existing GoWebappNavLink documents */
  connect?: InputMaybe<Array<GoWebappNavLinkConnectInput>>;
  /** Create and connect multiple GoWebappNavLink documents */
  create?: InputMaybe<Array<GoWebappNavLinkCreateInput>>;
  /** Delete multiple GoWebappNavLink documents */
  delete?: InputMaybe<Array<GoWebappNavLinkWhereUniqueInput>>;
  /** Disconnect multiple GoWebappNavLink documents */
  disconnect?: InputMaybe<Array<GoWebappNavLinkWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GoWebappNavLink documents */
  set?: InputMaybe<Array<GoWebappNavLinkWhereUniqueInput>>;
  /** Update multiple GoWebappNavLink documents */
  update?: InputMaybe<Array<GoWebappNavLinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GoWebappNavLink documents */
  upsert?: InputMaybe<Array<GoWebappNavLinkUpsertWithNestedWhereUniqueInput>>;
};

export type GoWebappNavLinkUpdateManyInput = {
  /** applicationHeader input for default locale (de_CH) */
  applicationHeader?: InputMaybe<Scalars['String']>;
  /** applicationSubheader input for default locale (de_CH) */
  applicationSubheader?: InputMaybe<Scalars['String']>;
  /** contactNavigationItems input for default locale (de_CH) */
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** contactNavigationItemsTitle input for default locale (de_CH) */
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  copywriteInformation?: InputMaybe<Scalars['String']>;
  /** downloadAppButtonTextHeader input for default locale (de_CH) */
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  /** languageSetting input for default locale (de_CH) */
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  /** languageSettingTitle input for default locale (de_CH) */
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoWebappNavLinkUpdateManyLocalizationsInput>;
  navigationComponentViewportSize?: InputMaybe<WebAppNavigationSelection>;
  /** policyNavigationItems input for default locale (de_CH) */
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** policyNavigationItemsTitle input for default locale (de_CH) */
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  /** siteNavigationItems input for default locale (de_CH) */
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** siteNavigationItemsTitle input for default locale (de_CH) */
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavLinkUpdateManyLocalizationDataInput = {
  applicationHeader?: InputMaybe<Scalars['String']>;
  applicationSubheader?: InputMaybe<Scalars['String']>;
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavLinkUpdateManyLocalizationInput = {
  data: GoWebappNavLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoWebappNavLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappNavLinkUpdateManyLocalizationInput>>;
};

export type GoWebappNavLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoWebappNavLinkUpdateManyInput;
  /** Document search */
  where: GoWebappNavLinkWhereInput;
};

export type GoWebappNavLinkUpdateOneInlineInput = {
  /** Connect existing GoWebappNavLink document */
  connect?: InputMaybe<GoWebappNavLinkWhereUniqueInput>;
  /** Create and connect one GoWebappNavLink document */
  create?: InputMaybe<GoWebappNavLinkCreateInput>;
  /** Delete currently connected GoWebappNavLink document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GoWebappNavLink document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GoWebappNavLink document */
  update?: InputMaybe<GoWebappNavLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GoWebappNavLink document */
  upsert?: InputMaybe<GoWebappNavLinkUpsertWithNestedWhereUniqueInput>;
};

export type GoWebappNavLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoWebappNavLinkUpdateInput;
  /** Unique document search */
  where: GoWebappNavLinkWhereUniqueInput;
};

export type GoWebappNavLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: GoWebappNavLinkCreateInput;
  /** Update document if it exists */
  update: GoWebappNavLinkUpdateInput;
};

export type GoWebappNavLinkUpsertLocalizationInput = {
  create: GoWebappNavLinkCreateLocalizationDataInput;
  locale: Locale;
  update: GoWebappNavLinkUpdateLocalizationDataInput;
};

export type GoWebappNavLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoWebappNavLinkUpsertInput;
  /** Unique document search */
  where: GoWebappNavLinkWhereUniqueInput;
};

/** Identifies documents */
export type GoWebappNavLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappNavLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappNavLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappNavLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appStoreImage_every?: InputMaybe<AssetWhereInput>;
  appStoreImage_none?: InputMaybe<AssetWhereInput>;
  appStoreImage_some?: InputMaybe<AssetWhereInput>;
  applicationHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  applicationHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  applicationHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  applicationHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  applicationHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  applicationHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  applicationHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  applicationHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  applicationHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  applicationHeader_starts_with?: InputMaybe<Scalars['String']>;
  applicationSubheader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  applicationSubheader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  applicationSubheader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  applicationSubheader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  applicationSubheader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  applicationSubheader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  applicationSubheader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  applicationSubheader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  applicationSubheader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  applicationSubheader_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  contactNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  contactNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  contactNavigationItemsTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  contactNavigationItemsTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  contactNavigationItemsTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  contactNavigationItemsTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  contactNavigationItemsTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  contactNavigationItemsTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  contactNavigationItemsTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  contactNavigationItemsTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  contactNavigationItemsTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter */
  contactNavigationItems_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  contactNavigationItems_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  contactNavigationItems_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  contactNavigationItems_not?: InputMaybe<Array<Scalars['String']>>;
  copywriteInformation?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  copywriteInformation_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  copywriteInformation_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  copywriteInformation_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  copywriteInformation_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  copywriteInformation_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  copywriteInformation_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  copywriteInformation_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  copywriteInformation_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  copywriteInformation_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  downloadAppButtonTextHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  downloadAppButtonTextHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  downloadAppButtonTextHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  downloadAppButtonTextHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  downloadAppButtonTextHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  downloadAppButtonTextHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  downloadAppButtonTextHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  downloadAppButtonTextHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  downloadAppButtonTextHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  downloadAppButtonTextHeader_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  languageIcon?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languageSetting?: InputMaybe<Array<Scalars['String']>>;
  languageSettingTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  languageSettingTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  languageSettingTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  languageSettingTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  languageSettingTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  languageSettingTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  languageSettingTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  languageSettingTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  languageSettingTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  languageSettingTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter */
  languageSetting_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languageSetting_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languageSetting_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languageSetting_not?: InputMaybe<Array<Scalars['String']>>;
  navigationComponentViewportSize?: InputMaybe<WebAppNavigationSelection>;
  /** All values that are contained in given list. */
  navigationComponentViewportSize_in?: InputMaybe<Array<WebAppNavigationSelection>>;
  /** All values that are not equal to given value. */
  navigationComponentViewportSize_not?: InputMaybe<WebAppNavigationSelection>;
  /** All values that are not contained in given list. */
  navigationComponentViewportSize_not_in?: InputMaybe<Array<WebAppNavigationSelection>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  policyNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  policyNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  policyNavigationItemsTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  policyNavigationItemsTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  policyNavigationItemsTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  policyNavigationItemsTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  policyNavigationItemsTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  policyNavigationItemsTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  policyNavigationItemsTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  policyNavigationItemsTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  policyNavigationItemsTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter */
  policyNavigationItems_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  policyNavigationItems_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  policyNavigationItems_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  policyNavigationItems_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  siteNavigationItems?: InputMaybe<Array<Scalars['String']>>;
  siteNavigationItemsTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  siteNavigationItemsTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  siteNavigationItemsTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  siteNavigationItemsTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  siteNavigationItemsTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  siteNavigationItemsTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  siteNavigationItemsTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  siteNavigationItemsTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  siteNavigationItemsTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  siteNavigationItemsTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter */
  siteNavigationItems_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  siteNavigationItems_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  siteNavigationItems_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  siteNavigationItems_not?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GoWebappNavLink record uniquely */
export type GoWebappNavLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizard = Node & {
  __typename?: 'GoWebappNavigationWizard';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GoWebappNavigationWizard>;
  /** List of GoWebappNavigationWizard versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GoWebappNavigationWizard>;
  /**
   * These are the localized navigation wizard items. these must be displayec in the EXACT order and correspond to their attached pages.
   *
   * EG. Class selection (class and subclass selection)
   */
  navigationItems: Array<Scalars['String']>;
  /**
   * This is the navigation wizard item to which the screen belongs
   *
   * 1 = Car selection
   * 2 = Extras
   * 3 = Schutz
   * 4 = Confirm & Pay
   */
  navigationWizardItem?: Maybe<Scalars['Int']>;
  /**
   * This is the order in which the screen information is displayed during the booking journey.
   *
   * 1 = Car Class Selection
   * 2 = Car Subclass Selection
   * 3 = Selection Overview
   * 4 = Select Extras
   * 5 = Select Coverage
   * 6 = Confirm and Pay
   * 7 = Payment Success
   */
  navigationWizardScreenDisplayOrder?: Maybe<Scalars['Int']>;
  /**
   * This is the title that is displayed as header for the page that is being displayed
   *
   * 1 = car class
   *
   * SELECT A CAR CLASS
   */
  navigationWizardScreenTitle?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the localizations of the navigation wizard visible during the booking journey */
export type GoWebappNavigationWizardUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type GoWebappNavigationWizardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GoWebappNavigationWizardWhereUniqueInput;
};

/** A connection to a list of items. */
export type GoWebappNavigationWizardConnection = {
  __typename?: 'GoWebappNavigationWizardConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GoWebappNavigationWizardEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GoWebappNavigationWizardCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GoWebappNavigationWizardCreateLocalizationsInput>;
  /** navigationItems input for default locale (de_CH) */
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardItem?: InputMaybe<Scalars['Int']>;
  navigationWizardScreenDisplayOrder?: InputMaybe<Scalars['Int']>;
  /** navigationWizardScreenTitle input for default locale (de_CH) */
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoWebappNavigationWizardCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GoWebappNavigationWizardCreateLocalizationInput = {
  /** Localization input */
  data: GoWebappNavigationWizardCreateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappNavigationWizardCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GoWebappNavigationWizardCreateLocalizationInput>>;
};

export type GoWebappNavigationWizardCreateManyInlineInput = {
  /** Connect multiple existing GoWebappNavigationWizard documents */
  connect?: InputMaybe<Array<GoWebappNavigationWizardWhereUniqueInput>>;
  /** Create and connect multiple existing GoWebappNavigationWizard documents */
  create?: InputMaybe<Array<GoWebappNavigationWizardCreateInput>>;
};

export type GoWebappNavigationWizardCreateOneInlineInput = {
  /** Connect one existing GoWebappNavigationWizard document */
  connect?: InputMaybe<GoWebappNavigationWizardWhereUniqueInput>;
  /** Create and connect one GoWebappNavigationWizard document */
  create?: InputMaybe<GoWebappNavigationWizardCreateInput>;
};

/** An edge in a connection. */
export type GoWebappNavigationWizardEdge = {
  __typename?: 'GoWebappNavigationWizardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GoWebappNavigationWizard;
};

/** Identifies documents */
export type GoWebappNavigationWizardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappNavigationWizardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappNavigationWizardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappNavigationWizardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  navigationWizardItem?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  navigationWizardItem_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  navigationWizardItem_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  navigationWizardItem_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  navigationWizardItem_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  navigationWizardItem_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  navigationWizardItem_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  navigationWizardItem_not_in?: InputMaybe<Array<Scalars['Int']>>;
  navigationWizardScreenDisplayOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  navigationWizardScreenDisplayOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  navigationWizardScreenDisplayOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  navigationWizardScreenDisplayOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  navigationWizardScreenDisplayOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  navigationWizardScreenDisplayOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  navigationWizardScreenDisplayOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  navigationWizardScreenDisplayOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GoWebappNavigationWizardOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NavigationItemsAsc = 'navigationItems_ASC',
  NavigationItemsDesc = 'navigationItems_DESC',
  NavigationWizardItemAsc = 'navigationWizardItem_ASC',
  NavigationWizardItemDesc = 'navigationWizardItem_DESC',
  NavigationWizardScreenDisplayOrderAsc = 'navigationWizardScreenDisplayOrder_ASC',
  NavigationWizardScreenDisplayOrderDesc = 'navigationWizardScreenDisplayOrder_DESC',
  NavigationWizardScreenTitleAsc = 'navigationWizardScreenTitle_ASC',
  NavigationWizardScreenTitleDesc = 'navigationWizardScreenTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GoWebappNavigationWizardUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<GoWebappNavigationWizardUpdateLocalizationsInput>;
  /** navigationItems input for default locale (de_CH) */
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardItem?: InputMaybe<Scalars['Int']>;
  navigationWizardScreenDisplayOrder?: InputMaybe<Scalars['Int']>;
  /** navigationWizardScreenTitle input for default locale (de_CH) */
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavigationWizardUpdateLocalizationDataInput = {
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavigationWizardUpdateLocalizationInput = {
  data: GoWebappNavigationWizardUpdateLocalizationDataInput;
  locale: Locale;
};

export type GoWebappNavigationWizardUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GoWebappNavigationWizardCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappNavigationWizardUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GoWebappNavigationWizardUpsertLocalizationInput>>;
};

export type GoWebappNavigationWizardUpdateManyInlineInput = {
  /** Connect multiple existing GoWebappNavigationWizard documents */
  connect?: InputMaybe<Array<GoWebappNavigationWizardConnectInput>>;
  /** Create and connect multiple GoWebappNavigationWizard documents */
  create?: InputMaybe<Array<GoWebappNavigationWizardCreateInput>>;
  /** Delete multiple GoWebappNavigationWizard documents */
  delete?: InputMaybe<Array<GoWebappNavigationWizardWhereUniqueInput>>;
  /** Disconnect multiple GoWebappNavigationWizard documents */
  disconnect?: InputMaybe<Array<GoWebappNavigationWizardWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GoWebappNavigationWizard documents */
  set?: InputMaybe<Array<GoWebappNavigationWizardWhereUniqueInput>>;
  /** Update multiple GoWebappNavigationWizard documents */
  update?: InputMaybe<Array<GoWebappNavigationWizardUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GoWebappNavigationWizard documents */
  upsert?: InputMaybe<Array<GoWebappNavigationWizardUpsertWithNestedWhereUniqueInput>>;
};

export type GoWebappNavigationWizardUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<GoWebappNavigationWizardUpdateManyLocalizationsInput>;
  /** navigationItems input for default locale (de_CH) */
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardItem?: InputMaybe<Scalars['Int']>;
  /** navigationWizardScreenTitle input for default locale (de_CH) */
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavigationWizardUpdateManyLocalizationDataInput = {
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
};

export type GoWebappNavigationWizardUpdateManyLocalizationInput = {
  data: GoWebappNavigationWizardUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GoWebappNavigationWizardUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GoWebappNavigationWizardUpdateManyLocalizationInput>>;
};

export type GoWebappNavigationWizardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GoWebappNavigationWizardUpdateManyInput;
  /** Document search */
  where: GoWebappNavigationWizardWhereInput;
};

export type GoWebappNavigationWizardUpdateOneInlineInput = {
  /** Connect existing GoWebappNavigationWizard document */
  connect?: InputMaybe<GoWebappNavigationWizardWhereUniqueInput>;
  /** Create and connect one GoWebappNavigationWizard document */
  create?: InputMaybe<GoWebappNavigationWizardCreateInput>;
  /** Delete currently connected GoWebappNavigationWizard document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GoWebappNavigationWizard document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GoWebappNavigationWizard document */
  update?: InputMaybe<GoWebappNavigationWizardUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GoWebappNavigationWizard document */
  upsert?: InputMaybe<GoWebappNavigationWizardUpsertWithNestedWhereUniqueInput>;
};

export type GoWebappNavigationWizardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GoWebappNavigationWizardUpdateInput;
  /** Unique document search */
  where: GoWebappNavigationWizardWhereUniqueInput;
};

export type GoWebappNavigationWizardUpsertInput = {
  /** Create document if it didn't exist */
  create: GoWebappNavigationWizardCreateInput;
  /** Update document if it exists */
  update: GoWebappNavigationWizardUpdateInput;
};

export type GoWebappNavigationWizardUpsertLocalizationInput = {
  create: GoWebappNavigationWizardCreateLocalizationDataInput;
  locale: Locale;
  update: GoWebappNavigationWizardUpdateLocalizationDataInput;
};

export type GoWebappNavigationWizardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GoWebappNavigationWizardUpsertInput;
  /** Unique document search */
  where: GoWebappNavigationWizardWhereUniqueInput;
};

/** Identifies documents */
export type GoWebappNavigationWizardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GoWebappNavigationWizardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GoWebappNavigationWizardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GoWebappNavigationWizardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  navigationItems?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  navigationItems_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  navigationItems_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  navigationItems_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  navigationItems_not?: InputMaybe<Array<Scalars['String']>>;
  navigationWizardItem?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  navigationWizardItem_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  navigationWizardItem_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  navigationWizardItem_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  navigationWizardItem_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  navigationWizardItem_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  navigationWizardItem_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  navigationWizardItem_not_in?: InputMaybe<Array<Scalars['Int']>>;
  navigationWizardScreenDisplayOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  navigationWizardScreenDisplayOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  navigationWizardScreenDisplayOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  navigationWizardScreenDisplayOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  navigationWizardScreenDisplayOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  navigationWizardScreenDisplayOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  navigationWizardScreenDisplayOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  navigationWizardScreenDisplayOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  navigationWizardScreenTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  navigationWizardScreenTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  navigationWizardScreenTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  navigationWizardScreenTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  navigationWizardScreenTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  navigationWizardScreenTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  navigationWizardScreenTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  navigationWizardScreenTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  navigationWizardScreenTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  navigationWizardScreenTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References GoWebappNavigationWizard record uniquely */
export type GoWebappNavigationWizardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  navigationWizardScreenDisplayOrder?: InputMaybe<Scalars['Int']>;
};

/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItem = Node & {
  __typename?: 'HamburgerMenuItem';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<HamburgerMenuItem>;
  /** This is the icon that fits with a corresponding hamburger menu item */
  hamburgerItemIcon?: Maybe<Asset>;
  /** This is the name of hamburger menu item ( eg. my profile), this will be localized for different lanaguages */
  hamburgerItemName?: Maybe<Scalars['String']>;
  /**
   * This is the Identifier that is used for internal use as an identifier for hamburger menu items
   *
   */
  hamburgerMenuIdentifierTitle?: Maybe<Scalars['String']>;
  /** List of HamburgerMenuItem versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the ID that corresponds to the language option that the application in localised in.  */
  languageOptionId: Array<Scalars['String']>;
  /**
   * These are the language options and are ordered EXACTLY corresponding to their language ID.
   *
   * This is ONLY to be used in the language selector at the bottom of the hamburger menu.
   */
  languageOptions: Array<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<HamburgerMenuItem>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** Link to the static content */
  staticContent?: Maybe<Legal>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemHamburgerItemIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemStaticContentArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the items links that populate the hamburger menu */
export type HamburgerMenuItemUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type HamburgerMenuItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: HamburgerMenuItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type HamburgerMenuItemConnection = {
  __typename?: 'HamburgerMenuItemConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<HamburgerMenuItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HamburgerMenuItemCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hamburgerItemIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** hamburgerItemName input for default locale (de_CH) */
  hamburgerItemName?: InputMaybe<Scalars['String']>;
  hamburgerMenuIdentifierTitle?: InputMaybe<Scalars['String']>;
  languageOptionId?: InputMaybe<Array<Scalars['String']>>;
  languageOptions?: InputMaybe<Array<Scalars['String']>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HamburgerMenuItemCreateLocalizationsInput>;
  staticContent?: InputMaybe<LegalCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HamburgerMenuItemCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hamburgerItemName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HamburgerMenuItemCreateLocalizationInput = {
  /** Localization input */
  data: HamburgerMenuItemCreateLocalizationDataInput;
  locale: Locale;
};

export type HamburgerMenuItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HamburgerMenuItemCreateLocalizationInput>>;
};

export type HamburgerMenuItemCreateManyInlineInput = {
  /** Connect multiple existing HamburgerMenuItem documents */
  connect?: InputMaybe<Array<HamburgerMenuItemWhereUniqueInput>>;
  /** Create and connect multiple existing HamburgerMenuItem documents */
  create?: InputMaybe<Array<HamburgerMenuItemCreateInput>>;
};

export type HamburgerMenuItemCreateOneInlineInput = {
  /** Connect one existing HamburgerMenuItem document */
  connect?: InputMaybe<HamburgerMenuItemWhereUniqueInput>;
  /** Create and connect one HamburgerMenuItem document */
  create?: InputMaybe<HamburgerMenuItemCreateInput>;
};

/** An edge in a connection. */
export type HamburgerMenuItemEdge = {
  __typename?: 'HamburgerMenuItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: HamburgerMenuItem;
};

/** Identifies documents */
export type HamburgerMenuItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HamburgerMenuItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HamburgerMenuItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HamburgerMenuItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  hamburgerItemIcon?: InputMaybe<AssetWhereInput>;
  hamburgerMenuIdentifierTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  hamburgerMenuIdentifierTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  hamburgerMenuIdentifierTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hamburgerMenuIdentifierTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  hamburgerMenuIdentifierTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  hamburgerMenuIdentifierTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  hamburgerMenuIdentifierTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hamburgerMenuIdentifierTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hamburgerMenuIdentifierTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  hamburgerMenuIdentifierTitle_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languageOptionId?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languageOptionId_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languageOptionId_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languageOptionId_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languageOptionId_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languageOptions?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languageOptions_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languageOptions_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languageOptions_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languageOptions_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  staticContent?: InputMaybe<LegalWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum HamburgerMenuItemOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HamburgerItemNameAsc = 'hamburgerItemName_ASC',
  HamburgerItemNameDesc = 'hamburgerItemName_DESC',
  HamburgerMenuIdentifierTitleAsc = 'hamburgerMenuIdentifierTitle_ASC',
  HamburgerMenuIdentifierTitleDesc = 'hamburgerMenuIdentifierTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguageOptionIdAsc = 'languageOptionId_ASC',
  LanguageOptionIdDesc = 'languageOptionId_DESC',
  LanguageOptionsAsc = 'languageOptions_ASC',
  LanguageOptionsDesc = 'languageOptions_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type HamburgerMenuItemUpdateInput = {
  hamburgerItemIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** hamburgerItemName input for default locale (de_CH) */
  hamburgerItemName?: InputMaybe<Scalars['String']>;
  hamburgerMenuIdentifierTitle?: InputMaybe<Scalars['String']>;
  languageOptionId?: InputMaybe<Array<Scalars['String']>>;
  languageOptions?: InputMaybe<Array<Scalars['String']>>;
  /** Manage document localizations */
  localizations?: InputMaybe<HamburgerMenuItemUpdateLocalizationsInput>;
  staticContent?: InputMaybe<LegalUpdateOneInlineInput>;
};

export type HamburgerMenuItemUpdateLocalizationDataInput = {
  hamburgerItemName?: InputMaybe<Scalars['String']>;
};

export type HamburgerMenuItemUpdateLocalizationInput = {
  data: HamburgerMenuItemUpdateLocalizationDataInput;
  locale: Locale;
};

export type HamburgerMenuItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HamburgerMenuItemCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<HamburgerMenuItemUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<HamburgerMenuItemUpsertLocalizationInput>>;
};

export type HamburgerMenuItemUpdateManyInlineInput = {
  /** Connect multiple existing HamburgerMenuItem documents */
  connect?: InputMaybe<Array<HamburgerMenuItemConnectInput>>;
  /** Create and connect multiple HamburgerMenuItem documents */
  create?: InputMaybe<Array<HamburgerMenuItemCreateInput>>;
  /** Delete multiple HamburgerMenuItem documents */
  delete?: InputMaybe<Array<HamburgerMenuItemWhereUniqueInput>>;
  /** Disconnect multiple HamburgerMenuItem documents */
  disconnect?: InputMaybe<Array<HamburgerMenuItemWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing HamburgerMenuItem documents */
  set?: InputMaybe<Array<HamburgerMenuItemWhereUniqueInput>>;
  /** Update multiple HamburgerMenuItem documents */
  update?: InputMaybe<Array<HamburgerMenuItemUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple HamburgerMenuItem documents */
  upsert?: InputMaybe<Array<HamburgerMenuItemUpsertWithNestedWhereUniqueInput>>;
};

export type HamburgerMenuItemUpdateManyInput = {
  /** hamburgerItemName input for default locale (de_CH) */
  hamburgerItemName?: InputMaybe<Scalars['String']>;
  hamburgerMenuIdentifierTitle?: InputMaybe<Scalars['String']>;
  languageOptionId?: InputMaybe<Array<Scalars['String']>>;
  languageOptions?: InputMaybe<Array<Scalars['String']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<HamburgerMenuItemUpdateManyLocalizationsInput>;
};

export type HamburgerMenuItemUpdateManyLocalizationDataInput = {
  hamburgerItemName?: InputMaybe<Scalars['String']>;
};

export type HamburgerMenuItemUpdateManyLocalizationInput = {
  data: HamburgerMenuItemUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type HamburgerMenuItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HamburgerMenuItemUpdateManyLocalizationInput>>;
};

export type HamburgerMenuItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HamburgerMenuItemUpdateManyInput;
  /** Document search */
  where: HamburgerMenuItemWhereInput;
};

export type HamburgerMenuItemUpdateOneInlineInput = {
  /** Connect existing HamburgerMenuItem document */
  connect?: InputMaybe<HamburgerMenuItemWhereUniqueInput>;
  /** Create and connect one HamburgerMenuItem document */
  create?: InputMaybe<HamburgerMenuItemCreateInput>;
  /** Delete currently connected HamburgerMenuItem document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected HamburgerMenuItem document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single HamburgerMenuItem document */
  update?: InputMaybe<HamburgerMenuItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single HamburgerMenuItem document */
  upsert?: InputMaybe<HamburgerMenuItemUpsertWithNestedWhereUniqueInput>;
};

export type HamburgerMenuItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HamburgerMenuItemUpdateInput;
  /** Unique document search */
  where: HamburgerMenuItemWhereUniqueInput;
};

export type HamburgerMenuItemUpsertInput = {
  /** Create document if it didn't exist */
  create: HamburgerMenuItemCreateInput;
  /** Update document if it exists */
  update: HamburgerMenuItemUpdateInput;
};

export type HamburgerMenuItemUpsertLocalizationInput = {
  create: HamburgerMenuItemCreateLocalizationDataInput;
  locale: Locale;
  update: HamburgerMenuItemUpdateLocalizationDataInput;
};

export type HamburgerMenuItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HamburgerMenuItemUpsertInput;
  /** Unique document search */
  where: HamburgerMenuItemWhereUniqueInput;
};

/** Identifies documents */
export type HamburgerMenuItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HamburgerMenuItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HamburgerMenuItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HamburgerMenuItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  hamburgerItemIcon?: InputMaybe<AssetWhereInput>;
  hamburgerItemName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  hamburgerItemName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  hamburgerItemName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hamburgerItemName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  hamburgerItemName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  hamburgerItemName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  hamburgerItemName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hamburgerItemName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hamburgerItemName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  hamburgerItemName_starts_with?: InputMaybe<Scalars['String']>;
  hamburgerMenuIdentifierTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  hamburgerMenuIdentifierTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  hamburgerMenuIdentifierTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hamburgerMenuIdentifierTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  hamburgerMenuIdentifierTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  hamburgerMenuIdentifierTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  hamburgerMenuIdentifierTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hamburgerMenuIdentifierTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hamburgerMenuIdentifierTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  hamburgerMenuIdentifierTitle_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languageOptionId?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languageOptionId_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languageOptionId_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languageOptionId_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languageOptionId_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languageOptions?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languageOptions_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languageOptions_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languageOptions_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languageOptions_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  staticContent?: InputMaybe<LegalWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References HamburgerMenuItem record uniquely */
export type HamburgerMenuItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the schema for the Enterprise GO home screen */
export type HomeScreen = Node & {
  __typename?: 'HomeScreen';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** This is the checkbox that when unchecked will display two location inputs (one for the pick up and one for the return location) */
  differentLocationCheckbox?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<HomeScreen>;
  /** List of HomeScreen versions */
  history: Array<Version>;
  /** This is the text that is shown in the main CTA button */
  homeScreenCTAButtonText?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<HomeScreen>;
  /** This is the header for the date and time input for Pick Ups */
  pickUpTimeHeader?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** This is the header for the date and time input for Pick Ups */
  returnTimeHeader?: Maybe<Scalars['String']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the schema for the Enterprise GO home screen */
export type HomeScreenUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type HomeScreenConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: HomeScreenWhereUniqueInput;
};

/** A connection to a list of items. */
export type HomeScreenConnection = {
  __typename?: 'HomeScreenConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<HomeScreenEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HomeScreenCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** differentLocationCheckbox input for default locale (de_CH) */
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  /** homeScreenCTAButtonText input for default locale (de_CH) */
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HomeScreenCreateLocalizationsInput>;
  /** pickUpTimeHeader input for default locale (de_CH) */
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  /** returnTimeHeader input for default locale (de_CH) */
  returnTimeHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HomeScreenCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  returnTimeHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HomeScreenCreateLocalizationInput = {
  /** Localization input */
  data: HomeScreenCreateLocalizationDataInput;
  locale: Locale;
};

export type HomeScreenCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HomeScreenCreateLocalizationInput>>;
};

export type HomeScreenCreateManyInlineInput = {
  /** Connect multiple existing HomeScreen documents */
  connect?: InputMaybe<Array<HomeScreenWhereUniqueInput>>;
  /** Create and connect multiple existing HomeScreen documents */
  create?: InputMaybe<Array<HomeScreenCreateInput>>;
};

export type HomeScreenCreateOneInlineInput = {
  /** Connect one existing HomeScreen document */
  connect?: InputMaybe<HomeScreenWhereUniqueInput>;
  /** Create and connect one HomeScreen document */
  create?: InputMaybe<HomeScreenCreateInput>;
};

/** An edge in a connection. */
export type HomeScreenEdge = {
  __typename?: 'HomeScreenEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: HomeScreen;
};

/** Identifies documents */
export type HomeScreenManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HomeScreenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HomeScreenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HomeScreenWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum HomeScreenOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DifferentLocationCheckboxAsc = 'differentLocationCheckbox_ASC',
  DifferentLocationCheckboxDesc = 'differentLocationCheckbox_DESC',
  HomeScreenCtaButtonTextAsc = 'homeScreenCTAButtonText_ASC',
  HomeScreenCtaButtonTextDesc = 'homeScreenCTAButtonText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PickUpTimeHeaderAsc = 'pickUpTimeHeader_ASC',
  PickUpTimeHeaderDesc = 'pickUpTimeHeader_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReturnTimeHeaderAsc = 'returnTimeHeader_ASC',
  ReturnTimeHeaderDesc = 'returnTimeHeader_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type HomeScreenUpdateInput = {
  /** differentLocationCheckbox input for default locale (de_CH) */
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  /** homeScreenCTAButtonText input for default locale (de_CH) */
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<HomeScreenUpdateLocalizationsInput>;
  /** pickUpTimeHeader input for default locale (de_CH) */
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  /** returnTimeHeader input for default locale (de_CH) */
  returnTimeHeader?: InputMaybe<Scalars['String']>;
};

export type HomeScreenUpdateLocalizationDataInput = {
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  returnTimeHeader?: InputMaybe<Scalars['String']>;
};

export type HomeScreenUpdateLocalizationInput = {
  data: HomeScreenUpdateLocalizationDataInput;
  locale: Locale;
};

export type HomeScreenUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HomeScreenCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<HomeScreenUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<HomeScreenUpsertLocalizationInput>>;
};

export type HomeScreenUpdateManyInlineInput = {
  /** Connect multiple existing HomeScreen documents */
  connect?: InputMaybe<Array<HomeScreenConnectInput>>;
  /** Create and connect multiple HomeScreen documents */
  create?: InputMaybe<Array<HomeScreenCreateInput>>;
  /** Delete multiple HomeScreen documents */
  delete?: InputMaybe<Array<HomeScreenWhereUniqueInput>>;
  /** Disconnect multiple HomeScreen documents */
  disconnect?: InputMaybe<Array<HomeScreenWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing HomeScreen documents */
  set?: InputMaybe<Array<HomeScreenWhereUniqueInput>>;
  /** Update multiple HomeScreen documents */
  update?: InputMaybe<Array<HomeScreenUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple HomeScreen documents */
  upsert?: InputMaybe<Array<HomeScreenUpsertWithNestedWhereUniqueInput>>;
};

export type HomeScreenUpdateManyInput = {
  /** differentLocationCheckbox input for default locale (de_CH) */
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  /** homeScreenCTAButtonText input for default locale (de_CH) */
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<HomeScreenUpdateManyLocalizationsInput>;
  /** pickUpTimeHeader input for default locale (de_CH) */
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  /** returnTimeHeader input for default locale (de_CH) */
  returnTimeHeader?: InputMaybe<Scalars['String']>;
};

export type HomeScreenUpdateManyLocalizationDataInput = {
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  returnTimeHeader?: InputMaybe<Scalars['String']>;
};

export type HomeScreenUpdateManyLocalizationInput = {
  data: HomeScreenUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type HomeScreenUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HomeScreenUpdateManyLocalizationInput>>;
};

export type HomeScreenUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HomeScreenUpdateManyInput;
  /** Document search */
  where: HomeScreenWhereInput;
};

export type HomeScreenUpdateOneInlineInput = {
  /** Connect existing HomeScreen document */
  connect?: InputMaybe<HomeScreenWhereUniqueInput>;
  /** Create and connect one HomeScreen document */
  create?: InputMaybe<HomeScreenCreateInput>;
  /** Delete currently connected HomeScreen document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected HomeScreen document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single HomeScreen document */
  update?: InputMaybe<HomeScreenUpdateWithNestedWhereUniqueInput>;
  /** Upsert single HomeScreen document */
  upsert?: InputMaybe<HomeScreenUpsertWithNestedWhereUniqueInput>;
};

export type HomeScreenUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HomeScreenUpdateInput;
  /** Unique document search */
  where: HomeScreenWhereUniqueInput;
};

export type HomeScreenUpsertInput = {
  /** Create document if it didn't exist */
  create: HomeScreenCreateInput;
  /** Update document if it exists */
  update: HomeScreenUpdateInput;
};

export type HomeScreenUpsertLocalizationInput = {
  create: HomeScreenCreateLocalizationDataInput;
  locale: Locale;
  update: HomeScreenUpdateLocalizationDataInput;
};

export type HomeScreenUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HomeScreenUpsertInput;
  /** Unique document search */
  where: HomeScreenWhereUniqueInput;
};

/** Identifies documents */
export type HomeScreenWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HomeScreenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HomeScreenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HomeScreenWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  differentLocationCheckbox?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  differentLocationCheckbox_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  differentLocationCheckbox_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  differentLocationCheckbox_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  differentLocationCheckbox_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  differentLocationCheckbox_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  differentLocationCheckbox_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  differentLocationCheckbox_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  differentLocationCheckbox_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  differentLocationCheckbox_starts_with?: InputMaybe<Scalars['String']>;
  homeScreenCTAButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  homeScreenCTAButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  homeScreenCTAButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  homeScreenCTAButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  homeScreenCTAButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  homeScreenCTAButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  homeScreenCTAButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  homeScreenCTAButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  homeScreenCTAButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  homeScreenCTAButtonText_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  pickUpTimeHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  pickUpTimeHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  pickUpTimeHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  pickUpTimeHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  pickUpTimeHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  pickUpTimeHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  pickUpTimeHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  pickUpTimeHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  pickUpTimeHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  pickUpTimeHeader_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  returnTimeHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  returnTimeHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  returnTimeHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  returnTimeHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  returnTimeHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  returnTimeHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  returnTimeHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  returnTimeHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  returnTimeHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  returnTimeHeader_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References HomeScreen record uniquely */
export type HomeScreenWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale'
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars['Int']>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars['Int']>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
};

/** All of the content pieces of the key screens have to work offline. */
export type Key = Node & {
  __typename?: 'Key';
  /** This is shown once the countdown ended */
  countdownEnded?: Maybe<Scalars['String']>;
  /**
   * This is the 'days', 'hours' 'minutes' after the number, so:
   * Return in x 'days'
   */
  countdownTime: Array<Scalars['String']>;
  /** The 'Return in'  */
  countdownToReturn?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Key>;
  /** List of Key versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** This field is to differentiate between the two records: no key = 1; assigned key = 2 */
  identifier?: Maybe<Scalars['Int']>;
  /** This is the important information below the key Image.  */
  importantInformation?: Maybe<Scalars['String']>;
  /** This is the important information icon to the left of the important information below the key image */
  importantInformationIcon?: Maybe<Asset>;
  /** This is the title of the screen. */
  keyName?: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Key>;
  /** This is the no key image */
  noKeyImage?: Maybe<Asset>;
  /**
   * NO KEY ONLY
   * This is the paragraph text that is displayed when there is no open key.
   */
  noKeyText?: Maybe<RichText>;
  /**
   * NO KEY ONLY
   * This is the header that comes directly above the no key text paragraph displayed when there is no open key
   */
  noKeyTextHeader?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyImportantInformationIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyNoKeyImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** All of the content pieces of the key screens have to work offline. */
export type KeyUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type KeyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: KeyWhereUniqueInput;
};

/** A connection to a list of items. */
export type KeyConnection = {
  __typename?: 'KeyConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<KeyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type KeyCreateInput = {
  /** countdownEnded input for default locale (de_CH) */
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** countdownTime input for default locale (de_CH) */
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  /** countdownToReturn input for default locale (de_CH) */
  countdownToReturn?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  identifier?: InputMaybe<Scalars['Int']>;
  /** importantInformation input for default locale (de_CH) */
  importantInformation?: InputMaybe<Scalars['String']>;
  importantInformationIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** keyName input for default locale (de_CH) */
  keyName?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<KeyCreateLocalizationsInput>;
  noKeyImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** noKeyText input for default locale (de_CH) */
  noKeyText?: InputMaybe<Scalars['RichTextAST']>;
  /** noKeyTextHeader input for default locale (de_CH) */
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KeyCreateLocalizationDataInput = {
  countdownEnded?: InputMaybe<Scalars['String']>;
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  countdownToReturn?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  importantInformation?: InputMaybe<Scalars['String']>;
  keyName?: InputMaybe<Scalars['String']>;
  noKeyText?: InputMaybe<Scalars['RichTextAST']>;
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KeyCreateLocalizationInput = {
  /** Localization input */
  data: KeyCreateLocalizationDataInput;
  locale: Locale;
};

export type KeyCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<KeyCreateLocalizationInput>>;
};

export type KeyCreateManyInlineInput = {
  /** Connect multiple existing Key documents */
  connect?: InputMaybe<Array<KeyWhereUniqueInput>>;
  /** Create and connect multiple existing Key documents */
  create?: InputMaybe<Array<KeyCreateInput>>;
};

export type KeyCreateOneInlineInput = {
  /** Connect one existing Key document */
  connect?: InputMaybe<KeyWhereUniqueInput>;
  /** Create and connect one Key document */
  create?: InputMaybe<KeyCreateInput>;
};

/** An edge in a connection. */
export type KeyEdge = {
  __typename?: 'KeyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Key;
};

/** Identifies documents */
export type KeyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  identifier?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  identifier_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  identifier_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  identifier_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  identifier_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  identifier_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  identifier_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  identifier_not_in?: InputMaybe<Array<Scalars['Int']>>;
  importantInformationIcon?: InputMaybe<AssetWhereInput>;
  noKeyImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboarding = Node & {
  __typename?: 'KeyOnboarding';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<KeyOnboarding>;
  /** List of KeyOnboarding versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Main Button to follow the key onboarding process, from screen to screen */
  keyOnboardingButton?: Maybe<Scalars['String']>;
  /** The description is a short text about the key onbarding topic. Please only use bold to style sections of the text! */
  keyOnboardingDescription?: Maybe<RichText>;
  /** This image is the only image per key onboarding screen. */
  keyOnboardingImage?: Maybe<Asset>;
  /** This name is the headline of the key onboarding screen */
  keyOnboardingName?: Maybe<Scalars['String']>;
  /** The key onboarding order is important to show the correct order of the screens within the GO app. */
  keyOnboardingOrder?: Maybe<Scalars['Int']>;
  /** Permanent Bottom Link to skip onboarding */
  keyOnboardingSkipLink?: Maybe<Scalars['String']>;
  /** This subtitle is a secondary title of the key onboarding topic. */
  keyOnboardingSubtitle?: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<KeyOnboarding>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingKeyOnboardingImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This model contains all the key onboarding screens for the GO mobile App */
export type KeyOnboardingUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type KeyOnboardingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: KeyOnboardingWhereUniqueInput;
};

/** A connection to a list of items. */
export type KeyOnboardingConnection = {
  __typename?: 'KeyOnboardingConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<KeyOnboardingEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type KeyOnboardingCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** keyOnboardingButton input for default locale (de_CH) */
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  /** keyOnboardingDescription input for default locale (de_CH) */
  keyOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  keyOnboardingImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** keyOnboardingName input for default locale (de_CH) */
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  keyOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** keyOnboardingSkipLink input for default locale (de_CH) */
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** keyOnboardingSubtitle input for default locale (de_CH) */
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<KeyOnboardingCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KeyOnboardingCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  keyOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KeyOnboardingCreateLocalizationInput = {
  /** Localization input */
  data: KeyOnboardingCreateLocalizationDataInput;
  locale: Locale;
};

export type KeyOnboardingCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<KeyOnboardingCreateLocalizationInput>>;
};

export type KeyOnboardingCreateManyInlineInput = {
  /** Connect multiple existing KeyOnboarding documents */
  connect?: InputMaybe<Array<KeyOnboardingWhereUniqueInput>>;
  /** Create and connect multiple existing KeyOnboarding documents */
  create?: InputMaybe<Array<KeyOnboardingCreateInput>>;
};

export type KeyOnboardingCreateOneInlineInput = {
  /** Connect one existing KeyOnboarding document */
  connect?: InputMaybe<KeyOnboardingWhereUniqueInput>;
  /** Create and connect one KeyOnboarding document */
  create?: InputMaybe<KeyOnboardingCreateInput>;
};

/** An edge in a connection. */
export type KeyOnboardingEdge = {
  __typename?: 'KeyOnboardingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: KeyOnboarding;
};

/** Identifies documents */
export type KeyOnboardingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeyOnboardingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeyOnboardingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeyOnboardingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  keyOnboardingImage?: InputMaybe<AssetWhereInput>;
  keyOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  keyOnboardingOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  keyOnboardingOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  keyOnboardingOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  keyOnboardingOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  keyOnboardingOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  keyOnboardingOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  keyOnboardingOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum KeyOnboardingOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyOnboardingButtonAsc = 'keyOnboardingButton_ASC',
  KeyOnboardingButtonDesc = 'keyOnboardingButton_DESC',
  KeyOnboardingNameAsc = 'keyOnboardingName_ASC',
  KeyOnboardingNameDesc = 'keyOnboardingName_DESC',
  KeyOnboardingOrderAsc = 'keyOnboardingOrder_ASC',
  KeyOnboardingOrderDesc = 'keyOnboardingOrder_DESC',
  KeyOnboardingSkipLinkAsc = 'keyOnboardingSkipLink_ASC',
  KeyOnboardingSkipLinkDesc = 'keyOnboardingSkipLink_DESC',
  KeyOnboardingSubtitleAsc = 'keyOnboardingSubtitle_ASC',
  KeyOnboardingSubtitleDesc = 'keyOnboardingSubtitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type KeyOnboardingUpdateInput = {
  /** keyOnboardingButton input for default locale (de_CH) */
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  /** keyOnboardingDescription input for default locale (de_CH) */
  keyOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  keyOnboardingImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** keyOnboardingName input for default locale (de_CH) */
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  keyOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** keyOnboardingSkipLink input for default locale (de_CH) */
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** keyOnboardingSubtitle input for default locale (de_CH) */
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<KeyOnboardingUpdateLocalizationsInput>;
};

export type KeyOnboardingUpdateLocalizationDataInput = {
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  keyOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
};

export type KeyOnboardingUpdateLocalizationInput = {
  data: KeyOnboardingUpdateLocalizationDataInput;
  locale: Locale;
};

export type KeyOnboardingUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<KeyOnboardingCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<KeyOnboardingUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<KeyOnboardingUpsertLocalizationInput>>;
};

export type KeyOnboardingUpdateManyInlineInput = {
  /** Connect multiple existing KeyOnboarding documents */
  connect?: InputMaybe<Array<KeyOnboardingConnectInput>>;
  /** Create and connect multiple KeyOnboarding documents */
  create?: InputMaybe<Array<KeyOnboardingCreateInput>>;
  /** Delete multiple KeyOnboarding documents */
  delete?: InputMaybe<Array<KeyOnboardingWhereUniqueInput>>;
  /** Disconnect multiple KeyOnboarding documents */
  disconnect?: InputMaybe<Array<KeyOnboardingWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing KeyOnboarding documents */
  set?: InputMaybe<Array<KeyOnboardingWhereUniqueInput>>;
  /** Update multiple KeyOnboarding documents */
  update?: InputMaybe<Array<KeyOnboardingUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple KeyOnboarding documents */
  upsert?: InputMaybe<Array<KeyOnboardingUpsertWithNestedWhereUniqueInput>>;
};

export type KeyOnboardingUpdateManyInput = {
  /** keyOnboardingButton input for default locale (de_CH) */
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  /** keyOnboardingDescription input for default locale (de_CH) */
  keyOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** keyOnboardingName input for default locale (de_CH) */
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  /** keyOnboardingSkipLink input for default locale (de_CH) */
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** keyOnboardingSubtitle input for default locale (de_CH) */
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<KeyOnboardingUpdateManyLocalizationsInput>;
};

export type KeyOnboardingUpdateManyLocalizationDataInput = {
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  keyOnboardingDescription?: InputMaybe<Scalars['RichTextAST']>;
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
};

export type KeyOnboardingUpdateManyLocalizationInput = {
  data: KeyOnboardingUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type KeyOnboardingUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<KeyOnboardingUpdateManyLocalizationInput>>;
};

export type KeyOnboardingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: KeyOnboardingUpdateManyInput;
  /** Document search */
  where: KeyOnboardingWhereInput;
};

export type KeyOnboardingUpdateOneInlineInput = {
  /** Connect existing KeyOnboarding document */
  connect?: InputMaybe<KeyOnboardingWhereUniqueInput>;
  /** Create and connect one KeyOnboarding document */
  create?: InputMaybe<KeyOnboardingCreateInput>;
  /** Delete currently connected KeyOnboarding document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected KeyOnboarding document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single KeyOnboarding document */
  update?: InputMaybe<KeyOnboardingUpdateWithNestedWhereUniqueInput>;
  /** Upsert single KeyOnboarding document */
  upsert?: InputMaybe<KeyOnboardingUpsertWithNestedWhereUniqueInput>;
};

export type KeyOnboardingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: KeyOnboardingUpdateInput;
  /** Unique document search */
  where: KeyOnboardingWhereUniqueInput;
};

export type KeyOnboardingUpsertInput = {
  /** Create document if it didn't exist */
  create: KeyOnboardingCreateInput;
  /** Update document if it exists */
  update: KeyOnboardingUpdateInput;
};

export type KeyOnboardingUpsertLocalizationInput = {
  create: KeyOnboardingCreateLocalizationDataInput;
  locale: Locale;
  update: KeyOnboardingUpdateLocalizationDataInput;
};

export type KeyOnboardingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: KeyOnboardingUpsertInput;
  /** Unique document search */
  where: KeyOnboardingWhereUniqueInput;
};

/** Identifies documents */
export type KeyOnboardingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeyOnboardingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeyOnboardingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeyOnboardingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  keyOnboardingButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  keyOnboardingButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  keyOnboardingButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  keyOnboardingButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  keyOnboardingButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  keyOnboardingButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  keyOnboardingButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  keyOnboardingButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  keyOnboardingButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  keyOnboardingButton_starts_with?: InputMaybe<Scalars['String']>;
  keyOnboardingImage?: InputMaybe<AssetWhereInput>;
  keyOnboardingName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  keyOnboardingName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  keyOnboardingName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  keyOnboardingName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  keyOnboardingName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  keyOnboardingName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  keyOnboardingName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  keyOnboardingName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  keyOnboardingName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  keyOnboardingName_starts_with?: InputMaybe<Scalars['String']>;
  keyOnboardingOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  keyOnboardingOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  keyOnboardingOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  keyOnboardingOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  keyOnboardingOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  keyOnboardingOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  keyOnboardingOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  keyOnboardingOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  keyOnboardingSkipLink?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  keyOnboardingSkipLink_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  keyOnboardingSkipLink_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  keyOnboardingSkipLink_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  keyOnboardingSkipLink_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  keyOnboardingSkipLink_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  keyOnboardingSkipLink_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  keyOnboardingSkipLink_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  keyOnboardingSkipLink_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  keyOnboardingSkipLink_starts_with?: InputMaybe<Scalars['String']>;
  keyOnboardingSubtitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  keyOnboardingSubtitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  keyOnboardingSubtitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  keyOnboardingSubtitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  keyOnboardingSubtitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  keyOnboardingSubtitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  keyOnboardingSubtitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  keyOnboardingSubtitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  keyOnboardingSubtitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  keyOnboardingSubtitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References KeyOnboarding record uniquely */
export type KeyOnboardingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  keyOnboardingOrder?: InputMaybe<Scalars['Int']>;
};

export enum KeyOrderByInput {
  CountdownEndedAsc = 'countdownEnded_ASC',
  CountdownEndedDesc = 'countdownEnded_DESC',
  CountdownTimeAsc = 'countdownTime_ASC',
  CountdownTimeDesc = 'countdownTime_DESC',
  CountdownToReturnAsc = 'countdownToReturn_ASC',
  CountdownToReturnDesc = 'countdownToReturn_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  ImportantInformationAsc = 'importantInformation_ASC',
  ImportantInformationDesc = 'importantInformation_DESC',
  KeyNameAsc = 'keyName_ASC',
  KeyNameDesc = 'keyName_DESC',
  NoKeyTextHeaderAsc = 'noKeyTextHeader_ASC',
  NoKeyTextHeaderDesc = 'noKeyTextHeader_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type KeyUpdateInput = {
  /** countdownEnded input for default locale (de_CH) */
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** countdownTime input for default locale (de_CH) */
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  /** countdownToReturn input for default locale (de_CH) */
  countdownToReturn?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['Int']>;
  /** importantInformation input for default locale (de_CH) */
  importantInformation?: InputMaybe<Scalars['String']>;
  importantInformationIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** keyName input for default locale (de_CH) */
  keyName?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<KeyUpdateLocalizationsInput>;
  noKeyImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** noKeyText input for default locale (de_CH) */
  noKeyText?: InputMaybe<Scalars['RichTextAST']>;
  /** noKeyTextHeader input for default locale (de_CH) */
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
};

export type KeyUpdateLocalizationDataInput = {
  countdownEnded?: InputMaybe<Scalars['String']>;
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  countdownToReturn?: InputMaybe<Scalars['String']>;
  importantInformation?: InputMaybe<Scalars['String']>;
  keyName?: InputMaybe<Scalars['String']>;
  noKeyText?: InputMaybe<Scalars['RichTextAST']>;
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
};

export type KeyUpdateLocalizationInput = {
  data: KeyUpdateLocalizationDataInput;
  locale: Locale;
};

export type KeyUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<KeyCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<KeyUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<KeyUpsertLocalizationInput>>;
};

export type KeyUpdateManyInlineInput = {
  /** Connect multiple existing Key documents */
  connect?: InputMaybe<Array<KeyConnectInput>>;
  /** Create and connect multiple Key documents */
  create?: InputMaybe<Array<KeyCreateInput>>;
  /** Delete multiple Key documents */
  delete?: InputMaybe<Array<KeyWhereUniqueInput>>;
  /** Disconnect multiple Key documents */
  disconnect?: InputMaybe<Array<KeyWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Key documents */
  set?: InputMaybe<Array<KeyWhereUniqueInput>>;
  /** Update multiple Key documents */
  update?: InputMaybe<Array<KeyUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Key documents */
  upsert?: InputMaybe<Array<KeyUpsertWithNestedWhereUniqueInput>>;
};

export type KeyUpdateManyInput = {
  /** countdownEnded input for default locale (de_CH) */
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** countdownTime input for default locale (de_CH) */
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  /** countdownToReturn input for default locale (de_CH) */
  countdownToReturn?: InputMaybe<Scalars['String']>;
  /** importantInformation input for default locale (de_CH) */
  importantInformation?: InputMaybe<Scalars['String']>;
  /** keyName input for default locale (de_CH) */
  keyName?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<KeyUpdateManyLocalizationsInput>;
  /** noKeyText input for default locale (de_CH) */
  noKeyText?: InputMaybe<Scalars['RichTextAST']>;
  /** noKeyTextHeader input for default locale (de_CH) */
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
};

export type KeyUpdateManyLocalizationDataInput = {
  countdownEnded?: InputMaybe<Scalars['String']>;
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  countdownToReturn?: InputMaybe<Scalars['String']>;
  importantInformation?: InputMaybe<Scalars['String']>;
  keyName?: InputMaybe<Scalars['String']>;
  noKeyText?: InputMaybe<Scalars['RichTextAST']>;
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
};

export type KeyUpdateManyLocalizationInput = {
  data: KeyUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type KeyUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<KeyUpdateManyLocalizationInput>>;
};

export type KeyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: KeyUpdateManyInput;
  /** Document search */
  where: KeyWhereInput;
};

export type KeyUpdateOneInlineInput = {
  /** Connect existing Key document */
  connect?: InputMaybe<KeyWhereUniqueInput>;
  /** Create and connect one Key document */
  create?: InputMaybe<KeyCreateInput>;
  /** Delete currently connected Key document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Key document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Key document */
  update?: InputMaybe<KeyUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Key document */
  upsert?: InputMaybe<KeyUpsertWithNestedWhereUniqueInput>;
};

export type KeyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: KeyUpdateInput;
  /** Unique document search */
  where: KeyWhereUniqueInput;
};

export type KeyUpsertInput = {
  /** Create document if it didn't exist */
  create: KeyCreateInput;
  /** Update document if it exists */
  update: KeyUpdateInput;
};

export type KeyUpsertLocalizationInput = {
  create: KeyCreateLocalizationDataInput;
  locale: Locale;
  update: KeyUpdateLocalizationDataInput;
};

export type KeyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: KeyUpsertInput;
  /** Unique document search */
  where: KeyWhereUniqueInput;
};

/** Identifies documents */
export type KeyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  countdownEnded_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  countdownEnded_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  countdownEnded_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  countdownEnded_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  countdownEnded_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  countdownEnded_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  countdownEnded_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  countdownEnded_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  countdownEnded_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countdownTime?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countdownTime_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countdownTime_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countdownTime_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countdownTime_not?: InputMaybe<Array<Scalars['String']>>;
  countdownToReturn?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  countdownToReturn_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  countdownToReturn_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  countdownToReturn_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  countdownToReturn_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  countdownToReturn_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  countdownToReturn_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  countdownToReturn_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  countdownToReturn_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  countdownToReturn_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  identifier?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  identifier_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  identifier_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  identifier_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  identifier_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  identifier_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  identifier_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  identifier_not_in?: InputMaybe<Array<Scalars['Int']>>;
  importantInformation?: InputMaybe<Scalars['String']>;
  importantInformationIcon?: InputMaybe<AssetWhereInput>;
  /** All values containing the given string. */
  importantInformation_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  importantInformation_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  importantInformation_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  importantInformation_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  importantInformation_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  importantInformation_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  importantInformation_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  importantInformation_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  importantInformation_starts_with?: InputMaybe<Scalars['String']>;
  keyName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  keyName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  keyName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  keyName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  keyName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  keyName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  keyName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  keyName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  keyName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  keyName_starts_with?: InputMaybe<Scalars['String']>;
  noKeyImage?: InputMaybe<AssetWhereInput>;
  noKeyTextHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noKeyTextHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noKeyTextHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noKeyTextHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noKeyTextHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noKeyTextHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noKeyTextHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noKeyTextHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noKeyTextHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noKeyTextHeader_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Key record uniquely */
export type KeyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  identifier?: InputMaybe<Scalars['Int']>;
};

/** Legal Content changes only infrequently. It's low formatted text only. */
export type Legal = Node & {
  __typename?: 'Legal';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Legal>;
  goSelections: Array<GoSelection>;
  /** List of Legal versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Legal>;
  profile?: Maybe<Profile>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** Formatted Body, consisting of lightly formatted text only (no embedded assets) */
  staticContentBody?: Maybe<RichText>;
  /** Name of the Static Content */
  staticContentName?: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalGoSelectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GoSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSelectionWhereInput>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalProfileArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Legal Content changes only infrequently. It's low formatted text only. */
export type LegalUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type LegalConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LegalWhereUniqueInput;
};

/** A connection to a list of items. */
export type LegalConnection = {
  __typename?: 'LegalConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LegalEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LegalCreateInput = {
  cl2g85qbo2n4a01xr9vt065jt?: InputMaybe<HamburgerMenuItemCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  goSelections?: InputMaybe<GoSelectionCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<LegalCreateLocalizationsInput>;
  profile?: InputMaybe<ProfileCreateOneInlineInput>;
  /** staticContentBody input for default locale (de_CH) */
  staticContentBody?: InputMaybe<Scalars['RichTextAST']>;
  /** staticContentName input for default locale (de_CH) */
  staticContentName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LegalCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  staticContentBody?: InputMaybe<Scalars['RichTextAST']>;
  staticContentName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LegalCreateLocalizationInput = {
  /** Localization input */
  data: LegalCreateLocalizationDataInput;
  locale: Locale;
};

export type LegalCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<LegalCreateLocalizationInput>>;
};

export type LegalCreateManyInlineInput = {
  /** Connect multiple existing Legal documents */
  connect?: InputMaybe<Array<LegalWhereUniqueInput>>;
  /** Create and connect multiple existing Legal documents */
  create?: InputMaybe<Array<LegalCreateInput>>;
};

export type LegalCreateOneInlineInput = {
  /** Connect one existing Legal document */
  connect?: InputMaybe<LegalWhereUniqueInput>;
  /** Create and connect one Legal document */
  create?: InputMaybe<LegalCreateInput>;
};

/** An edge in a connection. */
export type LegalEdge = {
  __typename?: 'LegalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Legal;
};

/** Identifies documents */
export type LegalManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LegalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LegalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LegalWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  goSelections_every?: InputMaybe<GoSelectionWhereInput>;
  goSelections_none?: InputMaybe<GoSelectionWhereInput>;
  goSelections_some?: InputMaybe<GoSelectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  profile?: InputMaybe<ProfileWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum LegalOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StaticContentNameAsc = 'staticContentName_ASC',
  StaticContentNameDesc = 'staticContentName_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type LegalUpdateInput = {
  cl2g85qbo2n4a01xr9vt065jt?: InputMaybe<HamburgerMenuItemUpdateManyInlineInput>;
  goSelections?: InputMaybe<GoSelectionUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<LegalUpdateLocalizationsInput>;
  profile?: InputMaybe<ProfileUpdateOneInlineInput>;
  /** staticContentBody input for default locale (de_CH) */
  staticContentBody?: InputMaybe<Scalars['RichTextAST']>;
  /** staticContentName input for default locale (de_CH) */
  staticContentName?: InputMaybe<Scalars['String']>;
};

export type LegalUpdateLocalizationDataInput = {
  staticContentBody?: InputMaybe<Scalars['RichTextAST']>;
  staticContentName?: InputMaybe<Scalars['String']>;
};

export type LegalUpdateLocalizationInput = {
  data: LegalUpdateLocalizationDataInput;
  locale: Locale;
};

export type LegalUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<LegalCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<LegalUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<LegalUpsertLocalizationInput>>;
};

export type LegalUpdateManyInlineInput = {
  /** Connect multiple existing Legal documents */
  connect?: InputMaybe<Array<LegalConnectInput>>;
  /** Create and connect multiple Legal documents */
  create?: InputMaybe<Array<LegalCreateInput>>;
  /** Delete multiple Legal documents */
  delete?: InputMaybe<Array<LegalWhereUniqueInput>>;
  /** Disconnect multiple Legal documents */
  disconnect?: InputMaybe<Array<LegalWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Legal documents */
  set?: InputMaybe<Array<LegalWhereUniqueInput>>;
  /** Update multiple Legal documents */
  update?: InputMaybe<Array<LegalUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Legal documents */
  upsert?: InputMaybe<Array<LegalUpsertWithNestedWhereUniqueInput>>;
};

export type LegalUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<LegalUpdateManyLocalizationsInput>;
  /** staticContentBody input for default locale (de_CH) */
  staticContentBody?: InputMaybe<Scalars['RichTextAST']>;
  /** staticContentName input for default locale (de_CH) */
  staticContentName?: InputMaybe<Scalars['String']>;
};

export type LegalUpdateManyLocalizationDataInput = {
  staticContentBody?: InputMaybe<Scalars['RichTextAST']>;
  staticContentName?: InputMaybe<Scalars['String']>;
};

export type LegalUpdateManyLocalizationInput = {
  data: LegalUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type LegalUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<LegalUpdateManyLocalizationInput>>;
};

export type LegalUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LegalUpdateManyInput;
  /** Document search */
  where: LegalWhereInput;
};

export type LegalUpdateOneInlineInput = {
  /** Connect existing Legal document */
  connect?: InputMaybe<LegalWhereUniqueInput>;
  /** Create and connect one Legal document */
  create?: InputMaybe<LegalCreateInput>;
  /** Delete currently connected Legal document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Legal document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Legal document */
  update?: InputMaybe<LegalUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Legal document */
  upsert?: InputMaybe<LegalUpsertWithNestedWhereUniqueInput>;
};

export type LegalUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LegalUpdateInput;
  /** Unique document search */
  where: LegalWhereUniqueInput;
};

export type LegalUpsertInput = {
  /** Create document if it didn't exist */
  create: LegalCreateInput;
  /** Update document if it exists */
  update: LegalUpdateInput;
};

export type LegalUpsertLocalizationInput = {
  create: LegalCreateLocalizationDataInput;
  locale: Locale;
  update: LegalUpdateLocalizationDataInput;
};

export type LegalUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LegalUpsertInput;
  /** Unique document search */
  where: LegalWhereUniqueInput;
};

/** Identifies documents */
export type LegalWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LegalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LegalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LegalWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  goSelections_every?: InputMaybe<GoSelectionWhereInput>;
  goSelections_none?: InputMaybe<GoSelectionWhereInput>;
  goSelections_some?: InputMaybe<GoSelectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  profile?: InputMaybe<ProfileWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  staticContentName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  staticContentName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  staticContentName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  staticContentName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  staticContentName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  staticContentName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  staticContentName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  staticContentName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  staticContentName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  staticContentName_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Legal record uniquely */
export type LegalWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  DeCh = 'de_CH',
  En = 'en',
  FrCh = 'fr_CH'
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location';
  distance: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LocationTabAvailableCarClasses = CarModel | Class;

export type LocationTabAvailableCarClassesConnectInput = {
  CarModel?: InputMaybe<CarModelConnectInput>;
  Class?: InputMaybe<ClassConnectInput>;
};

export type LocationTabAvailableCarClassesCreateInput = {
  CarModel?: InputMaybe<CarModelCreateInput>;
  Class?: InputMaybe<ClassCreateInput>;
};

export type LocationTabAvailableCarClassesCreateManyInlineInput = {
  /** Connect multiple existing LocationTabAvailableCarClasses documents */
  connect?: InputMaybe<Array<LocationTabAvailableCarClassesWhereUniqueInput>>;
  /** Create and connect multiple existing LocationTabAvailableCarClasses documents */
  create?: InputMaybe<Array<LocationTabAvailableCarClassesCreateInput>>;
};

export type LocationTabAvailableCarClassesCreateOneInlineInput = {
  /** Connect one existing LocationTabAvailableCarClasses document */
  connect?: InputMaybe<LocationTabAvailableCarClassesWhereUniqueInput>;
  /** Create and connect one LocationTabAvailableCarClasses document */
  create?: InputMaybe<LocationTabAvailableCarClassesCreateInput>;
};

export type LocationTabAvailableCarClassesUpdateInput = {
  CarModel?: InputMaybe<CarModelUpdateInput>;
  Class?: InputMaybe<ClassUpdateInput>;
};

export type LocationTabAvailableCarClassesUpdateManyInlineInput = {
  /** Connect multiple existing LocationTabAvailableCarClasses documents */
  connect?: InputMaybe<Array<LocationTabAvailableCarClassesConnectInput>>;
  /** Create and connect multiple LocationTabAvailableCarClasses documents */
  create?: InputMaybe<Array<LocationTabAvailableCarClassesCreateInput>>;
  /** Delete multiple LocationTabAvailableCarClasses documents */
  delete?: InputMaybe<Array<LocationTabAvailableCarClassesWhereUniqueInput>>;
  /** Disconnect multiple LocationTabAvailableCarClasses documents */
  disconnect?: InputMaybe<Array<LocationTabAvailableCarClassesWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LocationTabAvailableCarClasses documents */
  set?: InputMaybe<Array<LocationTabAvailableCarClassesWhereUniqueInput>>;
  /** Update multiple LocationTabAvailableCarClasses documents */
  update?: InputMaybe<Array<LocationTabAvailableCarClassesUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple LocationTabAvailableCarClasses documents */
  upsert?: InputMaybe<Array<LocationTabAvailableCarClassesUpsertWithNestedWhereUniqueInput>>;
};

export type LocationTabAvailableCarClassesUpdateManyWithNestedWhereInput = {
  CarModel?: InputMaybe<CarModelUpdateManyWithNestedWhereInput>;
  Class?: InputMaybe<ClassUpdateManyWithNestedWhereInput>;
};

export type LocationTabAvailableCarClassesUpdateOneInlineInput = {
  /** Connect existing LocationTabAvailableCarClasses document */
  connect?: InputMaybe<LocationTabAvailableCarClassesWhereUniqueInput>;
  /** Create and connect one LocationTabAvailableCarClasses document */
  create?: InputMaybe<LocationTabAvailableCarClassesCreateInput>;
  /** Delete currently connected LocationTabAvailableCarClasses document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected LocationTabAvailableCarClasses document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single LocationTabAvailableCarClasses document */
  update?: InputMaybe<LocationTabAvailableCarClassesUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LocationTabAvailableCarClasses document */
  upsert?: InputMaybe<LocationTabAvailableCarClassesUpsertWithNestedWhereUniqueInput>;
};

export type LocationTabAvailableCarClassesUpdateWithNestedWhereUniqueInput = {
  CarModel?: InputMaybe<CarModelUpdateWithNestedWhereUniqueInput>;
  Class?: InputMaybe<ClassUpdateWithNestedWhereUniqueInput>;
};

export type LocationTabAvailableCarClassesUpsertWithNestedWhereUniqueInput = {
  CarModel?: InputMaybe<CarModelUpsertWithNestedWhereUniqueInput>;
  Class?: InputMaybe<ClassUpsertWithNestedWhereUniqueInput>;
};

export type LocationTabAvailableCarClassesWhereInput = {
  CarModel?: InputMaybe<CarModelWhereInput>;
  Class?: InputMaybe<ClassWhereInput>;
};

export type LocationTabAvailableCarClassesWhereUniqueInput = {
  CarModel?: InputMaybe<CarModelWhereUniqueInput>;
  Class?: InputMaybe<ClassWhereUniqueInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create one appOnboarding */
  createAppOnboarding?: Maybe<AppOnboarding>;
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>;
  /** Create one banner */
  createBanner?: Maybe<Banner>;
  /** Create one bottomNavigationItem */
  createBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Create one calendar */
  createCalendar?: Maybe<Calendar>;
  /** Create one carModel */
  createCarModel?: Maybe<CarModel>;
  /** Create one carModelFeature */
  createCarModelFeature?: Maybe<CarModelFeature>;
  /** Create one class */
  createClass?: Maybe<Class>;
  /** Create one contact */
  createContact?: Maybe<Contact>;
  /** Create one coverage */
  createCoverage?: Maybe<Coverage>;
  /** Create one emergencyKeyOnboarding */
  createEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Create one errorMessage */
  createErrorMessage?: Maybe<ErrorMessage>;
  /** Create one extra */
  createExtra?: Maybe<Extra>;
  /** Create one gOLocation */
  createGOLocation?: Maybe<GoLocation>;
  /** Create one gOSearch */
  createGOSearch?: Maybe<GoSearch>;
  /** Create one goBookingJourney */
  createGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Create one goSelection */
  createGoSelection?: Maybe<GoSelection>;
  /** Create one goWebappBookingOverview */
  createGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Create one goWebappHomepage */
  createGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Create one goWebappNavLink */
  createGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Create one goWebappNavigationWizard */
  createGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Create one hamburgerMenuItem */
  createHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Create one homeScreen */
  createHomeScreen?: Maybe<HomeScreen>;
  /** Create one key */
  createKey?: Maybe<Key>;
  /** Create one keyOnboarding */
  createKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Create one legal */
  createLegal?: Maybe<Legal>;
  /** Create one popUpCard */
  createPopUpCard?: Maybe<PopUpCard>;
  /** Create one priceBreakdown */
  createPriceBreakdown?: Maybe<PriceBreakdown>;
  /** Create one profile */
  createProfile?: Maybe<Profile>;
  /** Create one rental */
  createRental?: Maybe<Rental>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>;
  /** Create one subClass */
  createSubClass?: Maybe<SubClass>;
  /** Create one successfulPaymentScreen */
  createSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Create one verification */
  createVerification?: Maybe<Verification>;
  /** Create one vin */
  createVin?: Maybe<Vin>;
  /** Delete one appOnboarding from _all_ existing stages. Returns deleted document. */
  deleteAppOnboarding?: Maybe<AppOnboarding>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Delete one banner from _all_ existing stages. Returns deleted document. */
  deleteBanner?: Maybe<Banner>;
  /** Delete one bottomNavigationItem from _all_ existing stages. Returns deleted document. */
  deleteBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Delete one calendar from _all_ existing stages. Returns deleted document. */
  deleteCalendar?: Maybe<Calendar>;
  /** Delete one carModel from _all_ existing stages. Returns deleted document. */
  deleteCarModel?: Maybe<CarModel>;
  /** Delete one carModelFeature from _all_ existing stages. Returns deleted document. */
  deleteCarModelFeature?: Maybe<CarModelFeature>;
  /** Delete one class from _all_ existing stages. Returns deleted document. */
  deleteClass?: Maybe<Class>;
  /** Delete one contact from _all_ existing stages. Returns deleted document. */
  deleteContact?: Maybe<Contact>;
  /** Delete one coverage from _all_ existing stages. Returns deleted document. */
  deleteCoverage?: Maybe<Coverage>;
  /** Delete one emergencyKeyOnboarding from _all_ existing stages. Returns deleted document. */
  deleteEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Delete one errorMessage from _all_ existing stages. Returns deleted document. */
  deleteErrorMessage?: Maybe<ErrorMessage>;
  /** Delete one extra from _all_ existing stages. Returns deleted document. */
  deleteExtra?: Maybe<Extra>;
  /** Delete one gOLocation from _all_ existing stages. Returns deleted document. */
  deleteGOLocation?: Maybe<GoLocation>;
  /** Delete one gOSearch from _all_ existing stages. Returns deleted document. */
  deleteGOSearch?: Maybe<GoSearch>;
  /** Delete one goBookingJourney from _all_ existing stages. Returns deleted document. */
  deleteGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Delete one goSelection from _all_ existing stages. Returns deleted document. */
  deleteGoSelection?: Maybe<GoSelection>;
  /** Delete one goWebappBookingOverview from _all_ existing stages. Returns deleted document. */
  deleteGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Delete one goWebappHomepage from _all_ existing stages. Returns deleted document. */
  deleteGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Delete one goWebappNavLink from _all_ existing stages. Returns deleted document. */
  deleteGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Delete one goWebappNavigationWizard from _all_ existing stages. Returns deleted document. */
  deleteGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Delete one hamburgerMenuItem from _all_ existing stages. Returns deleted document. */
  deleteHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Delete one homeScreen from _all_ existing stages. Returns deleted document. */
  deleteHomeScreen?: Maybe<HomeScreen>;
  /** Delete one key from _all_ existing stages. Returns deleted document. */
  deleteKey?: Maybe<Key>;
  /** Delete one keyOnboarding from _all_ existing stages. Returns deleted document. */
  deleteKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Delete one legal from _all_ existing stages. Returns deleted document. */
  deleteLegal?: Maybe<Legal>;
  /**
   * Delete many AppOnboarding documents
   * @deprecated Please use the new paginated many mutation (deleteManyAppOnboardingsConnection)
   */
  deleteManyAppOnboardings: BatchPayload;
  /** Delete many AppOnboarding documents, return deleted documents */
  deleteManyAppOnboardingsConnection: AppOnboardingConnection;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many Banner documents
   * @deprecated Please use the new paginated many mutation (deleteManyBannersConnection)
   */
  deleteManyBanners: BatchPayload;
  /** Delete many Banner documents, return deleted documents */
  deleteManyBannersConnection: BannerConnection;
  /**
   * Delete many BottomNavigationItem documents
   * @deprecated Please use the new paginated many mutation (deleteManyBottomNavigationItemsConnection)
   */
  deleteManyBottomNavigationItems: BatchPayload;
  /** Delete many BottomNavigationItem documents, return deleted documents */
  deleteManyBottomNavigationItemsConnection: BottomNavigationItemConnection;
  /**
   * Delete many Calendar documents
   * @deprecated Please use the new paginated many mutation (deleteManyCalendarsConnection)
   */
  deleteManyCalendars: BatchPayload;
  /** Delete many Calendar documents, return deleted documents */
  deleteManyCalendarsConnection: CalendarConnection;
  /**
   * Delete many CarModelFeature documents
   * @deprecated Please use the new paginated many mutation (deleteManyCarModelFeaturesConnection)
   */
  deleteManyCarModelFeatures: BatchPayload;
  /** Delete many CarModelFeature documents, return deleted documents */
  deleteManyCarModelFeaturesConnection: CarModelFeatureConnection;
  /**
   * Delete many CarModel documents
   * @deprecated Please use the new paginated many mutation (deleteManyCarModelsConnection)
   */
  deleteManyCarModels: BatchPayload;
  /** Delete many CarModel documents, return deleted documents */
  deleteManyCarModelsConnection: CarModelConnection;
  /**
   * Delete many Class documents
   * @deprecated Please use the new paginated many mutation (deleteManyClassesConnection)
   */
  deleteManyClasses: BatchPayload;
  /** Delete many Class documents, return deleted documents */
  deleteManyClassesConnection: ClassConnection;
  /**
   * Delete many Contact documents
   * @deprecated Please use the new paginated many mutation (deleteManyContactsConnection)
   */
  deleteManyContacts: BatchPayload;
  /** Delete many Contact documents, return deleted documents */
  deleteManyContactsConnection: ContactConnection;
  /**
   * Delete many Coverage documents
   * @deprecated Please use the new paginated many mutation (deleteManyCoveragesConnection)
   */
  deleteManyCoverages: BatchPayload;
  /** Delete many Coverage documents, return deleted documents */
  deleteManyCoveragesConnection: CoverageConnection;
  /**
   * Delete many EmergencyKeyOnboarding documents
   * @deprecated Please use the new paginated many mutation (deleteManyEmergencyKeyOnboardingsConnection)
   */
  deleteManyEmergencyKeyOnboardings: BatchPayload;
  /** Delete many EmergencyKeyOnboarding documents, return deleted documents */
  deleteManyEmergencyKeyOnboardingsConnection: EmergencyKeyOnboardingConnection;
  /**
   * Delete many ErrorMessage documents
   * @deprecated Please use the new paginated many mutation (deleteManyErrorMessagesConnection)
   */
  deleteManyErrorMessages: BatchPayload;
  /** Delete many ErrorMessage documents, return deleted documents */
  deleteManyErrorMessagesConnection: ErrorMessageConnection;
  /**
   * Delete many Extra documents
   * @deprecated Please use the new paginated many mutation (deleteManyExtrasConnection)
   */
  deleteManyExtras: BatchPayload;
  /** Delete many Extra documents, return deleted documents */
  deleteManyExtrasConnection: ExtraConnection;
  /**
   * Delete many GOSearch documents
   * @deprecated Please use the new paginated many mutation (deleteManyGOSearchesConnection)
   */
  deleteManyGOSearches: BatchPayload;
  /** Delete many GOSearch documents, return deleted documents */
  deleteManyGOSearchesConnection: GoSearchConnection;
  /**
   * Delete many GoBookingJourney documents
   * @deprecated Please use the new paginated many mutation (deleteManyGoBookingJourneysConnection)
   */
  deleteManyGoBookingJourneys: BatchPayload;
  /** Delete many GoBookingJourney documents, return deleted documents */
  deleteManyGoBookingJourneysConnection: GoBookingJourneyConnection;
  /**
   * Delete many GoSelection documents
   * @deprecated Please use the new paginated many mutation (deleteManyGoSelectionsConnection)
   */
  deleteManyGoSelections: BatchPayload;
  /** Delete many GoSelection documents, return deleted documents */
  deleteManyGoSelectionsConnection: GoSelectionConnection;
  /**
   * Delete many GoWebappBookingOverview documents
   * @deprecated Please use the new paginated many mutation (deleteManyGoWebappBookingOverviewsConnection)
   */
  deleteManyGoWebappBookingOverviews: BatchPayload;
  /** Delete many GoWebappBookingOverview documents, return deleted documents */
  deleteManyGoWebappBookingOverviewsConnection: GoWebappBookingOverviewConnection;
  /**
   * Delete many GoWebappHomepage documents
   * @deprecated Please use the new paginated many mutation (deleteManyGoWebappHomepagesConnection)
   */
  deleteManyGoWebappHomepages: BatchPayload;
  /** Delete many GoWebappHomepage documents, return deleted documents */
  deleteManyGoWebappHomepagesConnection: GoWebappHomepageConnection;
  /**
   * Delete many GoWebappNavLink documents
   * @deprecated Please use the new paginated many mutation (deleteManyGoWebappNavLinksConnection)
   */
  deleteManyGoWebappNavLinks: BatchPayload;
  /** Delete many GoWebappNavLink documents, return deleted documents */
  deleteManyGoWebappNavLinksConnection: GoWebappNavLinkConnection;
  /**
   * Delete many GoWebappNavigationWizard documents
   * @deprecated Please use the new paginated many mutation (deleteManyGoWebappNavigationWizardsConnection)
   */
  deleteManyGoWebappNavigationWizards: BatchPayload;
  /** Delete many GoWebappNavigationWizard documents, return deleted documents */
  deleteManyGoWebappNavigationWizardsConnection: GoWebappNavigationWizardConnection;
  /**
   * Delete many HamburgerMenuItem documents
   * @deprecated Please use the new paginated many mutation (deleteManyHamburgerMenuItemsConnection)
   */
  deleteManyHamburgerMenuItems: BatchPayload;
  /** Delete many HamburgerMenuItem documents, return deleted documents */
  deleteManyHamburgerMenuItemsConnection: HamburgerMenuItemConnection;
  /**
   * Delete many HomeScreen documents
   * @deprecated Please use the new paginated many mutation (deleteManyHomeScreensConnection)
   */
  deleteManyHomeScreens: BatchPayload;
  /** Delete many HomeScreen documents, return deleted documents */
  deleteManyHomeScreensConnection: HomeScreenConnection;
  /**
   * Delete many KeyOnboarding documents
   * @deprecated Please use the new paginated many mutation (deleteManyKeyOnboardingsConnection)
   */
  deleteManyKeyOnboardings: BatchPayload;
  /** Delete many KeyOnboarding documents, return deleted documents */
  deleteManyKeyOnboardingsConnection: KeyOnboardingConnection;
  /**
   * Delete many Key documents
   * @deprecated Please use the new paginated many mutation (deleteManyKeysConnection)
   */
  deleteManyKeys: BatchPayload;
  /** Delete many Key documents, return deleted documents */
  deleteManyKeysConnection: KeyConnection;
  /**
   * Delete many Legal documents
   * @deprecated Please use the new paginated many mutation (deleteManyLegalsConnection)
   */
  deleteManyLegals: BatchPayload;
  /** Delete many Legal documents, return deleted documents */
  deleteManyLegalsConnection: LegalConnection;
  /**
   * Delete many GOLocation documents
   * @deprecated Please use the new paginated many mutation (deleteManyLocationsConnection)
   */
  deleteManyLocations: BatchPayload;
  /** Delete many GOLocation documents, return deleted documents */
  deleteManyLocationsConnection: GoLocationConnection;
  /**
   * Delete many PopUpCard documents
   * @deprecated Please use the new paginated many mutation (deleteManyPopUpCardsConnection)
   */
  deleteManyPopUpCards: BatchPayload;
  /** Delete many PopUpCard documents, return deleted documents */
  deleteManyPopUpCardsConnection: PopUpCardConnection;
  /**
   * Delete many PriceBreakdown documents
   * @deprecated Please use the new paginated many mutation (deleteManyPriceBreakdownsConnection)
   */
  deleteManyPriceBreakdowns: BatchPayload;
  /** Delete many PriceBreakdown documents, return deleted documents */
  deleteManyPriceBreakdownsConnection: PriceBreakdownConnection;
  /**
   * Delete many Profile documents
   * @deprecated Please use the new paginated many mutation (deleteManyProfilesConnection)
   */
  deleteManyProfiles: BatchPayload;
  /** Delete many Profile documents, return deleted documents */
  deleteManyProfilesConnection: ProfileConnection;
  /**
   * Delete many Rental documents
   * @deprecated Please use the new paginated many mutation (deleteManyRentalsConnection)
   */
  deleteManyRentals: BatchPayload;
  /** Delete many Rental documents, return deleted documents */
  deleteManyRentalsConnection: RentalConnection;
  /**
   * Delete many SubClass documents
   * @deprecated Please use the new paginated many mutation (deleteManySubClassesConnection)
   */
  deleteManySubClasses: BatchPayload;
  /** Delete many SubClass documents, return deleted documents */
  deleteManySubClassesConnection: SubClassConnection;
  /**
   * Delete many SuccessfulPaymentScreen documents
   * @deprecated Please use the new paginated many mutation (deleteManySuccessfulPaymentScreensConnection)
   */
  deleteManySuccessfulPaymentScreens: BatchPayload;
  /** Delete many SuccessfulPaymentScreen documents, return deleted documents */
  deleteManySuccessfulPaymentScreensConnection: SuccessfulPaymentScreenConnection;
  /**
   * Delete many Verification documents
   * @deprecated Please use the new paginated many mutation (deleteManyVerificationsConnection)
   */
  deleteManyVerifications: BatchPayload;
  /** Delete many Verification documents, return deleted documents */
  deleteManyVerificationsConnection: VerificationConnection;
  /**
   * Delete many Vin documents
   * @deprecated Please use the new paginated many mutation (deleteManyVinsConnection)
   */
  deleteManyVins: BatchPayload;
  /** Delete many Vin documents, return deleted documents */
  deleteManyVinsConnection: VinConnection;
  /** Delete one popUpCard from _all_ existing stages. Returns deleted document. */
  deletePopUpCard?: Maybe<PopUpCard>;
  /** Delete one priceBreakdown from _all_ existing stages. Returns deleted document. */
  deletePriceBreakdown?: Maybe<PriceBreakdown>;
  /** Delete one profile from _all_ existing stages. Returns deleted document. */
  deleteProfile?: Maybe<Profile>;
  /** Delete one rental from _all_ existing stages. Returns deleted document. */
  deleteRental?: Maybe<Rental>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>;
  /** Delete one subClass from _all_ existing stages. Returns deleted document. */
  deleteSubClass?: Maybe<SubClass>;
  /** Delete one successfulPaymentScreen from _all_ existing stages. Returns deleted document. */
  deleteSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Delete one verification from _all_ existing stages. Returns deleted document. */
  deleteVerification?: Maybe<Verification>;
  /** Delete one vin from _all_ existing stages. Returns deleted document. */
  deleteVin?: Maybe<Vin>;
  /** Publish one appOnboarding */
  publishAppOnboarding?: Maybe<AppOnboarding>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Publish one banner */
  publishBanner?: Maybe<Banner>;
  /** Publish one bottomNavigationItem */
  publishBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Publish one calendar */
  publishCalendar?: Maybe<Calendar>;
  /** Publish one carModel */
  publishCarModel?: Maybe<CarModel>;
  /** Publish one carModelFeature */
  publishCarModelFeature?: Maybe<CarModelFeature>;
  /** Publish one class */
  publishClass?: Maybe<Class>;
  /** Publish one contact */
  publishContact?: Maybe<Contact>;
  /** Publish one coverage */
  publishCoverage?: Maybe<Coverage>;
  /** Publish one emergencyKeyOnboarding */
  publishEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Publish one errorMessage */
  publishErrorMessage?: Maybe<ErrorMessage>;
  /** Publish one extra */
  publishExtra?: Maybe<Extra>;
  /** Publish one gOLocation */
  publishGOLocation?: Maybe<GoLocation>;
  /** Publish one gOSearch */
  publishGOSearch?: Maybe<GoSearch>;
  /** Publish one goBookingJourney */
  publishGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Publish one goSelection */
  publishGoSelection?: Maybe<GoSelection>;
  /** Publish one goWebappBookingOverview */
  publishGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Publish one goWebappHomepage */
  publishGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Publish one goWebappNavLink */
  publishGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Publish one goWebappNavigationWizard */
  publishGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Publish one hamburgerMenuItem */
  publishHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Publish one homeScreen */
  publishHomeScreen?: Maybe<HomeScreen>;
  /** Publish one key */
  publishKey?: Maybe<Key>;
  /** Publish one keyOnboarding */
  publishKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Publish one legal */
  publishLegal?: Maybe<Legal>;
  /**
   * Publish many AppOnboarding documents
   * @deprecated Please use the new paginated many mutation (publishManyAppOnboardingsConnection)
   */
  publishManyAppOnboardings: BatchPayload;
  /** Publish many AppOnboarding documents */
  publishManyAppOnboardingsConnection: AppOnboardingConnection;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many Banner documents
   * @deprecated Please use the new paginated many mutation (publishManyBannersConnection)
   */
  publishManyBanners: BatchPayload;
  /** Publish many Banner documents */
  publishManyBannersConnection: BannerConnection;
  /**
   * Publish many BottomNavigationItem documents
   * @deprecated Please use the new paginated many mutation (publishManyBottomNavigationItemsConnection)
   */
  publishManyBottomNavigationItems: BatchPayload;
  /** Publish many BottomNavigationItem documents */
  publishManyBottomNavigationItemsConnection: BottomNavigationItemConnection;
  /**
   * Publish many Calendar documents
   * @deprecated Please use the new paginated many mutation (publishManyCalendarsConnection)
   */
  publishManyCalendars: BatchPayload;
  /** Publish many Calendar documents */
  publishManyCalendarsConnection: CalendarConnection;
  /**
   * Publish many CarModelFeature documents
   * @deprecated Please use the new paginated many mutation (publishManyCarModelFeaturesConnection)
   */
  publishManyCarModelFeatures: BatchPayload;
  /** Publish many CarModelFeature documents */
  publishManyCarModelFeaturesConnection: CarModelFeatureConnection;
  /**
   * Publish many CarModel documents
   * @deprecated Please use the new paginated many mutation (publishManyCarModelsConnection)
   */
  publishManyCarModels: BatchPayload;
  /** Publish many CarModel documents */
  publishManyCarModelsConnection: CarModelConnection;
  /**
   * Publish many Class documents
   * @deprecated Please use the new paginated many mutation (publishManyClassesConnection)
   */
  publishManyClasses: BatchPayload;
  /** Publish many Class documents */
  publishManyClassesConnection: ClassConnection;
  /**
   * Publish many Contact documents
   * @deprecated Please use the new paginated many mutation (publishManyContactsConnection)
   */
  publishManyContacts: BatchPayload;
  /** Publish many Contact documents */
  publishManyContactsConnection: ContactConnection;
  /**
   * Publish many Coverage documents
   * @deprecated Please use the new paginated many mutation (publishManyCoveragesConnection)
   */
  publishManyCoverages: BatchPayload;
  /** Publish many Coverage documents */
  publishManyCoveragesConnection: CoverageConnection;
  /**
   * Publish many EmergencyKeyOnboarding documents
   * @deprecated Please use the new paginated many mutation (publishManyEmergencyKeyOnboardingsConnection)
   */
  publishManyEmergencyKeyOnboardings: BatchPayload;
  /** Publish many EmergencyKeyOnboarding documents */
  publishManyEmergencyKeyOnboardingsConnection: EmergencyKeyOnboardingConnection;
  /**
   * Publish many ErrorMessage documents
   * @deprecated Please use the new paginated many mutation (publishManyErrorMessagesConnection)
   */
  publishManyErrorMessages: BatchPayload;
  /** Publish many ErrorMessage documents */
  publishManyErrorMessagesConnection: ErrorMessageConnection;
  /**
   * Publish many Extra documents
   * @deprecated Please use the new paginated many mutation (publishManyExtrasConnection)
   */
  publishManyExtras: BatchPayload;
  /** Publish many Extra documents */
  publishManyExtrasConnection: ExtraConnection;
  /**
   * Publish many GOSearch documents
   * @deprecated Please use the new paginated many mutation (publishManyGOSearchesConnection)
   */
  publishManyGOSearches: BatchPayload;
  /** Publish many GOSearch documents */
  publishManyGOSearchesConnection: GoSearchConnection;
  /**
   * Publish many GoBookingJourney documents
   * @deprecated Please use the new paginated many mutation (publishManyGoBookingJourneysConnection)
   */
  publishManyGoBookingJourneys: BatchPayload;
  /** Publish many GoBookingJourney documents */
  publishManyGoBookingJourneysConnection: GoBookingJourneyConnection;
  /**
   * Publish many GoSelection documents
   * @deprecated Please use the new paginated many mutation (publishManyGoSelectionsConnection)
   */
  publishManyGoSelections: BatchPayload;
  /** Publish many GoSelection documents */
  publishManyGoSelectionsConnection: GoSelectionConnection;
  /**
   * Publish many GoWebappBookingOverview documents
   * @deprecated Please use the new paginated many mutation (publishManyGoWebappBookingOverviewsConnection)
   */
  publishManyGoWebappBookingOverviews: BatchPayload;
  /** Publish many GoWebappBookingOverview documents */
  publishManyGoWebappBookingOverviewsConnection: GoWebappBookingOverviewConnection;
  /**
   * Publish many GoWebappHomepage documents
   * @deprecated Please use the new paginated many mutation (publishManyGoWebappHomepagesConnection)
   */
  publishManyGoWebappHomepages: BatchPayload;
  /** Publish many GoWebappHomepage documents */
  publishManyGoWebappHomepagesConnection: GoWebappHomepageConnection;
  /**
   * Publish many GoWebappNavLink documents
   * @deprecated Please use the new paginated many mutation (publishManyGoWebappNavLinksConnection)
   */
  publishManyGoWebappNavLinks: BatchPayload;
  /** Publish many GoWebappNavLink documents */
  publishManyGoWebappNavLinksConnection: GoWebappNavLinkConnection;
  /**
   * Publish many GoWebappNavigationWizard documents
   * @deprecated Please use the new paginated many mutation (publishManyGoWebappNavigationWizardsConnection)
   */
  publishManyGoWebappNavigationWizards: BatchPayload;
  /** Publish many GoWebappNavigationWizard documents */
  publishManyGoWebappNavigationWizardsConnection: GoWebappNavigationWizardConnection;
  /**
   * Publish many HamburgerMenuItem documents
   * @deprecated Please use the new paginated many mutation (publishManyHamburgerMenuItemsConnection)
   */
  publishManyHamburgerMenuItems: BatchPayload;
  /** Publish many HamburgerMenuItem documents */
  publishManyHamburgerMenuItemsConnection: HamburgerMenuItemConnection;
  /**
   * Publish many HomeScreen documents
   * @deprecated Please use the new paginated many mutation (publishManyHomeScreensConnection)
   */
  publishManyHomeScreens: BatchPayload;
  /** Publish many HomeScreen documents */
  publishManyHomeScreensConnection: HomeScreenConnection;
  /**
   * Publish many KeyOnboarding documents
   * @deprecated Please use the new paginated many mutation (publishManyKeyOnboardingsConnection)
   */
  publishManyKeyOnboardings: BatchPayload;
  /** Publish many KeyOnboarding documents */
  publishManyKeyOnboardingsConnection: KeyOnboardingConnection;
  /**
   * Publish many Key documents
   * @deprecated Please use the new paginated many mutation (publishManyKeysConnection)
   */
  publishManyKeys: BatchPayload;
  /** Publish many Key documents */
  publishManyKeysConnection: KeyConnection;
  /**
   * Publish many Legal documents
   * @deprecated Please use the new paginated many mutation (publishManyLegalsConnection)
   */
  publishManyLegals: BatchPayload;
  /** Publish many Legal documents */
  publishManyLegalsConnection: LegalConnection;
  /**
   * Publish many GOLocation documents
   * @deprecated Please use the new paginated many mutation (publishManyLocationsConnection)
   */
  publishManyLocations: BatchPayload;
  /** Publish many GOLocation documents */
  publishManyLocationsConnection: GoLocationConnection;
  /**
   * Publish many PopUpCard documents
   * @deprecated Please use the new paginated many mutation (publishManyPopUpCardsConnection)
   */
  publishManyPopUpCards: BatchPayload;
  /** Publish many PopUpCard documents */
  publishManyPopUpCardsConnection: PopUpCardConnection;
  /**
   * Publish many PriceBreakdown documents
   * @deprecated Please use the new paginated many mutation (publishManyPriceBreakdownsConnection)
   */
  publishManyPriceBreakdowns: BatchPayload;
  /** Publish many PriceBreakdown documents */
  publishManyPriceBreakdownsConnection: PriceBreakdownConnection;
  /**
   * Publish many Profile documents
   * @deprecated Please use the new paginated many mutation (publishManyProfilesConnection)
   */
  publishManyProfiles: BatchPayload;
  /** Publish many Profile documents */
  publishManyProfilesConnection: ProfileConnection;
  /**
   * Publish many Rental documents
   * @deprecated Please use the new paginated many mutation (publishManyRentalsConnection)
   */
  publishManyRentals: BatchPayload;
  /** Publish many Rental documents */
  publishManyRentalsConnection: RentalConnection;
  /**
   * Publish many SubClass documents
   * @deprecated Please use the new paginated many mutation (publishManySubClassesConnection)
   */
  publishManySubClasses: BatchPayload;
  /** Publish many SubClass documents */
  publishManySubClassesConnection: SubClassConnection;
  /**
   * Publish many SuccessfulPaymentScreen documents
   * @deprecated Please use the new paginated many mutation (publishManySuccessfulPaymentScreensConnection)
   */
  publishManySuccessfulPaymentScreens: BatchPayload;
  /** Publish many SuccessfulPaymentScreen documents */
  publishManySuccessfulPaymentScreensConnection: SuccessfulPaymentScreenConnection;
  /**
   * Publish many Verification documents
   * @deprecated Please use the new paginated many mutation (publishManyVerificationsConnection)
   */
  publishManyVerifications: BatchPayload;
  /** Publish many Verification documents */
  publishManyVerificationsConnection: VerificationConnection;
  /**
   * Publish many Vin documents
   * @deprecated Please use the new paginated many mutation (publishManyVinsConnection)
   */
  publishManyVins: BatchPayload;
  /** Publish many Vin documents */
  publishManyVinsConnection: VinConnection;
  /** Publish one popUpCard */
  publishPopUpCard?: Maybe<PopUpCard>;
  /** Publish one priceBreakdown */
  publishPriceBreakdown?: Maybe<PriceBreakdown>;
  /** Publish one profile */
  publishProfile?: Maybe<Profile>;
  /** Publish one rental */
  publishRental?: Maybe<Rental>;
  /** Publish one subClass */
  publishSubClass?: Maybe<SubClass>;
  /** Publish one successfulPaymentScreen */
  publishSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Publish one verification */
  publishVerification?: Maybe<Verification>;
  /** Publish one vin */
  publishVin?: Maybe<Vin>;
  /** Schedule to publish one appOnboarding */
  schedulePublishAppOnboarding?: Maybe<AppOnboarding>;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>;
  /** Schedule to publish one banner */
  schedulePublishBanner?: Maybe<Banner>;
  /** Schedule to publish one bottomNavigationItem */
  schedulePublishBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Schedule to publish one calendar */
  schedulePublishCalendar?: Maybe<Calendar>;
  /** Schedule to publish one carModel */
  schedulePublishCarModel?: Maybe<CarModel>;
  /** Schedule to publish one carModelFeature */
  schedulePublishCarModelFeature?: Maybe<CarModelFeature>;
  /** Schedule to publish one class */
  schedulePublishClass?: Maybe<Class>;
  /** Schedule to publish one contact */
  schedulePublishContact?: Maybe<Contact>;
  /** Schedule to publish one coverage */
  schedulePublishCoverage?: Maybe<Coverage>;
  /** Schedule to publish one emergencyKeyOnboarding */
  schedulePublishEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Schedule to publish one errorMessage */
  schedulePublishErrorMessage?: Maybe<ErrorMessage>;
  /** Schedule to publish one extra */
  schedulePublishExtra?: Maybe<Extra>;
  /** Schedule to publish one gOLocation */
  schedulePublishGOLocation?: Maybe<GoLocation>;
  /** Schedule to publish one gOSearch */
  schedulePublishGOSearch?: Maybe<GoSearch>;
  /** Schedule to publish one goBookingJourney */
  schedulePublishGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Schedule to publish one goSelection */
  schedulePublishGoSelection?: Maybe<GoSelection>;
  /** Schedule to publish one goWebappBookingOverview */
  schedulePublishGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Schedule to publish one goWebappHomepage */
  schedulePublishGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Schedule to publish one goWebappNavLink */
  schedulePublishGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Schedule to publish one goWebappNavigationWizard */
  schedulePublishGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Schedule to publish one hamburgerMenuItem */
  schedulePublishHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Schedule to publish one homeScreen */
  schedulePublishHomeScreen?: Maybe<HomeScreen>;
  /** Schedule to publish one key */
  schedulePublishKey?: Maybe<Key>;
  /** Schedule to publish one keyOnboarding */
  schedulePublishKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Schedule to publish one legal */
  schedulePublishLegal?: Maybe<Legal>;
  /** Schedule to publish one popUpCard */
  schedulePublishPopUpCard?: Maybe<PopUpCard>;
  /** Schedule to publish one priceBreakdown */
  schedulePublishPriceBreakdown?: Maybe<PriceBreakdown>;
  /** Schedule to publish one profile */
  schedulePublishProfile?: Maybe<Profile>;
  /** Schedule to publish one rental */
  schedulePublishRental?: Maybe<Rental>;
  /** Schedule to publish one subClass */
  schedulePublishSubClass?: Maybe<SubClass>;
  /** Schedule to publish one successfulPaymentScreen */
  schedulePublishSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Schedule to publish one verification */
  schedulePublishVerification?: Maybe<Verification>;
  /** Schedule to publish one vin */
  schedulePublishVin?: Maybe<Vin>;
  /** Unpublish one appOnboarding from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAppOnboarding?: Maybe<AppOnboarding>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>;
  /** Unpublish one banner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBanner?: Maybe<Banner>;
  /** Unpublish one bottomNavigationItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Unpublish one calendar from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCalendar?: Maybe<Calendar>;
  /** Unpublish one carModel from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCarModel?: Maybe<CarModel>;
  /** Unpublish one carModelFeature from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCarModelFeature?: Maybe<CarModelFeature>;
  /** Unpublish one class from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishClass?: Maybe<Class>;
  /** Unpublish one contact from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishContact?: Maybe<Contact>;
  /** Unpublish one coverage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCoverage?: Maybe<Coverage>;
  /** Unpublish one emergencyKeyOnboarding from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Unpublish one errorMessage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishErrorMessage?: Maybe<ErrorMessage>;
  /** Unpublish one extra from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishExtra?: Maybe<Extra>;
  /** Unpublish one gOLocation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGOLocation?: Maybe<GoLocation>;
  /** Unpublish one gOSearch from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGOSearch?: Maybe<GoSearch>;
  /** Unpublish one goBookingJourney from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Unpublish one goSelection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGoSelection?: Maybe<GoSelection>;
  /** Unpublish one goWebappBookingOverview from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Unpublish one goWebappHomepage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Unpublish one goWebappNavLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Unpublish one goWebappNavigationWizard from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Unpublish one hamburgerMenuItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Unpublish one homeScreen from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHomeScreen?: Maybe<HomeScreen>;
  /** Unpublish one key from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishKey?: Maybe<Key>;
  /** Unpublish one keyOnboarding from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Unpublish one legal from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLegal?: Maybe<Legal>;
  /** Unpublish one popUpCard from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPopUpCard?: Maybe<PopUpCard>;
  /** Unpublish one priceBreakdown from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPriceBreakdown?: Maybe<PriceBreakdown>;
  /** Unpublish one profile from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProfile?: Maybe<Profile>;
  /** Unpublish one rental from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishRental?: Maybe<Rental>;
  /** Unpublish one subClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSubClass?: Maybe<SubClass>;
  /** Unpublish one successfulPaymentScreen from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Unpublish one verification from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVerification?: Maybe<Verification>;
  /** Unpublish one vin from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVin?: Maybe<Vin>;
  /** Unpublish one appOnboarding from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAppOnboarding?: Maybe<AppOnboarding>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Unpublish one banner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBanner?: Maybe<Banner>;
  /** Unpublish one bottomNavigationItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Unpublish one calendar from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCalendar?: Maybe<Calendar>;
  /** Unpublish one carModel from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCarModel?: Maybe<CarModel>;
  /** Unpublish one carModelFeature from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCarModelFeature?: Maybe<CarModelFeature>;
  /** Unpublish one class from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishClass?: Maybe<Class>;
  /** Unpublish one contact from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishContact?: Maybe<Contact>;
  /** Unpublish one coverage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCoverage?: Maybe<Coverage>;
  /** Unpublish one emergencyKeyOnboarding from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Unpublish one errorMessage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishErrorMessage?: Maybe<ErrorMessage>;
  /** Unpublish one extra from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishExtra?: Maybe<Extra>;
  /** Unpublish one gOLocation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGOLocation?: Maybe<GoLocation>;
  /** Unpublish one gOSearch from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGOSearch?: Maybe<GoSearch>;
  /** Unpublish one goBookingJourney from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Unpublish one goSelection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGoSelection?: Maybe<GoSelection>;
  /** Unpublish one goWebappBookingOverview from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Unpublish one goWebappHomepage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Unpublish one goWebappNavLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Unpublish one goWebappNavigationWizard from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Unpublish one hamburgerMenuItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Unpublish one homeScreen from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHomeScreen?: Maybe<HomeScreen>;
  /** Unpublish one key from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishKey?: Maybe<Key>;
  /** Unpublish one keyOnboarding from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Unpublish one legal from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLegal?: Maybe<Legal>;
  /**
   * Unpublish many AppOnboarding documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAppOnboardingsConnection)
   */
  unpublishManyAppOnboardings: BatchPayload;
  /** Find many AppOnboarding documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAppOnboardingsConnection: AppOnboardingConnection;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many Banner documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBannersConnection)
   */
  unpublishManyBanners: BatchPayload;
  /** Find many Banner documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBannersConnection: BannerConnection;
  /**
   * Unpublish many BottomNavigationItem documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBottomNavigationItemsConnection)
   */
  unpublishManyBottomNavigationItems: BatchPayload;
  /** Find many BottomNavigationItem documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBottomNavigationItemsConnection: BottomNavigationItemConnection;
  /**
   * Unpublish many Calendar documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCalendarsConnection)
   */
  unpublishManyCalendars: BatchPayload;
  /** Find many Calendar documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCalendarsConnection: CalendarConnection;
  /**
   * Unpublish many CarModelFeature documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCarModelFeaturesConnection)
   */
  unpublishManyCarModelFeatures: BatchPayload;
  /** Find many CarModelFeature documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCarModelFeaturesConnection: CarModelFeatureConnection;
  /**
   * Unpublish many CarModel documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCarModelsConnection)
   */
  unpublishManyCarModels: BatchPayload;
  /** Find many CarModel documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCarModelsConnection: CarModelConnection;
  /**
   * Unpublish many Class documents
   * @deprecated Please use the new paginated many mutation (unpublishManyClassesConnection)
   */
  unpublishManyClasses: BatchPayload;
  /** Find many Class documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyClassesConnection: ClassConnection;
  /**
   * Unpublish many Contact documents
   * @deprecated Please use the new paginated many mutation (unpublishManyContactsConnection)
   */
  unpublishManyContacts: BatchPayload;
  /** Find many Contact documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyContactsConnection: ContactConnection;
  /**
   * Unpublish many Coverage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCoveragesConnection)
   */
  unpublishManyCoverages: BatchPayload;
  /** Find many Coverage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCoveragesConnection: CoverageConnection;
  /**
   * Unpublish many EmergencyKeyOnboarding documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEmergencyKeyOnboardingsConnection)
   */
  unpublishManyEmergencyKeyOnboardings: BatchPayload;
  /** Find many EmergencyKeyOnboarding documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEmergencyKeyOnboardingsConnection: EmergencyKeyOnboardingConnection;
  /**
   * Unpublish many ErrorMessage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyErrorMessagesConnection)
   */
  unpublishManyErrorMessages: BatchPayload;
  /** Find many ErrorMessage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyErrorMessagesConnection: ErrorMessageConnection;
  /**
   * Unpublish many Extra documents
   * @deprecated Please use the new paginated many mutation (unpublishManyExtrasConnection)
   */
  unpublishManyExtras: BatchPayload;
  /** Find many Extra documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyExtrasConnection: ExtraConnection;
  /**
   * Unpublish many GOSearch documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGOSearchesConnection)
   */
  unpublishManyGOSearches: BatchPayload;
  /** Find many GOSearch documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGOSearchesConnection: GoSearchConnection;
  /**
   * Unpublish many GoBookingJourney documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGoBookingJourneysConnection)
   */
  unpublishManyGoBookingJourneys: BatchPayload;
  /** Find many GoBookingJourney documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGoBookingJourneysConnection: GoBookingJourneyConnection;
  /**
   * Unpublish many GoSelection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGoSelectionsConnection)
   */
  unpublishManyGoSelections: BatchPayload;
  /** Find many GoSelection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGoSelectionsConnection: GoSelectionConnection;
  /**
   * Unpublish many GoWebappBookingOverview documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGoWebappBookingOverviewsConnection)
   */
  unpublishManyGoWebappBookingOverviews: BatchPayload;
  /** Find many GoWebappBookingOverview documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGoWebappBookingOverviewsConnection: GoWebappBookingOverviewConnection;
  /**
   * Unpublish many GoWebappHomepage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGoWebappHomepagesConnection)
   */
  unpublishManyGoWebappHomepages: BatchPayload;
  /** Find many GoWebappHomepage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGoWebappHomepagesConnection: GoWebappHomepageConnection;
  /**
   * Unpublish many GoWebappNavLink documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGoWebappNavLinksConnection)
   */
  unpublishManyGoWebappNavLinks: BatchPayload;
  /** Find many GoWebappNavLink documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGoWebappNavLinksConnection: GoWebappNavLinkConnection;
  /**
   * Unpublish many GoWebappNavigationWizard documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGoWebappNavigationWizardsConnection)
   */
  unpublishManyGoWebappNavigationWizards: BatchPayload;
  /** Find many GoWebappNavigationWizard documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGoWebappNavigationWizardsConnection: GoWebappNavigationWizardConnection;
  /**
   * Unpublish many HamburgerMenuItem documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHamburgerMenuItemsConnection)
   */
  unpublishManyHamburgerMenuItems: BatchPayload;
  /** Find many HamburgerMenuItem documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHamburgerMenuItemsConnection: HamburgerMenuItemConnection;
  /**
   * Unpublish many HomeScreen documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHomeScreensConnection)
   */
  unpublishManyHomeScreens: BatchPayload;
  /** Find many HomeScreen documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHomeScreensConnection: HomeScreenConnection;
  /**
   * Unpublish many KeyOnboarding documents
   * @deprecated Please use the new paginated many mutation (unpublishManyKeyOnboardingsConnection)
   */
  unpublishManyKeyOnboardings: BatchPayload;
  /** Find many KeyOnboarding documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyKeyOnboardingsConnection: KeyOnboardingConnection;
  /**
   * Unpublish many Key documents
   * @deprecated Please use the new paginated many mutation (unpublishManyKeysConnection)
   */
  unpublishManyKeys: BatchPayload;
  /** Find many Key documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyKeysConnection: KeyConnection;
  /**
   * Unpublish many Legal documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLegalsConnection)
   */
  unpublishManyLegals: BatchPayload;
  /** Find many Legal documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLegalsConnection: LegalConnection;
  /**
   * Unpublish many GOLocation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLocationsConnection)
   */
  unpublishManyLocations: BatchPayload;
  /** Find many GOLocation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLocationsConnection: GoLocationConnection;
  /**
   * Unpublish many PopUpCard documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPopUpCardsConnection)
   */
  unpublishManyPopUpCards: BatchPayload;
  /** Find many PopUpCard documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPopUpCardsConnection: PopUpCardConnection;
  /**
   * Unpublish many PriceBreakdown documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPriceBreakdownsConnection)
   */
  unpublishManyPriceBreakdowns: BatchPayload;
  /** Find many PriceBreakdown documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPriceBreakdownsConnection: PriceBreakdownConnection;
  /**
   * Unpublish many Profile documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProfilesConnection)
   */
  unpublishManyProfiles: BatchPayload;
  /** Find many Profile documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProfilesConnection: ProfileConnection;
  /**
   * Unpublish many Rental documents
   * @deprecated Please use the new paginated many mutation (unpublishManyRentalsConnection)
   */
  unpublishManyRentals: BatchPayload;
  /** Find many Rental documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyRentalsConnection: RentalConnection;
  /**
   * Unpublish many SubClass documents
   * @deprecated Please use the new paginated many mutation (unpublishManySubClassesConnection)
   */
  unpublishManySubClasses: BatchPayload;
  /** Find many SubClass documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySubClassesConnection: SubClassConnection;
  /**
   * Unpublish many SuccessfulPaymentScreen documents
   * @deprecated Please use the new paginated many mutation (unpublishManySuccessfulPaymentScreensConnection)
   */
  unpublishManySuccessfulPaymentScreens: BatchPayload;
  /** Find many SuccessfulPaymentScreen documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySuccessfulPaymentScreensConnection: SuccessfulPaymentScreenConnection;
  /**
   * Unpublish many Verification documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVerificationsConnection)
   */
  unpublishManyVerifications: BatchPayload;
  /** Find many Verification documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVerificationsConnection: VerificationConnection;
  /**
   * Unpublish many Vin documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVinsConnection)
   */
  unpublishManyVins: BatchPayload;
  /** Find many Vin documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVinsConnection: VinConnection;
  /** Unpublish one popUpCard from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPopUpCard?: Maybe<PopUpCard>;
  /** Unpublish one priceBreakdown from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPriceBreakdown?: Maybe<PriceBreakdown>;
  /** Unpublish one profile from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProfile?: Maybe<Profile>;
  /** Unpublish one rental from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishRental?: Maybe<Rental>;
  /** Unpublish one subClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSubClass?: Maybe<SubClass>;
  /** Unpublish one successfulPaymentScreen from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Unpublish one verification from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVerification?: Maybe<Verification>;
  /** Unpublish one vin from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVin?: Maybe<Vin>;
  /** Update one appOnboarding */
  updateAppOnboarding?: Maybe<AppOnboarding>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Update one banner */
  updateBanner?: Maybe<Banner>;
  /** Update one bottomNavigationItem */
  updateBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Update one calendar */
  updateCalendar?: Maybe<Calendar>;
  /** Update one carModel */
  updateCarModel?: Maybe<CarModel>;
  /** Update one carModelFeature */
  updateCarModelFeature?: Maybe<CarModelFeature>;
  /** Update one class */
  updateClass?: Maybe<Class>;
  /** Update one contact */
  updateContact?: Maybe<Contact>;
  /** Update one coverage */
  updateCoverage?: Maybe<Coverage>;
  /** Update one emergencyKeyOnboarding */
  updateEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Update one errorMessage */
  updateErrorMessage?: Maybe<ErrorMessage>;
  /** Update one extra */
  updateExtra?: Maybe<Extra>;
  /** Update one gOLocation */
  updateGOLocation?: Maybe<GoLocation>;
  /** Update one gOSearch */
  updateGOSearch?: Maybe<GoSearch>;
  /** Update one goBookingJourney */
  updateGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Update one goSelection */
  updateGoSelection?: Maybe<GoSelection>;
  /** Update one goWebappBookingOverview */
  updateGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Update one goWebappHomepage */
  updateGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Update one goWebappNavLink */
  updateGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Update one goWebappNavigationWizard */
  updateGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Update one hamburgerMenuItem */
  updateHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Update one homeScreen */
  updateHomeScreen?: Maybe<HomeScreen>;
  /** Update one key */
  updateKey?: Maybe<Key>;
  /** Update one keyOnboarding */
  updateKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Update one legal */
  updateLegal?: Maybe<Legal>;
  /**
   * Update many appOnboardings
   * @deprecated Please use the new paginated many mutation (updateManyAppOnboardingsConnection)
   */
  updateManyAppOnboardings: BatchPayload;
  /** Update many AppOnboarding documents */
  updateManyAppOnboardingsConnection: AppOnboardingConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many banners
   * @deprecated Please use the new paginated many mutation (updateManyBannersConnection)
   */
  updateManyBanners: BatchPayload;
  /** Update many Banner documents */
  updateManyBannersConnection: BannerConnection;
  /**
   * Update many bottomNavigationItems
   * @deprecated Please use the new paginated many mutation (updateManyBottomNavigationItemsConnection)
   */
  updateManyBottomNavigationItems: BatchPayload;
  /** Update many BottomNavigationItem documents */
  updateManyBottomNavigationItemsConnection: BottomNavigationItemConnection;
  /**
   * Update many calendars
   * @deprecated Please use the new paginated many mutation (updateManyCalendarsConnection)
   */
  updateManyCalendars: BatchPayload;
  /** Update many Calendar documents */
  updateManyCalendarsConnection: CalendarConnection;
  /**
   * Update many carModelFeatures
   * @deprecated Please use the new paginated many mutation (updateManyCarModelFeaturesConnection)
   */
  updateManyCarModelFeatures: BatchPayload;
  /** Update many CarModelFeature documents */
  updateManyCarModelFeaturesConnection: CarModelFeatureConnection;
  /**
   * Update many carModels
   * @deprecated Please use the new paginated many mutation (updateManyCarModelsConnection)
   */
  updateManyCarModels: BatchPayload;
  /** Update many CarModel documents */
  updateManyCarModelsConnection: CarModelConnection;
  /**
   * Update many classes
   * @deprecated Please use the new paginated many mutation (updateManyClassesConnection)
   */
  updateManyClasses: BatchPayload;
  /** Update many Class documents */
  updateManyClassesConnection: ClassConnection;
  /**
   * Update many contacts
   * @deprecated Please use the new paginated many mutation (updateManyContactsConnection)
   */
  updateManyContacts: BatchPayload;
  /** Update many Contact documents */
  updateManyContactsConnection: ContactConnection;
  /**
   * Update many coverages
   * @deprecated Please use the new paginated many mutation (updateManyCoveragesConnection)
   */
  updateManyCoverages: BatchPayload;
  /** Update many Coverage documents */
  updateManyCoveragesConnection: CoverageConnection;
  /**
   * Update many emergencyKeyOnboardings
   * @deprecated Please use the new paginated many mutation (updateManyEmergencyKeyOnboardingsConnection)
   */
  updateManyEmergencyKeyOnboardings: BatchPayload;
  /** Update many EmergencyKeyOnboarding documents */
  updateManyEmergencyKeyOnboardingsConnection: EmergencyKeyOnboardingConnection;
  /**
   * Update many errorMessages
   * @deprecated Please use the new paginated many mutation (updateManyErrorMessagesConnection)
   */
  updateManyErrorMessages: BatchPayload;
  /** Update many ErrorMessage documents */
  updateManyErrorMessagesConnection: ErrorMessageConnection;
  /**
   * Update many extras
   * @deprecated Please use the new paginated many mutation (updateManyExtrasConnection)
   */
  updateManyExtras: BatchPayload;
  /** Update many Extra documents */
  updateManyExtrasConnection: ExtraConnection;
  /**
   * Update many gOSearches
   * @deprecated Please use the new paginated many mutation (updateManyGOSearchesConnection)
   */
  updateManyGOSearches: BatchPayload;
  /** Update many GOSearch documents */
  updateManyGOSearchesConnection: GoSearchConnection;
  /**
   * Update many goBookingJourneys
   * @deprecated Please use the new paginated many mutation (updateManyGoBookingJourneysConnection)
   */
  updateManyGoBookingJourneys: BatchPayload;
  /** Update many GoBookingJourney documents */
  updateManyGoBookingJourneysConnection: GoBookingJourneyConnection;
  /**
   * Update many goSelections
   * @deprecated Please use the new paginated many mutation (updateManyGoSelectionsConnection)
   */
  updateManyGoSelections: BatchPayload;
  /** Update many GoSelection documents */
  updateManyGoSelectionsConnection: GoSelectionConnection;
  /**
   * Update many goWebappBookingOverviews
   * @deprecated Please use the new paginated many mutation (updateManyGoWebappBookingOverviewsConnection)
   */
  updateManyGoWebappBookingOverviews: BatchPayload;
  /** Update many GoWebappBookingOverview documents */
  updateManyGoWebappBookingOverviewsConnection: GoWebappBookingOverviewConnection;
  /**
   * Update many goWebappHomepages
   * @deprecated Please use the new paginated many mutation (updateManyGoWebappHomepagesConnection)
   */
  updateManyGoWebappHomepages: BatchPayload;
  /** Update many GoWebappHomepage documents */
  updateManyGoWebappHomepagesConnection: GoWebappHomepageConnection;
  /**
   * Update many goWebappNavLinks
   * @deprecated Please use the new paginated many mutation (updateManyGoWebappNavLinksConnection)
   */
  updateManyGoWebappNavLinks: BatchPayload;
  /** Update many GoWebappNavLink documents */
  updateManyGoWebappNavLinksConnection: GoWebappNavLinkConnection;
  /**
   * Update many goWebappNavigationWizards
   * @deprecated Please use the new paginated many mutation (updateManyGoWebappNavigationWizardsConnection)
   */
  updateManyGoWebappNavigationWizards: BatchPayload;
  /** Update many GoWebappNavigationWizard documents */
  updateManyGoWebappNavigationWizardsConnection: GoWebappNavigationWizardConnection;
  /**
   * Update many hamburgerMenuItems
   * @deprecated Please use the new paginated many mutation (updateManyHamburgerMenuItemsConnection)
   */
  updateManyHamburgerMenuItems: BatchPayload;
  /** Update many HamburgerMenuItem documents */
  updateManyHamburgerMenuItemsConnection: HamburgerMenuItemConnection;
  /**
   * Update many homeScreens
   * @deprecated Please use the new paginated many mutation (updateManyHomeScreensConnection)
   */
  updateManyHomeScreens: BatchPayload;
  /** Update many HomeScreen documents */
  updateManyHomeScreensConnection: HomeScreenConnection;
  /**
   * Update many keyOnboardings
   * @deprecated Please use the new paginated many mutation (updateManyKeyOnboardingsConnection)
   */
  updateManyKeyOnboardings: BatchPayload;
  /** Update many KeyOnboarding documents */
  updateManyKeyOnboardingsConnection: KeyOnboardingConnection;
  /**
   * Update many keys
   * @deprecated Please use the new paginated many mutation (updateManyKeysConnection)
   */
  updateManyKeys: BatchPayload;
  /** Update many Key documents */
  updateManyKeysConnection: KeyConnection;
  /**
   * Update many legals
   * @deprecated Please use the new paginated many mutation (updateManyLegalsConnection)
   */
  updateManyLegals: BatchPayload;
  /** Update many Legal documents */
  updateManyLegalsConnection: LegalConnection;
  /**
   * Update many locations
   * @deprecated Please use the new paginated many mutation (updateManyLocationsConnection)
   */
  updateManyLocations: BatchPayload;
  /** Update many GOLocation documents */
  updateManyLocationsConnection: GoLocationConnection;
  /**
   * Update many popUpCards
   * @deprecated Please use the new paginated many mutation (updateManyPopUpCardsConnection)
   */
  updateManyPopUpCards: BatchPayload;
  /** Update many PopUpCard documents */
  updateManyPopUpCardsConnection: PopUpCardConnection;
  /**
   * Update many priceBreakdowns
   * @deprecated Please use the new paginated many mutation (updateManyPriceBreakdownsConnection)
   */
  updateManyPriceBreakdowns: BatchPayload;
  /** Update many PriceBreakdown documents */
  updateManyPriceBreakdownsConnection: PriceBreakdownConnection;
  /**
   * Update many profiles
   * @deprecated Please use the new paginated many mutation (updateManyProfilesConnection)
   */
  updateManyProfiles: BatchPayload;
  /** Update many Profile documents */
  updateManyProfilesConnection: ProfileConnection;
  /**
   * Update many rentals
   * @deprecated Please use the new paginated many mutation (updateManyRentalsConnection)
   */
  updateManyRentals: BatchPayload;
  /** Update many Rental documents */
  updateManyRentalsConnection: RentalConnection;
  /**
   * Update many subClasses
   * @deprecated Please use the new paginated many mutation (updateManySubClassesConnection)
   */
  updateManySubClasses: BatchPayload;
  /** Update many SubClass documents */
  updateManySubClassesConnection: SubClassConnection;
  /**
   * Update many successfulPaymentScreens
   * @deprecated Please use the new paginated many mutation (updateManySuccessfulPaymentScreensConnection)
   */
  updateManySuccessfulPaymentScreens: BatchPayload;
  /** Update many SuccessfulPaymentScreen documents */
  updateManySuccessfulPaymentScreensConnection: SuccessfulPaymentScreenConnection;
  /**
   * Update many verifications
   * @deprecated Please use the new paginated many mutation (updateManyVerificationsConnection)
   */
  updateManyVerifications: BatchPayload;
  /** Update many Verification documents */
  updateManyVerificationsConnection: VerificationConnection;
  /**
   * Update many vins
   * @deprecated Please use the new paginated many mutation (updateManyVinsConnection)
   */
  updateManyVins: BatchPayload;
  /** Update many Vin documents */
  updateManyVinsConnection: VinConnection;
  /** Update one popUpCard */
  updatePopUpCard?: Maybe<PopUpCard>;
  /** Update one priceBreakdown */
  updatePriceBreakdown?: Maybe<PriceBreakdown>;
  /** Update one profile */
  updateProfile?: Maybe<Profile>;
  /** Update one rental */
  updateRental?: Maybe<Rental>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>;
  /** Update one subClass */
  updateSubClass?: Maybe<SubClass>;
  /** Update one successfulPaymentScreen */
  updateSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Update one verification */
  updateVerification?: Maybe<Verification>;
  /** Update one vin */
  updateVin?: Maybe<Vin>;
  /** Upsert one appOnboarding */
  upsertAppOnboarding?: Maybe<AppOnboarding>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Upsert one banner */
  upsertBanner?: Maybe<Banner>;
  /** Upsert one bottomNavigationItem */
  upsertBottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Upsert one calendar */
  upsertCalendar?: Maybe<Calendar>;
  /** Upsert one carModel */
  upsertCarModel?: Maybe<CarModel>;
  /** Upsert one carModelFeature */
  upsertCarModelFeature?: Maybe<CarModelFeature>;
  /** Upsert one class */
  upsertClass?: Maybe<Class>;
  /** Upsert one contact */
  upsertContact?: Maybe<Contact>;
  /** Upsert one coverage */
  upsertCoverage?: Maybe<Coverage>;
  /** Upsert one emergencyKeyOnboarding */
  upsertEmergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Upsert one errorMessage */
  upsertErrorMessage?: Maybe<ErrorMessage>;
  /** Upsert one extra */
  upsertExtra?: Maybe<Extra>;
  /** Upsert one gOLocation */
  upsertGOLocation?: Maybe<GoLocation>;
  /** Upsert one gOSearch */
  upsertGOSearch?: Maybe<GoSearch>;
  /** Upsert one goBookingJourney */
  upsertGoBookingJourney?: Maybe<GoBookingJourney>;
  /** Upsert one goSelection */
  upsertGoSelection?: Maybe<GoSelection>;
  /** Upsert one goWebappBookingOverview */
  upsertGoWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Upsert one goWebappHomepage */
  upsertGoWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Upsert one goWebappNavLink */
  upsertGoWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Upsert one goWebappNavigationWizard */
  upsertGoWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Upsert one hamburgerMenuItem */
  upsertHamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Upsert one homeScreen */
  upsertHomeScreen?: Maybe<HomeScreen>;
  /** Upsert one key */
  upsertKey?: Maybe<Key>;
  /** Upsert one keyOnboarding */
  upsertKeyOnboarding?: Maybe<KeyOnboarding>;
  /** Upsert one legal */
  upsertLegal?: Maybe<Legal>;
  /** Upsert one popUpCard */
  upsertPopUpCard?: Maybe<PopUpCard>;
  /** Upsert one priceBreakdown */
  upsertPriceBreakdown?: Maybe<PriceBreakdown>;
  /** Upsert one profile */
  upsertProfile?: Maybe<Profile>;
  /** Upsert one rental */
  upsertRental?: Maybe<Rental>;
  /** Upsert one subClass */
  upsertSubClass?: Maybe<SubClass>;
  /** Upsert one successfulPaymentScreen */
  upsertSuccessfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Upsert one verification */
  upsertVerification?: Maybe<Verification>;
  /** Upsert one vin */
  upsertVin?: Maybe<Vin>;
};


export type MutationCreateAppOnboardingArgs = {
  data: AppOnboardingCreateInput;
};


export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};


export type MutationCreateBannerArgs = {
  data: BannerCreateInput;
};


export type MutationCreateBottomNavigationItemArgs = {
  data: BottomNavigationItemCreateInput;
};


export type MutationCreateCalendarArgs = {
  data: CalendarCreateInput;
};


export type MutationCreateCarModelArgs = {
  data: CarModelCreateInput;
};


export type MutationCreateCarModelFeatureArgs = {
  data: CarModelFeatureCreateInput;
};


export type MutationCreateClassArgs = {
  data: ClassCreateInput;
};


export type MutationCreateContactArgs = {
  data: ContactCreateInput;
};


export type MutationCreateCoverageArgs = {
  data: CoverageCreateInput;
};


export type MutationCreateEmergencyKeyOnboardingArgs = {
  data: EmergencyKeyOnboardingCreateInput;
};


export type MutationCreateErrorMessageArgs = {
  data: ErrorMessageCreateInput;
};


export type MutationCreateExtraArgs = {
  data: ExtraCreateInput;
};


export type MutationCreateGoLocationArgs = {
  data: GoLocationCreateInput;
};


export type MutationCreateGoSearchArgs = {
  data: GoSearchCreateInput;
};


export type MutationCreateGoBookingJourneyArgs = {
  data: GoBookingJourneyCreateInput;
};


export type MutationCreateGoSelectionArgs = {
  data: GoSelectionCreateInput;
};


export type MutationCreateGoWebappBookingOverviewArgs = {
  data: GoWebappBookingOverviewCreateInput;
};


export type MutationCreateGoWebappHomepageArgs = {
  data: GoWebappHomepageCreateInput;
};


export type MutationCreateGoWebappNavLinkArgs = {
  data: GoWebappNavLinkCreateInput;
};


export type MutationCreateGoWebappNavigationWizardArgs = {
  data: GoWebappNavigationWizardCreateInput;
};


export type MutationCreateHamburgerMenuItemArgs = {
  data: HamburgerMenuItemCreateInput;
};


export type MutationCreateHomeScreenArgs = {
  data: HomeScreenCreateInput;
};


export type MutationCreateKeyArgs = {
  data: KeyCreateInput;
};


export type MutationCreateKeyOnboardingArgs = {
  data: KeyOnboardingCreateInput;
};


export type MutationCreateLegalArgs = {
  data: LegalCreateInput;
};


export type MutationCreatePopUpCardArgs = {
  data: PopUpCardCreateInput;
};


export type MutationCreatePriceBreakdownArgs = {
  data: PriceBreakdownCreateInput;
};


export type MutationCreateProfileArgs = {
  data: ProfileCreateInput;
};


export type MutationCreateRentalArgs = {
  data: RentalCreateInput;
};


export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};


export type MutationCreateSubClassArgs = {
  data: SubClassCreateInput;
};


export type MutationCreateSuccessfulPaymentScreenArgs = {
  data: SuccessfulPaymentScreenCreateInput;
};


export type MutationCreateVerificationArgs = {
  data: VerificationCreateInput;
};


export type MutationCreateVinArgs = {
  data: VinCreateInput;
};


export type MutationDeleteAppOnboardingArgs = {
  where: AppOnboardingWhereUniqueInput;
};


export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};


export type MutationDeleteBannerArgs = {
  where: BannerWhereUniqueInput;
};


export type MutationDeleteBottomNavigationItemArgs = {
  where: BottomNavigationItemWhereUniqueInput;
};


export type MutationDeleteCalendarArgs = {
  where: CalendarWhereUniqueInput;
};


export type MutationDeleteCarModelArgs = {
  where: CarModelWhereUniqueInput;
};


export type MutationDeleteCarModelFeatureArgs = {
  where: CarModelFeatureWhereUniqueInput;
};


export type MutationDeleteClassArgs = {
  where: ClassWhereUniqueInput;
};


export type MutationDeleteContactArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationDeleteCoverageArgs = {
  where: CoverageWhereUniqueInput;
};


export type MutationDeleteEmergencyKeyOnboardingArgs = {
  where: EmergencyKeyOnboardingWhereUniqueInput;
};


export type MutationDeleteErrorMessageArgs = {
  where: ErrorMessageWhereUniqueInput;
};


export type MutationDeleteExtraArgs = {
  where: ExtraWhereUniqueInput;
};


export type MutationDeleteGoLocationArgs = {
  where: GoLocationWhereUniqueInput;
};


export type MutationDeleteGoSearchArgs = {
  where: GoSearchWhereUniqueInput;
};


export type MutationDeleteGoBookingJourneyArgs = {
  where: GoBookingJourneyWhereUniqueInput;
};


export type MutationDeleteGoSelectionArgs = {
  where: GoSelectionWhereUniqueInput;
};


export type MutationDeleteGoWebappBookingOverviewArgs = {
  where: GoWebappBookingOverviewWhereUniqueInput;
};


export type MutationDeleteGoWebappHomepageArgs = {
  where: GoWebappHomepageWhereUniqueInput;
};


export type MutationDeleteGoWebappNavLinkArgs = {
  where: GoWebappNavLinkWhereUniqueInput;
};


export type MutationDeleteGoWebappNavigationWizardArgs = {
  where: GoWebappNavigationWizardWhereUniqueInput;
};


export type MutationDeleteHamburgerMenuItemArgs = {
  where: HamburgerMenuItemWhereUniqueInput;
};


export type MutationDeleteHomeScreenArgs = {
  where: HomeScreenWhereUniqueInput;
};


export type MutationDeleteKeyArgs = {
  where: KeyWhereUniqueInput;
};


export type MutationDeleteKeyOnboardingArgs = {
  where: KeyOnboardingWhereUniqueInput;
};


export type MutationDeleteLegalArgs = {
  where: LegalWhereUniqueInput;
};


export type MutationDeleteManyAppOnboardingsArgs = {
  where?: InputMaybe<AppOnboardingManyWhereInput>;
};


export type MutationDeleteManyAppOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
};


export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationDeleteManyBannersArgs = {
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationDeleteManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationDeleteManyBottomNavigationItemsArgs = {
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
};


export type MutationDeleteManyBottomNavigationItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
};


export type MutationDeleteManyCalendarsArgs = {
  where?: InputMaybe<CalendarManyWhereInput>;
};


export type MutationDeleteManyCalendarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarManyWhereInput>;
};


export type MutationDeleteManyCarModelFeaturesArgs = {
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
};


export type MutationDeleteManyCarModelFeaturesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
};


export type MutationDeleteManyCarModelsArgs = {
  where?: InputMaybe<CarModelManyWhereInput>;
};


export type MutationDeleteManyCarModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelManyWhereInput>;
};


export type MutationDeleteManyClassesArgs = {
  where?: InputMaybe<ClassManyWhereInput>;
};


export type MutationDeleteManyClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassManyWhereInput>;
};


export type MutationDeleteManyContactsArgs = {
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationDeleteManyContactsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationDeleteManyCoveragesArgs = {
  where?: InputMaybe<CoverageManyWhereInput>;
};


export type MutationDeleteManyCoveragesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoverageManyWhereInput>;
};


export type MutationDeleteManyEmergencyKeyOnboardingsArgs = {
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
};


export type MutationDeleteManyEmergencyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
};


export type MutationDeleteManyErrorMessagesArgs = {
  where?: InputMaybe<ErrorMessageManyWhereInput>;
};


export type MutationDeleteManyErrorMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
};


export type MutationDeleteManyExtrasArgs = {
  where?: InputMaybe<ExtraManyWhereInput>;
};


export type MutationDeleteManyExtrasConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtraManyWhereInput>;
};


export type MutationDeleteManyGoSearchesArgs = {
  where?: InputMaybe<GoSearchManyWhereInput>;
};


export type MutationDeleteManyGoSearchesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchManyWhereInput>;
};


export type MutationDeleteManyGoBookingJourneysArgs = {
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
};


export type MutationDeleteManyGoBookingJourneysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
};


export type MutationDeleteManyGoSelectionsArgs = {
  where?: InputMaybe<GoSelectionManyWhereInput>;
};


export type MutationDeleteManyGoSelectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSelectionManyWhereInput>;
};


export type MutationDeleteManyGoWebappBookingOverviewsArgs = {
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
};


export type MutationDeleteManyGoWebappBookingOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
};


export type MutationDeleteManyGoWebappHomepagesArgs = {
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
};


export type MutationDeleteManyGoWebappHomepagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
};


export type MutationDeleteManyGoWebappNavLinksArgs = {
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
};


export type MutationDeleteManyGoWebappNavLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
};


export type MutationDeleteManyGoWebappNavigationWizardsArgs = {
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
};


export type MutationDeleteManyGoWebappNavigationWizardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
};


export type MutationDeleteManyHamburgerMenuItemsArgs = {
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
};


export type MutationDeleteManyHamburgerMenuItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
};


export type MutationDeleteManyHomeScreensArgs = {
  where?: InputMaybe<HomeScreenManyWhereInput>;
};


export type MutationDeleteManyHomeScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HomeScreenManyWhereInput>;
};


export type MutationDeleteManyKeyOnboardingsArgs = {
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
};


export type MutationDeleteManyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
};


export type MutationDeleteManyKeysArgs = {
  where?: InputMaybe<KeyManyWhereInput>;
};


export type MutationDeleteManyKeysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyManyWhereInput>;
};


export type MutationDeleteManyLegalsArgs = {
  where?: InputMaybe<LegalManyWhereInput>;
};


export type MutationDeleteManyLegalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegalManyWhereInput>;
};


export type MutationDeleteManyLocationsArgs = {
  where?: InputMaybe<GoLocationManyWhereInput>;
};


export type MutationDeleteManyLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoLocationManyWhereInput>;
};


export type MutationDeleteManyPopUpCardsArgs = {
  where?: InputMaybe<PopUpCardManyWhereInput>;
};


export type MutationDeleteManyPopUpCardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopUpCardManyWhereInput>;
};


export type MutationDeleteManyPriceBreakdownsArgs = {
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
};


export type MutationDeleteManyPriceBreakdownsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
};


export type MutationDeleteManyProfilesArgs = {
  where?: InputMaybe<ProfileManyWhereInput>;
};


export type MutationDeleteManyProfilesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileManyWhereInput>;
};


export type MutationDeleteManyRentalsArgs = {
  where?: InputMaybe<RentalManyWhereInput>;
};


export type MutationDeleteManyRentalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentalManyWhereInput>;
};


export type MutationDeleteManySubClassesArgs = {
  where?: InputMaybe<SubClassManyWhereInput>;
};


export type MutationDeleteManySubClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubClassManyWhereInput>;
};


export type MutationDeleteManySuccessfulPaymentScreensArgs = {
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
};


export type MutationDeleteManySuccessfulPaymentScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
};


export type MutationDeleteManyVerificationsArgs = {
  where?: InputMaybe<VerificationManyWhereInput>;
};


export type MutationDeleteManyVerificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationManyWhereInput>;
};


export type MutationDeleteManyVinsArgs = {
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationDeleteManyVinsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationDeletePopUpCardArgs = {
  where: PopUpCardWhereUniqueInput;
};


export type MutationDeletePriceBreakdownArgs = {
  where: PriceBreakdownWhereUniqueInput;
};


export type MutationDeleteProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type MutationDeleteRentalArgs = {
  where: RentalWhereUniqueInput;
};


export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};


export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};


export type MutationDeleteSubClassArgs = {
  where: SubClassWhereUniqueInput;
};


export type MutationDeleteSuccessfulPaymentScreenArgs = {
  where: SuccessfulPaymentScreenWhereUniqueInput;
};


export type MutationDeleteVerificationArgs = {
  where: VerificationWhereUniqueInput;
};


export type MutationDeleteVinArgs = {
  where: VinWhereUniqueInput;
};


export type MutationPublishAppOnboardingArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AppOnboardingWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishBannerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: BannerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishBottomNavigationItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: BottomNavigationItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishCalendarArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CalendarWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishCarModelArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CarModelWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishCarModelFeatureArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CarModelFeatureWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishContactArgs = {
  to?: Array<Stage>;
  where: ContactWhereUniqueInput;
};


export type MutationPublishCoverageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CoverageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishEmergencyKeyOnboardingArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: EmergencyKeyOnboardingWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishErrorMessageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ErrorMessageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishExtraArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ExtraWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoLocationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoLocationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoSearchArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoSearchWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoBookingJourneyArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoBookingJourneyWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoSelectionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoSelectionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoWebappBookingOverviewArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoWebappBookingOverviewWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoWebappHomepageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoWebappHomepageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoWebappNavLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoWebappNavLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishGoWebappNavigationWizardArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: GoWebappNavigationWizardWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishHamburgerMenuItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: HamburgerMenuItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishHomeScreenArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: HomeScreenWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishKeyArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: KeyWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishKeyOnboardingArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: KeyOnboardingWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishLegalArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: LegalWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyAppOnboardingsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyAppOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyBannersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<BannerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<BannerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyBottomNavigationItemsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyBottomNavigationItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCalendarsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CalendarManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCalendarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CalendarManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCarModelFeaturesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCarModelFeaturesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCarModelsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CarModelManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCarModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CarModelManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyClassesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyContactsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationPublishManyContactsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationPublishManyCoveragesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CoverageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyCoveragesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CoverageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyEmergencyKeyOnboardingsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyEmergencyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyErrorMessagesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyErrorMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyExtrasArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ExtraManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyExtrasConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ExtraManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoSearchesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoSearchManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoSearchesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoSearchManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoBookingJourneysArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoBookingJourneysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoSelectionsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoSelectionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoSelectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoSelectionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappBookingOverviewsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappBookingOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappHomepagesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappHomepagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappNavLinksArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappNavLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappNavigationWizardsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyGoWebappNavigationWizardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyHamburgerMenuItemsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyHamburgerMenuItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyHomeScreensArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<HomeScreenManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyHomeScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<HomeScreenManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyKeyOnboardingsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyKeysArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<KeyManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyKeysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<KeyManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyLegalsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<LegalManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyLegalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<LegalManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyLocationsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoLocationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<GoLocationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyPopUpCardsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<PopUpCardManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyPopUpCardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<PopUpCardManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyPriceBreakdownsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyPriceBreakdownsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyProfilesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ProfileManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyProfilesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ProfileManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyRentalsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<RentalManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyRentalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<RentalManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManySubClassesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<SubClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManySubClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<SubClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManySuccessfulPaymentScreensArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManySuccessfulPaymentScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyVerificationsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<VerificationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyVerificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<VerificationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishManyVinsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationPublishManyVinsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationPublishPopUpCardArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: PopUpCardWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishPriceBreakdownArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: PriceBreakdownWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishProfileArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ProfileWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishRentalArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: RentalWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishSubClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: SubClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishSuccessfulPaymentScreenArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: SuccessfulPaymentScreenWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishVerificationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: VerificationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishVinArgs = {
  to?: Array<Stage>;
  where: VinWhereUniqueInput;
};


export type MutationSchedulePublishAppOnboardingArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AppOnboardingWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishBannerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: BannerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishBottomNavigationItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: BottomNavigationItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishCalendarArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CalendarWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishCarModelArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CarModelWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishCarModelFeatureArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CarModelFeatureWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishContactArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ContactWhereUniqueInput;
};


export type MutationSchedulePublishCoverageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CoverageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishEmergencyKeyOnboardingArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: EmergencyKeyOnboardingWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishErrorMessageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ErrorMessageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishExtraArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ExtraWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoLocationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoLocationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoSearchArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoSearchWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoBookingJourneyArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoBookingJourneyWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoSelectionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoSelectionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoWebappBookingOverviewArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoWebappBookingOverviewWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoWebappHomepageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoWebappHomepageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoWebappNavLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoWebappNavLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishGoWebappNavigationWizardArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GoWebappNavigationWizardWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishHamburgerMenuItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: HamburgerMenuItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishHomeScreenArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: HomeScreenWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishKeyArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: KeyWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishKeyOnboardingArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: KeyOnboardingWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishLegalArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: LegalWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishPopUpCardArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PopUpCardWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishPriceBreakdownArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PriceBreakdownWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishProfileArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ProfileWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishRentalArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: RentalWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishSubClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SubClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishSuccessfulPaymentScreenArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SuccessfulPaymentScreenWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishVerificationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: VerificationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSchedulePublishVinArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: VinWhereUniqueInput;
};


export type MutationScheduleUnpublishAppOnboardingArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AppOnboardingWhereUniqueInput;
};


export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AssetWhereUniqueInput;
};


export type MutationScheduleUnpublishBannerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: BannerWhereUniqueInput;
};


export type MutationScheduleUnpublishBottomNavigationItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: BottomNavigationItemWhereUniqueInput;
};


export type MutationScheduleUnpublishCalendarArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CalendarWhereUniqueInput;
};


export type MutationScheduleUnpublishCarModelArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CarModelWhereUniqueInput;
};


export type MutationScheduleUnpublishCarModelFeatureArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CarModelFeatureWhereUniqueInput;
};


export type MutationScheduleUnpublishClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ClassWhereUniqueInput;
};


export type MutationScheduleUnpublishContactArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: ContactWhereUniqueInput;
};


export type MutationScheduleUnpublishCoverageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CoverageWhereUniqueInput;
};


export type MutationScheduleUnpublishEmergencyKeyOnboardingArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: EmergencyKeyOnboardingWhereUniqueInput;
};


export type MutationScheduleUnpublishErrorMessageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ErrorMessageWhereUniqueInput;
};


export type MutationScheduleUnpublishExtraArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ExtraWhereUniqueInput;
};


export type MutationScheduleUnpublishGoLocationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoLocationWhereUniqueInput;
};


export type MutationScheduleUnpublishGoSearchArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoSearchWhereUniqueInput;
};


export type MutationScheduleUnpublishGoBookingJourneyArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoBookingJourneyWhereUniqueInput;
};


export type MutationScheduleUnpublishGoSelectionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoSelectionWhereUniqueInput;
};


export type MutationScheduleUnpublishGoWebappBookingOverviewArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappBookingOverviewWhereUniqueInput;
};


export type MutationScheduleUnpublishGoWebappHomepageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappHomepageWhereUniqueInput;
};


export type MutationScheduleUnpublishGoWebappNavLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappNavLinkWhereUniqueInput;
};


export type MutationScheduleUnpublishGoWebappNavigationWizardArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappNavigationWizardWhereUniqueInput;
};


export type MutationScheduleUnpublishHamburgerMenuItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: HamburgerMenuItemWhereUniqueInput;
};


export type MutationScheduleUnpublishHomeScreenArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: HomeScreenWhereUniqueInput;
};


export type MutationScheduleUnpublishKeyArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: KeyWhereUniqueInput;
};


export type MutationScheduleUnpublishKeyOnboardingArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: KeyOnboardingWhereUniqueInput;
};


export type MutationScheduleUnpublishLegalArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: LegalWhereUniqueInput;
};


export type MutationScheduleUnpublishPopUpCardArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PopUpCardWhereUniqueInput;
};


export type MutationScheduleUnpublishPriceBreakdownArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PriceBreakdownWhereUniqueInput;
};


export type MutationScheduleUnpublishProfileArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProfileWhereUniqueInput;
};


export type MutationScheduleUnpublishRentalArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: RentalWhereUniqueInput;
};


export type MutationScheduleUnpublishSubClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SubClassWhereUniqueInput;
};


export type MutationScheduleUnpublishSuccessfulPaymentScreenArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SuccessfulPaymentScreenWhereUniqueInput;
};


export type MutationScheduleUnpublishVerificationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: VerificationWhereUniqueInput;
};


export type MutationScheduleUnpublishVinArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: VinWhereUniqueInput;
};


export type MutationUnpublishAppOnboardingArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AppOnboardingWhereUniqueInput;
};


export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AssetWhereUniqueInput;
};


export type MutationUnpublishBannerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: BannerWhereUniqueInput;
};


export type MutationUnpublishBottomNavigationItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: BottomNavigationItemWhereUniqueInput;
};


export type MutationUnpublishCalendarArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CalendarWhereUniqueInput;
};


export type MutationUnpublishCarModelArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CarModelWhereUniqueInput;
};


export type MutationUnpublishCarModelFeatureArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CarModelFeatureWhereUniqueInput;
};


export type MutationUnpublishClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ClassWhereUniqueInput;
};


export type MutationUnpublishContactArgs = {
  from?: Array<Stage>;
  where: ContactWhereUniqueInput;
};


export type MutationUnpublishCoverageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CoverageWhereUniqueInput;
};


export type MutationUnpublishEmergencyKeyOnboardingArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: EmergencyKeyOnboardingWhereUniqueInput;
};


export type MutationUnpublishErrorMessageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ErrorMessageWhereUniqueInput;
};


export type MutationUnpublishExtraArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ExtraWhereUniqueInput;
};


export type MutationUnpublishGoLocationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoLocationWhereUniqueInput;
};


export type MutationUnpublishGoSearchArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoSearchWhereUniqueInput;
};


export type MutationUnpublishGoBookingJourneyArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoBookingJourneyWhereUniqueInput;
};


export type MutationUnpublishGoSelectionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoSelectionWhereUniqueInput;
};


export type MutationUnpublishGoWebappBookingOverviewArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappBookingOverviewWhereUniqueInput;
};


export type MutationUnpublishGoWebappHomepageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappHomepageWhereUniqueInput;
};


export type MutationUnpublishGoWebappNavLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappNavLinkWhereUniqueInput;
};


export type MutationUnpublishGoWebappNavigationWizardArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: GoWebappNavigationWizardWhereUniqueInput;
};


export type MutationUnpublishHamburgerMenuItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: HamburgerMenuItemWhereUniqueInput;
};


export type MutationUnpublishHomeScreenArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: HomeScreenWhereUniqueInput;
};


export type MutationUnpublishKeyArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: KeyWhereUniqueInput;
};


export type MutationUnpublishKeyOnboardingArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: KeyOnboardingWhereUniqueInput;
};


export type MutationUnpublishLegalArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: LegalWhereUniqueInput;
};


export type MutationUnpublishManyAppOnboardingsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
};


export type MutationUnpublishManyAppOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
};


export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUnpublishManyBannersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUnpublishManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUnpublishManyBottomNavigationItemsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
};


export type MutationUnpublishManyBottomNavigationItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
};


export type MutationUnpublishManyCalendarsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CalendarManyWhereInput>;
};


export type MutationUnpublishManyCalendarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CalendarManyWhereInput>;
};


export type MutationUnpublishManyCarModelFeaturesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
};


export type MutationUnpublishManyCarModelFeaturesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
};


export type MutationUnpublishManyCarModelsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CarModelManyWhereInput>;
};


export type MutationUnpublishManyCarModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CarModelManyWhereInput>;
};


export type MutationUnpublishManyClassesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ClassManyWhereInput>;
};


export type MutationUnpublishManyClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ClassManyWhereInput>;
};


export type MutationUnpublishManyContactsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationUnpublishManyContactsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationUnpublishManyCoveragesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CoverageManyWhereInput>;
};


export type MutationUnpublishManyCoveragesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CoverageManyWhereInput>;
};


export type MutationUnpublishManyEmergencyKeyOnboardingsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
};


export type MutationUnpublishManyEmergencyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
};


export type MutationUnpublishManyErrorMessagesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
};


export type MutationUnpublishManyErrorMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
};


export type MutationUnpublishManyExtrasArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ExtraManyWhereInput>;
};


export type MutationUnpublishManyExtrasConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ExtraManyWhereInput>;
};


export type MutationUnpublishManyGoSearchesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoSearchManyWhereInput>;
};


export type MutationUnpublishManyGoSearchesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoSearchManyWhereInput>;
};


export type MutationUnpublishManyGoBookingJourneysArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
};


export type MutationUnpublishManyGoBookingJourneysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
};


export type MutationUnpublishManyGoSelectionsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoSelectionManyWhereInput>;
};


export type MutationUnpublishManyGoSelectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoSelectionManyWhereInput>;
};


export type MutationUnpublishManyGoWebappBookingOverviewsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
};


export type MutationUnpublishManyGoWebappBookingOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
};


export type MutationUnpublishManyGoWebappHomepagesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
};


export type MutationUnpublishManyGoWebappHomepagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
};


export type MutationUnpublishManyGoWebappNavLinksArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
};


export type MutationUnpublishManyGoWebappNavLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
};


export type MutationUnpublishManyGoWebappNavigationWizardsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
};


export type MutationUnpublishManyGoWebappNavigationWizardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
};


export type MutationUnpublishManyHamburgerMenuItemsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
};


export type MutationUnpublishManyHamburgerMenuItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
};


export type MutationUnpublishManyHomeScreensArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<HomeScreenManyWhereInput>;
};


export type MutationUnpublishManyHomeScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<HomeScreenManyWhereInput>;
};


export type MutationUnpublishManyKeyOnboardingsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
};


export type MutationUnpublishManyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
};


export type MutationUnpublishManyKeysArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<KeyManyWhereInput>;
};


export type MutationUnpublishManyKeysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<KeyManyWhereInput>;
};


export type MutationUnpublishManyLegalsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LegalManyWhereInput>;
};


export type MutationUnpublishManyLegalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LegalManyWhereInput>;
};


export type MutationUnpublishManyLocationsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoLocationManyWhereInput>;
};


export type MutationUnpublishManyLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<GoLocationManyWhereInput>;
};


export type MutationUnpublishManyPopUpCardsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PopUpCardManyWhereInput>;
};


export type MutationUnpublishManyPopUpCardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PopUpCardManyWhereInput>;
};


export type MutationUnpublishManyPriceBreakdownsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
};


export type MutationUnpublishManyPriceBreakdownsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
};


export type MutationUnpublishManyProfilesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ProfileManyWhereInput>;
};


export type MutationUnpublishManyProfilesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ProfileManyWhereInput>;
};


export type MutationUnpublishManyRentalsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<RentalManyWhereInput>;
};


export type MutationUnpublishManyRentalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<RentalManyWhereInput>;
};


export type MutationUnpublishManySubClassesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SubClassManyWhereInput>;
};


export type MutationUnpublishManySubClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SubClassManyWhereInput>;
};


export type MutationUnpublishManySuccessfulPaymentScreensArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
};


export type MutationUnpublishManySuccessfulPaymentScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
};


export type MutationUnpublishManyVerificationsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<VerificationManyWhereInput>;
};


export type MutationUnpublishManyVerificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<VerificationManyWhereInput>;
};


export type MutationUnpublishManyVinsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationUnpublishManyVinsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationUnpublishPopUpCardArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PopUpCardWhereUniqueInput;
};


export type MutationUnpublishPriceBreakdownArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PriceBreakdownWhereUniqueInput;
};


export type MutationUnpublishProfileArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProfileWhereUniqueInput;
};


export type MutationUnpublishRentalArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: RentalWhereUniqueInput;
};


export type MutationUnpublishSubClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SubClassWhereUniqueInput;
};


export type MutationUnpublishSuccessfulPaymentScreenArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SuccessfulPaymentScreenWhereUniqueInput;
};


export type MutationUnpublishVerificationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: VerificationWhereUniqueInput;
};


export type MutationUnpublishVinArgs = {
  from?: Array<Stage>;
  where: VinWhereUniqueInput;
};


export type MutationUpdateAppOnboardingArgs = {
  data: AppOnboardingUpdateInput;
  where: AppOnboardingWhereUniqueInput;
};


export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};


export type MutationUpdateBannerArgs = {
  data: BannerUpdateInput;
  where: BannerWhereUniqueInput;
};


export type MutationUpdateBottomNavigationItemArgs = {
  data: BottomNavigationItemUpdateInput;
  where: BottomNavigationItemWhereUniqueInput;
};


export type MutationUpdateCalendarArgs = {
  data: CalendarUpdateInput;
  where: CalendarWhereUniqueInput;
};


export type MutationUpdateCarModelArgs = {
  data: CarModelUpdateInput;
  where: CarModelWhereUniqueInput;
};


export type MutationUpdateCarModelFeatureArgs = {
  data: CarModelFeatureUpdateInput;
  where: CarModelFeatureWhereUniqueInput;
};


export type MutationUpdateClassArgs = {
  data: ClassUpdateInput;
  where: ClassWhereUniqueInput;
};


export type MutationUpdateContactArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateCoverageArgs = {
  data: CoverageUpdateInput;
  where: CoverageWhereUniqueInput;
};


export type MutationUpdateEmergencyKeyOnboardingArgs = {
  data: EmergencyKeyOnboardingUpdateInput;
  where: EmergencyKeyOnboardingWhereUniqueInput;
};


export type MutationUpdateErrorMessageArgs = {
  data: ErrorMessageUpdateInput;
  where: ErrorMessageWhereUniqueInput;
};


export type MutationUpdateExtraArgs = {
  data: ExtraUpdateInput;
  where: ExtraWhereUniqueInput;
};


export type MutationUpdateGoLocationArgs = {
  data: GoLocationUpdateInput;
  where: GoLocationWhereUniqueInput;
};


export type MutationUpdateGoSearchArgs = {
  data: GoSearchUpdateInput;
  where: GoSearchWhereUniqueInput;
};


export type MutationUpdateGoBookingJourneyArgs = {
  data: GoBookingJourneyUpdateInput;
  where: GoBookingJourneyWhereUniqueInput;
};


export type MutationUpdateGoSelectionArgs = {
  data: GoSelectionUpdateInput;
  where: GoSelectionWhereUniqueInput;
};


export type MutationUpdateGoWebappBookingOverviewArgs = {
  data: GoWebappBookingOverviewUpdateInput;
  where: GoWebappBookingOverviewWhereUniqueInput;
};


export type MutationUpdateGoWebappHomepageArgs = {
  data: GoWebappHomepageUpdateInput;
  where: GoWebappHomepageWhereUniqueInput;
};


export type MutationUpdateGoWebappNavLinkArgs = {
  data: GoWebappNavLinkUpdateInput;
  where: GoWebappNavLinkWhereUniqueInput;
};


export type MutationUpdateGoWebappNavigationWizardArgs = {
  data: GoWebappNavigationWizardUpdateInput;
  where: GoWebappNavigationWizardWhereUniqueInput;
};


export type MutationUpdateHamburgerMenuItemArgs = {
  data: HamburgerMenuItemUpdateInput;
  where: HamburgerMenuItemWhereUniqueInput;
};


export type MutationUpdateHomeScreenArgs = {
  data: HomeScreenUpdateInput;
  where: HomeScreenWhereUniqueInput;
};


export type MutationUpdateKeyArgs = {
  data: KeyUpdateInput;
  where: KeyWhereUniqueInput;
};


export type MutationUpdateKeyOnboardingArgs = {
  data: KeyOnboardingUpdateInput;
  where: KeyOnboardingWhereUniqueInput;
};


export type MutationUpdateLegalArgs = {
  data: LegalUpdateInput;
  where: LegalWhereUniqueInput;
};


export type MutationUpdateManyAppOnboardingsArgs = {
  data: AppOnboardingUpdateManyInput;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
};


export type MutationUpdateManyAppOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: AppOnboardingUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppOnboardingManyWhereInput>;
};


export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: AssetUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUpdateManyBannersArgs = {
  data: BannerUpdateManyInput;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUpdateManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: BannerUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUpdateManyBottomNavigationItemsArgs = {
  data: BottomNavigationItemUpdateManyInput;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
};


export type MutationUpdateManyBottomNavigationItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: BottomNavigationItemUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BottomNavigationItemManyWhereInput>;
};


export type MutationUpdateManyCalendarsArgs = {
  data: CalendarUpdateManyInput;
  where?: InputMaybe<CalendarManyWhereInput>;
};


export type MutationUpdateManyCalendarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CalendarUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalendarManyWhereInput>;
};


export type MutationUpdateManyCarModelFeaturesArgs = {
  data: CarModelFeatureUpdateManyInput;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
};


export type MutationUpdateManyCarModelFeaturesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CarModelFeatureUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelFeatureManyWhereInput>;
};


export type MutationUpdateManyCarModelsArgs = {
  data: CarModelUpdateManyInput;
  where?: InputMaybe<CarModelManyWhereInput>;
};


export type MutationUpdateManyCarModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CarModelUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelManyWhereInput>;
};


export type MutationUpdateManyClassesArgs = {
  data: ClassUpdateManyInput;
  where?: InputMaybe<ClassManyWhereInput>;
};


export type MutationUpdateManyClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ClassUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassManyWhereInput>;
};


export type MutationUpdateManyContactsArgs = {
  data: ContactUpdateManyInput;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationUpdateManyContactsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ContactUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactManyWhereInput>;
};


export type MutationUpdateManyCoveragesArgs = {
  data: CoverageUpdateManyInput;
  where?: InputMaybe<CoverageManyWhereInput>;
};


export type MutationUpdateManyCoveragesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CoverageUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoverageManyWhereInput>;
};


export type MutationUpdateManyEmergencyKeyOnboardingsArgs = {
  data: EmergencyKeyOnboardingUpdateManyInput;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
};


export type MutationUpdateManyEmergencyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: EmergencyKeyOnboardingUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmergencyKeyOnboardingManyWhereInput>;
};


export type MutationUpdateManyErrorMessagesArgs = {
  data: ErrorMessageUpdateManyInput;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
};


export type MutationUpdateManyErrorMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ErrorMessageUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ErrorMessageManyWhereInput>;
};


export type MutationUpdateManyExtrasArgs = {
  data: ExtraUpdateManyInput;
  where?: InputMaybe<ExtraManyWhereInput>;
};


export type MutationUpdateManyExtrasConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ExtraUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtraManyWhereInput>;
};


export type MutationUpdateManyGoSearchesArgs = {
  data: GoSearchUpdateManyInput;
  where?: InputMaybe<GoSearchManyWhereInput>;
};


export type MutationUpdateManyGoSearchesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoSearchUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSearchManyWhereInput>;
};


export type MutationUpdateManyGoBookingJourneysArgs = {
  data: GoBookingJourneyUpdateManyInput;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
};


export type MutationUpdateManyGoBookingJourneysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoBookingJourneyUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoBookingJourneyManyWhereInput>;
};


export type MutationUpdateManyGoSelectionsArgs = {
  data: GoSelectionUpdateManyInput;
  where?: InputMaybe<GoSelectionManyWhereInput>;
};


export type MutationUpdateManyGoSelectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoSelectionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoSelectionManyWhereInput>;
};


export type MutationUpdateManyGoWebappBookingOverviewsArgs = {
  data: GoWebappBookingOverviewUpdateManyInput;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
};


export type MutationUpdateManyGoWebappBookingOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoWebappBookingOverviewUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappBookingOverviewManyWhereInput>;
};


export type MutationUpdateManyGoWebappHomepagesArgs = {
  data: GoWebappHomepageUpdateManyInput;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
};


export type MutationUpdateManyGoWebappHomepagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoWebappHomepageUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappHomepageManyWhereInput>;
};


export type MutationUpdateManyGoWebappNavLinksArgs = {
  data: GoWebappNavLinkUpdateManyInput;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
};


export type MutationUpdateManyGoWebappNavLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoWebappNavLinkUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappNavLinkManyWhereInput>;
};


export type MutationUpdateManyGoWebappNavigationWizardsArgs = {
  data: GoWebappNavigationWizardUpdateManyInput;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
};


export type MutationUpdateManyGoWebappNavigationWizardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoWebappNavigationWizardUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoWebappNavigationWizardManyWhereInput>;
};


export type MutationUpdateManyHamburgerMenuItemsArgs = {
  data: HamburgerMenuItemUpdateManyInput;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
};


export type MutationUpdateManyHamburgerMenuItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: HamburgerMenuItemUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HamburgerMenuItemManyWhereInput>;
};


export type MutationUpdateManyHomeScreensArgs = {
  data: HomeScreenUpdateManyInput;
  where?: InputMaybe<HomeScreenManyWhereInput>;
};


export type MutationUpdateManyHomeScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: HomeScreenUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HomeScreenManyWhereInput>;
};


export type MutationUpdateManyKeyOnboardingsArgs = {
  data: KeyOnboardingUpdateManyInput;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
};


export type MutationUpdateManyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: KeyOnboardingUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyOnboardingManyWhereInput>;
};


export type MutationUpdateManyKeysArgs = {
  data: KeyUpdateManyInput;
  where?: InputMaybe<KeyManyWhereInput>;
};


export type MutationUpdateManyKeysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: KeyUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KeyManyWhereInput>;
};


export type MutationUpdateManyLegalsArgs = {
  data: LegalUpdateManyInput;
  where?: InputMaybe<LegalManyWhereInput>;
};


export type MutationUpdateManyLegalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: LegalUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegalManyWhereInput>;
};


export type MutationUpdateManyLocationsArgs = {
  data: GoLocationUpdateManyInput;
  where?: InputMaybe<GoLocationManyWhereInput>;
};


export type MutationUpdateManyLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: GoLocationUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GoLocationManyWhereInput>;
};


export type MutationUpdateManyPopUpCardsArgs = {
  data: PopUpCardUpdateManyInput;
  where?: InputMaybe<PopUpCardManyWhereInput>;
};


export type MutationUpdateManyPopUpCardsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: PopUpCardUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopUpCardManyWhereInput>;
};


export type MutationUpdateManyPriceBreakdownsArgs = {
  data: PriceBreakdownUpdateManyInput;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
};


export type MutationUpdateManyPriceBreakdownsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: PriceBreakdownUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PriceBreakdownManyWhereInput>;
};


export type MutationUpdateManyProfilesArgs = {
  data: ProfileUpdateManyInput;
  where?: InputMaybe<ProfileManyWhereInput>;
};


export type MutationUpdateManyProfilesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ProfileUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileManyWhereInput>;
};


export type MutationUpdateManyRentalsArgs = {
  data: RentalUpdateManyInput;
  where?: InputMaybe<RentalManyWhereInput>;
};


export type MutationUpdateManyRentalsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: RentalUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentalManyWhereInput>;
};


export type MutationUpdateManySubClassesArgs = {
  data: SubClassUpdateManyInput;
  where?: InputMaybe<SubClassManyWhereInput>;
};


export type MutationUpdateManySubClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: SubClassUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubClassManyWhereInput>;
};


export type MutationUpdateManySuccessfulPaymentScreensArgs = {
  data: SuccessfulPaymentScreenUpdateManyInput;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
};


export type MutationUpdateManySuccessfulPaymentScreensConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: SuccessfulPaymentScreenUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SuccessfulPaymentScreenManyWhereInput>;
};


export type MutationUpdateManyVerificationsArgs = {
  data: VerificationUpdateManyInput;
  where?: InputMaybe<VerificationManyWhereInput>;
};


export type MutationUpdateManyVerificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: VerificationUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationManyWhereInput>;
};


export type MutationUpdateManyVinsArgs = {
  data: VinUpdateManyInput;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationUpdateManyVinsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: VinUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VinManyWhereInput>;
};


export type MutationUpdatePopUpCardArgs = {
  data: PopUpCardUpdateInput;
  where: PopUpCardWhereUniqueInput;
};


export type MutationUpdatePriceBreakdownArgs = {
  data: PriceBreakdownUpdateInput;
  where: PriceBreakdownWhereUniqueInput;
};


export type MutationUpdateProfileArgs = {
  data: ProfileUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpdateRentalArgs = {
  data: RentalUpdateInput;
  where: RentalWhereUniqueInput;
};


export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};


export type MutationUpdateSubClassArgs = {
  data: SubClassUpdateInput;
  where: SubClassWhereUniqueInput;
};


export type MutationUpdateSuccessfulPaymentScreenArgs = {
  data: SuccessfulPaymentScreenUpdateInput;
  where: SuccessfulPaymentScreenWhereUniqueInput;
};


export type MutationUpdateVerificationArgs = {
  data: VerificationUpdateInput;
  where: VerificationWhereUniqueInput;
};


export type MutationUpdateVinArgs = {
  data: VinUpdateInput;
  where: VinWhereUniqueInput;
};


export type MutationUpsertAppOnboardingArgs = {
  upsert: AppOnboardingUpsertInput;
  where: AppOnboardingWhereUniqueInput;
};


export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};


export type MutationUpsertBannerArgs = {
  upsert: BannerUpsertInput;
  where: BannerWhereUniqueInput;
};


export type MutationUpsertBottomNavigationItemArgs = {
  upsert: BottomNavigationItemUpsertInput;
  where: BottomNavigationItemWhereUniqueInput;
};


export type MutationUpsertCalendarArgs = {
  upsert: CalendarUpsertInput;
  where: CalendarWhereUniqueInput;
};


export type MutationUpsertCarModelArgs = {
  upsert: CarModelUpsertInput;
  where: CarModelWhereUniqueInput;
};


export type MutationUpsertCarModelFeatureArgs = {
  upsert: CarModelFeatureUpsertInput;
  where: CarModelFeatureWhereUniqueInput;
};


export type MutationUpsertClassArgs = {
  upsert: ClassUpsertInput;
  where: ClassWhereUniqueInput;
};


export type MutationUpsertContactArgs = {
  upsert: ContactUpsertInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpsertCoverageArgs = {
  upsert: CoverageUpsertInput;
  where: CoverageWhereUniqueInput;
};


export type MutationUpsertEmergencyKeyOnboardingArgs = {
  upsert: EmergencyKeyOnboardingUpsertInput;
  where: EmergencyKeyOnboardingWhereUniqueInput;
};


export type MutationUpsertErrorMessageArgs = {
  upsert: ErrorMessageUpsertInput;
  where: ErrorMessageWhereUniqueInput;
};


export type MutationUpsertExtraArgs = {
  upsert: ExtraUpsertInput;
  where: ExtraWhereUniqueInput;
};


export type MutationUpsertGoLocationArgs = {
  upsert: GoLocationUpsertInput;
  where: GoLocationWhereUniqueInput;
};


export type MutationUpsertGoSearchArgs = {
  upsert: GoSearchUpsertInput;
  where: GoSearchWhereUniqueInput;
};


export type MutationUpsertGoBookingJourneyArgs = {
  upsert: GoBookingJourneyUpsertInput;
  where: GoBookingJourneyWhereUniqueInput;
};


export type MutationUpsertGoSelectionArgs = {
  upsert: GoSelectionUpsertInput;
  where: GoSelectionWhereUniqueInput;
};


export type MutationUpsertGoWebappBookingOverviewArgs = {
  upsert: GoWebappBookingOverviewUpsertInput;
  where: GoWebappBookingOverviewWhereUniqueInput;
};


export type MutationUpsertGoWebappHomepageArgs = {
  upsert: GoWebappHomepageUpsertInput;
  where: GoWebappHomepageWhereUniqueInput;
};


export type MutationUpsertGoWebappNavLinkArgs = {
  upsert: GoWebappNavLinkUpsertInput;
  where: GoWebappNavLinkWhereUniqueInput;
};


export type MutationUpsertGoWebappNavigationWizardArgs = {
  upsert: GoWebappNavigationWizardUpsertInput;
  where: GoWebappNavigationWizardWhereUniqueInput;
};


export type MutationUpsertHamburgerMenuItemArgs = {
  upsert: HamburgerMenuItemUpsertInput;
  where: HamburgerMenuItemWhereUniqueInput;
};


export type MutationUpsertHomeScreenArgs = {
  upsert: HomeScreenUpsertInput;
  where: HomeScreenWhereUniqueInput;
};


export type MutationUpsertKeyArgs = {
  upsert: KeyUpsertInput;
  where: KeyWhereUniqueInput;
};


export type MutationUpsertKeyOnboardingArgs = {
  upsert: KeyOnboardingUpsertInput;
  where: KeyOnboardingWhereUniqueInput;
};


export type MutationUpsertLegalArgs = {
  upsert: LegalUpsertInput;
  where: LegalWhereUniqueInput;
};


export type MutationUpsertPopUpCardArgs = {
  upsert: PopUpCardUpsertInput;
  where: PopUpCardWhereUniqueInput;
};


export type MutationUpsertPriceBreakdownArgs = {
  upsert: PriceBreakdownUpsertInput;
  where: PriceBreakdownWhereUniqueInput;
};


export type MutationUpsertProfileArgs = {
  upsert: ProfileUpsertInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpsertRentalArgs = {
  upsert: RentalUpsertInput;
  where: RentalWhereUniqueInput;
};


export type MutationUpsertSubClassArgs = {
  upsert: SubClassUpsertInput;
  where: SubClassWhereUniqueInput;
};


export type MutationUpsertSuccessfulPaymentScreenArgs = {
  upsert: SuccessfulPaymentScreenUpsertInput;
  where: SuccessfulPaymentScreenWhereUniqueInput;
};


export type MutationUpsertVerificationArgs = {
  upsert: VerificationUpsertInput;
  where: VerificationWhereUniqueInput;
};


export type MutationUpsertVinArgs = {
  upsert: VinUpsertInput;
  where: VinWhereUniqueInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: Stage;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCard = Node & {
  __typename?: 'PopUpCard';
  /**
   * This is the denomination that follows the rental amount in case of cost incurred. When there is no cost incurred and the rental begins in more than 24 hours this will read free
   *
   * EG. [amount of rental] CHF
   *
   */
  cancellationCostAmountDenomination?: Maybe<Scalars['String']>;
  /**
   * The is the text that comes above the amount that cancellation will cost
   *
   * EG. In english -  COST OF CANCELLATION
   */
  cancellationCostTitle?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<PopUpCard>;
  /** List of PopUpCard versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PopUpCard>;
  /**
   * This is the header of the pop up card and is generally posed in the form of a question
   *
   * EG. Are you sure you want to cancel this rental?
   */
  popUpCardHeader?: Maybe<Scalars['String']>;
  /**
   * This is the icon displayed in some pop up cards next to the header
   *
   * eg. bluetooth icon next to the title
   */
  popUpCardHeaderIcon?: Maybe<Asset>;
  /**
   * This is the second description contained within cards such as the end rental card.
   *
   * EG. Don't forget your belongings
   */
  popUpCardSecondaryDescription?: Maybe<Scalars['String']>;
  /**
   * This is the text that gives more information to the user about the action that is to be taken
   *
   * EG. Being less than 24hours from rental will cause a loss of all funds paid
   */
  popUpCardText?: Maybe<Scalars['String']>;
  /**
   * NOT SHOWN TO THE GO USER
   * This is the title of the popup card distinguishes one instance from another the naming convention is (type | stage)
   *
   * EG. (Cancel Rental | Less than 24 hours until rental)
   *
   */
  popUpCardTitle?: Maybe<Scalars['String']>;
  /** This is the cross icon in the top right hand corner of pop ups. If the design does not contain a close icon then this will remain empty */
  popUpCloseIcon?: Maybe<Asset>;
  /**
   * This is the text that fills the main action button in the popup
   *
   * EG. CANCEL
   */
  popupCTAButtonText?: Maybe<Scalars['String']>;
  /**
   * This is the text that fills the pop up's secondary button. The distinction between buttons is that the secondary button follows the secondary button styling (border and no elevation)
   *
   * EG. NO
   */
  popupSecondaryButtonText?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardPopUpCardHeaderIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardPopUpCloseIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** These are the application wide popup cards that are used to alert the user to actions that should be taken and to confirm that the user is sure the action they are taking is the correct one  */
export type PopUpCardUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type PopUpCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PopUpCardWhereUniqueInput;
};

/** A connection to a list of items. */
export type PopUpCardConnection = {
  __typename?: 'PopUpCardConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PopUpCardEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PopUpCardCreateInput = {
  /** cancellationCostAmountDenomination input for default locale (de_CH) */
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  /** cancellationCostTitle input for default locale (de_CH) */
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PopUpCardCreateLocalizationsInput>;
  /** popUpCardHeader input for default locale (de_CH) */
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  popUpCardHeaderIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** popUpCardSecondaryDescription input for default locale (de_CH) */
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  /** popUpCardText input for default locale (de_CH) */
  popUpCardText?: InputMaybe<Scalars['String']>;
  popUpCardTitle?: InputMaybe<Scalars['String']>;
  popUpCloseIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** popupCTAButtonText input for default locale (de_CH) */
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  /** popupSecondaryButtonText input for default locale (de_CH) */
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PopUpCardCreateLocalizationDataInput = {
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  popUpCardText?: InputMaybe<Scalars['String']>;
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PopUpCardCreateLocalizationInput = {
  /** Localization input */
  data: PopUpCardCreateLocalizationDataInput;
  locale: Locale;
};

export type PopUpCardCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PopUpCardCreateLocalizationInput>>;
};

export type PopUpCardCreateManyInlineInput = {
  /** Connect multiple existing PopUpCard documents */
  connect?: InputMaybe<Array<PopUpCardWhereUniqueInput>>;
  /** Create and connect multiple existing PopUpCard documents */
  create?: InputMaybe<Array<PopUpCardCreateInput>>;
};

export type PopUpCardCreateOneInlineInput = {
  /** Connect one existing PopUpCard document */
  connect?: InputMaybe<PopUpCardWhereUniqueInput>;
  /** Create and connect one PopUpCard document */
  create?: InputMaybe<PopUpCardCreateInput>;
};

/** An edge in a connection. */
export type PopUpCardEdge = {
  __typename?: 'PopUpCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PopUpCard;
};

/** Identifies documents */
export type PopUpCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PopUpCardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PopUpCardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PopUpCardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  popUpCardHeaderIcon?: InputMaybe<AssetWhereInput>;
  popUpCardTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  popUpCardTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popUpCardTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popUpCardTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popUpCardTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popUpCardTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popUpCardTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popUpCardTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popUpCardTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popUpCardTitle_starts_with?: InputMaybe<Scalars['String']>;
  popUpCloseIcon?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PopUpCardOrderByInput {
  CancellationCostAmountDenominationAsc = 'cancellationCostAmountDenomination_ASC',
  CancellationCostAmountDenominationDesc = 'cancellationCostAmountDenomination_DESC',
  CancellationCostTitleAsc = 'cancellationCostTitle_ASC',
  CancellationCostTitleDesc = 'cancellationCostTitle_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PopUpCardHeaderAsc = 'popUpCardHeader_ASC',
  PopUpCardHeaderDesc = 'popUpCardHeader_DESC',
  PopUpCardSecondaryDescriptionAsc = 'popUpCardSecondaryDescription_ASC',
  PopUpCardSecondaryDescriptionDesc = 'popUpCardSecondaryDescription_DESC',
  PopUpCardTextAsc = 'popUpCardText_ASC',
  PopUpCardTextDesc = 'popUpCardText_DESC',
  PopUpCardTitleAsc = 'popUpCardTitle_ASC',
  PopUpCardTitleDesc = 'popUpCardTitle_DESC',
  PopupCtaButtonTextAsc = 'popupCTAButtonText_ASC',
  PopupCtaButtonTextDesc = 'popupCTAButtonText_DESC',
  PopupSecondaryButtonTextAsc = 'popupSecondaryButtonText_ASC',
  PopupSecondaryButtonTextDesc = 'popupSecondaryButtonText_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PopUpCardUpdateInput = {
  /** cancellationCostAmountDenomination input for default locale (de_CH) */
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  /** cancellationCostTitle input for default locale (de_CH) */
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PopUpCardUpdateLocalizationsInput>;
  /** popUpCardHeader input for default locale (de_CH) */
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  popUpCardHeaderIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** popUpCardSecondaryDescription input for default locale (de_CH) */
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  /** popUpCardText input for default locale (de_CH) */
  popUpCardText?: InputMaybe<Scalars['String']>;
  popUpCardTitle?: InputMaybe<Scalars['String']>;
  popUpCloseIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** popupCTAButtonText input for default locale (de_CH) */
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  /** popupSecondaryButtonText input for default locale (de_CH) */
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
};

export type PopUpCardUpdateLocalizationDataInput = {
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  popUpCardText?: InputMaybe<Scalars['String']>;
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
};

export type PopUpCardUpdateLocalizationInput = {
  data: PopUpCardUpdateLocalizationDataInput;
  locale: Locale;
};

export type PopUpCardUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PopUpCardCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PopUpCardUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PopUpCardUpsertLocalizationInput>>;
};

export type PopUpCardUpdateManyInlineInput = {
  /** Connect multiple existing PopUpCard documents */
  connect?: InputMaybe<Array<PopUpCardConnectInput>>;
  /** Create and connect multiple PopUpCard documents */
  create?: InputMaybe<Array<PopUpCardCreateInput>>;
  /** Delete multiple PopUpCard documents */
  delete?: InputMaybe<Array<PopUpCardWhereUniqueInput>>;
  /** Disconnect multiple PopUpCard documents */
  disconnect?: InputMaybe<Array<PopUpCardWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PopUpCard documents */
  set?: InputMaybe<Array<PopUpCardWhereUniqueInput>>;
  /** Update multiple PopUpCard documents */
  update?: InputMaybe<Array<PopUpCardUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PopUpCard documents */
  upsert?: InputMaybe<Array<PopUpCardUpsertWithNestedWhereUniqueInput>>;
};

export type PopUpCardUpdateManyInput = {
  /** cancellationCostAmountDenomination input for default locale (de_CH) */
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  /** cancellationCostTitle input for default locale (de_CH) */
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PopUpCardUpdateManyLocalizationsInput>;
  /** popUpCardHeader input for default locale (de_CH) */
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  /** popUpCardSecondaryDescription input for default locale (de_CH) */
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  /** popUpCardText input for default locale (de_CH) */
  popUpCardText?: InputMaybe<Scalars['String']>;
  popUpCardTitle?: InputMaybe<Scalars['String']>;
  /** popupCTAButtonText input for default locale (de_CH) */
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  /** popupSecondaryButtonText input for default locale (de_CH) */
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
};

export type PopUpCardUpdateManyLocalizationDataInput = {
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  popUpCardText?: InputMaybe<Scalars['String']>;
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
};

export type PopUpCardUpdateManyLocalizationInput = {
  data: PopUpCardUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PopUpCardUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PopUpCardUpdateManyLocalizationInput>>;
};

export type PopUpCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PopUpCardUpdateManyInput;
  /** Document search */
  where: PopUpCardWhereInput;
};

export type PopUpCardUpdateOneInlineInput = {
  /** Connect existing PopUpCard document */
  connect?: InputMaybe<PopUpCardWhereUniqueInput>;
  /** Create and connect one PopUpCard document */
  create?: InputMaybe<PopUpCardCreateInput>;
  /** Delete currently connected PopUpCard document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PopUpCard document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PopUpCard document */
  update?: InputMaybe<PopUpCardUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PopUpCard document */
  upsert?: InputMaybe<PopUpCardUpsertWithNestedWhereUniqueInput>;
};

export type PopUpCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PopUpCardUpdateInput;
  /** Unique document search */
  where: PopUpCardWhereUniqueInput;
};

export type PopUpCardUpsertInput = {
  /** Create document if it didn't exist */
  create: PopUpCardCreateInput;
  /** Update document if it exists */
  update: PopUpCardUpdateInput;
};

export type PopUpCardUpsertLocalizationInput = {
  create: PopUpCardCreateLocalizationDataInput;
  locale: Locale;
  update: PopUpCardUpdateLocalizationDataInput;
};

export type PopUpCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PopUpCardUpsertInput;
  /** Unique document search */
  where: PopUpCardWhereUniqueInput;
};

/** Identifies documents */
export type PopUpCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PopUpCardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PopUpCardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PopUpCardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cancellationCostAmountDenomination?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  cancellationCostAmountDenomination_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  cancellationCostAmountDenomination_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  cancellationCostAmountDenomination_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  cancellationCostAmountDenomination_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  cancellationCostAmountDenomination_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  cancellationCostAmountDenomination_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  cancellationCostAmountDenomination_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  cancellationCostAmountDenomination_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  cancellationCostAmountDenomination_starts_with?: InputMaybe<Scalars['String']>;
  cancellationCostTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  cancellationCostTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  cancellationCostTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  cancellationCostTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  cancellationCostTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  cancellationCostTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  cancellationCostTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  cancellationCostTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  cancellationCostTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  cancellationCostTitle_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  popUpCardHeader?: InputMaybe<Scalars['String']>;
  popUpCardHeaderIcon?: InputMaybe<AssetWhereInput>;
  /** All values containing the given string. */
  popUpCardHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popUpCardHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popUpCardHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popUpCardHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popUpCardHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popUpCardHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popUpCardHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popUpCardHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popUpCardHeader_starts_with?: InputMaybe<Scalars['String']>;
  popUpCardSecondaryDescription?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  popUpCardSecondaryDescription_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popUpCardSecondaryDescription_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popUpCardSecondaryDescription_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popUpCardSecondaryDescription_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popUpCardSecondaryDescription_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popUpCardSecondaryDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popUpCardSecondaryDescription_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popUpCardSecondaryDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popUpCardSecondaryDescription_starts_with?: InputMaybe<Scalars['String']>;
  popUpCardText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  popUpCardText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popUpCardText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popUpCardText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popUpCardText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popUpCardText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popUpCardText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popUpCardText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popUpCardText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popUpCardText_starts_with?: InputMaybe<Scalars['String']>;
  popUpCardTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  popUpCardTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popUpCardTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popUpCardTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popUpCardTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popUpCardTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popUpCardTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popUpCardTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popUpCardTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popUpCardTitle_starts_with?: InputMaybe<Scalars['String']>;
  popUpCloseIcon?: InputMaybe<AssetWhereInput>;
  popupCTAButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  popupCTAButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popupCTAButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popupCTAButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popupCTAButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popupCTAButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popupCTAButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popupCTAButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popupCTAButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popupCTAButtonText_starts_with?: InputMaybe<Scalars['String']>;
  popupSecondaryButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  popupSecondaryButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  popupSecondaryButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  popupSecondaryButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  popupSecondaryButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  popupSecondaryButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  popupSecondaryButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  popupSecondaryButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  popupSecondaryButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  popupSecondaryButtonText_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References PopUpCard record uniquely */
export type PopUpCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdown = Node & {
  __typename?: 'PriceBreakdown';
  /** This is the icon to expand the card and collapse it again. */
  cardExpandCollapseIcon: Array<Asset>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<PriceBreakdown>;
  /** List of PriceBreakdown versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PriceBreakdown>;
  /** This is the titel field of the price preakdown just internal reference! It is not shown anywhere in the UI. There is also no localization for it. */
  priceBreakdownInternalName?: Maybe<Scalars['String']>;
  /** This is the mapping for the price section (should be the same as RENT) */
  priceBreakdownMapping?: Maybe<PriceBreakdownMapping>;
  /**
   * This is the 'CHF' AFTER each unit in Swiss Franc, for example:
   * 100 CHF or 10 CHF or 1 CHF
   */
  priceChf?: Maybe<Scalars['String']>;
  /** This it the subtitle name for 'Days x'. 'x' means times, for the time & mileage calculation. */
  priceDaysName?: Maybe<Scalars['String']>;
  /**
   * This is the product name that we are selling (check WIKI):
   * 'Rental' which is the time and mileage
   * 'Coverage' is the coverage type
   * 'Extras' are ski rack, etc
   * VAT is the Swiss VAT, always 7.7%
   */
  productName: Array<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** This is the header of the price */
  totalPriceName?: Maybe<Scalars['String']>;
  /** This is the subtitle, shown either when card is collapsed or expanded. */
  totalPriceSubtitle: Array<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** This is the '7.7%' shown to the user. */
  vatSubtitle?: Maybe<Scalars['String']>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownCardExpandCollapseIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the price breakdown to show the user the different prices of the products, VAT and full price. */
export type PriceBreakdownUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type PriceBreakdownConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PriceBreakdownWhereUniqueInput;
};

/** A connection to a list of items. */
export type PriceBreakdownConnection = {
  __typename?: 'PriceBreakdownConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PriceBreakdownEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PriceBreakdownCreateInput = {
  cardExpandCollapseIcon?: InputMaybe<AssetCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PriceBreakdownCreateLocalizationsInput>;
  priceBreakdownInternalName?: InputMaybe<Scalars['String']>;
  priceBreakdownMapping?: InputMaybe<PriceBreakdownMapping>;
  priceChf?: InputMaybe<Scalars['String']>;
  /** priceDaysName input for default locale (de_CH) */
  priceDaysName?: InputMaybe<Scalars['String']>;
  /** productName input for default locale (de_CH) */
  productName?: InputMaybe<Array<Scalars['String']>>;
  /** totalPriceName input for default locale (de_CH) */
  totalPriceName?: InputMaybe<Scalars['String']>;
  /** totalPriceSubtitle input for default locale (de_CH) */
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vatSubtitle?: InputMaybe<Scalars['String']>;
};

export type PriceBreakdownCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  priceDaysName?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Array<Scalars['String']>>;
  totalPriceName?: InputMaybe<Scalars['String']>;
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PriceBreakdownCreateLocalizationInput = {
  /** Localization input */
  data: PriceBreakdownCreateLocalizationDataInput;
  locale: Locale;
};

export type PriceBreakdownCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PriceBreakdownCreateLocalizationInput>>;
};

export type PriceBreakdownCreateManyInlineInput = {
  /** Connect multiple existing PriceBreakdown documents */
  connect?: InputMaybe<Array<PriceBreakdownWhereUniqueInput>>;
  /** Create and connect multiple existing PriceBreakdown documents */
  create?: InputMaybe<Array<PriceBreakdownCreateInput>>;
};

export type PriceBreakdownCreateOneInlineInput = {
  /** Connect one existing PriceBreakdown document */
  connect?: InputMaybe<PriceBreakdownWhereUniqueInput>;
  /** Create and connect one PriceBreakdown document */
  create?: InputMaybe<PriceBreakdownCreateInput>;
};

/** An edge in a connection. */
export type PriceBreakdownEdge = {
  __typename?: 'PriceBreakdownEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PriceBreakdown;
};

/** Identifies documents */
export type PriceBreakdownManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PriceBreakdownWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PriceBreakdownWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PriceBreakdownWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cardExpandCollapseIcon_every?: InputMaybe<AssetWhereInput>;
  cardExpandCollapseIcon_none?: InputMaybe<AssetWhereInput>;
  cardExpandCollapseIcon_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  priceBreakdownInternalName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceBreakdownInternalName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceBreakdownInternalName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceBreakdownInternalName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceBreakdownInternalName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceBreakdownInternalName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceBreakdownInternalName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceBreakdownInternalName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceBreakdownInternalName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceBreakdownInternalName_starts_with?: InputMaybe<Scalars['String']>;
  priceBreakdownMapping?: InputMaybe<PriceBreakdownMapping>;
  /** All values that are contained in given list. */
  priceBreakdownMapping_in?: InputMaybe<Array<PriceBreakdownMapping>>;
  /** All values that are not equal to given value. */
  priceBreakdownMapping_not?: InputMaybe<PriceBreakdownMapping>;
  /** All values that are not contained in given list. */
  priceBreakdownMapping_not_in?: InputMaybe<Array<PriceBreakdownMapping>>;
  priceChf?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceChf_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceChf_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceChf_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceChf_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceChf_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceChf_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceChf_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceChf_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceChf_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vatSubtitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  vatSubtitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  vatSubtitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  vatSubtitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  vatSubtitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  vatSubtitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  vatSubtitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  vatSubtitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  vatSubtitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  vatSubtitle_starts_with?: InputMaybe<Scalars['String']>;
};

/** This is the mapping enumeration for the price breakdown sections (eg. VAT, Coverage, Time and Mileage) */
export enum PriceBreakdownMapping {
  Coverage = 'Coverage',
  Extras = 'Extras',
  Total = 'Total',
  Vat = 'VAT',
  TimeAndMileage = 'timeAndMileage'
}

export enum PriceBreakdownOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PriceBreakdownInternalNameAsc = 'priceBreakdownInternalName_ASC',
  PriceBreakdownInternalNameDesc = 'priceBreakdownInternalName_DESC',
  PriceBreakdownMappingAsc = 'priceBreakdownMapping_ASC',
  PriceBreakdownMappingDesc = 'priceBreakdownMapping_DESC',
  PriceChfAsc = 'priceChf_ASC',
  PriceChfDesc = 'priceChf_DESC',
  PriceDaysNameAsc = 'priceDaysName_ASC',
  PriceDaysNameDesc = 'priceDaysName_DESC',
  ProductNameAsc = 'productName_ASC',
  ProductNameDesc = 'productName_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TotalPriceNameAsc = 'totalPriceName_ASC',
  TotalPriceNameDesc = 'totalPriceName_DESC',
  TotalPriceSubtitleAsc = 'totalPriceSubtitle_ASC',
  TotalPriceSubtitleDesc = 'totalPriceSubtitle_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatSubtitleAsc = 'vatSubtitle_ASC',
  VatSubtitleDesc = 'vatSubtitle_DESC'
}

export type PriceBreakdownUpdateInput = {
  cardExpandCollapseIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<PriceBreakdownUpdateLocalizationsInput>;
  priceBreakdownInternalName?: InputMaybe<Scalars['String']>;
  priceBreakdownMapping?: InputMaybe<PriceBreakdownMapping>;
  priceChf?: InputMaybe<Scalars['String']>;
  /** priceDaysName input for default locale (de_CH) */
  priceDaysName?: InputMaybe<Scalars['String']>;
  /** productName input for default locale (de_CH) */
  productName?: InputMaybe<Array<Scalars['String']>>;
  /** totalPriceName input for default locale (de_CH) */
  totalPriceName?: InputMaybe<Scalars['String']>;
  /** totalPriceSubtitle input for default locale (de_CH) */
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
  vatSubtitle?: InputMaybe<Scalars['String']>;
};

export type PriceBreakdownUpdateLocalizationDataInput = {
  priceDaysName?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Array<Scalars['String']>>;
  totalPriceName?: InputMaybe<Scalars['String']>;
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
};

export type PriceBreakdownUpdateLocalizationInput = {
  data: PriceBreakdownUpdateLocalizationDataInput;
  locale: Locale;
};

export type PriceBreakdownUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PriceBreakdownCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PriceBreakdownUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PriceBreakdownUpsertLocalizationInput>>;
};

export type PriceBreakdownUpdateManyInlineInput = {
  /** Connect multiple existing PriceBreakdown documents */
  connect?: InputMaybe<Array<PriceBreakdownConnectInput>>;
  /** Create and connect multiple PriceBreakdown documents */
  create?: InputMaybe<Array<PriceBreakdownCreateInput>>;
  /** Delete multiple PriceBreakdown documents */
  delete?: InputMaybe<Array<PriceBreakdownWhereUniqueInput>>;
  /** Disconnect multiple PriceBreakdown documents */
  disconnect?: InputMaybe<Array<PriceBreakdownWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PriceBreakdown documents */
  set?: InputMaybe<Array<PriceBreakdownWhereUniqueInput>>;
  /** Update multiple PriceBreakdown documents */
  update?: InputMaybe<Array<PriceBreakdownUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PriceBreakdown documents */
  upsert?: InputMaybe<Array<PriceBreakdownUpsertWithNestedWhereUniqueInput>>;
};

export type PriceBreakdownUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<PriceBreakdownUpdateManyLocalizationsInput>;
  priceBreakdownInternalName?: InputMaybe<Scalars['String']>;
  priceBreakdownMapping?: InputMaybe<PriceBreakdownMapping>;
  priceChf?: InputMaybe<Scalars['String']>;
  /** priceDaysName input for default locale (de_CH) */
  priceDaysName?: InputMaybe<Scalars['String']>;
  /** productName input for default locale (de_CH) */
  productName?: InputMaybe<Array<Scalars['String']>>;
  /** totalPriceName input for default locale (de_CH) */
  totalPriceName?: InputMaybe<Scalars['String']>;
  /** totalPriceSubtitle input for default locale (de_CH) */
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
  vatSubtitle?: InputMaybe<Scalars['String']>;
};

export type PriceBreakdownUpdateManyLocalizationDataInput = {
  priceDaysName?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Array<Scalars['String']>>;
  totalPriceName?: InputMaybe<Scalars['String']>;
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
};

export type PriceBreakdownUpdateManyLocalizationInput = {
  data: PriceBreakdownUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PriceBreakdownUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PriceBreakdownUpdateManyLocalizationInput>>;
};

export type PriceBreakdownUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PriceBreakdownUpdateManyInput;
  /** Document search */
  where: PriceBreakdownWhereInput;
};

export type PriceBreakdownUpdateOneInlineInput = {
  /** Connect existing PriceBreakdown document */
  connect?: InputMaybe<PriceBreakdownWhereUniqueInput>;
  /** Create and connect one PriceBreakdown document */
  create?: InputMaybe<PriceBreakdownCreateInput>;
  /** Delete currently connected PriceBreakdown document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PriceBreakdown document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PriceBreakdown document */
  update?: InputMaybe<PriceBreakdownUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PriceBreakdown document */
  upsert?: InputMaybe<PriceBreakdownUpsertWithNestedWhereUniqueInput>;
};

export type PriceBreakdownUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PriceBreakdownUpdateInput;
  /** Unique document search */
  where: PriceBreakdownWhereUniqueInput;
};

export type PriceBreakdownUpsertInput = {
  /** Create document if it didn't exist */
  create: PriceBreakdownCreateInput;
  /** Update document if it exists */
  update: PriceBreakdownUpdateInput;
};

export type PriceBreakdownUpsertLocalizationInput = {
  create: PriceBreakdownCreateLocalizationDataInput;
  locale: Locale;
  update: PriceBreakdownUpdateLocalizationDataInput;
};

export type PriceBreakdownUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PriceBreakdownUpsertInput;
  /** Unique document search */
  where: PriceBreakdownWhereUniqueInput;
};

/** Identifies documents */
export type PriceBreakdownWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PriceBreakdownWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PriceBreakdownWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PriceBreakdownWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cardExpandCollapseIcon_every?: InputMaybe<AssetWhereInput>;
  cardExpandCollapseIcon_none?: InputMaybe<AssetWhereInput>;
  cardExpandCollapseIcon_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  priceBreakdownInternalName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceBreakdownInternalName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceBreakdownInternalName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceBreakdownInternalName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceBreakdownInternalName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceBreakdownInternalName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceBreakdownInternalName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceBreakdownInternalName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceBreakdownInternalName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceBreakdownInternalName_starts_with?: InputMaybe<Scalars['String']>;
  priceBreakdownMapping?: InputMaybe<PriceBreakdownMapping>;
  /** All values that are contained in given list. */
  priceBreakdownMapping_in?: InputMaybe<Array<PriceBreakdownMapping>>;
  /** All values that are not equal to given value. */
  priceBreakdownMapping_not?: InputMaybe<PriceBreakdownMapping>;
  /** All values that are not contained in given list. */
  priceBreakdownMapping_not_in?: InputMaybe<Array<PriceBreakdownMapping>>;
  priceChf?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceChf_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceChf_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceChf_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceChf_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceChf_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceChf_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceChf_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceChf_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceChf_starts_with?: InputMaybe<Scalars['String']>;
  priceDaysName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  priceDaysName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  priceDaysName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  priceDaysName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  priceDaysName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  priceDaysName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  priceDaysName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  priceDaysName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  priceDaysName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  priceDaysName_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  productName?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  productName_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  productName_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  productName_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  productName_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  totalPriceName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  totalPriceName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  totalPriceName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  totalPriceName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  totalPriceName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  totalPriceName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  totalPriceName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  totalPriceName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  totalPriceName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  totalPriceName_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  totalPriceSubtitle?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  totalPriceSubtitle_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  totalPriceSubtitle_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  totalPriceSubtitle_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  totalPriceSubtitle_not?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vatSubtitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  vatSubtitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  vatSubtitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  vatSubtitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  vatSubtitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  vatSubtitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  vatSubtitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  vatSubtitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  vatSubtitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  vatSubtitle_starts_with?: InputMaybe<Scalars['String']>;
};

/** References PriceBreakdown record uniquely */
export type PriceBreakdownWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the profile schema */
export type Profile = Node & {
  __typename?: 'Profile';
  /** This is the 'add new payment method' below the existing payment methods. */
  addNewPaymentMethod?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** This is a header of a specific field within the credit card form */
  creditCardInputHeader: Array<Scalars['String']>;
  /** This is the placeholder text when entering a new card in the credit card form. */
  creditCardInputPlaceholder: Array<Scalars['String']>;
  /** This is the delete payment method icon */
  deletePaymentMethodIcon?: Maybe<Asset>;
  /** Get the document in other stages */
  documentInStages: Array<Profile>;
  /** This is the right hand arrow to edit the profile. */
  editProfileIcon?: Maybe<Asset>;
  /** List of Profile versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** This field is the name above the input field, for example: First name. */
  inputFieldName: Array<Scalars['String']>;
  /** This is the language selection. */
  language: Array<Scalars['String']>;
  /** The short description about accepting the legal fine print */
  legalConfirmation?: Maybe<Scalars['String']>;
  /** The checkbox is there to confirm that the user agrees with our rental policy and data protection. */
  legalcheckbox: Array<Asset>;
  legals: Array<Legal>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Profile>;
  /** This is the user information about their card being encrypted when transmitted */
  paymentEncryptionText?: Maybe<Scalars['String']>;
  /** This is the icon of the payment method (visa, etc.) */
  paymentMethodIcon: Array<Asset>;
  /** This is the header of the screen when adding a new payment method. */
  paymentMethodName: Array<Scalars['String']>;
  /** This is the selection of the payment */
  paymentSelection: Array<ProfilePaymentSelectionRichText>;
  /** This is the text shown as a placeholder if an input field is deleted. */
  placeholderText: Array<Scalars['String']>;
  /** These are the icons used for the profile section (email, tel, address, globe, dropdown) */
  profileIcons: Array<Asset>;
  /** This is the section header */
  profileSectionHeader?: Maybe<Scalars['String']>;
  /** This is the icon left of the profile section header */
  profileSectionIcon?: Maybe<Asset>;
  /** This is to group the fields together when developing the content. This is not shown to the user. */
  profileSectionInternal?: Maybe<ProfileSection>;
  /** This is the sub header just below the screen header, e.g. PERSONAL INFORMATION */
  profileSubHeader?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** This is the save button when any changes are made. */
  saveButton?: Maybe<Scalars['String']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** This is the continue icon that is next to the not verified and failed verification statuses */
  verificationLinkIcon?: Maybe<Asset>;
  /** The verification status shown to the user */
  verificationStatus: Array<ProfileVerificationStatusRichText>;
  /**
   * This is the Icon that illustrates the verification status
   *
   * Eg. [pending icon] pending
   */
  verificationStatusIcon: Array<Asset>;
  /** This is the static verification status: header. */
  verificationStatusName?: Maybe<Scalars['String']>;
};


/** This is the profile schema */
export type ProfileCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the profile schema */
export type ProfileCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfileDeletePaymentMethodIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfileDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the profile schema */
export type ProfileEditProfileIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfileHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the profile schema */
export type ProfileLegalcheckboxArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the profile schema */
export type ProfileLegalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LegalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegalWhereInput>;
};


/** This is the profile schema */
export type ProfileLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the profile schema */
export type ProfilePaymentMethodIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the profile schema */
export type ProfileProfileIconsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** This is the profile schema */
export type ProfileProfileSectionIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfilePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the profile schema */
export type ProfilePublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfileScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the profile schema */
export type ProfileUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the profile schema */
export type ProfileUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfileVerificationLinkIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the profile schema */
export type ProfileVerificationStatusIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};

export type ProfileConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProfileWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProfileEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProfileCreateInput = {
  /** addNewPaymentMethod input for default locale (de_CH) */
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** creditCardInputHeader input for default locale (de_CH) */
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  /** creditCardInputPlaceholder input for default locale (de_CH) */
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  deletePaymentMethodIcon?: InputMaybe<AssetCreateOneInlineInput>;
  editProfileIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** inputFieldName input for default locale (de_CH) */
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  /** language input for default locale (de_CH) */
  language?: InputMaybe<Array<Scalars['String']>>;
  /** legalConfirmation input for default locale (de_CH) */
  legalConfirmation?: InputMaybe<Scalars['String']>;
  legalcheckbox?: InputMaybe<AssetCreateManyInlineInput>;
  legals?: InputMaybe<LegalCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProfileCreateLocalizationsInput>;
  /** paymentEncryptionText input for default locale (de_CH) */
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  paymentMethodIcon?: InputMaybe<AssetCreateManyInlineInput>;
  /** paymentMethodName input for default locale (de_CH) */
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  /** paymentSelection input for default locale (de_CH) */
  paymentSelection?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** placeholderText input for default locale (de_CH) */
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  profileIcons?: InputMaybe<AssetCreateManyInlineInput>;
  /** profileSectionHeader input for default locale (de_CH) */
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  profileSectionIcon?: InputMaybe<AssetCreateOneInlineInput>;
  profileSectionInternal?: InputMaybe<ProfileSection>;
  /** profileSubHeader input for default locale (de_CH) */
  profileSubHeader?: InputMaybe<Scalars['String']>;
  /** saveButton input for default locale (de_CH) */
  saveButton?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verificationLinkIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** verificationStatus input for default locale (de_CH) */
  verificationStatus?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verificationStatusIcon?: InputMaybe<AssetCreateManyInlineInput>;
  /** verificationStatusName input for default locale (de_CH) */
  verificationStatusName?: InputMaybe<Scalars['String']>;
};

export type ProfileCreateLocalizationDataInput = {
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Array<Scalars['String']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  paymentSelection?: InputMaybe<Array<Scalars['RichTextAST']>>;
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  profileSubHeader?: InputMaybe<Scalars['String']>;
  saveButton?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verificationStatus?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verificationStatusName?: InputMaybe<Scalars['String']>;
};

export type ProfileCreateLocalizationInput = {
  /** Localization input */
  data: ProfileCreateLocalizationDataInput;
  locale: Locale;
};

export type ProfileCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProfileCreateLocalizationInput>>;
};

export type ProfileCreateManyInlineInput = {
  /** Connect multiple existing Profile documents */
  connect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  /** Create and connect multiple existing Profile documents */
  create?: InputMaybe<Array<ProfileCreateInput>>;
};

export type ProfileCreateOneInlineInput = {
  /** Connect one existing Profile document */
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  /** Create and connect one Profile document */
  create?: InputMaybe<ProfileCreateInput>;
};

/** An edge in a connection. */
export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Profile;
};

/** Identifies documents */
export type ProfileManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  deletePaymentMethodIcon?: InputMaybe<AssetWhereInput>;
  editProfileIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  legalcheckbox_every?: InputMaybe<AssetWhereInput>;
  legalcheckbox_none?: InputMaybe<AssetWhereInput>;
  legalcheckbox_some?: InputMaybe<AssetWhereInput>;
  legals_every?: InputMaybe<LegalWhereInput>;
  legals_none?: InputMaybe<LegalWhereInput>;
  legals_some?: InputMaybe<LegalWhereInput>;
  paymentMethodIcon_every?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_none?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_some?: InputMaybe<AssetWhereInput>;
  profileIcons_every?: InputMaybe<AssetWhereInput>;
  profileIcons_none?: InputMaybe<AssetWhereInput>;
  profileIcons_some?: InputMaybe<AssetWhereInput>;
  profileSectionIcon?: InputMaybe<AssetWhereInput>;
  profileSectionInternal?: InputMaybe<ProfileSection>;
  /** All values that are contained in given list. */
  profileSectionInternal_in?: InputMaybe<Array<ProfileSection>>;
  /** All values that are not equal to given value. */
  profileSectionInternal_not?: InputMaybe<ProfileSection>;
  /** All values that are not contained in given list. */
  profileSectionInternal_not_in?: InputMaybe<Array<ProfileSection>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  verificationLinkIcon?: InputMaybe<AssetWhereInput>;
  verificationStatusIcon_every?: InputMaybe<AssetWhereInput>;
  verificationStatusIcon_none?: InputMaybe<AssetWhereInput>;
  verificationStatusIcon_some?: InputMaybe<AssetWhereInput>;
};

export enum ProfileOrderByInput {
  AddNewPaymentMethodAsc = 'addNewPaymentMethod_ASC',
  AddNewPaymentMethodDesc = 'addNewPaymentMethod_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreditCardInputHeaderAsc = 'creditCardInputHeader_ASC',
  CreditCardInputHeaderDesc = 'creditCardInputHeader_DESC',
  CreditCardInputPlaceholderAsc = 'creditCardInputPlaceholder_ASC',
  CreditCardInputPlaceholderDesc = 'creditCardInputPlaceholder_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputFieldNameAsc = 'inputFieldName_ASC',
  InputFieldNameDesc = 'inputFieldName_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  LegalConfirmationAsc = 'legalConfirmation_ASC',
  LegalConfirmationDesc = 'legalConfirmation_DESC',
  PaymentEncryptionTextAsc = 'paymentEncryptionText_ASC',
  PaymentEncryptionTextDesc = 'paymentEncryptionText_DESC',
  PaymentMethodNameAsc = 'paymentMethodName_ASC',
  PaymentMethodNameDesc = 'paymentMethodName_DESC',
  PlaceholderTextAsc = 'placeholderText_ASC',
  PlaceholderTextDesc = 'placeholderText_DESC',
  ProfileSectionHeaderAsc = 'profileSectionHeader_ASC',
  ProfileSectionHeaderDesc = 'profileSectionHeader_DESC',
  ProfileSectionInternalAsc = 'profileSectionInternal_ASC',
  ProfileSectionInternalDesc = 'profileSectionInternal_DESC',
  ProfileSubHeaderAsc = 'profileSubHeader_ASC',
  ProfileSubHeaderDesc = 'profileSubHeader_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SaveButtonAsc = 'saveButton_ASC',
  SaveButtonDesc = 'saveButton_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VerificationStatusNameAsc = 'verificationStatusName_ASC',
  VerificationStatusNameDesc = 'verificationStatusName_DESC'
}

export type ProfilePaymentSelectionRichText = {
  __typename?: 'ProfilePaymentSelectionRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<ProfilePaymentSelectionRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type ProfilePaymentSelectionRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ProfilePaymentSelectionRichTextEmbeddedTypes = Asset;

/** For internal use only to cluster the content better. */
export enum ProfileSection {
  Coupons = 'Coupons',
  Coverage = 'Coverage',
  CreateProfile = 'CreateProfile',
  CreateProfile2 = 'CreateProfile2',
  NewPaymentMethod = 'NewPaymentMethod',
  PaymentMethods = 'PaymentMethods',
  Profile = 'Profile'
}

export type ProfileUpdateInput = {
  /** addNewPaymentMethod input for default locale (de_CH) */
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  /** creditCardInputHeader input for default locale (de_CH) */
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  /** creditCardInputPlaceholder input for default locale (de_CH) */
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  deletePaymentMethodIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  editProfileIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** inputFieldName input for default locale (de_CH) */
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  /** language input for default locale (de_CH) */
  language?: InputMaybe<Array<Scalars['String']>>;
  /** legalConfirmation input for default locale (de_CH) */
  legalConfirmation?: InputMaybe<Scalars['String']>;
  legalcheckbox?: InputMaybe<AssetUpdateManyInlineInput>;
  legals?: InputMaybe<LegalUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProfileUpdateLocalizationsInput>;
  /** paymentEncryptionText input for default locale (de_CH) */
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  paymentMethodIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** paymentMethodName input for default locale (de_CH) */
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  /** paymentSelection input for default locale (de_CH) */
  paymentSelection?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** placeholderText input for default locale (de_CH) */
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  profileIcons?: InputMaybe<AssetUpdateManyInlineInput>;
  /** profileSectionHeader input for default locale (de_CH) */
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  profileSectionIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  profileSectionInternal?: InputMaybe<ProfileSection>;
  /** profileSubHeader input for default locale (de_CH) */
  profileSubHeader?: InputMaybe<Scalars['String']>;
  /** saveButton input for default locale (de_CH) */
  saveButton?: InputMaybe<Scalars['String']>;
  verificationLinkIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** verificationStatus input for default locale (de_CH) */
  verificationStatus?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verificationStatusIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** verificationStatusName input for default locale (de_CH) */
  verificationStatusName?: InputMaybe<Scalars['String']>;
};

export type ProfileUpdateLocalizationDataInput = {
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Array<Scalars['String']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  paymentSelection?: InputMaybe<Array<Scalars['RichTextAST']>>;
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  profileSubHeader?: InputMaybe<Scalars['String']>;
  saveButton?: InputMaybe<Scalars['String']>;
  verificationStatus?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verificationStatusName?: InputMaybe<Scalars['String']>;
};

export type ProfileUpdateLocalizationInput = {
  data: ProfileUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProfileUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProfileCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProfileUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProfileUpsertLocalizationInput>>;
};

export type ProfileUpdateManyInlineInput = {
  /** Connect multiple existing Profile documents */
  connect?: InputMaybe<Array<ProfileConnectInput>>;
  /** Create and connect multiple Profile documents */
  create?: InputMaybe<Array<ProfileCreateInput>>;
  /** Delete multiple Profile documents */
  delete?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  /** Disconnect multiple Profile documents */
  disconnect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Profile documents */
  set?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  /** Update multiple Profile documents */
  update?: InputMaybe<Array<ProfileUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Profile documents */
  upsert?: InputMaybe<Array<ProfileUpsertWithNestedWhereUniqueInput>>;
};

export type ProfileUpdateManyInput = {
  /** addNewPaymentMethod input for default locale (de_CH) */
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  /** creditCardInputHeader input for default locale (de_CH) */
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  /** creditCardInputPlaceholder input for default locale (de_CH) */
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  /** inputFieldName input for default locale (de_CH) */
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  /** language input for default locale (de_CH) */
  language?: InputMaybe<Array<Scalars['String']>>;
  /** legalConfirmation input for default locale (de_CH) */
  legalConfirmation?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProfileUpdateManyLocalizationsInput>;
  /** paymentEncryptionText input for default locale (de_CH) */
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  /** paymentMethodName input for default locale (de_CH) */
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  /** paymentSelection input for default locale (de_CH) */
  paymentSelection?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** placeholderText input for default locale (de_CH) */
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  /** profileSectionHeader input for default locale (de_CH) */
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  profileSectionInternal?: InputMaybe<ProfileSection>;
  /** profileSubHeader input for default locale (de_CH) */
  profileSubHeader?: InputMaybe<Scalars['String']>;
  /** saveButton input for default locale (de_CH) */
  saveButton?: InputMaybe<Scalars['String']>;
  /** verificationStatus input for default locale (de_CH) */
  verificationStatus?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** verificationStatusName input for default locale (de_CH) */
  verificationStatusName?: InputMaybe<Scalars['String']>;
};

export type ProfileUpdateManyLocalizationDataInput = {
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Array<Scalars['String']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  paymentSelection?: InputMaybe<Array<Scalars['RichTextAST']>>;
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  profileSubHeader?: InputMaybe<Scalars['String']>;
  saveButton?: InputMaybe<Scalars['String']>;
  verificationStatus?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verificationStatusName?: InputMaybe<Scalars['String']>;
};

export type ProfileUpdateManyLocalizationInput = {
  data: ProfileUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProfileUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProfileUpdateManyLocalizationInput>>;
};

export type ProfileUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProfileUpdateManyInput;
  /** Document search */
  where: ProfileWhereInput;
};

export type ProfileUpdateOneInlineInput = {
  /** Connect existing Profile document */
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  /** Create and connect one Profile document */
  create?: InputMaybe<ProfileCreateInput>;
  /** Delete currently connected Profile document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Profile document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Profile document */
  update?: InputMaybe<ProfileUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Profile document */
  upsert?: InputMaybe<ProfileUpsertWithNestedWhereUniqueInput>;
};

export type ProfileUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProfileUpdateInput;
  /** Unique document search */
  where: ProfileWhereUniqueInput;
};

export type ProfileUpsertInput = {
  /** Create document if it didn't exist */
  create: ProfileCreateInput;
  /** Update document if it exists */
  update: ProfileUpdateInput;
};

export type ProfileUpsertLocalizationInput = {
  create: ProfileCreateLocalizationDataInput;
  locale: Locale;
  update: ProfileUpdateLocalizationDataInput;
};

export type ProfileUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProfileUpsertInput;
  /** Unique document search */
  where: ProfileWhereUniqueInput;
};

export type ProfileVerificationStatusRichText = {
  __typename?: 'ProfileVerificationStatusRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<ProfileVerificationStatusRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type ProfileVerificationStatusRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ProfileVerificationStatusRichTextEmbeddedTypes = Asset;

/** Identifies documents */
export type ProfileWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  addNewPaymentMethod?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  addNewPaymentMethod_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  addNewPaymentMethod_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  addNewPaymentMethod_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  addNewPaymentMethod_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  addNewPaymentMethod_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  addNewPaymentMethod_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  addNewPaymentMethod_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  addNewPaymentMethod_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  addNewPaymentMethod_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  creditCardInputHeader?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  creditCardInputHeader_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  creditCardInputHeader_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  creditCardInputHeader_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  creditCardInputHeader_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  creditCardInputPlaceholder?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  creditCardInputPlaceholder_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  creditCardInputPlaceholder_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  creditCardInputPlaceholder_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  creditCardInputPlaceholder_not?: InputMaybe<Array<Scalars['String']>>;
  deletePaymentMethodIcon?: InputMaybe<AssetWhereInput>;
  editProfileIcon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  inputFieldName?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  inputFieldName_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  inputFieldName_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  inputFieldName_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  inputFieldName_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  language?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  language_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  language_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  language_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  language_not?: InputMaybe<Array<Scalars['String']>>;
  legalConfirmation?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  legalConfirmation_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  legalConfirmation_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  legalConfirmation_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  legalConfirmation_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  legalConfirmation_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  legalConfirmation_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  legalConfirmation_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  legalConfirmation_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  legalConfirmation_starts_with?: InputMaybe<Scalars['String']>;
  legalcheckbox_every?: InputMaybe<AssetWhereInput>;
  legalcheckbox_none?: InputMaybe<AssetWhereInput>;
  legalcheckbox_some?: InputMaybe<AssetWhereInput>;
  legals_every?: InputMaybe<LegalWhereInput>;
  legals_none?: InputMaybe<LegalWhereInput>;
  legals_some?: InputMaybe<LegalWhereInput>;
  paymentEncryptionText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  paymentEncryptionText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  paymentEncryptionText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  paymentEncryptionText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  paymentEncryptionText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  paymentEncryptionText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  paymentEncryptionText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  paymentEncryptionText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  paymentEncryptionText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  paymentEncryptionText_starts_with?: InputMaybe<Scalars['String']>;
  paymentMethodIcon_every?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_none?: InputMaybe<AssetWhereInput>;
  paymentMethodIcon_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  paymentMethodName?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  paymentMethodName_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  paymentMethodName_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  paymentMethodName_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  paymentMethodName_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  placeholderText?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  placeholderText_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  placeholderText_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  placeholderText_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  placeholderText_not?: InputMaybe<Array<Scalars['String']>>;
  profileIcons_every?: InputMaybe<AssetWhereInput>;
  profileIcons_none?: InputMaybe<AssetWhereInput>;
  profileIcons_some?: InputMaybe<AssetWhereInput>;
  profileSectionHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  profileSectionHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  profileSectionHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  profileSectionHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  profileSectionHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  profileSectionHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  profileSectionHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  profileSectionHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  profileSectionHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  profileSectionHeader_starts_with?: InputMaybe<Scalars['String']>;
  profileSectionIcon?: InputMaybe<AssetWhereInput>;
  profileSectionInternal?: InputMaybe<ProfileSection>;
  /** All values that are contained in given list. */
  profileSectionInternal_in?: InputMaybe<Array<ProfileSection>>;
  /** All values that are not equal to given value. */
  profileSectionInternal_not?: InputMaybe<ProfileSection>;
  /** All values that are not contained in given list. */
  profileSectionInternal_not_in?: InputMaybe<Array<ProfileSection>>;
  profileSubHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  profileSubHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  profileSubHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  profileSubHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  profileSubHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  profileSubHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  profileSubHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  profileSubHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  profileSubHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  profileSubHeader_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  saveButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  saveButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  saveButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  saveButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  saveButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  saveButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  saveButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  saveButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  saveButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  saveButton_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  verificationLinkIcon?: InputMaybe<AssetWhereInput>;
  verificationStatusIcon_every?: InputMaybe<AssetWhereInput>;
  verificationStatusIcon_none?: InputMaybe<AssetWhereInput>;
  verificationStatusIcon_some?: InputMaybe<AssetWhereInput>;
  verificationStatusName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  verificationStatusName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  verificationStatusName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  verificationStatusName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  verificationStatusName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  verificationStatusName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  verificationStatusName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  verificationStatusName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  verificationStatusName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  verificationStatusName_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Profile record uniquely */
export type ProfileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a single appOnboarding */
  appOnboarding?: Maybe<AppOnboarding>;
  /** Retrieve document version */
  appOnboardingVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple appOnboardings */
  appOnboardings: Array<AppOnboarding>;
  /** Retrieve multiple appOnboardings using the Relay connection interface */
  appOnboardingsConnection: AppOnboardingConnection;
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single banner */
  banner?: Maybe<Banner>;
  /** Retrieve document version */
  bannerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple banners */
  banners: Array<Banner>;
  /** Retrieve multiple banners using the Relay connection interface */
  bannersConnection: BannerConnection;
  /** Retrieve a single bottomNavigationItem */
  bottomNavigationItem?: Maybe<BottomNavigationItem>;
  /** Retrieve document version */
  bottomNavigationItemVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple bottomNavigationItems */
  bottomNavigationItems: Array<BottomNavigationItem>;
  /** Retrieve multiple bottomNavigationItems using the Relay connection interface */
  bottomNavigationItemsConnection: BottomNavigationItemConnection;
  /** Retrieve a single calendar */
  calendar?: Maybe<Calendar>;
  /** Retrieve document version */
  calendarVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple calendars */
  calendars: Array<Calendar>;
  /** Retrieve multiple calendars using the Relay connection interface */
  calendarsConnection: CalendarConnection;
  /** Retrieve a single carModel */
  carModel?: Maybe<CarModel>;
  /** Retrieve a single carModelFeature */
  carModelFeature?: Maybe<CarModelFeature>;
  /** Retrieve document version */
  carModelFeatureVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple carModelFeatures */
  carModelFeatures: Array<CarModelFeature>;
  /** Retrieve multiple carModelFeatures using the Relay connection interface */
  carModelFeaturesConnection: CarModelFeatureConnection;
  /** Retrieve document version */
  carModelVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple carModels */
  carModels: Array<CarModel>;
  /** Retrieve multiple carModels using the Relay connection interface */
  carModelsConnection: CarModelConnection;
  /** Retrieve a single class */
  class?: Maybe<Class>;
  /** Retrieve document version */
  classVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple classes */
  classes: Array<Class>;
  /** Retrieve multiple classes using the Relay connection interface */
  classesConnection: ClassConnection;
  /** Retrieve a single contact */
  contact?: Maybe<Contact>;
  /** Retrieve document version */
  contactVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple contacts */
  contacts: Array<Contact>;
  /** Retrieve multiple contacts using the Relay connection interface */
  contactsConnection: ContactConnection;
  /** Retrieve a single coverage */
  coverage?: Maybe<Coverage>;
  /** Retrieve document version */
  coverageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple coverages */
  coverages: Array<Coverage>;
  /** Retrieve multiple coverages using the Relay connection interface */
  coveragesConnection: CoverageConnection;
  /** Retrieve a single emergencyKeyOnboarding */
  emergencyKeyOnboarding?: Maybe<EmergencyKeyOnboarding>;
  /** Retrieve document version */
  emergencyKeyOnboardingVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple emergencyKeyOnboardings */
  emergencyKeyOnboardings: Array<EmergencyKeyOnboarding>;
  /** Retrieve multiple emergencyKeyOnboardings using the Relay connection interface */
  emergencyKeyOnboardingsConnection: EmergencyKeyOnboardingConnection;
  /** Retrieve a single errorMessage */
  errorMessage?: Maybe<ErrorMessage>;
  /** Retrieve document version */
  errorMessageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple errorMessages */
  errorMessages: Array<ErrorMessage>;
  /** Retrieve multiple errorMessages using the Relay connection interface */
  errorMessagesConnection: ErrorMessageConnection;
  /** Retrieve a single extra */
  extra?: Maybe<Extra>;
  /** Retrieve document version */
  extraVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple extras */
  extras: Array<Extra>;
  /** Retrieve multiple extras using the Relay connection interface */
  extrasConnection: ExtraConnection;
  /** Retrieve a single gOLocation */
  gOLocation?: Maybe<GoLocation>;
  /** Retrieve document version */
  gOLocationVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single gOSearch */
  gOSearch?: Maybe<GoSearch>;
  /** Retrieve document version */
  gOSearchVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple gOSearches */
  gOSearches: Array<GoSearch>;
  /** Retrieve multiple gOSearches using the Relay connection interface */
  gOSearchesConnection: GoSearchConnection;
  /** Retrieve a single goBookingJourney */
  goBookingJourney?: Maybe<GoBookingJourney>;
  /** Retrieve document version */
  goBookingJourneyVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple goBookingJourneys */
  goBookingJourneys: Array<GoBookingJourney>;
  /** Retrieve multiple goBookingJourneys using the Relay connection interface */
  goBookingJourneysConnection: GoBookingJourneyConnection;
  /** Retrieve a single goSelection */
  goSelection?: Maybe<GoSelection>;
  /** Retrieve document version */
  goSelectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple goSelections */
  goSelections: Array<GoSelection>;
  /** Retrieve multiple goSelections using the Relay connection interface */
  goSelectionsConnection: GoSelectionConnection;
  /** Retrieve a single goWebappBookingOverview */
  goWebappBookingOverview?: Maybe<GoWebappBookingOverview>;
  /** Retrieve document version */
  goWebappBookingOverviewVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple goWebappBookingOverviews */
  goWebappBookingOverviews: Array<GoWebappBookingOverview>;
  /** Retrieve multiple goWebappBookingOverviews using the Relay connection interface */
  goWebappBookingOverviewsConnection: GoWebappBookingOverviewConnection;
  /** Retrieve a single goWebappHomepage */
  goWebappHomepage?: Maybe<GoWebappHomepage>;
  /** Retrieve document version */
  goWebappHomepageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple goWebappHomepages */
  goWebappHomepages: Array<GoWebappHomepage>;
  /** Retrieve multiple goWebappHomepages using the Relay connection interface */
  goWebappHomepagesConnection: GoWebappHomepageConnection;
  /** Retrieve a single goWebappNavLink */
  goWebappNavLink?: Maybe<GoWebappNavLink>;
  /** Retrieve document version */
  goWebappNavLinkVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple goWebappNavLinks */
  goWebappNavLinks: Array<GoWebappNavLink>;
  /** Retrieve multiple goWebappNavLinks using the Relay connection interface */
  goWebappNavLinksConnection: GoWebappNavLinkConnection;
  /** Retrieve a single goWebappNavigationWizard */
  goWebappNavigationWizard?: Maybe<GoWebappNavigationWizard>;
  /** Retrieve document version */
  goWebappNavigationWizardVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple goWebappNavigationWizards */
  goWebappNavigationWizards: Array<GoWebappNavigationWizard>;
  /** Retrieve multiple goWebappNavigationWizards using the Relay connection interface */
  goWebappNavigationWizardsConnection: GoWebappNavigationWizardConnection;
  /** Retrieve a single hamburgerMenuItem */
  hamburgerMenuItem?: Maybe<HamburgerMenuItem>;
  /** Retrieve document version */
  hamburgerMenuItemVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple hamburgerMenuItems */
  hamburgerMenuItems: Array<HamburgerMenuItem>;
  /** Retrieve multiple hamburgerMenuItems using the Relay connection interface */
  hamburgerMenuItemsConnection: HamburgerMenuItemConnection;
  /** Retrieve a single homeScreen */
  homeScreen?: Maybe<HomeScreen>;
  /** Retrieve document version */
  homeScreenVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple homeScreens */
  homeScreens: Array<HomeScreen>;
  /** Retrieve multiple homeScreens using the Relay connection interface */
  homeScreensConnection: HomeScreenConnection;
  /** Retrieve a single key */
  key?: Maybe<Key>;
  /** Retrieve a single keyOnboarding */
  keyOnboarding?: Maybe<KeyOnboarding>;
  /** Retrieve document version */
  keyOnboardingVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple keyOnboardings */
  keyOnboardings: Array<KeyOnboarding>;
  /** Retrieve multiple keyOnboardings using the Relay connection interface */
  keyOnboardingsConnection: KeyOnboardingConnection;
  /** Retrieve document version */
  keyVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple keys */
  keys: Array<Key>;
  /** Retrieve multiple keys using the Relay connection interface */
  keysConnection: KeyConnection;
  /** Retrieve a single legal */
  legal?: Maybe<Legal>;
  /** Retrieve document version */
  legalVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple legals */
  legals: Array<Legal>;
  /** Retrieve multiple legals using the Relay connection interface */
  legalsConnection: LegalConnection;
  /** Retrieve multiple locations */
  locations: Array<GoLocation>;
  /** Retrieve multiple locations using the Relay connection interface */
  locationsConnection: GoLocationConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve a single popUpCard */
  popUpCard?: Maybe<PopUpCard>;
  /** Retrieve document version */
  popUpCardVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple popUpCards */
  popUpCards: Array<PopUpCard>;
  /** Retrieve multiple popUpCards using the Relay connection interface */
  popUpCardsConnection: PopUpCardConnection;
  /** Retrieve a single priceBreakdown */
  priceBreakdown?: Maybe<PriceBreakdown>;
  /** Retrieve document version */
  priceBreakdownVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple priceBreakdowns */
  priceBreakdowns: Array<PriceBreakdown>;
  /** Retrieve multiple priceBreakdowns using the Relay connection interface */
  priceBreakdownsConnection: PriceBreakdownConnection;
  /** Retrieve a single profile */
  profile?: Maybe<Profile>;
  /** Retrieve document version */
  profileVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple profiles */
  profiles: Array<Profile>;
  /** Retrieve multiple profiles using the Relay connection interface */
  profilesConnection: ProfileConnection;
  /** Retrieve a single rental */
  rental?: Maybe<Rental>;
  /** Retrieve document version */
  rentalVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple rentals */
  rentals: Array<Rental>;
  /** Retrieve multiple rentals using the Relay connection interface */
  rentalsConnection: RentalConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single subClass */
  subClass?: Maybe<SubClass>;
  /** Retrieve document version */
  subClassVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple subClasses */
  subClasses: Array<SubClass>;
  /** Retrieve multiple subClasses using the Relay connection interface */
  subClassesConnection: SubClassConnection;
  /** Retrieve a single successfulPaymentScreen */
  successfulPaymentScreen?: Maybe<SuccessfulPaymentScreen>;
  /** Retrieve document version */
  successfulPaymentScreenVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple successfulPaymentScreens */
  successfulPaymentScreens: Array<SuccessfulPaymentScreen>;
  /** Retrieve multiple successfulPaymentScreens using the Relay connection interface */
  successfulPaymentScreensConnection: SuccessfulPaymentScreenConnection;
  /** Retrieve a single user */
  user?: Maybe<User>;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
  /** Retrieve a single verification */
  verification?: Maybe<Verification>;
  /** Retrieve document version */
  verificationVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple verifications */
  verifications: Array<Verification>;
  /** Retrieve multiple verifications using the Relay connection interface */
  verificationsConnection: VerificationConnection;
  /** Retrieve a single vin */
  vin?: Maybe<Vin>;
  /** Retrieve document version */
  vinVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple vins */
  vins: Array<Vin>;
  /** Retrieve multiple vins using the Relay connection interface */
  vinsConnection: VinConnection;
};


export type QueryAppOnboardingArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AppOnboardingWhereUniqueInput;
};


export type QueryAppOnboardingVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAppOnboardingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AppOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AppOnboardingWhereInput>;
};


export type QueryAppOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AppOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AppOnboardingWhereInput>;
};


export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};


export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};


export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};


export type QueryBannerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BannerWhereUniqueInput;
};


export type QueryBannerVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBannersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<BannerWhereInput>;
};


export type QueryBannersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<BannerWhereInput>;
};


export type QueryBottomNavigationItemArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BottomNavigationItemWhereUniqueInput;
};


export type QueryBottomNavigationItemVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBottomNavigationItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BottomNavigationItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<BottomNavigationItemWhereInput>;
};


export type QueryBottomNavigationItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BottomNavigationItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<BottomNavigationItemWhereInput>;
};


export type QueryCalendarArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CalendarWhereUniqueInput;
};


export type QueryCalendarVersionArgs = {
  where: VersionWhereInput;
};


export type QueryCalendarsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CalendarOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CalendarWhereInput>;
};


export type QueryCalendarsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CalendarOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CalendarWhereInput>;
};


export type QueryCarModelArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CarModelWhereUniqueInput;
};


export type QueryCarModelFeatureArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CarModelFeatureWhereUniqueInput;
};


export type QueryCarModelFeatureVersionArgs = {
  where: VersionWhereInput;
};


export type QueryCarModelFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CarModelFeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CarModelFeatureWhereInput>;
};


export type QueryCarModelFeaturesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CarModelFeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CarModelFeatureWhereInput>;
};


export type QueryCarModelVersionArgs = {
  where: VersionWhereInput;
};


export type QueryCarModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CarModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CarModelWhereInput>;
};


export type QueryCarModelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CarModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CarModelWhereInput>;
};


export type QueryClassArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ClassWhereUniqueInput;
};


export type QueryClassVersionArgs = {
  where: VersionWhereInput;
};


export type QueryClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ClassWhereInput>;
};


export type QueryClassesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ClassWhereInput>;
};


export type QueryContactArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ContactWhereUniqueInput;
};


export type QueryContactVersionArgs = {
  where: VersionWhereInput;
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ContactOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryContactsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ContactOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryCoverageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CoverageWhereUniqueInput;
};


export type QueryCoverageVersionArgs = {
  where: VersionWhereInput;
};


export type QueryCoveragesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CoverageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CoverageWhereInput>;
};


export type QueryCoveragesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CoverageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CoverageWhereInput>;
};


export type QueryEmergencyKeyOnboardingArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: EmergencyKeyOnboardingWhereUniqueInput;
};


export type QueryEmergencyKeyOnboardingVersionArgs = {
  where: VersionWhereInput;
};


export type QueryEmergencyKeyOnboardingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmergencyKeyOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
};


export type QueryEmergencyKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmergencyKeyOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmergencyKeyOnboardingWhereInput>;
};


export type QueryErrorMessageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ErrorMessageWhereUniqueInput;
};


export type QueryErrorMessageVersionArgs = {
  where: VersionWhereInput;
};


export type QueryErrorMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ErrorMessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ErrorMessageWhereInput>;
};


export type QueryErrorMessagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ErrorMessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ErrorMessageWhereInput>;
};


export type QueryExtraArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ExtraWhereUniqueInput;
};


export type QueryExtraVersionArgs = {
  where: VersionWhereInput;
};


export type QueryExtrasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ExtraOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ExtraWhereInput>;
};


export type QueryExtrasConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ExtraOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ExtraWhereInput>;
};


export type QueryGoLocationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoLocationWhereUniqueInput;
};


export type QueryGoLocationVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoSearchArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoSearchWhereUniqueInput;
};


export type QueryGoSearchVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoSearchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoSearchWhereInput>;
};


export type QueryGoSearchesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoSearchOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoSearchWhereInput>;
};


export type QueryGoBookingJourneyArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoBookingJourneyWhereUniqueInput;
};


export type QueryGoBookingJourneyVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoBookingJourneysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoBookingJourneyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoBookingJourneyWhereInput>;
};


export type QueryGoBookingJourneysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoBookingJourneyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoBookingJourneyWhereInput>;
};


export type QueryGoSelectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoSelectionWhereUniqueInput;
};


export type QueryGoSelectionVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoSelectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoSelectionWhereInput>;
};


export type QueryGoSelectionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoSelectionWhereInput>;
};


export type QueryGoWebappBookingOverviewArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoWebappBookingOverviewWhereUniqueInput;
};


export type QueryGoWebappBookingOverviewVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoWebappBookingOverviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


export type QueryGoWebappBookingOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappBookingOverviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappBookingOverviewWhereInput>;
};


export type QueryGoWebappHomepageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoWebappHomepageWhereUniqueInput;
};


export type QueryGoWebappHomepageVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoWebappHomepagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


export type QueryGoWebappHomepagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappHomepageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappHomepageWhereInput>;
};


export type QueryGoWebappNavLinkArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoWebappNavLinkWhereUniqueInput;
};


export type QueryGoWebappNavLinkVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoWebappNavLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappNavLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappNavLinkWhereInput>;
};


export type QueryGoWebappNavLinksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappNavLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappNavLinkWhereInput>;
};


export type QueryGoWebappNavigationWizardArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GoWebappNavigationWizardWhereUniqueInput;
};


export type QueryGoWebappNavigationWizardVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGoWebappNavigationWizardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappNavigationWizardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappNavigationWizardWhereInput>;
};


export type QueryGoWebappNavigationWizardsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoWebappNavigationWizardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoWebappNavigationWizardWhereInput>;
};


export type QueryHamburgerMenuItemArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: HamburgerMenuItemWhereUniqueInput;
};


export type QueryHamburgerMenuItemVersionArgs = {
  where: VersionWhereInput;
};


export type QueryHamburgerMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HamburgerMenuItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<HamburgerMenuItemWhereInput>;
};


export type QueryHamburgerMenuItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HamburgerMenuItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<HamburgerMenuItemWhereInput>;
};


export type QueryHomeScreenArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: HomeScreenWhereUniqueInput;
};


export type QueryHomeScreenVersionArgs = {
  where: VersionWhereInput;
};


export type QueryHomeScreensArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HomeScreenOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<HomeScreenWhereInput>;
};


export type QueryHomeScreensConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HomeScreenOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<HomeScreenWhereInput>;
};


export type QueryKeyArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: KeyWhereUniqueInput;
};


export type QueryKeyOnboardingArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: KeyOnboardingWhereUniqueInput;
};


export type QueryKeyOnboardingVersionArgs = {
  where: VersionWhereInput;
};


export type QueryKeyOnboardingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<KeyOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<KeyOnboardingWhereInput>;
};


export type QueryKeyOnboardingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<KeyOnboardingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<KeyOnboardingWhereInput>;
};


export type QueryKeyVersionArgs = {
  where: VersionWhereInput;
};


export type QueryKeysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<KeyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<KeyWhereInput>;
};


export type QueryKeysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<KeyOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<KeyWhereInput>;
};


export type QueryLegalArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: LegalWhereUniqueInput;
};


export type QueryLegalVersionArgs = {
  where: VersionWhereInput;
};


export type QueryLegalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LegalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<LegalWhereInput>;
};


export type QueryLegalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LegalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<LegalWhereInput>;
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoLocationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoLocationWhereInput>;
};


export type QueryLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GoLocationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<GoLocationWhereInput>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
  locales?: Array<Locale>;
  stage?: Stage;
};


export type QueryPopUpCardArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PopUpCardWhereUniqueInput;
};


export type QueryPopUpCardVersionArgs = {
  where: VersionWhereInput;
};


export type QueryPopUpCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PopUpCardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<PopUpCardWhereInput>;
};


export type QueryPopUpCardsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PopUpCardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<PopUpCardWhereInput>;
};


export type QueryPriceBreakdownArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PriceBreakdownWhereUniqueInput;
};


export type QueryPriceBreakdownVersionArgs = {
  where: VersionWhereInput;
};


export type QueryPriceBreakdownsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PriceBreakdownOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<PriceBreakdownWhereInput>;
};


export type QueryPriceBreakdownsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PriceBreakdownOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<PriceBreakdownWhereInput>;
};


export type QueryProfileArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProfileWhereUniqueInput;
};


export type QueryProfileVersionArgs = {
  where: VersionWhereInput;
};


export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryProfilesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProfileOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryRentalArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: RentalWhereUniqueInput;
};


export type QueryRentalVersionArgs = {
  where: VersionWhereInput;
};


export type QueryRentalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RentalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<RentalWhereInput>;
};


export type QueryRentalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RentalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<RentalWhereInput>;
};


export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};


export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};


export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};


export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};


export type QuerySubClassArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SubClassWhereUniqueInput;
};


export type QuerySubClassVersionArgs = {
  where: VersionWhereInput;
};


export type QuerySubClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SubClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SubClassWhereInput>;
};


export type QuerySubClassesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SubClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SubClassWhereInput>;
};


export type QuerySuccessfulPaymentScreenArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SuccessfulPaymentScreenWhereUniqueInput;
};


export type QuerySuccessfulPaymentScreenVersionArgs = {
  where: VersionWhereInput;
};


export type QuerySuccessfulPaymentScreensArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SuccessfulPaymentScreenOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
};


export type QuerySuccessfulPaymentScreensConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SuccessfulPaymentScreenOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SuccessfulPaymentScreenWhereInput>;
};


export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVerificationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VerificationWhereUniqueInput;
};


export type QueryVerificationVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVerificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VerificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<VerificationWhereInput>;
};


export type QueryVerificationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VerificationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<VerificationWhereInput>;
};


export type QueryVinArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VinWhereUniqueInput;
};


export type QueryVinVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVinsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VinOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<VinWhereInput>;
};


export type QueryVinsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VinOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<VinWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA';
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

/**
 * This is the schema where all the rental screens are included.
 *
 */
export type Rental = Node & {
  __typename?: 'Rental';
  /** This is the text that comes to the left of the countdown numerical value, describing the amount of time until the cancellation policy ends.   */
  cancellationPolicyCountdownText?: Maybe<Scalars['String']>;
  /** These are the hourglass icons that show whether the free cancellation is still available */
  cancellationPolicyIcons: Array<Asset>;
  /** This is the title of the cancellation policy section eg. cancellation policy */
  cancellationPolicySectionTitle?: Maybe<Scalars['String']>;
  /** This this is explanation of how long the cancellation policy is valid for eg. 24 hours before the rental begin.  */
  cancellationPolicySubtext?: Maybe<Scalars['String']>;
  /** This is the icon that is contained within the contact button */
  contactButtonIcon?: Maybe<Asset>;
  /** This is the text of the contact button on the detailed rental page. eg. contact us. */
  contactButtonText?: Maybe<Scalars['String']>;
  /** This is the text that is displayed when the rental turns overdue and must be returned */
  countdownEnded?: Maybe<Scalars['String']>;
  /**
   * This is the countdown thatshows how long a rental has until it begins or ends, depending on the rental stage.
   *
   * EG. Current rental = Return in
   *        Upcoming rental = Starts in
   */
  countdownTimer?: Maybe<Scalars['String']>;
  /**
   * This is the 'days', 'hours', 'minutes' that follows the amount of time until either the return or pickup of a particular rental, depending on the rental stage
   *
   * EG. Return in 3 'days'
   */
  countdownTimes: Array<Scalars['String']>;
  /**
   * This is the title of the coverage section of the rental view. This is displayed in the rental detailed view.
   *
   * EG. Coverage Level
   */
  coverageSectionTitle?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** This is the page header for the detailed rental view eg. current rental, upcoming rental, past rental.  */
  detailedViewPageHeader?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<Rental>;
  /**
   * This si the title of the extras section of the rental view. This is displayed in the rental detailed view.
   *
   * EG. Additional Extras
   */
  extrasSectionTitle?: Maybe<Scalars['String']>;
  /** List of Rental versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Rental>;
  /** This is the image/illustration that is to be displayed when no rental is currently on going */
  noCurrentRentalImage?: Maybe<Asset>;
  /**
   * ONLY FOR NO CURRENT RENTAL
   *
   * This is the subheader to be displayed when there there is no current rental open.
   */
  noCurrentRentalSubheader?: Maybe<Scalars['String']>;
  /** This is the paragraph text that explains that there is no current rental and the user must book a rental to have a rental currently open */
  noCurrentRentalText?: Maybe<Scalars['String']>;
  /** This is the header that is above the paragraph text to be displayed when there is no rental currently open */
  noCurrentRentalTextHeader?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /**
   * This is the text that is contained within the Call to Action (main) button eg. Cancel Rental.
   *
   * This is the primary button, pages may have a secondary button (in this case the distinction will be the styling, secondary buttons are styled using border and no fill)
   */
  rentalCTAButtonText?: Maybe<Scalars['String']>;
  /** This is the header at the top of the detailed rental page that displays at which stage the rental is in eg. current rental, upcoming rental, past rental */
  rentalDetailedViewPageHeader?: Maybe<Scalars['String']>;
  /** This is the Rental ID & PIN title. */
  rentalIDandPin: Array<Scalars['String']>;
  /** This is the text that fills the tabs separating the rental overview sections, the selected tab is highlighted in green gradient, while the deselected tabs are inactive. EG. CURRENT, UPCOMING  and PAST in English */
  rentalOverviewTabText: Array<Scalars['String']>;
  /** This is the title field that shows which stage and view this instance matches to EG (Upcoming Rental Overview) refers to the overview page of all upcoming rentals, while (Current Rental Detailed View) refers to the individual page for a GO user's open rental. */
  rentalStageAndView?: Maybe<Scalars['String']>;
  /**
   * This is the header at the top of the rentals section Overview pages eg. Rentals, Miete, Location.
   *
   * This header only applies to overview pages, detailed view pages use the Rentals detailed view header
   */
  rentalsOverviewPageHeader?: Maybe<Scalars['String']>;
  scheduledIn: Array<ScheduledOperation>;
  /**
   * This is the secondary call to action button in the overview and the detailed rental view.
   *
   * EG . in current rental this is the 'view all details button'
   */
  secondaryCtaButton?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalCancellationPolicyIconsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalContactButtonIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalNoCurrentRentalImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/**
 * This is the schema where all the rental screens are included.
 *
 */
export type RentalUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type RentalConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RentalWhereUniqueInput;
};

/** A connection to a list of items. */
export type RentalConnection = {
  __typename?: 'RentalConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RentalEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RentalCreateInput = {
  /** cancellationPolicyCountdownText input for default locale (de_CH) */
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  cancellationPolicyIcons?: InputMaybe<AssetCreateManyInlineInput>;
  /** cancellationPolicySectionTitle input for default locale (de_CH) */
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  /** cancellationPolicySubtext input for default locale (de_CH) */
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  contactButtonIcon?: InputMaybe<AssetCreateOneInlineInput>;
  /** contactButtonText input for default locale (de_CH) */
  contactButtonText?: InputMaybe<Scalars['String']>;
  /** countdownEnded input for default locale (de_CH) */
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** countdownTimer input for default locale (de_CH) */
  countdownTimer?: InputMaybe<Scalars['String']>;
  /** countdownTimes input for default locale (de_CH) */
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  /** coverageSectionTitle input for default locale (de_CH) */
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** detailedViewPageHeader input for default locale (de_CH) */
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** extrasSectionTitle input for default locale (de_CH) */
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<RentalCreateLocalizationsInput>;
  noCurrentRentalImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** noCurrentRentalSubheader input for default locale (de_CH) */
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  /** noCurrentRentalText input for default locale (de_CH) */
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  /** noCurrentRentalTextHeader input for default locale (de_CH) */
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  /** rentalCTAButtonText input for default locale (de_CH) */
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  /** rentalDetailedViewPageHeader input for default locale (de_CH) */
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** rentalIDandPin input for default locale (de_CH) */
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  /** rentalOverviewTabText input for default locale (de_CH) */
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  rentalStageAndView?: InputMaybe<Scalars['String']>;
  /** rentalsOverviewPageHeader input for default locale (de_CH) */
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  /** secondaryCtaButton input for default locale (de_CH) */
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RentalCreateLocalizationDataInput = {
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  contactButtonText?: InputMaybe<Scalars['String']>;
  countdownEnded?: InputMaybe<Scalars['String']>;
  countdownTimer?: InputMaybe<Scalars['String']>;
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RentalCreateLocalizationInput = {
  /** Localization input */
  data: RentalCreateLocalizationDataInput;
  locale: Locale;
};

export type RentalCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<RentalCreateLocalizationInput>>;
};

export type RentalCreateManyInlineInput = {
  /** Connect multiple existing Rental documents */
  connect?: InputMaybe<Array<RentalWhereUniqueInput>>;
  /** Create and connect multiple existing Rental documents */
  create?: InputMaybe<Array<RentalCreateInput>>;
};

export type RentalCreateOneInlineInput = {
  /** Connect one existing Rental document */
  connect?: InputMaybe<RentalWhereUniqueInput>;
  /** Create and connect one Rental document */
  create?: InputMaybe<RentalCreateInput>;
};

/** An edge in a connection. */
export type RentalEdge = {
  __typename?: 'RentalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Rental;
};

/** Identifies documents */
export type RentalManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RentalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RentalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RentalWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cancellationPolicyIcons_every?: InputMaybe<AssetWhereInput>;
  cancellationPolicyIcons_none?: InputMaybe<AssetWhereInput>;
  cancellationPolicyIcons_some?: InputMaybe<AssetWhereInput>;
  contactButtonIcon?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  noCurrentRentalImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentalStageAndView?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  rentalStageAndView_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  rentalStageAndView_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  rentalStageAndView_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  rentalStageAndView_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  rentalStageAndView_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  rentalStageAndView_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  rentalStageAndView_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  rentalStageAndView_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  rentalStageAndView_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum RentalOrderByInput {
  CancellationPolicyCountdownTextAsc = 'cancellationPolicyCountdownText_ASC',
  CancellationPolicyCountdownTextDesc = 'cancellationPolicyCountdownText_DESC',
  CancellationPolicySectionTitleAsc = 'cancellationPolicySectionTitle_ASC',
  CancellationPolicySectionTitleDesc = 'cancellationPolicySectionTitle_DESC',
  CancellationPolicySubtextAsc = 'cancellationPolicySubtext_ASC',
  CancellationPolicySubtextDesc = 'cancellationPolicySubtext_DESC',
  ContactButtonTextAsc = 'contactButtonText_ASC',
  ContactButtonTextDesc = 'contactButtonText_DESC',
  CountdownEndedAsc = 'countdownEnded_ASC',
  CountdownEndedDesc = 'countdownEnded_DESC',
  CountdownTimerAsc = 'countdownTimer_ASC',
  CountdownTimerDesc = 'countdownTimer_DESC',
  CountdownTimesAsc = 'countdownTimes_ASC',
  CountdownTimesDesc = 'countdownTimes_DESC',
  CoverageSectionTitleAsc = 'coverageSectionTitle_ASC',
  CoverageSectionTitleDesc = 'coverageSectionTitle_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DetailedViewPageHeaderAsc = 'detailedViewPageHeader_ASC',
  DetailedViewPageHeaderDesc = 'detailedViewPageHeader_DESC',
  ExtrasSectionTitleAsc = 'extrasSectionTitle_ASC',
  ExtrasSectionTitleDesc = 'extrasSectionTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NoCurrentRentalSubheaderAsc = 'noCurrentRentalSubheader_ASC',
  NoCurrentRentalSubheaderDesc = 'noCurrentRentalSubheader_DESC',
  NoCurrentRentalTextHeaderAsc = 'noCurrentRentalTextHeader_ASC',
  NoCurrentRentalTextHeaderDesc = 'noCurrentRentalTextHeader_DESC',
  NoCurrentRentalTextAsc = 'noCurrentRentalText_ASC',
  NoCurrentRentalTextDesc = 'noCurrentRentalText_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RentalCtaButtonTextAsc = 'rentalCTAButtonText_ASC',
  RentalCtaButtonTextDesc = 'rentalCTAButtonText_DESC',
  RentalDetailedViewPageHeaderAsc = 'rentalDetailedViewPageHeader_ASC',
  RentalDetailedViewPageHeaderDesc = 'rentalDetailedViewPageHeader_DESC',
  RentalIDandPinAsc = 'rentalIDandPin_ASC',
  RentalIDandPinDesc = 'rentalIDandPin_DESC',
  RentalOverviewTabTextAsc = 'rentalOverviewTabText_ASC',
  RentalOverviewTabTextDesc = 'rentalOverviewTabText_DESC',
  RentalStageAndViewAsc = 'rentalStageAndView_ASC',
  RentalStageAndViewDesc = 'rentalStageAndView_DESC',
  RentalsOverviewPageHeaderAsc = 'rentalsOverviewPageHeader_ASC',
  RentalsOverviewPageHeaderDesc = 'rentalsOverviewPageHeader_DESC',
  SecondaryCtaButtonAsc = 'secondaryCtaButton_ASC',
  SecondaryCtaButtonDesc = 'secondaryCtaButton_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RentalUpdateInput = {
  /** cancellationPolicyCountdownText input for default locale (de_CH) */
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  cancellationPolicyIcons?: InputMaybe<AssetUpdateManyInlineInput>;
  /** cancellationPolicySectionTitle input for default locale (de_CH) */
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  /** cancellationPolicySubtext input for default locale (de_CH) */
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  contactButtonIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** contactButtonText input for default locale (de_CH) */
  contactButtonText?: InputMaybe<Scalars['String']>;
  /** countdownEnded input for default locale (de_CH) */
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** countdownTimer input for default locale (de_CH) */
  countdownTimer?: InputMaybe<Scalars['String']>;
  /** countdownTimes input for default locale (de_CH) */
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  /** coverageSectionTitle input for default locale (de_CH) */
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  /** detailedViewPageHeader input for default locale (de_CH) */
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** extrasSectionTitle input for default locale (de_CH) */
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<RentalUpdateLocalizationsInput>;
  noCurrentRentalImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** noCurrentRentalSubheader input for default locale (de_CH) */
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  /** noCurrentRentalText input for default locale (de_CH) */
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  /** noCurrentRentalTextHeader input for default locale (de_CH) */
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  /** rentalCTAButtonText input for default locale (de_CH) */
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  /** rentalDetailedViewPageHeader input for default locale (de_CH) */
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** rentalIDandPin input for default locale (de_CH) */
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  /** rentalOverviewTabText input for default locale (de_CH) */
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  rentalStageAndView?: InputMaybe<Scalars['String']>;
  /** rentalsOverviewPageHeader input for default locale (de_CH) */
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  /** secondaryCtaButton input for default locale (de_CH) */
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
};

export type RentalUpdateLocalizationDataInput = {
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  contactButtonText?: InputMaybe<Scalars['String']>;
  countdownEnded?: InputMaybe<Scalars['String']>;
  countdownTimer?: InputMaybe<Scalars['String']>;
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
};

export type RentalUpdateLocalizationInput = {
  data: RentalUpdateLocalizationDataInput;
  locale: Locale;
};

export type RentalUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<RentalCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<RentalUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<RentalUpsertLocalizationInput>>;
};

export type RentalUpdateManyInlineInput = {
  /** Connect multiple existing Rental documents */
  connect?: InputMaybe<Array<RentalConnectInput>>;
  /** Create and connect multiple Rental documents */
  create?: InputMaybe<Array<RentalCreateInput>>;
  /** Delete multiple Rental documents */
  delete?: InputMaybe<Array<RentalWhereUniqueInput>>;
  /** Disconnect multiple Rental documents */
  disconnect?: InputMaybe<Array<RentalWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Rental documents */
  set?: InputMaybe<Array<RentalWhereUniqueInput>>;
  /** Update multiple Rental documents */
  update?: InputMaybe<Array<RentalUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Rental documents */
  upsert?: InputMaybe<Array<RentalUpsertWithNestedWhereUniqueInput>>;
};

export type RentalUpdateManyInput = {
  /** cancellationPolicyCountdownText input for default locale (de_CH) */
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  /** cancellationPolicySectionTitle input for default locale (de_CH) */
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  /** cancellationPolicySubtext input for default locale (de_CH) */
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  /** contactButtonText input for default locale (de_CH) */
  contactButtonText?: InputMaybe<Scalars['String']>;
  /** countdownEnded input for default locale (de_CH) */
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** countdownTimer input for default locale (de_CH) */
  countdownTimer?: InputMaybe<Scalars['String']>;
  /** countdownTimes input for default locale (de_CH) */
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  /** coverageSectionTitle input for default locale (de_CH) */
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  /** detailedViewPageHeader input for default locale (de_CH) */
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** extrasSectionTitle input for default locale (de_CH) */
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<RentalUpdateManyLocalizationsInput>;
  /** noCurrentRentalSubheader input for default locale (de_CH) */
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  /** noCurrentRentalText input for default locale (de_CH) */
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  /** noCurrentRentalTextHeader input for default locale (de_CH) */
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  /** rentalCTAButtonText input for default locale (de_CH) */
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  /** rentalDetailedViewPageHeader input for default locale (de_CH) */
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** rentalIDandPin input for default locale (de_CH) */
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  /** rentalOverviewTabText input for default locale (de_CH) */
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  /** rentalsOverviewPageHeader input for default locale (de_CH) */
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  /** secondaryCtaButton input for default locale (de_CH) */
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
};

export type RentalUpdateManyLocalizationDataInput = {
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  contactButtonText?: InputMaybe<Scalars['String']>;
  countdownEnded?: InputMaybe<Scalars['String']>;
  countdownTimer?: InputMaybe<Scalars['String']>;
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
};

export type RentalUpdateManyLocalizationInput = {
  data: RentalUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type RentalUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<RentalUpdateManyLocalizationInput>>;
};

export type RentalUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RentalUpdateManyInput;
  /** Document search */
  where: RentalWhereInput;
};

export type RentalUpdateOneInlineInput = {
  /** Connect existing Rental document */
  connect?: InputMaybe<RentalWhereUniqueInput>;
  /** Create and connect one Rental document */
  create?: InputMaybe<RentalCreateInput>;
  /** Delete currently connected Rental document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Rental document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Rental document */
  update?: InputMaybe<RentalUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Rental document */
  upsert?: InputMaybe<RentalUpsertWithNestedWhereUniqueInput>;
};

export type RentalUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RentalUpdateInput;
  /** Unique document search */
  where: RentalWhereUniqueInput;
};

export type RentalUpsertInput = {
  /** Create document if it didn't exist */
  create: RentalCreateInput;
  /** Update document if it exists */
  update: RentalUpdateInput;
};

export type RentalUpsertLocalizationInput = {
  create: RentalCreateLocalizationDataInput;
  locale: Locale;
  update: RentalUpdateLocalizationDataInput;
};

export type RentalUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RentalUpsertInput;
  /** Unique document search */
  where: RentalWhereUniqueInput;
};

/** Identifies documents */
export type RentalWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RentalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RentalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RentalWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cancellationPolicyCountdownText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  cancellationPolicyCountdownText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  cancellationPolicyCountdownText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  cancellationPolicyCountdownText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  cancellationPolicyCountdownText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  cancellationPolicyCountdownText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  cancellationPolicyCountdownText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  cancellationPolicyCountdownText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  cancellationPolicyCountdownText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  cancellationPolicyCountdownText_starts_with?: InputMaybe<Scalars['String']>;
  cancellationPolicyIcons_every?: InputMaybe<AssetWhereInput>;
  cancellationPolicyIcons_none?: InputMaybe<AssetWhereInput>;
  cancellationPolicyIcons_some?: InputMaybe<AssetWhereInput>;
  cancellationPolicySectionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  cancellationPolicySectionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  cancellationPolicySectionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  cancellationPolicySectionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  cancellationPolicySectionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  cancellationPolicySectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  cancellationPolicySectionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  cancellationPolicySectionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  cancellationPolicySectionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  cancellationPolicySectionTitle_starts_with?: InputMaybe<Scalars['String']>;
  cancellationPolicySubtext?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  cancellationPolicySubtext_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  cancellationPolicySubtext_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  cancellationPolicySubtext_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  cancellationPolicySubtext_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  cancellationPolicySubtext_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  cancellationPolicySubtext_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  cancellationPolicySubtext_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  cancellationPolicySubtext_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  cancellationPolicySubtext_starts_with?: InputMaybe<Scalars['String']>;
  contactButtonIcon?: InputMaybe<AssetWhereInput>;
  contactButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  contactButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  contactButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  contactButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  contactButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  contactButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  contactButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  contactButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  contactButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  contactButtonText_starts_with?: InputMaybe<Scalars['String']>;
  countdownEnded?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  countdownEnded_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  countdownEnded_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  countdownEnded_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  countdownEnded_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  countdownEnded_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  countdownEnded_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  countdownEnded_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  countdownEnded_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  countdownEnded_starts_with?: InputMaybe<Scalars['String']>;
  countdownTimer?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  countdownTimer_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  countdownTimer_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  countdownTimer_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  countdownTimer_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  countdownTimer_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  countdownTimer_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  countdownTimer_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  countdownTimer_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  countdownTimer_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countdownTimes?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countdownTimes_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countdownTimes_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countdownTimes_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countdownTimes_not?: InputMaybe<Array<Scalars['String']>>;
  coverageSectionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  coverageSectionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  coverageSectionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  coverageSectionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  coverageSectionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  coverageSectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  coverageSectionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  coverageSectionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  coverageSectionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  coverageSectionTitle_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  detailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  detailedViewPageHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  detailedViewPageHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  detailedViewPageHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  detailedViewPageHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  detailedViewPageHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  detailedViewPageHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  detailedViewPageHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  detailedViewPageHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  detailedViewPageHeader_starts_with?: InputMaybe<Scalars['String']>;
  extrasSectionTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  extrasSectionTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  extrasSectionTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  extrasSectionTitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  extrasSectionTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  extrasSectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  extrasSectionTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  extrasSectionTitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  extrasSectionTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  extrasSectionTitle_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  noCurrentRentalImage?: InputMaybe<AssetWhereInput>;
  noCurrentRentalSubheader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCurrentRentalSubheader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCurrentRentalSubheader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCurrentRentalSubheader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCurrentRentalSubheader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCurrentRentalSubheader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCurrentRentalSubheader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCurrentRentalSubheader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCurrentRentalSubheader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCurrentRentalSubheader_starts_with?: InputMaybe<Scalars['String']>;
  noCurrentRentalText?: InputMaybe<Scalars['String']>;
  noCurrentRentalTextHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCurrentRentalTextHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCurrentRentalTextHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCurrentRentalTextHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCurrentRentalTextHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCurrentRentalTextHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCurrentRentalTextHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCurrentRentalTextHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCurrentRentalTextHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCurrentRentalTextHeader_starts_with?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  noCurrentRentalText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  noCurrentRentalText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  noCurrentRentalText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  noCurrentRentalText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  noCurrentRentalText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  noCurrentRentalText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  noCurrentRentalText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  noCurrentRentalText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  noCurrentRentalText_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentalCTAButtonText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  rentalCTAButtonText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  rentalCTAButtonText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  rentalCTAButtonText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  rentalCTAButtonText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  rentalCTAButtonText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  rentalCTAButtonText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  rentalCTAButtonText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  rentalCTAButtonText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  rentalCTAButtonText_starts_with?: InputMaybe<Scalars['String']>;
  rentalDetailedViewPageHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  rentalDetailedViewPageHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  rentalDetailedViewPageHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  rentalDetailedViewPageHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  rentalDetailedViewPageHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  rentalDetailedViewPageHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  rentalDetailedViewPageHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  rentalDetailedViewPageHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  rentalDetailedViewPageHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  rentalDetailedViewPageHeader_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  rentalIDandPin?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  rentalIDandPin_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  rentalIDandPin_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  rentalIDandPin_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  rentalIDandPin_not?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  rentalOverviewTabText?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  rentalOverviewTabText_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  rentalOverviewTabText_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  rentalOverviewTabText_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  rentalOverviewTabText_not?: InputMaybe<Array<Scalars['String']>>;
  rentalStageAndView?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  rentalStageAndView_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  rentalStageAndView_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  rentalStageAndView_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  rentalStageAndView_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  rentalStageAndView_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  rentalStageAndView_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  rentalStageAndView_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  rentalStageAndView_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  rentalStageAndView_starts_with?: InputMaybe<Scalars['String']>;
  rentalsOverviewPageHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  rentalsOverviewPageHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  rentalsOverviewPageHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  rentalsOverviewPageHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  rentalsOverviewPageHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  rentalsOverviewPageHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  rentalsOverviewPageHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  rentalsOverviewPageHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  rentalsOverviewPageHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  rentalsOverviewPageHeader_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  secondaryCtaButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  secondaryCtaButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  secondaryCtaButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  secondaryCtaButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  secondaryCtaButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  secondaryCtaButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  secondaryCtaButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  secondaryCtaButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  secondaryCtaButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  secondaryCtaButton_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References Rental record uniquely */
export type RentalWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  rentalStageAndView?: InputMaybe<Scalars['String']>;
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns AST representation */
  raw: Scalars['RichTextAST'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

/** Scheduled Operation system model */
export type ScheduledOperation = Node & {
  __typename?: 'ScheduledOperation';
  affectedDocuments: Array<ScheduledOperationAffectedDocument>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Operation description */
  description?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledOperation>;
  /** Operation error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars['Json'];
  /** The release this operation is scheduled for */
  release?: Maybe<ScheduledRelease>;
  /** System stage field */
  stage: Stage;
  /** operation Status */
  status: ScheduledOperationStatus;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument = AppOnboarding | Asset | Banner | BottomNavigationItem | Calendar | CarModel | CarModelFeature | Class | Contact | Coverage | EmergencyKeyOnboarding | ErrorMessage | Extra | GoLocation | GoSearch | GoBookingJourney | GoSelection | GoWebappBookingOverview | GoWebappHomepage | GoWebappNavLink | GoWebappNavigationWizard | HamburgerMenuItem | HomeScreen | Key | KeyOnboarding | Legal | PopUpCard | PriceBreakdown | Profile | Rental | SubClass | SuccessfulPaymentScreen | Verification | Vin;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<ScheduledOperationStatus>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<ScheduledOperationStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<ScheduledOperationStatus>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<ScheduledOperationStatus>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Node & {
  __typename?: 'ScheduledRelease';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Release description */
  description?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledRelease>;
  /** Release error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Whether scheduled release should be run */
  isActive: Scalars['Boolean'];
  /** Whether scheduled release is implicit */
  isImplicit: Scalars['Boolean'];
  /** Operations to run with this release */
  operations: Array<ScheduledOperation>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Release date and time */
  releaseAt?: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  /** Release Status */
  status: ScheduledReleaseStatus;
  /** Release Title */
  title?: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isImplicit?: InputMaybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<ScheduledReleaseStatus>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<ScheduledReleaseStatus>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isImplicit?: InputMaybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<ScheduledReleaseStatus>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<ScheduledReleaseStatus>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED'
}

/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClass = Node & {
  __typename?: 'SubClass';
  /** This reference to Car Model Features highlights the most important Car Model Features in this Sub Class */
  carModelFeatures: Array<CarModelFeature>;
  /** Each Sub Class contains multiple Car Models without any overlap to other Sub Classes. */
  carModels: Array<CarModel>;
  /** This reference allows one class referenced to multiple sub classes, meaning a query can be made to a specific class to get the sub classes. */
  class?: Maybe<Class>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<SubClass>;
  /** List of SubClass versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<SubClass>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** This reference model acts as a placeholder model for all the car models that comprise this sub class. It always requires an ending "or similar" */
  referenceCarModelName?: Maybe<Scalars['String']>;
  /** Mapping to RENT Subclass. */
  rentSubclassId?: Maybe<Scalars['Int']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The Sub Class Description is the short text used to describe a subclass across our products (same as Minilease, Enterprise Go web app and Enterprise GO Mobile). */
  subClassDescription: RichText;
  /**
   * These are the fallback features that are displayed underneath the subclass image. These are generic features that can cover an entire sub class.
   *
   * EG. [Door icon] 4
   *        [AC icon] A/C
   *        [Group Icon] 1-5
   */
  subClassFallbackFeatures: Array<Scalars['String']>;
  /**
   * These are the Sub Class feature icons. Each icon comes with a corresponding description. These icons must be ordered in the EXACT same order as their corresponding feature description.
   *
   * EG. [icon] urban icon (#1) [Description] great for city (#1)
   */
  subClassFeatureIcon: Array<Asset>;
  /**
   * These subclass features are the 3 distinct features that make up a class.
   *
   */
  subClassFeatures: Array<SubClassSubClassFeaturesRichText>;
  /** There is only one sub class image that represents the sub class */
  subClassImage: Asset;
  /** This is the name of the subclass */
  subClassName: Scalars['String'];
  /**
   * These are the fallback features that are displayed underneath the subclass image. These are generic features that can cover an entire sub class.
   *
   * EG. [Door icon] 4
   *        [AC icon] A/C
   *        [Group Icon] 1-5
   */
  subclassFallbackFeaturesIcon: Array<Asset>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassCarModelFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelFeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelFeatureWhereInput>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassCarModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelWhereInput>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassSubClassFeatureIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassSubClassImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassSubclassFallbackFeaturesIconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Sub Classes represent the grouping of multiple car models into one sub class. */
export type SubClassUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type SubClassConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SubClassWhereUniqueInput;
};

/** A connection to a list of items. */
export type SubClassConnection = {
  __typename?: 'SubClassConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SubClassEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SubClassCreateInput = {
  carModelFeatures?: InputMaybe<CarModelFeatureCreateManyInlineInput>;
  carModels?: InputMaybe<CarModelCreateManyInlineInput>;
  class?: InputMaybe<ClassCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SubClassCreateLocalizationsInput>;
  /** referenceCarModelName input for default locale (de_CH) */
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  rentSubclassId?: InputMaybe<Scalars['Int']>;
  /** subClassDescription input for default locale (de_CH) */
  subClassDescription: Scalars['RichTextAST'];
  /** subClassFallbackFeatures input for default locale (de_CH) */
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  subClassFeatureIcon?: InputMaybe<AssetCreateManyInlineInput>;
  /** subClassFeatures input for default locale (de_CH) */
  subClassFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  subClassImage: AssetCreateOneInlineInput;
  /** subClassName input for default locale (de_CH) */
  subClassName: Scalars['String'];
  subclassFallbackFeaturesIcon?: InputMaybe<AssetCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubClassCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  subClassDescription: Scalars['RichTextAST'];
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  subClassFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  subClassName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubClassCreateLocalizationInput = {
  /** Localization input */
  data: SubClassCreateLocalizationDataInput;
  locale: Locale;
};

export type SubClassCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SubClassCreateLocalizationInput>>;
};

export type SubClassCreateManyInlineInput = {
  /** Connect multiple existing SubClass documents */
  connect?: InputMaybe<Array<SubClassWhereUniqueInput>>;
  /** Create and connect multiple existing SubClass documents */
  create?: InputMaybe<Array<SubClassCreateInput>>;
};

export type SubClassCreateOneInlineInput = {
  /** Connect one existing SubClass document */
  connect?: InputMaybe<SubClassWhereUniqueInput>;
  /** Create and connect one SubClass document */
  create?: InputMaybe<SubClassCreateInput>;
};

/** An edge in a connection. */
export type SubClassEdge = {
  __typename?: 'SubClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SubClass;
};

/** Identifies documents */
export type SubClassManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SubClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SubClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SubClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModelFeatures_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_some?: InputMaybe<CarModelFeatureWhereInput>;
  carModels_every?: InputMaybe<CarModelWhereInput>;
  carModels_none?: InputMaybe<CarModelWhereInput>;
  carModels_some?: InputMaybe<CarModelWhereInput>;
  class?: InputMaybe<ClassWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rentSubclassId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentSubclassId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentSubclassId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentSubclassId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentSubclassId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentSubclassId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentSubclassId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentSubclassId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subClassFeatureIcon_every?: InputMaybe<AssetWhereInput>;
  subClassFeatureIcon_none?: InputMaybe<AssetWhereInput>;
  subClassFeatureIcon_some?: InputMaybe<AssetWhereInput>;
  subClassImage?: InputMaybe<AssetWhereInput>;
  subclassFallbackFeaturesIcon_every?: InputMaybe<AssetWhereInput>;
  subclassFallbackFeaturesIcon_none?: InputMaybe<AssetWhereInput>;
  subclassFallbackFeaturesIcon_some?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SubClassOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReferenceCarModelNameAsc = 'referenceCarModelName_ASC',
  ReferenceCarModelNameDesc = 'referenceCarModelName_DESC',
  RentSubclassIdAsc = 'rentSubclassId_ASC',
  RentSubclassIdDesc = 'rentSubclassId_DESC',
  SubClassFallbackFeaturesAsc = 'subClassFallbackFeatures_ASC',
  SubClassFallbackFeaturesDesc = 'subClassFallbackFeatures_DESC',
  SubClassNameAsc = 'subClassName_ASC',
  SubClassNameDesc = 'subClassName_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SubClassSubClassFeaturesRichText = {
  __typename?: 'SubClassSubClassFeaturesRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<SubClassSubClassFeaturesRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


export type SubClassSubClassFeaturesRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SubClassSubClassFeaturesRichTextEmbeddedTypes = Asset;

export type SubClassUpdateInput = {
  carModelFeatures?: InputMaybe<CarModelFeatureUpdateManyInlineInput>;
  carModels?: InputMaybe<CarModelUpdateManyInlineInput>;
  class?: InputMaybe<ClassUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<SubClassUpdateLocalizationsInput>;
  /** referenceCarModelName input for default locale (de_CH) */
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  rentSubclassId?: InputMaybe<Scalars['Int']>;
  /** subClassDescription input for default locale (de_CH) */
  subClassDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** subClassFallbackFeatures input for default locale (de_CH) */
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  subClassFeatureIcon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** subClassFeatures input for default locale (de_CH) */
  subClassFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  subClassImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** subClassName input for default locale (de_CH) */
  subClassName?: InputMaybe<Scalars['String']>;
  subclassFallbackFeaturesIcon?: InputMaybe<AssetUpdateManyInlineInput>;
};

export type SubClassUpdateLocalizationDataInput = {
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  subClassDescription?: InputMaybe<Scalars['RichTextAST']>;
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  subClassFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  subClassName?: InputMaybe<Scalars['String']>;
};

export type SubClassUpdateLocalizationInput = {
  data: SubClassUpdateLocalizationDataInput;
  locale: Locale;
};

export type SubClassUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SubClassCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<SubClassUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<SubClassUpsertLocalizationInput>>;
};

export type SubClassUpdateManyInlineInput = {
  /** Connect multiple existing SubClass documents */
  connect?: InputMaybe<Array<SubClassConnectInput>>;
  /** Create and connect multiple SubClass documents */
  create?: InputMaybe<Array<SubClassCreateInput>>;
  /** Delete multiple SubClass documents */
  delete?: InputMaybe<Array<SubClassWhereUniqueInput>>;
  /** Disconnect multiple SubClass documents */
  disconnect?: InputMaybe<Array<SubClassWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SubClass documents */
  set?: InputMaybe<Array<SubClassWhereUniqueInput>>;
  /** Update multiple SubClass documents */
  update?: InputMaybe<Array<SubClassUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple SubClass documents */
  upsert?: InputMaybe<Array<SubClassUpsertWithNestedWhereUniqueInput>>;
};

export type SubClassUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SubClassUpdateManyLocalizationsInput>;
  /** referenceCarModelName input for default locale (de_CH) */
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  rentSubclassId?: InputMaybe<Scalars['Int']>;
  /** subClassDescription input for default locale (de_CH) */
  subClassDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** subClassFallbackFeatures input for default locale (de_CH) */
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  /** subClassFeatures input for default locale (de_CH) */
  subClassFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** subClassName input for default locale (de_CH) */
  subClassName?: InputMaybe<Scalars['String']>;
};

export type SubClassUpdateManyLocalizationDataInput = {
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  subClassDescription?: InputMaybe<Scalars['RichTextAST']>;
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  subClassFeatures?: InputMaybe<Array<Scalars['RichTextAST']>>;
  subClassName?: InputMaybe<Scalars['String']>;
};

export type SubClassUpdateManyLocalizationInput = {
  data: SubClassUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type SubClassUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SubClassUpdateManyLocalizationInput>>;
};

export type SubClassUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SubClassUpdateManyInput;
  /** Document search */
  where: SubClassWhereInput;
};

export type SubClassUpdateOneInlineInput = {
  /** Connect existing SubClass document */
  connect?: InputMaybe<SubClassWhereUniqueInput>;
  /** Create and connect one SubClass document */
  create?: InputMaybe<SubClassCreateInput>;
  /** Delete currently connected SubClass document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SubClass document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SubClass document */
  update?: InputMaybe<SubClassUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SubClass document */
  upsert?: InputMaybe<SubClassUpsertWithNestedWhereUniqueInput>;
};

export type SubClassUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SubClassUpdateInput;
  /** Unique document search */
  where: SubClassWhereUniqueInput;
};

export type SubClassUpsertInput = {
  /** Create document if it didn't exist */
  create: SubClassCreateInput;
  /** Update document if it exists */
  update: SubClassUpdateInput;
};

export type SubClassUpsertLocalizationInput = {
  create: SubClassCreateLocalizationDataInput;
  locale: Locale;
  update: SubClassUpdateLocalizationDataInput;
};

export type SubClassUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SubClassUpsertInput;
  /** Unique document search */
  where: SubClassWhereUniqueInput;
};

/** Identifies documents */
export type SubClassWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SubClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SubClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SubClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModelFeatures_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_some?: InputMaybe<CarModelFeatureWhereInput>;
  carModels_every?: InputMaybe<CarModelWhereInput>;
  carModels_none?: InputMaybe<CarModelWhereInput>;
  carModels_some?: InputMaybe<CarModelWhereInput>;
  class?: InputMaybe<ClassWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  referenceCarModelName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  referenceCarModelName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  referenceCarModelName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  referenceCarModelName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  referenceCarModelName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  referenceCarModelName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  referenceCarModelName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  referenceCarModelName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  referenceCarModelName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  referenceCarModelName_starts_with?: InputMaybe<Scalars['String']>;
  rentSubclassId?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  rentSubclassId_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  rentSubclassId_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  rentSubclassId_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  rentSubclassId_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  rentSubclassId_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  rentSubclassId_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  rentSubclassId_not_in?: InputMaybe<Array<Scalars['Int']>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  subClassFallbackFeatures?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  subClassFallbackFeatures_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  subClassFallbackFeatures_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  subClassFallbackFeatures_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  subClassFallbackFeatures_not?: InputMaybe<Array<Scalars['String']>>;
  subClassFeatureIcon_every?: InputMaybe<AssetWhereInput>;
  subClassFeatureIcon_none?: InputMaybe<AssetWhereInput>;
  subClassFeatureIcon_some?: InputMaybe<AssetWhereInput>;
  subClassImage?: InputMaybe<AssetWhereInput>;
  subClassName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  subClassName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  subClassName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  subClassName_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  subClassName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  subClassName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  subClassName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  subClassName_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  subClassName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  subClassName_starts_with?: InputMaybe<Scalars['String']>;
  subclassFallbackFeaturesIcon_every?: InputMaybe<AssetWhereInput>;
  subclassFallbackFeaturesIcon_none?: InputMaybe<AssetWhereInput>;
  subclassFallbackFeaturesIcon_some?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References SubClass record uniquely */
export type SubClassWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreen = Node & {
  __typename?: 'SuccessfulPaymentScreen';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<SuccessfulPaymentScreen>;
  /** List of SuccessfulPaymentScreen versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<SuccessfulPaymentScreen>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** This is the button on the successful payment page that prompts the user to continue, returning the user to the hoemscreen */
  successfulPaymentPageButton?: Maybe<Scalars['String']>;
  /** This is the image or illustration that is displayed on the successful payment page */
  successfulPaymentPageImage?: Maybe<Asset>;
  /** This is the subheader that is above the paragraph text on the successful payment page, that further explains the screen.  */
  successfulPaymentPageSubheader?: Maybe<Scalars['String']>;
  /** This is the text that is displayed on the successful payment page */
  successfulPaymentPageText?: Maybe<Scalars['String']>;
  /** This is the header at the top of the successful payment screen */
  successfulPaymentpageHeader?: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenSuccessfulPaymentPageImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** This is the screen that is shown once the user's payment has been successfully confirmed.  */
export type SuccessfulPaymentScreenUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type SuccessfulPaymentScreenConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SuccessfulPaymentScreenWhereUniqueInput;
};

/** A connection to a list of items. */
export type SuccessfulPaymentScreenConnection = {
  __typename?: 'SuccessfulPaymentScreenConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SuccessfulPaymentScreenEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SuccessfulPaymentScreenCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SuccessfulPaymentScreenCreateLocalizationsInput>;
  /** successfulPaymentPageButton input for default locale (de_CH) */
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  successfulPaymentPageImage?: InputMaybe<AssetCreateOneInlineInput>;
  /** successfulPaymentPageSubheader input for default locale (de_CH) */
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  /** successfulPaymentPageText input for default locale (de_CH) */
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  /** successfulPaymentpageHeader input for default locale (de_CH) */
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SuccessfulPaymentScreenCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SuccessfulPaymentScreenCreateLocalizationInput = {
  /** Localization input */
  data: SuccessfulPaymentScreenCreateLocalizationDataInput;
  locale: Locale;
};

export type SuccessfulPaymentScreenCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SuccessfulPaymentScreenCreateLocalizationInput>>;
};

export type SuccessfulPaymentScreenCreateManyInlineInput = {
  /** Connect multiple existing SuccessfulPaymentScreen documents */
  connect?: InputMaybe<Array<SuccessfulPaymentScreenWhereUniqueInput>>;
  /** Create and connect multiple existing SuccessfulPaymentScreen documents */
  create?: InputMaybe<Array<SuccessfulPaymentScreenCreateInput>>;
};

export type SuccessfulPaymentScreenCreateOneInlineInput = {
  /** Connect one existing SuccessfulPaymentScreen document */
  connect?: InputMaybe<SuccessfulPaymentScreenWhereUniqueInput>;
  /** Create and connect one SuccessfulPaymentScreen document */
  create?: InputMaybe<SuccessfulPaymentScreenCreateInput>;
};

/** An edge in a connection. */
export type SuccessfulPaymentScreenEdge = {
  __typename?: 'SuccessfulPaymentScreenEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SuccessfulPaymentScreen;
};

/** Identifies documents */
export type SuccessfulPaymentScreenManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SuccessfulPaymentScreenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SuccessfulPaymentScreenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SuccessfulPaymentScreenWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  successfulPaymentPageImage?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SuccessfulPaymentScreenOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SuccessfulPaymentPageButtonAsc = 'successfulPaymentPageButton_ASC',
  SuccessfulPaymentPageButtonDesc = 'successfulPaymentPageButton_DESC',
  SuccessfulPaymentPageSubheaderAsc = 'successfulPaymentPageSubheader_ASC',
  SuccessfulPaymentPageSubheaderDesc = 'successfulPaymentPageSubheader_DESC',
  SuccessfulPaymentPageTextAsc = 'successfulPaymentPageText_ASC',
  SuccessfulPaymentPageTextDesc = 'successfulPaymentPageText_DESC',
  SuccessfulPaymentpageHeaderAsc = 'successfulPaymentpageHeader_ASC',
  SuccessfulPaymentpageHeaderDesc = 'successfulPaymentpageHeader_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SuccessfulPaymentScreenUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<SuccessfulPaymentScreenUpdateLocalizationsInput>;
  /** successfulPaymentPageButton input for default locale (de_CH) */
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  successfulPaymentPageImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** successfulPaymentPageSubheader input for default locale (de_CH) */
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  /** successfulPaymentPageText input for default locale (de_CH) */
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  /** successfulPaymentpageHeader input for default locale (de_CH) */
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
};

export type SuccessfulPaymentScreenUpdateLocalizationDataInput = {
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
};

export type SuccessfulPaymentScreenUpdateLocalizationInput = {
  data: SuccessfulPaymentScreenUpdateLocalizationDataInput;
  locale: Locale;
};

export type SuccessfulPaymentScreenUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SuccessfulPaymentScreenCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<SuccessfulPaymentScreenUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<SuccessfulPaymentScreenUpsertLocalizationInput>>;
};

export type SuccessfulPaymentScreenUpdateManyInlineInput = {
  /** Connect multiple existing SuccessfulPaymentScreen documents */
  connect?: InputMaybe<Array<SuccessfulPaymentScreenConnectInput>>;
  /** Create and connect multiple SuccessfulPaymentScreen documents */
  create?: InputMaybe<Array<SuccessfulPaymentScreenCreateInput>>;
  /** Delete multiple SuccessfulPaymentScreen documents */
  delete?: InputMaybe<Array<SuccessfulPaymentScreenWhereUniqueInput>>;
  /** Disconnect multiple SuccessfulPaymentScreen documents */
  disconnect?: InputMaybe<Array<SuccessfulPaymentScreenWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SuccessfulPaymentScreen documents */
  set?: InputMaybe<Array<SuccessfulPaymentScreenWhereUniqueInput>>;
  /** Update multiple SuccessfulPaymentScreen documents */
  update?: InputMaybe<Array<SuccessfulPaymentScreenUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple SuccessfulPaymentScreen documents */
  upsert?: InputMaybe<Array<SuccessfulPaymentScreenUpsertWithNestedWhereUniqueInput>>;
};

export type SuccessfulPaymentScreenUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SuccessfulPaymentScreenUpdateManyLocalizationsInput>;
  /** successfulPaymentPageButton input for default locale (de_CH) */
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  /** successfulPaymentPageSubheader input for default locale (de_CH) */
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  /** successfulPaymentPageText input for default locale (de_CH) */
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  /** successfulPaymentpageHeader input for default locale (de_CH) */
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
};

export type SuccessfulPaymentScreenUpdateManyLocalizationDataInput = {
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
};

export type SuccessfulPaymentScreenUpdateManyLocalizationInput = {
  data: SuccessfulPaymentScreenUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type SuccessfulPaymentScreenUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SuccessfulPaymentScreenUpdateManyLocalizationInput>>;
};

export type SuccessfulPaymentScreenUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SuccessfulPaymentScreenUpdateManyInput;
  /** Document search */
  where: SuccessfulPaymentScreenWhereInput;
};

export type SuccessfulPaymentScreenUpdateOneInlineInput = {
  /** Connect existing SuccessfulPaymentScreen document */
  connect?: InputMaybe<SuccessfulPaymentScreenWhereUniqueInput>;
  /** Create and connect one SuccessfulPaymentScreen document */
  create?: InputMaybe<SuccessfulPaymentScreenCreateInput>;
  /** Delete currently connected SuccessfulPaymentScreen document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SuccessfulPaymentScreen document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SuccessfulPaymentScreen document */
  update?: InputMaybe<SuccessfulPaymentScreenUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SuccessfulPaymentScreen document */
  upsert?: InputMaybe<SuccessfulPaymentScreenUpsertWithNestedWhereUniqueInput>;
};

export type SuccessfulPaymentScreenUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SuccessfulPaymentScreenUpdateInput;
  /** Unique document search */
  where: SuccessfulPaymentScreenWhereUniqueInput;
};

export type SuccessfulPaymentScreenUpsertInput = {
  /** Create document if it didn't exist */
  create: SuccessfulPaymentScreenCreateInput;
  /** Update document if it exists */
  update: SuccessfulPaymentScreenUpdateInput;
};

export type SuccessfulPaymentScreenUpsertLocalizationInput = {
  create: SuccessfulPaymentScreenCreateLocalizationDataInput;
  locale: Locale;
  update: SuccessfulPaymentScreenUpdateLocalizationDataInput;
};

export type SuccessfulPaymentScreenUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SuccessfulPaymentScreenUpsertInput;
  /** Unique document search */
  where: SuccessfulPaymentScreenWhereUniqueInput;
};

/** Identifies documents */
export type SuccessfulPaymentScreenWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SuccessfulPaymentScreenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SuccessfulPaymentScreenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SuccessfulPaymentScreenWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  successfulPaymentPageButton?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  successfulPaymentPageButton_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  successfulPaymentPageButton_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  successfulPaymentPageButton_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  successfulPaymentPageButton_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  successfulPaymentPageButton_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  successfulPaymentPageButton_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  successfulPaymentPageButton_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  successfulPaymentPageButton_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  successfulPaymentPageButton_starts_with?: InputMaybe<Scalars['String']>;
  successfulPaymentPageImage?: InputMaybe<AssetWhereInput>;
  successfulPaymentPageSubheader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  successfulPaymentPageSubheader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  successfulPaymentPageSubheader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  successfulPaymentPageSubheader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  successfulPaymentPageSubheader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  successfulPaymentPageSubheader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  successfulPaymentPageSubheader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  successfulPaymentPageSubheader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  successfulPaymentPageSubheader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  successfulPaymentPageSubheader_starts_with?: InputMaybe<Scalars['String']>;
  successfulPaymentPageText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  successfulPaymentPageText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  successfulPaymentPageText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  successfulPaymentPageText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  successfulPaymentPageText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  successfulPaymentPageText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  successfulPaymentPageText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  successfulPaymentPageText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  successfulPaymentPageText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  successfulPaymentPageText_starts_with?: InputMaybe<Scalars['String']>;
  successfulPaymentpageHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  successfulPaymentpageHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  successfulPaymentpageHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  successfulPaymentpageHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  successfulPaymentpageHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  successfulPaymentpageHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  successfulPaymentpageHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  successfulPaymentpageHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  successfulPaymentpageHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  successfulPaymentpageHeader_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References SuccessfulPaymentScreen record uniquely */
export type SuccessfulPaymentScreenWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Combined = 'COMBINED',
  Localization = 'LOCALIZATION'
}

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** User system model */
export type User = Node & {
  __typename?: 'User';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** Get the document in other stages */
  documentInStages: Array<User>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean'];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: UserKind;
  /** The username */
  name: Scalars['String'];
  /** Profile Picture url */
  picture?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
};


/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK'
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<UserKind>>;
  /** All values that are not equal to given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<UserKind>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  picture_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum UserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<UserKind>>;
  /** All values that are not equal to given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<UserKind>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  picture_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Verification is the process that users go through to verify their identity matching their face. */
export type Verification = Node & {
  __typename?: 'Verification';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Verification>;
  /** List of Verification versions */
  history: Array<Version>;
  /** This text describes every icon seperately. */
  iconDescription: Array<RichText>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Verification>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** This is the link text that is displayed on the verification failed screen that connects the user to help. */
  verficationFailedLinkText?: Maybe<Scalars['String']>;
  /** Each page has a short description, each description can be styled differently and have embedded icons. */
  verficationicon: Array<Asset>;
  /** Verification screens have at least one button. */
  verificationButton: Array<Scalars['String']>;
  /** Each page has a short description, each description can be styled differently and have embedded icons. */
  verificationDescription?: Maybe<VerificationVerificationDescriptionRichText>;
  /**
   * ONLY ON START VERIFICATION PROCESS SCREEN
   * This is the verification disclaimer above the CTA, displayed to the user before verification begins
   */
  verificationDurationDisclaimer?: Maybe<Scalars['String']>;
  /** Each page has an image to convey the user what to do next. */
  verificationImage: Asset;
  /** The verification process is defined by the order. There are two different entry levels (for order number 1): book now and book later. The rest of the process is the same. */
  verificationOrder: Scalars['Int'];
  /** For each verification page, there is a header. */
  verificationPageHeader: Scalars['String'];
  /**
   * This is the text following the photo upload method icon
   *
   * ie. take photo
   */
  verificationPhotoUploadMethodDescriptiveText: Array<Scalars['String']>;
  /**
   * ONLY FOLLOWING IDENTIFICATION UPLOAD SCREENS
   * These are the icons displayed to the user during ID & driver's license photo upload.
   *
   * ie. following the driver's license front screen
   */
  verificationPhotoUploadMethodIcons: Array<Asset>;
  /** Each verification page requires a subtitle and can have an embedded icon. */
  verificationSubtitle: VerificationVerificationSubtitleRichText;
  /** Each verification page requires a subtitle and can have an embedded icon. */
  verificationSubtitleIcon?: Maybe<Asset>;
  /** The workflow Book Now or Book Later defines the screens that are shown. */
  verificationWorkflow: Array<BookingWorkflow>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationVerficationiconArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationVerificationImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationVerificationPhotoUploadMethodIconsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};


/** Verification is the process that users go through to verify their identity matching their face. */
export type VerificationVerificationSubtitleIconArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type VerificationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VerificationWhereUniqueInput;
};

/** A connection to a list of items. */
export type VerificationConnection = {
  __typename?: 'VerificationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VerificationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VerificationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** iconDescription input for default locale (de_CH) */
  iconDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VerificationCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** verficationFailedLinkText input for default locale (de_CH) */
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  verficationicon?: InputMaybe<AssetCreateManyInlineInput>;
  /** verificationButton input for default locale (de_CH) */
  verificationButton: Array<Scalars['String']>;
  /** verificationDescription input for default locale (de_CH) */
  verificationDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** verificationDurationDisclaimer input for default locale (de_CH) */
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  verificationImage: AssetCreateOneInlineInput;
  verificationOrder: Scalars['Int'];
  /** verificationPageHeader input for default locale (de_CH) */
  verificationPageHeader: Scalars['String'];
  /** verificationPhotoUploadMethodDescriptiveText input for default locale (de_CH) */
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  verificationPhotoUploadMethodIcons?: InputMaybe<AssetCreateManyInlineInput>;
  /** verificationSubtitle input for default locale (de_CH) */
  verificationSubtitle: Scalars['RichTextAST'];
  verificationSubtitleIcon?: InputMaybe<AssetCreateOneInlineInput>;
  verificationWorkflow?: InputMaybe<Array<BookingWorkflow>>;
};

export type VerificationCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  iconDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  verificationButton: Array<Scalars['String']>;
  verificationDescription?: InputMaybe<Scalars['RichTextAST']>;
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  verificationPageHeader: Scalars['String'];
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  verificationSubtitle: Scalars['RichTextAST'];
};

export type VerificationCreateLocalizationInput = {
  /** Localization input */
  data: VerificationCreateLocalizationDataInput;
  locale: Locale;
};

export type VerificationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VerificationCreateLocalizationInput>>;
};

export type VerificationCreateManyInlineInput = {
  /** Connect multiple existing Verification documents */
  connect?: InputMaybe<Array<VerificationWhereUniqueInput>>;
  /** Create and connect multiple existing Verification documents */
  create?: InputMaybe<Array<VerificationCreateInput>>;
};

export type VerificationCreateOneInlineInput = {
  /** Connect one existing Verification document */
  connect?: InputMaybe<VerificationWhereUniqueInput>;
  /** Create and connect one Verification document */
  create?: InputMaybe<VerificationCreateInput>;
};

/** An edge in a connection. */
export type VerificationEdge = {
  __typename?: 'VerificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Verification;
};

/** Identifies documents */
export type VerificationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VerificationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VerificationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VerificationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  verficationicon_every?: InputMaybe<AssetWhereInput>;
  verficationicon_none?: InputMaybe<AssetWhereInput>;
  verficationicon_some?: InputMaybe<AssetWhereInput>;
  verificationImage?: InputMaybe<AssetWhereInput>;
  verificationOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  verificationOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  verificationOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  verificationOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  verificationOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  verificationOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  verificationOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  verificationOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  verificationPhotoUploadMethodIcons_every?: InputMaybe<AssetWhereInput>;
  verificationPhotoUploadMethodIcons_none?: InputMaybe<AssetWhereInput>;
  verificationPhotoUploadMethodIcons_some?: InputMaybe<AssetWhereInput>;
  verificationSubtitleIcon?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  verificationWorkflow?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array contains *all* items provided to the filter */
  verificationWorkflow_contains_all?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  verificationWorkflow_contains_none?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array contains at least one item provided to the filter */
  verificationWorkflow_contains_some?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  verificationWorkflow_not?: InputMaybe<Array<BookingWorkflow>>;
};

export enum VerificationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VerficationFailedLinkTextAsc = 'verficationFailedLinkText_ASC',
  VerficationFailedLinkTextDesc = 'verficationFailedLinkText_DESC',
  VerificationButtonAsc = 'verificationButton_ASC',
  VerificationButtonDesc = 'verificationButton_DESC',
  VerificationDurationDisclaimerAsc = 'verificationDurationDisclaimer_ASC',
  VerificationDurationDisclaimerDesc = 'verificationDurationDisclaimer_DESC',
  VerificationOrderAsc = 'verificationOrder_ASC',
  VerificationOrderDesc = 'verificationOrder_DESC',
  VerificationPageHeaderAsc = 'verificationPageHeader_ASC',
  VerificationPageHeaderDesc = 'verificationPageHeader_DESC',
  VerificationPhotoUploadMethodDescriptiveTextAsc = 'verificationPhotoUploadMethodDescriptiveText_ASC',
  VerificationPhotoUploadMethodDescriptiveTextDesc = 'verificationPhotoUploadMethodDescriptiveText_DESC',
  VerificationWorkflowAsc = 'verificationWorkflow_ASC',
  VerificationWorkflowDesc = 'verificationWorkflow_DESC'
}

export type VerificationUpdateInput = {
  /** iconDescription input for default locale (de_CH) */
  iconDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** Manage document localizations */
  localizations?: InputMaybe<VerificationUpdateLocalizationsInput>;
  /** verficationFailedLinkText input for default locale (de_CH) */
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  verficationicon?: InputMaybe<AssetUpdateManyInlineInput>;
  /** verificationButton input for default locale (de_CH) */
  verificationButton?: InputMaybe<Array<Scalars['String']>>;
  /** verificationDescription input for default locale (de_CH) */
  verificationDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** verificationDurationDisclaimer input for default locale (de_CH) */
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  verificationImage?: InputMaybe<AssetUpdateOneInlineInput>;
  verificationOrder?: InputMaybe<Scalars['Int']>;
  /** verificationPageHeader input for default locale (de_CH) */
  verificationPageHeader?: InputMaybe<Scalars['String']>;
  /** verificationPhotoUploadMethodDescriptiveText input for default locale (de_CH) */
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  verificationPhotoUploadMethodIcons?: InputMaybe<AssetUpdateManyInlineInput>;
  /** verificationSubtitle input for default locale (de_CH) */
  verificationSubtitle?: InputMaybe<Scalars['RichTextAST']>;
  verificationSubtitleIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  verificationWorkflow?: InputMaybe<Array<BookingWorkflow>>;
};

export type VerificationUpdateLocalizationDataInput = {
  iconDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  verificationButton?: InputMaybe<Array<Scalars['String']>>;
  verificationDescription?: InputMaybe<Scalars['RichTextAST']>;
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  verificationPageHeader?: InputMaybe<Scalars['String']>;
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  verificationSubtitle?: InputMaybe<Scalars['RichTextAST']>;
};

export type VerificationUpdateLocalizationInput = {
  data: VerificationUpdateLocalizationDataInput;
  locale: Locale;
};

export type VerificationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VerificationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VerificationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VerificationUpsertLocalizationInput>>;
};

export type VerificationUpdateManyInlineInput = {
  /** Connect multiple existing Verification documents */
  connect?: InputMaybe<Array<VerificationConnectInput>>;
  /** Create and connect multiple Verification documents */
  create?: InputMaybe<Array<VerificationCreateInput>>;
  /** Delete multiple Verification documents */
  delete?: InputMaybe<Array<VerificationWhereUniqueInput>>;
  /** Disconnect multiple Verification documents */
  disconnect?: InputMaybe<Array<VerificationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Verification documents */
  set?: InputMaybe<Array<VerificationWhereUniqueInput>>;
  /** Update multiple Verification documents */
  update?: InputMaybe<Array<VerificationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Verification documents */
  upsert?: InputMaybe<Array<VerificationUpsertWithNestedWhereUniqueInput>>;
};

export type VerificationUpdateManyInput = {
  /** iconDescription input for default locale (de_CH) */
  iconDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VerificationUpdateManyLocalizationsInput>;
  /** verficationFailedLinkText input for default locale (de_CH) */
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  /** verificationButton input for default locale (de_CH) */
  verificationButton?: InputMaybe<Array<Scalars['String']>>;
  /** verificationDescription input for default locale (de_CH) */
  verificationDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** verificationDurationDisclaimer input for default locale (de_CH) */
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  /** verificationPageHeader input for default locale (de_CH) */
  verificationPageHeader?: InputMaybe<Scalars['String']>;
  /** verificationPhotoUploadMethodDescriptiveText input for default locale (de_CH) */
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  /** verificationSubtitle input for default locale (de_CH) */
  verificationSubtitle?: InputMaybe<Scalars['RichTextAST']>;
  verificationWorkflow?: InputMaybe<Array<BookingWorkflow>>;
};

export type VerificationUpdateManyLocalizationDataInput = {
  iconDescription?: InputMaybe<Array<Scalars['RichTextAST']>>;
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  verificationButton?: InputMaybe<Array<Scalars['String']>>;
  verificationDescription?: InputMaybe<Scalars['RichTextAST']>;
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  verificationPageHeader?: InputMaybe<Scalars['String']>;
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  verificationSubtitle?: InputMaybe<Scalars['RichTextAST']>;
};

export type VerificationUpdateManyLocalizationInput = {
  data: VerificationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VerificationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VerificationUpdateManyLocalizationInput>>;
};

export type VerificationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VerificationUpdateManyInput;
  /** Document search */
  where: VerificationWhereInput;
};

export type VerificationUpdateOneInlineInput = {
  /** Connect existing Verification document */
  connect?: InputMaybe<VerificationWhereUniqueInput>;
  /** Create and connect one Verification document */
  create?: InputMaybe<VerificationCreateInput>;
  /** Delete currently connected Verification document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Verification document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Verification document */
  update?: InputMaybe<VerificationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Verification document */
  upsert?: InputMaybe<VerificationUpsertWithNestedWhereUniqueInput>;
};

export type VerificationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VerificationUpdateInput;
  /** Unique document search */
  where: VerificationWhereUniqueInput;
};

export type VerificationUpsertInput = {
  /** Create document if it didn't exist */
  create: VerificationCreateInput;
  /** Update document if it exists */
  update: VerificationUpdateInput;
};

export type VerificationUpsertLocalizationInput = {
  create: VerificationCreateLocalizationDataInput;
  locale: Locale;
  update: VerificationUpdateLocalizationDataInput;
};

export type VerificationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VerificationUpsertInput;
  /** Unique document search */
  where: VerificationWhereUniqueInput;
};

export type VerificationVerificationDescriptionRichText = {
  __typename?: 'VerificationVerificationDescriptionRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<VerificationVerificationDescriptionRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type VerificationVerificationDescriptionRichTextEmbeddedTypes = Asset;

export type VerificationVerificationSubtitleRichText = {
  __typename?: 'VerificationVerificationSubtitleRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<VerificationVerificationSubtitleRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type VerificationVerificationSubtitleRichTextEmbeddedTypes = Asset;

/** Identifies documents */
export type VerificationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VerificationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VerificationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VerificationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  verficationFailedLinkText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  verficationFailedLinkText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  verficationFailedLinkText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  verficationFailedLinkText_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  verficationFailedLinkText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  verficationFailedLinkText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  verficationFailedLinkText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  verficationFailedLinkText_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  verficationFailedLinkText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  verficationFailedLinkText_starts_with?: InputMaybe<Scalars['String']>;
  verficationicon_every?: InputMaybe<AssetWhereInput>;
  verficationicon_none?: InputMaybe<AssetWhereInput>;
  verficationicon_some?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  verificationButton?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  verificationButton_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  verificationButton_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  verificationButton_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  verificationButton_not?: InputMaybe<Array<Scalars['String']>>;
  verificationDurationDisclaimer?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  verificationDurationDisclaimer_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  verificationDurationDisclaimer_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  verificationDurationDisclaimer_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  verificationDurationDisclaimer_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  verificationDurationDisclaimer_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  verificationDurationDisclaimer_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  verificationDurationDisclaimer_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  verificationDurationDisclaimer_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  verificationDurationDisclaimer_starts_with?: InputMaybe<Scalars['String']>;
  verificationImage?: InputMaybe<AssetWhereInput>;
  verificationOrder?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  verificationOrder_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  verificationOrder_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  verificationOrder_in?: InputMaybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  verificationOrder_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  verificationOrder_lte?: InputMaybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  verificationOrder_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  verificationOrder_not_in?: InputMaybe<Array<Scalars['Int']>>;
  verificationPageHeader?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  verificationPageHeader_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  verificationPageHeader_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  verificationPageHeader_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  verificationPageHeader_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  verificationPageHeader_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  verificationPageHeader_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  verificationPageHeader_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  verificationPageHeader_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  verificationPageHeader_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  verificationPhotoUploadMethodDescriptiveText?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  verificationPhotoUploadMethodDescriptiveText_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  verificationPhotoUploadMethodDescriptiveText_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  verificationPhotoUploadMethodDescriptiveText_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  verificationPhotoUploadMethodDescriptiveText_not?: InputMaybe<Array<Scalars['String']>>;
  verificationPhotoUploadMethodIcons_every?: InputMaybe<AssetWhereInput>;
  verificationPhotoUploadMethodIcons_none?: InputMaybe<AssetWhereInput>;
  verificationPhotoUploadMethodIcons_some?: InputMaybe<AssetWhereInput>;
  verificationSubtitleIcon?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  verificationWorkflow?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array contains *all* items provided to the filter */
  verificationWorkflow_contains_all?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  verificationWorkflow_contains_none?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array contains at least one item provided to the filter */
  verificationWorkflow_contains_some?: InputMaybe<Array<BookingWorkflow>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  verificationWorkflow_not?: InputMaybe<Array<BookingWorkflow>>;
};

/** References Verification record uniquely */
export type VerificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  verificationOrder?: InputMaybe<Scalars['Int']>;
};

export type Version = {
  __typename?: 'Version';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type Vin = Node & {
  __typename?: 'Vin';
  carModel?: Maybe<CarModel>;
  carModelFeatures: Array<CarModelFeature>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Vin>;
  /** List of Vin versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** This is the VIN number in RENT */
  vin?: Maybe<Scalars['String']>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinCarModelArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinCarModelFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CarModelFeatureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarModelFeatureWhereInput>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinCreatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinPublishedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/**
 * This is the table to match the VINs in RENT and the Car Models in the CMS
 *
 */
export type VinUpdatedByArgs = {
  locales?: InputMaybe<Array<Locale>>;
};

export type VinConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VinWhereUniqueInput;
};

/** A connection to a list of items. */
export type VinConnection = {
  __typename?: 'VinConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VinEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VinCreateInput = {
  carModel?: InputMaybe<CarModelCreateOneInlineInput>;
  carModelFeatures?: InputMaybe<CarModelFeatureCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type VinCreateManyInlineInput = {
  /** Connect multiple existing Vin documents */
  connect?: InputMaybe<Array<VinWhereUniqueInput>>;
  /** Create and connect multiple existing Vin documents */
  create?: InputMaybe<Array<VinCreateInput>>;
};

export type VinCreateOneInlineInput = {
  /** Connect one existing Vin document */
  connect?: InputMaybe<VinWhereUniqueInput>;
  /** Create and connect one Vin document */
  create?: InputMaybe<VinCreateInput>;
};

/** An edge in a connection. */
export type VinEdge = {
  __typename?: 'VinEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Vin;
};

/** Identifies documents */
export type VinManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VinWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VinWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VinWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModel?: InputMaybe<CarModelWhereInput>;
  carModelFeatures_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_some?: InputMaybe<CarModelFeatureWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vin?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  vin_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  vin_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  vin_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  vin_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  vin_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  vin_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  vin_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  vin_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  vin_starts_with?: InputMaybe<Scalars['String']>;
};

export enum VinOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VinAsc = 'vin_ASC',
  VinDesc = 'vin_DESC'
}

export type VinUpdateInput = {
  carModel?: InputMaybe<CarModelUpdateOneInlineInput>;
  carModelFeatures?: InputMaybe<CarModelFeatureUpdateManyInlineInput>;
  vin?: InputMaybe<Scalars['String']>;
};

export type VinUpdateManyInlineInput = {
  /** Connect multiple existing Vin documents */
  connect?: InputMaybe<Array<VinConnectInput>>;
  /** Create and connect multiple Vin documents */
  create?: InputMaybe<Array<VinCreateInput>>;
  /** Delete multiple Vin documents */
  delete?: InputMaybe<Array<VinWhereUniqueInput>>;
  /** Disconnect multiple Vin documents */
  disconnect?: InputMaybe<Array<VinWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Vin documents */
  set?: InputMaybe<Array<VinWhereUniqueInput>>;
  /** Update multiple Vin documents */
  update?: InputMaybe<Array<VinUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Vin documents */
  upsert?: InputMaybe<Array<VinUpsertWithNestedWhereUniqueInput>>;
};

export type VinUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type VinUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VinUpdateManyInput;
  /** Document search */
  where: VinWhereInput;
};

export type VinUpdateOneInlineInput = {
  /** Connect existing Vin document */
  connect?: InputMaybe<VinWhereUniqueInput>;
  /** Create and connect one Vin document */
  create?: InputMaybe<VinCreateInput>;
  /** Delete currently connected Vin document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Vin document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Vin document */
  update?: InputMaybe<VinUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Vin document */
  upsert?: InputMaybe<VinUpsertWithNestedWhereUniqueInput>;
};

export type VinUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VinUpdateInput;
  /** Unique document search */
  where: VinWhereUniqueInput;
};

export type VinUpsertInput = {
  /** Create document if it didn't exist */
  create: VinCreateInput;
  /** Update document if it exists */
  update: VinUpdateInput;
};

export type VinUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VinUpsertInput;
  /** Unique document search */
  where: VinWhereUniqueInput;
};

/** Identifies documents */
export type VinWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VinWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VinWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VinWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  carModel?: InputMaybe<CarModelWhereInput>;
  carModelFeatures_every?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_none?: InputMaybe<CarModelFeatureWhereInput>;
  carModelFeatures_some?: InputMaybe<CarModelFeatureWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vin?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  vin_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  vin_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  vin_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values that are not equal to given value. */
  vin_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  vin_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  vin_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  vin_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  vin_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  vin_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Vin record uniquely */
export type VinWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  vin?: InputMaybe<Scalars['String']>;
};

/** These are the enumerations that differentiate between navigation items across viewport sizes (mobile and desktop viewport items) */
export enum WebAppNavigationSelection {
  DesktopHeader = 'desktopHeader',
  Footer = 'footer',
  MobileHeader = 'mobileHeader',
  MobileMenu = 'mobileMenu'
}

export enum _FilterKind {
  And = 'AND',
  Not = 'NOT',
  Or = 'OR',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsNone = 'contains_none',
  ContainsSome = 'contains_some',
  EndsWith = 'ends_with',
  Eq = 'eq',
  EqNot = 'eq_not',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'not_contains',
  NotEndsWith = 'not_ends_with',
  NotIn = 'not_in',
  NotStartsWith = 'not_starts_with',
  RelationalEvery = 'relational_every',
  RelationalNone = 'relational_none',
  RelationalSingle = 'relational_single',
  RelationalSome = 'relational_some',
  Search = 'search',
  StartsWith = 'starts_with'
}

export enum _MutationInputFieldKind {
  Enum = 'enum',
  Relation = 'relation',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Scalar = 'scalar',
  Union = 'union',
  Virtual = 'virtual'
}

export enum _MutationKind {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Publish = 'publish',
  PublishMany = 'publishMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
  Unpublish = 'unpublish',
  UnpublishMany = 'unpublishMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert'
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum _RelationInputCardinality {
  Many = 'many',
  One = 'one'
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update'
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union'
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Combined = 'combined',
  Localization = 'localization'
}
