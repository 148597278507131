import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const getErrorState = (state: RootState) => state.errors;

export const getCurrentErrorState = createSelector(
  [getErrorState],
  (errorState) => errorState.visible
);

export const getCurrentErrorMessage = createSelector(
  [getErrorState],
  (errorState) => errorState.message
);
