import { Locale } from 'generated/graphql';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HomeHeader from 'shared/components/header/HomeHeader';
import MarkdownText from 'shared/components/text/MarkdownText';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCurrentLocale } from 'store/language/language.selectors';
import { updateCurrentLanguage } from 'store/language/language.thunk';
import { privacyTexts } from './privacy-text.config';
import { usePrefetch as useNavigationsPrefetch } from 'store/navigations/navigations-overview.slice';

const PrivacyComponent = () => {
  const { lang } = useParams<string>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = useAppSelector(getCurrentLocale);
  const prefetchNavigations = useNavigationsPrefetch('GetNavigationsOverview');

  useEffect(() => {
    if (lang && Object.values(Locale).includes(lang as Locale)) {
      dispatch(updateCurrentLanguage(lang));
    }
  }, []);

  useEffect(() => {
    navigate('/privacy/' + currentLanguage);
    prefetchNavigations();
  }, [currentLanguage]);

  return (
    <main className="privacy">
      <HomeHeader />
      <section className="px-4 md:px-8 pt-4 pb-8">
        <MarkdownText text={privacyTexts[currentLanguage]} />
      </section>
    </main>
  );
};
export default PrivacyComponent;
