import { createSelector } from '@reduxjs/toolkit';
import { LocationSort } from '../../shared/components/booking-widget/location-sort.enum';
import { LocationsUtils } from '../../shared/components/booking-widget/locations.utils';
import {
  getLocationSearchQuery,
  getLocationSortType,
  getUserLocation
} from '../booking/booking.selectors';
import { RootState } from '../store';
import { locationsAPI } from './locations.slice';
import { locationOverviewAPI } from './location-overview.slice';

const getAllLocations = locationsAPI.endpoints.getLocationsList.select();
const getAllLocationsOverview = locationOverviewAPI.endpoints.GetAllLocationsOverview.select();

export const getLocationsBySearchCriteria = createSelector(
  [getLocationSearchQuery, getAllLocations],
  (searchQuery, locations) => {
    if (!searchQuery) return locations.data;

    const searchToLowerCase = searchQuery.toLocaleLowerCase();
    return locations.data?.filter(
      (location) =>
        location.locationName.toLocaleLowerCase().includes(searchToLowerCase) ||
        location.postalCode.includes(searchToLowerCase)
    );
  }
);

type Return = (state: RootState) => string;

export const getLocationNameById = (id: number): Return =>
  createSelector(
    [getAllLocations],
    (locations) =>
      locations.data?.find((location) => location.locationId === id)?.locationName || ''
  );

export const getSortedLocations = createSelector(
  [getLocationsBySearchCriteria, getLocationSortType, getUserLocation],
  (locations, sortType) => {
    return sortType === LocationSort.NAME
      ? LocationsUtils.sortByName(locations)
      : LocationsUtils.sortByDistance(locations);
  }
);

export const getLocationsListOverview = createSelector([getAllLocationsOverview], (locations) => {
  return locations.data;
});

export const getLocationOverviewNameById = (id: number) =>
  createSelector(
    [getLocationsListOverview],
    (list) => list?.find((overview) => overview.rentLocationId === id)?.gOLocationName || ''
  );
