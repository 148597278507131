import MarkdownText from '../../../shared/components/text/MarkdownText';
import PrimaryText from '../../../shared/components/text/PrimaryText';

type LocationImportantInformationProps = {
  goLocationImportantName: string;
  goLocationImportantInformation: string | Array<{ html: string }>;
  goLocationOpeningHoursName: string;
  gOLocationOpeningHours: string | Array<{ html: string }>;
};

export default function LocationImportantInformation({
  goLocationImportantName,
  goLocationImportantInformation,
  goLocationOpeningHoursName,
  gOLocationOpeningHours
}: LocationImportantInformationProps) {
  const concatHtml = (input: Array<{ html: string }> | string) => {
    return Array.isArray(input)
      ? input.map((hours) => hours.html).join('')
      : gOLocationOpeningHours;
  };

  return (
    <>
      <PrimaryText text={goLocationImportantName} className="font-semibold text-lg" />
      <MarkdownText
        paragraphClassName="text-sm text-red-500 my-0"
        text={concatHtml(goLocationImportantInformation)}
      />
      <PrimaryText
        text={goLocationOpeningHoursName as string}
        className="font-semibold text-lg mt-2"
      />
      <MarkdownText
        paragraphClassName="my-1 flex-1 text-color-muted text-sm"
        strongClassName="text-color-secondary mb-1"
        text={concatHtml(gOLocationOpeningHours)}
      />
    </>
  );
}
